import * as React from 'react';
import * as ReactDOM from 'react-dom';
import DialogueBox from './DialogueBox';

const styles = require('./styles/dialogue.styl');

export interface IDialogueProps {
  message?: string;
  confirmText?: string;
  cancelText?: string;
  width?: number;
  height?: number;
  lightBackdrop?: boolean;
  confirmCallback?: () => any;
  cancelCallback?: () => any;
}

export default class Dialogue extends React.Component<IDialogueProps, any> {
  private dialogue: HTMLElement;

  public state = {
    top: -this.props.height,
  };

  public static defaultProps: IDialogueProps = {
    message: '',
    confirmText: 'OK',
    cancelText: 'Cancel',
    width: 400,
    lightBackdrop: false,
    height: 150,
  };

  public componentDidMount(): void {
    // Need to wait 1 tick of the event loop for css transitions to work
    setTimeout(() => this.setState({top: 0}), 0);
  }

  public render(): JSX.Element {
    const { top } = this.state;
    const { lightBackdrop, height } = this.props;

    const props = {...this.props, ...{
      transitionOut: this.transitionOut,
      top: top,
      height: height,
      ref: (dialogue) => {
        this.dialogue = dialogue;
      },
    }};

    const dialogue = <DialogueBox {...props} />;

    let className = styles.DialogueContainer;
    if (lightBackdrop) { className += ` ${styles.LightBackdrop}`; }

    return (
      <div className={className}>
        {dialogue}
      </div>
    );
  }

  public transitionOut = (btnValue): void => {
    const dialogue = ReactDOM.findDOMNode(this.dialogue);

    const transEnd = () => {
      dialogue.removeEventListener('transitionend', transEnd);
      this.handleTransitionEnd(btnValue);
    };

    dialogue.addEventListener('transitionend', transEnd, false);

    this.setState({ top: -1 * this.props.height });
  };

  public handleTransitionEnd = (btnValue): void => {
    this.props.cancelCallback();
    if (btnValue === 'okay') { this.props.confirmCallback(); }
  };
}
