module.exports = {
  "__appName__ is not currently available.": "__appName__ は現在使用できません。",
  "Access Code": "アクセスコード",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "ログインが5回試行されたため、アカウント${this.username}はロックされました。管理者に連絡してロックを解除してください",
  "Afghanistan": "アフガニスタン",
  "Albania": "アルバニア",
  "Algeria": "アルジェリア",
  "American Samoa": "米サモア",
  "Andorra": "アンドラ",
  "Angola": "アンゴラ",
  "Anguilla": "アンギラ",
  "Antigua and Barbuda": "アンチグアバーブーダ",
  "App": "アプリ",
  "Application Error": "アプリケーションエラー",
  "Application error.": "アプリケーションエラー。",
  "Application is not currently available.": "アプリケーションは現在利用できません。",
  "Apply Changes": "変更を適用する",
  "Apr": "4月",
  "April": "4月",
  "Argentina": "アルゼンチン",
  "Armenia": "アルメニア",
  "Aruba": "アルーバ",
  "At least __value__ characters": "少なくとも__value__文字",
  "At least one lowercase letter": "少なくとも1つの小文字",
  "At least one number": "少なくとも1つの数字",
  "At least one of these special characters: __value__": "少なくとも1つの以下の特殊文字：__value__",
  "At least one uppercase letter": "少なくとも1つの大文字",
  "Aug": "8月",
  "August": "8月",
  "Australia": "オーストラリア",
  "Austria": "オーストリア",
  "Azerbaijan": "アゼルバイジャン",
  "Bahamas": "バハマ",
  "Bahrain": "バーレーン",
  "Bangladesh": "バングラデシュ",
  "Barbados": "バルバドス",
  "Belarus": "ベラルーシ",
  "Belgium": "ベルギー",
  "Belize": "ベリーズ",
  "Benin": "ベナン",
  "Bermuda": "バミューダ",
  "Bhutan": "ブータン",
  "Bluetooth is Turned Off": "Bluetoothがオフです",
  "Bolivia": "ボリビア",
  "Bosnia and Herzegovina": "ボスニア・ヘルツェゴビナ",
  "Botswana": "ボツワナ",
  "Brazil": "ブラジル",
  "Bronchial Lavage": "気管支洗浄",
  "Brunei Darussalam": "ブルネイ・ダルサラーム国",
  "Bulgaria": "ブルガリア",
  "Burkina Faso": "ブルキナファソ",
  "Burundi": "ブルンジ",
  "Cambodia": "カンボジア",
  "Cameroon": "カメルーン",
  "Canada": "カナダ",
  "Cancel": "キャンセル",
  "Cannot be empty": "空にできません",
  "Cape Verde": "カーボベルデ",
  "Capillary Blood": "毛細管血",
  "Cayman Islands": "ケイマン諸島",
  "Central African Republic": "中央アフリカ共和国",
  "Cerebrospinal Fluid": "脳脊髄液",
  "Cervical Swab": "子宮頸部スワブ",
  "Chad": "チャド",
  "Change Access Code": "アクセスコードの変更",
  "Check again": "再度確認してください",
  "Chile": "チリ",
  "China": "中国",
  "Clear Search": "検索をクリア",
  "Close": "閉じる",
  "Cocos(Keeling) Islands and Chr": "ココス諸島とクリスマス島",
  "Colombia": "コロンビア",
  "Comoros": "コモロ",
  "Confirm": "確定",
  "Confirm New Password": "新しいパスワードの確認",
  "Confirm new password": "新しいパスワードの確認",
  "Confirm New Password is required.": "新しいパスワードの確認入力が必要です。",
  "Confirm Password is required.": "パスワードの確認入力が必要です。",
  "Confirm Password must match New Password": "パスワードの確認入力は、新しいパスワードと一致しなければなりません",
  "Congo(Brazzaville)": "コンゴ共和国（ブラザビル）",
  "Congo(Kinshasa)": "コンゴ民主共和国（キンシャサ）",
  "Cook Islands": "クック諸島",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx​ __UI_VERSION__",
  "Costa Rica": "コスタリカ",
  "Cote D'Ivoire (Ivory Coast)": "コートジボワール（アイボリーコースト）",
  "Croatia(Hrvatska)": "クロアチア（フルバツカ）",
  "Cuba": "キューバ",
  "Culture Broth": "培養液",
  "Current Password": "現在のパスワード",
  "Current Password is required.": "現在のパスワードが必要です。",
  "Current password required": "現在のパスワードが必要",
  "Cyprus": "キプロス",
  "Czech Republic": "チェコ共和国",
  "Danish": "デンマーク語",
  "Dec": "12月",
  "December": "12月",
  "Denmark": "デンマーク",
  "Disabled": "無効",
  "Discard Changes": "変更を破棄する",
  "Dismiss": "やり直す",
  "Djibouti": "ジブチ",
  "Does not match your previous passwords": "以前使用したパスワードと一致しません",
  "Does not use unsupported characters": "サポートされていない文字を使用しないでください",
  "Domain": "ドメイン",
  "Domain is required.": "ドメインが必要です",
  "Dominica": "ドミニカ国",
  "Dominican Republic": "ドミニカ共和国",
  "Door Open": "カバーが開いています",
  "Dutch": "オランダ語",
  "Ecuador": "エクアドル",
  "Egypt": "エジプト",
  "El Salvador": "エルサルバドル",
  "Email": "メール",
  "Email is required.": "メールが必要です",
  "English (UK)": "英語（英国）",
  "English (US)": "英語（米国）",
  "Enter": "決定",
  "Equatorial Guinea": "赤道ギニア",
  "Eritrea": "エリトリア",
  "Error": "エラー",
  "Estonia": "エストニア",
  "Ethiopia": "エチオピア",
  "Factory Manager is not supported in the Connect mobile application.": "ファクトリーマネージャーはコネクトモバイルアプリでサポートされていません。",
  "Failed to reset password. A server error was encountered": "パスワードのリセットに失敗しました。サーバーエラーです",
  "Falkland Islands(Malvinas)": "フォークランド諸島（マルビナス諸島）",
  "Faroe Islands": "フェロー諸島",
  "Fatal Application Error": "重大なアプリケーションエラー",
  "Feb": "2月",
  "February": "2月",
  "Fecal Specimen": "便検体",
  "Fiji": "フィジー",
  "File type must be __fileType__": "ファイルタイプは__fileType__である必要があります",
  "Finland": "フィンランド",
  "Finnish": "フィンランド語",
  "First Name": "名",
  "Forgot your password?": "パスワードをお忘れですか？",
  "Fr": "金曜",
  "France": "フランス",
  "French": "フランス語",
  "French Guiana": "仏領ギアナ",
  "French Polynesia": "仏領ポリネシア",
  "Gabon": "ガボン",
  "Gambia": "ガンビア",
  "Georgia": "ジョージア",
  "German": "ドイツ語",
  "Germany": "ドイツ",
  "Ghana": "ガーナ",
  "Gibraltar": "ジブラルタル",
  "Go To __storeName__": "__storeName__に移動",
  "Greater than __value__ in __measure__": "__measure__で__value__より大きい",
  "Greece": "ギリシャ",
  "Greenland": "グリーンランド",
  "Grenada": "グレナダ",
  "Guadeloupe": "グアドループ島",
  "Guam": "グアム島",
  "Guatemala": "グアテマラ",
  "Guinea": "ギニア",
  "Guinea - Bissau": "ギニアビサウ",
  "Guyana": "ガイアナ",
  "Haiti": "ハイチ",
  "Hct Out of Range": "ヘマトクリット値範囲外",
  "Hide": "非表示にする",
  "Hide password": "パスワードの非表示",
  "Holy See(Vatican City State)": "ローマ教皇庁（バチカン）",
  "Honduras": "ホンジュラス",
  "Hong Kong, SAR": "香港特別行政区",
  "Hungary": "ハンガリー",
  "Iceland": "アイスランド",
  "India": "インド",
  "Indonesia": "インドネシア",
  "Initials": "イニシャル",
  "Install": "インストール",
  "Install Now": "今すぐインストールする",
  "Instrument Not Level": "機器が水平ではありません",
  "Insufficient Sample": "検体量不足",
  "Internet connection is offline.": "インターネット接続がオフラインです。",
  "Invalid access code": "無効なアクセスコード",
  "Invalid email format": "無効なメール形式",
  "Invalid File": "無効なファイル",
  "Invalid Lot Cal File": "無効なロット較正ファイル",
  "Invalid username or password.": "無効なユーザー名またはパスワード。",
  "Iran, Islamic Republic of": "イラン・イスラム共和国",
  "Iraq": "イラク",
  "Ireland": "アイルランド共和国",
  "Israel": "イスラエル",
  "Italian": "イタリア語",
  "Italy": "イタリア",
  "Jamaica": "ジャマイカ",
  "Jan": "1月",
  "January": "1月",
  "Japan": "日本",
  "Japanese": "日本語",
  "Jordan": "ヨルダン",
  "Jul": "7月",
  "July": "7月",
  "Jun": "6月",
  "June": "6月",
  "Kazakhstan": "カザフスタン",
  "KC Swab": "KCスワブ",
  "Kenya": "ケニア",
  "Kiribati": "キリバス",
  "Korea, Democratic People's Rep": "朝鮮民主主義人民共和国",
  "Korea, Republic of(South)": "大韓民国（韓国）",
  "Kuwait": "クウェート",
  "Kyrgyzstan": "キルギスタン",
  "Language": "言語",
  "Laos(Lao PDR)": "ラオス人民民主共和国",
  "Last Name": "姓",
  "Latvia": "ラトビア",
  "Lebanon": "レバノン",
  "Lesotho": "レソト",
  "Less than __value__ in __measure__": "__measure__で__value__より小さい",
  "Liberia": "リベリア",
  "Libya": "リビア",
  "Liechtenstein": "リヒテンシュタイン",
  "Liquid Quality Control": "コントロール溶液",
  "Lithuania": "リトアニア",
  "Loading more records...": "さらに記録を読み込み中...",
  "Login": "ログイン",
  "Logout": "ログアウト",
  "Luxembourg": "ルクセンブルク",
  "Macao(SAR China)": "マカオ特別行政区（中国）",
  "Macedonia, Republic of": "マケドニア共和国",
  "Madagascar": "マダガスカル",
  "Malawi": "マラウイ",
  "Malaysia": "マレーシア",
  "Maldives": "モルジブ",
  "Mali": "マリ",
  "Malta": "マルタ",
  "Mar": "3月",
  "March": "3月",
  "Marshall Islands": "マーシャル諸島",
  "Martinique": "マルティニーク島",
  "Mauritania": "モーリタニア",
  "Mauritius": "モーリシャス",
  "MAX": "最大",
  "Maximum length is __count__ characters": "最大__count__文字まで入力できます",
  "May": "5月",
  "Mexico": "メキシコ",
  "Micronesia, Federated States o": "ミクロネシア連邦",
  "Mo": "月曜",
  "Mobile Application Version": "モバイルアプリのバージョン",
  "Moldova": "モルドバ",
  "Monaco": "モナコ",
  "Mongolia": "モンゴル国",
  "Montenegro": "モンテネグロ",
  "Montserrat": "モントセラト島",
  "Morocco and Western Sahara": "モロッコおよび西サハラ",
  "Mouth Swab": "口腔スワブ",
  "Mozambique": "モザンビーク",
  "Myanmar": "ミャンマー",
  "Namibia": "ナミビア",
  "Nasal Discharge": "鼻汁",
  "Nasal Swab": "鼻腔スワブ",
  "Nauru": "ナウル",
  "Nepal": "ネパール",
  "Netherlands": "オランダ",
  "Netherlands Antilles": "オランダ領アンティル",
  "Never Connected": "今まで接続されたことがありません",
  "New Caledonia": "仏領ニューカレドニア",
  "New Password": "新しいパスワード",
  "New Password is invalid.": "新しいパスワードが無効です。",
  "New Password is required.": "新しいパスワードが必要です。",
  "New password must match": "新しいパスワードが一致している必要があります",
  "New Version Required": "新しいバージョンが必要です",
  "New Zealand": "ニュージーランド",
  "Nicaragua": "ニカラグア",
  "Niger": "ニジェール",
  "Nigeria": "ナイジェリア",
  "Niue": "ニウエ",
  "No application modules exist in this environment. Please report this issue to an administrator.": "この環境ではアプリモジュールは存在しません。この問題を管理者に報告してください。",
  "No easily guessable words": "簡単に推測できる単語を使用しないでください",
  "No more than __value__ characters": "__value__文字以下",
  "No region set": "地域の設定なし",
  "No repetitive characters (e.g. aaa)": "同じ文字を繰り返さないでください（例：aaa）",
  "No sequential characters (e.g. 123)": "連続文字を使用しないでください（例：123）",
  "No Test Strip Detected": "テストストリップを検出できません",
  "none": "なし",
  "Norfolk Island": "ノーフォーク島",
  "Northern Mariana Islands": "北マリアナ諸島連邦",
  "Norway(inc Svalbard and Jan May": "ノルウェー（スバールバル諸島およびヤンマイエン島を含む）",
  "Norwegian": "ノルウェー語",
  "Nov": "11月",
  "November": "11月",
  "NP Aspirate": "鼻咽頭吸引液",
  "NP Swab": "鼻咽頭スワブ",
  "Oct": "10月",
  "October": "10月",
  "Offline 1 day": "オフライン1日",
  "Offline 1 hour": "オフライン1時間",
  "Offline 1 minute": "オフライン1分",
  "Offline 1 month": "オフライン1カ月",
  "Offline 1 year": "オフライン1年",
  "Offline __days__ days": "オフライン__days__日",
  "Offline __hours__ hours": "オフライン__hours__時間",
  "Offline __minutes__ minutes": "オフライン__minutes__分",
  "Offline __months__ months": "オフライン__months__カ月",
  "Offline __years__ years": "オフライン__years__年",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "オマーン",
  "Pakistan": "パキスタン",
  "Palau": "パラオ",
  "Palestinian Territory, Occupie": "パレスチナ占領地区",
  "Panama": "パナマ",
  "Papua New Guinea": "パプアニューギニア",
  "Paraguay": "パラグアイ",
  "Password": "パスワード",
  "Password requirements:": "パスワードの要件：",
  "Peru": "ペルー",
  "Philippines": "フィリピン",
  "Pitcairn": "ピトケアン",
  "Plasma": "血漿",
  "Poland": "ポーランド",
  "Portugal": "ポルトガル",
  "Portuguese (Brazil)": "ポルトガル語（ブラジル）",
  "Portuguese (Portugal)": "ポルトガル語（ポルトガル）",
  "Profile": "プロファイル",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "パスワードを設定するため以下の情報を入力してください。あなたのメールにバリデーションコードが送信されました（メールが有効な場合）。",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "パスワードのリセットのため、あなたのメールアドレスを入力してください。あなたのメールに仮バリデーションコードが送信されます。",
  "Puerto Rico": "プエルトリコ",
  "Qatar": "カタール",
  "QC Solution Detected": "コントロール溶液が検知されました",
  "Rectal Swab": "直腸スワブ",
  "Required Update": "更新が必要です",
  "Reset": "リセット",
  "Reset Password": "パスワードをリセット",
  "Reset password": "パスワードをリセット",
  "Resolution requirements": "問題解決の要件",
  "Return to Login": "ログインに戻る",
  "Romania": "ルーマニア",
  "Russian Federation": "ロシア連邦",
  "Rwanda": "ルワンダ",
  "Réunion and Mayotte": "レユニオン島およびマヨット島",
  "Sa": "土曜",
  "Saint Helena and Tristan da Cu": "セント・ヘレナ島およびトリスタン・ダ・クーニャ",
  "Saint Kitts and Nevis": "セントキッツ島およびネビス島",
  "Saint Lucia": "セントルシア",
  "Saint Pierre and Miquelon": "サンピエール島およびミクロン島",
  "Saint Vincent and the Grenadine": "セントビンセントおよびグレナディーン諸島",
  "Saliva": "唾液",
  "Samoa": "サモア島",
  "Sample Detected": "検体が検知されました",
  "Sample Error": "検体エラー",
  "Sample Type Error": "検体種エラー",
  "San Marino": "サン・マリノ",
  "Saudi Arabia": "サウジアラビア",
  "Save": "保存する",
  "Select a date": "日付を設定する",
  "Select from list...": "リストから選択...",
  "Select language": "言語を選択する",
  "Selected files must match requirements": "選択したファイルが要件に一致する必要があります",
  "Senegal": "セネガル",
  "Sep": "9月",
  "September": "9月",
  "Serbia": "セルビア",
  "Serum": "血清",
  "Server error, please try again later.": "サーバーエラー。しばらくしてから再度実行してください。",
  "Server Error. Unable to save your profile.": "サーバーエラー あなたのプロファイルを保存できません。",
  "Set Password": "パスワードの設定",
  "Seychelles": "セーシェル",
  "Show Password": "パスワードを表示",
  "Sierra Leone": "シエラ・レオネ",
  "Singapore": "シンガポール",
  "Size must be less than __maxSize__": "サイズは__maxSize__より小さい必要があります",
  "Skin Swab": "皮膚スワブ",
  "Slovakia": "スロバキア",
  "Slovenia": "スロベニア",
  "Solomon Islands": "ソロモン諸島",
  "Somalia": "ソマリア",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "申し訳ございませんが、DataViewが無効になっているか、この組織に対するアクセス権がありません",
  "South Africa": "南アフリカ",
  "Space": "空白",
  "Spain": "スペイン",
  "Spanish": "スペイン語",
  "Spanish (US)": "スペイン語（米国）",
  "Sputum": "喀痰",
  "Sri Lanka": "スリランカ",
  "Su": "日曜",
  "Sudan": "スーダン",
  "Suriname": "スリナム",
  "Swaziland": "スワジランド",
  "Sweden": "スウェーデン",
  "Swedish": "スウェーデン語",
  "Switzerland": "スイス",
  "Syrian Arab Republic(Syria)": "シリアアラブ共和国（シリア）",
  "São Tomé and Príncipe": "サントメ・プリンシペ",
  "Taiwan, Republic of China": "台湾（中華民国）",
  "Tajikistan": "タジキスタン",
  "Tanzania, United Republic of": "タンザニア連邦共和国",
  "Temperature Error": "温度エラー",
  "Test Cancelled": "測定がキャンセルされました",
  "Test Lot Not Permitted": "使用できない測定ロットです",
  "Test Operation Error": "測定作動エラー",
  "Test Strip Lot Expired": "テストストリップのロットの使用期限が過ぎています",
  "Test Strip Not Supported": "非対応のテストストリップです",
  "Test Strip Reading Error": "テストストリップ読み取りエラー",
  "Test Timeout": "測定タイムアウト",
  "Test Type Not Permitted": "使用できない測定タイプです",
  "Th": "木曜",
  "Thailand": "タイ",
  "The new password validation code will be sent to the email, if email is a valid user email.": "新しいパスワードのバリデーションコードはメールに送信されます（有効なユーザーのメールである場合）。",
  "The reset validation code was populated from the link and should not be changed.": "リセットされたバリデーションコードはリンクから入力されたもので、変更してはいけません。",
  "There are unsaved changes. How do you want to proceed?": "保存されていない変更があります。どうしますか？",
  "There is a new version available (__versionNumber__), would you like to install it now?": "新しいバージョンが利用可能です（__versionNumber__）。今すぐインストールしますか？",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "このデバイスはコネクトハブとして機能していません。このデバイスをコネクトハブとして使用し続けるには、デバイスの設定でBluetoothを再度オンにし、コネクトハブ設定画面にある　この携帯デバイスをコネクトハブとして使用する　をオンに戻します。",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "ルミラ・コネクトのこのバージョンは現在、あなたの組織には対応していません。__storeName__に移動して、最新バージョンに更新してください。",
  "Throat Swab": "咽喉スワブ",
  "Timor - Leste": "東ティモール",
  "Title": "敬称",
  "TN Swab": "喉/鼻用コンビスワブ",
  "to": "至",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "__APP_NAME__の使用を続けるには、最新バージョン（__versionNumber__）に更新してください。",
  "Togo": "トーゴ",
  "Tokelau": "トケラウ諸島",
  "Tonga": "トンガ",
  "Trinidad and Tobago": "トリニダード・トバゴ",
  "Tu": "火曜",
  "Tunisia": "チュニジア",
  "Turkey": "トルコ",
  "Turkmenistan": "トルクメニスタン",
  "Turks and Caicos Islands": "タークス・カイコス諸島",
  "Tuvalu": "ツバル",
  "Uganda": "ウガンダ",
  "Ukraine": "ウクライナ",
  "Unable to reset password, the provided current password is not valid.": "パスワードリセット不能、入力された現在のパスワードが無効です。",
  "Unable to reset your password.": "パスワードリセット不能",
  "Unable to validate access code.": "アクセスコード検証不能",
  "United Arab Emirates": "アラブ首長国連邦",
  "United Kingdom": "UK",
  "United States of America": "アメリカ合衆国",
  "Unrecognized credentials. Please try again.": "認識できないパスワード 再度実行してください。",
  "Unsaved Changes": "保存されていない変更",
  "Update Available": "利用可能な更新があります",
  "Update Password": "パスワードを更新",
  "Urethral Swab": "尿道スワブ",
  "Urine": "尿",
  "Uruguay": "ウルグアイ",
  "User account is locked. Please try again in an hour.": "ユーザーアカウントがロックされています。１時間後に再試行してください。",
  "User Interface Version": "ユーザーインターフェースのバージョン",
  "User logged out due to inactivity.": "操作がなかったため、ログアウトしました。",
  "Username": "ユーザー名",
  "Uzbekistan": "ウズベキスタン",
  "Vaginal Swab": "膣スワブ",
  "Validate Access Code": "アクセスコードの検証",
  "Validation Code": "バリデーションコード",
  "Validation Code is required.": "バリデーションコードが必要です。",
  "Vanuatu": "バヌアツ",
  "Venezuela(Bolivarian Republic o": "ベネズエラ・ボリバル共和国",
  "Venous Blood": "静脈血",
  "Version": "バージョン",
  "Viet Nam": "ベトナム",
  "Virgin Islands, British": "英領ヴァージン諸島",
  "Virgin Islands, US": "アメリカ領ヴァージン諸島",
  "VTM": "検体輸送用培地",
  "Vulvo Vaginal Swab": "外陰膣スワブ",
  "Wallis and Futuna Islands": "ワリー・エ・フトゥーナ諸島",
  "We": "水曜",
  "We are working to get you back up and running as quickly as possible.": "早急に復旧させるための作業を実行中です。",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "申し訳ございませんが、ルミラ・コネクトは現在メンテナンスのため停止中です。",
  "Yemen": "イエメン",
  "You have been signed out due to a password reset attempt.": "パスワードリセットのため、サインアウトしました。",
  "You have been signed out due to the creation of another session with the same credentials.": "同じパスワードで別のセッションを行ったため、サインアウトしました。",
  "You have been signed out due to your account being disabled by an admin.": "管理者によってアカウントが無効にされたため、サインアウトしました。",
  "You have been signed out due to your password being changed by an admin.": "管理者によってパスワードが変更されたため、サインアウトしました。",
  "You have been signed out due to your user role being changed by an admin.": "管理者によってユーザーの権限が変更されたため、サインアウトしました。",
  "You have been signed out.": "サインアウトしました。",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "あなたの組織に対応するには、 __APP_NAME__の別のバージョン（__versionNumber__）をインストールする必要があります。",
  "Your password has expired. Please set a new password.": "パスワードの期限が切れました。新しいパスワードを設定してください。",
  "Your session has expired. Please login again.": "セッションの期限が切れました。再度ログインしてください。",
  "Your session has expired. User is not authorized.": "セッションの期限が切れました。ユーザーは権限がありません。",
  "Zambia": "ザンビア",
  "Zimbabwe": "ジンバブエ"
};
