import * as React from 'react';
import { IValidationError, IBaseInputProps, IValidateOptions, PvrDirections } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';
import SelectCustomOptions from './SelectCustomOptions';
import Pvr from './Pvr';
import { alphaNumericKeyCode } from './utils';
import Keyboard from './Keyboard';

const styles = require('./styles/input.styl');

const OPTION_HEIGHT = 35;
const FILTER_HEIGHT = 45;

export interface ISelectCustomProps extends IBaseInputProps {
  validate?: (options: IValidateOptions) => IValidationError;
  selectText?: string;
  valueField: string;
  labelField: string;
  width?: number;
  height?: number;
  isFilter?: boolean;
  closeOptions: () => any;
  getOptions: (page: number, filter: string) => Promise<any>;
  pageSize?: number;
  returnFullObjects?: boolean;
  uniqueAttribute?: string;
}

interface IState {
  filter: string;
  showErrors?: boolean;
  valueHasChanged?: boolean;
  optionsOpen: boolean;
  options: Map<number, any>;
  filteredOptions: Map<number, any>;
  loading: boolean;
  optionsFullyLoaded: boolean;
}

export default class SelectCustom extends React.Component<ISelectCustomProps, IState> {
  public static defaultProps = {
    selectText: 'Select from list...',
    placeholder: 'Filter options',
    valueField: 'value',
    labelField: 'title',
    width: 250,
    height: 400,
    isFilter: true,
    nibColor: 'white',
    forceShowAllErrors: false,
    validation: false,
    returnFullObjects: false,
    pageSize: 50,
    uniqueAttribute: 'Id',
  };

  public state = {
    valueHasChanged: false,
    showErrors: false,
    optionsOpen: false,
    options: new Map(),
    filteredOptions: new Map(),
    filter: '',
    loading: true,
    optionsFullyLoaded: false,
  };

  private page: number = 0;
  private timer: any;
  private errorAnchor: HTMLElement;
  private selectEl: React.RefObject<HTMLSelectElement>;

  constructor(props) {
    super(props);
    this.selectEl = React.createRef();
  }

  public componentDidMount(): void {
    const { focusOnMount } = this.props;

    // This will recursively get pages of options until the value is found
    this.getNextOptionsPage(true);

    if (focusOnMount) { this.selectEl.current.focus(); }
  }

  public componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  public render(): JSX.Element {
    const {
      id,
      selectText,
      forceShowAllErrors,
      valueField,
      labelField,
      tabIndex,
      disabled,
      wrapperClass,
      validate,
      validation,
      flashErrors,
      placeholder,
      isFilter,
      width,
      value,
      jsonPath,
    } = this.props;
    const {
      valueHasChanged,
      showErrors,
      optionsOpen,
      options,
      filteredOptions,
      filter,
      loading,
      optionsFullyLoaded,
    } = this.state;
    const error = validate({ value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;

    let outerClass = styles.FieldWrap;
    if (wrapperClass) { outerClass += ` ${wrapperClass}`; }

    if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.Shrink} ${styles.Invalid}`; }

    const optionItems = [
      <option key="none" value="">{selectText}</option>,
    ];

    options.forEach((o, id) => {
      optionItems.push(<option key={id} value={o[valueField]}>{o[labelField]}</option>);
    });

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    return (
      <div className={outerClass}>
        <select
          key="select"
          ref={this.selectEl}
          tabIndex={tabIndex}
          id={id}
          disabled={disabled}
          value={value}
          data-test={jsonPath}
          onChange={() => null} // So React doesn't complain about value with no onChange handler
          onMouseDown={this.handleNativeEvent}
          onKeyDown={this.handleNativeEvent}
          onTouchStart={this.handleErrorTouch}
        >{optionItems}</select>
        <div
          className={styles.FieldErrorsShow}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
          onTouchStart={this.handleErrorTouch}
        />
        {errorPvr}
        {optionsOpen ? (
          <Pvr
            direction={PvrDirections.AUTO}
            width={width}
            height={this.calcHeight()}
            anchor={this.selectEl.current}
            animateIn={false}
            close={this.closeOptions}
          >
            <SelectCustomOptions
              options={options}
              filteredOptions={filteredOptions}
              onChange={this.handleValueChange}
              labelField={labelField}
              valueField={valueField}
              placeholder={placeholder}
              value={value}
              SelectEl={this.selectEl.current}
              isFilter={isFilter}
              searchWidth={width - 10}
              optionHeight={OPTION_HEIGHT}
              filter={filter}
              onChangeFilter={this.handleFilterChange}
              closeOptions={this.closeOptions}
              loading={loading}
              getNextOptionsPage={this.getNextOptionsPage}
              optionsFullyLoaded={optionsFullyLoaded}
            />
          </Pvr>
        ) : null}
      </div>
    );
  }

  public openOptionsList() {
    this.setState({ optionsOpen: true });
  }

  public closeOptions = (): void => {
    this.setState({ optionsOpen: false });

    // Focus when closing unless in the mobile app
    // Focusing on mobile forces the native select drop down open
    if (!process.env.MOBILE_APP) {
      this.selectEl.current?.focus();
    }
  };

  public handleFilterChange = (filter: string): void => {
    const { getOptions, uniqueAttribute } = this.props;
    const { options } = this.state;

    if (filter === '') {
      this.setState({
        filter,
        filteredOptions: new Map(),
      });
    } else {
      this.setState({
        filter,
        loading: true,
      });

      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        getOptions(1, filter)
          .then((filteredOptions) => {
            const newFilteredOptions = new Map();

            for (let i = 0; i < filteredOptions.length; i++) {
              const opt = filteredOptions[i];
              const optId = opt[uniqueAttribute];

              newFilteredOptions.set(optId, opt);

              // Filtered options need to be added to the master list if they don't already exists there
              if (!options.has(optId)) {
                options.set(optId, opt);
              }
            }

            this.setState({
              filteredOptions: newFilteredOptions,
              options: this.optionSorter(options),
              loading: false,
            });
          });
      }, 200);

    }
  };

  public getNextOptionsPage = (waitForValue = false): void => {
    const { getOptions, pageSize, uniqueAttribute, value, valueField } = this.props;
    const { filter, options } = this.state;

    this.page = this.page + 1;

    getOptions(this.page, filter)
      .then((newOfPageOptions) => {
        let valueFound = false;
        // Add any new options to the master list if they aren't already there
        for (let i = 0; i < newOfPageOptions.length; i++) {
          const opt = newOfPageOptions[i];
          if (opt[valueField] === value) { valueFound = true; }
          if (!options.has(opt[uniqueAttribute])) {
            options.set(opt[uniqueAttribute], opt);
          }
        }

        const optionsFullyLoaded = newOfPageOptions.length < pageSize;

        this.setState({
          options: this.optionSorter(options),
          optionsFullyLoaded,
          loading: false,
        }, () => {
          // Recursively get next option page until current value is found
          if (waitForValue && value && !optionsFullyLoaded && !valueFound) {
            this.getNextOptionsPage(true);
          }
        });

      });

  };

  public handleNativeEvent = (e): void => {
    const { keyCode } = e;
    const openKeys = [Keyboard.DOWN_ARROW, Keyboard.RIGHT_ARROW, Keyboard.LEFT_ARROW, Keyboard.UP_ARROW, Keyboard.ENTER, Keyboard.SPACE];
    const closeKeys = [Keyboard.ESCAPE, Keyboard.TAB];

    // Handle the keyboard
    if (keyCode != null) {
      const isOpenKey = openKeys.indexOf(keyCode) > -1;
      const isCloseKey = closeKeys.indexOf(keyCode) > -1;
      const alphaNum = alphaNumericKeyCode(keyCode);

      if (isOpenKey || alphaNum) {
        // let filter;
        e.preventDefault();
        e.stopPropagation();

        // For alphanumeric input, start filtering the element
        if (alphaNum) {
          this.setState({ filter: e.key });
        }

        this.openOptionsList();

      } else if (isCloseKey) {
        if (keyCode !== Keyboard.TAB) { e.preventDefault(); }
        this.closeOptions();
      }

      return;
    }

    // Handle mouse clicks
    e.preventDefault();

    this.openOptionsList();

  };

  // Note: using slightly non-standard names for the next 2 methods in order to not conflict with the mixin
  public handleValueChange = (value): void => {
    const { onChange, jsonPath, returnFullObjects, valueField } = this.props;

    if (returnFullObjects) {
      onChange(value, jsonPath);
    } else {
      onChange(value[valueField], jsonPath);
    }
    this.closeOptions();
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

  public getValue(): any {
    const { value } = this.props;
    return value;
  }

  private calcHeight = (): number => {
    const { options, filteredOptions, filter } = this.state;
    const { height } = this.props;

    const relevantMap = filter !== '' ? filteredOptions : options;

    if (relevantMap.size === 0) {
      return 100 + FILTER_HEIGHT;
    }

    const listHeight = (relevantMap.size * OPTION_HEIGHT) + FILTER_HEIGHT + 5;

    const benchmark = Math.min(height, this.calculateSpaceBelow());

    return listHeight > benchmark ? benchmark : listHeight;
  };

  private calculateSpaceBelow = (): number => {
    const rect = this.selectEl.current?.getBoundingClientRect();

    const spaceBelow = window.innerHeight - rect?.bottom;

    return spaceBelow - 30;
  };

  private optionSorter = (options: Map<number, any>): Map<number, any> => {
    const { labelField } = this.props;
    return new Map( Array.from( options.entries() ).sort(([, aOpt], [, bOpt]) => aOpt[labelField].toLowerCase() > bOpt[labelField].toLowerCase() ? 1 : -1) );
  };

}
