import * as React from 'react';
import TextInput from './TextInput';
import SelectInput from './SelectInput';
import Textarea from './TextareaInput';
import SelectCustomInput from './SelectCustomInput';
import MultiSelectInput from './MultiSelectInput';
import MultiSelectInputScroll from './MultiSelectInputScroll';
import TypeAheadInput from './TypeAheadInput';
import { ITextProps } from './Text';
import { IKeyboard } from './interfaces';
import { ISelectProps } from './Select';
import { ITextareaProps } from './Textarea';
import { ISelectCustomProps } from './SelectCustom';
import { IMultiSelectProps } from './MultiSelect';
import { ITypeAheadProps } from './TypeAhead';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
let labelFlexBasisDefault;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/grid.styl');
    labelFlexBasisDefault = '165px';
    break;
  default:
    styles = require('./styles/grid.styl');
    labelFlexBasisDefault = '130px';
    break;
}
export interface IGridFormInputProps extends Partial<ISelectCustomProps>, Partial<ITextareaProps>, Partial<ISelectProps>, Partial<ITextProps>, Partial<IMultiSelectProps>, Partial<ITypeAheadProps> {
  /**
   * @param inputType InputTypes enum value that determines which control goes into the grid. Default to TEXT
   */
  inputType?: InputTypes;
  /**
   * @param formLabel When passed, creates a cell for the label and uses this text
   */
  formLabel?: string;
  /**
   * @param className Class applied to the grid row. Defaults to 'form-grid'
   */
  className?: string;
  /**
   * @param labelOnTop Determines wether the label shall sit on top of teh input. Defaults to false
   */
  labelOnTop?: boolean;
  /**
   * @param labelColumnClass Class applied to label cell. Defaults to 'label'
   */
  labelColumnClass?: string;
  /**
   * @param labelClassName Style applied to the label to determine its size.
   */
  labelClassName?: string;
  /**
   * @param labelFlexBasis Style applied to the row label to determine its size.
   */
  labelFlexBasis?: string;
  /**
   * @param inputColumnClass Class applied to input cell. Defaults to 'InputControl'
   */
  inputColumnClass?: string;
  /**
   * @param isFieldRequired Shows red asterisk if true. Note: does NOT actually apply any validation to the field.
   */
  isFieldRequired?: boolean;
  /**
   * @param fullRow Determines whether to display the input in a full row with or without a label. Set to false when GridFormInput is a child of another GridFormInput.
   */
  fullRow?: boolean;
  innerRef?: (el: HTMLElement) => void;
  keyboard?: IKeyboard;
  resetPassToggle?: boolean;
  search?: boolean;
  ariaLabel?: string;
}

export enum InputTypes {
  TEXT,
  SELECT,
  TEXTAREA,
  MULTISELECT,
  MULTISELECTSCROLL,
  SELECT_CUSTOM,
  TYPEAHEAD,
  DATE,
  SELECT_PAGING,
}

export default class GridFormInput extends React.Component<IGridFormInputProps, any> {

  public static defaultProps = {
    inputType: InputTypes.TEXT,
    labelColumnClass: styles.Label,
    labelFlexBasis: labelFlexBasisDefault,
    inputColumnClass: styles.InputControl,
    className: styles.FormGrid,
    fullRow: true,
    formLabel: null,
    isFieldRequired: false,
    keyboard: null,
    labelOnTop: false,
    ariaLabel: null,
  };

  public render(): JSX.Element {
    let content;
    let labelField;
    const { labelOnTop, inputType, formLabel, className, labelColumnClass, labelClassName, labelFlexBasis, inputColumnClass, isFieldRequired, fullRow, children, value, options, innerRef, id, jsonPath } = this.props;

    if (formLabel != null) {
      let labelClass = labelClassName != null ? labelClassName : styles.FormLabel;
      if (isFieldRequired) { labelClass += ` ${styles.IsRequired}`; }

      labelField = (
        <div key="labelField" className={labelColumnClass} style={{ flexBasis: labelOnTop ? '' : labelFlexBasis }}>
          <label className={labelClass} htmlFor={id || jsonPath}>{formLabel}</label>
        </div>
      );
    }

    // Clone the props and add a ref
    // Ensure a value is always passed
    // Ensure options is always passed for the select types
    // Do not pass the className down as it will mess up the styles
    const additionalProps = {
      value: value != null ? value : '',
      options: null,
    };

    if ([InputTypes.SELECT, InputTypes.SELECT_CUSTOM, InputTypes.MULTISELECT, InputTypes.MULTISELECTSCROLL, InputTypes.TYPEAHEAD].includes(inputType)) { additionalProps.options = options || []; }

    const inputProps = {...this.props, ...additionalProps} as any;
    delete inputProps.className;

    const input = (() => {
      switch (inputType) {
        case InputTypes.TEXTAREA: return <Textarea {...inputProps} />;
        case InputTypes.SELECT: return <SelectInput {...inputProps} />;
        case InputTypes.SELECT_CUSTOM: return <SelectCustomInput {...inputProps} />;
        case InputTypes.MULTISELECT: return <MultiSelectInput {...inputProps} />;
        case InputTypes.MULTISELECTSCROLL: return <MultiSelectInputScroll {...inputProps} />;
        case InputTypes.TYPEAHEAD: return <TypeAheadInput {...inputProps} />;
        case InputTypes.DATE: return <input type="date" {...inputProps} />;
        default: return <TextInput {...inputProps} />;
      }
    })();

    const inputCell = <div key="inputCell" ref={innerRef} className={inputColumnClass}>{input}</div>;

    // This is a full row of a form with or without a label
    if (fullRow) {
      content = [
        (formLabel != null) ? labelField : null,
        inputCell,
      ].concat(children);

      return <div className={`${className} ${labelOnTop ? styles.LabelOnTop : ''}`}>{content}</div>;
    }
    // This is a single cell within a row
    else {
      return inputCell;
    }
  }
}
