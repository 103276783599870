import * as React from 'react';

const styles = require('./styles/grid.styl');

export enum ButtonPosition {
  START,
  END
}

export interface IGridFormAddButtonProps {
  // Provided by Parent Input Component
  handleClick: (e) => void;
  formLabel?: string;
  labelColumnClass?: string;
  inputColumnClass?: string;
  className?: string;
  title?: string;
  type?: string;
  disabled?: boolean;
  tabIndex?: number;
  flexBasis?: string;
  buttonPosition?: ButtonPosition;
  btnBorder?: boolean;
  bottomBorder?: boolean;
  largeLabel?: boolean;
}

export default class GridFormAddButton extends React.Component<IGridFormAddButtonProps, any> {
 
  public static defaultProps = {
    labelColumnClass: styles.Label,
    inputColumnClass: null,
    className: styles.FormGrid,
    type: 'plus',
    tabIndex: -1,
    disabled: false,
    title: null,
    flexBasis: '130px',
    buttonPosition: ButtonPosition.START,
    btnBorder: false,
    bottomBorder: false,
    largeLabel: false,
  };

  public render(): JSX.Element {
    const { handleClick, formLabel, tabIndex, labelColumnClass, className, type, title, disabled, flexBasis, buttonPosition, btnBorder, bottomBorder, largeLabel } = this.props;
    const sign = (type === 'plus') ? styles.plus : styles.minus;
    let btnClass = `${styles.BtnPlusMinus} ${ sign } ${styles.large}`;
    btnClass += btnBorder ? '' : ` ${styles.noBorder}`;
    const btn = <button tabIndex={tabIndex} className={btnClass} title={title} disabled={disabled} onClick={handleClick} />;

    const rowClass = bottomBorder ? `${className} ${styles.Borders}` : className;
    const labelClass = largeLabel ? `${styles.FormLabel} ${styles.Large}` : `${styles.FormLabel}`;

    if (formLabel != null) {
      return (
        <div className={rowClass}>
          <div className={labelColumnClass} style={{flexBasis}}>
            <label className={labelClass}>{formLabel}</label>
          </div>
          <div className={styles.InputControl} style={{ display: 'flex', justifyContent: `${buttonPosition === ButtonPosition.START ? 'flex-start' : 'flex-end'}` }}>
            {btn}
          </div>
        </div>
      );
    }
    else {
      return (
        <div>{btn}</div>
      );
    }    
  }
}
