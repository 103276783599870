import * as React from 'react';
import { IBaseInputProps } from './interfaces';

const styles = require('./styles/type_ahead.styl');

export interface ITypeAheadOptionItemProps extends IBaseInputProps {
  index: number;
  focusOption: any;
  option?: any;
  isSelected?: boolean;
  isFocused: boolean;
  labelField?: string;
  onSelect: (option: any) => any;
}

export default class TypeAheadOptionItem extends React.Component<ITypeAheadOptionItemProps, any> {
   
  public render() {
    const {option, labelField, isFocused} = this.props;

    const label = option[labelField] || option;
    const className = (isFocused) ? `${styles.Focused}` : '';

    return (
      <li
        data-test={label}
        className={className} 
        title={label}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
      >
        {label}
      </li>
    );
  }
  
  private handleClick = (): void => {
    const {option, onSelect} = this.props;
    onSelect(option); 
  };

  private handleMouseOver = (): void => {
    const {index, focusOption} = this.props;
    focusOption(index);
  };
}
