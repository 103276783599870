import * as React from 'react';
import * as ReactSpinner from 'react-spinkit';

const styles = require('./styles/spinner.styl');

export enum SpinnerNames {
  Circle = 'circle',
  CubeGrid = 'cube-grid',
  Wave = 'wave',
  FoldingCube = 'folding-cube',
  ThreeBounce = 'three-bounce',
  DoubleBounce = 'double-bounce',
  WanderingCubes = 'wandering-cubes',
  ChasingDots = 'chasing-dots',
  RotatingPlane = 'rotating-plane',
  Pulse = 'pulse',
  Wordpress = 'wordpress',
  BallGridBeat = 'ball-grid-beat',
  BallGridPulse = 'ball-grid-pulse',
  LineSpinFadeLoader = 'line-spin-fade-loader',
  BallSpinFadeLoader = 'ball-spin-fade-loader',
  BallPulseRise = 'ball-pulse-rise',
  LineScale = 'line-scale',
  LineScalePulseOut = 'line-scale-pulse-out',
  LineScalePulseOutRapid = 'line-scale-pulse-out-rapid',
  Pacman = 'pacman',
  LineScaleParty = 'line-scale-party',
  BallTrianglePath = 'ball-triangle-path',
  BallScaleMultiple = 'ball-scale-multiple',
  BallScaleRippleMultiple = 'ball-scale-ripple-multiple',
  BallPulseSync = 'ball-pulse-sync',
  BallBeat = 'ball-beat',
  BallZigZag = 'ball-zig-zag',
  BallZigZagDeflect = 'ball-zig-zag-deflect',
  BallClipRotatePulse = 'ball-clip-rotate-pulse',
  BallClipRotateMuliple = 'ball-clip-rotate-multiple',
  BallClipRotate = 'ball-clip-rotate',
  BallScaleRipple = 'ball-scale-ripple',
  TriangleSkewSpin = 'triangle-skew-spin'
}

export interface ISpinnerProps {
  message?: string | JSX.Element;
  color?: string;
  name?: SpinnerNames;
  fadeIn?: 'none' | 'quarter' | 'half';
  wrapperClass?: string;
  messageClass?: string;
}

export default class Spinner extends React.Component<ISpinnerProps, any> {
  public static defaultProps: ISpinnerProps = {
    name: SpinnerNames.LineSpinFadeLoader,
    wrapperClass: '',
    messageClass: '',
    color: '#000000',
    fadeIn: 'quarter',
  };

  public render(): JSX.Element {
    const { message, color, name, wrapperClass, messageClass } = this.props;
    return (
      <div className={`${styles.SpinnerWrapper} ${wrapperClass}`}>
        <ReactSpinner name={name} className={styles.Spinner} color={color} fadeIn="quarter" />
        {(message != null && message !== '') ? <div className={`${styles.Message} ${messageClass}`}>{message}</div> : null}
      </div>
    );
  }

}
