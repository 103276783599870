import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlertModal } from '@Lineup/index';

import { IComponent, InjectedComponent } from '../../global/interfaces';
import { BridgeCommands, OSVersions } from '../../global/enums';

@inject('mobileBridgeStore') @observer
class ForceAppUpdate extends InjectedComponent<IComponent, any> {

  public render(): JSX.Element {
    const { nativeAppIsCompatible, os, uiUpdateAlertOpen } = this.props.mobileBridgeStore;

    return !nativeAppIsCompatible && !uiUpdateAlertOpen ? (
      <AlertModal
        close={this.navToAppStore}
        alertTitle={t('Required Update')}
        message={t('This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.', { storeName: os === OSVersions.ANDROID ? 'Google Play Store' : 'App Store' })}
        cancelText={t('Go To __storeName__', { storeName: os === OSVersions.ANDROID ? 'Google Play Store' : 'App Store' })}
      />
    ) : null;
  }

  public navToAppStore = (): void => {
    const { tellMobileApp, os } = this.props.mobileBridgeStore;
    tellMobileApp(BridgeCommands.OPEN_EXTERNAL_URL, os === OSVersions.ANDROID ? 'https://play.google.com/store/apps/details?id=com.lumiradx.connect' : 'https://apps.apple.com/us/app/lumiradx-connect/id1423794466');
  };

}

export default ForceAppUpdate;
