import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { LoggedInUser } from '../../stores';
import { TextInput, ConfirmSave } from '@Lineup/index';
import { SaveStates } from '@Lineup/enums/index';
import PasswordRules from './PasswordRules';
import { IComponent } from '../../global/interfaces';
import FormErrorTracker from '../../helpers/ErrorTracker';

const styles = require('./styles/AccountResetPassword.styl');

interface IProps extends IComponent {
  loggedInUserStore?: LoggedInUser;
  navToApp: () => void;
}

interface IState {
  saveState?: SaveStates;
  saveMessage?: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
  newPassIsFocused: boolean;
  rulesPvrOpen: boolean;
  errorMessage: string;
}

@inject('loggedInUserStore') @observer
export default class AccountExpiredPasswordReset extends React.Component<IProps, IState> {
  private menuAnchor: HTMLElement;
  private errorTracker: FormErrorTracker = new FormErrorTracker();
  private lastReqTimer: any;

  public state: IState = {
    forceShowAllErrors: false,
    flashErrorPvrs: 0,
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: '',
    rulesPvrOpen: false,
    newPassIsFocused: false,
    saveState: null,
    saveMessage: null,
    errorMessage: null,
  };

  public componentWillUnmount() {
    const { resetPasswordRules } = this.props.loggedInUserStore;
    resetPasswordRules();
  }

  public render(): JSX.Element {
    const { loggedInUserStore, navToApp } = this.props;
    const { isValidPassword, ruleResults } = loggedInUserStore;
    const { currentPassword, newPassword, newPasswordConfirm, rulesPvrOpen, forceShowAllErrors, flashErrorPvrs, saveState, saveMessage } = this.state;
    const { registerError } = this.errorTracker;

    return (
      <div className={`${styles.Body}`}>
        <h3>
          {t('Your password has expired. Please set a new password.')}
        </h3>
        {(saveState !== null) ?
          <ConfirmSave
            dismissBtnText={t('Dismiss')}
            saveState={saveState}
            saveMessage={saveMessage}
            done={navToApp}
            fail={() => {
              this.props.loggedInUserStore.setLoginMessage(t('Unable to reset password, the provided current password is not valid.'));
              this.setState({
                saveState: null,
              });
            }}
          /> : null
        }
        <form>

          {(!isValidPassword && rulesPvrOpen) ?
            <PasswordRules ruleResults={ruleResults} close={this.handleRulesPvr} anchor={this.menuAnchor} /> : null
          }
          <div key="newPassword" ref={(el: HTMLElement) => this.menuAnchor = el}>
            <TextInput
              id="currentPassword"
              data-test="currentPassword"
              type="password"
              placeholder={t('Current Password')}
              jsonPath="currentPassword"
              value={currentPassword}
              wrapperClass={styles.TextInput}
              onChange={(value: string) => this.handleChange(value, 'currentPassword')}
              registerError={registerError}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              autoComplete="current-password"
              autoCapitalize="off"
              validation={(value: string) => {
                const messages = [];
                if (!value.length) { messages.push(t('Current Password is required.')); }
                return messages.length ? { messages } : null;
              }}
            />
            <TextInput
              id="newPassword"
              data-test="newPassword"
              type="password"
              placeholder={t('New Password')}
              jsonPath="newPassword"
              value={newPassword}
              wrapperClass={styles.TextInput}
              onChange={(value: string) => this.handleChange(value, 'newPassword')}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              registerError={registerError}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              autoComplete="new-password"
              autoCapitalize="off"
              validation={(function() {
                const messages = [];
                if (!newPassword.length) { messages.push(t('New Password is required.')); }
                else if (!isValidPassword) { messages.push(t('New Password is invalid.')); }
                return messages.length ? { messages } : null;
              })()}

            />
          </div>

          <TextInput
            id="newPasswordConfirm"
            data-test="newPasswordConfirm"
            key="newPasswordConfirm"
            type="password"
            placeholder={t('Confirm New Password')}
            jsonPath="newPasswordConfirm"
            value={newPasswordConfirm}
            wrapperClass={styles.TextInput}
            onChange={(value: string) => this.handleChange(value, 'newPasswordConfirm')}
            registerError={registerError}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            autoComplete="new-password"
            autoCapitalize="off"
            validation={(function() {
              const messages = [];
              if (!newPasswordConfirm.length) { messages.push(t('Confirm Password is required.')); }
              if (newPasswordConfirm !== newPassword) { messages.push(t('Confirm Password must match New Password')); }
              return messages.length ? { messages } : null;
            })()}
          />

          <div key="set">
            <button
              id="set-password-button"
              data-test="setPasswordButton"
              className={styles.Btn}
              onClick={this.handlePasswordUpdate}
            >
              {t('Set Password')}
            </button>
            <br/>
            <button
              className={styles.Btn}
              onClick={this.returnToLogin}
              data-test="return-to-login"
            >
              {t('Return to Login')}
            </button>
          </div>
        </form>
      </div>
    );
  }

  public handleChange = (value: string, property: string): void => {
    // If new password and password has length check to see if it is valid
    if (property === 'newPassword') {
      this.handlePasswordValidation(value);
    }

    this.setState({
      [property]: value,
    } as any);
  };

  public handlePasswordValidation = (password: string): void => {
    const { loggedInUserStore } = this.props;
    const { getValidatePassword, user: { username, email, NoEmailAccess } } = loggedInUserStore;

    if (this.lastReqTimer) {
      clearInterval(this.lastReqTimer);
    }

    this.lastReqTimer = setTimeout(() => {
      getValidatePassword(password, email, username, NoEmailAccess).then((isValid) => {
        const { newPassIsFocused } = this.state;
        if (newPassIsFocused) {
          this.handleRulesPvr(!isValid);
        }
      });
    }, 200);

  };

  public handlePasswordUpdate = (e: React.MouseEvent): void => {
    e.preventDefault();

    const { loggedInUserStore, history } = this.props;
    const { updateUserPassword, resetRequiredPasswordChange, setLoginMessage } = loggedInUserStore;
    const { newPassword, newPasswordConfirm, currentPassword } = this.state;
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    this.setState({
      saveState: SaveStates.PENDING,
    });

    updateUserPassword(currentPassword, newPasswordConfirm, newPassword)
      .catch(err => {
        this.setState({
          saveState: SaveStates.FAILED,
        });
        return Promise.reject(err);
      })
      .then(() => {
        // if password is updated
        this.setState({
          saveState: SaveStates.COMPLETE,
        }, () => {
          resetRequiredPasswordChange();
          setLoginMessage(null);
          history.replace('/home');
        });
      });
  };

  public handleRulesPvr = (rulesPvrOpen: boolean): void => {
    const { resetPasswordRules } = this.props.loggedInUserStore;
    const { newPassword } = this.state;

    if (!rulesPvrOpen && newPassword === '') {
      resetPasswordRules();
    }

    this.setState({ rulesPvrOpen });
  };

  public handleFocus = (): void => {
    this.setState({ newPassIsFocused: true });
    this.onFocus();
  };

  public handleBlur = (): void => {
    this.setState({
      newPassIsFocused: false,
      rulesPvrOpen: false,
    });
  };

  public onFocus = (): void => {
    const { isValidPassword, resetPasswordRules } = this.props.loggedInUserStore;
    const { newPassword } = this.state;

    if (newPassword === '') {
      resetPasswordRules();
    }
    else {
      this.handlePasswordValidation(newPassword);
    }

    if (!isValidPassword) { this.handleRulesPvr(true); }
  };

  public returnToLogin = (): void => {
    this.props.history.replace('/login?force=true');
  };

}
