module.exports = {
  "__appName__ is not currently available.": "__appName__ no está disponible actualmente.",
  "Access Code": "Código de acceso",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "La cuenta ${this.username} se ha bloqueado tras 5 intentos de conexión. Contacte con su administrador para desbloquearla",
  "Afghanistan": "Afganistán",
  "Albania": "Albania",
  "Algeria": "Argelia",
  "American Samoa": "American Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguila",
  "Antigua and Barbuda": "Antigua y Barbuda",
  "App": "Aplicación",
  "Application Error": "Error de la aplicación",
  "Application error.": "Error de la aplicación.",
  "Application is not currently available.": "La aplicación no está disponible actualmente.",
  "Apply Changes": "Aplicar cambios",
  "Apr": "Abr",
  "April": "Abril",
  "Argentina": "Argentina",
  "Armenia": "Armenia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Al menos __value__ caracteres",
  "At least one lowercase letter": "Al menos una letra minúscula",
  "At least one number": "Al menos un número",
  "At least one of these special characters: __value__": "Al menos uno de estos caracteres especiales: __value__",
  "At least one uppercase letter": "Al menos una letra mayúscula",
  "Aug": "Ago",
  "August": "Agosto",
  "Australia": "Australia",
  "Austria": "Austria",
  "Azerbaijan": "Azerbaiyán",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahréin",
  "Bangladesh": "Bangladés",
  "Barbados": "Barbados",
  "Belarus": "Bielorrusia",
  "Belgium": "Bélgica",
  "Belize": "Belice",
  "Benin": "Benín",
  "Bermuda": "Bermudas",
  "Bhutan": "Bután",
  "Bluetooth is Turned Off": "Bluetooth desactivado",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnia y Herzegovina",
  "Botswana": "Botsuana",
  "Brazil": "Brasil",
  "Bronchial Lavage": "Lavado bronquial",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Camboya",
  "Cameroon": "Camerún",
  "Canada": "Canadá",
  "Cancel": "Cancelar",
  "Cannot be empty": "No puede estar vacío",
  "Cape Verde": "Cabo Verde",
  "Capillary Blood": "Sangre capilar",
  "Cayman Islands": "Islas Caimán",
  "Central African Republic": "República Centroafricana",
  "Cerebrospinal Fluid": "Líq cefalorraquídeo",
  "Cervical Swab": "Hisopo cervical",
  "Chad": "Chad",
  "Change Access Code": "Cambiar código de acceso",
  "Check again": "Comprobar de nuevo",
  "Chile": "Chile",
  "China": "China",
  "Clear Search": "Borrar búsqueda",
  "Close": "Cerrar",
  "Cocos(Keeling) Islands and Chr": "Islas Cocos (Keeling) e Isla de Navidad",
  "Colombia": "Colombia",
  "Comoros": "Comoras",
  "Confirm": "Confirmar",
  "Confirm New Password": "Confirmar nueva contraseña",
  "Confirm new password": "Confirmar nueva contraseña",
  "Confirm New Password is required.": "Se requiere confirmar nueva contraseña.",
  "Confirm Password is required.": "Se requiere confirmar contraseña.",
  "Confirm Password must match New Password": "La contraseña de confirmación debe coincidir con la nueva contraseña",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Islas Cook",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__: LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__: LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Costa de Marfil",
  "Croatia(Hrvatska)": "Croacia",
  "Cuba": "Cuba",
  "Culture Broth": "Caldo de cultivo",
  "Current Password": "Contraseña actual",
  "Current Password is required.": "Se requiere contraseña actual.",
  "Current password required": "Se requiere la contraseña actual",
  "Cyprus": "Chipre",
  "Czech Republic": "República Checa",
  "Danish": "Danés",
  "Dec": "Dic",
  "December": "Diciembre",
  "Denmark": "Dinamarca",
  "Disabled": "Deshabilitado",
  "Discard Changes": "Descartar cambios",
  "Dismiss": "Descartar",
  "Djibouti": "Yibuti",
  "Does not match your previous passwords": "No coincide con ninguna de sus contraseñas anteriores",
  "Does not use unsupported characters": "No utiliza caracteres no permitidos",
  "Domain": "Dominio",
  "Domain is required.": "Se requiere el dominio.",
  "Dominica": "Dominica",
  "Dominican Republic": "República Dominicana",
  "Door Open": "Puerta abierta",
  "Dutch": "Holandés",
  "Ecuador": "Ecuador",
  "Egypt": "Egipto",
  "El Salvador": "El Salvador",
  "Email": "Correo electrónico",
  "Email is required.": "Se requiere correo electrónico.",
  "English (UK)": "Inglés (Reino Unido)",
  "English (US)": "Inglés (EE. UU.)",
  "Enter": "Introducir",
  "Equatorial Guinea": "Guinea Ecuatorial",
  "Eritrea": "Eritrea",
  "Error": "Error",
  "Estonia": "Estonia",
  "Ethiopia": "Etiopía",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager no está incluido en la aplicación móvil Connect.",
  "Failed to reset password. A server error was encountered": "No se pudo restablecer la contraseña. Se encontró un error del servidor",
  "Falkland Islands(Malvinas)": "Islas Malvinas (Falkland)",
  "Faroe Islands": "Islas Feroe",
  "Fatal Application Error": "Error irrecuperable de la aplicación",
  "Feb": "Feb",
  "February": "Febrero",
  "Fecal Specimen": "Muestra fecal",
  "Fiji": "Fiyi",
  "File type must be __fileType__": "El tipo de archivo debe ser __fileType__",
  "Finland": "Finlandia",
  "Finnish": "Finés",
  "First Name": "Nombre",
  "Forgot your password?": "¿Olvidó su contraseña?",
  "Fr": "Vi",
  "France": "Francia",
  "French": "Francés",
  "French Guiana": "Guayana Francesa",
  "French Polynesia": "Polinesia Francesa",
  "Gabon": "Gabón",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "Alemán",
  "Germany": "Alemania",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Ir a __storeName__",
  "Greater than __value__ in __measure__": "Mayor que __value__ en __measure__",
  "Greece": "Grecia",
  "Greenland": "Groenlandia",
  "Grenada": "Granada",
  "Guadeloupe": "Guadalupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haití",
  "Hct Out of Range": "Hct fuera de rango",
  "Hide": "Ocultar",
  "Hide password": "Ocultar contraseña",
  "Holy See(Vatican City State)": "Santa Sede (Estado de la Ciudad del Vaticano)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, RAE",
  "Hungary": "Hungría",
  "Iceland": "Islandia",
  "India": "India",
  "Indonesia": "Indonesia",
  "Initials": "Iniciales",
  "Install": "Instalar",
  "Install Now": "Instalar ahora",
  "Instrument Not Level": "Instrument no nivelado",
  "Insufficient Sample": "Muestra insuficiente",
  "Internet connection is offline.": "No está conectado a Internet.",
  "Invalid access code": "Código de acceso no válido",
  "Invalid email format": "Formato de correo electrónico no válido",
  "Invalid File": "Archivo no válido",
  "Invalid Lot Cal File": "Archivo calibrac lote no válido",
  "Invalid username or password.": "Nombre de usuario o contraseña no válidos.",
  "Iran, Islamic Republic of": "Irán, República Islámica de",
  "Iraq": "Irak",
  "Ireland": "Irlanda",
  "Israel": "Israel",
  "Italian": "Italiano",
  "Italy": "Italia",
  "Jamaica": "Jamaica",
  "Jan": "Ene",
  "January": "Enero",
  "Japan": "Japón",
  "Japanese": "Japonés",
  "Jordan": "Jordania",
  "Jul": "Jul",
  "July": "Julio",
  "Jun": "Jun",
  "June": "Junio",
  "Kazakhstan": "Kazajistán",
  "KC Swab": "Hisopo KC",
  "Kenya": "Kenia",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Corea, República Popular Democrática de",
  "Korea, Republic of(South)": "Corea, República de (Sur)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirguizistán",
  "Language": "Idioma",
  "Laos(Lao PDR)": "Laos (RDP de Laos)",
  "Last Name": "Apellidos",
  "Latvia": "Letonia",
  "Lebanon": "Líbano",
  "Lesotho": "Lesoto",
  "Less than __value__ in __measure__": "Menor que __value__ en __measure__",
  "Liberia": "Liberia",
  "Libya": "Libia",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Ctrl Calidad líquido",
  "Lithuania": "Lituania",
  "Loading more records...": "Cargando más registros…",
  "Login": "Conectar",
  "Logout": "Desconectar",
  "Luxembourg": "Luxemburgo",
  "Macao(SAR China)": "Macao (RAE China)",
  "Macedonia, Republic of": "Macedonia, República de",
  "Madagascar": "Madagascar",
  "Malawi": "Malaui",
  "Malaysia": "Malasia",
  "Maldives": "Maldivas",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Marzo",
  "Marshall Islands": "Islas Marshall",
  "Martinique": "Martinica",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauricio",
  "MAX": "MÁX.",
  "Maximum length is __count__ characters": "La longitud máxima es de __count__ caracteres",
  "May": "May",
  "Mexico": "México",
  "Micronesia, Federated States o": "Micronesia, Estados Federados de",
  "Mo": "Lu",
  "Mobile Application Version": "Versión de aplicación móvil",
  "Moldova": "Moldavia",
  "Monaco": "Mónaco",
  "Mongolia": "Mongolia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marruecos y Sáhara Occidental",
  "Mouth Swab": "Muestra bucal",
  "Mozambique": "Mozambique",
  "Myanmar": "Birmania",
  "Namibia": "Namibia",
  "Nasal Discharge": "Secreción nasal",
  "Nasal Swab": "Muestra nasal",
  "Nauru": "Naurú",
  "Nepal": "Nepal",
  "Netherlands": "Países Bajos",
  "Netherlands Antilles": "Antillas Neerlandesas",
  "Never Connected": "Nunca conectado",
  "New Caledonia": "Nueva Caledonia",
  "New Password": "Nueva contraseña",
  "New Password is invalid.": "La nueva contraseña no es válida.",
  "New Password is required.": "Se requiere nueva contraseña.",
  "New password must match": "La nueva contraseña debe coincidir",
  "New Version Required": "Se requiere nueva versión",
  "New Zealand": "Nueva Zelanda",
  "Nicaragua": "Nicaragua",
  "Niger": "Níger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "No existen módulos de aplicación en este entorno. Informe de este problema a un administrador.",
  "No easily guessable words": "No hay palabras que puedan adivinarse fácilmente",
  "No more than __value__ characters": "No más de __value__ caracteres",
  "No region set": "Sin región fijada",
  "No repetitive characters (e.g. aaa)": "No hay caracteres repetitivos (p. ej., aaa)",
  "No sequential characters (e.g. 123)": "No hay caracteres secuenciales (p. ej., 123)",
  "No Test Strip Detected": "Ninguna Tira Reactiva detectada",
  "none": "ninguna",
  "Norfolk Island": "Isla Norfolk",
  "Northern Mariana Islands": "Islas Marianas del Norte",
  "Norway(inc Svalbard and Jan May": "Noruega (inc. Svalbard y Jan Mayen)",
  "Norwegian": "Noruego",
  "Nov": "Nov",
  "November": "Noviembre",
  "NP Aspirate": "Aspirado NF",
  "NP Swab": "Muestra nasofaríngea",
  "Oct": "Oct",
  "October": "Octubre",
  "Offline 1 day": "Desconectado 1 día",
  "Offline 1 hour": "Desconectado 1 hora",
  "Offline 1 minute": "Desconectado 1 minuto",
  "Offline 1 month": "Desconectado 1 mes",
  "Offline 1 year": "Desconectado 1 año",
  "Offline __days__ days": "Desconectado __days__ días",
  "Offline __hours__ hours": "Desconectado __hours__ horas",
  "Offline __minutes__ minutes": "Desconectado __minutes__ minutos",
  "Offline __months__ months": "Desconectado __months__ meses",
  "Offline __years__ years": "Desconectado __years__ años",
  "OK": "Aceptar",
  "Okay": "Aceptar",
  "Oman": "Omán",
  "Pakistan": "Pakistán",
  "Palau": "Palaos",
  "Palestinian Territory, Occupie": "Territorio Palestino, Ocupado",
  "Panama": "Panamá",
  "Papua New Guinea": "Papúa Nueva Guinea",
  "Paraguay": "Paraguay",
  "Password": "Contraseña",
  "Password requirements:": "Requisitos de la contraseña:",
  "Peru": "Perú",
  "Philippines": "Filipinas",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polonia",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugués (Brasil)",
  "Portuguese (Portugal)": "Portugués (Portugal)",
  "Profile": "Perfil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Proporcione la información siguiente para establecer su contraseña.  El código de validación se envió a su dirección de correo electrónico, si es válida.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Proporcione su correo electrónico para solicitar el restablecimiento de la contraseña. Se enviará un código de validación temporal a su correo electrónico.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Catar",
  "QC Solution Detected": "Solución de QC detectada",
  "Rectal Swab": "Muestra rectal",
  "Required Update": "Actualización obligatoria",
  "Reset": "Restablecer",
  "Reset Password": "Restablecer contraseña",
  "Reset password": "Restablecer contraseña",
  "Resolution requirements": "Requisitos de la resolución",
  "Return to Login": "Volver a Conectar",
  "Romania": "Rumanía",
  "Russian Federation": "Federación Rusa",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Reunión y Mayotte",
  "Sa": "Sa",
  "Saint Helena and Tristan da Cu": "Santa Elena y Tristán de Acuña",
  "Saint Kitts and Nevis": "San Cristóbal y Nieves",
  "Saint Lucia": "Santa Lucía",
  "Saint Pierre and Miquelon": "San Pedro y Miquelón",
  "Saint Vincent and the Grenadine": "San Vicente y las Granadinas",
  "Saliva": "Saliva",
  "Samoa": "Samoa",
  "Sample Detected": "Muestra detectada",
  "Sample Error": "Error de la muestra",
  "Sample Type Error": "Error de tipo de muestra",
  "San Marino": "San Marino",
  "Saudi Arabia": "Arabia Saudí",
  "Save": "Guardar",
  "Select a date": "Seleccionar una fecha",
  "Select from list...": "Seleccionar de lista...",
  "Select language": "Seleccionar idioma",
  "Selected files must match requirements": "Los archivos seleccionados deben cumplir los requisitos",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "Septiembre",
  "Serbia": "Serbia",
  "Serum": "Suero",
  "Server error, please try again later.": "Error del servidor, vuelva a intentarlo más tarde.",
  "Server Error. Unable to save your profile.": "Error del servidor. No se pudo guardar su perfil.",
  "Set Password": "Establecer contraseña",
  "Seychelles": "Seychelles",
  "Show Password": "Mostrar contraseña",
  "Sierra Leone": "Sierra Leona",
  "Singapore": "Singapur",
  "Size must be less than __maxSize__": "El tamaño debe ser inferior a __maxSize__",
  "Skin Swab": "Muestra cutánea",
  "Slovakia": "Eslovaquia",
  "Slovenia": "Eslovenia",
  "Solomon Islands": "Islas Salomón",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Lo sentimos, pero DataView no está activado o usted no tiene acceso a esta organización",
  "South Africa": "Sudáfrica",
  "Space": "Espacio",
  "Spain": "España",
  "Spanish": "Español",
  "Spanish (US)": "Español (EE. UU.)",
  "Sputum": "Esputo",
  "Sri Lanka": "Sri Lanka",
  "Su": "Do",
  "Sudan": "Sudán",
  "Suriname": "Surinam",
  "Swaziland": "Suazilandia",
  "Sweden": "Suecia",
  "Swedish": "Sueco",
  "Switzerland": "Suiza",
  "Syrian Arab Republic(Syria)": "República Árabe Siria (Siria)",
  "São Tomé and Príncipe": "Santo Tomé y Príncipe",
  "Taiwan, Republic of China": "Taiwán, República de China",
  "Tajikistan": "Tayikistán",
  "Tanzania, United Republic of": "Tanzania, República Unida de",
  "Temperature Error": "Error de temperatura",
  "Test Cancelled": "Test cancelado",
  "Test Lot Not Permitted": "Lote de test no permitido",
  "Test Operation Error": "Error de funcionamiento del test",
  "Test Strip Lot Expired": "Lote de Tira Reactiva caducado",
  "Test Strip Not Supported": "Tira Reactiva no admitida",
  "Test Strip Reading Error": "Error lectura de Tira Reactiva",
  "Test Timeout": "Agotado tiempo espera del test",
  "Test Type Not Permitted": "Tipo de test no permitido",
  "Th": "Ju",
  "Thailand": "Tailandia",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Si el correo electrónico es un correo electrónico de usuario válido, el código de validación de la nueva contraseña se enviará a dicho correo.",
  "The reset validation code was populated from the link and should not be changed.": "El código de validación del restablecimiento se tomó del enlace y no debe cambiarse.",
  "There are unsaved changes. How do you want to proceed?": "Hay cambios no guardados. ¿Cómo desea continuar?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Hay una nueva versión disponible (__versionNumber__), ¿desea instalarla ahora?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Este dispositivo ya no funciona como Connect Hub. Para seguir utilizando este dispositivo como Connect Hub, vuelva a habilitar Bluetooth en la configuración de su dispositivo y active de nuevo Utilizar este dispositivo móvil como un Connect Hub en la pantalla Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Esta versión de LumiraDx Connect ya no es compatible con su organización. Vaya a __storeName__ y actualice a la última versión.",
  "Throat Swab": "Muestra faríngea",
  "Timor - Leste": "Timor Oriental",
  "Title": "Título",
  "TN Swab": "Hisopo NF",
  "to": "hasta",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Para continuar usando __APP_NAME__, actualice a la última versión (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad y Tobago",
  "Tu": "Ma",
  "Tunisia": "Túnez",
  "Turkey": "Turquía",
  "Turkmenistan": "Turkmenistán",
  "Turks and Caicos Islands": "Islas Turcas y Caicos",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ucrania",
  "Unable to reset password, the provided current password is not valid.": "No se puede restablecer la contraseña, la contraseña actual indicada no es válida.",
  "Unable to reset your password.": "No se pudo restablecer su contraseña.",
  "Unable to validate access code.": "No se pudo validar el código de acceso.",
  "United Arab Emirates": "Emiratos Árabes Unidos",
  "United Kingdom": "Reino Unido",
  "United States of America": "Estados Unidos de América",
  "Unrecognized credentials. Please try again.": "Credenciales no reconocidas. Vuelva a intentarlo.",
  "Unsaved Changes": "Cambios no guardados",
  "Update Available": "Actualización disponible",
  "Update Password": "Actualizar contraseña",
  "Urethral Swab": "Muestra uretral",
  "Urine": "Orina",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "La cuenta de usuario está bloqueada. Vuelva a intentarlo dentro de una hora.",
  "User Interface Version": "Versión de la interfaz de usuario",
  "User logged out due to inactivity.": "Se ha cerrado la sesión del usuario debido a inactividad.",
  "Username": "Nombre de usuario",
  "Uzbekistan": "Uzbekistán",
  "Vaginal Swab": "Muestra vaginal",
  "Validate Access Code": "Validar código de acceso",
  "Validation Code": "Código de validación",
  "Validation Code is required.": "Se requiere código de validación.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (República Bolivariana de)",
  "Venous Blood": "Sangre venosa",
  "Version": "Versión",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Islas Vírgenes, Británicas",
  "Virgin Islands, US": "Islas Vírgenes, EE. UU.",
  "VTM": "MTV",
  "Vulvo Vaginal Swab": "Muestra vulvovaginal",
  "Wallis and Futuna Islands": "Islas Wallis y Futuna",
  "We": "Mi",
  "We are working to get you back up and running as quickly as possible.": "Estamos trabajando para que usted pueda continuar lo antes posible.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Lo sentimos, LumiraDx Connect está actualmente fuera de servicio por mantenimiento.",
  "Yemen": "Yemen",
  "You have been signed out due to a password reset attempt.": "Se ha cerrado la sesión debido a un intento de restablecimiento de la contraseña.",
  "You have been signed out due to the creation of another session with the same credentials.": "Se ha cerrado la sesión debido a la creación de otra sesión con las mismas credenciales.",
  "You have been signed out due to your account being disabled by an admin.": "Se ha cerrado la sesión porque su cuenta ha sido deshabilitada por un admin.",
  "You have been signed out due to your password being changed by an admin.": "Se ha cerrado la sesión porque su contraseña ha sido cambiada por un admin.",
  "You have been signed out due to your user role being changed by an admin.": "Se ha cerrado la sesión porque su cargo de usuario ha sido cambiado por un admin.",
  "You have been signed out.": "Se ha cerrado la sesión.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Tiene que instalar una versión diferente (__versionNumber__) de __APP_NAME__ para que sea compatible con su organización.",
  "Your password has expired. Please set a new password.": "Su contraseña ha caducado. Establezca una nueva contraseña.",
  "Your session has expired. Please login again.": "Su sesión ha caducado. Vuelva a iniciar una sesión.",
  "Your session has expired. User is not authorized.": "Su sesión ha caducado. El usuario no está autorizado.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabue"
};
