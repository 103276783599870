import * as React from 'react';
import { ISelectPvrOption } from './SelectPvr';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/pvr.styl');
    break;
  default:
    styles = require('./styles/pvr.styl');
    break;
}

export interface ISelectPvrOptionsProps {
  optionHeight?: number;
  isSelected?: boolean;
  noWrapOptions?: boolean;
  option?: ISelectPvrOption;
  children?: any[];
  multiSelectGroupId?: string | number;
  subOptionsHeight?: number;
  isOpen?: boolean;
  setOpenSubOptions?: (option: ISelectPvrOption, adjust: number) => any;
  canDeselect?: boolean;
  onChange?: (option: ISelectPvrOption, multiSelectGroupId: string | number) => any;
  customClass?: string;
}

/**
 * @param children
 * Options contained underneath a section header. Header will assume collapse/expand duties.
 * @param noWrapOptions
 * option to turn off text wrapping on the menu's options
 * @param optionHeight
 * the height of the options in the list
 * @param canDeselect
 * option to de-select the selected option - will allow us call handle click for the selected option
 */
export default class SelectPvrOption extends React.Component<ISelectPvrOptionsProps, any> {
  public static defaultProps: ISelectPvrOptionsProps = {
    optionHeight: 0,
    isSelected: false,
    noWrapOptions: false,
    option: {
      isSubHeader: false,
      isIndented: false,
      disabled: false,
    },
    children: [],
    multiSelectGroupId: null,
  };

  public render(): JSX.Element {
    let optionHandler;
    const { option, isSelected, optionHeight, noWrapOptions, children, subOptionsHeight, isOpen } = this.props;
    const { isSubHeader, isIndented, info, subLabel, label, customClass, disabled, toolTip, value } = option;

    let className = `${styles.SelectPvrOption}`;
    if (isSelected) { className += ` ${styles.IsSelected}`; }
    if (noWrapOptions) { className += ` ${styles.NoWrap}`; }
    if (disabled) { className += ` ${styles.IsDisabled}`; }
    if (isSubHeader) { className += ` ${styles.IsSubHeader}`; }
    if (isIndented) { className += ` ${styles.IsIndented}`; }
    if (children.length) { className += ` ${styles.HasSubOptions}`; }
    if (isOpen) { className += ` ${styles.SubOptionsOpen}`; }
    if (customClass != null) { className += ` ${customClass}`; }

    let subOptionsToggleClass = styles.SubOptions;
    if (!isOpen) { subOptionsToggleClass += ` ${styles.IsClosed}`; }

    let labelClass = styles.Label;
    if (info != null) { labelClass += ` ${styles.HasInfo}`; }

    if (!isSubHeader) { optionHandler = this.handleChange; }
    if (children.length) { optionHandler = this.handleToggleSubOptions; }

    const infoEl = info != null ? <div key="info" className={styles.Info}>{info}</div> : null;
    const labelEl = label != null ? <div key="label" className={labelClass}>{label}</div> : null;
    const subLabelEl = subLabel != null ? <div key="subEl" className={styles.SubLabel}>{subLabel}</div> : null;
    const subOptions = children.length ? (
      <div
        key="subOptions"
        className={subOptionsToggleClass}
        style={{height: isOpen ? subOptionsHeight : 0}}
      >
        {children}
      </div>
    ) : null;

    const height = optionHeight - (children.length ? 1 : 0);

    const content = (
      <div key="main" className={className} onClick={optionHandler} style={{ height }} title={toolTip} data-test={value}>
        <div className={styles.MainLabel}>
          {infoEl}
          {labelEl}
          {subLabelEl}
        </div>
      </div>
    );

    return children.length ? (
      <div className={styles.SelectOptionCont}>
        {content}
        {subOptions}
      </div>
    ) : content;
  }

  public handleChange = (e): void => {
    e.stopPropagation();
    const { onChange, option, isSelected, canDeselect, multiSelectGroupId } = this.props;
    const { disabled } = option;

    if ((!isSelected || !!canDeselect) && !disabled) {
      if (typeof onChange === 'function') {
        onChange(option, multiSelectGroupId);
      }
    }
  };

  public handleToggleSubOptions = (e): void => {
    e.stopPropagation();
    const { children } = this.props;
    let adjust = 0;

    for (const ch of Array.from(children)) {
      adjust += ch.props.optionHeight;
    }

    const opt = this.props.isOpen ? null : this.props.option;
    adjust = (opt != null) ? adjust : 0;

    return this.props.setOpenSubOptions(opt, adjust);
  };
}
