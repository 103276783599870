module.exports = {
  "__appName__ is not currently available.": "__appName__ er ikke tilgjengelig for øyeblikket.",
  "Access Code": "Tilgangskode",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Kontoen ${this.username} er låst grunnet 5 innloggingsforsøk. Kontakt administratoren for å åpne den",
  "Afghanistan": "Afghanistan",
  "Albania": "Albania",
  "Algeria": "Algerie",
  "American Samoa": "Amerikansk Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua og Barbuda",
  "App": "App",
  "Application Error": "Programfeil",
  "Application error.": "Programfeil.",
  "Application is not currently available.": "Programmet er for tiden utilgjengelig.",
  "Apply Changes": "Ta i bruk endringer",
  "Apr": "Apr",
  "April": "April",
  "Argentina": "Argentina",
  "Armenia": "Armenia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Minst __value__ tegn",
  "At least one lowercase letter": "Minst én liten bokstav",
  "At least one number": "Minst ett tall",
  "At least one of these special characters: __value__": "Minst ett av disse spesialtegnene: __value__",
  "At least one uppercase letter": "Minst én stor bokstav",
  "Aug": "Aug",
  "August": "August",
  "Australia": "Australia",
  "Austria": "Østerrike",
  "Azerbaijan": "Aserbajdsjan",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Hviterussland",
  "Belgium": "Belgia",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth er avslått",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnia og Herzegovina",
  "Botswana": "Botswana",
  "Brazil": "Brasil",
  "Bronchial Lavage": "Bronkialskylling",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Kambodsja",
  "Cameroon": "Kamerun",
  "Canada": "Canada",
  "Cancel": "Avbryt",
  "Cannot be empty": "Kan ikke være tomt",
  "Cape Verde": "Kapp Verde",
  "Capillary Blood": "Kapillærblod",
  "Cayman Islands": "Caymanøyene",
  "Central African Republic": "Den sentralafrikanske republikk",
  "Cerebrospinal Fluid": "Cerebrospinalvæske",
  "Cervical Swab": "Cervixpinne",
  "Chad": "Tsjad",
  "Change Access Code": "Endre tilgangskode",
  "Check again": "Kontroller igjen",
  "Chile": "Chile",
  "China": "Kina",
  "Clear Search": "Tøm søket",
  "Close": "Lukk",
  "Cocos(Keeling) Islands and Chr": "Kokosøyene (Keelingøyene) og Christmasøya",
  "Colombia": "Colombia",
  "Comoros": "Komorene",
  "Confirm": "Bekreft",
  "Confirm New Password": "Bekreft nytt passord",
  "Confirm new password": "Bekreft nytt passord",
  "Confirm New Password is required.": "Bekreft nytt passord er påkrevd.",
  "Confirm Password is required.": "Bekreft passord er påkrevd.",
  "Confirm Password must match New Password": "Bekreft passord må stemme overens med Nytt passord",
  "Congo(Brazzaville)": "Kongo (Brazzaville)",
  "Congo(Kinshasa)": "Kongo (Kinshasa)",
  "Cook Islands": "Cookøyene",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ – LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ – LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Elfenbenskysten",
  "Croatia(Hrvatska)": "Kroatia (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Kulturbuljong",
  "Current Password": "Nåværende passord",
  "Current Password is required.": "Nåværende passord er påkrevd.",
  "Current password required": "Nåværende passord er påkrevd",
  "Cyprus": "Kypros",
  "Czech Republic": "Den tsjekkiske republikk",
  "Danish": "Dansk",
  "Dec": "Des",
  "December": "Desember",
  "Denmark": "Danmark",
  "Disabled": "Deaktivert",
  "Discard Changes": "Forkast endringer",
  "Dismiss": "Ignorer",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Samsvarer ikke med dine forrige passord",
  "Does not use unsupported characters": "Bruker ikke tegn som ikke støttes",
  "Domain": "Domene",
  "Domain is required.": "Domene er påkrevd.",
  "Dominica": "Dominica",
  "Dominican Republic": "Den dominikanske republikk",
  "Door Open": "Luken åpen",
  "Dutch": "Nederlandsk",
  "Ecuador": "Ecuador",
  "Egypt": "Egypt",
  "El Salvador": "El Salvador",
  "Email": "E-post",
  "Email is required.": "E-post er påkrevd.",
  "English (UK)": "Engelsk (Storbritannia)",
  "English (US)": "Engelsk (USA)",
  "Enter": "Angi",
  "Equatorial Guinea": "Ekvatorial-Guinea",
  "Eritrea": "Eritrea",
  "Error": "Feil",
  "Estonia": "Estland",
  "Ethiopia": "Etiopia",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager støttes ikke i mobilapplikasjonen Connect.",
  "Failed to reset password. A server error was encountered": "Klarte ikke å tilbakestille passord. Det oppsto en serverfeil",
  "Falkland Islands(Malvinas)": "Falklandsøyene (Malvinas)",
  "Faroe Islands": "Færøyene",
  "Fatal Application Error": "Alvorlig programfeil",
  "Feb": "Feb",
  "February": "Februar",
  "Fecal Specimen": "Avføringsprøve",
  "Fiji": "Fiji",
  "File type must be __fileType__": "Filtypen må være __fileType__",
  "Finland": "Finland",
  "Finnish": "Finsk",
  "First Name": "Fornavn",
  "Forgot your password?": "Glemt passordet ditt?",
  "Fr": "Fr",
  "France": "Frankrike",
  "French": "Fransk",
  "French Guiana": "Fransk Guyana",
  "French Polynesia": "Fransk Polynesia",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "Tysk",
  "Germany": "Tyskland",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Gå til __storeName__",
  "Greater than __value__ in __measure__": "Mer enn __value__ i __measure__",
  "Greece": "Hellas",
  "Greenland": "Grønland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct utenfor området",
  "Hide": "Skjul",
  "Hide password": "Skjul passord",
  "Holy See(Vatican City State)": "Den hellige stol (Vatikanstaten)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hongkong, SAR",
  "Hungary": "Ungarn",
  "Iceland": "Island",
  "India": "India",
  "Indonesia": "Indonesia",
  "Initials": "Initialer",
  "Install": "Installer",
  "Install Now": "Installer nå",
  "Instrument Not Level": "Instrument ikke vannrett",
  "Insufficient Sample": "Utilstrekkelig prøve",
  "Internet connection is offline.": "Internettilkoblingen er offline.",
  "Invalid access code": "Ugyldig tilgangskode",
  "Invalid email format": "Ugyldig e-postformat",
  "Invalid File": "Ugyldig fil",
  "Invalid Lot Cal File": "Ugyldig lotkalibreringsfil",
  "Invalid username or password.": "Ugyldig brukernavn eller passord.",
  "Iran, Islamic Republic of": "Iran, Islamske republikken",
  "Iraq": "Irak",
  "Ireland": "Irland",
  "Israel": "Israel",
  "Italian": "Italiensk",
  "Italy": "Italia",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "Januar",
  "Japan": "Japan",
  "Japanese": "Japansk",
  "Jordan": "Jordan",
  "Jul": "Jul",
  "July": "Juli",
  "Jun": "Jun",
  "June": "Juni",
  "Kazakhstan": "Kasakhstan",
  "KC Swab": "KC-pinne",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Den demokratiske folkerepublikken",
  "Korea, Republic of(South)": "Korea, Republikken (Sør)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgisistan",
  "Language": "Språk",
  "Laos(Lao PDR)": "Laos (Den demokratiske folkerepublikk)",
  "Last Name": "Etternavn",
  "Latvia": "Latvia",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Mindre enn __value__ i __measure__",
  "Liberia": "Liberia",
  "Libya": "Libya",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Kvalitetskontrolløsning",
  "Lithuania": "Litauen",
  "Loading more records...": "Laster inn flere oppføringer ...",
  "Login": "Logg inn",
  "Logout": "Logg ut",
  "Luxembourg": "Luxembourg",
  "Macao(SAR China)": "Macao (SAR Kina)",
  "Macedonia, Republic of": "Makedonia, Republikken",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysia",
  "Maldives": "Maldivene",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Mars",
  "Marshall Islands": "Marshalløyene",
  "Martinique": "Martinique",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauritius",
  "MAX": "MAKS",
  "Maximum length is __count__ characters": "Maks. lengde er __count__ tegn",
  "May": "Mai",
  "Mexico": "Mexico",
  "Micronesia, Federated States o": "Mikronesiaføderasjonen",
  "Mo": "Ma",
  "Mobile Application Version": "Mobilapplikasjonsversjon",
  "Moldova": "Moldova",
  "Monaco": "Monaco",
  "Mongolia": "Mongolia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marokko og Vest-Sahara",
  "Mouth Swab": "Munnpinne",
  "Mozambique": "Mosambik",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Neseslim",
  "Nasal Swab": "Nesepinne",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Nederland",
  "Netherlands Antilles": "De nederlandske Antillene",
  "Never Connected": "Aldri tilkoblet",
  "New Caledonia": "Ny-Caledonia",
  "New Password": "Nytt passord",
  "New Password is invalid.": "Nytt passord er ugyldig.",
  "New Password is required.": "Nytt passord er påkrevd.",
  "New password must match": "Nytt passord må samsvare",
  "New Version Required": "Ny versjon påkrevd",
  "New Zealand": "New Zealand",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Det finnes ingen applikasjonsmodul i dette miljøet. Rapporter dette problemet til en administrator.",
  "No easily guessable words": "Ingen ord som lett kan gjettes",
  "No more than __value__ characters": "Ikke mer enn __value__ tegn",
  "No region set": "Ingen region angitt",
  "No repetitive characters (e.g. aaa)": "Ingen gjentakende tegn (f.eks. aaa)",
  "No sequential characters (e.g. 123)": "Ingen sekvensielle tegn (f.eks. 123)",
  "No Test Strip Detected": "Ingen Testkort detektert",
  "none": "Ingen",
  "Norfolk Island": "Norfolkøya",
  "Northern Mariana Islands": "Nord-Marianene",
  "Norway(inc Svalbard and Jan May": "Norge (inkl. Svalbard og Jan Mayen)",
  "Norwegian": "Norsk",
  "Nov": "Nov",
  "November": "November",
  "NP Aspirate": "NP-aspirat",
  "NP Swab": "NP-pinne",
  "Oct": "Okt",
  "October": "Oktober",
  "Offline 1 day": "Offline 1 dag",
  "Offline 1 hour": "Offline 1 time",
  "Offline 1 minute": "Offline 1 minutt",
  "Offline 1 month": "Offline 1 måned",
  "Offline 1 year": "Offline 1 år",
  "Offline __days__ days": "Offline __days__ dager",
  "Offline __hours__ hours": "Offline __hours__ timer",
  "Offline __minutes__ minutes": "Offline __minutes__ minutter",
  "Offline __months__ months": "Offline __months__ måneder",
  "Offline __years__ years": "Offline __years__ år",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestinske territoriene, okkuperte",
  "Panama": "Panama",
  "Papua New Guinea": "Papua Ny-Guinea",
  "Paraguay": "Paraguay",
  "Password": "Passord",
  "Password requirements:": "Krav for passord:",
  "Peru": "Peru",
  "Philippines": "Filippinene",
  "Pitcairn": "Pitcairnøyene",
  "Plasma": "Plasma",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugisisk (Brasil)",
  "Portuguese (Portugal)": "Portugisisk (Portugal)",
  "Profile": "Profil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Oppgi følgende informasjon for å angi passordet ditt.  Valideringskoden ble sendt til e-postadressen din hvis den er gyldig.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Oppgi e-postadressen din for å be om en tilbakestilling av passordet. Du vil få tilsendt en midlertidig valideringskode på e-post.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC-løsning detektert",
  "Rectal Swab": "Rektalpinne",
  "Required Update": "Påkrevd oppdatering",
  "Reset": "Tilbakestill",
  "Reset Password": "Tilbakestill passord",
  "Reset password": "Tilbakestill passord",
  "Resolution requirements": "Oppløsningskrav",
  "Return to Login": "Tilbake til pålogging",
  "Romania": "Romania",
  "Russian Federation": "Russiske føderasjonen",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion og Mayotte",
  "Sa": "Lø",
  "Saint Helena and Tristan da Cu": "Sankt Helena og Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts og Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre og Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent og Grenadinene",
  "Saliva": "Spytt",
  "Samoa": "Samoa",
  "Sample Detected": "Prøve detektert",
  "Sample Error": "Prøvefeil",
  "Sample Type Error": "Feil ved prøvetype",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi-Arabia",
  "Save": "Lagre",
  "Select a date": "Velg en dato",
  "Select from list...": "Velg fra listen …",
  "Select language": "Velg språk",
  "Selected files must match requirements": "Valgte filer må stemme overens med kravene",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "September",
  "Serbia": "Serbia",
  "Serum": "Serum",
  "Server error, please try again later.": "Serverfeil, prøv igjen senere.",
  "Server Error. Unable to save your profile.": "Serverfeil. Kan ikke lagre profilen din.",
  "Set Password": "Angi passord",
  "Seychelles": "Seychellene",
  "Show Password": "Vis passord",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Størrelsen må være mindre enn __maxSize__",
  "Skin Swab": "Hudpinne",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Solomon Islands": "Salomonøyene",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Beklager, DataView er ikke aktivert, eller du har ikke tilgang til denne organisasjonen",
  "South Africa": "Sør-Afrika",
  "Space": "Mellomrom",
  "Spain": "Spania",
  "Spanish": "Spansk",
  "Spanish (US)": "Spansk (USA)",
  "Sputum": "Sputum",
  "Sri Lanka": "Sri Lanka",
  "Su": "Sø",
  "Sudan": "Sudan",
  "Suriname": "Surinam",
  "Swaziland": "Swaziland",
  "Sweden": "Sverige",
  "Swedish": "Svensk",
  "Switzerland": "Sveits",
  "Syrian Arab Republic(Syria)": "Syria, Den arabiske republikk (Syria)",
  "São Tomé and Príncipe": "São Tomé og Príncipe",
  "Taiwan, Republic of China": "Taiwan, Republikken Kina",
  "Tajikistan": "Tadsjikistan",
  "Tanzania, United Republic of": "Tanzania, Den forente republikk",
  "Temperature Error": "Temperaturfeil",
  "Test Cancelled": "Analyse avbrutt",
  "Test Lot Not Permitted": "Testkortlot ikke tillatt",
  "Test Operation Error": "Driftsfeil ved analysering",
  "Test Strip Lot Expired": "Testkort-lot utløpt",
  "Test Strip Not Supported": "Testkort støttes ikke",
  "Test Strip Reading Error": "Feil ved avlesning av Testkort",
  "Test Timeout": "Tidsavbrudd for analysen",
  "Test Type Not Permitted": "Analysetype ikke tillatt",
  "Th": "To",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Valideringskoden for det nye passordet sendes til e-postadressen, hvis e-postadressen tilhører en gyldig bruker.",
  "The reset validation code was populated from the link and should not be changed.": "Valideringskoden for tilbakestilling av passord ble hentet fra linken og skal ikke endres.",
  "There are unsaved changes. How do you want to proceed?": "Det er endringer som ikke er lagret. Hvordan ønsker du å fortsette?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "En ny versjon er tilgjengelig (__versionNumber__). Vil du installere den nå?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Denne enheten fungerer ikke lenger som en Connect Hub. For å fortsette å bruke denne enheten som en Connect Hub må du reaktivere Bluetooth i enhetens innstillinger og aktivere Bruk denne mobile enheten som en Connect Hub fra Hubs skjermbildet.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Denne versjonen av LumiraDx Connect er ikke lenger kompatibel med din organisasjon. Gå til __storeName__ og oppdater til nyeste versjon.",
  "Throat Swab": "Halspinne",
  "Timor - Leste": "Timor-Leste",
  "Title": "Tittel",
  "TN Swab": "TN-pinne",
  "to": "til",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "For å fortsette å bruke __APP_NAME__ bør du oppgradere til den nyeste versjonen (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad og Tobago",
  "Tu": "Ti",
  "Tunisia": "Tunisia",
  "Turkey": "Tyrkia",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- og Caicosøyene",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraina",
  "Unable to reset password, the provided current password is not valid.": "Kan ikke tilbakestille passordet, det angitte gjeldende passordet er ikke gyldig.",
  "Unable to reset your password.": "Kan ikke tilbakestille passordet ditt.",
  "Unable to validate access code.": "Kan ikke validere tilgangskode.",
  "United Arab Emirates": "De forente arabiske emirater",
  "United Kingdom": "Storbritannia",
  "United States of America": "USA",
  "Unrecognized credentials. Please try again.": "Påloggingsinformasjonen gjenkjennes ikke. Prøv igjen.",
  "Unsaved Changes": "Ulagrede endringer",
  "Update Available": "Oppdatering tilgjengelig",
  "Update Password": "Oppdater passord",
  "Urethral Swab": "Uretrapinne",
  "Urine": "Urin",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Brukerkontoen er låst. Prøv igjen om en time.",
  "User Interface Version": "Brukergrensesnittversjon",
  "User logged out due to inactivity.": "Brukeren ble logget ut som følge av inaktivitet.",
  "Username": "Brukernavn",
  "Uzbekistan": "Usbekistan",
  "Vaginal Swab": "Vaginalpinne",
  "Validate Access Code": "Valider tilgangskode",
  "Validation Code": "Valideringskode",
  "Validation Code is required.": "Valideringskode er påkrevd.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (Den bolivariske republikken)",
  "Venous Blood": "Venøst blod",
  "Version": "Versjon",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Jomfruøyene, britiske",
  "Virgin Islands, US": "Jomfruøyene, amerikanske",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvovaginalpinne",
  "Wallis and Futuna Islands": "Wallis og Futuna",
  "We": "On",
  "We are working to get you back up and running as quickly as possible.": "Vi jobber med å få deg i gang igjen så raskt som mulig.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Vi beklager, LumiraDx Connect er for tiden nede for vedlikehold.",
  "Yemen": "Jemen",
  "You have been signed out due to a password reset attempt.": "Du er blitt logget ut som følge av et forsøk på å tilbakestille passordet.",
  "You have been signed out due to the creation of another session with the same credentials.": "Du er blitt logget ut som følge av opprettelsen av en annen økt med samme påloggingsinformasjon.",
  "You have been signed out due to your account being disabled by an admin.": "Du er blitt logget ut som følge av at kontoen din ble deaktivert av en administrator.",
  "You have been signed out due to your password being changed by an admin.": "Du er blitt logget ut som følge av at passordet ditt er blitt endret av en administrator.",
  "You have been signed out due to your user role being changed by an admin.": "Du er blitt logget ut som følge av at brukerrollen din ble endret av en administrator.",
  "You have been signed out.": "Du er blitt logget ut.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Du må installere en annen versjon (__versionNumber__) av __APP_NAME__ for å være kompatibel med din organisasjon.",
  "Your password has expired. Please set a new password.": "Passordet ditt har utløpt. Angi et nytt passord.",
  "Your session has expired. Please login again.": "Økten din har utløpt. Vennligst logg inn igjen.",
  "Your session has expired. User is not authorized.": "Økten din har utløpt. Brukeren er ikke autorisert.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
