import * as React from 'react';
import Input from './Input';
import Datepicker from './Datepicker';
import { Moment } from 'moment';
import { IBaseInputProps, IValidateOptions, IValidationError } from './interfaces';
import { IPvrProps } from './Pvr';

export interface IDatepickerProps extends IBaseInputProps {
  onChange?: (value: string | Moment | Date, jsonPath?: string) => void;
  onKeyUp?: (e) => void;
  clear?: () => void;
  validate?: (options: IValidateOptions) => IValidationError;
  hasDefaultDate?: boolean;
  value?: any;
  returnDateFormat?: string;
  format?: string;
  minDate?: Moment;
  maxDate?: Moment;
  dayPickerProps?: any;
  pvrProps?: IPvrProps;
  [x: string]: any;
}

/**
 * @param value The value of the Datepicker, a `moment` object.
 * @param hasDefaultDate Will default the Datepicker value to the current date
 * @param returnDateFormat A Moment date format string to change the format of the return value
 * @param format A Moment date format string for the Datepicker input
 * @param dayPickerProps Props passed directly to React Day Picker
 * @param pvrProps Props passed directly to the `Pvr` component containing the calendar
 */
export default class DatepickerInput extends React.Component<IDatepickerProps, any> {
  private input: Datepicker;

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Datepicker {...this.props} {...props} ref={(input) => this.input = input} getValue={this.getValue} />
          );
        }}
      </Input>
    );
  }

  public getValue(): string | Moment | Date {
    return this.input.getValue();
  }
}
