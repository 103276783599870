module.exports = {
  "__appName__ is not currently available.": "__appName__ non è attualmente disponibile.",
  "Access Code": "Codice di accesso",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "L'account ${this.username} è bloccato a causa di 5 tentativi di accesso. Contattare l'amministratore per sbloccarlo.",
  "Afghanistan": "Afghanistan",
  "Albania": "Albania",
  "Algeria": "Algeria",
  "American Samoa": "Samoa Americane",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua e Barbuda",
  "App": "App",
  "Application Error": "Errore applicazione",
  "Application error.": "Errore applicazione.",
  "Application is not currently available.": "L'applicazione non è attualmente disponibile.",
  "Apply Changes": "Applica modifiche",
  "Apr": "Apr",
  "April": "Aprile",
  "Argentina": "Argentina",
  "Armenia": "Armenia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Almeno __value__ caratteri",
  "At least one lowercase letter": "Almeno una lettera minuscola",
  "At least one number": "Almeno un numero",
  "At least one of these special characters: __value__": "Almeno uno di questi caratteri speciali: __value__",
  "At least one uppercase letter": "Almeno una lettera maiuscola",
  "Aug": "Ago",
  "August": "Agosto",
  "Australia": "Australia",
  "Austria": "Austria",
  "Azerbaijan": "Azerbaigian",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrein",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Bielorussia",
  "Belgium": "Belgio",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermude",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth spento",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnia-Erzegovina",
  "Botswana": "Botswana",
  "Brazil": "Brasile",
  "Bronchial Lavage": "Lavaggio bronchiale",
  "Brunei Darussalam": "Brunei",
  "Bulgaria": "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Cambogia",
  "Cameroon": "Camerun",
  "Canada": "Canada",
  "Cancel": "Annulla",
  "Cannot be empty": "Non può essere vuoto",
  "Cape Verde": "Capo Verde",
  "Capillary Blood": "Sangue capillare",
  "Cayman Islands": "Isole Cayman",
  "Central African Republic": "Repubblica Centrafricana",
  "Cerebrospinal Fluid": "Liq. cerebrospinale",
  "Cervical Swab": "Tampone cervicale",
  "Chad": "Ciad",
  "Change Access Code": "Cambia codice di accesso",
  "Check again": "Seleziona di nuovo",
  "Chile": "Cile",
  "China": "Cina",
  "Clear Search": "Cancella ricerca",
  "Close": "Chiudi",
  "Cocos(Keeling) Islands and Chr": "Isole Cocos (Keeling) e Isola di Natale",
  "Colombia": "Colombia",
  "Comoros": "Comore",
  "Confirm": "Conferma",
  "Confirm New Password": "Conferma nuova password",
  "Confirm new password": "Conferma nuova password",
  "Confirm New Password is required.": "Conferma nuova password obbligatoria.",
  "Confirm Password is required.": "Password di conferma obbligatoria.",
  "Confirm Password must match New Password": "La password di conferma deve corrispondere alla nuova password",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Isole Cook",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Cote D'Ivoire (Costa d’Avorio)",
  "Croatia(Hrvatska)": "Croazia (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Brodo di coltura",
  "Current Password": "Password attuale",
  "Current Password is required.": "Password attuale obbligatoria.",
  "Current password required": "Password attuale obbligatoria",
  "Cyprus": "Cipro",
  "Czech Republic": "Repubblica Ceca",
  "Danish": "Danese",
  "Dec": "Dic",
  "December": "Dicembre",
  "Denmark": "Danimarca",
  "Disabled": "Disattivato",
  "Discard Changes": "Annulla modifiche",
  "Dismiss": "Ignora",
  "Djibouti": "Gibuti",
  "Does not match your previous passwords": "Non corrisponde alle password precedenti dell'utente",
  "Does not use unsupported characters": "Non utilizzare caratteri non supportati",
  "Domain": "Dominio",
  "Domain is required.": "Dominio obbligatorio.",
  "Dominica": "Dominica",
  "Dominican Republic": "Repubblica Dominicana",
  "Door Open": "Sportello aperto",
  "Dutch": "Olandese",
  "Ecuador": "Ecuador",
  "Egypt": "Egitto",
  "El Salvador": "El Salvador",
  "Email": "E-mail",
  "Email is required.": "Indirizzo e-mail obbligatorio.",
  "English (UK)": "Inglese (GB)",
  "English (US)": "Inglese (USA)",
  "Enter": "Invio",
  "Equatorial Guinea": "Guinea Equatoriale",
  "Eritrea": "Eritrea",
  "Error": "Errore",
  "Estonia": "Estonia",
  "Ethiopia": "Etiopia",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager non è supportato nell'applicazione mobile Connect.",
  "Failed to reset password. A server error was encountered": "Reimpostazione della password non riuscita. Si è verificato un errore del server",
  "Falkland Islands(Malvinas)": "Isole Falkland (Malvinas)",
  "Faroe Islands": "Isole Faroe",
  "Fatal Application Error": "Errore irreversibile applicazione",
  "Feb": "Feb",
  "February": "Febbraio",
  "Fecal Specimen": "Campione fecale",
  "Fiji": "Figi",
  "File type must be __fileType__": "Il tipo di file deve essere __fileType__",
  "Finland": "Finlandia",
  "Finnish": "Finlandese",
  "First Name": "Nome",
  "Forgot your password?": "Password dimenticata?",
  "Fr": "Ven",
  "France": "Francia",
  "French": "Francese",
  "French Guiana": "Guiana Francese",
  "French Polynesia": "Polinesia Francese",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "Tedesco",
  "Germany": "Germania",
  "Ghana": "Ghana",
  "Gibraltar": "Gibilterra",
  "Go To __storeName__": "Vai a __storeName__",
  "Greater than __value__ in __measure__": "Più di __value__ su __measure__",
  "Greece": "Grecia",
  "Greenland": "Groenlandia",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadalupa",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guiana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct fuori intervallo",
  "Hide": "Nascondi",
  "Hide password": "Nascondi password",
  "Holy See(Vatican City State)": "Santa Sede (Città del Vaticano)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, RAS",
  "Hungary": "Ungheria",
  "Iceland": "Islanda",
  "India": "India",
  "Indonesia": "Indonesia",
  "Initials": "Iniziali",
  "Install": "Installa",
  "Install Now": "Installa adesso",
  "Instrument Not Level": "Instrument non in piano",
  "Insufficient Sample": "Campione insufficiente",
  "Internet connection is offline.": "Connessione a Internet disconnessa.",
  "Invalid access code": "Codice di accesso non valido",
  "Invalid email format": "Formato e-mail non valido",
  "Invalid File": "File non valido",
  "Invalid Lot Cal File": "File calibr. lotto non valido",
  "Invalid username or password.": "Nome utente o password non validi.",
  "Iran, Islamic Republic of": "Iran, Repubblica Islamica dell’",
  "Iraq": "Iraq",
  "Ireland": "Irlanda",
  "Israel": "Israele",
  "Italian": "Italiano",
  "Italy": "Italia",
  "Jamaica": "Giamaica",
  "Jan": "Gen",
  "January": "Gennaio",
  "Japan": "Giappone",
  "Japanese": "Giapponese",
  "Jordan": "Giordania",
  "Jul": "Lug",
  "July": "Luglio",
  "Jun": "Giu",
  "June": "Giugno",
  "Kazakhstan": "Kazakistan",
  "KC Swab": "Tampone KC",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Corea, Repubblica Popolare Democratica di",
  "Korea, Republic of(South)": "Corea del Sud, Repubblica di",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirghizistan",
  "Language": "Lingua",
  "Laos(Lao PDR)": "Laos (Repubblica Popolare Democratica del Laos)",
  "Last Name": "Cognome",
  "Latvia": "Lettonia",
  "Lebanon": "Libano",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Meno di __value__ su __measure__",
  "Liberia": "Liberia",
  "Libya": "Libia",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "QC liquido",
  "Lithuania": "Lituania",
  "Loading more records...": "Caricamento di altri record in corso...",
  "Login": "Accedi",
  "Logout": "Disconnetti",
  "Luxembourg": "Lussemburgo",
  "Macao(SAR China)": "Macao (RAS della Cina)",
  "Macedonia, Republic of": "Macedonia, Repubblica di",
  "Madagascar": "Madagascar",
  "Malawi": "Malawi",
  "Malaysia": "Malesia",
  "Maldives": "Maldive",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Marzo",
  "Marshall Islands": "Isole Marshall",
  "Martinique": "Martinica",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Lunghezza massima __count__ caratteri",
  "May": "Maggio",
  "Mexico": "Messico",
  "Micronesia, Federated States o": "Micronesia, Stati Federati di",
  "Mo": "Lun",
  "Mobile Application Version": "Versione applicazione mobile",
  "Moldova": "Moldavia",
  "Monaco": "Monaco",
  "Mongolia": "Mongolia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marocco e Sahara Occidentale",
  "Mouth Swab": "Tampone orale",
  "Mozambique": "Mozambico",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Secrezioni nasali",
  "Nasal Swab": "Tampone nasale",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Paesi Bassi",
  "Netherlands Antilles": "Antille Olandesi",
  "Never Connected": "Mai connesso",
  "New Caledonia": "Nuova Caledonia",
  "New Password": "Nuova password",
  "New Password is invalid.": "Nuova password non valida.",
  "New Password is required.": "Nuova password obbligatoria.",
  "New password must match": "La nuova password deve corrispondere",
  "New Version Required": "Nuova versione obbligatoria",
  "New Zealand": "Nuova Zelanda",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Non esistono moduli applicativi in questo ambiente. Segnalare questo problema a un amministratore.",
  "No easily guessable words": "Non immettere parole intuibili",
  "No more than __value__ characters": "Non più di __value__ caratteri",
  "No region set": "Regione non indicata",
  "No repetitive characters (e.g. aaa)": "Non immettere caratteri ripetitivi (ad es, aaa)",
  "No sequential characters (e.g. 123)": "Non immettere caratteri sequenziali (ad es, 123)",
  "No Test Strip Detected": "Striscia Reattiva non rilevata",
  "none": "nessuno",
  "Norfolk Island": "Isola Norfolk",
  "Northern Mariana Islands": "Isole Marianne Settentrionali",
  "Norway(inc Svalbard and Jan May": "Norvegia (Svalbard e Jan Mayen)",
  "Norwegian": "Norvegese",
  "Nov": "Nov",
  "November": "Novembre",
  "NP Aspirate": "Aspirato nasofaring.",
  "NP Swab": "Tampone nasofaringeo",
  "Oct": "Ott",
  "October": "Ottobre",
  "Offline 1 day": "Disconnesso da 1 giorno",
  "Offline 1 hour": "Disconnesso da 1 ora",
  "Offline 1 minute": "Disconnesso da 1 minuto",
  "Offline 1 month": "Disconnesso da 1 mese",
  "Offline 1 year": "Disconnesso da 1 anno",
  "Offline __days__ days": "Disconnesso da __days__ giorni",
  "Offline __hours__ hours": "Disconnesso da __hours__ ore",
  "Offline __minutes__ minutes": "Disconnesso da __minutes__ minuti",
  "Offline __months__ months": "Disconnesso da __months__ mesi",
  "Offline __years__ years": "Disconnesso da __years__ anni",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Terrotorio palestinese occupato",
  "Panama": "Panama",
  "Papua New Guinea": "Papua Nuova Guinea",
  "Paraguay": "Paraguay",
  "Password": "Password",
  "Password requirements:": "Requisiti relativi alla password:",
  "Peru": "Perù",
  "Philippines": "Filippine",
  "Pitcairn": "Isole Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polonia",
  "Portugal": "Portogallo",
  "Portuguese (Brazil)": "Portoghese (Brasile)",
  "Portuguese (Portugal)": "Portoghese (Portogallo)",
  "Profile": "Profilo",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Per impostare la password, fornire le seguenti informazioni.  Il codice di convalida è stato inviato all'indirizzo e-mail dell'utente (se valido).",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Per richiedere la reimpostazione della password, fornire la propria e-mail. Un codice di convalida provvisorio verrà inviato all'e-mail specificata.",
  "Puerto Rico": "Portorico",
  "Qatar": "Qatar",
  "QC Solution Detected": "Soluzione QC rilevata",
  "Rectal Swab": "Tampone rettale",
  "Required Update": "Richiesto aggiornamento",
  "Reset": "Ripristina",
  "Reset Password": "Ripristina password",
  "Reset password": "Ripristina password",
  "Resolution requirements": "Requisiti per la risoluzione",
  "Return to Login": "Torna all'accesso",
  "Romania": "Romania",
  "Russian Federation": "Russia",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Réunion e Mayotte",
  "Sa": "Sab",
  "Saint Helena and Tristan da Cu": "Sant'Elena, Ascensione e Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts e Nevis",
  "Saint Lucia": "Santa Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre e Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent e Grenadine",
  "Saliva": "Saliva",
  "Samoa": "Samoa",
  "Sample Detected": "Campione rilevato",
  "Sample Error": "Errore del campione",
  "Sample Type Error": "Errore tipo di campione",
  "San Marino": "San Marino",
  "Saudi Arabia": "Arabia Saudita",
  "Save": "Salva",
  "Select a date": "Seleziona una data",
  "Select from list...": "Seleziona dall'elenco...",
  "Select language": "Seleziona lingua",
  "Selected files must match requirements": "I file selezionati devono soddisfare i requisiti",
  "Senegal": "Senegal",
  "Sep": "Set",
  "September": "Settembre",
  "Serbia": "Serbia",
  "Serum": "Siero",
  "Server error, please try again later.": "Errore del server, riprovare più tardi.",
  "Server Error. Unable to save your profile.": "Errore server. Impossibile salvare il profilo dell'utente.",
  "Set Password": "Imposta password",
  "Seychelles": "Seychelles",
  "Show Password": "Mostra password",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "La dimensione deve essere inferiore a __maxSize__",
  "Skin Swab": "Tampone cutaneo",
  "Slovakia": "Slovacchia",
  "Slovenia": "Slovenia",
  "Solomon Islands": "Isole Salomone",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "DataView non è abilitato o non si dispone dell'accesso a questa organizzazione",
  "South Africa": "Repubblica Sudafricana",
  "Space": "Spazio",
  "Spain": "Spagna",
  "Spanish": "Spagnolo",
  "Spanish (US)": "Spagnolo (USA)",
  "Sputum": "Espettorato",
  "Sri Lanka": "Sri Lanka",
  "Su": "Dom",
  "Sudan": "Sudan",
  "Suriname": "Suriname",
  "Swaziland": "Swaziland",
  "Sweden": "Svezia",
  "Swedish": "Svedese",
  "Switzerland": "Svizzera",
  "Syrian Arab Republic(Syria)": "Repubblica Araba di Siria",
  "São Tomé and Príncipe": "São Tomé e Príncipe",
  "Taiwan, Republic of China": "Taiwan, Repubblica di Cina",
  "Tajikistan": "Tagikistan",
  "Tanzania, United Republic of": "Tanzania, Repubblica Unita di",
  "Temperature Error": "Errore temperatura",
  "Test Cancelled": "Test annullato",
  "Test Lot Not Permitted": "Lotto test non autorizzato",
  "Test Operation Error": "Errore esecuzione test",
  "Test Strip Lot Expired": "Lotto Strisce Reattive scaduto",
  "Test Strip Not Supported": "Striscia Reattiva non supportata",
  "Test Strip Reading Error": "Errore lettura Striscia Reattiva",
  "Test Timeout": "Timeout del test",
  "Test Type Not Permitted": "Tipo di test non autorizzato",
  "Th": "Gio",
  "Thailand": "Thailandia",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Il codice di convalida della nuova password verrà inviato all'e-mail specificata, se questa corrisponde a un utente valido.",
  "The reset validation code was populated from the link and should not be changed.": "Il codice di convalida della reimpostazione è stato inserito dal link e non deve essere modificato.",
  "There are unsaved changes. How do you want to proceed?": "Sono presenti modifiche non salvate. Come si desidera procedere?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "È disponibile una nuova versione (__versionNumber__). Installarla ora?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Il dispositivo non funge più da Connect Hub. Per continuare a usare questo dispositivo come Connect Hub, riabilitare Bluetooth nelle impostazioni del dispositivo e riattivare l'opzione Usa questo dispositivo mobile come Connect Hub nella schermata Hub.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Questa versione di LumiraDx Connect non è più compatibile con l'organizzazione. Vai  a __storeName__ e aggiorna alla versione più recente.",
  "Throat Swab": "Tampone faringeo",
  "Timor - Leste": "Timor Est",
  "Title": "Titolo",
  "TN Swab": "Tampone RF",
  "to": "a",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Per continuare a usare __APP_NAME__, effettuare l’aggiornamento alla versione più recente (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad e Tobago",
  "Tu": "Mar",
  "Tunisia": "Tunisia",
  "Turkey": "Turchia",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Isole Turks e Caicos",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ucraina",
  "Unable to reset password, the provided current password is not valid.": "Reimpostazione password non riuscita. La password attuale digitata non è valida.",
  "Unable to reset your password.": "Impossibile reimpostare la password.",
  "Unable to validate access code.": "Impossibile convalidare il codice di accesso.",
  "United Arab Emirates": "Emirati Arabi Uniti",
  "United Kingdom": "Regno Unito",
  "United States of America": "Stati Uniti",
  "Unrecognized credentials. Please try again.": "Credenziali non riconosciute. Riprovare.",
  "Unsaved Changes": "Modifiche non salvate",
  "Update Available": "Aggiornamento disponibile",
  "Update Password": "Aggiorna password",
  "Urethral Swab": "Tampone uretrale",
  "Urine": "Urina",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "L'account dell'utente è bloccato. Riprova tra un'ora.",
  "User Interface Version": "Versione interfaccia utente",
  "User logged out due to inactivity.": "Sessione interrotta a causa di inattività.",
  "Username": "Nome utente",
  "Uzbekistan": "Uzbekistan",
  "Vaginal Swab": "Tampone vaginale",
  "Validate Access Code": "Convalida codice di accesso",
  "Validation Code": "Codice di convalida",
  "Validation Code is required.": "Codice di convalida obbligatorio.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela, Repubblica Bolivariana del",
  "Venous Blood": "Sangue venoso",
  "Version": "Versione",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Isole Vergini britanniche",
  "Virgin Islands, US": "Isole Vergini, Stati Uniti",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Tamp. vulvo-vaginale",
  "Wallis and Futuna Islands": "Isole Wallis e Futuna",
  "We": "Mer",
  "We are working to get you back up and running as quickly as possible.": "Stiamo lavorando per ripristinare l'operatività il più presto possibile.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Siamo spiacenti, LumiraDx Connect è fermo per manutenzione.",
  "Yemen": "Yemen",
  "You have been signed out due to a password reset attempt.": "La disconnessione è avvenuta a causa di un tentativo di reimpostazione della password.",
  "You have been signed out due to the creation of another session with the same credentials.": "La disconnessione è avvenuta a causa della creazione di un'altra sessione con le medesime credenziali.",
  "You have been signed out due to your account being disabled by an admin.": "La disconnessione è avvenuta a causa della disabilitazione dell'account utente da parte di un amministratore.",
  "You have been signed out due to your password being changed by an admin.": "La disconnessione è avvenuta a causa del cambiamento della password da parte di un amministratore.",
  "You have been signed out due to your user role being changed by an admin.": "La disconnessione è avvenuta a causa del cambiamento del ruolo dell'utente da parte di un amministratore.",
  "You have been signed out.": "È avvenuta la disconnessione.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "È necessario installare una versione diversa (__versionNumber__) di __APP_NAME__ affinché sia compatibile con l'organizzazione.",
  "Your password has expired. Please set a new password.": "La password è scaduta. Impostare una nuova password.",
  "Your session has expired. Please login again.": "La sessione è scaduta. Eseguire nuovamente l'accesso.",
  "Your session has expired. User is not authorized.": "La sessione è scaduta. L'utente non è autorizzato.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
