module.exports = {
  "__appName__ is not currently available.": "__appName__ är inte tillgängligt för närvarande.",
  "Access Code": "Åtkomstkod",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Kontot ${this.username} är låst på grund av 5 inloggningsförsök. Kontakta din administratör för att låsa upp det",
  "Afghanistan": "Afganistan",
  "Albania": "Albanien",
  "Algeria": "Algeriet",
  "American Samoa": "Amerikanska Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua och Barbuda",
  "App": "Program",
  "Application Error": "Programfel",
  "Application error.": "Programfel.",
  "Application is not currently available.": "Programmet är inte tillgängligt för närvarande.",
  "Apply Changes": "Tillämpa ändringar",
  "Apr": "Apr",
  "April": "April",
  "Argentina": "Argentina",
  "Armenia": "Armenien",
  "Aruba": "Aruba",
  "At least __value__ characters": "Minst __value__ tecken",
  "At least one lowercase letter": "Minst en liten bokstav",
  "At least one number": "Minst ett nummer",
  "At least one of these special characters: __value__": "Minst ett av dessa specialtecken: __value__",
  "At least one uppercase letter": "Minst en stor bokstav",
  "Aug": "Aug",
  "August": "Augusti",
  "Australia": "Australien",
  "Austria": "Österrike",
  "Azerbaijan": "Azerbajdzjan",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Vitryssland",
  "Belgium": "Belgien",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth är avstängt",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnien och Hercegovina",
  "Botswana": "Botswana",
  "Brazil": "Brasilien",
  "Bronchial Lavage": "Bronkialsköljning",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgarien",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Kambodia",
  "Cameroon": "Kamerun",
  "Canada": "Kanada",
  "Cancel": "Avbryt",
  "Cannot be empty": "Får inte vara tomt",
  "Cape Verde": "Kap Verde",
  "Capillary Blood": "Kapillärblod",
  "Cayman Islands": "Caymanöarna",
  "Central African Republic": "Centralafrikanska Republiken",
  "Cerebrospinal Fluid": "Spinalvätska/Likvor",
  "Cervical Swab": "Livmoderhalssvabb",
  "Chad": "Chad",
  "Change Access Code": "Ändra åtkomstkod",
  "Check again": "Kontrollera igen",
  "Chile": "Chile",
  "China": "Kina",
  "Clear Search": "Rensa sökning",
  "Close": "Stäng",
  "Cocos(Keeling) Islands and Chr": "Kokosöarna och Julön",
  "Colombia": "Colombia",
  "Comoros": "Komorerna",
  "Confirm": "Bekräfta",
  "Confirm New Password": "Bekräfta nytt lösenord",
  "Confirm new password": "Bekräfta nytt lösenord",
  "Confirm New Password is required.": "Bekräftat nytt lösenord krävs.",
  "Confirm Password is required.": "Bekräftat lösenord krävs.",
  "Confirm Password must match New Password": "Bekräftat lösenord måste stämma med det nya lösenordet",
  "Congo(Brazzaville)": "Kongo (Brazzaville)",
  "Congo(Kinshasa)": "Kongo (Kinshasa)",
  "Cook Islands": "Cooköarna",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ – LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ – LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Elfenbenskusten",
  "Croatia(Hrvatska)": "Kroatien (Hrvatska)",
  "Cuba": "Kuba",
  "Culture Broth": "Odlingsbuljong",
  "Current Password": "Aktuellt lösenord",
  "Current Password is required.": "Aktuellt lösenord krävs.",
  "Current password required": "Aktuellt lösenord krävs",
  "Cyprus": "Cypern",
  "Czech Republic": "Tjeckien",
  "Danish": "Danska",
  "Dec": "Dec",
  "December": "December",
  "Denmark": "Danmark",
  "Disabled": "Avaktiverad",
  "Discard Changes": "Radera ändringar",
  "Dismiss": "Avvisa",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Stämmer inte med ditt tidigare lösenord",
  "Does not use unsupported characters": "Använder inte tecken som inte stöds",
  "Domain": "Domän",
  "Domain is required.": "Domän krävs.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominikanska republiken",
  "Door Open": "Lucka öppen",
  "Dutch": "Nederländska",
  "Ecuador": "Ecuador",
  "Egypt": "Egypten",
  "El Salvador": "El Salvador",
  "Email": "E-postadress",
  "Email is required.": "E-post krävs.",
  "English (UK)": "Engelska (Storbritannien)",
  "English (US)": "Engelska (USA)",
  "Enter": "Enter",
  "Equatorial Guinea": "Ekvatorialguinea",
  "Eritrea": "Eritrea",
  "Error": "Fel",
  "Estonia": "Estland",
  "Ethiopia": "Etiopien",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager stöds ej i Connect mobilapplikation.",
  "Failed to reset password. A server error was encountered": "Det gick inte att återställa lösenordet. Ett serverfel påträffades",
  "Falkland Islands(Malvinas)": "Falklandsöarna (Malvinerna)",
  "Faroe Islands": "Färöarna",
  "Fatal Application Error": "Fatalt programfel",
  "Feb": "Feb",
  "February": "Februari",
  "Fecal Specimen": "Avföringsprov",
  "Fiji": "Fiji",
  "File type must be __fileType__": "Filtyp måste vara __fileType__",
  "Finland": "Finland",
  "Finnish": "Finska",
  "First Name": "Förnamn",
  "Forgot your password?": "Glömt lösenordet?",
  "Fr": "Fre",
  "France": "Frankrike",
  "French": "Franska",
  "French Guiana": "Franska Guyana",
  "French Polynesia": "Franska Polynesien",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgien",
  "German": "Tyska",
  "Germany": "Tyskland",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Gå till __storeName__",
  "Greater than __value__ in __measure__": "Större än __value__ i __measure__",
  "Greece": "Grekland",
  "Greenland": "Grönland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hematokrit utanför intervall",
  "Hide": "Dölj",
  "Hide password": "Dölj lösenord",
  "Holy See(Vatican City State)": "Heliga stolen (Vatikanstaden)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hongkong, SAR",
  "Hungary": "Ungern",
  "Iceland": "Island",
  "India": "Indien",
  "Indonesia": "Indonesien",
  "Initials": "Initialer",
  "Install": "Installera",
  "Install Now": "Installera nu",
  "Instrument Not Level": "Instrument ej plant",
  "Insufficient Sample": "Otillräckligt prov",
  "Internet connection is offline.": "Internetanslutning är offline.",
  "Invalid access code": "Ogiltig åtkomstkod",
  "Invalid email format": "Ogiltigt e-postformat",
  "Invalid File": "Ogiltig fil",
  "Invalid Lot Cal File": "Ogiltig LOT-kalibreringsfil",
  "Invalid username or password.": "Ogiltigt användarnamn eller lösenord.",
  "Iran, Islamic Republic of": "Iran, Islamiska republiken av",
  "Iraq": "Irak",
  "Ireland": "Irland",
  "Israel": "Israel",
  "Italian": "Italienska",
  "Italy": "Italien",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "Januari",
  "Japan": "Japan",
  "Japanese": "Japanska",
  "Jordan": "Jordan",
  "Jul": "Jul",
  "July": "Juli",
  "Jun": "Jun",
  "June": "Juni",
  "Kazakhstan": "Kazakstan",
  "KC Swab": "KC-svabb",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Demokratiska Folkets Republik",
  "Korea, Republic of(South)": "Korea, Republiken (Syd)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kyrgyzstan",
  "Language": "Språk",
  "Laos(Lao PDR)": "Laos (Lao PDR)",
  "Last Name": "Efternamn",
  "Latvia": "Lettland",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Mindre än __value__ i __measure__",
  "Liberia": "Liberia",
  "Libya": "Libyen",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Kontrollösning",
  "Lithuania": "Litauen",
  "Loading more records...": "Laddar flera registreringar…",
  "Login": "Logga in",
  "Logout": "Logga ut",
  "Luxembourg": "Luxemburg",
  "Macao(SAR China)": "Macau (SAR Kina)",
  "Macedonia, Republic of": "Makedonien, Republiken",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysia",
  "Maldives": "Maldiverna",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Mars",
  "Marshall Islands": "Marshallöarna",
  "Martinique": "Martinique",
  "Mauritania": "Mauretanien",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Maxlängden är __count__ tecken",
  "May": "Maj",
  "Mexico": "Mexiko",
  "Micronesia, Federated States o": "Mikronesiska federationen",
  "Mo": "Mån",
  "Mobile Application Version": "Mobilapplikation version",
  "Moldova": "Moldavien",
  "Monaco": "Monaco",
  "Mongolia": "Mongoliet",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Morocco och Västsahara",
  "Mouth Swab": "Munhåla provpinne",
  "Mozambique": "Mozambique",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Nässekret",
  "Nasal Swab": "Nässwab",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Nederländerna",
  "Netherlands Antilles": "Nederländska Antillerna",
  "Never Connected": "Aldrig ansluten",
  "New Caledonia": "Nya Kaledonien",
  "New Password": "Nytt lösenord",
  "New Password is invalid.": "Det nya lösenordet är ogiltigt.",
  "New Password is required.": "Nytt lösenord krävs.",
  "New password must match": "Nytt lösenord måste stämma överens",
  "New Version Required": "Ny version krävs",
  "New Zealand": "Nya Zeeland",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Inga programmoduler finns i denna miljö. Rapportera detta problem till en administratör.",
  "No easily guessable words": "Inga ord som är enkla att gissa",
  "No more than __value__ characters": "Inte fler än __value__ tecken",
  "No region set": "Region ej inställd",
  "No repetitive characters (e.g. aaa)": "Inga upprepade tecken (t.ex. aaa)",
  "No sequential characters (e.g. 123)": "Inga sekventiella tecken (t.ex. 123)",
  "No Test Strip Detected": "Inget Testkort detekterat",
  "none": "inga",
  "Norfolk Island": "Norfolkön",
  "Northern Mariana Islands": "Nordmarianerna",
  "Norway(inc Svalbard and Jan May": "Norge (inkl. Svalbard och Jan Mayen",
  "Norwegian": "Norska",
  "Nov": "Nov",
  "November": "November",
  "NP Aspirate": "Nasofarynxaspirat",
  "NP Swab": "Nasofarynxpinne",
  "Oct": "Okt",
  "October": "Oktober",
  "Offline 1 day": "Offline en dag",
  "Offline 1 hour": "Offline en timme",
  "Offline 1 minute": "Offline en minut",
  "Offline 1 month": "Offline en månad",
  "Offline 1 year": "Offline ett år",
  "Offline __days__ days": "Offline __days__ dagar",
  "Offline __hours__ hours": "Offline __hours__ timmar",
  "Offline __minutes__ minutes": "Offline __minutes__ minuter",
  "Offline __months__ months": "Offline __months__ månader",
  "Offline __years__ years": "Offline __years__ år",
  "OK": "OK",
  "Okay": "Okej",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestinska territorier, ockuperade",
  "Panama": "Panama",
  "Papua New Guinea": "Papua Nya Guinea",
  "Paraguay": "Paraguay",
  "Password": "Lösenord",
  "Password requirements:": "Krav på lösenord:",
  "Peru": "Peru",
  "Philippines": "Filippinerna",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugisiska (Brasilien)",
  "Portuguese (Portugal)": "Portugisiska (Portugal)",
  "Profile": "Profil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Tillhandahåll den följande informationen för att ställa in ditt lösenord.  Valideringskoden har skickats till din e-postadress om den är giltig.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Uppge din e-postadress för att be om en återställning av lösenord. En tillfällig valideringskod kommer att skickas till din e-postadress.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC-lösning detekterad",
  "Rectal Swab": "Rektalsvabb",
  "Required Update": "Uppdatering krävs",
  "Reset": "Återställ",
  "Reset Password": "Återställ lösenord",
  "Reset password": "Återställ lösenord",
  "Resolution requirements": "Krav på lösning",
  "Return to Login": "Gå tillbaka till Logga in",
  "Romania": "Rumänien",
  "Russian Federation": "Ryska federationen",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion och Mayotte",
  "Sa": "Lör",
  "Saint Helena and Tristan da Cu": "Saint Helena och Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts och Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre och Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent och Grenadine",
  "Saliva": "Saliv",
  "Samoa": "Samoa",
  "Sample Detected": "Prov detekterat",
  "Sample Error": "Provfel",
  "Sample Type Error": "Provtypsfel",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudiarabien",
  "Save": "Spara",
  "Select a date": "Välj ett datum",
  "Select from list...": "Välj från listan…",
  "Select language": "Välj språk",
  "Selected files must match requirements": "Valda filer måste matcha kraven",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "September",
  "Serbia": "Serbien",
  "Serum": "Serum",
  "Server error, please try again later.": "Serverfel, försök igen senare.",
  "Server Error. Unable to save your profile.": "Serverfel. Kan inte spara din profil.",
  "Set Password": "Ställ in lösenord",
  "Seychelles": "Seychellerna",
  "Show Password": "Visa lösenord",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Storlek måste vara mindre än __maxSize__",
  "Skin Swab": "Hudsvabb",
  "Slovakia": "Slovakien",
  "Slovenia": "Slovenien",
  "Solomon Islands": "Solomonöarna",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Tyvärr är inte DataView aktiverad, eller så har du inte tillgång till organisationen",
  "South Africa": "Sydafrika",
  "Space": "Mellanslag",
  "Spain": "Spanien",
  "Spanish": "Spanska",
  "Spanish (US)": "Spanska (US)",
  "Sputum": "Sputum",
  "Sri Lanka": "Sri Lanka",
  "Su": "Sön",
  "Sudan": "Sudan",
  "Suriname": "Surinam",
  "Swaziland": "Swaziland",
  "Sweden": "Sverige",
  "Swedish": "Svenska",
  "Switzerland": "Schweiz",
  "Syrian Arab Republic(Syria)": "Syrien",
  "São Tomé and Príncipe": "São Tomé och Príncipe",
  "Taiwan, Republic of China": "Taiwan, Republiken Kina",
  "Tajikistan": "Tajikistan",
  "Tanzania, United Republic of": "Förenade republiken Tanzania",
  "Temperature Error": "Temperaturfel",
  "Test Cancelled": "Analys avbruten",
  "Test Lot Not Permitted": "Ej tillåten LOT-analys",
  "Test Operation Error": "Analyseringsfel",
  "Test Strip Lot Expired": "Utgången LOT på Testkort",
  "Test Strip Not Supported": "Ej användbart Testkort",
  "Test Strip Reading Error": "Läsfel för Testkort",
  "Test Timeout": "Timeout för analys",
  "Test Type Not Permitted": "Ej tillåten analystyp",
  "Th": "Tor",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "En valideringskod för det nya lösenordet kommer att skickas till e-postadressen, om e-postadressen är en giltig användare.",
  "The reset validation code was populated from the link and should not be changed.": "Valideringskoden för återställning fylldes i från länken och ska inte ändras.",
  "There are unsaved changes. How do you want to proceed?": "Det finns osparade ändringar. Hur vill du fortsätta?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Det finns en ny version tillgänglig (__versionNumber__). Vill du installera den nu?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Denna enhet fungerar inte längre som en Connect Hub. För att fortsätta använda denna enhet som en Connect Hub måste du återaktivera Bluetooth i din enhets inställningar och sätta på ”Använd denna mobila enhet som en Connect Hub” på Hubs-skärmen.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Denna version av LumiraDx Connect är inte längre kompatibel med din organisation. Gå till __storeName__ och uppdatera till den senaste versionen.",
  "Throat Swab": "Svalgprov",
  "Timor - Leste": "Timor-Leste",
  "Title": "Titel",
  "TN Swab": "TN provpinne",
  "to": "till",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "För att fortsätta använda __APP_NAME__, uppgradera till den senaste versionen (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad och Tobago",
  "Tu": "Tis",
  "Tunisia": "Tunisien",
  "Turkey": "Turkiet",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- och Caicosöarna",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraina",
  "Unable to reset password, the provided current password is not valid.": "Det går inte att återställa lösenordet. Det angivna aktuella lösenordet är inte giltigt.",
  "Unable to reset your password.": "Det går inte att återställa ditt lösenord.",
  "Unable to validate access code.": "Det går inte att validera åtkomstkoden.",
  "United Arab Emirates": "Förenade Arabemiraten",
  "United Kingdom": "Storbritannien",
  "United States of America": "USA",
  "Unrecognized credentials. Please try again.": "Inloggningsuppgifterna känns inte igen. Försök igen.",
  "Unsaved Changes": "Osparade ändringar",
  "Update Available": "Uppdatering tillgänglig",
  "Update Password": "Uppdatera lösenord",
  "Urethral Swab": "Uretrapinne",
  "Urine": "Urin",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Användarkontot är låst. Försök igen om en timme.",
  "User Interface Version": "Användargränssnitt version",
  "User logged out due to inactivity.": "Användaren har loggats ut på grund av inaktivitet.",
  "Username": "Användarnamn",
  "Uzbekistan": "Uzbekistan",
  "Vaginal Swab": "Vaginalsvabb",
  "Validate Access Code": "Validera åtkomstkod",
  "Validation Code": "Valideringskod",
  "Validation Code is required.": "Valideringskod krävs.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Bolivarianska republiken Venezuela",
  "Venous Blood": "Venöst blod",
  "Version": "Version",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Brittiska Jungfruöarna",
  "Virgin Islands, US": "Amerikanska Jungfruöarna",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvovaginalsvabb",
  "Wallis and Futuna Islands": "Wallis- och Futunaöarna",
  "We": "Ons",
  "We are working to get you back up and running as quickly as possible.": "Vi jobbar på att du så snabbt som möjligt ska kunna sätta igång igen.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Vi beklagar men LumiraDx Connect ligger för närvarande nere på grund av underhåll.",
  "Yemen": "Yemen",
  "You have been signed out due to a password reset attempt.": "Du har loggats ut på grund av ett försök till en lösenordsåterställning.",
  "You have been signed out due to the creation of another session with the same credentials.": "Du har loggats ut på grund av att det har skapats en annan session med samma inloggningsuppgifter.",
  "You have been signed out due to your account being disabled by an admin.": "Du har loggats ut på grund av att ditt konto har inaktiverats av en administratör.",
  "You have been signed out due to your password being changed by an admin.": "Du har loggats ut på grund av att ditt lösenord har ändrats av en administratör.",
  "You have been signed out due to your user role being changed by an admin.": "Du har loggats ut på grund av att din användarroll har ändrats av en administratör.",
  "You have been signed out.": "Du har loggats ut.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Du behöver installera en annan version (__versionNumber__) av __APP_NAME__ för att vara kompatibel med din organisation.",
  "Your password has expired. Please set a new password.": "Ditt lösenord är utgånget. Ange ett nytt lösenord.",
  "Your session has expired. Please login again.": "Din session har gått ut. Logga in igen.",
  "Your session has expired. User is not authorized.": "Din session har gått ut. Användaren saknar behörighet.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
