import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Pvr } from '@Lineup/index';
import { InjectedComponent, IComponent } from '../../global/interfaces';

const styles = require('./styles/VersionsPvr.styl');

interface IProps extends IComponent {
  anchor: HTMLElement;
  close: () => void;
}

@inject('mobileBridgeStore') @observer
class VersionPvr extends InjectedComponent<IProps, any> {

  public render(): JSX.Element {
    const { anchor, close, mobileBridgeStore: { appData: { AppVersion } }} = this.props;
    return (
      <Pvr anchor={anchor} width={200} height={107} close={close}>
        <div className={styles.Container}>
          <dt>{t('User Interface Version')}</dt>
          <dd>{`${process.env.UI_VERSION}`}</dd>
          <dt>{t('Mobile Application Version')}</dt>
          <dd>{AppVersion}</dd>
        </div>
      </Pvr>
    );
  }
}

export default VersionPvr;
