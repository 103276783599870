import * as React from 'react';

const styles = require('./styles/selection_wheel.styl');

interface IState {
  pressed: boolean;
  scrolling: boolean;
  items: ISelectionWheelItem[];
}

enum Direction {
  UP,
  DOWN,
}

export enum SelectionWheelAlignments {
  LEFT,
  RIGHT,
  CENTER
}

export interface ISelectionWheelItem {
  text: string;
  value?: any;
  selected?: boolean;
  position?: number;
  height?: number;
  ref?: React.RefObject<HTMLDivElement>;
}

export interface ISelectionWheelProps {
  items: ISelectionWheelItem[];
  selectionChange?: (items: any) => void;
  vMax?: number;
  snapToItem?: boolean;
  align?: SelectionWheelAlignments;
  jsonPath?: string;
}

export default class SelectionWheel extends React.Component<ISelectionWheelProps, IState> {

  private scrollRef: HTMLDivElement;
  private scrollTop = 0;
  private kScrollTop = 0;
  private startY = 0;
  private startTime;
  private endY = 0;

  public static defaultProps: ISelectionWheelProps = {
    snapToItem: true,
    items: [{text: 'No Items', value: '', selected: false}],
    align: SelectionWheelAlignments.CENTER,
  };

  constructor(props) {
    super(props);
    const items = [];
    const itemsFromProps = props.items;

    itemsFromProps?.forEach(item => {
      items.push({...item, value: item.value ?? item.text, position: 0, height: 0, ref: React.createRef()});
    });

    this.state = {
      pressed: false,
      items: items,
      scrolling: false,
    };
  }

  public static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.items !== null && ((nextProps.items.length !== prevState.items.length)
      || (nextProps.items.map(e => e.text).join() !== prevState.items.map(e => e.text).join()))) {

      const newStateItems = [];
      nextProps.items?.forEach(item => {
        newStateItems.push({text: item.text, selected: item.selected, value: item.value ?? item.text, position: 0, height: 0, ref: React.createRef()});
      });
      return { items: newStateItems };
    } else{
      return null;
    }
  }

  public componentDidUpdate(prevProps, prevState): void {
    if (this.state.items !== null && ((this.state.items.length !== prevState.items.length)
      || (this.state.items.map(e => e.text).join() !== prevState.items.map(e => e.text).join()))) {
      const items = this.calculatePositions(this.state.items);
      this.setState({ items }, () => {
        const initialSelection = items.find(item => item.selected)?.position ?? 0;
        this.scrollRef.scrollTo?.(0, initialSelection === 0 ? items[0]?.position ?? 0 : initialSelection);
      });
    }
  }

  public componentDidMount(): void {
    // TODO: figure out better solution: reason for the setTimeout: the overlay(react portal) breaks the getBoundingClientRect
    setTimeout(() => {
      const items = this.calculatePositions(this.state.items);

      // default select first item if nothing selected
      if (!items.find(i => i.selected)) {
        items[0].selected = items && items.length > 0;

        this.props.selectionChange(items);
      }

      this.setState({ items });
      const initialSelection = items.find(item => item.selected)?.position;
      this.scrollRef.scrollTo?.(0, initialSelection);

    }, 1);
  }

  public calculatePositions = (items): any => {
    const inputTop = this.scrollRef.getBoundingClientRect().top;
    const inputScrollTop = this.scrollRef.scrollTop;
    // position relative to the scroll top (190: assuming the top padding hasn't changed) = scroll input padding (212) - half the height of the item (22)
    const relativePositionOffset = parseFloat(window.getComputedStyle(this.scrollRef).getPropertyValue('padding-top'));
    items.forEach(item => {
      item.position = (inputScrollTop + item.ref.current.getBoundingClientRect().top - inputTop - relativePositionOffset) + (item.ref.current.offsetHeight / 2);
      item.height = item.ref.current.offsetHeight;
    });

    return items;
  };

  public render(): JSX.Element {
    const { align } = this.props;

    let itemAlignment;
    switch (align) {
      case SelectionWheelAlignments.RIGHT:
        itemAlignment = styles.RightAlign;
        break;
      case SelectionWheelAlignments.LEFT:
        itemAlignment = styles.LeftAlign;
        break;
      case SelectionWheelAlignments.CENTER:
        itemAlignment = styles.CenterAlign;
        break;
      default:
        itemAlignment = styles.CenterAlign;
        break;
    }

    const { jsonPath } = this.props;

    return (
      <>
        <div className={`${styles.Scroll}`}
          onScroll={this.handleScroll}
          onPointerDown={this.handleonMouseDown}
          onPointerUp={this.handleonMouseUp}
          onPointerLeave={this.handleonMouseUp}
          onPointerMove={this.handleMouseMove}
          ref={(el) => this.scrollRef = el}
          data-test={`scroll-wheel-${jsonPath}`}
        >
          {
            this.state.items.map((item) => {
              return (
                <div data-test={item.value} ref={item.ref} key={item.text} className={`${styles.Item} ${item.selected && !this.state.scrolling && styles.Selected}`}>
                  <div className={`${styles.ItemText} ${itemAlignment}`}>{item.text}</div>
                </div>
              );
            })
          }
        </div>
      </>
    );
  }

  private handleonMouseDown = (event: any): void => {
    this.setState({pressed: true, scrolling: true});
    event.preventDefault();
    this.scrollTop = this.scrollRef.scrollTop;
    this.startY = event.clientY;
    this.endY = 0;
    this.startTime = Date.now();
  };

  private handleonMouseUp = (event: any): void => {
    event.preventDefault();
    const { pressed, items } = this.state;
    if (!pressed) {
      return;
    }

    this.endY = this.endY !== 0 ? this.endY : this.startY;

    const elapsed = Date.now() - this.startTime;
    const naturalVelocity = 100 * (this.startY - this.endY) / (elapsed);
    const velocity = naturalVelocity > 0 ? Math.min(naturalVelocity, items.length * 5) : Math.max(naturalVelocity, items.length * -5);
    this.setState({pressed: false, scrolling: false});

    if (velocity > 50 || velocity < -50) {
      window.requestAnimationFrame(() => this.kineticScroll(velocity));
    } else {
      this.snapToItem(this.startY < this.endY ? Direction.DOWN : Direction.UP);
    }
  };

  private kineticScroll = (velocity, callback?: () => void): void => {
    const { pressed } = this.state;
    if (velocity && !pressed) {
      this.setState({scrolling: true});
      const elapsed = Date.now() - this.startTime;
      const distance = -(0.8 * velocity) * Math.exp(-elapsed / 325); // ms

      if ((distance > 5 || distance < -5)  && this.scrollRef && this.scrollRef.scrollTop !== this.kScrollTop) {

        this.kScrollTop = this.scrollRef.scrollTop;
        this.scrollRef.scrollTop = this.scrollRef.scrollTop - distance;
        window.requestAnimationFrame(() => this.kineticScroll(velocity));
      } else {
        this.snapToItem(velocity > 0 ? Direction.DOWN : Direction.UP);
        this.setState({scrolling: false});

        callback?.();
      }
    } else {
      this.snapToItem(velocity > 0 ? Direction.DOWN : Direction.UP);
    }

  };

  private handleMouseMove = (event: any): void => {
    event.preventDefault();

    if (this.state.pressed && this.scrollRef) {
      this.scrollRef.scrollTop = this.scrollTop + this.startY - event.clientY;
    }
    this.endY = event.clientY;
  };

  private handleScroll = (event: any): void => {
    this.highlightMidItem();
  };

  private highlightMidItem = (): void => {
    const { items } = this.state;
    const midPoint = this.scrollRef.scrollTop;
    items.forEach(element => {
      element.selected = (element.position - (element.height * 0.8)) < midPoint && (element.position + (element.height * 0.8)) > midPoint;
    });

    this.setState({items});
  };

  private snapToItem = (direction?: Direction): void => {
    if (!this.props.snapToItem || !this.scrollRef) {
      return;
    }
    const top = this.state.items?.find((item) => item.selected)?.position ?? -1;

    if (top === -1) {
      this.startTime = Date.now();
      this.kineticScroll(direction === Direction.DOWN ? -30 : 30);
    } else {
      this.scrollRef.scrollTo?.({top: top, behavior: 'smooth'});
      this.props.selectionChange(this.state.items);
    }
  };
}
