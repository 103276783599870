import * as React from 'react';
import { PvrDirections } from './interfaces';
import SelectPvr, { ISelectPvrOption } from './SelectPvr';

interface IProps {
  onOptionSelect: (option) => void;
  options: ISelectPvrOption[];
  current: ISelectPvrOption;
  label: JSX.Element | string;
  className: string;
  labelClassName: string;
  disabled: boolean;
}

interface IState {
  showPopup: boolean;
}

export default class SelectPvrAnchorLabel extends React.Component<IProps, IState> {
  private currentText: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    onFilterChange: null,
    options: [],
    label: null,
    current: null,
    className: null,
    labelClassName: null,
    disabled: false,
  };

  public constructor(props) {
    super(props);
    this.currentText = React.createRef();
  }

  public state: IState = {
    showPopup: false,
  };

  public render(): JSX.Element {
    const { onOptionSelect, options, current, className, disabled, label, labelClassName } = this.props;
    const { showPopup } = this.state;
    return (
      <>
        <div ref={this.currentText} className={className} onClick={disabled ? void(0) : this.togglePopup}>
          <div className={labelClassName}>{label ?? current.label}</div>
        </div>
        {showPopup &&
          <SelectPvr
            close={this.togglePopup}
            onChange={onOptionSelect}
            options={options}
            defaultSelected={current}
            optionHeight={55}
            styleMixin={{ fontSize: '20px' }}
            pvrProps={{
              closeOnAnchorLeave: true,
              direction: PvrDirections.BELOW,
              showNib: true,
              anchor: this.currentText.current,
              hSlide: 135,
              hAdjust: 98,
              width: 310,
              animateIn: false,
            }}
          />}
      </>
    );
  }

  private togglePopup = (): void => {
    this.setState({ showPopup: !this.state.showPopup });
  };
}
