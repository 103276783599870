import * as React from 'react';
import importedComponent from 'react-imported-component';
import amd from '../../helpers/amd';
import { Spinner } from '@Lineup/index';
import { SpinnerNames } from '@Lineup/enums/index';
import { lumiraBlue } from '../../global/variables';
import { IModuleManifest, IComponent } from '../../global/interfaces';
import { inject, observer } from 'mobx-react';
import { chooseModuleVersion } from '../../global/helpers';
const { importModule } = amd;

const styles = require('./styles/App.styl');

interface IProps extends IComponent {
  module: IModuleManifest;
  showLoader?: boolean;
  rootPath: string;
  version?: string;

  [x: string]: any;
}

@inject('applicationStore', 'loggedInUserStore') @observer
export default class ModuleContainer extends React.Component<IProps, any> {
  private Component;

  constructor(props: IProps) {
    super(props);
    const { showLoader = true, module, loggedInUserStore, applicationStore, rootPath } = props;

    const root = rootPath.substr(1).split('/')[0];
    const { id } = module;
    const { accessDto, moduleVersionOverride } = loggedInUserStore;
    const { modulesById } = applicationStore;
    const { availableVersions = [] } = modulesById.get(id);
    const versions = Array.from(availableVersions, (v) => v[0]);

    // Determine the best version match for the module
    // When the root is tools where is no accessCode
    const version = (root === 'tools') ? versions[0] : moduleVersionOverride.get(id) ?? chooseModuleVersion(accessDto?.Version || this.props.version, versions, modulesById.get(id).version);

    const moduleNameAndVersion = process.env.CS || availableVersions.length === 0 ? id : `${id}/${version}`;

    this.Component = importedComponent(
      () => importModule(`/ui-modules/${moduleNameAndVersion}/${id}.js`), {
        LoadingComponent: (props) => {
          const { error } = props;
          return error ? (
            <div style={{ color: 'red' }}>
              {t('Application error.')}
            </div>) : (
            showLoader ? (
              <Spinner
                key="spinner"
                wrapperClass={styles.ModuleLoader}
                name={SpinnerNames.BallScaleRipple}
                color={lumiraBlue}
              />
            ) : null
          );
        },
      });
  }

  public render(): JSX.Element {
    const { Component } = this;
    return <Component {...this.props} />;
  }
}
