import * as React from 'react';
import Modal from './Modal';
import Pvr from './Pvr';
import AlertIcon, { AlertTypes } from './AlertIcon';
import { SaveStates } from './ConfirmSave';

const styles = require('./styles/alert_modal.styl');
const modalStyles = require('./styles/modal.styl');

/* &
  @props.close - REQUIRED - [Function]
  Function that closes the modal

  @props.alertTitle - REQUIRED - [String]
  The main point of the alert modal, eg 'Delete Item' or 'Unsaved Changes'

  @props.message - REQUIRED - [String]
  Can be a string that is the detail text of the alert modal, rendering under the the title.
  Can be a react element

  @props.cancelText
  Default to 'Cancel'. The title of the 'Cancel' or 'Dismiss' button.
  Note: If the props.cb method is NOT passed this button is not rendered.

  @props.okText
  Default to 'OK'. The title of the 'okay' or 'confirm' button.
  Note: This functions as the cancel or close button if th props.cb method is not passed

  @props.okColor
  Default to the primary blue. The color of the 'okay' or 'confirm' button.

  @props.cb
  Function that gets called when the 'OK' button is clicked. Presumably this executes some sort of action like navigating the user or deleting an item.
  When it's not provided, no okay button is rendered and only a cancel/close button is present

  @props.alertIcon - OPTIONAL - [Enum] - 'WARNING', 'DELETE'
  Defaults to 'WARNING'. The icon to show next to the alert title.
  Note: will not show if no alertTitle is passed.

  @props.isPvr - OPTIONAL - [boolean]
  Defaults to FALSE. This allows the user to open the alert modal as an alert popover
  When true the alert will display in a popover

  @props.pvrProps - Object
  When opening the alert in a popover (isPvr = true) this will allow us to pass props to the popover
  I.e. direction, etc

&*/

export interface IAlertModalProps {
  close: () => void;
  alertTitle: string | JSX.Element;
  message: string | JSX.Element;
  okText?: string;
  okColor?: string;
  cancelText?: string;
  cb?: () => void;
  alertIcon?: AlertTypes;
  autoCloseOnCb?: boolean;
  saveState?: SaveStates;
  saveMessage?: string;
  onSaveFail?: () => void;
  shiftSaveMessage?: number;
  isPvr?: boolean;
  pvrProps?: any;
  backdropClose?: boolean;
  className?: string;
  styleMixin?: React.CSSProperties;
  backdrop?: boolean;
}

export default class AlertModal extends React.Component<IAlertModalProps, any > {
  public static defaultProps = {
    okText: 'OK',
    cancelText: 'Cancel',
    alertIcon: AlertTypes.WARNING,
    autoCloseOnCb: true,
    saveState: null,
    saveMessage: null,
    shiftSaveMessage: -38,
    isPvr: false,
    backdropClose: false,
    styleMixin: null,
    backdrop: true,
  };

  public render(): JSX.Element {
    const { message, okText, okColor, cancelText, cb, alertIcon, alertTitle, saveState, saveMessage, onSaveFail, shiftSaveMessage, isPvr, pvrProps, backdropClose, className, styleMixin, backdrop } = this.props;

    let color;
    if (okColor != null) {
      color = okColor;
    }
    else if (alertIcon === AlertTypes.DELETE) {
      color = 'rgb(236,26,35)';
    }

    const content = [];
    const iconEl = <AlertIcon type={alertIcon} />;

    content.push((
      <div key="titleWrap" className={styles.Header}>
        <div className={styles.Icon} key="icon">
          {iconEl}
        </div>
        <div key="title" className={styles.Title}>
          {alertTitle}
        </div>
      </div>
    ));

    const messageClass = isPvr ? styles.PvrMessage : styles.ModalMessage;

    content.push((
      <div key="message" className={messageClass}>
        {message}
      </div>
    ));

    const okayBtn = cb != null ? (
      <button
        key="ok"
        className={`${styles.OkayCancel} ${styles.Okay}`}
        onClick={this.confirm}
        style={color ? { color } : null}
        data-test="okay"
      >
        {okText}
      </button>
    ) : null;

    content.push((
      <div key="btns" className={styles.Buttons}>
        <button
          key="cancel"
          className={cb != null ? `${styles.OkayCancel} ${styles.Cancel}` : `${styles.OkayCancel} ${styles.JustCancel}`}
          onClick={this.close}
          data-test="cancel"
        >
          {cancelText}
        </button>
        {okayBtn}
      </div>
    ));

    return isPvr ? (
      <Pvr {...pvrProps}>
        {content}
      </Pvr>
    ) : (
      <Modal
        close={this.close}
        showClose={false}
        className={`${modalStyles.AlertModal} ${className ? className : ''}`}
        draggable={false}
        saveState={saveState}
        saveMessage={saveMessage}
        onSaveFail={onSaveFail}
        shiftSaveMessage={shiftSaveMessage}
        backdrop={backdrop}
        backdropClose={backdropClose}
        style={styleMixin}
        zIndex={99}
      >
        {content}
      </Modal>
    );
  }

  private close = (): void => {
    this.props.close();
  };

  private confirm = (): void => {
    const { cb, autoCloseOnCb, close } = this.props;
    if (typeof cb === 'function') {
      cb();
    }
    if (autoCloseOnCb) {
      close();
    }
  };

}
