import * as React from 'react';
import DevTargetChoicePvr from './DevTargetChoicePvr';
import { makeGuid } from '@Lineup/enums/index';
import { LoggedInUser } from '../../stores';

import { observer, inject } from 'mobx-react';

const styles = require('../Login/styles/Login.styl');

interface IProps {
  loggedInUserStore?: LoggedInUser;
  devServices?: LoggedInUser['targets'];
}

@inject('loggedInUserStore') @observer
export default class DevServiceSelect extends React.Component<IProps, any> {

  public state = {
    selecting: false,
    choices: null,
    selected: null,
    selectAnchor: null,
  };

  public render(): JSX.Element {
    const { activeTarget, targets } = this.props.loggedInUserStore;
    const { selecting, selectAnchor } = this.state;

    if (!targets.length) {
      return null;
    }

    const { name } = activeTarget;

    const choicePvr = selecting ? (
      <DevTargetChoicePvr
        targets={targets.map((ds) => {
          return {
            id: makeGuid(),
            label: ds.name,
            value: ds.id,
            isSelected: ds.name === name,
            children: null,
          };
        })}
        onClose={this.closeOverlay}
        onSelected={this.optionSelected}
        anchor={selectAnchor}
      />) : null;

    return (
      <div className={styles.CurrentTarget}>
        <span onClick={this.handleClick}>
          {name}
        </span>
        {choicePvr}
      </div>
    );
  }

  public handleClick = (e) => {
    this.setState({
      selecting: true,
      selectAnchor: e.target,
    });
  };

  public closeOverlay = () => {
    this.setState({
      selecting: false,
      options: null,
    });
  };

  public optionSelected = (item) => {
    const { parent, value} = item;
    const { loggedInUserStore } = this.props;

    const targetName = parent ? item.parent.name : item.label;

    loggedInUserStore.setTargetByName(targetName, value)
    .then(() =>
      this.setState({
        selecting: false,
        options: null,
        selected: null}
      ));
  };
}
