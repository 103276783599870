import * as React from 'react';
import Input from './Input';
import Checkbox, { ICheckboxProps } from './Checkbox';

export default class CheckboxInput extends React.Component<ICheckboxProps, any> {
  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Checkbox {...props} {...this.props} />
          );
        }}
      </Input>
    );
  }
}
