import * as React from 'react';
import Pvr from './Pvr';
import SelectPvrOption from './SelectPvrOption';
import ScrollContainer from './ScrollContainer';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/pvr.styl');
    break;
  default:
    styles = require('./styles/pvr.styl');
    break;
}

export interface ISelectPvrOption {
  isSubHeader?: boolean;
  isIndented?: boolean;
  disabled?: boolean;
  info?: string;
  id?: string;
  value?: string | number;
  subLabel?: string;
  label?: string;
  customClass?: string;
  multiSelectGroupId?: string | number;
  children?: any[];
  toolTip?: string;
}

export interface ISelectPvrProps {
  options?: ISelectPvrOption[];
  styleMixin?: React.CSSProperties;
  headerClass?: string;
  close?: () => any;
  optionHeight?: number;
  onChange?: (opt?: ISelectPvrOption) => any;
  maxHeight?: number;
  pvrProps?: any;
  canDeselect?: boolean;
  noWrapOptions?: boolean;
  defaultSelected?: ISelectPvrOption;
  multiSelect?: boolean;
  multiSelectKey?: string | number;
  multiSelectGroupId?: string | number;
  footerComponent?: React.ReactNode;
  footerHeight?: number;
  headerTitle?: string;
  hideSelected?: boolean;
}

export interface ISelectPvrState {
  selectedOptions?: ISelectPvrOption;
  aggregateOptionsHeight?: number;
  optionWithOpenedSubOptions?: ISelectPvrOption;
  scale?: number;
}

/* &
@general
Select popover menu with sub-option capability

@props.options - [Array] - Required
  array of objects containing at minimum a label and value attribute
  optionally a subLabel property can be passed

@props.defaultSelected - [Object|String] - Optional
  value of the option selected by default

@props.close - [Function] - Required
  func that closes the popover

@props.styleMixin - [Object] - Optional
  object containing any style properties to mixin with and/or overrride the defaults
  note that width height are passed separately so they can have defaults and auto settings
  passing widt/height in this object could cause issues

@props.onChange - [Function] - Required
  method to call when the non selected option is clicked

@props.hideSelected - [Boolean] - Optional
  when on, the defaultSelected option will be removed from the list

@props.headerTitle - [String] - Optional
  optional title String for popover header

@props.headerClass - [String] - Optional
  optional class for popover header

@props.maxHeight - [Number] - Optional
  the maximum height the popover should be. used to set height on the pvr if this is
  lower than the computed height.

@props.pvrProps - [Object] - Optional
  properties germane to PVR wrapper: width, height, anchor, hAdjust, vAdjust, direction

@props.multiSelect - [Boolean] - Optional
  allows the component to have multiple selections before firing the onChange handler.
  when multiSelect is enabled, onChange will be deferred to componentWillUnmount

@props.multiSelectKey - [String|Number] - Optional
  `default: 'id'`
  a custom property name can be defined for keying multiSelect options.
  must be a unique identifier.

@props.multiSelectGroupId - [String|Number] - Optional
  when `multiSelect = true`, selection scoping can be achieved by providing a group ID to separate selection groups.

@props.footerComponent - [Component] - Optional
  Create a footer space at the bottom of the SelectPvr with a passed component

@props.footerHeight - [Number] - Optional
  Set a fixed height to pass as calculated to the Pvr parent component. Used to size the footer space.
&*/
export default class SelectPvr extends React.Component<ISelectPvrProps, ISelectPvrState> {
  constructor(props) {
    super(props);
    const { defaultSelected, options, optionHeight, multiSelect } = this.props;

    this.state = {
      selectedOptions: defaultSelected,
      aggregateOptionsHeight: options.length * optionHeight,
      optionWithOpenedSubOptions: null,
    };

    this.multiSelectGroupIds = new Map();

    if (multiSelect) {
      options.forEach(o => {
        const { multiSelectGroupId } = o;
        if (this.multiSelectGroupIds.has(multiSelectGroupId)) {
          this.multiSelectGroupIds.set(multiSelectGroupId, this.multiSelectGroupIds.get(multiSelectGroupId) + 1);
        } else {
          this.multiSelectGroupIds.set(multiSelectGroupId, 1);
        }
      });
    }
  }

  public static defaultProps: ISelectPvrProps = {
    options: [],
    styleMixin: {},
    headerTitle: null,
    headerClass: '',
    hideSelected: false,
    optionHeight: 36,
    noWrapOptions: false,
    pvrProps: {},
    canDeselect: false,
    multiSelect: false,
    multiSelectKey: 'id',
    footerComponent: null,
    footerHeight: 0,
    defaultSelected: null,
  };

  private multiSelectGroupIds: Map<string | number, number>;
  private changeOnUnmount: boolean;
  private hasHeader: boolean;

  public componentDidMount(): void {
    const { multiSelect, options } = this.props;
    this.multiSelectGroupIds = new Map();

    if (multiSelect) {
      return options.forEach(o => {
        const { multiSelectGroupId } = o;
        if (this.multiSelectGroupIds.has(multiSelectGroupId)) {
          return this.multiSelectGroupIds.set(multiSelectGroupId, this.multiSelectGroupIds.get(multiSelectGroupId) + 1);
        } else {
          return this.multiSelectGroupIds.set(multiSelectGroupId, 1);
        }
      });
    }
  }

  public componentWillUnmount(): void {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;
    if (this.changeOnUnmount) {
      return onChange(selectedOptions);
    }
  }

  public render(): JSX.Element {
    const { styleMixin, options, hideSelected, optionHeight, headerTitle, headerClass, noWrapOptions, maxHeight, close, canDeselect, footerComponent, footerHeight } = this.props;
    const { scale, aggregateOptionsHeight, optionWithOpenedSubOptions } = this.state;

    const pvrProps = {
      ...this.props.pvrProps,
    };

    this.hasHeader = (headerTitle != null);

    if (pvrProps.height == null) {
      // 1px border on top and bottom
      const borderWidth = 2;
      pvrProps.height = aggregateOptionsHeight - (hideSelected ? optionHeight : 0) + borderWidth;
    }

    if (this.hasHeader) {
      pvrProps.height += 34;
    }

    if (footerComponent != null) {
      pvrProps.height += footerHeight;
    }

    if ((maxHeight != null) && (pvrProps.height > maxHeight)) {
      pvrProps.height = maxHeight;
    }

    const style: React.CSSProperties = {
      ...styleMixin,
    };

    if ((pvrProps.styleMixin != null ? pvrProps.styleMixin.maxHeight : undefined) != null) {
      style.maxHeight = pvrProps.styleMixin.maxHeight;
    }
    style.height = pvrProps.height;

    if (pvrProps.width) {
      style.width = pvrProps.width;
    }

    const optionEls = [];
    for (const option of Array.from(options)) {
      const { children, id, value, multiSelectGroupId } = option;
      const optionsEqual = this.compareOptions(option);

      if (hideSelected && optionsEqual) { continue; }

      const childItems = [];
      let subOptionsHeight = 0;

      if (children != null) {
        for (const chld of Array.from(children)) {
          if (chld != null) {
            const opth = chld.optionHeight || optionHeight;
            subOptionsHeight += opth;
            childItems.push(
              <SelectPvrOption
                key={chld.id || chld.value}
                option={chld}
                optionHeight={opth}
                isSelected={this.compareOptions(chld)}
                multiSelectGroupId={chld.multiSelectGroupId}
                canDeselect={chld.canDeselect}
                onChange={chld.handleChange || this.handleChange}
                noWrapOptions={chld.noWrapOptions}
                customClass={chld.customClass}
              />
            );
          }
        }
      }

      optionEls.push(
        <SelectPvrOption
          option={option}
          optionHeight={optionHeight}
          key={id || value}
          isSelected={optionsEqual}
          canDeselect={canDeselect}
          onChange={this.handleChange}
          noWrapOptions={noWrapOptions}
          subOptionsHeight={subOptionsHeight}
          setOpenSubOptions={this.setOpenSubOptions}
          multiSelectGroupId={multiSelectGroupId}
          isOpen={childItems.length && (optionWithOpenedSubOptions === option)}
        >{childItems}</SelectPvrOption>
      );
    }

    const header = this.hasHeader ? <div key="header" className={`${styles.Header} ${styles.PlainContentItem} ${headerClass ? headerClass : ''}`}>{headerTitle}</div> : null;

    pvrProps.scale = scale;
    pvrProps.close = close;

    return (
      <Pvr {...pvrProps}>
        <ScrollContainer className={`${styles.SelectPvr}`} style={style}>
          {header}
          {optionEls}
          {footerComponent}
        </ScrollContainer>
      </Pvr>
    );
  }

  public handleChange = (option, multiSelectGroupId): void => {
    const { multiSelect, multiSelectKey } = this.props;
    const { selectedOptions } = this.state;
    this.changeOnUnmount = this.multiSelectGroupIds.get(multiSelectGroupId) > 1;
    let opt = {};

    // Multi-select
    if (multiSelect) {
      opt = {
        ...opt,
        ...selectedOptions,
      };
      // Maintain selection by group ids
      if ((multiSelectGroupId != null) && (multiSelectGroupId !== __guard__(selectedOptions[Object.keys(selectedOptions)[0]], x => x.multiSelectGroupId))) {
        opt = {};
      }

      // De-select it
      if (opt[option[multiSelectKey]] != null) {
        delete opt[option[multiSelectKey]];

        // Select it
      } else {
        opt[option[multiSelectKey]] = option;
      }

      // No multi selection
    } else {
      opt = option;
    }

    this.setState({
      selectedOptions: opt,
    });

    if (!this.changeOnUnmount) {
      this.props.onChange(opt);
      return this.props.close();
    }
  };

  public setOpenSubOptions = (option: ISelectPvrOption, adjust: number): void => {
    const { options, optionHeight } = this.props;
    const agg = (options.length * optionHeight) + adjust;
    return this.setState({
      optionWithOpenedSubOptions: option,
      aggregateOptionsHeight: agg,
    });
  };

  public compareOptions(option: ISelectPvrOption) {
    const { selectedOptions } = this.state;
    const { multiSelect } = this.props;
    const { label } = option;

    if (typeof selectedOptions === 'string') {
      return selectedOptions === label;
    } else if (typeof selectedOptions === 'object') {
      if (multiSelect) {
        for (const k in selectedOptions) {
          if (Object.prototype.hasOwnProperty.call(selectedOptions, k)) {
            const o = selectedOptions[k];
            if (o.value === option.value) {
              return true;
            }
          }
        }
        return false;
      } else {
        return selectedOptions && selectedOptions.value === option.value;
      }
    }
  }
}

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
