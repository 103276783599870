module.exports = {
  "__appName__ is not currently available.": "__appName__ is op het moment niet beschikbaar.",
  "Access Code": "Toegangscode",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Account ${this.username} is vergrendeld vanwege 5 aanmeldpogingen. Neem contact op met uw beheerder om deze vrij te geven",
  "Afghanistan": "Afghanistan",
  "Albania": "Albanië",
  "Algeria": "Algerije",
  "American Samoa": "Amerikaans-Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua en Barbuda",
  "App": "App",
  "Application Error": "Applicatiefout",
  "Application error.": "Applicatiefout.",
  "Application is not currently available.": "Applicatie is momenteel niet beschikbaar.",
  "Apply Changes": "Wijzigingen toepassen",
  "Apr": "apr",
  "April": "april",
  "Argentina": "Argentinië",
  "Armenia": "Armenië",
  "Aruba": "Aruba",
  "At least __value__ characters": "Minimaal __value__ tekens",
  "At least one lowercase letter": "Minimaal één kleine letter",
  "At least one number": "Minimaal één cijfer",
  "At least one of these special characters: __value__": "Minimaal één van deze speciale tekens: __value__",
  "At least one uppercase letter": "Minimaal één hoofdletter",
  "Aug": "aug",
  "August": "augustus",
  "Australia": "Australië",
  "Austria": "Oostenrijk",
  "Azerbaijan": "Azerbeidzjan",
  "Bahamas": "Bahama's",
  "Bahrain": "Bahrein",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Belarus",
  "Belgium": "België",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth is uitgeschakeld",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnië en Herzegovina",
  "Botswana": "Botswana",
  "Brazil": "Brazilië",
  "Bronchial Lavage": "Bronchiale lavage",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgarije",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Cambodja",
  "Cameroon": "Kameroen",
  "Canada": "Canada",
  "Cancel": "Annuleren",
  "Cannot be empty": "Kan niet leeg zijn",
  "Cape Verde": "Kaapverdië",
  "Capillary Blood": "Capillair bloed",
  "Cayman Islands": "Kaaimaneilanden",
  "Central African Republic": "Centraal-Afrikaanse Republiek",
  "Cerebrospinal Fluid": "Cerebrospinaal vocht",
  "Cervical Swab": "Baarmoederhalsuitstrijkje",
  "Chad": "Tsjaad",
  "Change Access Code": "Toegangscode wijzigen",
  "Check again": "Opnieuw controleren",
  "Chile": "Chili",
  "China": "China",
  "Clear Search": "Zoekopdracht wissen",
  "Close": "Sluiten",
  "Cocos(Keeling) Islands and Chr": "Cocoseilanden en Christmaseiland",
  "Colombia": "Colombia",
  "Comoros": "Comoren",
  "Confirm": "Bevestigen",
  "Confirm New Password": "Nieuw wachtwoord bevestigen",
  "Confirm new password": "Nieuw wachtwoord bevestigen",
  "Confirm New Password is required.": "Nieuw wachtwoord bevestigen is vereist.",
  "Confirm Password is required.": "Wachtwoord bevestigen is vereist.",
  "Confirm Password must match New Password": "Bevestigde wachtwoord moet gelijk zijn aan nieuwe wachtwoord",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Cookeilanden",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Ivoorkust",
  "Croatia(Hrvatska)": "Kroatië (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Kweekbouillon",
  "Current Password": "Huidig wachtwoord",
  "Current Password is required.": "Huidig wachtwoord is vereist.",
  "Current password required": "Huidig wachtwoord vereist",
  "Cyprus": "Cyprus",
  "Czech Republic": "Tsjechische Republiek",
  "Danish": "Deens",
  "Dec": "dec",
  "December": "december",
  "Denmark": "Denemarken",
  "Disabled": "Uitgeschakeld",
  "Discard Changes": "Ongedaan maken",
  "Dismiss": "Negeren",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Niet gelijk aan uw eerdere wachtwoorden",
  "Does not use unsupported characters": "Geen niet-ondersteunde tekens",
  "Domain": "Domein",
  "Domain is required.": "Domein is vereist.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominicaanse Republiek",
  "Door Open": "Klep open",
  "Dutch": "Nederlands",
  "Ecuador": "Ecuador",
  "Egypt": "Egypte",
  "El Salvador": "El Salvador",
  "Email": "E-mailadres",
  "Email is required.": "E-mailadres is vereist.",
  "English (UK)": "Engels (VK)",
  "English (US)": "Engels (VS)",
  "Enter": "Enter",
  "Equatorial Guinea": "Equatoriaal-Guinea",
  "Eritrea": "Eritrea",
  "Error": "Fout",
  "Estonia": "Estland",
  "Ethiopia": "Ethiopië",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager wordt niet ondersteund in de mobiele Connect-toepassing.",
  "Failed to reset password. A server error was encountered": "Wachtwoord is niet gereset. Er is een serverfout opgetreden",
  "Falkland Islands(Malvinas)": "Falklandeilanden (Malvinen)",
  "Faroe Islands": "Faeröer",
  "Fatal Application Error": "Fatale applicatiefout",
  "Feb": "feb",
  "February": "februari",
  "Fecal Specimen": "Fecesmonster",
  "Fiji": "Fiji",
  "File type must be __fileType__": "Bestandtype moet __fileType__ zijn",
  "Finland": "Finland",
  "Finnish": "Fins",
  "First Name": "Voornaam",
  "Forgot your password?": "Uw wachtwoord vergeten?",
  "Fr": "Vr",
  "France": "Frankrijk",
  "French": "Frans",
  "French Guiana": "Frans-Guyana",
  "French Polynesia": "Frans-Polynesië",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgië",
  "German": "Duits",
  "Germany": "Duitsland",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Ga naar __storeName__",
  "Greater than __value__ in __measure__": "Groter dan __value__ in __measure__",
  "Greece": "Griekenland",
  "Greenland": "Groenland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinee",
  "Guinea - Bissau": "Guinee-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haïti",
  "Hct Out of Range": "Hct buiten bereik",
  "Hide": "Verbergen",
  "Hide password": "Wachtwoord verbergen",
  "Holy See(Vatican City State)": "Heilige Stoel (Vaticaanstad)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hongkong, SAR",
  "Hungary": "Hongarije",
  "Iceland": "IJsland",
  "India": "India",
  "Indonesia": "Indonesië",
  "Initials": "Initialen",
  "Install": "Installeren",
  "Install Now": "Nu installeren",
  "Instrument Not Level": "Instrument niet waterpas",
  "Insufficient Sample": "Onvoldoende monster",
  "Internet connection is offline.": "Internetverbinding is offline.",
  "Invalid access code": "Ongeldige toegangscode",
  "Invalid email format": "Ongeldige e-mailadresopmaak",
  "Invalid File": "Ongeldig bestand",
  "Invalid Lot Cal File": "Ongeldig lotkal.bestand",
  "Invalid username or password.": "Ongeldige gebruikersnaam of wachtwoord.",
  "Iran, Islamic Republic of": "Iran, Islamitische Republiek",
  "Iraq": "Irak",
  "Ireland": "Ierland",
  "Israel": "Israël",
  "Italian": "Italiaans",
  "Italy": "Italië",
  "Jamaica": "Jamaica",
  "Jan": "jan",
  "January": "januari",
  "Japan": "Japan",
  "Japanese": "Japans",
  "Jordan": "Jordanië",
  "Jul": "jul",
  "July": "juli",
  "Jun": "jun",
  "June": "juni",
  "Kazakhstan": "Kazachstan",
  "KC Swab": "KC-uitstrijkje",
  "Kenya": "Kenia",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Democratische Volksrepubliek",
  "Korea, Republic of(South)": "Korea, Republiek Zuid-",
  "Kuwait": "Koeweit",
  "Kyrgyzstan": "Kirgizië",
  "Language": "Taal",
  "Laos(Lao PDR)": "Laos, Democratische Volksrepubliek",
  "Last Name": "Achternaam",
  "Latvia": "Letland",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Kleiner dan __value__ in __measure__",
  "Liberia": "Liberia",
  "Libya": "Libië",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Quality Control met vloeistof",
  "Lithuania": "Litouwen",
  "Loading more records...": "Bezig met laden van meer gegevens…",
  "Login": "Aanmelden",
  "Logout": "Afmelden",
  "Luxembourg": "Luxemburg",
  "Macao(SAR China)": "Macau (SAR China)",
  "Macedonia, Republic of": "Noord-Macedonië, Republiek",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Maleisië",
  "Maldives": "Maldiven",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "mrt",
  "March": "maart",
  "Marshall Islands": "Marshalleilanden",
  "Martinique": "Martinique",
  "Mauritania": "Mauritanië",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Maximumlengte is __count__ tekens",
  "May": "mei",
  "Mexico": "Mexico",
  "Micronesia, Federated States o": "Micronesia, Federale Staten van",
  "Mo": "Ma",
  "Mobile Application Version": "Mobiele toepassingsversie",
  "Moldova": "Moldavië",
  "Monaco": "Monaco",
  "Mongolia": "Mongolië",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marokko en Westelijke Sahara",
  "Mouth Swab": "Monduitstrijkje",
  "Mozambique": "Mozambique",
  "Myanmar": "Myanmar",
  "Namibia": "Namibië",
  "Nasal Discharge": "Neusafscheiding",
  "Nasal Swab": "Neusuitstrijkje",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Nederland",
  "Netherlands Antilles": "Nederlandse Antillen",
  "Never Connected": "Nooit aangesloten",
  "New Caledonia": "Nieuw-Caledonië",
  "New Password": "Nieuw wachtwoord",
  "New Password is invalid.": "Nieuw wachtwoord is ongeldig.",
  "New Password is required.": "Nieuw wachtwoord is vereist.",
  "New password must match": "Nieuw wachtwoord moet hetzelfde zijn",
  "New Version Required": "Nieuwe versie vereist",
  "New Zealand": "Nieuw-Zeeland",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Geen toepassingsmodules aanwezig in deze omgeving. Meld dit probleem bij een beheerder.",
  "No easily guessable words": "Geen woorden die gemakkelijk te raden zijn",
  "No more than __value__ characters": "Maximaal __value__ tekens",
  "No region set": "Geen regio ingesteld",
  "No repetitive characters (e.g. aaa)": "Geen zich herhalende tekens (bijv. aaa)",
  "No sequential characters (e.g. 123)": "Geen opeenvolgende tekens (bijv. 123)",
  "No Test Strip Detected": "Geen Teststrip gedetecteerd",
  "none": "geen",
  "Norfolk Island": "Norfolkeiland",
  "Northern Mariana Islands": "Noordelijke Marianen",
  "Norway(inc Svalbard and Jan May": "Noorwegen (incl. Svalbard en Jan Mayen)",
  "Norwegian": "Noors",
  "Nov": "nov",
  "November": "november",
  "NP Aspirate": "NP-aspiraat",
  "NP Swab": "Nas.far. uitstrijkje",
  "Oct": "okt",
  "October": "oktober",
  "Offline 1 day": "1 dag offline",
  "Offline 1 hour": "1 uur offline",
  "Offline 1 minute": "1 minuut offline",
  "Offline 1 month": "1 maand offline",
  "Offline 1 year": "1 jaar offline",
  "Offline __days__ days": "__days__ dagen offline",
  "Offline __hours__ hours": "__hours__ uur offline",
  "Offline __minutes__ minutes": "__minutes__ minuten offline",
  "Offline __months__ months": "__months__ maanden offline",
  "Offline __years__ years": "__years__ jaar offline",
  "OK": "OK",
  "Okay": "Oke",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestijnse gebieden, bezet",
  "Panama": "Panama",
  "Papua New Guinea": "Papoea-Nieuw-Guinea",
  "Paraguay": "Paraguay",
  "Password": "Wachtwoord",
  "Password requirements:": "Wachtwoordvereisten:",
  "Peru": "Peru",
  "Philippines": "Filipijnen",
  "Pitcairn": "Pitcairneilanden",
  "Plasma": "Plasma",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugees (Brazilië)",
  "Portuguese (Portugal)": "Portugees (Portugal)",
  "Profile": "Profiel",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Geef de volgende informatie om uw wachtwoord in te stellen.  De validatiecode is naar uw e-mailadres verzonden, indien dit geldig is.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Geef uw e-mailadres op voor het aanvragen van een wachtwoordreset. Er wordt een tijdelijke validatiecode naar uw e-mailadres verzonden.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC-oplossing gedetecteerd",
  "Rectal Swab": "Rectaal uitstrijkje",
  "Required Update": "Vereiste update",
  "Reset": "Resetten",
  "Reset Password": "Wachtwoord resetten",
  "Reset password": "Wachtwoord resetten",
  "Resolution requirements": "Vereisten oplossing",
  "Return to Login": "Terug naar aanmelden",
  "Romania": "Roemenië",
  "Russian Federation": "Russische Federatie",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion en Mayotte",
  "Sa": "Za",
  "Saint Helena and Tristan da Cu": "Sint-Helena en Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts en Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre en Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent en de Grenadines",
  "Saliva": "Speeksel",
  "Samoa": "Samoa",
  "Sample Detected": "Monster gedetecteerd",
  "Sample Error": "Monsterfout",
  "Sample Type Error": "Fout met monstertype",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi-Arabië",
  "Save": "Opslaan",
  "Select a date": "Selecteer een datum",
  "Select from list...": "Selecteren uit lijst…",
  "Select language": "Taal selecteren",
  "Selected files must match requirements": "Geselecteerde bestanden moeten aan vereisten voldoen",
  "Senegal": "Senegal",
  "Sep": "sep",
  "September": "september",
  "Serbia": "Servië",
  "Serum": "Serum",
  "Server error, please try again later.": "Serverfout, probeer het later opnieuw.",
  "Server Error. Unable to save your profile.": "Serverfout. Het opslaan van uw profiel is mislukt.",
  "Set Password": "Wachtwoord instellen",
  "Seychelles": "Seychellen",
  "Show Password": "Wachtwoord tonen",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Grootte moet kleiner zijn dan __maxSize__",
  "Skin Swab": "Huiduitstrijkje",
  "Slovakia": "Slowakije",
  "Slovenia": "Slovenië",
  "Solomon Islands": "Salomonseilanden",
  "Somalia": "Somalië",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "DataView is helaas niet ingeschakeld of u hebt geen toegang tot deze organisatie",
  "South Africa": "Zuid-Afrika",
  "Space": "Spatie",
  "Spain": "Spanje",
  "Spanish": "Spaans",
  "Spanish (US)": "Spaans (VS)",
  "Sputum": "Sputum",
  "Sri Lanka": "Sri Lanka",
  "Su": "Zo",
  "Sudan": "Sudan",
  "Suriname": "Suriname",
  "Swaziland": "Swaziland",
  "Sweden": "Zweden",
  "Swedish": "Zweeds",
  "Switzerland": "Zwitserland",
  "Syrian Arab Republic(Syria)": "Syrië, Arabische Republiek",
  "São Tomé and Príncipe": "Sao Tomé en Principe",
  "Taiwan, Republic of China": "Taiwan (Republiek China)",
  "Tajikistan": "Tadzjikistan",
  "Tanzania, United Republic of": "Tanzania, Verenigde Republiek",
  "Temperature Error": "Temperatuurfout",
  "Test Cancelled": "Test geannuleerd",
  "Test Lot Not Permitted": "Test-lot niet toegestaan",
  "Test Operation Error": "Fout met uitvoering test",
  "Test Strip Lot Expired": "Teststrip-lot vervallen",
  "Test Strip Not Supported": "Teststrip niet ondersteund",
  "Test Strip Reading Error": "Fout met lezen Teststrip",
  "Test Timeout": "Time-out test",
  "Test Type Not Permitted": "Testtype niet toegestaan",
  "Th": "Do",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "De validatiecode voor het nieuwe wachtwoord wordt naar het e-mailadres verzonden als het e-mailadres het geldige e-mailadres van een gebruiker is.",
  "The reset validation code was populated from the link and should not be changed.": "De resetvalidatiecode werd ingevoerd via de link en mag niet worden gewijzigd.",
  "There are unsaved changes. How do you want to proceed?": "Er zijn niet-opgeslagen wijzigingen. Hoe wilt u verdergaan?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Er is een nieuwe versie beschikbaar (__versionNumber__), wilt u deze nu installeren?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Dit apparaat werkt niet meer als Connect Hub. Om dit apparaat te blijven gebruiken als Connect Hub moet u Bluetooth weer inschakelen in de instellingen van uw apparaat en Gebruik dit mobiele apparaat als Connect Hub via het scherm Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Deze versie van LumiraDx Connect is niet meer compatibel met uw organisatie. Ga naar __storeName__ en voer een update uit naar de laatste versie.",
  "Throat Swab": "Keeluitstrijkje",
  "Timor - Leste": "Oost-Timor",
  "Title": "Titel",
  "TN Swab": "Keel-/neusuitstrijkje",
  "to": "naar",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Voer een upgrade uit naar de laatste versie (__versionNumber__) als u door wilt gaan met het gebruik van __APP_NAME__.",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad en Tobago",
  "Tu": "Di",
  "Tunisia": "Tunesië",
  "Turkey": "Turkije",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- en Caicoseilanden",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Oekraïne",
  "Unable to reset password, the provided current password is not valid.": "Kan wachtwoord niet resetten, het gegeven huidig wachtwoord is niet geldig.",
  "Unable to reset your password.": "Kan uw wachtwoord niet resetten.",
  "Unable to validate access code.": "Kan toegangscode niet valideren.",
  "United Arab Emirates": "Verenigde Arabische Emiraten",
  "United Kingdom": "Verenigd Koninkrijk",
  "United States of America": "Verenigde Staten van Amerika",
  "Unrecognized credentials. Please try again.": "Niet herkende gegevens. Probeer het opnieuw.",
  "Unsaved Changes": "Niet-opgeslagen wijzigingen",
  "Update Available": "Update beschikbaar",
  "Update Password": "Wachtwoord bijwerken",
  "Urethral Swab": "Urethr. uitstrijkje",
  "Urine": "Urine",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Gebruikersaccount is geblokkeerd. Probeer het over een uur opnieuw.",
  "User Interface Version": "Versie gebruikersinterface",
  "User logged out due to inactivity.": "Gebruiker vanwege inactiviteit afgemeld.",
  "Username": "Gebruikersnaam",
  "Uzbekistan": "Oezbekistan",
  "Vaginal Swab": "Vaginaal uitstrijkje",
  "Validate Access Code": "Toegangscode valideren",
  "Validation Code": "Validatiecode",
  "Validation Code is required.": "Validatiecode is vereist.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela, Bolivariaanse Republiek",
  "Venous Blood": "Veneus bloed",
  "Version": "Versie",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Britse Maagdeneilanden",
  "Virgin Islands, US": "Amerikaanse Maagdeneilanden",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvovag uitstrijkje",
  "Wallis and Futuna Islands": "Wallis en Futuna",
  "We": "Wo",
  "We are working to get you back up and running as quickly as possible.": "We proberen u zo snel mogelijk weer op gang te helpen.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Het spijt ons maar LumiraDx Connect is nu niet actief wegens onderhoud.",
  "Yemen": "Jemen",
  "You have been signed out due to a password reset attempt.": "U bent afgemeld als gevolg van een poging het wachtwoord te resetten.",
  "You have been signed out due to the creation of another session with the same credentials.": "U bent afgemeld vanwege het aanmaken van een andere sessie met dezelfde gegevens.",
  "You have been signed out due to your account being disabled by an admin.": "U bent afgemeld omdat uw account door een beheerder is uitgeschakeld.",
  "You have been signed out due to your password being changed by an admin.": "U bent afgemeld omdat uw wachtwoord door een beheerder is gewijzigd.",
  "You have been signed out due to your user role being changed by an admin.": "U bent afgemeld omdat uw gebruikersrol door een beheerder is gewijzigd.",
  "You have been signed out.": "U bent afgemeld.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Voor compatibiliteit met uw organisatie is het nodig dat u een andere versie (__versionNumber__) van __APP_NAME__ installeert.",
  "Your password has expired. Please set a new password.": "Uw wachtwoord is verlopen. Stel een nieuw wachtwoord in.",
  "Your session has expired. Please login again.": "Uw sessie is verlopen. Meld u opnieuw aan.",
  "Your session has expired. User is not authorized.": "Uw sessie is verlopen. Gebruiker is niet geautoriseerd.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
