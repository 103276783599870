import * as React from 'react';
import Overlay from './Overlay';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import { IKeyboardKey, IKeyboardModifyInput } from './interfaces';
import { centerInputScroll, resetInputScroll } from './utils';
import { SOFT_KEYBOARD_LAYOUTS } from './SoftKeyboardLayouts';
// import * as japaneseLayout from 'simple-keyboard-layouts/build/layouts/japanese';
// import * as chineseLayout from 'simple-keyboard-layouts/build/layouts/chinese';

const styles = require('./styles/keyboard.styl');

export enum ContextKeys {
  LOCK = '{lock}',
  CAPS = '{caps}',
  TAB = '{tab}',
  BACKSPACE = '{bksp}',
  ENTER = '{enter}',
  SHIFT = '{shift}',
  NUMBER = '{123}',
  NUMBERSMALL = '{123s}',
  ABC = '{abc}',
  SPACE = '{space}',
  DIACRITIC = '{àêö}',
  SPECIAL_ACTION = '{special}',
  ABC_SPECIAL_ACTION = '{123-special}'
}

export enum KeyboardLayouts {
  QWERTY = 'QWERTY',
  QWERTZ = 'QWERTZ',
  ABCDEF = 'ABCDEF',
  NUMERIC = '123',
}

export enum KeyboardLayers {
  ABC_LOWERCASE = 'abcLowercase',
  ABC_UPPERCASE = 'abcUppercase',
  NUMERIC = '123',
  NUMERIC_SPECIAL_ACTION = '123-special',
  DIACRITIC_LOWERCASE = 'diacriticLowercase',
  DIACRITIC_UPPERCASE = 'diacriticUppercase'
}

enum ShiftState {
  OFF,
  SHIFT,
  CAPS_LOCK,
}

enum ShiftAction {
  NONE,
  TOGGLED_OFF_BY_USER,
  TOGGLED_ON_BY_USER,
}

enum KeyStrokeType {
  HARD,
  SOFT
}

export interface IKeyboardLayout {
  default: {
    layout: {
      'abcLowercase'?: string[];
      'abcUppercase'?: string[];
      'diacriticLowercase'?: string[];
      'diacriticUppercase'?: string[];
      '123-special'?: string[];
      '123'?: string[];
      'default'?: string[];
    };
    height: number;
  };
}

export interface IKeyboardLayouts {
  QWERTY: IKeyboardLayout;
  ABCDEF: IKeyboardLayout;
  QWERTZ?: IKeyboardLayout;
  123: IKeyboardLayout;
}

export interface IKeyboardContext {
  closeKeyboard: () => void;
}

interface IState {
  prevLayout: any;
  currentLayer: KeyboardLayers;
  keys: Map<string, IKeyboardKey>;
  shiftKey: ShiftState;
  specialActionToggledOn: boolean;
  startClose: boolean;
  latestShiftAction: ShiftAction; // necessary to remember a case/shift key click by the user until the next
  // character is entered even when the layout is switched in the meanwhile
}

export interface ISpecialAction {
  action?: (value: string, cursorPos: number) => string;
  contextKey: string;
  suppressUpperCase: boolean;
}

export interface IKBProps {
  keySound?: () => void;
  animation?: boolean;
  inputs: string[];
  onEnter?: () => void;
  disableKeys?: string[];
  enterText?: string;
  /**
   * Takes precedence over an onEnter (don't use them together)
   */
  enterAsNextField?: boolean;
  inputName: string;
  isPassword?: boolean;
  isTextareaInput?: boolean;
  close?: () => void;
  onChange?: (e) => void;
  modifyInput?: IKeyboardModifyInput;
  layout?: KeyboardLayouts;
  initialLayer?: KeyboardLayers;
  scrollSelector?: string;
  zIndex?: number;
  closeOnInputBlur?: boolean;
  showHideNav?: boolean;
  showNavOverride?: boolean;
  /**
   * add a special action key,
   * when the context key is toggled on: runs the current input value through the action property
   * suppressUpperCase can be set to true to restrict the special action to lowercase keyboard
   */
  specialAction?: ISpecialAction;
  specialActionDefaultOn?: boolean;
  layouts?: any;
}

export default class SoftKeyboard extends React.Component<IKBProps, IState> {
  private contentEl: React.RefObject<HTMLDivElement>;
  private keyboard: any;
  private inputEls: HTMLInputElement[];
  private lastKeyStrokeType: KeyStrokeType;
  private shiftPressed: boolean;

  public static defaultProps = {
    layouts: SOFT_KEYBOARD_LAYOUTS,
    animation: true,
    disableKeys: [],
    isTextareaInput: false,
    layout: KeyboardLayouts.QWERTY,
    initialLayer: KeyboardLayers.ABC_LOWERCASE,
    enterAsNextField: false,
    closeOnInputBlur: true,
    showHideNav: true,
    showNavOverride: false,
    isPassword: false,
    specialActionDefaultOn: true,
  };

  public static defaultState = {
    shiftKey: ShiftState.OFF,
    latestShiftAction: ShiftAction.NONE,
  };

  constructor(props) {
    super(props);
    const { layouts, layout, inputs, initialLayer, isTextareaInput, specialAction, disableKeys, specialActionDefaultOn } = this.props;

    // Create array of the input DOM elements
    this.inputEls = inputs.map(i => document.querySelector(`${isTextareaInput ? 'textarea' : 'input'}[name="${i}"]`));

    this.inputEls.forEach(el => {
      if (props.closeOnInputBlur) {
        el?.addEventListener('blur', this.handleInputBlur);
      }
      el?.addEventListener('pointerup', this.handleInputPointerUp);
      el?.addEventListener('focus', this.handleInputFocus);
      el?.addEventListener('keyup', this.handleHWKeyboard);
      el?.addEventListener('clear', this.handleClear);
    });

    this.contentEl = React.createRef();

    this.state = {
      ...SoftKeyboard.defaultState,
      prevLayout: layouts[layout ?? KeyboardLayouts.QWERTY],
      currentLayer: initialLayer,
      startClose: false,
      keys: null,
      specialActionToggledOn: specialAction && !disableKeys.includes(ContextKeys.SPECIAL_ACTION) && specialActionDefaultOn,
    };
  }

  public componentDidMount() {
    const { inputName } = this.props;

    setTimeout(() => {
      this.resetKeyboardLayout();
    }, 0);

    this.handleInputFocus({ target : { name: inputName }}, 100);
  }

  public componentDidUpdate(prevProps) {
    const { inputName, disableKeys } = this.props;
    if (this.lastKeyStrokeType === KeyStrokeType.SOFT) {
      this.setInputCaretPosition();
    }

    if (inputName !== prevProps.inputName) {
      this.syncKeyboardWithDOM();
    }

    if (disableKeys !== prevProps.disableKeys && disableKeys.includes(ContextKeys.SPECIAL_ACTION)) {
      this.setState({ specialActionToggledOn: false });
    }
  }

  public componentWillUnmount() {
    this.inputEls.forEach(el => {
      el?.removeEventListener('blur', this.handleInputBlur);
      el?.removeEventListener('pointerup', this.handleInputPointerUp);
      el?.removeEventListener('focus', this.handleInputFocus);
      el?.removeEventListener('keyup', this.handleHWKeyboard);
      el?.removeEventListener('clear', this.handleClear);
    });
  }

  public render(): JSX.Element {
    const { currentLayer, keys, startClose, specialActionToggledOn } = this.state;
    let { shiftKey } = this.state;

    const { layouts, inputName, enterText, close, layout, inputs, disableKeys, modifyInput, animation, zIndex, showNavOverride, specialAction } = this.props;
    const currentLayout = (layouts[layout] || layouts[KeyboardLayouts.ABCDEF]) ?? (SOFT_KEYBOARD_LAYOUTS[layout] || SOFT_KEYBOARD_LAYOUTS[KeyboardLayouts.ABCDEF]);
    const showNavBar = inputs.length > 1 || showNavOverride;
    const contentClass = `${styles.KeyboardContent} ${showNavBar ? '' : styles.NoNavBar}`;
    const layoutClass =
      `${styles.KeyboardWrapper} ${showNavBar ? '' : styles.NoNavBar} ${[layouts[KeyboardLayouts.ABCDEF]].includes(currentLayout) ? styles.ABC : ''}`;
    const animationClass = `${layoutClass} ${animation ? styles.KeyboardEnter : ''} ${startClose ? styles.KeyboardExit : ''}`;
    const buttonAttributes = [];

    keys?.forEach((key) => {
      if (!key.enabled) {
        buttonAttributes.push({
          attribute: 'disabled',
          value: 'disabled',
          buttons: key.type,
        });
      }
    });

    const disabledCharKeys = disableKeys.filter(key =>
      !Object.values(ContextKeys).includes(key as ContextKeys) &&
      !modifyInput?.addedChars?.includes(key));

    shiftKey = specialActionToggledOn && specialAction.suppressUpperCase ? ShiftState.OFF : shiftKey;

    const inputPattern = disabledCharKeys.length > 0 ? new RegExp(`^[^${this.escapeRegExp((disabledCharKeys).join(''))}]+$`) : '[^]*';
    const content = (
      <>
        <div ref={this.contentEl} className={`${layoutClass} ${styles.Ghost}`} />
        <div onAnimationEnd={startClose ? close : null} className={animationClass}>
          <div className={contentClass} onMouseDown={this.preventFocusLoss}>
            {showNavBar ? this.getNavButtons() : null}
            <Keyboard
              keyboardRef={r => {
                this.keyboard = r;
                this.syncKeyboardWithDOM();
                this.setKeyboardCaretPosition();
              }}
              inputName={inputName}
              mergeDisplay
              display={{
                '{hidden}': '',
                '{lock}': ' ',
                '{caps}': ' ',
                '{tab}': ' ',
                '{bksp}': ' ',
                '{enter}': enterText || t('Enter'),
                '{shift}': ' ',
                '{123}': '123!',
                '{123s}': '123!',
                '{abc}': 'abc',
                '{space}': t('Space'),
                '{#+=}': '#+=',
                '{àêö}': 'àêö',
                '{special}': specialAction?.contextKey ?? '' ,
                '{123-special}': specialAction?.contextKey ?? '',
              }}
              buttonTheme={[
                {
                  class: shiftKey === ShiftState.CAPS_LOCK ?
                    'caps-lock' :
                    shiftKey === ShiftState.SHIFT ?
                      'shift-key-on' :
                      'shift-key',
                  buttons: `${ContextKeys.SHIFT}`,
                },
                {
                  class:
                    [layouts[KeyboardLayouts.ABCDEF]].includes(currentLayout) ?
                      'hg-button-abcdef' :
                      'hg-button',
                  buttons: '{123} {123s} {abc} {shift} {bksp} {space} {enter} {àêö} {special} {123-special} a b c d e f g h i j k l m n o p q r ' +
                    's t u v w x y z A B C D E F G H I J K L M N O P Q R S T U V W X Y Z . 1 2 3 4 5 6 7 8 9 0 , ー ・ ' +
                    '? ! \' / + - = % # ( ) ~ < > @ à á â ä æ å ç è é ë ê î ï ì í ñ ô ö ò ó œ ø ß ù ú ü À Á Â Ä Æ ' +
                    'Å Ç È É Ë Ê Î Ï Ì Í Ñ Ô Ö Ò Ó Œ Ø ẞ Ù Ú Ü',
                },
                {
                  class: !enterText || enterText === t('Enter') ? 'hg-functionBtn' : 'special-action',
                  buttons: `${ContextKeys.ENTER}`,
                },
                {
                  class: specialActionToggledOn ? 'special-active' : 'abc-active',
                  buttons: `${ContextKeys.SPECIAL_ACTION}`,
                },
              ]}
              buttonAttributes={buttonAttributes}
              disableButtonHold
              physicalKeyboardHighlightPress
              physicalKeyboardHighlight
              layout={currentLayout.default.layout}
              layoutName={currentLayer}
              onChange={this.handleChange}
              onKeyReleased={this.handleOnKeyReleased}
              onKeyPress={this.onKeyPress}
              layoutCandidatesPageSize={17}
              useButtonTag={true}
              preventMouseDownDefault={true}
              inputPattern={inputPattern}
            />

          </div>
        </div>
      </>
    );

    return (
      <Overlay backdrop={false} nonBlocking={true} backdropClose={false} zIndex={zIndex}>
        {content}
      </Overlay>
    );
  }

  private escapeRegExp = (str): string => {
    return str.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
  };

  private handleClear = (e): void => {
    if (e?.detail?.inputName === this.props.inputName) {
      setImmediate(() => {
        this.handleChange('');
        this.updateKeyboardLayout();
      });
    }
  };

  private getCurrentInput = (): HTMLInputElement => {
    const { isTextareaInput, inputName } = this.props;
    return document.querySelector(`${isTextareaInput ? 'textarea' : 'input'}[name=${inputName}]`) as HTMLInputElement;
  };

  public handleChange = (value: string = ''): void => {
    const { onChange, modifyInput, inputName, specialAction } = this.props;
    const currentInput = this.getCurrentInput();
    // Check if input was cleared since last change event
    const inputValue = currentInput?.value;
    let  newCurPos = 0;

    if (modifyInput && modifyInput.modify) {
      const newInput = modifyInput.modify(value, inputValue, currentInput.selectionStart);
      value = newInput.newValue;
      newCurPos = newInput.newCurPos;
    }

    if (specialAction && this.state.specialActionToggledOn && (value.length > inputValue.length)) {
      const newValue = specialAction.action(value, currentInput.selectionStart + 1) ?? '';
      const cursorOffsetFromEnd = (value.length - (currentInput.selectionStart + 1));
      newCurPos = newValue.length - cursorOffsetFromEnd;
      value = newValue;
    }

    if ((currentInput.maxLength > -1 && value.length > currentInput.maxLength)) {
      value = currentInput.value;
      newCurPos = currentInput.selectionStart;
    }

    if (value.startsWith(' ')) {
      value = value.trimStart();
    }

    // update keyboard internal state if changes have been made w/o input, e.g clearing the input
    if (value !== this.keyboard.getInput()) {
      this.updateKeyboardState(value, inputName, newCurPos);
    }

    // Only necessary to check when value is growing (ie user is not deleting)
    if (value.length > inputValue.length) {

      // Remove last character from value (ie what was just typed, but is not yet reflected in the input in the DOM)
      const valueCheck = value.substring(0, value.length - 1);
      // Also get the just typed character
      const newInput = value.substring(value.length - 1, value.length);

      // If the input is empty and values don't match,
      // Update the keyboard internal state and alter the value being sent in the onChange handler
      if (inputValue === '' && valueCheck !== inputValue) {
        value = newInput;
        this.keyboard.replaceInput({
          ...this.getInputObj(),
          [inputName]: value,
        });
      }
    }

    onChange({
      target: {
        value: value,
      },
    });
  };

  public handleClose = (): void => {
    const { startClose } = this.state;

    if (!startClose) {
      this.setState({ startClose: true }, this.handleExit);
      const currentInput = this.getCurrentInput();
      currentInput?.blur();
    }
  };

  public onKeyPress = (e: any): void => {
    const { keySound } = this.props;
    const { keys } = this.state;
    const keyState = keys.get(e);

    if (keyState && !keyState?.enabled) {
      return;
    } else if (keySound){
      keySound?.();
    }
  };

  public handleOnKeyReleased = (e: any): void => {
    const { onEnter, enterAsNextField, specialAction } = this.props;
    const { keys, currentLayer, shiftKey, specialActionToggledOn } = this.state;

    const keyState = keys.get(e);
    if (keyState && !keys.get(e)?.enabled) {
      return;
    }

    if (this.lastKeyStrokeType === KeyStrokeType.HARD) {
      this.setInputCaretPosition();
    }

    this.lastKeyStrokeType = KeyStrokeType.SOFT;
    let newLayer;

    // IF shift key is pressed
    if (e === ContextKeys.LOCK || e === ContextKeys.SHIFT) {
      setTimeout(() => {
        // if shift key is OFF and shift press is false then set the shift key to SHIFT (single click of the shift key)
        // else if shift press is true then set the shift key to CAPS_LOCK ( double click of the shift key)
        // otherwise shift key should be turned OFF
        const shiftState = shiftKey === ShiftState.OFF && !this.shiftPressed ?
          ShiftState.SHIFT : this.shiftPressed ? ShiftState.CAPS_LOCK : ShiftState.OFF;
        this.setState({
          shiftKey: shiftState,
          latestShiftAction: shiftKey === ShiftState.SHIFT ? ShiftAction.TOGGLED_OFF_BY_USER : ShiftAction.TOGGLED_ON_BY_USER,
          currentLayer: this.getShiftLayer(currentLayer, shiftState),
        });
        // double click
        this.shiftPressed = true;
      }, 10);
      setTimeout(() => {
        // single click
        this.shiftPressed = false;
      }, 500);
    }
    // ELSE any other key is pressed
    else if (e === ContextKeys.NUMBER || e === ContextKeys.NUMBERSMALL) {
      newLayer = specialActionToggledOn ? KeyboardLayers.NUMERIC_SPECIAL_ACTION : KeyboardLayers.NUMERIC;
    }
    else if (e === ContextKeys.ABC) {
      newLayer = shiftKey === ShiftState.OFF ? KeyboardLayers.ABC_LOWERCASE : KeyboardLayers.ABC_UPPERCASE;
    }
    else if (e === ContextKeys.DIACRITIC) {
      newLayer = shiftKey === ShiftState.OFF ? KeyboardLayers.DIACRITIC_LOWERCASE : KeyboardLayers.DIACRITIC_UPPERCASE;
    }
    else if (e === ContextKeys.SPECIAL_ACTION && specialAction) {
      // if special action is passed as a prop and the special action key was pressed: toggle the special action on/off
      // revert the previous shift state if toggled off and suppressUpperCase is enabled
      this.setState({ specialActionToggledOn: !specialActionToggledOn });
      newLayer = !specialActionToggledOn && specialAction.suppressUpperCase ?
        KeyboardLayers.ABC_LOWERCASE :
        shiftKey === ShiftState.OFF ?
          KeyboardLayers.ABC_LOWERCASE :
          KeyboardLayers.ABC_UPPERCASE;
    }
    else if (e === ContextKeys.ABC_SPECIAL_ACTION) {
      newLayer = KeyboardLayers.ABC_LOWERCASE;
    }
    else if (e === ContextKeys.ENTER && (!keys.get(ContextKeys.ENTER) || keys.get(ContextKeys.ENTER)?.enabled)) {
      if (enterAsNextField) {
        this.nextInput(1);
      } else if (onEnter) {
        this.handleClose();
        onEnter();
      }
    }
    else if (!Object.values(ContextKeys).includes(e)) {
      this.shiftPressed = false;
      // alphanumeric - when typing if caps lock is on then keep it on else set shift key to off
      this.setState({ shiftKey: shiftKey === ShiftState.CAPS_LOCK ? ShiftState.CAPS_LOCK : ShiftState.OFF, latestShiftAction: ShiftAction.NONE });
    }
    this.updateKeyboardLayout(newLayer);
  };

  private getShiftLayer = (currentLayer: KeyboardLayers, shiftKey: ShiftState): KeyboardLayers => {
    const upper = this.isUpperCaseShiftState(shiftKey);

    if (this.state.specialActionToggledOn) {
      return KeyboardLayers.ABC_LOWERCASE;
    } else if (this.isAbcKeyboardLayer(currentLayer)) {
      return upper ? KeyboardLayers.ABC_UPPERCASE : KeyboardLayers.ABC_LOWERCASE;
    } else if (this.isDiacriticKeyboardLayer(currentLayer)) {
      return upper ?  KeyboardLayers.DIACRITIC_UPPERCASE : KeyboardLayers.DIACRITIC_LOWERCASE;
    }
  };

  public preventFocusLoss = (e: any): void => {
    e.preventDefault();
  };

  private isUpperCaseShiftState = (shiftKey: ShiftState) => [ShiftState.CAPS_LOCK, ShiftState.SHIFT].includes(shiftKey);
  private isAbcKeyboardLayer = (layer: KeyboardLayers)  => [KeyboardLayers.ABC_UPPERCASE, KeyboardLayers.ABC_LOWERCASE].includes(layer);
  private isDiacriticKeyboardLayer = (layer: KeyboardLayers) => [KeyboardLayers.DIACRITIC_LOWERCASE, KeyboardLayers.DIACRITIC_UPPERCASE].includes(layer);
  private isUppercaseKeyboardLayer = (layer: KeyboardLayers) => [KeyboardLayers.ABC_UPPERCASE, KeyboardLayers.DIACRITIC_UPPERCASE].includes(layer);
  private isLowercaseKeyboardLayer = (layer: KeyboardLayers) => [KeyboardLayers.ABC_LOWERCASE, KeyboardLayers.DIACRITIC_LOWERCASE].includes(layer);

  private handleInputPointerUp = (): void => {
    setTimeout(() => this.updateKeyboardLayout(), 1); // Give the DOM a chance to update
  };

  private handleInputFocus = (e: any, timeout = 0): void => {
    setTimeout(() => this.scrollInput(e.target.name), timeout);
    // Give the DOM a chance to update
    setTimeout(() => {
      // when we focus on a new field we need to reset the keyboard layout and get the current input
      this.resetKeyboardLayout();
      this.focusInput(this.getCurrentInput());
    }, 1);
  };

  private isInputEmpty = (input: HTMLInputElement): boolean => {
    return !input?.value || input?.value.length === 0 || input?.value.trim() === '';
  };

  // reset keyboard should be called any time that we switch to a different field
  public resetKeyboardLayout = (): void => {
    const { isPassword, initialLayer } = this.props;

    // any time we focus on a different input field we need to reset the default states
    const newState: IState = {
      ...this.state,
      ...SoftKeyboard.defaultState,
      currentLayer: initialLayer,
    };

    const currentInput = this.getCurrentInput();

    // if layer is numeric then set to numeric
    if (this.props.initialLayer === KeyboardLayers.NUMERIC) {
      newState.currentLayer = KeyboardLayers.NUMERIC;
      newState.shiftKey = ShiftState.OFF;
    }
    // else if there is no text and not a password field then first character is UPPERCASE
    else if ((this.isInputEmpty(currentInput) || currentInput?.selectionStart === 0) && !isPassword) {
      // if lowercase update to appropriate uppercase
      if (this.isLowercaseKeyboardLayer(newState.currentLayer)) {
        // update case to uppercase
        newState.currentLayer = this.getShiftLayer(newState.currentLayer, ShiftState.SHIFT);
      }
      newState.shiftKey = ShiftState.SHIFT;
    }
    // else if not lower case then set to lower case
    else if (newState.currentLayer !== KeyboardLayers.ABC_LOWERCASE) {
      newState.currentLayer = KeyboardLayers.ABC_LOWERCASE;
      newState.shiftKey = ShiftState.OFF;
    }

    newState.keys = this.getKeyStates(currentInput, newState.currentLayer);
    this.setState(newState);
  };

  /**
   * @param newLayer - new keyboard layer to be set; null if not explicitly requested
   */
  private updateKeyboardLayout = (newLayer: KeyboardLayers = null): void => {
    const { currentLayer, latestShiftAction } = this.state;
    const { isPassword } = this.props;

    const newState: IState = {
      ...this.state,
      currentLayer: newLayer ?? currentLayer,
    };

    const currentInput = this.getCurrentInput();
    // if layer is numeric then set to numeric
    if (this.props.initialLayer === KeyboardLayers.NUMERIC) {
      newState.currentLayer = KeyboardLayers.NUMERIC;
      newState.shiftKey = ShiftState.OFF;
    }
    // else if field is empty or at first character and not a password field and user has not toggled the shift then set to uppercase
    else if ((this.isInputEmpty(currentInput) || currentInput?.selectionStart === 0) && latestShiftAction !== ShiftAction.TOGGLED_OFF_BY_USER && !isPassword) {
      // if lowercase update to appropriate uppercase
      if (this.isLowercaseKeyboardLayer(newState.currentLayer)) {
        // update case to uppercase
        newState.currentLayer = this.getShiftLayer(newState.currentLayer, ShiftState.SHIFT);
        newState.shiftKey = ShiftState.SHIFT;
      }
      // if CAPS LOCK is not set then reset back to SHIFT
      if (newState.shiftKey !== ShiftState.CAPS_LOCK) {
        newState.shiftKey = ShiftState.SHIFT;
      }
    }
    // else if not caps lock and user has not toggle the shift then set to lowercase
    else if (newState.shiftKey !== ShiftState.CAPS_LOCK && latestShiftAction !== ShiftAction.TOGGLED_ON_BY_USER) {
      // if uppercase update to appropriate lowercase
      if (this.isUppercaseKeyboardLayer(newState.currentLayer)) {
        // update case to lowercase
        newState.currentLayer = this.getShiftLayer(newState.currentLayer, ShiftState.OFF);
        newState.shiftKey = ShiftState.OFF;
      }
    }

    newState.keys = this.getKeyStates(currentInput, newState.currentLayer);
    this.setState(newState);
  };

  private setInputCaretPosition = (): void => {
    const currentInput = this.getCurrentInput();
    const caretPos = this.keyboard.getCaretPosition();

    if (caretPos != null) {
      currentInput?.setSelectionRange(caretPos, caretPos);
    }

    // Required to shift the text left when overflowing input width
    currentInput.scrollLeft = ((currentInput.scrollWidth / currentInput.value.length) * caretPos);
  };

  private setKeyboardCaretPosition = (): void => {

    const currentInput = this.getCurrentInput();

    const caretPos = currentInput?.selectionStart;

    if (caretPos != null) {
      this.keyboard?.setCaretPosition(caretPos);
    }
  };

  private getNavButtons = (): any => {
    const { showHideNav } = this.props;

    const currentInput = this.getCurrentInput();
    const inputs = this.getAllInputs();
    const showArrowsBar = inputs.length > 1;
    const index = inputs?.indexOf(currentInput);

    return (
      <>
        {showArrowsBar ?
          <>
            <button className={`${styles.ActionButton} ${styles.up}`} disabled={inputs.length <= 1 || index === 0} onClick={() => this.nextInput(-1)} />
            <button className={`${styles.ActionButton} ${styles.down}`} disabled={inputs.length <= 1 || index + 1 === inputs.length} onClick={() => this.nextInput(1)} />
          </>
          : null}
        {showHideNav ? <button data-test={'nav-hide'} className={`${styles.ActionButton} ${styles.close}`} onClick={this.handleClose}>{t('Hide')}</button> : null}
      </>
    );
  };

  private getAllInputs = (): any[] => {
    const { isTextareaInput } = this.props;
    return Array.from(document.querySelectorAll(`${isTextareaInput ? 'textarea' : 'input'}[class*="input__TextInput"]`));
  };

  private nextInput = (direction: number): void => {
    const currentInput = this.getCurrentInput();
    const inputs = this.getAllInputs();
    let index = inputs.indexOf(currentInput);

    // if there is another input handle the focus of the next input field and keep the keyboard open
    if (!(inputs.length - 1 === index && direction === 1) && !(index === 0 && direction === -1)) {
      index = index + direction;
      this.focusInput(inputs[index]);
    }
    // otherwise close the keyboard
    else {
      this.handleClose();
    }

  };

  private focusInput = (element: HTMLInputElement): void => {
    if (element != null) {
      const strLength = element.value.length;
      if (element !== document.activeElement) {
        setTimeout(() => element.focus(), 1);
      }

      element.setSelectionRange(strLength, strLength);
    }
  };

  private scrollInput(input: string) {
    const { scrollSelector } = this.props;

    const scrollEl = document.querySelector(scrollSelector) as HTMLDivElement;
    const scrollElRect = scrollEl?.getBoundingClientRect();
    const kbdRect = this.contentEl?.current?.getBoundingClientRect();

    // get kbd height
    const kbHeight = kbdRect?.bottom - kbdRect?.top;

    // get scrollable area from top of keyboard to top of current scrollable element
    const scrollHeight = kbdRect?.top - scrollElRect?.top;

    centerInputScroll(scrollSelector, input, kbHeight, scrollHeight);
  }

  public handleInputBlur = (): void => {
    setTimeout(() => {
      !this.inputEls.includes(document.activeElement as HTMLInputElement) ? this.handleClose() : void 0;
    }, 10);
  };

  private handleExit = (): void => {
    const { scrollSelector } = this.props;
    resetInputScroll(scrollSelector);
  };

  public handleHWKeyboard = (e: KeyboardEvent): void => {
    this.lastKeyStrokeType = KeyStrokeType.HARD;

    const currentInput = this.getCurrentInput();

    this.updateKeyboardLayout();
    const caretPos = currentInput?.selectionStart;

    if (caretPos != null) {
      this.keyboard.setCaretPosition(caretPos + 1);
    }

    // Update the keyboard's internal state to match HW input
    setTimeout(() => {
      this.syncKeyboardWithDOM();
    }, 0);
  };

  private syncKeyboardWithDOM = (): void => {
    const input = this.getInputObj();
    this.keyboard?.replaceInput(input);
  };

  private getInputObj = (): any => {
    const { inputs } = this.props;

    return this.inputEls.reduce((rv, inputEl, index) => {
      rv[inputs[index]] = inputEl?.value;
      return rv;
    }, {});
  };

  private getKeyStates = (input: HTMLInputElement, layer: KeyboardLayers): Map<string, IKeyboardKey> => {
    const { disableKeys, specialAction } = this.props;
    const { specialActionToggledOn } = this.state;
    const keys = new Map();

    disableKeys?.forEach((state) => keys.set(state, { type: state, enabled: false }));
    keys.set(ContextKeys.SPACE, {
      type: ContextKeys.SPACE,
      enabled: disableKeys.indexOf(ContextKeys.SPACE) > -1 ? false :
        (input?.value !== undefined && input?.value?.length > 0 && input?.selectionStart !== 0),
    });
    keys.set(ContextKeys.SHIFT, {
      type: ContextKeys.SHIFT,
      enabled: disableKeys.indexOf(ContextKeys.SHIFT) > -1 ? false :
        (layer === KeyboardLayers.ABC_LOWERCASE ||
          layer === KeyboardLayers.ABC_UPPERCASE ||
          layer === KeyboardLayers.DIACRITIC_UPPERCASE ||
          layer === KeyboardLayers.DIACRITIC_LOWERCASE)
          && (!specialAction || !specialActionToggledOn && specialAction.suppressUpperCase),
    });

    return keys;
  };

  private updateKeyboardState = (value: string, inputName: string, carePos: number = 0): void => {
    this.keyboard.setInput(value, inputName);
    this.keyboard?.setCaretPosition(carePos);
  };
}
