type IDependencyMap = Map<string, object>;

class AMD {
  private dynamicDeps: Set<string> = new Set();
  private registry: IDependencyMap = new Map();

  /**
   * Add entries to the AMD regisrty. Any external dependency of an AMD module must be registered with this method.
   * @param dependencies Map of <dependency name, module>
   */
  public registerDependencies = (dependencies: IDependencyMap): void => {
    this.registry = new Map([...this.registry, ...dependencies]);    
  };

  /**
   * Part of the AMD spec. Retrieves a dependency from the registry by it's string name.
   * @param moduleName Name of the dependency
   */
  public require = (moduleName: string): any => {
    if (this.registry.has(moduleName)) {
      return this.registry.get(moduleName);
    } else {
      if (process.env.NODE_ENV === 'development') {
        throw (new Error(`A loaded AMD module is asking for an unregistered dependency: ${moduleName}`));
      }
      else {
        if (process.env.NODE_ENV !== 'test') {
          console.error(`A loaded AMD module is asking for an unregistered dependency: ${moduleName}. Bugs are very likely. This issue can likely be resolved with a ui-core deploy.`);
        }
        return {};
      }
    }
  };

  /**
   * Part of the AMD spec. Defines a module, provides it's dependencies, and registers it in the registry.
   * @param moduleName Name of the dependency
   * @param dependencies Array of string module names
   * @param module function that returns the module definition
   */
  public define = (moduleName: string, dependencies: string[], module: (...deps: any) => any): void => {
    const resolvedDeps = dependencies.map((dep: string) => {
      if (this.registry.has(dep)) {
        return this.registry.get(dep);
      } else {
        if (process.env.NODE_ENV === 'development') {
          throw(new Error(`Loaded AMD module (${moduleName}) is asking for an unregistered dependency: ${dep}`));
        }
        else {
          if (process.env.NODE_ENV !== 'test') {
            console.error(`Loaded AMD module (${moduleName}) is asking for an unregistered dependency: ${dep}. Bugs are very likely. This issue can likely be resolved with a ui-core deploy.`);
          }
          return {};
        }
      }
    });
    
    this.registry.set(moduleName, module(...resolvedDeps));
  };

  /**
   * Removes a registered dependency
   * @param moduleName Name of the dependency
   * @return Whether or not the dependency was removed
   */
  public unregisterDependency = (moduleName: string): boolean => {
    return this.registry.delete(moduleName);
  };

  /**
   * Clears all registered dependencies that were dynamically loaded
   */
  public clearDynamicDeps = (): void => {
    this.dynamicDeps.forEach((moduleName) => {
      this.registry.delete(moduleName);
    });
    this.dynamicDeps.clear();
  };

  /**
   * Download and executes a new module from a server path and returns a promise.
   * @param modulePath Url of the module to be loaded
   */
  public importModule = (modulePath: string, bypassRegistry: boolean = false): Promise<any> => {
    return new Promise((resolve, reject) => {
      
      // Check for requested module in the registry first 
      const moduleName = modulePath.split('/').pop().replace('.js', '');
      
      if (this.registry.has(moduleName) && !bypassRegistry) {
        return resolve(this.registry.get(moduleName));
      }
      else {
        return this.loadScript(modulePath, moduleName)
        .catch((err) => reject(err))
        .then((mod) => resolve(mod));
      }

    });

  };

  private loadScript(modulePath: string, moduleName: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const scriptId = `_script_${moduleName}`;
      const existingScriptEl = document.getElementById(scriptId);

      // If the script exists, delete it from the DOM
      if (existingScriptEl != null) {
        existingScriptEl.parentNode.removeChild(existingScriptEl);
      }

      // Create a new script element
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = modulePath;

      script.addEventListener('load', () => {
        this.dynamicDeps.add(moduleName);
        return resolve(this.registry.get(moduleName));
      });

      script.addEventListener('error', () => {        
        return reject(new Error('Unable to load the module requested.'));
      });

      document.getElementsByTagName('head')[0].appendChild(script);
    });
   
  }
  
}

const amd = new AMD();

(window as any).define = amd.define;
(window as any).require = amd.require;

export default amd;
