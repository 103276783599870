import * as React from 'react';
import Input from './Input';
import MultiSelect, { IMultiSelectProps } from './MultiSelect';

export default class MultiSelectInput extends React.Component<IMultiSelectProps, any> {
  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <MultiSelect {...this.props} {...props} />
          );
        }}
      </Input>
    );
  }
}
