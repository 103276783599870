import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlertModal } from '@Lineup/index';
import { IComponent, InjectedComponent } from '../../global/interfaces';

@inject('mobileBridgeStore') @observer
class BluetoothWarn extends InjectedComponent<IComponent, any> {

  public render(): JSX.Element {
    const { toggleBTWarning, showBTWarning } = this.props.mobileBridgeStore;

    return showBTWarning ? (
      <AlertModal
        close={toggleBTWarning}
        backdropClose={true}
        alertTitle={t('Bluetooth is Turned Off')}
        message={t('This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.')}
        cancelText={t('Close')}
      />
    ) : null;
  }

}

export default BluetoothWarn;
