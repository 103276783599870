import * as React from 'react';
import { createPortal } from 'react-dom';
const styles = require('./styles/sticky_header.styl');

export enum ScrollDirections {
  DEFAULT = 0,
  UP = 1,
  DOWN = -1
}

export interface IProps {
  height?: number;
  listScrollTop: number;
  offsetTop?: number;
}

interface IState {
  isStuck: boolean;
  scrollDirection: ScrollDirections;
}

/**
 * Sticky header component for use inside `ScrollContainer`
 * @param children The contents of the header.
 * @param height The height of the element. Defaults to `30`.
 * @param listScrollTop The `scrollTop` value of containing `ScrollContainer`. This is used to trigger re-renders on each scroll event.
 * @param offsetTop An optional offset to add to the absolute value used to position the header.
 */
export default class StickyHeader extends React.Component<IProps, IState> {
  private el: React.RefObject<HTMLLIElement> = React.createRef();
  private nextHeaderEl: Element;
  private prevHeaderEl: Element;

  public state = {
    isStuck: false,
    scrollDirection: 0,
  };

  public static defaultProps: Partial<IProps> = {
    height: 30,
    offsetTop: 0,
  };

  public shouldComponentUpdate(nextProps: IProps, nextState: IState): boolean {
    return this.props.listScrollTop !== nextProps.listScrollTop || this.props.offsetTop !== nextProps.offsetTop || this.state.isStuck !== nextState.isStuck;
  }

  public componentDidUpdate(prevProps: IProps): void {
    const { listScrollTop } = this.props;

    // Use offsetTop to determine which way the user is scrolling
    //  1: user is scrolling up
    // -1: user is scrolling down
    //  0: default state
    if (prevProps.listScrollTop !== listScrollTop) {
      this.stickOrUnstickElement();
      this.setState({
        scrollDirection: (prevProps.listScrollTop > listScrollTop ? ScrollDirections.UP : ScrollDirections.DOWN) ?? ScrollDirections.DEFAULT,
      });
    }
  }

  public render(): JSX.Element {
    const { children, height, offsetTop } = this.props;
    const { isStuck } = this.state;
    const stickyHeaderPortal = document.getElementById('-ldx-sticky-header-portal');
    const stickyHeader = (
      <div
        className={`${styles.StickyHeader} ${styles.isStuck} ${isStuck ? '' : styles.isHidden}`}
        style={{
          height,
          top: offsetTop,
        }}
      >
        <div>{children}</div>
      </div>
    );
    
    return (
      <>
        <li
          ref={this.el}
          className={`-ldx-sticky-header ${styles.StickyHeader} ${isStuck ? styles.isInvisible : ''}`}
          style={{
            height,
          }}
        >
          <div>{children}</div>
        </li>
        {stickyHeaderPortal != null && isStuck ? createPortal(stickyHeader, stickyHeaderPortal) : null}
      </>
    );
  }

  public stickOrUnstickElement = () => {
    const el = this.el.current;
    const listEl = el.parentElement as HTMLUListElement;
    const listOffsetTop = listEl.getBoundingClientRect().top;
    const elOffsetTop = el.getBoundingClientRect().top;
    const { scrollDirection } = this.state;

    // Figure out if the header should be stuck
    if (elOffsetTop <= listOffsetTop) {
      this.setState({
        isStuck: true,
      });

      // Once the header is stuck, find the next one down the list
      if (scrollDirection === ScrollDirections.DOWN && this.nextHeaderEl == null) {
        this.nextHeaderEl = this.findPreviousOrNextHeader(el, 1);
      }
    }
    // Unstick it when scrolling away
    else if (elOffsetTop > listOffsetTop) {
      this.setState({
        isStuck: false,
      });

      // Once the header is unstuck, find the previous one 
      if (scrollDirection === ScrollDirections.DOWN && this.prevHeaderEl == null) {
        this.prevHeaderEl = this.findPreviousOrNextHeader(el, -1);
      }
    }
  };

  public findPreviousOrNextHeader = (startingEl: Element, direction: 1 | -1): Element => {
    let node = (direction === 1 ? startingEl.nextElementSibling : startingEl.previousElementSibling) as Element;
    while (node) {
      if (node.classList.contains('-ldx-sticky-header')) {
        return node;
      }
      node = (direction === 1 ? node.nextElementSibling : node.previousElementSibling);
    }
  };
}
