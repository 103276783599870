module.exports = {
  "__appName__ is not currently available.": "__appName__ is not currently available.",
  "__fieldName__ is not a valid URL or IP address": "__fieldName__ is not a valid URL or IP address",
  "__fieldName__ must start with http:// or https://": "__fieldName__ must start with http:// or https://",
  "__fieldName__ should not start with http:// or https://": "__fieldName__ should not start with http:// or https://",
  "Access Code": "Access Code",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it",
  "Afghanistan": "Afghanistan",
  "Albania": "Albania",
  "Algeria": "Algeria",
  "American Samoa": "American Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua and Barbuda",
  "App": "App",
  "Application Error": "Application Error",
  "Application error.": "Application error.",
  "Application is not currently available.": "Application is not currently available.",
  "Apply Changes": "Apply Changes",
  "Apr": "Apr",
  "April": "April",
  "Argentina": "Argentina",
  "Armenia": "Armenia",
  "Aruba": "Aruba",
  "At least __value__ characters": "At least __value__ characters",
  "At least one lowercase letter": "At least one lowercase letter",
  "At least one number": "At least one number",
  "At least one of these special characters: __value__": "At least one of these special characters: __value__",
  "At least one uppercase letter": "At least one uppercase letter",
  "Aug": "Aug",
  "August": "August",
  "Australia": "Australia",
  "Austria": "Austria",
  "Azerbaijan": "Azerbaijan",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Belarus",
  "Belgium": "Belgium",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth is Turned Off",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnia and Herzegovina",
  "Botswana": "Botswana",
  "Brazil": "Brazil",
  "Bronchial Lavage": "Bronchial Lavage",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Cambodia",
  "Cameroon": "Cameroon",
  "Canada": "Canada",
  "Cancel": "Cancel",
  "Cannot be empty": "Cannot be empty",
  "Cape Verde": "Cape Verde",
  "Capillary Blood": "Capillary Blood",
  "Cayman Islands": "Cayman Islands",
  "Central African Republic": "Central African Republic",
  "Cerebrospinal Fluid": "Cerebrospinal Fluid",
  "Cervical Swab": "Cervical Swab",
  "Chad": "Chad",
  "Change Access Code": "Change Access Code",
  "Check again": "Check again",
  "Chile": "Chile",
  "China": "China",
  "Clear Search": "Clear Search",
  "Close": "Close",
  "Cocos(Keeling) Islands and Chr": "Cocos(Keeling) Islands and Chr",
  "Colombia": "Colombia",
  "Comoros": "Comoros",
  "Confirm": "Confirm",
  "Confirm New Password": "Confirm New Password",
  "Confirm new password": "Confirm new password",
  "Confirm New Password is required.": "Confirm New Password is required.",
  "Confirm Password is required.": "Confirm Password is required.",
  "Confirm Password must match New Password": "Confirm Password must match New Password",
  "Congo(Brazzaville)": "Congo(Brazzaville)",
  "Congo(Kinshasa)": "Congo(Kinshasa)",
  "Cook Islands": "Cook Islands",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Cote D'Ivoire (Ivory Coast)",
  "Croatia(Hrvatska)": "Croatia(Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Culture Broth",
  "Current Password": "Current Password",
  "Current Password is required.": "Current Password is required.",
  "Current password required": "Current password required",
  "Cyprus": "Cyprus",
  "Czech Republic": "Czech Republic",
  "Danish": "Danish",
  "Dec": "Dec",
  "December": "December",
  "Denmark": "Denmark",
  "Disabled": "Disabled",
  "Discard Changes": "Discard Changes",
  "Dismiss": "Dismiss",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Does not match your previous passwords",
  "Does not use unsupported characters": "Does not use unsupported characters",
  "Domain": "Domain",
  "Domain is required.": "Domain is required.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominican Republic",
  "Door Open": "Door Open",
  "Dutch": "Dutch",
  "Ecuador": "Ecuador",
  "Egypt": "Egypt",
  "El Salvador": "El Salvador",
  "Email": "Email",
  "Email is required.": "Email is required.",
  "English (UK)": "English (UK)",
  "English (US)": "English (US)",
  "Enter": "Enter",
  "Equatorial Guinea": "Equatorial Guinea",
  "Eritrea": "Eritrea",
  "Error": "Error",
  "Estonia": "Estonia",
  "Ethiopia": "Ethiopia",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager is not supported in the Connect mobile application.",
  "Failed to reset password. A server error was encountered": "Failed to reset password. A server error was encountered",
  "Falkland Islands(Malvinas)": "Falkland Islands(Malvinas)",
  "Faroe Islands": "Faroe Islands",
  "Fatal Application Error": "Fatal Application Error",
  "Feb": "Feb",
  "February": "February",
  "Fecal Specimen": "Fecal Specimen",
  "Fiji": "Fiji",
  "File type must be __fileType__": "File type must be __fileType__",
  "Finland": "Finland",
  "Finnish": "Finnish",
  "First Name": "First Name",
  "Forgot your password?": "Forgot your password?",
  "Fr": "Fr",
  "France": "France",
  "French": "French",
  "French Guiana": "French Guiana",
  "French Polynesia": "French Polynesia",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "German",
  "Germany": "Germany",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Go To __storeName__",
  "Greater than __value__ in __measure__": "Greater than __value__ in __measure__",
  "Greece": "Greece",
  "Greenland": "Greenland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea - Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct Out of Range",
  "Hide": "Hide",
  "Hide password": "Hide password",
  "Holy See(Vatican City State)": "Holy See(Vatican City State)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, SAR",
  "Hungary": "Hungary",
  "Iceland": "Iceland",
  "India": "India",
  "Indonesia": "Indonesia",
  "Initials": "Initials",
  "Install": "Install",
  "Install Now": "Install Now",
  "Instrument Not Level": "Instrument Not Level",
  "Insufficient Sample": "Insufficient Sample",
  "Internet connection is offline.": "Internet connection is offline.",
  "Invalid access code": "Invalid access code",
  "Invalid email format": "Invalid email format",
  "Invalid File": "Invalid File",
  "Invalid Lot Cal File": "Invalid Lot Cal File",
  "Invalid username or password.": "Invalid username or password.",
  "Iran, Islamic Republic of": "Iran, Islamic Republic of",
  "Iraq": "Iraq",
  "Ireland": "Ireland",
  "Israel": "Israel",
  "Italian": "Italian",
  "Italy": "Italy",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "January",
  "Japan": "Japan",
  "Japanese": "Japanese",
  "Jordan": "Jordan",
  "Jul": "Jul",
  "July": "July",
  "Jun": "Jun",
  "June": "June",
  "Kazakhstan": "Kazakhstan",
  "KC Swab": "KC Swab",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Democratic People's Rep",
  "Korea, Republic of(South)": "Korea, Republic of(South)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kyrgyzstan",
  "Language": "Language",
  "Laos(Lao PDR)": "Laos(Lao PDR)",
  "Last Name": "Last Name",
  "Latvia": "Latvia",
  "Lebanon": "Lebanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Less than __value__ in __measure__",
  "Liberia": "Liberia",
  "Libya": "Libya",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Liquid Quality Control",
  "Lithuania": "Lithuania",
  "Loading more records...": "Loading more records...",
  "Login": "Login",
  "Logout": "Logout",
  "LumiraDx Connect": "LumiraDx Connect",
  "LumiraDx Connect Hubs": "LumiraDx Connect Hubs",
  "LumiraDx Instruments": "LumiraDx Instruments",
  "Luxembourg": "Luxembourg",
  "Macao(SAR China)": "Macao(SAR China)",
  "Macedonia, Republic of": "Macedonia, Republic of",
  "Madagascar": "Madagascar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysia",
  "Maldives": "Maldives",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "March",
  "Marshall Islands": "Marshall Islands",
  "Martinique": "Martinique",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Maximum length is __count__ characters",
  "May": "May",
  "Mexico": "Mexico",
  "Micronesia, Federated States o": "Micronesia, Federated States o",
  "Mo": "Mo",
  "Mobile Application Version": "Mobile Application Version",
  "Moldova": "Moldova",
  "Monaco": "Monaco",
  "Mongolia": "Mongolia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Morocco and Western Sahara",
  "Mouth Swab": "Mouth Swab",
  "Mozambique": "Mozambique",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Nasal Discharge",
  "Nasal Swab": "Nasal Swab",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Netherlands",
  "Netherlands Antilles": "Netherlands Antilles",
  "Never Connected": "Never Connected",
  "New Caledonia": "New Caledonia",
  "New Password": "New Password",
  "New Password is invalid.": "New Password is invalid.",
  "New Password is required.": "New Password is required.",
  "New password must match": "New password must match",
  "New Version Required": "New Version Required",
  "New Zealand": "New Zealand",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "No application modules exist in this environment. Please report this issue to an administrator.",
  "No easily guessable words": "No easily guessable words",
  "No more than __value__ characters": "No more than __value__ characters",
  "No region set": "No region set",
  "No repetitive characters (e.g. aaa)": "No repetitive characters (e.g. aaa)",
  "No sequential characters (e.g. 123)": "No sequential characters (e.g. 123)",
  "No Test Strip Detected": "No Test Strip Detected",
  "none": "none",
  "Norfolk Island": "Norfolk Island",
  "Northern Mariana Islands": "Northern Mariana Islands",
  "Norway(inc Svalbard and Jan May": "Norway(inc Svalbard and Jan May",
  "Norwegian": "Norwegian",
  "Nov": "Nov",
  "November": "November",
  "NP Aspirate": "NP Aspirate",
  "NP Swab": "NP Swab",
  "Oct": "Oct",
  "October": "October",
  "Offline 1 day": "Offline 1 day",
  "Offline 1 hour": "Offline 1 hour",
  "Offline 1 minute": "Offline 1 minute",
  "Offline 1 month": "Offline 1 month",
  "Offline 1 year": "Offline 1 year",
  "Offline __days__ days": "Offline __days__ days",
  "Offline __hours__ hours": "Offline __hours__ hours",
  "Offline __minutes__ minutes": "Offline __minutes__ minutes",
  "Offline __months__ months": "Offline __months__ months",
  "Offline __years__ years": "Offline __years__ years",
  "OK": "OK",
  "Okay": "Okay",
  "Oman": "Oman",
  "On Premises Export": "On Premises Export",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestinian Territory, Occupie",
  "Panama": "Panama",
  "Papua New Guinea": "Papua New Guinea",
  "Paraguay": "Paraguay",
  "Password": "Password",
  "Password requirements:": "Password requirements:",
  "Peru": "Peru",
  "Philippines": "Philippines",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Poland",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portuguese (Brazil)",
  "Portuguese (Portugal)": "Portuguese (Portugal)",
  "Profile": "Profile",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Provide the following information to set your password.  The validation code was sent to your email, if valid.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Provide your email to request a password reset. A temporary validation code will be sent to your email.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC Solution Detected",
  "Quality Control": "Quality Control",
  "Rectal Swab": "Rectal Swab",
  "Required Update": "Required Update",
  "Reset": "Reset",
  "Reset Password": "Reset Password",
  "Reset password": "Reset password",
  "Resolution requirements": "Resolution requirements",
  "Return to Login": "Return to Login",
  "Romania": "Romania",
  "Russian Federation": "Russian Federation",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion and Mayotte",
  "Sa": "Sa",
  "Saint Helena and Tristan da Cu": "Saint Helena and Tristan da Cu",
  "Saint Kitts and Nevis": "Saint Kitts and Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent and the Grenadine",
  "Saliva": "Saliva",
  "Samoa": "Samoa",
  "Sample Detected": "Sample Detected",
  "Sample Error": "Sample Error",
  "Sample Type Error": "Sample Type Error",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi Arabia",
  "Save": "Save",
  "Select a date": "Select a date",
  "Select from list...": "Select from list...",
  "Select language": "Select language",
  "Selected files must match requirements": "Selected files must match requirements",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "September",
  "Serbia": "Serbia",
  "Serum": "Serum",
  "Server error, please try again later.": "Server error, please try again later.",
  "Server Error. Unable to save your profile.": "Server Error. Unable to save your profile.",
  "Set Password": "Set Password",
  "Settings": "Settings",
  "Seychelles": "Seychelles",
  "Show Password": "Show Password",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Size must be less than __maxSize__",
  "Skin Swab": "Skin Swab",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Solomon Islands": "Solomon Islands",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Sorry, DataView is not enabled, or you do not have access to this organization",
  "South Africa": "South Africa",
  "Space": "Space",
  "Spain": "Spain",
  "Spanish": "Spanish",
  "Spanish (US)": "Spanish (US)",
  "Sputum": "Sputum",
  "Sri Lanka": "Sri Lanka",
  "Su": "Su",
  "Sudan": "Sudan",
  "Suriname": "Suriname",
  "Swaziland": "Swaziland",
  "Sweden": "Sweden",
  "Swedish": "Swedish",
  "Switzerland": "Switzerland",
  "Syrian Arab Republic(Syria)": "Syrian Arab Republic(Syria)",
  "São Tomé and Príncipe": "São Tomé and Príncipe",
  "Taiwan, Republic of China": "Taiwan, Republic of China",
  "Tajikistan": "Tajikistan",
  "Tanzania, United Republic of": "Tanzania, United Republic of",
  "Temperature Error": "Temperature Error",
  "Test Cancelled": "Test Cancelled",
  "Test Lot Not Permitted": "Test Lot Not Permitted",
  "Test Operation Error": "Test Operation Error",
  "Test Results": "Test Results",
  "Test Strip Lot Expired": "Test Strip Lot Expired",
  "Test Strip Not Supported": "Test Strip Not Supported",
  "Test Strip Reading Error": "Test Strip Reading Error",
  "Test Timeout": "Test Timeout",
  "Test Type Not Permitted": "Test Type Not Permitted",
  "Th": "Th",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "The new password validation code will be sent to the email, if email is a valid user email.",
  "The reset validation code was populated from the link and should not be changed.": "The reset validation code was populated from the link and should not be changed.",
  "There are unsaved changes. How do you want to proceed?": "There are unsaved changes. How do you want to proceed?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "There is a new version available (__versionNumber__), would you like to install it now?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.",
  "Throat Swab": "Throat Swab",
  "Timor - Leste": "Timor - Leste",
  "Title": "Title",
  "TN Swab": "TN Swab",
  "to": "to",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad and Tobago",
  "Tu": "Tu",
  "Tunisia": "Tunisia",
  "Turkey": "Turkey",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks and Caicos Islands",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraine",
  "Unable to reset password, the provided current password is not valid.": "Unable to reset password, the provided current password is not valid.",
  "Unable to reset your password.": "Unable to reset your password.",
  "Unable to validate access code.": "Unable to validate access code.",
  "United Arab Emirates": "United Arab Emirates",
  "United Kingdom": "United Kingdom",
  "United States of America": "United States of America",
  "Unrecognized credentials. Please try again.": "Unrecognized credentials. Please try again.",
  "Unsaved Changes": "Unsaved Changes",
  "Update Available": "Update Available",
  "Update Password": "Update Password",
  "Urethral Swab": "Urethral Swab",
  "Urine": "Urine",
  "Uruguay": "Uruguay",
  "User account is temporarily locked. Please try again later.": "User account is temporarily locked. Please try again later.",
  "User Interface Version": "User Interface Version",
  "User logged out due to inactivity.": "User logged out due to inactivity.",
  "Username": "Username",
  "Users": "Users",
  "Uzbekistan": "Uzbekistan",
  "Vaginal Swab": "Vaginal Swab",
  "Validate Access Code": "Validate Access Code",
  "Validation Code": "Validation Code",
  "Validation Code is required.": "Validation Code is required.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela(Bolivarian Republic o",
  "Venous Blood": "Venous Blood",
  "Version": "Version",
  "Viet Nam": "Viet Nam",
  "Virgin Islands, British": "Virgin Islands, British",
  "Virgin Islands, US": "Virgin Islands, US",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvo Vaginal Swab",
  "Wallis and Futuna Islands": "Wallis and Futuna Islands",
  "We": "We",
  "We are working to get you back up and running as quickly as possible.": "We are working to get you back up and running as quickly as possible.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "We're sorry, LumiraDx Connect is currently down for maintenance.",
  "WorkGroups": "WorkGroups",
  "Yemen": "Yemen",
  "You have been signed out due to a password reset attempt.": "You have been signed out due to a password reset attempt.",
  "You have been signed out due to the creation of another session with the same credentials.": "You have been signed out due to the creation of another session with the same credentials.",
  "You have been signed out due to your account being disabled by an admin.": "You have been signed out due to your account being disabled by an admin.",
  "You have been signed out due to your password being changed by an admin.": "You have been signed out due to your password being changed by an admin.",
  "You have been signed out due to your user role being changed by an admin.": "You have been signed out due to your user role being changed by an admin.",
  "You have been signed out.": "You have been signed out.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.",
  "Your password has expired. Please set a new password.": "Your password has expired. Please set a new password.",
  "Your session has expired. Please login again.": "Your session has expired. Please login again.",
  "Your session has expired. User is not authorized.": "Your session has expired. User is not authorized.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
