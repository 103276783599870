import * as React from 'react';
import Spinner from './Spinner';
import { IValidationError, IBaseInputProps, IValidateOptions, IInputKeyboardProps, IKeyboard } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/input.styl');
    break;
  default:
    styles = require('./styles/input.styl');
    break;
}

export interface ITextProps extends IBaseInputProps {
  // Provided by Parent Input Component
  onKeyUp?: (e) => void;
  onPaste?: (e) => void;
  clear?: () => void;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
  autoCapitalize?: string;
  clearBtnClass?: string;
  title?: string;
  list?: string;
  passToggle?: boolean;
  revealPassword?: boolean;
  keyboard?: IKeyboard;
  resetPassToggle?: boolean;
  onTogglePasswordVisible?: (e) => void;
  highlight?: boolean;
  search?: boolean;
  showBarcodeIcon?: boolean;
  handleBarcodeClick?: () => void;
  formLabel?: string;
  ariaLabel?: string;
}

interface ITextState {
  showErrors: boolean;
  showPassword: boolean;
  inputHasBlurred: boolean;
  inputInFocus: boolean;
}

export default class Text extends React.Component<ITextProps, ITextState> {
  public state = {
    showErrors: false,
    showPassword: false,
    inputHasBlurred: false,
    inputInFocus: false,
  };

  private errorAnchor: HTMLElement;
  private input: React.RefObject<HTMLInputElement>;

  public static defaultProps = {
    type: 'text',
    className: styles.TextInput,
    wrapperClass: '',
    loading: false,
    disabled: false,
    highlight: false,
    search: false,
    autoComplete: 'on',
    validation: false,
    isInPopover: false,
    spellCheck: false,
    keepFocus: false,
    focusOnMount: false,
    focusOnMountDelay: 0,
    selectOnMount: false,
    forceShowAllErrors: false,
    returnNull: false,
    placeholder: null,
    showClear: false,
    displayErrorPvr: true,
    passToggle: false,
    mobileValidation: false,
    revealPassword: false,
    keyboard: null,
    resetPassToggle: null,
    showBarcodeIcon: false,
    handleBarcodeClick: null,
    formLabel: null,
    ariaLabel: null,
  };

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  public static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.resetPassToggle !== null && nextProps.resetPassToggle !== prevState.showPassword) {
      return {
        showPassword: nextProps.resetPassToggle,
      };
    }
    else {
      return null;
    }
  }

  public componentDidMount(): void {
    const { focusOnMount, selectOnMount, focusOnMountDelay } = this.props;

    if (focusOnMount) {
      setTimeout(() => this.input.current?.focus(), focusOnMountDelay);
    }
    if (selectOnMount) {
      setTimeout(() => this.input.current?.select(), 0);
    }
  }

  public render(): JSX.Element {
    const { showErrors, inputHasBlurred, inputInFocus } = this.state;
    let { showPassword } = this.state;

    const {
      className,
      wrapperClass,
      wrapperLabel,
      valueHasChanged,
      validate,
      loading,
      style,
      forceShowAllErrors,
      validation,
      displayErrorPvr,
      showClear,
      clearBtnClass,
      value = '',
      id,
      type,
      name,
      placeholder,
      maxLength,
      disabled,
      tabIndex,
      onPaste,
      onKeyUp,
      onKeyDown,
      onKeyPress,
      autoComplete,
      spellCheck,
      step,
      min,
      max,
      flashErrors,
      jsonPath,
      autoCapitalize,
      title,
      list,
      passToggle,
      mobileValidation,
      keyboard,
      revealPassword,
      highlight,
      search,
      showBarcodeIcon,
      handleBarcodeClick,
      formLabel,
      ariaLabel,
    } = this.props;

    showPassword = revealPassword || showPassword;

    const val = value as string;

    const error = validate({ value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;

    let outerClass = `${styles.FieldWrap} ${styles.TextInputWrapper}`;
    if (wrapperClass != null) { outerClass += ` ${wrapperClass}`; }

    if (!mobileValidation) {
      if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.Invalid}`; }
    }
    else {
      if (!isValid && (inputHasBlurred || forceShowAllErrors)) { outerClass += ` ${styles.Invalid}`; }
    }

    if (type === 'number') { outerClass += ` ${styles.Number}`; }

    let inputClass = (className != null) ? className : '';

    if (highlight) { inputClass += ` ${styles.Highlight}`; }
    if (loading) { inputClass += ` ${styles.TextLoadingSpinner}`; }
    if (passToggle) { inputClass += ` ${styles.PassToggleOn}`; }
    if (search) { inputClass += ` ${styles.Search} ${inputInFocus ? styles.Active : null}`; }

    let clearClass = styles.SearchClear;
    if (clearBtnClass) {
      clearClass = clearBtnClass;
    }

    const errorButtonClass = styles.FieldErrorsShow;

    // create aria label - ariaLabel then placeholder text then formLabel text
    const inputAriaLabel = ariaLabel !== null ? ariaLabel :
      placeholder !== null ? placeholder :
        formLabel !== null ? formLabel : null;

    let textInput = (
      <input
        key="textInput"
        ref={this.input}
        value={val}
        className={inputClass}
        onChange={this.handleChange}
        id={id || jsonPath}
        data-test={jsonPath}
        name={name || keyboard?.inputName}
        type={showPassword ? 'text' : type}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        tabIndex={tabIndex}
        onPaste={onPaste}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onBlur={this.onBlur}
        onFocus={this.handleFocus}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        step={step}
        min={min}
        max={max}
        onTouchStart={!mobileValidation ? this.handleErrorTouch : null}
        autoCapitalize={autoCapitalize}
        title={title}
        list={list}
        aria-label={inputAriaLabel}
      />
    );

    // Add a wrapper label element if label prop is present
    if (wrapperLabel) {
      textInput = (
        <label htmlFor={id || jsonPath}>
          {wrapperLabel}
          {textInput}
        </label>
      );
    }

    const loadingIndicator = loading ? <Spinner /> : null;

    const clearBtn = (val != null ? val.length : undefined) && showClear ? (
      <button
        className={clearClass}
        title={t('Clear Search')}
        onClick={this.handleClear}
        tabIndex={-1}
        onMouseDown={this.preventDefault}
      />
    ) : void 0;

    const passToggleBtn = passToggle ? (
      <input
        type="checkbox"
        className={styles.ShowPassBtn}
        title={showPassword ? t('Hide password') : t('Show Password')}
        onChange={this.togglePasswordVisibility}
        onMouseDown={this.preventDefault}
        tabIndex={-1}
        checked={showPassword}
        aria-label={showPassword ? t('Hide password') : t('Show Password')}
      />
    ) : void 0;

    const barCodeBtn = showBarcodeIcon ? (
      <button
        className={styles.BarcodeBtn}
        onClick={handleBarcodeClick}
        onMouseDown={this.preventDefault}
        tabIndex={-1}
      />
    ) : void 0;

    let errorEl;
    if (!mobileValidation && !isValid && displayErrorPvr && (showErrors || flashErrors)) {
      errorEl = <ValidationErrorPvr error={error} />;
    }
    else if (mobileValidation && !isValid && (inputHasBlurred || forceShowAllErrors)) {
      errorEl = <ul className={styles.MobileError} >{error.messages.map(e => <li key={e}>{e}</li>)}</ul>;
    }

    return (
      <div
        className={outerClass}
        style={style}
      >
        {textInput}
        {loadingIndicator}
        {clearBtn || barCodeBtn}
        {passToggleBtn}
        <div
          className={errorButtonClass}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
          onTouchStart={!mobileValidation ? this.handleErrorTouch : null}
        />
        {errorEl}
      </div>
    );
  }

  private handleClear = (): void => {
    const { clear, keyboard } = this.props;
    clear();

    if (keyboard) {
      const event = new CustomEvent('clear', { detail: { inputName: keyboard.inputName } });
      this.input?.current?.dispatchEvent(event);
    }
  };

  private handleChange = (e): void => {
    const { onChange } = this.props;
    const { value } = e.target;

    onChange(value);
  };

  private handleFocus = (e): void => {
    const { onFocus, keyboard } = this.props;

    this.setState({ inputInFocus: true });

    const kbProps: IInputKeyboardProps = keyboard ? {
      ...keyboard,
      onChange: this.handleChange,
    } : null;

    onFocus?.(e, kbProps);

  };

  private onBlur = (e): void => {
    const { onBlur, keyboard, keepFocus } = this.props;

    if (keepFocus) {
      this.input.current.focus();
      return;
    }

    this.setState({ inputHasBlurred: true, inputInFocus: false });

    const kbProps: IInputKeyboardProps = keyboard ? {
      ...keyboard,
      onChange: this.handleChange,
    } : null;

    onBlur?.(e, kbProps);
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private togglePasswordVisibility = (e): void => {
    const { onTogglePasswordVisible } = this.props;

    const caretPos = this.input.current?.selectionStart;

    this.input.current?.focus();

    if (typeof onTogglePasswordVisible === 'function') {
      return onTogglePasswordVisible(e);
    }

    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword }, () => {
      setTimeout(() => {
        this.input.current.setSelectionRange(caretPos, caretPos);
      }, 0);
    });
  };

  public preventDefault = (e): void => {
    e.preventDefault();
  };

  private handleErrorTouch = (e): void => {
    const { valueHasChanged, forceShowAllErrors } = this.props;
    if (!valueHasChanged && !forceShowAllErrors) { return; }

    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

  public getValue(): string {
    const { returnNull } = this.props;
    return returnNull && this.input.current.value === '' ? null : this.input.current.value;
  }
}
