module.exports = {
  "__appName__ is not currently available.": "__appName__ atualmente indisponível.",
  "Access Code": "Código de acesso",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Conta ${this.username} bloqueada devido a 5 tentativas de início de sessão. Contacte o seu administrador para desbloqueá-la",
  "Afghanistan": "Afeganistão",
  "Albania": "Albânia",
  "Algeria": "Argélia",
  "American Samoa": "Samoa Americana",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguila",
  "Antigua and Barbuda": "Antígua e Barbuda",
  "App": "App",
  "Application Error": "Erro da aplicação",
  "Application error.": "Erro da aplicação.",
  "Application is not currently available.": "Aplicação atualmente indisponível.",
  "Apply Changes": "Aplicar alterações",
  "Apr": "Abr",
  "April": "Abril",
  "Argentina": "Argentina",
  "Armenia": "Arménia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Pelo menos __value__ caracteres",
  "At least one lowercase letter": "Pelo menos uma letra minúscula",
  "At least one number": "Pelo menos um número",
  "At least one of these special characters: __value__": "Pelo menos um destes carateres especiais: __value__",
  "At least one uppercase letter": "Pelo menos uma letra maiúscula",
  "Aug": "Ago",
  "August": "Agosto",
  "Australia": "Austrália",
  "Austria": "Áustria",
  "Azerbaijan": "Azerbaijão",
  "Bahamas": "Bahamas",
  "Bahrain": "Barém",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Bielorrússia",
  "Belgium": "Bélgica",
  "Belize": "Belize",
  "Benin": "Benim",
  "Bermuda": "Bermudas",
  "Bhutan": "Butão",
  "Bluetooth is Turned Off": "O Bluetooth está desligado",
  "Bolivia": "Bolívia",
  "Bosnia and Herzegovina": "Bósnia e Herzegovina",
  "Botswana": "Botsuana",
  "Brazil": "Brasil",
  "Bronchial Lavage": "Lavagem brônquica",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgária",
  "Burkina Faso": "Burquina Fasso",
  "Burundi": "Burundi",
  "Cambodia": "Camboja",
  "Cameroon": "Camarões",
  "Canada": "Canadá",
  "Cancel": "Cancelar",
  "Cannot be empty": "Não pode estar vazio",
  "Cape Verde": "Cabo Verde",
  "Capillary Blood": "Sangue capilar",
  "Cayman Islands": "Ilhas Caimão",
  "Central African Republic": "República Centro-Africana",
  "Cerebrospinal Fluid": "Líq cefalorraquidiano",
  "Cervical Swab": "Zaragatoa colo útero",
  "Chad": "Chade",
  "Change Access Code": "Alterar código de acesso",
  "Check again": "Verificar novamente",
  "Chile": "Chile",
  "China": "China",
  "Clear Search": "Remover procura",
  "Close": "Fechar",
  "Cocos(Keeling) Islands and Chr": "Ilhas Cocos (Keeling) e Ilha do Natal",
  "Colombia": "Colômbia",
  "Comoros": "Comores",
  "Confirm": "Confirmar",
  "Confirm New Password": "Confirmar palavra-passe nova",
  "Confirm new password": "Confirmar palavra-passe nova",
  "Confirm New Password is required.": "É necessário confirmar a palavra-passe nova.",
  "Confirm Password is required.": "É necessário confirmar a palavra-passe.",
  "Confirm Password must match New Password": "A palavra-passe em Confirmar palavra-passe tem de corresponder com a Palavra-passe nova",
  "Congo(Brazzaville)": "Congo (Brazavile)",
  "Congo(Kinshasa)": "Congo (Quinxasa)",
  "Cook Islands": "Ilhas Cook",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Cote D'Ivoire (Costa do Marfim)",
  "Croatia(Hrvatska)": "Croácia (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Meio cultura líquido",
  "Current Password": "Palavra-passe atual",
  "Current Password is required.": "É necessária a palavra-passe atual.",
  "Current password required": "É necessária a palavra-passe atual",
  "Cyprus": "Chipre",
  "Czech Republic": "República Checa",
  "Danish": "Dinamarquês",
  "Dec": "Dez",
  "December": "Dezembro",
  "Denmark": "Dinamarca",
  "Disabled": "Desativ.",
  "Discard Changes": "Rejeitar alterações",
  "Dismiss": "Dispensar",
  "Djibouti": "Djibuti",
  "Does not match your previous passwords": "Não corresponde às suas palavras-passe anteriores",
  "Does not use unsupported characters": "Não utiliza carateres não suportados",
  "Domain": "Domínio",
  "Domain is required.": "É necessário um domínio.",
  "Dominica": "Dominica",
  "Dominican Republic": "República Dominicana",
  "Door Open": "Porta aberta",
  "Dutch": "Neerlandês",
  "Ecuador": "Equador",
  "Egypt": "Egito",
  "El Salvador": "El Salvador",
  "Email": "E-mail",
  "Email is required.": "É necessário um e-mail.",
  "English (UK)": "Inglês (Reino Unido)",
  "English (US)": "Inglês (EUA)",
  "Enter": "Introduzir",
  "Equatorial Guinea": "Guiné Equatorial",
  "Eritrea": "Eritreia",
  "Error": "Erro",
  "Estonia": "Estónia",
  "Ethiopia": "Etiópia",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager não é suportado na aplicação móvel Connect.",
  "Failed to reset password. A server error was encountered": "Não foi possível redefinir a palavra-passe. Foi detetado um erro no servidor",
  "Falkland Islands(Malvinas)": "Ilhas Falkland (Malvinas)",
  "Faroe Islands": "Ilhas Faroé",
  "Fatal Application Error": "Erro fatal da aplicação",
  "Feb": "Fev",
  "February": "Fevereiro",
  "Fecal Specimen": "Espécime fecal",
  "Fiji": "Fiji",
  "File type must be __fileType__": "O tipo de ficheiro tem de ser __fileType__",
  "Finland": "Finlândia",
  "Finnish": "Finlandês",
  "First Name": "Nome próprio",
  "Forgot your password?": "Esqueceu-se da palavra-passe?",
  "Fr": "Sex.",
  "France": "França",
  "French": "Francês",
  "French Guiana": "Guiana Francesa",
  "French Polynesia": "Polinésia Francesa",
  "Gabon": "Gabão",
  "Gambia": "Gâmbia",
  "Georgia": "Geórgia",
  "German": "Alemão",
  "Germany": "Alemanha",
  "Ghana": "Gana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Ir para __storeName__",
  "Greater than __value__ in __measure__": "Superior a __value__ em __measure__",
  "Greece": "Grécia",
  "Greenland": "Gronelândia",
  "Grenada": "Granada",
  "Guadeloupe": "Guadalupe",
  "Guam": "Guame",
  "Guatemala": "Guatemala",
  "Guinea": "Guiné",
  "Guinea - Bissau": "Guiné-Bissau",
  "Guyana": "Guiana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct fora de limite",
  "Hide": "Ocultar",
  "Hide password": "Ocultar palavra-passe",
  "Holy See(Vatican City State)": "Santa Sé (Cidade do Vaticano)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, RAE",
  "Hungary": "Hungria",
  "Iceland": "Islândia",
  "India": "Índia",
  "Indonesia": "Indonésia",
  "Initials": "Iniciais",
  "Install": "Instalar",
  "Install Now": "Instalar agora",
  "Instrument Not Level": "Instrument não nivelado",
  "Insufficient Sample": "Amostra insuficiente",
  "Internet connection is offline.": "A ligação à Internet está desligada.",
  "Invalid access code": "Código de acesso inválido",
  "Invalid email format": "Formato de e-mail inválido",
  "Invalid File": "Ficheiro inválido",
  "Invalid Lot Cal File": "Ficheiro de cal. lote inválido",
  "Invalid username or password.": "Nome de utilizador ou palavra-passe inválidos.",
  "Iran, Islamic Republic of": "Irão, República Islâmica do",
  "Iraq": "Iraque",
  "Ireland": "Irlanda",
  "Israel": "Israel",
  "Italian": "Italiano",
  "Italy": "Itália",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "Janeiro",
  "Japan": "Japão",
  "Japanese": "Japonês",
  "Jordan": "Jordânia",
  "Jul": "Jul",
  "July": "Julho",
  "Jun": "Jun",
  "June": "Junho",
  "Kazakhstan": "Cazaquistão",
  "KC Swab": "Zaragatoa de KC",
  "Kenya": "Quénia",
  "Kiribati": "Quiribáti",
  "Korea, Democratic People's Rep": "Coreia, República Popular Democrática da",
  "Korea, Republic of(South)": "Coreia, (do Sul) República da",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Quirguistão",
  "Language": "Idioma",
  "Laos(Lao PDR)": "Laos (RDP do Laos)",
  "Last Name": "Apelido",
  "Latvia": "Letónia",
  "Lebanon": "Líbano",
  "Lesotho": "Lesoto",
  "Less than __value__ in __measure__": "Inferior a __value__ em __measure__",
  "Liberia": "Libéria",
  "Libya": "Líbia",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Controlo de Qualidade líquido",
  "Lithuania": "Lituânia",
  "Loading more records...": "A carregar mais registos...",
  "Login": "Ini sess",
  "Logout": "Terminar sessão",
  "Luxembourg": "Luxemburgo",
  "Macao(SAR China)": "Macau (RAE de China)",
  "Macedonia, Republic of": "Macedónia, República da",
  "Madagascar": "Madagáscar",
  "Malawi": "Maláui",
  "Malaysia": "Malásia",
  "Maldives": "Maldivas",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Março",
  "Marshall Islands": "Ilhas Marshall",
  "Martinique": "Martinica",
  "Mauritania": "Mauritânia",
  "Mauritius": "Maurícia",
  "MAX": "MÁX.",
  "Maximum length is __count__ characters": "O comprimento máximo é de __count__ carateres",
  "May": "Mai",
  "Mexico": "México",
  "Micronesia, Federated States o": "Micronésia, Estados Federados da",
  "Mo": "Seg.",
  "Mobile Application Version": "Versão da aplicação móvel",
  "Moldova": "Moldávia",
  "Monaco": "Mónaco",
  "Mongolia": "Mongólia",
  "Montenegro": "Montenegro",
  "Montserrat": "Monserrate",
  "Morocco and Western Sahara": "Marrocos e Saara Ocidental",
  "Mouth Swab": "Zaragatoa bucal",
  "Mozambique": "Moçambique",
  "Myanmar": "Mianmar",
  "Namibia": "Namíbia",
  "Nasal Discharge": "Descarga nasal",
  "Nasal Swab": "Zaragatoa nasal",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Países Baixos",
  "Netherlands Antilles": "Antilhas Neerlandesas",
  "Never Connected": "Nunca ligado",
  "New Caledonia": "Nova Caledónia",
  "New Password": "Palavra-passe nova",
  "New Password is invalid.": "Palavra-passe nova inválida.",
  "New Password is required.": "É necessária uma palavra-passe nova.",
  "New password must match": "A palavra-passe nova tem de corresponder",
  "New Version Required": "É necessária uma versão nova",
  "New Zealand": "Nova Zelândia",
  "Nicaragua": "Nicarágua",
  "Niger": "Níger",
  "Nigeria": "Nigéria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Não existe nenhum módulo da aplicação neste ambiente. Comunique este problema a um administrador.",
  "No easily guessable words": "Nenhuma palavra fácil de adivinhar",
  "No more than __value__ characters": "Não mais de __value__ caracteres",
  "No region set": "Nenhuma região def",
  "No repetitive characters (e.g. aaa)": "Nenhum caracter repetitivo (por ex. aaa)",
  "No sequential characters (e.g. 123)": "Nenhum caracter sequencial (por ex. 123)",
  "No Test Strip Detected": "Nenhuma Tira de Teste detetada",
  "none": "nenhum(a)",
  "Norfolk Island": "Ilha Norfolk",
  "Northern Mariana Islands": "Ilhas Marianas do Norte",
  "Norway(inc Svalbard and Jan May": "Noruega (inc Svalbard e Jan Mayen)",
  "Norwegian": "Norueguês",
  "Nov": "Nov",
  "November": "Novembro",
  "NP Aspirate": "Aspirado nasofarín.",
  "NP Swab": "Zaragatoa nasofar",
  "Oct": "Out",
  "October": "Outubro",
  "Offline 1 day": "Offline há 1 dia",
  "Offline 1 hour": "Offline há 1 hora",
  "Offline 1 minute": "Offline há 1 minuto",
  "Offline 1 month": "Offline há 1 mês",
  "Offline 1 year": "Offline há 1 ano",
  "Offline __days__ days": "Offline há __days__ dias",
  "Offline __hours__ hours": "Offline há __hours__ horas",
  "Offline __minutes__ minutes": "Offline há __minutes__ minutos",
  "Offline __months__ months": "Offline há __months__ meses",
  "Offline __years__ years": "Offline há __years__ anos",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Omã",
  "Pakistan": "Paquistão",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestiniano, Território Ocupado",
  "Panama": "Panamá",
  "Papua New Guinea": "Papua Nova Guiné",
  "Paraguay": "Paraguai",
  "Password": "Palavra-passe",
  "Password requirements:": "Requisitos da palavra-passe:",
  "Peru": "Peru",
  "Philippines": "Filipinas",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polónia",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Português (Brasil)",
  "Portuguese (Portugal)": "Português (Portugal)",
  "Profile": "Perfil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Forneça as seguintes informações para definir a palavra-passe.  O código de validação foi enviado para o seu e-mail, se este for válido.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Forneça o seu e-mail para solicitar uma reposição da palavra-passe. Será enviado um código de validação temporário para o seu e-mail.",
  "Puerto Rico": "Porto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "Solução QC detetada",
  "Rectal Swab": "Zaragatoa retal",
  "Required Update": "Atualização necessária",
  "Reset": "Repor",
  "Reset Password": "Repor palavra-passe",
  "Reset password": "Repor palavra-passe",
  "Resolution requirements": "Requisitos de resolução",
  "Return to Login": "Voltar para iniciar sessão",
  "Romania": "Roménia",
  "Russian Federation": "Federação Russa",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Reunião e Maiote",
  "Sa": "Sáb.",
  "Saint Helena and Tristan da Cu": "Santa Helena e Tristão da Cunha",
  "Saint Kitts and Nevis": "São Cristóvão e Névis",
  "Saint Lucia": "Santa Lúcia",
  "Saint Pierre and Miquelon": "São Pedro e Miquelão",
  "Saint Vincent and the Grenadine": "São Vicente e Granadinas",
  "Saliva": "Saliva",
  "Samoa": "Samoa",
  "Sample Detected": "Amostra detetada",
  "Sample Error": "Erro de amostra",
  "Sample Type Error": "Erro de tipo de amostra",
  "San Marino": "São Marino",
  "Saudi Arabia": "Arábia Saudita",
  "Save": "Guardar",
  "Select a date": "Selecionar uma data",
  "Select from list...": "Selecionar na lista...",
  "Select language": "Selecionar idioma",
  "Selected files must match requirements": "Os ficheiros selecionados têm de cumprir os requisitos",
  "Senegal": "Senegal",
  "Sep": "Set",
  "September": "Setembro",
  "Serbia": "Sérvia",
  "Serum": "Soro",
  "Server error, please try again later.": "Erro no servidor, tente novamente mais tarde.",
  "Server Error. Unable to save your profile.": "Erro no servidor. Não é possível guardar o seu perfil.",
  "Set Password": "Definir palavra-passe",
  "Seychelles": "Seicheles",
  "Show Password": "Mostrar palavra-passe",
  "Sierra Leone": "Serra Leoa",
  "Singapore": "Singapura",
  "Size must be less than __maxSize__": "O tamanho tem de ser inferior a __maxSize__",
  "Skin Swab": "Zaragatoa cutânea",
  "Slovakia": "Eslováquia",
  "Slovenia": "Eslovénia",
  "Solomon Islands": "Ilhas Salomão",
  "Somalia": "Somália",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Desculpe, DataView não está ativado ou não tem acesso a esta organização",
  "South Africa": "África do Sul",
  "Space": "Espaço",
  "Spain": "Espanha",
  "Spanish": "Espanhol",
  "Spanish (US)": "Espanhol (EUA)",
  "Sputum": "Expetoração",
  "Sri Lanka": "Sri Lanka",
  "Su": "Dom.",
  "Sudan": "Sudão",
  "Suriname": "Suriname",
  "Swaziland": "Essuatíni",
  "Sweden": "Suécia",
  "Swedish": "Sueco",
  "Switzerland": "Suíça",
  "Syrian Arab Republic(Syria)": "República Árabe Síria (Síria)",
  "São Tomé and Príncipe": "São Tomé e Príncipe",
  "Taiwan, Republic of China": "Taiwan, República da China",
  "Tajikistan": "Tajiquistão",
  "Tanzania, United Republic of": "Tanzânia, República Unida da",
  "Temperature Error": "Erro de temperatura",
  "Test Cancelled": "Teste cancelado",
  "Test Lot Not Permitted": "Lote de testes não permitido",
  "Test Operation Error": "Erro de operação de teste",
  "Test Strip Lot Expired": "Lote de Tira de Teste expirado",
  "Test Strip Not Supported": "Tira de Teste não compatível",
  "Test Strip Reading Error": "Erro de leitura da Tira de Teste",
  "Test Timeout": "Tempo de teste excedido",
  "Test Type Not Permitted": "Tipo de teste não permitido",
  "Th": "Qui.",
  "Thailand": "Tailândia",
  "The new password validation code will be sent to the email, if email is a valid user email.": "O código de validação da palavra-passe nova será enviado para o e-mail, se este for um e-mail válido do utilizador.",
  "The reset validation code was populated from the link and should not be changed.": "O código de validação da reposição foi preenchido a partir da hiperligação e não deve ser alterado.",
  "There are unsaved changes. How do you want to proceed?": "Existem alterações não guardadas. Como deseja prosseguir?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Está disponível uma versão nova(__versionNumber__). Gostaria de a instalar agora?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Este dispositivo já não funciona como um Connect Hub. Para continuar a utilizar este dispositivo como um Connect Hub, reative o Bluetooth nas definições do seu dispositivo e volte a ligar Utilizar este dispositivo móvel como um Connect Hub no ecrã Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Esta versão do LumiraDx Connect já não é compatível com a sua organização. Vá a __storeName__ e atualize para a última versão.",
  "Throat Swab": "Zaragatoa orofaríng",
  "Timor - Leste": "Timor - Leste",
  "Title": "Título",
  "TN Swab": "Zaragatoa TN",
  "to": "até",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Para continuar a utilizar __APP_NAME__, atualize para a versão mais recente (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Toquelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad e Tobago",
  "Tu": "Ter.",
  "Tunisia": "Tunísia",
  "Turkey": "Turquia",
  "Turkmenistan": "Turquemenistão",
  "Turks and Caicos Islands": "Ilhas Turcas e Caicos",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ucrânia",
  "Unable to reset password, the provided current password is not valid.": "Não foi possível repor a palavra-passe; a palavra-passe atual fornecida não é válida.",
  "Unable to reset your password.": "Não foi possível redefinir a sua palavra-passe.",
  "Unable to validate access code.": "Não foi possível validar o código de acesso.",
  "United Arab Emirates": "Emirados Árabes Unidos",
  "United Kingdom": "Reino Unido",
  "United States of America": "Estados Unidos da América",
  "Unrecognized credentials. Please try again.": "Credenciais não reconhecidas. Tente novamente.",
  "Unsaved Changes": "Alterações não guardadas",
  "Update Available": "Atualização disponível",
  "Update Password": "Atualizar palavra-passe",
  "Urethral Swab": "Zaragatoa uretral",
  "Urine": "Urina",
  "Uruguay": "Uruguai",
  "User account is locked. Please try again in an hour.": "Conta de utilizador bloqueada. Tente novamente daqui a uma hora.",
  "User Interface Version": "Versão da interface de utilizador",
  "User logged out due to inactivity.": "Sessão do utilizador terminada devido a inatividade.",
  "Username": "Nome do utilizador",
  "Uzbekistan": "Uzbequistão",
  "Vaginal Swab": "Zaragatoa vaginal",
  "Validate Access Code": "Validar código de acesso",
  "Validation Code": "Código de validação",
  "Validation Code is required.": "É necessário um código de validação.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (República Bolivariana da)",
  "Venous Blood": "Sangue venoso",
  "Version": "Versão",
  "Viet Nam": "Vietname",
  "Virgin Islands, British": "Ilhas Virgens Britânicas",
  "Virgin Islands, US": "Ilhas Virgens Americanas",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Zaragatoa vulvovagi",
  "Wallis and Futuna Islands": "Wallis e Futuna",
  "We": "Qua.",
  "We are working to get you back up and running as quickly as possible.": "Estamos a trabalhar para o pôr a funcionar o mais rapidamente possível.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Pedimos desculpa, o LumiraDx Connect está atualmente inoperacional para manutenção.",
  "Yemen": "Iémen",
  "You have been signed out due to a password reset attempt.": "A sua sessão foi terminada devido a uma tentativa de reposição da palavra-passe.",
  "You have been signed out due to the creation of another session with the same credentials.": "A sua sessão foi terminada porque foi criada outra sessão com as mesmas credenciais.",
  "You have been signed out due to your account being disabled by an admin.": "A sua sessão foi terminada porque a sua conta foi desativada por um administrador.",
  "You have been signed out due to your password being changed by an admin.": "A sua sessão foi terminada porque a sua palavra-passe foi alterada por um administrador.",
  "You have been signed out due to your user role being changed by an admin.": "A sua sessão foi terminada porque a sua função de utilizador foi alterada por um administrador.",
  "You have been signed out.": "A sua sessão foi terminada.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Precisa de instalar uma versão diferente (__versionNumber__) de __APP_NAME__ que seja compatível com a sua organização.",
  "Your password has expired. Please set a new password.": "A palavra-passe expirou. Defina uma palavra-passe nova.",
  "Your session has expired. Please login again.": "A sessão expirou. Inicie novamente a sessão.",
  "Your session has expired. User is not authorized.": "A sessão expirou. Utilizador não autorizado.",
  "Zambia": "Zâmbia",
  "Zimbabwe": "Zimbábue"
};
