import * as React from 'react';
import * as Animation from 'ainojs-animation';
import * as easing from 'ainojs-easing';
import Spinner, { SpinnerNames } from './Spinner';
import ProgressBar from './ProgressBar';

const styles = require('./styles/confirm_save.styl');
const progressBarStyles = require('./styles/progress_bar.styl');

/*
@general
  This component is intended to be used inside of a modal or popover, but could be used other places to confirm a save event was successful.
  Tips for using this..
  - It is absolutely positioned w/ all zeroes, to add to the top level of the render tree
  - Create a local 'saveState' state on your component, and default it to null
  - render like this:

  ```coffee
  ConfirmSave {
    key: 'confirm'
    done: @close
    saveState: @state.saveState
  } if @state.saveState?
  ```

  This way, when you trigger the save, also call @setState({saveState: 'pending'})
  Then pass a call back to the save action, which just calls @setState({saveState: 'complete'})

@props.saveState - REQUIRED - [String]
  This can be either 'pending' or 'complete' or 'failed'
    - When 'pending', a spinner shows
    - When 'complete', a green check mark flashes before calling the done callback
    - When 'warning', a yellow exclamation mark flashes before calling the done callback
    - When 'failed', a red x flashes before calling the fail callback

@props.saveMessage
  A message that will be displayed alongside the saveState imagery

@props.longSaveMessage - default false
  Will improve formatting for long error messages

@props.dismissBtnText
  Text that will be displayed as the dismiss button

@props.done - REQUIRED - [Function]
  This method will be called animationDuration ms after the saveState hits complete
  It will usually be a method that sets the parent's saveState back to null, which will remove this widget from the DOM, and will usually be a method that also performs some other post save action

@props.fail
  This method will be called animationDuration ms after the saveState hits failed
  It is REQUIRED if there is any change saveState will ever hit 'failed', otherwise the component will never hide after a failed save
  It will usually be a method that sets the parent's saveState back to null, which will remove this widget from the DOM

@props.scaleCheckmark - OPTIONAL - [Number] - default 1
  percent to scale the confirm check and spinner

@props.vTranslateCheckmark - OPTIONAL - default 0
  number of pixels to move the checkmark up above the middle of the container

@props.animationDuration - OPTIONAL - default 800
  ms over which the check scalling takes place

@props.displayProgressBar
  Defaults to no, whether or not the confirm/save show the progress bar instead of the spinner

@props.uploadProgress - OPTIONAL - [String | Number]
  Progress of a file being uploaded

@props.className - OPTIONAL [String]
  Class name applied to primary container

@props.spinner - OPTIONAL [JSX Element]
  If a spinner element is passed, this will be used instead of the generic Lineup spinner.
&*/

export interface IConfirmSaveProps {
  saveState: SaveStates;
  saveMessage?: string | JSX.Element;
  longSaveMessage?: boolean;
  dismissBtnText?: string;
  done?: () => void;
  fail?: () => void;
  scaleCheckmark?: number;
  vTranslateCheckmark?: number;
  animationDuration?: number;
  displayProgressBar?: boolean;
  uploadProgress?: string | number;
  spinnerName?: SpinnerNames;
  shiftSaveMessage?: number;
  className?: string;
  spinner?: JSX.Element;
  confirmCheckClass?: string;
  saveTitle?: string;
}

export enum SaveStates {
  PENDING,
  COMPLETE,
  WARNING,
  FAILED
}

export class ConfirmSave extends React.Component<IConfirmSaveProps, any> {
  private endHasBeenCalled: boolean = false;
  private animateImmediately: boolean;
  private animation;

  public static defaultProps = {
    dismissBtnText: 'Dismiss',
    scaleCheckmark: 1,
    animationDuration: 800,
    vTranslateCheckmark: 0,
    saveMessage: '',
    displayProgressBar: false,
    uploadProgress: '',
    longSaveMessage: false,
    spinnerName: SpinnerNames.BallScaleRipple,
    shiftSaveMessage: 0,
    className: '',
    saveTitle: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      scale: .25,
    };

    // Handles the case when the saveState is never pending
    if ((this.props.saveState === SaveStates.COMPLETE) || (this.props.saveState === SaveStates.FAILED)) {
      this.animateImmediately = true;
    }
  }

  public componentDidMount() {
    if (this.animateImmediately) { return this.animateCheck(); }
  }

  public componentDidUpdate(prevProps) {
    const { saveState } = this.props;

    // Only animate the post-pending state when the state switches from PENDING to COMPLETE, Warning or FAILED
    if ((prevProps.saveState !== SaveStates.PENDING) || ![SaveStates.COMPLETE, SaveStates.WARNING, SaveStates.FAILED].includes(saveState)) {
      return;
    }

    this.animateCheck();
  }

  public componentWillUnmount() {
    if (this.animation != null ? this.animation.isAnimating() : undefined) { return this.animation.end(); }
  }

  public render(): JSX.Element {
    let checkClassName;
    let content;
    let displayContent;
    const { dismissBtnText, saveState, scaleCheckmark, vTranslateCheckmark, saveMessage, displayProgressBar, uploadProgress, longSaveMessage, spinnerName, shiftSaveMessage, className, spinner, confirmCheckClass, saveTitle } = this.props;
    const { scale } = this.state;
    let newScaleCheckmark = scaleCheckmark;

    let confirmSaveClass = `${styles.ConfirmSaveWrappper} ${className}`;
    if (saveMessage) { confirmSaveClass += ` ${styles.HasMessage}`; }

    if ((saveState === SaveStates.PENDING) && displayProgressBar && (uploadProgress !== '')) {
      confirmSaveClass += ` ${styles.ProgressBackground}`;

      content = (
        <ProgressBar
          key="progress"
          progress={uploadProgress}
          className={progressBarStyles.ModalBar}
          labelPosition="top"
        />
      );
    } else if (saveState === SaveStates.PENDING) {
      content = spinner != null ? spinner : (
        <Spinner
          color="white"
          name={spinnerName}
          wrapperClass={styles.SpinnerWrapper}
        />
      );
      // Use a separate component for messages
    } else if (saveMessage) {
      checkClassName = styles.ConfirmCheckMessage;
      if (saveState === SaveStates.FAILED) { checkClassName += ` ${styles.Failed}`; }
      if (saveState === SaveStates.WARNING) { checkClassName += ` ${styles.Warning}`; }
      if (longSaveMessage) { checkClassName += ` ${styles.Long}`; }

      const messageStyle = {
        transform: `translateY(${shiftSaveMessage}px)`,
        msTransform: `translateY(${shiftSaveMessage}px)`,
        WebkitTransform: `translateY(${shiftSaveMessage}px)`,
      };

      // Don't do a scale animation when there's a message
      newScaleCheckmark = 1;

      content = [];
      content.push(
        <div key="icon" className={checkClassName} style={messageStyle}>
          <span className="confirm-message">{saveMessage}</span>
        </div>
      );
      content.push(
        <div key="dismiss" className={styles.MessageDismiss}>
          <button onClick={this.end}>{dismissBtnText}</button>
        </div>
      );
    }
    else if ((saveState === SaveStates.COMPLETE) && saveTitle !== '') {
      checkClassName = confirmCheckClass ? confirmCheckClass : styles.ConfirmCheck;
      const checkStyles = {
        transform: `scale(${scale})`,
        msTransform: `scale(${scale})`,
        WebkitTransform: `scale(${scale})`,
      };

      content = [];
      content.push((
        <div key="titleWrap" className={styles.Header}>
          <div key="title" className={styles.Title}>
            {saveTitle}
          </div>
        </div>
      ));
      content.push((
        <div key="check" className={`${checkClassName} ${styles.CenterCheck}`} style={checkStyles} />
      ));
    } else {
      checkClassName = confirmCheckClass ? confirmCheckClass : styles.ConfirmCheck;
      if (saveState === SaveStates.FAILED) { checkClassName = `${styles.ConfirmCheck} ${styles.Failed}`; }
      if (saveState === SaveStates.WARNING) { checkClassName = `${styles.ConfirmCheck} ${styles.Warning}`; }
      const checkStyles = {
        transform: `scale(${scale})`,
        msTransform: `scale(${scale})`,
        WebkitTransform: `scale(${scale})`,
      };
      content = (
        <div
          className={checkClassName}
          style={checkStyles}
        />
      );
    }

    if ((saveState === SaveStates.PENDING) && displayProgressBar && (uploadProgress !== '')) {
      displayContent = (
        <div className={styles.ProgressBarFrame}>
          {content}
        </div>
      );
    } else {
      const confirmStyles = {
        transform: `scale(${newScaleCheckmark}) translateY(${vTranslateCheckmark}px)`,
        msTransform: `scale(${newScaleCheckmark}) translateY(${vTranslateCheckmark}px)`,
        WebkitTransform: `scale(${newScaleCheckmark}) translateY(${vTranslateCheckmark}px)`,
      };
      displayContent = (
        <div className={`${styles.ConfirmFrame} ${(saveState === SaveStates.COMPLETE) && saveTitle !== '' ? styles.AmiraConfirmBoxMedium : null}`} style={!saveMessage ? confirmStyles : null}>
          {content}
        </div>
      );
    }

    return (
      <div className={confirmSaveClass}>
        {displayContent}
      </div>
    );
  }

  private animateCheck() {

    if (this.animation != null ? this.animation.isAnimating() : undefined) { this.animation.end(); }

    const { scale } = this.state;
    const { animationDuration, saveMessage } = this.props;

    this.animation = new Animation({
      duration: animationDuration,
      easing: easing('easeOutElastic'),
    }).init({ scale })
      .on('frame', this.onFrame)
      .on('complete', saveMessage ? () => null : this.end)
      .animateTo({ scale: 1 });
  }

  private onFrame = (e): void => {
    this.setState(e.values);
  };

  private end = (): void => {
    const { saveState, done, fail } = this.props;

    if (!this.endHasBeenCalled) {
      this.endHasBeenCalled = true;
      if (saveState === SaveStates.COMPLETE) {
        if (typeof done === 'function') {
          done();
        }
      } else {
        if (typeof fail === 'function') {
          fail();
        }
      }
    }
  };
}

export default ConfirmSave;
