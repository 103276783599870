import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ScrollContainer } from '@Lineup/index';
import { IComponent } from '../../global/interfaces';
import { Drawer } from '@Lineup/index';

const styles = require('./styles/DebugLog.styl');

interface IProps extends IComponent {
  close: () => void;
}

@inject('mobileBridgeStore') @observer
class DebugLog extends React.Component<IProps, any> {

  public render(): JSX.Element {
    const { mobileBridgeStore: { debugLog }, close } = this.props;

    return (
      <Drawer
        // modifierClass={styles.ReportingDrawer}
        close={close}
        scaleOthers={true}
        noPadding
      >
        <ScrollContainer className={styles.Container} data-test="debug-log">
          <figure className={styles.Scroll}>
            <figcaption>Mobile Debug Log</figcaption>
            <button className={styles.CopyBtn} onClick={this.copyLog}>Copy</button>
            <button className={styles.ClearBtn} onClick={this.handleClearClick}>Clear</button>
            <pre className={styles.CodeWrapper}>
              <div className={styles.LineNumbers}>
                {debugLog.length ? debugLog.map((t, i) => {
                  return t?.match ? `${i + 1}${(t.match(/\n/g) || []).join('')}\n` : 1;
                }) : 1
                }
              </div>
              <code className={styles.Code}>
                {debugLog.length ? debugLog.join('\n') : 'Log is empty.'}
              </code>
            </pre>
          </figure>
        </ScrollContainer>
      </Drawer>
    );
  }

  public handleClearClick = (): void => {
    const { resetLog } = this.props.mobileBridgeStore;
    resetLog();
  };

  public copyLog = async () => {
    const { debugLog } = this.props.mobileBridgeStore;

    try {
      await navigator.clipboard.writeText(debugLog.join('\n'));
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

}

export default DebugLog;
