import * as React from 'react';

export interface INumberRangeProps {
  name?: string;
  min: number;
  max: number;
  step: number;
  defaultValue?: number;
  onChange?: (val: number) => void;
  onRelease?: (val: number) => void;
  className?: string;
  disabled?: boolean;
  value?: number;
}

interface IState {
  value: number;
}

export default class NumberRange extends React.Component<INumberRangeProps, IState> {
  private range: React.RefObject<HTMLInputElement>;

  public static defaultProps = {
    name: 'range',
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue,
    };

    this.range = React.createRef();
  }

  public render(): JSX.Element {
    const { name, min, max, step, defaultValue, className, disabled, value } = this.props;

    const interactions = disabled ? null : {
      onChange: this.handleChange,
      onTouchEnd: this.handlePointerUp,
      onMouseUp: this.handlePointerUp,
    };

    return (
      <>
        <input
          {...interactions}
          ref={this.range}
          className={className}
          name={name}
          type={'range'}
          min={min} max={max} step={step}
          defaultValue={defaultValue}
          disabled={disabled}
          value={value}
        />
      </>
    );
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props;
    this.setState({value: +e?.target?.value});
    onChange?.(+e?.target?.value);
  };

  public handlePointerUp = (e: any): void => {
    const { onRelease } = this.props;
    onRelease?.(this.state.value);
  };
}
