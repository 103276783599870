import { MobileBridgeStore } from '../stores/MobileBridge';
import { MOBILE_APPS } from '../global/constants';

/**
 * Whether or not this app uses the browser local storage APIs. Android and iOS apps use the native app layer
 * Web apps and instrument UI uses the browsers local storage
 * @returns boolean
 */
const useLocalStorage = (): boolean => {
  return !process.env.MOBILE_APP || process.env.MOBILE_APP === MOBILE_APPS.INSTRUMENT || process.env.MOBILE_APP === MOBILE_APPS.ON_PREM;
};

/**
 * Set an item in local storage and return the result
 * @param key Local storage key to set
 * @param data The data captured from local storage
 * @param stringify The returned data passed through JSON.stringify. Default: true
 */
export const setItem = (key: string, data: any, stringify: boolean = true): any => {
  const d = stringify && !process.env.MOBILE_APP ? JSON.stringify(data) : data;

  useLocalStorage() ?
    localStorage?.setItem(key, d) :
    MobileBridgeStore.setConnectData({ [key]: d });

  return d;
};

/**
 * Get an item from local storage and return the parsed result
 * @param key Local storage key to access
 * @param parse Whether or not to run the output through JSON.parse. Default: true
 */
export const getItem = (key: string, parse: boolean = true): any => {
  let data: string = useLocalStorage() ?
    localStorage?.getItem(key) :
    MobileBridgeStore.ldxConnectData[key];

  if (parse && !process.env.MOBILE_APP) {
    try {
      data = JSON.parse(data);
    }
    catch (e) {
      data = null;
    }
  }
  return data;
};

/**
 * Remove an item from local storage and return the removed item
 * @param key Local storage key to remove
 */
export const removeItem = (key: string): any => {
  const val = getItem(key);

  useLocalStorage() ?
    localStorage?.removeItem(key) :
    MobileBridgeStore.setConnectData({ [key]: null });

  return val;
};
