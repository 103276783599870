import * as React from 'react';
import SearchInput from './SearchInput';
import Spinner, { SpinnerNames } from './Spinner';
import Keyboard from './Keyboard';
import { IBaseInputProps, InputValue } from './interfaces';
import SelectCustomLoadingRow from './SelectCustomLoadingRow';

const styles = require('./styles/custom_select.styl');

export interface ISelectCustomOptionsState {
  focusedOptionIndex: number;
}

export interface ISelectCustomOptionsProps extends IBaseInputProps {
  filter?: string;
  options: Map<number, any>;
  filteredOptions: Map<number, any>;
  optionHeight: number;
  value?: InputValue;
  labelField: string;
  valueField: string;
  SelectEl?: HTMLSelectElement;
  noResultsText?: string;
  isFilter?: boolean;
  searchWidth?: number;
  closeOptions: () => any;
  onChange: (value: InputValue, jsonPath?: string, callback?: (opts?: any) => any) => void;
  onChangeFilter: (filterValue: string) => any;
  loading: boolean;
  optionsFullyLoaded: boolean;
  getNextOptionsPage: () => void;
}

/**
 * @param filter
 * Initialize the component overlay with a filter value. This will start filtering option labels based on this value.
 * @param placeholder
 * Placeholder value for the filter input
 * @param onChange
 * Function that is fired when a selection change is made
 * @param options
 * Array of options to render in the select
 * @param optionHeight
 * The fixed height of each menu option
 * @param value
 * The value of the currently selected option object
 * @param noResultsText
 * Text displayed in the menu when no results match the filter input value
 * @param SelectEl
 * Reference to the select menu component that opens this overlay. If provided, focus will be directed back to the input when closing the overlay
 * @param onChangeFilter
 * Function fired when the filter input changes
 * @param searchWidth
 * Width of the search input. Default is 250
 * @param closeOptions
 * Close options handler
 * @param getOptions
 * method that consume the paging api with the options
 * @param loading
 * true when the options are being fetched on a filter change
 */
export default class SelectCustomOptions extends React.Component<ISelectCustomOptionsProps, ISelectCustomOptionsState> {
  public static defaultProps = {
    filter: '',
    isFilter: true,
    searchWidth: 250,
    noResultsText: 'No matches found',
  };

  public state = {
    focusedOptionIndex: -1,
  };

  private textInput: any;
  private optionsList: any;

  public componentDidMount(): void {
    const { filter, SelectEl } = this.props;

    // If using a filter, focus that on mount
    if (filter) {
      this.handleFilterChange(filter);
    } else {
      __guard__(SelectEl != null ? SelectEl : undefined, x => x.blur());
    }

    // Focus the filter by default when not on mobile
    // Too jarring on mobile as the keyboard comes up
    if (!process.env.MOBILE_APP) {
      setTimeout(() => this.textInput?.focus(), 50);
    }

    // Add arrow key handlers
    window.addEventListener('keydown', this.handleKeyDown);

  }

  public componentWillUnmount(): void {
    return window.removeEventListener('keydown', this.handleKeyDown);
  }

  public render(): JSX.Element {
    const {
      placeholder,
      noResultsText,
      isFilter,
      searchWidth,
      options,
      filteredOptions,
      filter,
      loading,
      optionsFullyLoaded,
      getNextOptionsPage,
    } = this.props;

    const optionListClass = isFilter ? styles.OptionsList : `${styles.OptionsList} ${styles.NoFilter}`;

    // Render options elements
    const selectOptions = [];
    let index = 0;
    const filterActive = filter !== '';
    (filterActive ? filteredOptions : options)
      .forEach((option, id) => {
        selectOptions.push(this.processOption(id, option, index));
        index++;
      });

    if (!optionsFullyLoaded && filter === '') {
      selectOptions.push(<SelectCustomLoadingRow key="loading-row" getNextOptionsPage={getNextOptionsPage} optionsFullyLoaded={optionsFullyLoaded}/>);
    }

    return (
      <div className={styles.ListWrapper} onClick={this.handleOptionsClick}>
        {isFilter ? (
          <SearchInput
            key="searchInput"
            ref={input => this.textInput = input}
            id="filter"
            value={filter}
            onChange={this.handleFilterChange}
            placeholder={placeholder}
            wrapperClass={styles.CustomFilterSelect}
            width={searchWidth}
            showClear={true}
          />
        ) : null}
        {loading ? (
          <Spinner
            key="spinner"
            wrapperClass={styles.SpinnerWrapper}
            name={SpinnerNames.BallScaleRipple}
            color="gray"
          />
        ) : filterActive && filteredOptions.size === 0 ? (
          <div key="no-results" className={styles.NoResults}>{noResultsText}</div>
        ) : (
          <ul
            key="options"
            id="OptionsList"
            className={optionListClass}
            ref={(optionsList) => this.optionsList = optionsList}
            onScroll={this.handleScroll}
            onTouchMove={this.stopProp}
          >
            {selectOptions}
          </ul>
        )}

      </div>
    );
  }

  public processOption = (id, opt, index): JSX.Element => {
    const { focusedOptionIndex } = this.state;
    const { value, labelField, valueField } = this.props;
    let optionClass = styles.Option;

    if (index === focusedOptionIndex) { optionClass += ` ${styles.IsFocused}`; }
    if (value === opt[valueField]) { optionClass += ` ${styles.IsSelected}`; }

    return (
      <li
        key={id}
        onClick={this.handleClick.bind(this, opt)}
        onMouseOver={this.handleMouseOver.bind(this, index)}
        onMouseOut={this.handleMouseOut.bind(this, index)}
        className={optionClass}
        title={opt[labelField]}
      >
        {opt[labelField]}
      </li>
    );
  };

  public handleKeyDown = (e) => {
    const { focusedOptionIndex } = this.state;
    const { isFilter, closeOptions, jsonPath, options, filteredOptions, filter } = this.props;
    let adjust = 0;
    const currentOption = Array.from((filter.trimStart().length ? filteredOptions : options).values())[focusedOptionIndex];

    switch (e.keyCode) {
      case Keyboard.UP_ARROW:
        e.preventDefault();
        adjust = -1;
        break;
      case Keyboard.DOWN_ARROW:
        e.preventDefault();
        adjust = 1;
        break;
      case Keyboard.TAB:
        e.preventDefault();
        return closeOptions();
      case Keyboard.ESCAPE:
        e.preventDefault();
        return closeOptions();
      case Keyboard.ENTER:
        e.preventDefault();
        if (currentOption != null) {
          return this.props.onChange(currentOption, jsonPath);
        }
        return closeOptions();
      default:
        e.stopPropagation();
        break;
    }

    let newIndex = focusedOptionIndex + adjust;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= (options.size - 1)) {
      newIndex = options.size - 1;
    }

    this.setState({
      focusedOptionIndex: newIndex,
    }, () => {
      if (isFilter) {
        this.textInput?.focus();
      }
      if (this.optionsList != null) {
        return this.adjustScrollPosition(newIndex);
      }
    });
  };

  public adjustScrollPosition(newIndex) {
    const { optionHeight } = this.props;
    const { scrollTop, clientHeight } = this.optionsList;

    const newIndexTop = optionHeight * newIndex;
    const newIndexBottom = newIndexTop + optionHeight;

    const isOffBottom = newIndexBottom > (scrollTop + clientHeight);
    const isOffTop = newIndexTop < scrollTop;

    let adjust = 0;

    if (isOffBottom) {
      adjust = newIndexBottom - (scrollTop + clientHeight) + 3;
    } else if (isOffTop) {
      adjust = 0 - (scrollTop - newIndexTop) - 3;
    }

    // Adjust the scrollTop position
    if (adjust !== 0) { return this.optionsList.scrollTop = scrollTop + adjust; }
  }

  public handleFilterChange = (filter): void => {
    const { onChangeFilter } = this.props;

    onChangeFilter(filter?.trimStart());
    this.setState({ focusedOptionIndex: -1 });
  };

  public handleClick = (option, e): void => {
    const { onChange } = this.props;
    return onChange(option);
  };

  public handleMouseOver = (focusedOptionIndex, e): void => {
    return this.setState({ focusedOptionIndex });
  };

  public handleMouseOut = (focusedOptionIndex, e): void => {
    return this.setState({ focusedOptionIndex: -1 });
  };

  public handleOptionsClick = (e) => {
    return e.stopPropagation();
  };

  public handleScroll = (e) => {
    return e.stopPropagation();
  };

  public stopProp = (e): void => {
    if (!this.optionsList) { return; }

    const { scrollHeight, clientHeight } = this.optionsList;

    if (scrollHeight > clientHeight) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

  };

}

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
