import * as React from 'react';
import Spinner from './Spinner';
import { IValidationError, IBaseInputProps, IValidateOptions } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';
import { synthesizeMouseEvent } from './utils';

const styles = require('./styles/input.styl');

export interface ISelectProps extends IBaseInputProps {
  // Provided by Parent Input Component
  onKeyUp?: (e) => void;
  clear?: () => void;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
  options?: any[];
  returnFullObjects?: boolean;
  selectText?: string;
  valueField?: string;
  labelField?: string;
  openOnMount?: boolean;
  checked?: boolean;
  formLabel?: string;
  ariaLabel?: string;
}

interface IState {
  showErrors: boolean;
  inputHasBlurred: boolean;
}

export default class Select extends React.Component<ISelectProps, IState> {
  public static defaultProps = {
    className: styles.SelectMenu,
    id: null,
    name: null,
    wrapperClass: null,
    wrapperLabel: null,
    loading: false,
    tabIndex: null,
    onKeyDown: null,
    onKeyPress: null,
    onFocus: null,
    onBlur: null,
    onKeyUp: null,
    onEnterKey: null,
    onChange: null,
    disabled: false,
    validation: false,
    isInPopover: false,
    delayedActionOnChange: null,
    openOnMount: false,
    returnFullObjects: false,
    valueField: 'value',
    labelField: 'label',
    selectText: null,
    returnNull: false,
    mobileValidation: false,
    formLabel: null,
    ariaLabel: null,
  };

  public state = {
    showErrors: false,
    inputHasBlurred: false,
  };

  private errorAnchor: HTMLElement;
  private selectEl: React.RefObject<HTMLSelectElement>;

  constructor(props) {
    super(props);
    this.selectEl = React.createRef();
  }

  public componentDidMount(): any {
    const { focusOnMount, openOnMount } = this.props;
    const { current } = this.selectEl;

    if (focusOnMount) { current.focus(); }

    if (openOnMount) {
      setTimeout(() => {
        synthesizeMouseEvent(current, 'mousedown');
      }, 15);
    }
  }

  public render(): JSX.Element {
    const { inputHasBlurred } = this.state;
    const { name, options, tabIndex, className, loading, onKeyDown, onKeyPress, onFocus, wrapperClass,
      wrapperLabel, id, disabled, validation, valueHasChanged, validate, forceShowAllErrors, returnFullObjects,
      selectText, onKeyUp, valueField, labelField, flashErrors, jsonPath, isFieldRequired, mobileValidation, formLabel, ariaLabel } = this.props;

    let { value = '' } = this.props;
    const { showErrors } = this.state;
    const error = validate({ value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;

    let outerClass = styles.FieldWrap;
    if (wrapperClass != null) { outerClass += ` ${wrapperClass}`; }

    if (!mobileValidation) {
      if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.Shrink} ${styles.Invalid}`; }
    }
    else {
      if (!isValid && (inputHasBlurred || forceShowAllErrors)) { outerClass += ` ${styles.Shrink} ${styles.Invalid}`; }
    }

    // When returnFullObjects is on, then overwrite the value (which will be an object),
    // with its 'valueField' attribute
    if (returnFullObjects && (value != null)) {
      value = value[valueField];
    }

    const optionEls = options.map((item, index) => {
      return (
        <option
          key={`${item[valueField]}_${index}`}
          value={item[valueField]}
          disabled={item.disabled}
        >{item[labelField]}</option>
      );
    });

    if (selectText != null) {
      optionEls.unshift(<option key="default" value="" >{selectText}</option>);
    }

    // create aria label - ariaLabel then placeholder text then formLabel text
    const inputAriaLabel = ariaLabel !== null ? ariaLabel :
      formLabel !== null ? formLabel : null;

    let selectInput = (
      <select
        key="selectMenu"
        ref={this.selectEl}
        name={name}
        data-test={jsonPath}
        onChange={this.handleChange}
        onKeyUp={onKeyUp}
        value={value as string}
        className={className}
        id={id || jsonPath}
        tabIndex={tabIndex}
        onFocus={onFocus}
        onBlur={this.onBlur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        disabled={disabled}
        onTouchStart={!mobileValidation ? this.handleErrorTouch : null}
        required={isFieldRequired ? true : false}
        aria-label={inputAriaLabel}
      >{optionEls}</select>
    );

    // Add a wrapper label element if label prop is present
    if (wrapperLabel) {
      selectInput = (
        <label htmlFor={id || jsonPath}>
          {wrapperLabel}
          {selectInput}
        </label>
      );
    }

    const loadingIndicator = loading ? <Spinner /> : null;

    let errorEl;
    if (!mobileValidation && !isValid && (showErrors || flashErrors)) {
      errorEl = <ValidationErrorPvr error={error} />;
    }
    else if (mobileValidation && !isValid && (inputHasBlurred || forceShowAllErrors)) {
      errorEl = <ul className={styles.MobileError} >{error.messages.map(e => <li key={e}>{e}</li>)}</ul>;
    }

    return (
      <div className={outerClass}>
        {selectInput}
        {loadingIndicator}
        <div
          className={styles.FieldErrorsShow}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
          onTouchStart={!mobileValidation ? this.handleErrorTouch : null}
        />
        {errorEl}
      </div>
    );
  }

  private handleChange = (e): void => {
    const { onChange, jsonPath } = this.props;
    const { value } = e.target;
    onChange(value, jsonPath);
  };

  private onBlur = (e): void => {
    const { onBlur } = this.props;
    // Fix an iOS issue where <html> scrollTop is modified improperly when selector is closed
    document.getElementsByTagName('html')[0].scrollTop = 0;

    this.setState({ inputHasBlurred: true });

    onBlur?.(e);
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    const { valueHasChanged, forceShowAllErrors } = this.props;
    if (!valueHasChanged && !forceShowAllErrors) { return; }

    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

  public getValue(): any {
    const { returnFullObjects, valueField = 'value', value, options } = this.props;

    if (returnFullObjects && (value != null)) {
      for (const opt of options) {
        if (opt[valueField] === value) {
          return opt;
        }
      }
    }
    return value;
  }
}
