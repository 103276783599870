import * as React from 'react';
import { IBaseInputProps } from './interfaces';

const styles = require('./styles/multi_select.styl');

export interface IMultiSelectOptionItemProps extends IBaseInputProps {
  index: number;
  focusOption: any;
  option?: any;
  isSelected: boolean;
  isFocused: boolean;
  labelField?: string;
  onRemove: (option: any) => any;
  onAdd: (option: any) => any;
}

export default class MultiSelectOptionItem extends React.Component<IMultiSelectOptionItemProps, any> {
   
  public render() {
    const {option, isSelected, labelField, isFocused} = this.props;

    const label = option[labelField] || option;
    let className = 'multi-opt';
    if (isSelected) { className += ` ${styles.Selected}`; }
    if (isFocused) { className += ` ${styles.Focused}`; }

    return (
      <li
        data-test={label}
        className={className} 
        title={label}
        onClick={this.handleClick}
        onMouseOver={this.handleMouseOver}
      >
        {label}
      </li>
    );
  }
  
  private handleClick = (): void => {
    const {option, isSelected, onAdd, onRemove} = this.props;

    if (isSelected) { 
      onRemove(option); 
    } else { 
      onAdd(option); 
    }
  };

  private handleMouseOver = (): void => {
    const {index, focusOption} = this.props;
    focusOption(index);
  };
}
