import * as React from 'react';
import { SelectionWheelContainer, GridFormInput } from '@Lineup/index';
import { ISelectionWheel, ISelectionWheelContainerProps } from '@Lineup/SelectionWheelContainer';
import { ITextProps } from './Text';
import { IGridFormInputProps } from './GridFormInput';

interface IState {
  showWheel: boolean;
  wheels: ISelectionWheel[];
}

export interface IGridFormSelectionWheelProps extends Partial<ITextProps>, Partial<IGridFormInputProps>, Partial<ISelectionWheelContainerProps>  {
  title: string;
  optionWheels?: ISelectionWheel[];
  jsonPath?: string;
  generateWheels?: () => ISelectionWheel[] ;
  getNewValue?: (wheels) => any;
  columnRuleCheck?: (wheels: ISelectionWheel[]) => ISelectionWheel[];
  selectionWheelRef?: React.RefObject<SelectionWheelContainer>;
}

export default class GridFormSelectionWheel extends React.Component<IGridFormSelectionWheelProps, IState> {

  public static defaultProps = {
    title: '',
    optionWheels: [{name: ''}],
  };

  constructor(props) {
    super(props);

    this.state = {
      showWheel: false,
      wheels: props.optionWheels,
    };
  }

  public render(): JSX.Element {
    const { showWheel } = this.state;
    const { jsonPath, name, columnRuleCheck, selectionWheelRef, optionWheels } = this.props;

    return (
      <>
        <GridFormInput {...this.props} name={name ?? jsonPath} highlight={showWheel} onFocus={this.handleFocus} />
        {showWheel &&
          <SelectionWheelContainer
            {...this.props}
            ref={selectionWheelRef}
            textInputName={name ?? jsonPath}
            columnRuleCheck={columnRuleCheck}
            selectionChange={(wheels: ISelectionWheel[]) => this.handleChange(wheels)}
            wheels={optionWheels}
            onClosed={this.onClosed}
            jsonPath={jsonPath}
          />
        }
      </>
    );
  }

  private getValue = (wheelsNew) => {
    const value = [];
    wheelsNew.forEach((wheel) => wheel.items?.some((item) => {if (item.selected){value.push(item.value ?? item.text); return; }}));
    return value.join(' ');
  };

  private handleChange = (wheelsNew: any) => {
    const { onChange, jsonPath, getNewValue } = this.props;
    const value = getNewValue ? getNewValue?.(wheelsNew) : this.getValue(wheelsNew);
    onChange(value, jsonPath);

    this.setState({showWheel: true, wheels: wheelsNew});
  };

  private handleFocus = (e) => {
    const { onFocus, generateWheels } = this.props;
    e.preventDefault();
    const wheels = generateWheels?.();
    onFocus?.(e);
    e.target.blur();  // Get around cursor/carat showing through from input on chromium
    this.setState({showWheel: true, wheels: wheels ?? this.state.wheels});
  };

  public onClosed = () => {
    this.setState({showWheel: false});
  };
}
