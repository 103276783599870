import * as React from 'react';
import { IValidationError, PvrDirections } from './interfaces';
import Pvr from './Pvr';
import Keyboard from './Keyboard';

const styles = require('./styles/validation_error_pvr.styl');

export interface IValidationErrorPvrProps {
  error: IValidationError;
}

class ValidationErrorPvr extends React.Component<IValidationErrorPvrProps, any> {

  private overlayEl: HTMLElement;
  private modalScrollArea: HTMLElement;

  public constructor(props) {
    super(props);

    this.overlayEl = document.getElementById('validation-errors');
    this.modalScrollArea = document.querySelector('[class^="modal__ModalContent"]');

    if (!this.overlayEl) {
      const el = document.createElement('div');
      el.id = 'validation-errors';
      this.overlayEl = el;
      this.overlayEl.style.zIndex = '16';
      document.body.appendChild(this.overlayEl);
    }

  }

  componentDidMount(): void {
    this.modalScrollArea?.addEventListener('scroll', this.updateOnScroll);
  }

  componentWillUnmount(): void {
    this.modalScrollArea?.removeEventListener('scroll', this.updateOnScroll);
    if (this.modalScrollArea) {
      this.modalScrollArea.style.borderTop = 'none';
    }
  }

  public render(): JSX.Element {
    const { messages, direction = PvrDirections.BELOW, anchor } = this.props.error;

    const listItems = messages.map((msg) => {
      return (
        <li key={msg} className="field-error">
          {msg}
        </li>
      );
    });

    return this.errorIsScrolledOff() ? null : (
      <Pvr
        anchor={anchor}
        height="auto"
        autoHeightClass={styles.FieldErrorsList}
        width={200}
        vAdjust={0}
        hAdjust={-1}
        hSlide={80}
        direction={direction}
        nibColor="rgb(226,42,32)"
        updateOnKeys={[Keyboard.HOME, Keyboard.END, Keyboard.PAGE_UP, Keyboard.PAGE_DOWN, Keyboard.UP_ARROW, Keyboard.DOWN_ARROW]}
        close={() => null}
        root={this.overlayEl}
        nonBlocking={true}
        styleMixin={{transformOrigin: '90% 0%'}}
      >
        <ul className={styles.FieldErrorsList}>
          {listItems}
        </ul>
      </Pvr>
    );
  }

  errorIsScrolledOff(): boolean {

    if (!this.modalScrollArea) { return false; }
    if (!(this.modalScrollArea.scrollHeight > this.modalScrollArea.clientHeight)) { return false; }

    const { anchor } = this.props.error;
    if (!anchor) { return false; }

    const { top, bottom} = anchor.getBoundingClientRect();
    const scrollArea = this.modalScrollArea.getBoundingClientRect();

    if (bottom + 10 < scrollArea.top) {
      this.modalScrollArea.style.borderTop = '1px solid red';
      return true;
    }
    else if (top + 25 > scrollArea.bottom) {
      this.modalScrollArea.style.borderTop = '1px solid red';
      return true;
    }
    else { return false; }
  }

  updateOnScroll = () => {
    this.forceUpdate();
  };

}

export default ValidationErrorPvr;
