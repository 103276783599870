import * as React from 'react';
import Overlay from './Overlay';
import SelectionWheel, { SelectionWheelAlignments, ISelectionWheelItem } from './SelectionWheel';
import { centerInputScroll, resetInputScroll } from './utils';

const styles = require('./styles/selection_wheel.styl');

interface IState {
  wheels: ISelectionWheel[];
  startClose: boolean;
}

export interface ISelectionWheel {
  id: number;
  items: ISelectionWheelItem[];
  align?: SelectionWheelAlignments;
}

export interface ISelectionWheelContainerProps {
  title: string;
  wheels: ISelectionWheel[];
  buttons?: [{text: string, handler: () => any}]; // TODO: add button customisation
  selectionChange?: (selections: any) => void;
  columnRuleCheck?: (wheels: ISelectionWheel[]) => ISelectionWheel[];
  onClosed?: () => void;
  vMax?: number;
  transitions?: boolean;
  continuousUpdate?: boolean;
  className?: string;
  jsonPath?: string;
  scrollSelector?: string;
  textInputName?: string;
  focusSelectorOnEnter?: string;
  onActive?: () => void;
  onInactive?: () => void;
}

export default class SelectionWheelContainer extends React.Component<ISelectionWheelContainerProps, IState> {
  private contentEl: React.RefObject<HTMLDivElement>;

  public static defaultProps = {
    continuousUpdate: false,
    title: 'Selection Wheel',
    vMax: 600,
    transitions: true,
    className: null,
    focusSelectorOnEnter: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      startClose: false,
      wheels: props.wheels,
    };

    this.contentEl = React.createRef();
  }

  public componentDidMount(): void {
    const { scrollSelector, textInputName, onActive } = this.props;

    const scrollEl = document.querySelector(scrollSelector) as HTMLDivElement;
    const scrollElRect = scrollEl?.getBoundingClientRect();
    const wheelRect = this.contentEl?.current?.getBoundingClientRect();

    // get selection wheel height
    const wheelHeight = wheelRect?.bottom - wheelRect?.top;

    // get scroll element height
    const scrollHeight = wheelRect.top - scrollElRect?.top;

    centerInputScroll(scrollSelector, textInputName, wheelHeight, scrollHeight);

    onActive?.();
  }

  public componentWillUnmount() {
    const { scrollSelector, onInactive } = this.props;
    resetInputScroll(scrollSelector);
    onInactive?.();
  }

  public render(): JSX.Element {
    const { startClose, wheels } = this.state;
    const { title, className, jsonPath, transitions } = this.props;

    const animationStyle = `${styles.Wrapper} ${transitions ? styles.Enter : ''} ${startClose ? styles.Exit : ''}`;

    return (
      <Overlay backdropClose={false} fadeBackground={true} startBackgroundFade={startClose}>
        <div ref={this.contentEl} className={`${styles.Wrapper} ${styles.Ghost}`} />
        <div onAnimationEnd={startClose ? this.close : null} className={animationStyle} data-test="selection-wheel">
          <div className={styles.Header}>
            {title}
          </div>
          <div className={styles.Target} />
          <div className={styles.Overlay} />
          <div className={`${styles.Wheels} ${className}`}>
            {wheels && wheels.map((column) =>
              <div key={`wheel-container-${column.id}`} className={styles.Wheel}>
                <SelectionWheel key={`wheel-${column.id}`} items={column.items} align={column.align} selectionChange={(items: any) => this.update(column.id, items)} jsonPath={jsonPath}/>
              </div>
            )}
          </div>
          <div className={styles.Buttons}>
            <button className={styles.Button} onClick={this.handleCancel}>{t('Cancel')}</button>
            <button className={styles.Button} onClick={this.handleEnter}>{t('Enter')}</button>
          </div>
        </div>
      </Overlay>
    );
  }

  public handleClose = (alwaysTransition: boolean = false): void => {
    const { transitions, focusSelectorOnEnter } = this.props;

    if ((transitions && !focusSelectorOnEnter) || alwaysTransition) {
      this.setState({ startClose: true });
    } else {
      this.close();
    }
  };

  public handleCancel = (): void => {
    this.handleClose(true);
  };

  public handleEnter = (): void => {
    const { selectionChange, focusSelectorOnEnter } = this.props;
    selectionChange(this.state.wheels);

    if (focusSelectorOnEnter) {
      (document.querySelector(focusSelectorOnEnter) as HTMLInputElement)?.focus();
      this.handleClose();
    } else {
      this.handleClose(true);
    }
  };

  public close = (): void => {
    const { onClosed } = this.props;
    onClosed?.();
  };

  public update = (columnIndex: number, items: ISelectionWheelItem[]): void => {
    const {continuousUpdate, selectionChange, columnRuleCheck} = this.props;

    let {wheels} = this.state;
    wheels.find(wheel => wheel.id === columnIndex).items = items;

    wheels = columnRuleCheck?.(wheels) ?? wheels;

    this.setState({wheels});

    if (continuousUpdate) {
      selectionChange(wheels);
    }
  };
}
