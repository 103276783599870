import * as React from 'react';

const styles = require('./styles/grid.styl');

export interface IGridFormSectionTitleProps {
  title: string;
  titleClass?: string;
  className?: string;
  children?: object;
}

export default class GridFormSectionTitle extends React.Component<IGridFormSectionTitleProps, any> {
 
  public static defaultProps = {
    title: '',
    titleClass: styles.Title,
    className: styles.FormGrid,    
    children: [],
  };

  public render(): JSX.Element {
    const { title, className, titleClass, children } = this.props;
 
    return (
      <div className={className}>
        <div key="title" className={titleClass}><h3 className={styles.FormSectionTitle}>{title}</h3></div>
        {children}
      </div>
    );
       
  }
}
