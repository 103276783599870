import * as React from 'react';
import Input from './Input';
import Text, { ITextProps } from './Text';

export default class TextInput extends React.Component<ITextProps, any> {
  private input: Text;

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Text {...this.props} {...props} ref={(input) => this.input = input} getValue={this.getValue} />
          );
        }}
      </Input>
    );
  }

  public getValue(): string {
    return this.input.getValue();
  }
}
