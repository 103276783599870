import * as React from 'react';

const styles = require('./styles/circle_x_button.styl');

export interface ICircleXProps {
  onClick: () => any;
  positionClass?: string;
  rotateOnClick?: boolean;
  collapsed?: boolean;
  tabIndex?: number;
}

export default class CircleX extends React.Component<ICircleXProps, any> {
  public static defaultProps: ICircleXProps = {
    onClick: () => null, // method to handle clicks
    positionClass: null, // and additionaly class to add for positioning the element
    rotateOnClick: false, // whether or not to rotate into a + symbol when clicked
    collapsed: false,
  };

  public render(): JSX.Element {
    const { positionClass, collapsed, rotateOnClick, tabIndex } = this.props;

    let className = styles.CircleX;
    if (positionClass != null) { className += ` ${positionClass}`; }
    if (collapsed && rotateOnClick) { className += ` ${styles.IsCollapsed}`; }

    return (
      <svg
        tabIndex={tabIndex}
        className={className}
        onClick={this.handleClick}
        cursor="pointer"
        width="14"
        height="14"
        viewBox="0 0 14 14"
      >
        <path
          fill="#D2D2D2"
          d="M7 0C3.1 0 0 3.1 0 7s3.1 7 7 7 7-3.1 7-7-3.1-7-7-7zm3.8 9.6c.3.3.3.9 0 1.2-.2.2-.4.2-.6.2-.2 0-.4-.1-.6-.2L7 8.2l-2.6 2.6c-.1.1-.3.2-.6.2-.2 0-.4-.1-.6-.2-.3-.3-.3-.9 0-1.2L5.8 7 3.2 4.4c-.3-.3-.3-.9 0-1.2.3-.3.9-.3 1.2 0L7 5.8l2.6-2.6c.3-.3.9-.3 1.2 0 .3.3.3.9 0 1.2L8.2 7l2.6 2.6z"
        />
      </svg>
    );
  }

  private handleClick = (e) => {
    e.stopPropagation();
    this.props.onClick();
  };
}
