import { observable, action, when, computed } from 'mobx';
import * as moment from 'moment';
import * as queryString from 'query-string';
import { IBridgeAppData, IBridgeDeviceData, IBridgeServerInfo, IBridgeUIVersionUpdate, IConnectData, IUrlAction, ISetPasswordData, IQAccessDto } from '../global/interfaces';
import { BridgeCommands, DeviceStatuses, OSVersions, MobileBridgeVersions, UIActions, SensorStatusKeys } from '../global/enums';
import * as compareVersions from 'semver/functions/compare';
import { MOBILE_APPS, MIN_SUPPORTED_APP_VERSION } from '../global/constants';
import { LoggedInUserStore } from './LoggedInUser';
import { history } from '../helpers/history';

const prettyJSONStringify = require('pretty-json-stringify');


class MobileBridge { // tslint:disable:variable-name
  @observable public showBTWarning: boolean = false;
  @observable public appData: IBridgeAppData;
  @observable public ldxConnectData: IConnectData = {
    accessCode: null,
    useAsHub: false,
    locale: null,
    clearedMiddlewareOpWarning: null,
    cameraPermissionsAccepted: false,
  };
  @observable public devices: Map<string, IBridgeDeviceData> = new Map();
  @observable public serverInfo: IBridgeServerInfo;
  @observable public uiVersionUpdate: IBridgeUIVersionUpdate;
  @observable public uiUpdateAlertOpen: boolean = false;
  @observable public uiUpdateForce: boolean = false;
  @observable public deviceConnectionStatus: Map<string, DeviceStatuses> = new Map();
  @observable public debugLog: string[] = [];
  @observable public nativeAppIsCompatible: boolean = true;
  @observable public amiraSetPasswordData: ISetPasswordData;
  @observable public wiredMacAddresses: Map<string, string> = new Map();
  @observable public wirelessMacAddresses: Map<string, string> = new Map();
  @observable public offloadComplete: boolean;
  @observable public debugMobile: unknown = process.env.DEBUG_MOBILE;

  @observable public uiURL: string;
  private lastOrgCodeRevalidation: number = new Date().getTime();
  private bridgeVersion: MobileBridgeVersions;
  public os: OSVersions;

  @computed public get deviceNames(): Map<string, IBridgeDeviceData> {
    const dn: Map<string, IBridgeDeviceData> = new Map();
    this.devices.forEach(d => {
      if (d != null) {
        dn.set(d.DeviceName, d);
      }
    });
    return dn;
  }

  @computed public get bluetoothReady(): boolean {
    return (this.appData?.BluetoothEnabled && this.appData?.BLEAllowed) ?? false;
  }

  /**
   * Set the bridge version (from the query param on the mobile app page load)
   */
  @action public setOsInfo = (os: OSVersions, bridgeVersion: MobileBridgeVersions = MobileBridgeVersions.BRIDGE_V1, validateCode?: (string) => Promise<any>): Promise<any> => {

    // Engage container doesn't pass this through properly, so detect the old way
    if (!os || process.env.MOBILE_APP === MOBILE_APPS.ENGAGE) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.search('iphone') > -1 || userAgent.search('ipod') > -1 || userAgent.search('ipad') > -1){
        os = OSVersions.IOS1;
      }
      else if (userAgent.search('android') > -1){
        os = OSVersions.ANDROID;
      }
    }

    this.os = +os;
    this.bridgeVersion = bridgeVersion;

    if (this.debugMobile) {
      this.log(`BV & OS Set, Actual Values: bv: ${this.bridgeVersion}, os: ${this.os}`);
    }

    // Wait for the mobile app to update the app data, and then validate the code
    when(
      () => this.appData !== undefined,
      () => {
        const { AccessCode, accessCode, useAsHub } = this.ldxConnectData;

        if (this.debugMobile) {
          this.log(`Mobile app updated appData, validating code: ${accessCode || AccessCode}.`);
        }

        validateCode(accessCode || AccessCode)
          .then((accessDto) => this.mobileAppStartUp(accessDto, useAsHub))
          .catch(() => {
            // Likely no stored access code and user was redirected to access code form
          });
      }
    );

    this.tellMobileApp(BridgeCommands.GET_APP_INFORMATION);

    return Promise.resolve();
  };

  /**
   * Makes the calls to the native app necessary at startup or after a new access code is validated
   * Called in either the setOSInfo method (cold start w/ a saved code) or in the AccessCode component when user submits a code
   * @param accessDto response from the server for a validated access code
   *
   */
  public mobileAppStartUp = (accessDto: IQAccessDto, useAsHub: boolean = false): IQAccessDto => {
    const { HofUrl, ExternalGuid, QuantumSyncUrl, UiApiUrl, Version, AccessCode } = accessDto;

    if (this.debugMobile) {
      this.log(`Mobile app Start Up Called for: ${AccessCode}.`);
    }

    this.tellMobileApp(BridgeCommands.HOF_URL, JSON.stringify({
      ExternalHof: {
        URL: HofUrl,
        Guid: ExternalGuid,
      },
    }));

    this.tellMobileApp(BridgeCommands.SET_SERVER_URL, QuantumSyncUrl);
    this.tellMobileApp(BridgeCommands.SET_GUID, ExternalGuid);
    this.tellMobileApp(BridgeCommands.SET_UI_CONTENT_URL, this.getUiContentUrl(UiApiUrl, Version));

    if (useAsHub) {
      if (this.debugMobile) {
        this.log(`Use as Hub setting is on, triggering mobile app call`);
      }
      this.toggleUseAsHub(true, false);
    }

    return accessDto;
  };

  private checkVersion = (): void => {
    const { AppVersion } = this.appData;

    this.nativeAppIsCompatible = compareVersions(AppVersion, MIN_SUPPORTED_APP_VERSION) >= 0;
  };

  /**
   * This function is used to set application information in the UI. An object is passed that contains the properties and values that should be set. One or more properties can be set per call.
   */
  @action public UI_update_app_information = (data: Partial<IBridgeAppData>): void => {
    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log('MobileApp Called: UI_update_app_information, with:', data);
    }
    const { appData = {} } = this;

    if (data.UiString) {
      const { UiString } = data;
      try {
        this.ldxConnectData = JSON.parse(UiString);
      } catch (err) {
        this.log('UI String was un-parsable!');
      }
    }

    // Attempt to revalidate the org access code if the app says it can't reach the server
    // This could mean that the org has been upgraded and the url changed
    // Only do this if the there is an access code
    const { AccessCode, accessCode } = this.ldxConnectData;

    if (data.ServerOk === false && accessCode) {
      const now = new Date().getTime();
      if ((now - this.lastOrgCodeRevalidation) / 1000 > 35) {
        this.lastOrgCodeRevalidation = now;

        if (this.debugMobile) {
          this.log(`Server not okay, re-validating code: ${accessCode || AccessCode}.`);
        }
        LoggedInUserStore.validateAccessCode(accessCode || AccessCode)
          .then(() => {
            this.setConnectData({});
          });
      }
      else {
        if (this.debugMobile) {
          this.log('Server not okay, but throttling call.');
        }
      }
    }

    // User has turned off bluetooth while act as hub is on
    if (this.ldxConnectData.useAsHub && !data.BluetoothEnabled) {
      this.toggleUseAsHub(false);
      this.toggleBTWarning(true);
    }

    this.appData = {
      ...appData,
      ...data,
    };

    if (data.AppVersion) {
      this.checkVersion();
    }

  };

  /**
   * Updates the list of all registered devices referenced by LumiraDx ID
   */
  @action public UI_update_SN_list = (list: string = ''): void => {
    const snList = list.split(',').filter(sn => sn !== 'endlist');

    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log(`MobileApp Called: UI_update_SN_list, with:`, snList.length > 0 ? snList.join(', ') : '(empty list)');
    }

    if (snList.length > 0) {
      snList.forEach((sn) => {
        if (sn) {
          this.devices.set(sn, null);
        }
      });
    }
  };

  /**
   * This function is used to set device information in the UI.  A java object is passed that contains the properties and values that should be set.  One or more properties can be set per call.
   * Note: This function is called on device start with a list of all devices in order for the UI to display the proper device list.
   */
  @action public UI_update_device_information = (deviceData: IBridgeDeviceData = { SerialNumber: null }): void => {
    const { SerialNumber, SensorStatusEnum } = deviceData;

    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      const time = moment().format('h:mm:ss:SSS');
      this.log(`${time}: MobileApp Called: UI_update_device_information for ${SerialNumber}, with:`, deviceData || 'null data');
    }

    // If we are already offloading and the status is set to OKAY, we are done
    if (SensorStatusEnum === SensorStatusKeys.OKAY && this.offloadComplete === false) {
      this.offloadComplete = true;
    }
    // If the status is offload, the offload is in progress
    else if (SensorStatusEnum === SensorStatusKeys.OFFLOAD) {
      this.offloadComplete = false;
    }
    // Reset the offload property
    else {
      this.offloadComplete = undefined;
    }

    if (SerialNumber) {
      // Sometimes the firmware version comes up as null. If we got a valid version before, keep it around and don't overwrite it
      const existingFirmwareVersion = this.devices.get(SerialNumber)?.FirmwareVersion;
      deviceData.FirmwareVersion = deviceData.FirmwareVersion ?? existingFirmwareVersion;
      this.devices.set(SerialNumber, deviceData);
      this.devices = new Map(this.devices);
    }
  };

  /**
   * This function is used to notify the UI of the current app server settings.
   */
  @action public UI_update_server_information = (serverInfo: IBridgeServerInfo): void => {
    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log('MobileApp Called: UI_update_server_information, with:', serverInfo);
    }
    this.serverInfo = serverInfo;
  };

  /**
   * This function is used to notify the UI that a new UIContents.zip has been downloaded and is ready to load.
   */
  @action public UI_update_new_ui_available = (uiVersionUpdate: IBridgeUIVersionUpdate = {}): void => {
    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log('MobileApp Called: UI_update_new_ui_available, with:', uiVersionUpdate);
    }

    if (uiVersionUpdate.UIVersion != null && uiVersionUpdate.UIVersion !== process.env.UI_VERSION) {
      this.uiVersionUpdate = uiVersionUpdate;
      this.openUpdateAlert(true);
    }
    else if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log(`New Version (${uiVersionUpdate.UIVersion}) is the same as the current version (${process.env.UI_VERSION}). No action taken.`);
    }
  };

  @action public UI_update_ui_status = ({ status, percentage} = { status: '', percentage: 0 }): void => {
    this.log(status, percentage);
  };

  @action public UI_update_mac_addresses = (serialNumber: string, wired: string, wifi: string): void =>
  {
    this.wiredMacAddresses.set(serialNumber, wired);
    this.wirelessMacAddresses.set(serialNumber, wifi);
  };

  /**
   * The app will send this status message any time that a device’s connection status changes
   */
  @action public UI_update_device_connection_status = (wirelessModuleId: string, status: DeviceStatuses): void => {
    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      const time = moment().format('h:mm:ss:SSS');
      this.log(`${time}: MobileApp Called: UI_update_device_connection_status, with:`, { wirelessModuleId, status });
    }

    this.setDeviceConnectionStatus(wirelessModuleId, status);
  };

  /**
   *
   * @param data The app will call this in response to a lumiradx-connect:// or lumiradx-amira:// link click
   * Note: data comes a n object, but with a single attribute, which is a url query string
   */
  @action public UI_url_action = (data: IUrlAction): void => {
    const { uiaction, code, orgCode, email, expiryDate, patientprofileid, resultid } = queryString.parse(data.uiaction);

    if (uiaction === UIActions.CONFIRM || uiaction === UIActions.RESET) {
      this.amiraSetPassword({
        code: code as string,
        orgCode: orgCode as string,
        email: email as string,
        action: uiaction as string,
        expiryDate: expiryDate as string,
      });
    }
    else if (uiaction === UIActions.NOTIFICATION)
    {
      setTimeout(() => {
        history.push(LoggedInUserStore.userToken && this.ldxConnectData.token ?
          `/amira/app/test-results/${patientprofileid}/list/${resultid}` :
          `/amira/login/auth/redirect/${patientprofileid}/${resultid}`);
      }, 500);
    }
    else if (uiaction === UIActions.LOGIN)
    {
      setTimeout(() => {
        history.push('/amira/login/auth');
      }, 500);
    }
    else if (uiaction === UIActions.RESETPASSWORD)
    {
      setTimeout(() => {
        history.push('/amira/login/forgot-password');
      }, 500);
    }
  };

  /**
   * Generic method to make calls over the mobile bridge
   */
  public tellMobileApp = (command: BridgeCommands, data?: any): void => {
    if (data === undefined || data === null) { data = ''; }

    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log(`UI SENT: ${command}`, data);
    }

    if (!process.env.MOBILE_APP) { return; }

    const { IOS1, IOS2 } = OSVersions;
    const { BRIDGE_V1 } = MobileBridgeVersions;
    try {
      if ([IOS1, IOS2].includes(this.os)) {
        if (this.bridgeVersion === BRIDGE_V1) {
          (window as any).Mt.App.fireEvent('iDevice_JS2CP', {
            msg: command,
            extra: data,
            answer: 42,
          });
        }
        else {
          (window as any).webkit.messageHandlers.jsHandler.postMessage({field: command, data});
        }
      }
      else {
        (window as any).JS2CS.aDevice_JS2CP(command, data);
      }
    }
    catch (err) {
      this.log(err);
    }
  };

  /**
   * Log something to the mobile debug log
   */
  @action public log = (msg: string, data?: any): void => {
    if (this.debugMobile && (msg || data)) {
      this.debugLog.push('-------------------------------------');
      this.debugLog.push(msg);

      if (data && typeof data === 'object') {
        data = prettyJSONStringify(data, { shouldExpand: () => true });
      }
      else if (data !== null && data !== undefined && typeof data !== 'string') {
        data = data.toString();
      }

      if (data !== null && data !== undefined) { this.debugLog.push(data); }
    }
    else if (console !== undefined) {
      console.log(msg);
    }
  };

  /**
   * Clear the mobile debug log
   */
  @action public resetLog = (): void => {
    this.debugLog = [];
  };

  /**
   * Called by UI_url_action when a new amira user clicks the invite link
   */
  private amiraSetPassword = (data: ISetPasswordData): void => {

    // Wait for the mobile app to update the app data, and then validate the code
    when(
      () => this.appData !== undefined,
      () => {
        // This is an attempt to handle an edge case:
        // User clicks the invite link on a different device than the one they initially registered on.
        // Therefore, there will be no save ord code, and so we will pull it from the SetPassword data
        const { orgCode, expiryDate } = data;
        let { action } = data;
        let path = '';

        if (moment.utc().isAfter(moment(expiryDate))){
          action = UIActions.EXPIRED;
        }

        switch (action) {
          case UIActions.CONFIRM:
            path = '/amira/welcome/set-password';
            break;
          case UIActions.RESET:
            path = '/amira/login/reset-password';
            break;
          case UIActions.EXPIRED:
            path = '/amira/login/expired-link';
            break;
          default:
            path = '/amira/welcome/set-password';
            break;
        }

        if (this.ldxConnectData != null && (!this.ldxConnectData?.AccessCode && !this.ldxConnectData?.accessCode)) {
          LoggedInUserStore.validateAccessCode(orgCode)
            .then(() => {
              this.amiraSetPasswordData = data;
              history.push(path);
            });
        }
        else {
          this.amiraSetPasswordData = data;
          // Wait here to avoid a race condition with routing decisions that may occur when the app first starts up
          setTimeout(() => {
            history.push(path);
          }, 1000);

        }
      }
    );
  };

  @action public clearSetPasswordData = (): void => {
    this.amiraSetPasswordData = null;
  };

  /**
   * Open/closes the UI Update alert modal
   */
  @action public openUpdateAlert = (status: boolean): void => {
    this.uiUpdateAlertOpen = status;
  };

  @action public setDeviceConnectionStatus = (serialNumber: string, status: DeviceStatuses): void => {
    this.deviceConnectionStatus.set(serialNumber, status);
    this.deviceConnectionStatus = new Map(this.deviceConnectionStatus);
  };

  /**
   * Forces the UI Update alert modal
   */
  @action public setForceUIUpdate = (force: boolean): void => {
    this.uiUpdateForce = force;
  };

  @action public setConnectData = (data: Partial<IConnectData>): void => {
    if (this.debugMobile || process.env.MOCK_CONNECT_APP) {
      this.log(`setConnectData: `, data);
    }

    this.ldxConnectData = {
      ...this.ldxConnectData,
      ...data,
    };

    this.tellMobileApp(BridgeCommands.SET_UI_STRING, JSON.stringify(this.ldxConnectData));

  };

  @action public toggleUseAsHub = (status: boolean, persistSetting: boolean = true): void => {
    this.tellMobileApp(BridgeCommands.ACT_AS_HUB, status);
    if (persistSetting) {
      this.setConnectData({ useAsHub: status });
    }
  };

  @action public toggleBTWarning = (status: boolean = false): void => {
    this.showBTWarning = status;
  };

  @action public setBt = (value: boolean) => {
    this.appData.BluetoothEnabled = value;
  };

  @action public getUiContentUrl = (UiApiUrl: string, Version: string): string => {
    // find the first / after //
    const urlIndex = UiApiUrl.indexOf('/', UiApiUrl.indexOf('://') + 3);

    // create the new ui url
    const newUiApiUrl = `${UiApiUrl.slice(0, urlIndex)}/ui-modules/${process.env.MOBILE_APP === MOBILE_APPS.CONNECT ? 'mobile-connect' : 'mobile-amira'}/${Version}`;

    this.uiURL = newUiApiUrl;

    return newUiApiUrl;
  };

  @action public setMobileDebug = (value: boolean) => {
    this.debugMobile = value;
  };

}

const store = new MobileBridge();

(window as any).UI_update_app_information = store.UI_update_app_information;
(window as any).UI_update_SN_list = store.UI_update_SN_list;
(window as any).UI_update_device_information = store.UI_update_device_information;
(window as any).UI_update_server_information = store.UI_update_server_information;
(window as any).UI_update_new_ui_available = store.UI_update_new_ui_available;
(window as any).UI_update_device_connection_status = store.UI_update_device_connection_status;
(window as any).UI_update_ui_status = store.UI_update_ui_status;
(window as any).UI_url_action = store.UI_url_action;
(window as any).UI_update_mac_addresses = store.UI_update_mac_addresses;

(window as any).bt = store.setBt;

export { MobileBridge, store as MobileBridgeStore };
