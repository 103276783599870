import * as React from 'react';
import Spinner from './Spinner';
import { IValidationError, IBaseInputProps, IValidateOptions } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';

const styles = require('./styles/input.styl');

export interface ISearchProps extends IBaseInputProps {
  onChange?: (value: string, jsonPath?: string) => void;
  onKeyDown?: (e) => void;
  clear?: () => void;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
  height?: string | number;
  width?: string | number;
  changeDelay?: number;
}

export interface ISearchState {
  showErrors: boolean;
}

export default class Search extends React.Component<ISearchProps, ISearchState> {
  public static defaultProps = {
    wrapperClass: null,
    height: 35,
    width: 260,
    changeDelay: 0,
    placeholder: 'search',
    focusOnMount: false,
    value: '',
    loading: false,
    className: null,
    tabIndex: -1,
  };

  public state = {
    showErrors: false,
  };

  private searchInput: React.RefObject<HTMLInputElement>;
  private errorAnchor: HTMLElement;

  constructor(props) {
    super(props);
    this.searchInput = React.createRef();
  }

  public componentDidMount(): void {
    const { focusOnMount, selectOnMount } = this.props;

    if (focusOnMount) { this.searchInput.current.focus(); }
    if (selectOnMount) { this.searchInput.current.select(); }
  }

  public render(): JSX.Element {
    const { value, height, width, wrapperClass, placeholder, loading, clear, className, disabled, showClear, tabIndex, validate, validation, forceShowAllErrors, valueHasChanged, flashErrors } = this.props;
    const { showErrors } = this.state;
    let { autoComplete } = this.props;
    const val = value as string;
    let outerClass = styles.FieldWrap;

    if (wrapperClass != null) { outerClass += ` ${wrapperClass}`; }

    const error = validate({ value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;

    if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.invalid}`; }

    const errorButtonClass = styles.FieldErrorsShow;

    // Autocomplete
    autoComplete = (() => {
      switch (typeof autoComplete) {
        case 'string': return autoComplete;
        case 'boolean':
          if (autoComplete) { return 'on'; } else { return 'off'; }
      }
    })();

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;
    const loadingIndicator = loading ? <Spinner /> : null;
    const clearBtn = val != null && val.length && showClear ? (
      <button
        className={styles.SearchClear}
        title="Clear Search"
        key="searchClearBtn"
        onClick={clear}
        tabIndex={-1}
      />
    ) : null;

    return (
      <div className={outerClass} style={{ height: height, width: width }}>
        <input
          ref={this.searchInput}
          className={className}
          type="text"
          placeholder={placeholder}
          value={val}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          autoComplete={autoComplete}
          disabled={disabled}
          tabIndex={tabIndex}
        />
        {loadingIndicator}
        {clearBtn}
        <div
          className={errorButtonClass}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
        />
        {errorPvr}
      </div>
    );
  }

  public handleChange = (e): void => {
    const newTerm = e.target.value;
    const { value, onChange, jsonPath } = this.props;

    // IE fires change events when the field is focused
    // this just ensures that state onlt changes when the data actually change
    if (newTerm === value) { return; }

    if (typeof onChange === 'function') {
      onChange(newTerm, jsonPath);
    }
  };

  public handleKeyDown = (e): void => {
    const { onKeyDown } = this.props;
    if (e.key === 'Enter') { e.preventDefault(); }
    if (typeof onKeyDown === 'function') {
      onKeyDown(e);
    }
  };

  public focus() {
    this.searchInput.current.focus();
  }

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  public getValue(): string {
    const { returnNull } = this.props;
    return returnNull && this.searchInput.current.value === '' ? null : this.searchInput.current.value;
  }
}
