import * as React from 'react';
import { IBaseInputProps, IValidateOptions, IValidationError } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';

const styles = require('./styles/radio_input.styl');
const inputStyles = require('./styles/input.styl');

export interface IRadioProps extends IBaseInputProps {
  name: string;
  jsonPath: string;
  className?: string;
  wrapperLabel?: string;
  disabled?: boolean;
  tabIndex?: number;
  title?: string;
  id?: string;
  checked?: boolean;
  onChange: (value: string, jsonPath?: string, checked?: boolean) => void;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
}

export default class Radio extends React.Component<IRadioProps, any> {
  public static defaultProps = {
    wrapperClass: '',
    validation: false,
    forceShowAllErrors: false,
  };

  public state = {
    showErrors: false,
  };

  private errorAnchor: HTMLElement;
  public input: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  public componentDidMount(): void {
    const { focusOnMount } = this.props;

    if (focusOnMount) { this.input.current.focus(); }
  }

  public render(): JSX.Element {
    const { className, wrapperClass, wrapperLabel, validation, checked, disabled, id, name,
      tabIndex, title, validate, forceShowAllErrors, valueHasChanged, value, flashErrors,
      jsonPath } = this.props;
    const { showErrors } = this.state;
    const error = validate({ value: checked, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;
    const inputEl = (
      <input
        type="radio"
        key="input"
        ref={this.input}
        id={id}
        name={name}
        data-test={jsonPath}
        title={title}
        checked={checked}
        disabled={disabled}
        className={className}
        tabIndex={tabIndex}
        onChange={this.handleChange}
        value={value as string}
        onTouchStart={this.handleErrorTouch}
      />
    );

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    const errEl = (
      <div
        className={styles.FieldErrorsShow}
        key="checkboxInputErrorsShow"
        ref={(el: HTMLElement) => this.errorAnchor = el}
        onMouseOver={this.handleErrorMouseOver}
        onMouseOut={this.handleErrorMouseOut}
        onTouchStart={this.handleErrorTouch}
      />
    );

    let outerClass = inputStyles.FieldWrap;
    let disabledLabel = styles.RadioInputLabel;
    let contentItems = null;

    if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ' invalid'; }

    if (disabled) { disabledLabel += ' disabled'; }

    if (wrapperLabel != null) {
      contentItems = (
        <label className={disabledLabel} htmlFor={id} title={title}>
          {inputEl}
          <span key="label">{wrapperLabel}</span>
        </label>
      );
    } else {
      contentItems = inputEl;
    }

    return (
      <div className={`${outerClass} ${wrapperClass}`}>
        {contentItems}
        {errEl}
        {errorPvr}
      </div>
    );
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, jsonPath } = this.props;
    if (typeof onChange === 'function') {
      onChange(e.target.value, jsonPath);
    }
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

}
