import * as React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as queryString from 'query-string';
import { observer, inject } from 'mobx-react';
import { when } from 'mobx';

import { TextInput, Spinner, Modal, SelectPvr, ServerRequest } from '@Lineup/index';
const { Req } = ServerRequest;
import { IComponent, InjectedComponent, ILocaleOption } from '../../global/interfaces';
import { SpinnerNames } from '@Lineup/enums/index';
import AccountResetPassword from './AccountResetPassword';
import AccountExpiredPasswordReset from './AccountExpiredPasswordReset';
import BuildInfo from './BuildInfo';
import VersionPvr from './VersionPvr';
import * as variables from '../../global/variables';
import AccessCode from './AccessCode';
import { getItem, removeItem } from '../../helpers/LocalStorage';
import DevServiceSelect from './DevServiceSelect';
import { BridgeCommands } from '../../global/enums';
import { FACTORY_CODE, MOBILE_APPS, APP_NAME } from '../../global/constants';
import { languages } from '../../global/languages';

const { backgroundBlue } = variables;

const styles = require('./styles/Login.styl');
const appStyles = require('../App/styles/App.styl');

@inject('loggedInUserStore', 'systemStore', 'applicationStore', 'mobileBridgeStore') @observer
export default class Login extends InjectedComponent<IComponent, any> {

  private langBtn: React.RefObject<HTMLButtonElement>;
  private footer: React.RefObject<HTMLDivElement>;
  private loginFields: HTMLDivElement;
  private userNavvedToFactory: boolean = window.location.pathname?.toLowerCase().includes('factory');
  private userNavvedToDataview: boolean = window.location.pathname?.toLowerCase().includes('dataview');

  public state = {
    loading: true,
    redirectToReferrer: false,
    selectingLocale: false,
    shake: false,
    fade: true,
    showVersions: false,
    manualAccessCodeNav: false,
  };

  constructor(props) {
    super(props);
    this.langBtn = React.createRef();
    this.footer = React.createRef();
  }

  public componentDidMount() {
    const { accessDto, autoFillConnectCreds, setLoginMessage, parseLocatorCookie } = this.props.loggedInUserStore;
    const { location } = this.props;
    this.props.applicationStore.getAvailableModules()
      .catch((e) => {
        setLoginMessage(t('No application modules exist in this environment. Please report this issue to an administrator.'));
        return Promise.reject();
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      });

    if (!process.env.CS && !process.env.MOBILE_APP && accessDto === null) {
      // If no code was passed, and there is not one in local storage, check for one in the url
      const { orgCode } = queryString.parse(location.search);
      const { codeFromCookie } = parseLocatorCookie();

      // If the user already has a stored access code, then automatically validate it and redirect
      this.validateAccessCode((orgCode || codeFromCookie) as string);
    }

    if (process.env.NODE_ENV === 'development') {
      const { getTargets } = this.props.loggedInUserStore;

      getTargets()
      .then((activeTarget) => {
        const { setCredentials } = this.props.loggedInUserStore;
        const { activeAccount } = activeTarget;

        if (activeAccount && activeAccount.length && process.env.CS) {
          setCredentials({
            username: activeAccount[0],
            password: activeAccount[1],
          });
        }

      });
    }

    // In mobile debug mode only, autofill creds when the value has changed
    if ((process.env.DEBUG_MOBILE || process.env.NODE_ENV === 'development') && !process.env.CS) {
      when(() => this.props.loggedInUserStore.accessDto != null, autoFillConnectCreds);
    }

    if (process.env.MOBILE_APP) {
      const { configOptionIsSet } = Req;

      // Mobile Connect App Startup Sequence
      // Will result in the mobile app setting the appData
      // Only needs to be done once, so skip if the Req class already has a url prefix configured is already defined
      if (!configOptionIsSet('prefix')) {
        const { bv, os } = queryString.parse(window.location.search);
        const { tellMobileApp, setOsInfo, log, debugMobile } = this.props.mobileBridgeStore;

        if (debugMobile) {
          log(`Setting BV Info, query param values: bv: ${bv}`);
        }

        setOsInfo(os as any, bv ? +bv : undefined, this.validateAccessCode)
          .then(() => tellMobileApp(BridgeCommands.API_READY));
      }

    }

  }

  public render(): JSX.Element {
    const { search } = this.props.location;
    const { username = '', password = '', loginMessage, initialFromRoute, passwordChangeRequired, forcePasswordChange, accessDto } = this.props.loggedInUserStore;
    const { device } = this.props.systemStore;
    const { locale } = this.props.applicationStore;
    const { loading, redirectToReferrer, selectingLocale, shake, fade, showVersions, manualAccessCodeNav } = this.state;
    const disabled = username === '' || password === '' || accessDto == null;

    // Temporary Spanish (US) removal for LUMI-2313
    const lang = languages;
    lang.delete('es-US');

    const accessCode = this.findStoredAccessCode();

    // This will redirect straight to the parent module for
    // login once modules have been loaded
    if (process.env.MODULE_LOGIN && this.state.loading) {
      return null;
    }
    else if (process.env.MODULE_LOGIN) {
      this.props.loggedInUserStore.login();
      return <Redirect to="/main" />;
    }

    const btn = loading ? (
      <Spinner
        wrapperClass={styles.LoginSpinner}
        name={SpinnerNames.BallScaleRipple}
        color={backgroundBlue}
      />
    ) : (
      <button
        id="login-button"
        data-test="login-button"
        className={styles.LoginArrow}
        onClick={this.userNavvedToDataview ? this.loginDataview : this.login}
        disabled={disabled}
      >{t('Login')}</button>
    );

    const langPvr = selectingLocale ? (
      <SelectPvr
        options={Array.from(lang.entries()).map(([code, config]) => {
          return {
            value: code,
            label: t(config.name),
          };
        }).sort((a, b) => a.label > b.label ? 1 : -1)}
        pvrProps={{ anchor: this.langBtn.current, nibColor: 'white', close: this.closeLangSelect }}
        onChange={this.handleLangChange}
        close={this.closeLangSelect}
        defaultSelected={{ value: locale }}
        maxHeight={device === 'mobile' ? 120 : 300}
      />
    ) : null;

    const loginFields = (
      <div
        className={`${styles.LoginFields} ${fade ? styles.Fade : ''} ${shake ? styles.Shake : ''}`}
        ref={(loginFields) => {
          this.loginFields = loginFields;
        }}
      >
        <form className={styles.LoginForm}>
          <TextInput
            id="username"
            type={accessCode === FACTORY_CODE ? 'email' : 'text'}
            placeholder={accessCode === FACTORY_CODE ? t('Email') : t('Username')}
            jsonPath="username"
            value={username}
            wrapperClass={styles.TextInput}
            focusOnMount={true}
            onChange={this.handleCredChange}
            autoCapitalize="off"
            autoComplete="username"
            name="username"
            onBlur={this.handleBlur}
          />
          <TextInput
            id="password"
            placeholder={t('Password')}
            jsonPath="password"
            value={password}
            type="password"
            wrapperClass={styles.TextInput}
            onChange={this.handleCredChange}
            autoComplete="current-password"
            name="password"
            onBlur={this.handleBlur}
          />
          {btn}
        </form>
      </div>
    );

    const lgnMsg = loginMessage ? (
      <div className={styles.LoginMessage}>
        {loginMessage}
      </div>
    ) : null;

    const headerContent = !process.env.CS ? (accessCode === FACTORY_CODE ? 'LumiraDx Factory' : (this.userNavvedToDataview ? 'LumiraDx DataView' : 'LumiraDx Connect')) : `${APP_NAME}`;

    // Used to allow the user to manually return to login screen from force reset
    const { force } = queryString.parse(search);

    let logoClass = `${styles.Logo}`;

    // Use more identifiable factory logo in development mode
    if (process.env.NODE_ENV === 'development') {
      logoClass += accessCode === FACTORY_CODE ? ` ${styles.Factory}` : '';
    }

    return (
      <div className={styles.LoginContainer}>
        <header>
          <div>
            {headerContent}
            {process.env.NODE_ENV === 'development' && !process.env.MOBILE_APP ? <>{' | '}<DevServiceSelect /></> : null}
          </div>
        </header>
        {lgnMsg}
        <div className={styles.Login}>
          <div className={logoClass} />
          <Switch>
            <Route
              exact
              path={'/build'}
              render={(props) =>
                <Modal
                  {...props}
                  title="Build Information"
                  close={this.closeModal}
                >
                  <BuildInfo {...props} />
                </Modal>
              }
            />
            {(!process.env.CS) ?
              <Route
                exact
                path={'/login/account-reset-password'}
                render={(props) => <AccountResetPassword {...props} />}
              /> : null
            }
            {(!process.env.CS) ?
              <Route
                exact
                path={'/login/expired-password-reset'}
                render={(props) => <AccountExpiredPasswordReset {...props} navToApp={this.navToApp} />}
              /> : null
            }
            {(!process.env.CS) ?
              <Route
                exact
                path={'/login/access-code'}
                render={(props) => {
                  return <AccessCode
                    {...props}
                    validateAccessCode={this.validateAccessCode}
                    showAccessCode={this.showAccessCode}
                    existingCode={accessCode}
                    manualAccessCodeNav={manualAccessCodeNav}
                    handleLangClick={this.handleLangClick}
                    langRef={this.langBtn}
                    locale={t(languages.get(locale).name)}
                    showUILoader={this.showUILoader}
                  />;
                }}
              /> : null
            }
            <Route
              exact
              path={'/(login)?'}
              render={() => {
                // In the mobile app, it is possible to trigger a reload on the older version of the app for a route unknown to this app
                // So do not redirect to the loaded route in the connect mobile app context
                // Otherwise there is are a few other cases where a redirect causes issues, see shouldNotRedirectUser method
                if (redirectToReferrer && (process.env.MOBILE_APP === MOBILE_APPS.CONNECT || this.shouldNotRedirectUser(initialFromRoute, accessCode))) {
                  return <Redirect to="/main" />;
                }
                else if (redirectToReferrer) {
                  return <Redirect to={initialFromRoute} />;
                }
                else if ((passwordChangeRequired || forcePasswordChange) && force !== 'true') {
                  return <Redirect to="/login/expired-password-reset" />;
                }
                else {
                  return process.env.CS || accessDto === null || accessDto?.OrganizationStatus !== false ? (
                    <>
                      {loginFields}
                      <div className={styles.ButtonWrap}>
                        <button
                          id="language-selector"
                          data-test="language-selector"
                          className={`${appStyles.DropDownBtn} ${styles.LoginBtn} ${styles.LangBtn}`}
                          onClick={this.handleLangClick}
                          ref={this.langBtn}
                        >
                          {t(languages.get(locale).name)}
                        </button>
                        {(!process.env.CS) ?
                          <div>
                            {!this.userNavvedToDataview ? <button
                              id="forgot-password-button"
                              data-test="forgot-password-button"
                              className={styles.LoginBtn}
                              onClick={this.showForgotPassword}
                            >{t('Forgot your password?')}</button> : null}
                            {!this.userNavvedToFactory ? <button
                              id="change-access-code-button"
                              data-test="change-access-code-button"
                              className={styles.LoginBtn}
                              onClick={() => this.showAccessCode(true)}
                            >{t('Change Access Code')}</button> : null}
                          </div> : null
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <p data-test="offline">{t(`We're sorry, LumiraDx Connect is currently down for maintenance.`)}</p>
                      <p>{t('We are working to get you back up and running as quickly as possible.')}</p>
                      <p>
                        <button className={styles.RefreshBtn} title={t('Check again')} onClick={() => this.validateAccessCode(accessDto.AccessCode)} />
                      </p>
                    </>);
                }
              }}
            />
          </Switch>
          {langPvr}
        </div>
        {showVersions ? (
          <VersionPvr
            anchor={this.footer.current}
            close={() => this.showVersions(false)}
          />
        ) : null}
        <footer
          ref={this.footer}
          onTouchEnd={process.env.MOBILE_APP ? (e) => {
            e.preventDefault();
            this.showVersions(true);
          } : null}
        >
          {process.env.MOBILE_APP === MOBILE_APPS.CONNECT ?
            <><div>{t('Copyright © __year__ - LumiraDx', { year: new Date().getFullYear() })}</div><div>{process.env.UI_VERSION}</div></>:
            t('Copyright © __year__ - LumiraDx', { year: new Date().getFullYear() })}
        </footer>
      </div>
    );
  }

  public handleBlur = (): void => {
    // Combat an iOS issue where the keyboard from the previous screen has shifted the web view up, and it does not come back down
    // This results in a white box at the bottom of the screen.
    if (process.env.MOBILE_APP) {
      window.scrollTo(0, 0);
    }
  };

  private findStoredAccessCode = (): string => {
    if (this.userNavvedToFactory) {
      return FACTORY_CODE;
    }

    const { search } = this.props.location;
    const { orgCode } = queryString.parse(search);

    // Note: in the Mobile App access code was stored as an uppercase variable AccessCode
    // This is no longer the case, but continue to check for it as a fallback
    return getItem('accessCode') || getItem('AccessCode') || orgCode as string;
  };

  public handleCredChange = (value: string, jsonPath: string): void => {
    if (value.toLowerCase() === '_build' && jsonPath === 'username') {
      this.showBuildInfo();
      value = '';
    }

    if (process.env.MOBILE_APP && value.toLowerCase() === '_debug' && jsonPath === 'username') {
      this.props.mobileBridgeStore.setMobileDebug(true);
      value = '';
    }

    this.props.loggedInUserStore.setCredentials({
      [jsonPath]: value,
    });
  };

  public loginDataview = () => {
    const { getMemberOrgsPDE } = this.props.loggedInUserStore;

    this.setState({ loading: true });

    getMemberOrgsPDE()
      .catch(() => {
        this.shake();
        this.setState({
          loading: false,
        });
        return Promise.reject();
      })
      .then(() => {
        return this.setState({
          loading: false,
          redirectToReferrer: true,
        });
      });
  };

  public login = (e: React.MouseEvent): void => {
    e.preventDefault();

    const { history } = this.props;
    const { login, setLoginMessage, toggleOrgStatus } = this.props.loggedInUserStore;
    const { appSetup } = this.props.systemStore;

    let AccessCode;
    if (!process.env.CS) {
      AccessCode = this.props.loggedInUserStore.accessDto.AccessCode;
    }

    this.setState({
      loading: true,
    });

    login()
      .catch(({ status, msg }) => {
        this.setState({ loading: false });

        if (!navigator.onLine) {
          setLoginMessage(t('Internet connection is offline.'));
          this.setState({ loading: false });
          history.replace('/login');
          return Promise.reject();
        }

        if (+status >= 500 && !msg) {
          const userMsg = t('Server error, please try again later.');
          setLoginMessage(userMsg);
        }
        // 404 reponse indicates the org has been taken offline
        else if (+status === 400 && msg === 'Organization is offline.') {
          toggleOrgStatus(false);
        }
        else {
          const userMsg = msg?.startsWith('User is locked out') ? t('User account is temporarily locked. Please try again later.') : t('Unrecognized credentials. Please try again.');
          setLoginMessage(userMsg);
          this.shake();
        }

        history.replace('/login');
        return Promise.reject();
      })
      .then(() => {
        // In case the org was previously offline, mark it as back online
        toggleOrgStatus(true);

        return appSetup(AccessCode)
          .catch(() => {
            return this.setState({
              loading: false,
            });
          })
          .then(() => {
            const { passwordChangeRequired, forcePasswordChange } = this.props.loggedInUserStore;
            setLoginMessage(null);

            if (passwordChangeRequired || forcePasswordChange) {
              const { history } = this.props;
              history.replace('/login/expired-password-reset');
              return this.setState({
                loading: false,
              });
            }
            else {
              return this.setState({
                loading: false,
                redirectToReferrer: true,
              });
            }

          });
      });
  };

  private handleLangClick = (): void => {
    this.setState({ selectingLocale: true });
  };

  public handleLangChange = (newLocale: ILocaleOption) => {
    const { setLocale } = this.props.applicationStore;
    setLocale(newLocale.value);
  };

  public showVersions = (showVersions: boolean): void => {
    this.setState({ showVersions });
  };

  public closeLangSelect = (): void => {
    this.setState({ selectingLocale: false });
  };

  public showBuildInfo = (): void => {
    const { history } = this.props;
    history.replace('/build');
  };

  public showUILoader = (): void => {
    const { history } = this.props;
    history.replace('/load-ui-build');
  };

  private showForgotPassword = (): void => {
    const { setLoginMessage } = this.props.loggedInUserStore;
    const { history } = this.props;

    setLoginMessage(null);
    history.replace('/login/account-reset-password');
  };

  private showAccessCode = (manualAccessCodeNav = false): void => {
    const { search } = this.props.location;

    // Clear any access code cookie if the user chooses to manually navigate to the access code screen
    if (manualAccessCodeNav) {
      document.cookie = 'locator=deleted; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
    }

    this.setState({ manualAccessCodeNav }, () => {
      this.props.history.replace({
        pathname: '/login/access-code',
        search: search,
      });
    });

  };

  public validateAccessCode = (code: string = getItem('accessCode')): Promise<any> => {
    const { loggedInUserStore, history, location: { pathname, search }, mobileBridgeStore: { debugMobile } } = this.props;
    const { validateAccessCode, setLoginMessage, loginMessage, setAccessDto } = loggedInUserStore;

    if (debugMobile) {
      this.props.mobileBridgeStore.log(`Validating access code: ${code}`);
    }

    const isFactoryCode = code && code.toLowerCase && code.toLowerCase() === FACTORY_CODE;

    if (!process.env.MOBILE_APP && (isFactoryCode || this.userNavvedToFactory)) {
      document.title = 'Factory Manager';
      setAccessDto({
        AccessCode: FACTORY_CODE,
        UiApiUrl: '',
        EnvShortCode: '',
        ExternalGuid: '',
        QuantumSyncUrl: '',
        Version: '',
        OrganizationStatus: true,
      }, !this.userNavvedToFactory);
      if (pathname === '/login/access-code') {
        history.replace('/login');
      }
      setLoginMessage('');
      return Promise.resolve(FACTORY_CODE);
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.CONNECT && isFactoryCode) {
      setLoginMessage(t('Factory Manager is not supported in the Connect mobile application.'));
      return Promise.resolve(FACTORY_CODE);
    }
    else if (code) {
      // Attempt to validate the stored access code
      return validateAccessCode(code)
        .then((accessDto) => {
          document.title = this.userNavvedToDataview ? 'DataView' : 'Connect Manager';
          // if they user enters an access code remove address & sampleType from local storage
          if (!process.env.MOBILE_APP) {
            removeItem('address');
            removeItem('sampleType');
          }

          if (loginMessage === t('Invalid access code')) {
            setLoginMessage('');
          }
          // If the user came from the reset password form, bring them back there and preserve the query params
          if (pathname === '/login/access-code') {
            const { email } = queryString.parse(search);
            email ? history.replace({
              pathname: '/login/account-reset-password',
              search,
            }) : history.replace('/login');
          }

          return accessDto;
        })
        .catch(({ code, message }) => {
          removeItem('AccessCode');
          removeItem('accessCode');

          if (['/', '/login', '/login/account-reset-password', '/login/access-code'].includes(pathname)) {
            this.showAccessCode();

            if (!navigator.onLine) {
              setLoginMessage(t('Internet connection is offline.'));
            }
            else {
              // Code of 20 means the user aborted the call, so show no error message
              setLoginMessage(code === 20 ? '' : message);
            }

          }

        });
    }
    // Otherwise, forward them to the access code entry field
    else {
      if (debugMobile) {
        this.props.mobileBridgeStore.log('Code was undefined, redirecting to the access code form.');
      }

      this.showAccessCode();

      return Promise.reject();

    }
  };

  public navToApp = (): void => {
    const { history } = this.props;
    history.replace('/login');
    this.setState({ redirectToReferrer: true });
  };

  public closeModal = (): void => {
    const { history } = this.props;
    history.replace('/login');
  };

  public shake = (): void => {
    this.loginFields.addEventListener('animationend', this.removeCssAnimations, false);
    this.setState({ shake: true });
  };

  public removeCssAnimations = (): void => {
    this.loginFields.removeEventListener('animationend', this.removeCssAnimations, false);
    this.setState({
      shake: false,
      fade: false,
    });
  };

  private shouldNotRedirectUser(initialFromRoute: string, accessCode: string): boolean {
    // Normalize case and slice off the query params from the initialFromRoute
    const route = initialFromRoute.split('?')[0].toLowerCase();

    // List of routes that should never be redirected to...
    const isBanned = [
      '/login',
      '/login/',
      '/build',
      '/login/account-reset-password',
      '/login/access-code',
      '/account_orgaccesscode',
      '/login/expired-password-reset',
      '/',
      '',
    ].includes(route);

    if (isBanned) { return true; }

    // Don't redirect a factory user to connect route and vice versa
    if (accessCode === FACTORY_CODE) {
      return route.search('connect') !== -1;
    }
    else {
      return route.search('factory') !== -1;
    }

  }

}
