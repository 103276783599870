import * as React from 'react';
import { Modal, GridFormInput } from '@Lineup/index';
import { inject, observer } from 'mobx-react';
import { IComponent, InjectedComponent } from './../../global/interfaces';
import { SaveStates } from '../../../LDX-Lineup/enums/index';
import FormErrorTracker from './../../helpers/ErrorTracker';
import PasswordRules from './../Login/PasswordRules';

const appStyles = require('./styles/App.styl');
const styles = require('./styles/Profile.styl');
const modalStyles = require('@Lineup/styles/modal.styl');

interface IState {
  currentPassword?: string;
  newPassword?: string;
  newPasswordConfirm?: string;
  saveState?: SaveStates;
  saveMessage?: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
  rulesPvrOpen: boolean;
}

interface IProps extends IComponent {
  togglePassword: (open: boolean) => void;
}

@inject('loggedInUserStore') @observer
export default class ResetPassword extends InjectedComponent<IProps, IState> {
  private errorTracker: FormErrorTracker = new FormErrorTracker();

  constructor(props: IProps) {
    super(props);
    this.state = {
      forceShowAllErrors: false,
      flashErrorPvrs: 0,
      currentPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
      saveState: null,
      saveMessage: null,
      rulesPvrOpen: false,
    };
  }

  public componentWillUnmount() {
    const { resetPasswordRules } = this.props.loggedInUserStore;
    resetPasswordRules();
  }

  public render(): JSX.Element {
    const { currentPassword, newPassword, newPasswordConfirm, saveState, saveMessage, forceShowAllErrors, flashErrorPvrs, rulesPvrOpen} = this.state;
    const { loggedInUserStore } = this.props;
    const { isValidPassword, ruleResults } = loggedInUserStore;
    const { registerError } = this.errorTracker;

    return (
      <Modal
        close={this.close}
        title={t('Reset Password')}
        className={styles.Modal}
        onSaveComplete={this.close}
        onSaveFail={this.resetSaveState}
        saveState={saveState}
        saveMessage={saveMessage}
        closeAfterSave
      >
        <div className={modalStyles.ModalContent}>
          <div className={styles.Body}>
            <form>
              <GridFormInput
                data-test="currentPassword"
                formLabel={t('Current Password')}
                type="password"
                value={currentPassword}
                autoComplete="current-password"
                onChange={(value) => this.handlePasswordChange(value, 'currentPassword')}
                forceShowAllErrors={forceShowAllErrors}
                flashErrorPvrs={flashErrorPvrs}
                registerError={registerError}
                validation={(function() {
                  const messages = [];
                  if ((newPassword || newPasswordConfirm) && !currentPassword) {
                    messages.push(t('Current password required'));
                  }
                  return messages.length ? { messages } : null;
                })()}
              />
              <GridFormInput
                jsonPath="newPassword"
                formLabel={t('New Password')}
                type="password"
                autoComplete="new-password"
                value={newPassword}
                onChange={(value) => this.handlePasswordChange(value, 'newPassword')}
                onBlur={() => this.handleRulesPvr(false)}
                onFocus={this.handleFocus}
                forceShowAllErrors={forceShowAllErrors}
                flashErrorPvrs={flashErrorPvrs}
                registerError={registerError}
                validation={(function() {
                  const messages = [];
                  if (!newPassword.length) { messages.push(t('New Password is required.')); }
                  else if (!isValidPassword) { messages.push(t('New Password is invalid.')); }
                  return messages.length ? { messages } : null;
                })()}
              />
              {(!isValidPassword && rulesPvrOpen) ?
                <PasswordRules
                  {...this.props}
                  ruleResults={ruleResults}
                  close={this.handleRulesPvr}
                  anchor={document.querySelector('[data-test="newPassword"]')}
                /> : null
              }
              <GridFormInput
                data-test="newPasswordConfirm"
                formLabel={t('Confirm New Password')}
                type="password"
                autoComplete="new-password"
                value={newPasswordConfirm}
                onChange={(value) => this.handlePasswordChange(value, 'newPasswordConfirm')}
                forceShowAllErrors={forceShowAllErrors}
                flashErrorPvrs={flashErrorPvrs}
                registerError={registerError}
                validation={(function() {
                  const messages = [];
                  if (!newPasswordConfirm.length) { messages.push(t('Confirm New Password is required.')); }
                  if (newPassword && newPasswordConfirm && newPassword !== newPasswordConfirm) {
                    messages.push(t('New password must match'));
                  }
                  return messages.length ? { messages } : null;
                })()}
              />
              <div className={styles.ConfirmButton}>
                <button onClick={this.handlePasswordUpdate} className={appStyles.Button} disabled={!currentPassword || !newPassword || !newPasswordConfirm}>{t('Update Password')}</button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }

  public close = (): void => {
    this.props.togglePassword(false);
  };

  public resetSaveState = (): void => {
    this.setState({
      saveState: null,
      saveMessage: null,
    });
  };

  public handlePasswordChange = (value: any, property: string): void => {
    // if new password and password has length check to see if it is valid
    if (property === 'newPassword') {
      this.handlePasswordValidation(value);
    }
    // set state of field
    const state = this.state;
    state[property] = value;
    this.setState(state);
  };

  public handlePasswordValidation = (password: string): void => {
    const { loggedInUserStore } = this.props;
    const { user, getValidatePassword } = loggedInUserStore;
    const { email, username, NoEmailAccess } = user;
    // check to see if the password is valid
    getValidatePassword(password, email, username, NoEmailAccess).then((isValid) => {
      this.handleRulesPvr(!isValid);
    });
  };

  public handlePasswordUpdate = (e: React.MouseEvent): void => {
    e.preventDefault();
    const { updateUserPassword } = this.props.loggedInUserStore;
    const { currentPassword, newPassword, newPasswordConfirm } = this.state;
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    this.setState({
      saveState: SaveStates.PENDING,
    });

    updateUserPassword(currentPassword, newPassword, newPasswordConfirm)
      .catch(err => {
        this.setState({
          saveMessage: t('Failed to reset password. A server error was encountered'),
          saveState: SaveStates.FAILED,
        });
        return Promise.reject(err);
      })
      .then(() => {
        this.setState({
          saveState: SaveStates.COMPLETE,
        });
      });
  };

  public handleRulesPvr = (pvrOpen: boolean): void => {
    this.setState({
      rulesPvrOpen: pvrOpen,
    });
  };

  public handleFocus = (): void => {
    const { isValidPassword } = this.props.loggedInUserStore;

    if (!isValidPassword) { this.handleRulesPvr(true); }
  };

}
