module.exports = {
  "__appName__ is not currently available.": "__appName__ er ikke tilgængelig i øjeblikket.",
  "Access Code": "Adgangskode",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Konto ${this.username} er låst på grund af 5 login-forsøg. Kontakt din administrator for at låse den op",
  "Afghanistan": "Afghanistan",
  "Albania": "Albanien",
  "Algeria": "Algeriet",
  "American Samoa": "Amerikansk Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua og Barbuda",
  "App": "App",
  "Application Error": "Programfejl",
  "Application error.": "Programfejl.",
  "Application is not currently available.": "Programmet er ikke tilgængeligt nu.",
  "Apply Changes": "Anvend ændringer",
  "Apr": "Apr",
  "April": "April",
  "Argentina": "Argentina",
  "Armenia": "Armenien",
  "Aruba": "Aruba",
  "At least __value__ characters": "Mindst __value__ tegn",
  "At least one lowercase letter": "Mindst et lille bogstav",
  "At least one number": "Mindst et ciffer",
  "At least one of these special characters: __value__": "Mindst et af disse specialtegn: __value__",
  "At least one uppercase letter": "Mindst et stort bogstav",
  "Aug": "Aug",
  "August": "August",
  "Australia": "Australien",
  "Austria": "Østrig",
  "Azerbaijan": "Aserbajdsjan",
  "Bahamas": "Bahamaøerne",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Belarus",
  "Belgium": "Belgien",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth er slået fra",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnien-Hercegovina",
  "Botswana": "Botswana",
  "Brazil": "Brasilien",
  "Bronchial Lavage": "Bronkial skylning",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgarien",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Cambodia",
  "Cameroon": "Cameroun",
  "Canada": "Canada",
  "Cancel": "Afbryd",
  "Cannot be empty": "Må ikke være tom",
  "Cape Verde": "Kap Verde",
  "Capillary Blood": "Kapillærblod",
  "Cayman Islands": "Caymanøerne",
  "Central African Republic": "Den Centralafrikanske Republik",
  "Cerebrospinal Fluid": "Cerebrospinalvæske",
  "Cervical Swab": "Podning til livmoderhals",
  "Chad": "Tchad",
  "Change Access Code": "Skift adgangskode",
  "Check again": "Kontrollér igen",
  "Chile": "Chile",
  "China": "Kina",
  "Clear Search": "Ryd søgning",
  "Close": "Luk",
  "Cocos(Keeling) Islands and Chr": "Cocosøerne (Keelingøerne) og Jul",
  "Colombia": "Colombia",
  "Comoros": "Comorerne",
  "Confirm": "Bekræft",
  "Confirm New Password": "Bekræft ny adgangskode",
  "Confirm new password": "Bekræft ny adgangskode",
  "Confirm New Password is required.": "Bekræft ny adgangskode er påkrævet.",
  "Confirm Password is required.": "Bekræft adgangskode er påkrævet.",
  "Confirm Password must match New Password": "Bekræft adgangskode skal stemme overens med Ny adgangskode",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Cookøerne",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ – LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ – LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Cote D'Ivoire (Elfenbenskysten)",
  "Croatia(Hrvatska)": "Kroatien (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Dyrkningsvæske",
  "Current Password": "Aktuel adgangskode",
  "Current Password is required.": "Aktuel adgangskode er påkrævet.",
  "Current password required": "Aktuel adgangskode påkrævet",
  "Cyprus": "Cypern",
  "Czech Republic": "Den Tjekkiske Republik",
  "Danish": "Dansk",
  "Dec": "Dec",
  "December": "December",
  "Denmark": "Danmark",
  "Disabled": "Deaktiveret",
  "Discard Changes": "Kassér ændringer",
  "Dismiss": "Afvis",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Svarer ikke til dine tidligere adgangskoder",
  "Does not use unsupported characters": "Kan ikke bruge tegn, der ikke understøttes",
  "Domain": "Domæne",
  "Domain is required.": "Domæne er påkrævet.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominikanske Republik",
  "Door Open": "Lågen åben",
  "Dutch": "Hollandsk",
  "Ecuador": "Ecuador",
  "Egypt": "Ægypten",
  "El Salvador": "El Salvador",
  "Email": "E-mail",
  "Email is required.": "E-mail er påkrævet.",
  "English (UK)": "Engelsk (UK)",
  "English (US)": "Engelsk (USA)",
  "Enter": "Tryk enter",
  "Equatorial Guinea": "Ækvatorialguinea",
  "Eritrea": "Eritrea",
  "Error": "Fejl",
  "Estonia": "Estland",
  "Ethiopia": "Etiopien",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager er ikke understøttet i Connect mobilapplikationen.",
  "Failed to reset password. A server error was encountered": "Nulstilling af adgangskoden mislykkedes. Der opstod en fejl i serveren",
  "Falkland Islands(Malvinas)": "Falklandsøerne (Malvinas)",
  "Faroe Islands": "Færøerne",
  "Fatal Application Error": "Uoprettelig programfejl",
  "Feb": "Feb",
  "February": "Februar",
  "Fecal Specimen": "Fækalprøver",
  "Fiji": "Fiji",
  "File type must be __fileType__": "Filtype skal være __fileType__",
  "Finland": "Finland",
  "Finnish": "Finsk",
  "First Name": "Fornavn",
  "Forgot your password?": "Har du glemt din adgangskode?",
  "Fr": "Fr",
  "France": "Frankrig",
  "French": "Fransk",
  "French Guiana": "Fransk Guyana",
  "French Polynesia": "Fransk Polynesien",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgien",
  "German": "Tysk",
  "Germany": "Tyskland",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Gå til __storeName__",
  "Greater than __value__ in __measure__": "Større end __value__ i __measure__",
  "Greece": "Grækenland",
  "Greenland": "Grønland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct uden for område",
  "Hide": "Skjul",
  "Hide password": "Skjul adgangskode",
  "Holy See(Vatican City State)": "Santa Sede (Vatikanstaten)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, SAR",
  "Hungary": "Ungarn",
  "Iceland": "Island",
  "India": "Indien",
  "Indonesia": "Indonesien",
  "Initials": "Initialer",
  "Install": "Installer",
  "Install Now": "Installer nu",
  "Instrument Not Level": "Instrument er ikke placeret vandret",
  "Insufficient Sample": "Utilstrækkelig prøve",
  "Internet connection is offline.": "Internetforbindelsen er offline.",
  "Invalid access code": "Ugyldig adgangskode",
  "Invalid email format": "Ugyldigt e-mailformat",
  "Invalid File": "Ugyldig fil",
  "Invalid Lot Cal File": "Ugyldig Lot kal-fil",
  "Invalid username or password.": "Ugyldigt brugernavn eller adgangskode.",
  "Iran, Islamic Republic of": "Iran, Den Islamiske Republik",
  "Iraq": "Irak",
  "Ireland": "Irland",
  "Israel": "Israel",
  "Italian": "Italiensk",
  "Italy": "Italien",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "Januar",
  "Japan": "Japan",
  "Japanese": "Japansk",
  "Jordan": "Jordan",
  "Jul": "Jul",
  "July": "Juli",
  "Jun": "Jun",
  "June": "Juni",
  "Kazakhstan": "Kasakhstan",
  "KC Swab": "KC-podning",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Den Demokratiske Folkerepublik",
  "Korea, Republic of(South)": "Korea, Republikken (syd)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgisistan",
  "Language": "Sprog",
  "Laos(Lao PDR)": "Laos (Dem. Folkerep. Laos)",
  "Last Name": "Efternavn",
  "Latvia": "Letland",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Mindre end __value__ i __measure__",
  "Liberia": "Liberia",
  "Libya": "Libyen",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Flydende kvalitetskontrol",
  "Lithuania": "Litauen",
  "Loading more records...": "Indlæser flere journaler…",
  "Login": "Log ind",
  "Logout": "Log ud",
  "Luxembourg": "Luxembourg",
  "Macao(SAR China)": "Macao (SAR Kina)",
  "Macedonia, Republic of": "Makedonien, Republikken",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysia",
  "Maldives": "Maldiverne",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Marts",
  "Marshall Islands": "Marshalløerne",
  "Martinique": "Martinique",
  "Mauritania": "Mauretanien",
  "Mauritius": "Mauritius",
  "MAX": "MAKS.",
  "Maximum length is __count__ characters": "Maks. længde er __count__ tegn",
  "May": "Maj",
  "Mexico": "Mexico",
  "Micronesia, Federated States o": "Mikronesiens Fødererede Stater",
  "Mo": "Ma",
  "Mobile Application Version": "Mobilapplikation-version",
  "Moldova": "Moldova",
  "Monaco": "Monaco",
  "Mongolia": "Mongoliet",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marokko og Vestsahara",
  "Mouth Swab": "Podning til munden",
  "Mozambique": "Mozambique",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Nasal sekret",
  "Nasal Swab": "Podning til næsen",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Holland",
  "Netherlands Antilles": "De Nederlandske Antiller",
  "Never Connected": "Aldrig tilsluttet",
  "New Caledonia": "Nykaledonien",
  "New Password": "Ny adgangskode",
  "New Password is invalid.": "Ny adgangskode er ugyldig.",
  "New Password is required.": "Ny adgangskode er påkrævet.",
  "New password must match": "Ny adgangskode skal matche",
  "New Version Required": "Ny version påkrævet",
  "New Zealand": "New Zealand",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Der eksisterer ikke nogen applikationsmoduler i dette miljø. Rapportér dette forhold til en administrator.",
  "No easily guessable words": "Ingen ord der let kan gættes",
  "No more than __value__ characters": "Ikke mere end __value__ tegn",
  "No region set": "Ingen region indsat",
  "No repetitive characters (e.g. aaa)": "Ingen repetitive tegn (f.eks. aaa)",
  "No sequential characters (e.g. 123)": "Ingen fortløbende tegn (f.eks. 123)",
  "No Test Strip Detected": "Ingen Testkort detekteret",
  "none": "Ingen",
  "Norfolk Island": "Norfolk Island",
  "Northern Mariana Islands": "Nordmarianerne",
  "Norway(inc Svalbard and Jan May": "Norge (inkl. Svalbard og Jan Mayen)",
  "Norwegian": "Norsk",
  "Nov": "Nov",
  "November": "November",
  "NP Aspirate": "NP-aspirat",
  "NP Swab": "NP-podning",
  "Oct": "Okt",
  "October": "Oktober",
  "Offline 1 day": "Offline 1 dag",
  "Offline 1 hour": "Offline 1 time",
  "Offline 1 minute": "Offline 1 minut",
  "Offline 1 month": "Offline 1 måned",
  "Offline 1 year": "Offline 1 år",
  "Offline __days__ days": "Offline __days__ dage",
  "Offline __hours__ hours": "Offline __hours__ timer",
  "Offline __minutes__ minutes": "Offline __minutes__ minutter",
  "Offline __months__ months": "Offline __months__ måneder",
  "Offline __years__ years": "Offline __years__ år",
  "OK": "OK",
  "Okay": "Ok",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "De palæstinensiske områder, besat",
  "Panama": "Panama",
  "Papua New Guinea": "Papua Ny Guinea",
  "Paraguay": "Paraguay",
  "Password": "Adgangskode",
  "Password requirements:": "Krav til adgangskode:",
  "Peru": "Peru",
  "Philippines": "Filippinerne",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugisisk (Brasilien)",
  "Portuguese (Portugal)": "Portugisisk (Portugal)",
  "Profile": "Profil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Angiv følgende oplysninger for at indstille din adgangskode.  Valideringskoden blev sendt til din e-mail, hvis gyldig.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Angiv din e-mail for at anmode om at nulstille en adgangskode. Der vil blive sendt en midlertidig valideringskode til din e-mail.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC-opløsning detekteret",
  "Rectal Swab": "Rektal-podning",
  "Required Update": "Påkrævet opdatering",
  "Reset": "Nulstil",
  "Reset Password": "Nulstil adgangskode",
  "Reset password": "Nulstil adgangskode",
  "Resolution requirements": "Resolutionskrav",
  "Return to Login": "Vend tilbage for at logge ind",
  "Romania": "Rumænien",
  "Russian Federation": "Den Russiske Føderation",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion og Mayotte",
  "Sa": "Lø",
  "Saint Helena and Tristan da Cu": "Sankt Helena og Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts og Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre og Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent og Grenadinerne",
  "Saliva": "Spyt",
  "Samoa": "Samoa",
  "Sample Detected": "Prøve fundet",
  "Sample Error": "Prøvefejl",
  "Sample Type Error": "Fejl ved prøvetype",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi-Arabien",
  "Save": "Gem",
  "Select a date": "Vælg en dato",
  "Select from list...": "Vælg fra listen…",
  "Select language": "Vælg sprog",
  "Selected files must match requirements": "De valgte filer skal opfylde kravene",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "September",
  "Serbia": "Serbien",
  "Serum": "Serum",
  "Server error, please try again later.": "Serverfejl, prøv igen senere.",
  "Server Error. Unable to save your profile.": "Serverfejl. Kunne ikke gemme din profil.",
  "Set Password": "Indstil adgangskode",
  "Seychelles": "Seychellerne",
  "Show Password": "Vis adgangskode",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Størrelse skal være mindre end __maxSize__",
  "Skin Swab": "Hud-podning",
  "Slovakia": "Slovakiet",
  "Slovenia": "Slovenien",
  "Solomon Islands": "Salomonøerne",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Beklager, DataView er ikke aktiveret, eller du har ikke adgang til denne organisation",
  "South Africa": "Sydafrika",
  "Space": "Mellemrum",
  "Spain": "Spanien",
  "Spanish": "Spansk",
  "Spanish (US)": "Spansk (USA)",
  "Sputum": "Opspyt",
  "Sri Lanka": "Sri Lanka",
  "Su": "Sø",
  "Sudan": "Sudan",
  "Suriname": "Surinam",
  "Swaziland": "Swaziland",
  "Sweden": "Sverige",
  "Swedish": "Svensk",
  "Switzerland": "Schweiz",
  "Syrian Arab Republic(Syria)": "Den syriske Arabiske Republik (Syrien)",
  "São Tomé and Príncipe": "São Tomé og Príncipe",
  "Taiwan, Republic of China": "Taiwan, Republikken Kina",
  "Tajikistan": "Tadsjikistan",
  "Tanzania, United Republic of": "Tanzania, Den Forenede Republik",
  "Temperature Error": "Temperaturfejl",
  "Test Cancelled": "Analyse afbrudt",
  "Test Lot Not Permitted": "Analyse Lot ikke tilladt",
  "Test Operation Error": "Driftsfejl ved analysen",
  "Test Strip Lot Expired": "Testkort Lot udløbet",
  "Test Strip Not Supported": "Testkort ikke understøttet",
  "Test Strip Reading Error": "Læsningsfejl på Testkort",
  "Test Timeout": "Tidsafbrud for analysen",
  "Test Type Not Permitted": "Testtype ikke tilladt",
  "Th": "To",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Den nye valideringskode for adgangskoden vil blive sendt til denne e-mail, hvis e-mailadressen er en gyldig adresse.",
  "The reset validation code was populated from the link and should not be changed.": "Valideringskoden til nulstilling blev udfyldt via linket og bør ikke ændres.",
  "There are unsaved changes. How do you want to proceed?": "Der er ikke-gemte ændringer. Hvordan ønsker du at fortsætte?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "En ny version er tilgængelig (__versionNumber__). Vil du installere den nu?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Enheden fungerer ikke længere som Connect Hub. Hvis du fortsat vil anvende enheden som Connect Hub, skal du genaktivere Bluetooth i indstillingerne på din enhed og slå Brug denne mobile enhed som Connect Hub til igen på skærmen Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Denne version af LumiraDx Connect er ikke længere kompatibel med din organisation. Gå til __storeName__, og opdater til den nyeste version.",
  "Throat Swab": "Hals-podning",
  "Timor - Leste": "Østtimor",
  "Title": "Titel",
  "TN Swab": "Hals-/næsepodning",
  "to": "til",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Opgradér til den seneste version (__versionNumber__) for at fortsætte brugen af __APP_NAME__.",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad og Tobago",
  "Tu": "Ti",
  "Tunisia": "Tunesien",
  "Turkey": "Tyrkiet",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- og Caicosøerne",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraine",
  "Unable to reset password, the provided current password is not valid.": "Kan ikke nulstille adgangskoden. Den indtastede aktuelle adgangskode er ikke gyldig.",
  "Unable to reset your password.": "Adgangskoden kan ikke nulstilles.",
  "Unable to validate access code.": "Adgangskoden kan ikke valideres.",
  "United Arab Emirates": "Forenede Arabiske Emirater",
  "United Kingdom": "Storbritannien",
  "United States of America": "USA",
  "Unrecognized credentials. Please try again.": "Legitimation blev ikke registreret. Prøv venligst igen.",
  "Unsaved Changes": "Ikke-gemte ændringer",
  "Update Available": "Opdatering er tilgængelig",
  "Update Password": "Opdater adgangskode",
  "Urethral Swab": "Podning til urinrør",
  "Urine": "Urin",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Brugerkontoen er låst. Prøv igen om en time.",
  "User Interface Version": "Brugergrænseflade-version",
  "User logged out due to inactivity.": "Bruger logget ud pga. inaktivitet.",
  "Username": "Brugernavn",
  "Uzbekistan": "Usbekistan",
  "Vaginal Swab": "Vaginal podning",
  "Validate Access Code": "Valider adgangskode",
  "Validation Code": "Valideringskode",
  "Validation Code is required.": "Valideringskode er påkrævet.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (Den Bolivarianske Republik)",
  "Venous Blood": "Veneblod",
  "Version": "Version",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Jomfruøerne, britiske",
  "Virgin Islands, US": "Jomfruøerne, amerikanske",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvo vaginal podning",
  "Wallis and Futuna Islands": "Wallis og Futunaøerne",
  "We": "On",
  "We are working to get you back up and running as quickly as possible.": "Vi arbejder på at få dig op at køre igen så hurtigt som muligt.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Vi beklager, men LumiraDx Connect er lige nu nede på grund af vedligeholdelse.",
  "Yemen": "Yemen",
  "You have been signed out due to a password reset attempt.": "Du blev logget ud pga. et forsøg på at nulstille adgangskoden.",
  "You have been signed out due to the creation of another session with the same credentials.": "Du blev logget ud, fordi en anden session med samme legitimation blev oprettet.",
  "You have been signed out due to your account being disabled by an admin.": "Du blev logget ud, fordi din konto blev deaktiveret af en admin.",
  "You have been signed out due to your password being changed by an admin.": "Du blev logget ud, fordi din adgangskode blev ændret af en admin.",
  "You have been signed out due to your user role being changed by an admin.": "Du blev logget ud, fordi din brugerrolle blev ændret af en admin.",
  "You have been signed out.": "Du blev logget ud.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Du skal installere en anden version (__versionNumber__) af __APP_NAME__ for at være kompatibel med din organisation.",
  "Your password has expired. Please set a new password.": "Din adgangskode er udløbet. Indstil en ny adgangskode.",
  "Your session has expired. Please login again.": "Sessionen er udløbet. Log venligst ind igen.",
  "Your session has expired. User is not authorized.": "Sessionen er udløbet. Bruger ikke godkendt.",
  "Zambia": "Zambia",
  "Zimbabwe": "Zimbabwe"
};
