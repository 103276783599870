import { observable, action, computed, set, runInAction, when } from 'mobx';
import * as ServerRequest from '@Lineup/ServerRequest';
const { Req, ContentType, Auth, ErrorStyles, ResponseType } = ServerRequest;
import { ITarget } from '../../../server/interfaces';
import { IUser, IPasswordRules, IRuleResult, IValidatePassword, IQToken, IQuantumObjRes, IQUser, IQAccessDto, IOrg } from '../global/interfaces';
import { InvalidTokens } from '../global/enums';
import { InvalidTokenMsgs, FACTORY_CODE, PROD_HOF_URL, TOOLS_CODE, MOBILE_APPS } from '../global/constants';
import { ConsultReq } from '../helpers';
import amd from '../helpers/amd';
const { unregisterDependency, clearDynamicDeps } = amd;

import { history } from '../helpers/history';
import { mappedQuantumObject, quantumUserMappings } from '../global/helpers';
import { setItem, getItem } from '../helpers/LocalStorage';

// Iterate through the factories and find the default one
import * as moment from 'moment';

const COOKIE_NAME = 'locator';

interface ICreds {
  username?: string;
  password?: string;
}

interface ICookieCode {
  codeFromCookie?: string;
}

class LoggedInUser {
  @observable public username: string = '';
  @observable public password: string = '';
  @observable public userToken: string = '';
  @observable public appToken: string = '';
  @observable public passwordChangeRequired: boolean = false;
  @observable public forcePasswordChange: boolean = false;
  @observable public accessDto: IQAccessDto = null;
  @observable public passwordRules: IPasswordRules;
  @observable public validatePassword: any;
  @observable public isValidPassword: boolean = true;
  @observable public ruleResults: IRuleResult[] = [];
  @observable public targets: ITarget[] = [];
  @observable public activeTarget: ITarget = {};
  @observable public branch: string;
  @observable public loginMessage: string;
  @observable public user: IUser;
  @observable public initialFromRoute: string = window.location.hash.slice(1);
  @observable public userLastActive: Date = new Date();
  @observable public connectCreds: any;
  @observable public validateCodeAbortController: AbortController = null;
  @observable public moduleVersionOverride: Map<string, string>;
  @observable public pdeOrg: IOrg;
  @observable public loggedInGroupOrgs: IOrg[] = [];

  // This is used to track hof URLs that have been tried in a specific access code validation attempt
  // Should the AlternativeHofUrl chain be misconfigured, it would be easy to create an inifinite loop
  // This ensures that if the validateAccessCode method encounters the same URL a 2nd time in a validation attempt, it catches and stops trying
  private triedHofUrls: Set<string> = new Set();

  @computed public get fullName(): string {
    return this.user ? `${this.user.firstName} ${this.user.lastName}` : 'Unknown';
  }

  constructor() {
    // [Aaron]: Added this to allow CS to login again, not sure what this should be
    // and how it really works. If you can refactor this, please go ahead.

    try {
      this.moduleVersionOverride = new Map(JSON.parse(getItem('moduleVersionOverride')));
    }
    catch {
      this.moduleVersionOverride = new Map();
    }

    if (process.env.CS) {
      this.accessDto = {
        AccessCode: '',
        ExternalGuid: '',
        QuantumSyncUrl: '',
        UiApiUrl: '',
        EnvShortCode: '',
        Version: '',
        OrganizationStatus: true,
      };
    }

  }
  /**
   * Attempts to parse the org access code our of the locator cookie.
   */
  @action public parseLocatorCookie = (): ICookieCode => {
    // Check for the locator cookie from older versions of the web app.
    // If present, delete the cookie and return the code
    if (!process.env.MOBILE_APP) {
      let locator;
      const values = document.cookie.split(/;\s?/);

      for (let i = values.length - 1; i >= 0; i--) {
        const val = values[i];
        if (val.startsWith(`${COOKIE_NAME}=`)) {
          locator = val.split('=')[1];
          break;
        }
      }

      if (locator != null) {
        const [, code] = locator.match(/https?:\/\/(\w+)-/);

        // Delete the cookie after, regardless of outcome.
        // If a code was not found due to an invalid format, we don't want to try to use it anyways
        document.cookie = `${COOKIE_NAME}=deleted; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/`;

        return { codeFromCookie: /^[\d|\w]{5}$/.test(code) ? code : void 0 };
      }
      else {
        return {};
      }
    }
    else {
      return {};
    }
  };

  @action public setUserLastActiveTime = (): void => {
    const now = new Date();
    const diff = Math.abs(moment(this.userLastActive).diff(now, 'minutes'));
    const timeout = this.getUserTimeout();

    // Logout the user if they have been idle for more than the specified timespan
    if (diff >= timeout) {
      this.logout();
      return this.setLoginMessage(t('User logged out due to inactivity.'));
    }

    this.userLastActive = now;
  };

  public autoFillConnectCreds = (): void => {
    if (this.connectCreds && this.accessDto && this.connectCreds[this.accessDto.AccessCode.toLowerCase()]) {
      const [username, password] = this.connectCreds[this.accessDto.AccessCode.toLowerCase()];
      this.username = username;
      this.password = password;
    }
  };

  /**
   * Change the entry route to the application. Useful for avoiding render loops when routing directly to certain views.
   * @param route the route value to set. Will also be returned by this method.
   */
  @action public setInitialFromRoute = (route: string = '/'): string => {
    this.initialFromRoute = route;
    return route;
  };

  /**
   * Reset the LoggedInUser store to an initial state
   */
  @action public reset(): Partial<LoggedInUser> {
    const props: Partial<LoggedInUser> = {
      user: null,
      userToken: '',
      isValidPassword: true,
      passwordRules: null,
    };

    if (process.env.NODE_ENV === 'production') {
      props.password = '';
    }

    Req.configure({
      errorStyle: process.env.CS ? ErrorStyles.REGULAR : ErrorStyles.QUANTUM,
      headers: {
        'Content-Type': ContentType.JSON,
        'Accept': ContentType.JSON,
      },
    });

    return set(this, props);
  }

  /**
   * Validate the access code against the factory instance
   * @param code The code string passed to the factory instance for validation
   */
  @action public validateAccessCode = (code: string, altUrl?: string): Promise<any> => {
    // set the loginMessage to null so that the spinner will show when validating
    this.loginMessage = null;
    const url = process.env.MOBILE_APP ? (altUrl ? altUrl : PROD_HOF_URL) : '/hof'; // hofUrl;

    if (this.triedHofUrls.has(url)) {
      this.triedHofUrls.clear();
      return Promise.reject({
        message: t('Unable to validate access code.'),
      });
    }
    else {
      this.triedHofUrls.add(url);
    }

    // Note that this does not catch on Errored: true since the payload needs to be parsed even on an error.
    // The catch here is a 'just in case' something else goes wrong with the request
    // Set the current request to validateAccessCodeRequest so it can be accessed and aborted if necessary
    this.validateCodeAbortController = new AbortController();
    return Req.get({
      prefix: '',
      url: `${url}/OrganizationLocator/Validate/${code}`,
      errorStyle: ErrorStyles.REGULAR,
      abort: this.validateCodeAbortController,
    })
      .then((res: IQuantumObjRes<IQAccessDto>): IQAccessDto | Promise<any> => {
        if (res.Errored) {
          if (res.AlternativeHofUrl) {
            return this.validateAccessCode(code, res.AlternativeHofUrl);
          }
          else {
            this.triedHofUrls.clear();
            return Promise.reject({
              message: t('Unable to validate access code.'),
            });
          }
        }
        else {
          this.triedHofUrls.clear();
          runInAction(() => {
            this.setAccessDto(res.Object);
          });
          return res.Object;
        }
      })
      .catch(({ code, message }) => {
        this.triedHofUrls.clear();
        return Promise.reject({
          message: t('Unable to validate access code.'),
          code,
        });
      })
      .finally(() => {
        this.validateCodeAbortController = null;
      });
  };

  /**
   * Sets the accessDto on the store, as well as the local storage property and Req configuration
   * @param accessDto an access object
   */
  @action public setAccessDto = (accessDto: IQAccessDto, persist = true): void => {
    // Clear amd registry of loaded modules if necessary
    if (this.accessDto && this.accessDto.Version !== accessDto?.Version) {
      clearDynamicDeps();
    }

    this.accessDto = accessDto;
    const { AccessCode, EnvShortCode, UiApiUrl, Version = '3.2' } = accessDto;

    // only set access code in local storage if not tools and persist
    if (persist && AccessCode !== TOOLS_CODE) {
      // Set the local storage property
      setItem('accessCode', AccessCode);
    }

    ConsultReq.configure({
      prefix: `/api/${EnvShortCode}/consult/v${Version}`,
      errorStyle: ErrorStyles.REGULAR,
      responseType: ResponseType.JSON,
    });

    // In mobile debug mode only, autofill creds when the value has changed
    if ((process.env.DEBUG_MOBILE || process.env.NODE_ENV === 'development') && !process.env.CS) {
      this.autoFillConnectCreds();
    }

    const prefix = AccessCode === FACTORY_CODE ?
      '/api/factory'
      : (process.env.MOBILE_APP ? UiApiUrl : `/api/${EnvShortCode}/connectmanager/v${Version}`);

    // Configure Request
    Req.configure({
      errorStyle: ErrorStyles.QUANTUM,
      prefix,
    });
  };

  /**
   * Resets the accessDto to null
   */
  @action public clearAccessDto = (): void => {
    this.accessDto = null;
  };

  /**
   * Updates the Organization status in the accessDto, which signals an org is offline
   * This is used when the server responds with a 404 on the token call
   * @param OrganizationStatus boolean
   */
  @action public toggleOrgStatus = (OrganizationStatus: boolean): void => {
    this.accessDto = {
      ...this.accessDto,
      OrganizationStatus,
    };
  };

  /**
   * Resets the LoggedInUser store back to an initial state, then uses React Router's History instance to route back to the Login page
   * @param history React Router's History instance. Usually passed from `this.props.history`.
   */
  @action public logout = (): void => {
    window.removeEventListener('click', this.setUserLastActiveTime, true);
    window.removeEventListener('touchstart', this.setUserLastActiveTime, true);
    window.removeEventListener('keydown', this.setUserLastActiveTime);
    window.removeEventListener('focus', this.setUserLastActiveTime);
    this.reset();

    if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      history.push('/amira/login/auth');
    }
    // Attempt fix for IE
    // @ts-ignore
    if (navigator.msSaveBlob != null) {
      unregisterDependency('connect');
    }
  };

  @action public handleAPIErrors = (status: number, err = { Message: InvalidTokens.EXPIRED, ErrorEnum: null }): void => {
    if (![401, 403, 404, 503].includes(status)) { return; }

    if (status === 401) {
      const msgs = InvalidTokenMsgs();
      const { Message } = err;
      this.loginMessage = msgs.has(Message) ? msgs.get(Message) : msgs.get(InvalidTokens.EXPIRED);
      this.logout();
    }
    else if (status === 403) {
      this.loginMessage = t('Your session has expired. User is not authorized.');
      this.logout();
    }
    else if (status === 404 && err.ErrorEnum === 'OrganizationOffline') {
      this.toggleOrgStatus(false);
      this.logout();
    }
    else if (status === 503) {
      this.loginMessage = t('Application is not currently available.');
      this.logout();
    }

  };

  @action public loginApp = async (userName, password): Promise<any> => {
    // Wait for the accessDto to be available
    await new Promise((resolve, reject) => {
      const disposer = when(
        () => this.accessDto != null,
        () => {
          resolve(this.accessDto);
          disposer();
        }
      );
    });

    const { AccessCode } = this.accessDto;

    const body = 'source=app&grant_type=password'
      + `&username=${encodeURIComponent(userName)}`
      + `&password=${encodeURIComponent(password)}`
      + `&AccessCode=${AccessCode}`;

    return Req.post({
      url: '/token',
      contentType: ContentType.URL_ENCODED,
      body,
    })
      .catch(({ status, message: { error_description } }) => {
        return Promise.reject({ status, msg: error_description });
      })
      .then((res: IQToken) => {
        runInAction(() => {
          this.appToken = res.access_token;
        });
      });
  };

  @action private loginQuantum = (): Promise<any> => {
    const { username, password } = this;
    const { AccessCode } = this.accessDto;

    let body = `grant_type=password&username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`;
    body += AccessCode === FACTORY_CODE ? '' : `&AccessCode=${AccessCode}`;

    return Req.post({
      url: '/token',
      contentType: ContentType.URL_ENCODED,
      body,
    })
      .catch(({ status, message: { error_description } }) => {
        this.passwordChangeRequired = false;
        this.forcePasswordChange = false;
        return Promise.reject({ status, msg: error_description });
      })
      .then((res: IQToken) => {
        if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
          setItem('username', username);
        }
        runInAction(() => {
          this.setToken(res.access_token);
          this.passwordChangeRequired = res.password_change_required; // Password expired
          this.forcePasswordChange = res.force_password_change; // Admin set users password, and they need to set their own on first login
        });
      })
      .then(this.attachUserActiveHandlers);
  };

  @action public setToken = (token: string): void => {
    this.userLastActive = new Date();
    this.userToken = token;
    if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      setItem('token', this.userToken);
    }
    this.configureRequest();
  };

  private getUserTimeout = (): number => {
    return process.env.MOBILE_APP === MOBILE_APPS.AMIRA ?
      this.passwordRules.AmiraUserSessionTimeSpan : this.passwordRules.UserSessionTimeSpan;
  };
  @action public attachUserActiveHandlers = (): void => {

    // Once the passwordRules come back, attach listeners for setting the user active time
    when(
      () => this.passwordRules != null,
      () => {
        const timeout = this.getUserTimeout();

        // Only track the user's session time if the timeout isn't 0
        if (timeout !== 0) {
          window.addEventListener('click', this.setUserLastActiveTime, true);
          window.addEventListener('touchstart', this.setUserLastActiveTime, true);
          window.addEventListener('keydown', this.setUserLastActiveTime);
          window.addEventListener('focus', this.setUserLastActiveTime);
        }
      }
    );
  };

  @action public configureRequest = (): void => {
    Req.configure({
      auth: Auth.TOKEN_BEARER,
      token: this.userToken,
      handleErrorResponse: this.handleAPIErrors,
      errorStyle: ErrorStyles.QUANTUM,
      headers: {
        'Content-Type': ContentType.JSON,
        'Accept': ContentType.JSON,
      },
    });
  };

  /**
   * Quantum specific - Gets the details about the logged in user and maps them to this.user
   */
  @action public getCurrentUser = (): Promise<IUser> => {
    return Req.get({
      url: '/api/account/currentuser',
    })
      .then((res: IQuantumObjRes) => {
        runInAction(() => {
          this.user = mappedQuantumObject<IUser, IQUser>(res.Object, quantumUserMappings);
        });

        return this.user;
      });
  };

  /**
   * Quantum only - Update the stored user
   */
  @action public updateCurrentUser = (user, token?): void => {
    this.user = user;
    if (token != null) {
      this.userToken = token;
    }
  };

  /**
   * Quantum only - Save the stored user
   */
  @action public saveCurrentUser = (user: IUser): Promise<IUser> => {
    return Req.put({
      url: '/api/account/update',
      body: mappedQuantumObject<IUser, IQUser>(user, quantumUserMappings),
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes): IUser | Promise<any> => {
        runInAction(() => {
          this.user = mappedQuantumObject<IUser, IQUser>(res.Object, quantumUserMappings);
        });

        return this.user;
      });
  };

  @action public updateUserPassword = (currentPassword: string, newPassword: string, newPasswordConfirm: string): Promise<any> => {
    return Req.put({
      url: '/api/account/setpassword',
      body: {
        CurrentPassword: currentPassword,
        NewPassword: newPassword,
        ReenterPassword: newPasswordConfirm,
        UserName: this.username,
        Id: this.user.id,
      },
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      });
  };

  @action public resetPassword = (email: string): Promise<any> => {
    const { AccessCode } = this.accessDto;

    return Req.put({
      url: '/api/account/resetpasswordcode',
      headers: {
        'Content-Type': ContentType.JSON,
        'Accept': ContentType.JSON,
      },
      query: {
        accessCode: AccessCode,
      },
      body: {
        UserName: email,
      },
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      });
  };

  @action public setPasswordWithCode = (email: string, code: string, newPassword: string): Promise<any> => {
    const { AccessCode } = this.accessDto;

    return Req.put({
      url: '/api/account/setpasswordwithcode',
      headers: {
        'Content-Type': ContentType.JSON,
        'Accept': ContentType.JSON,
      },
      query: {
        accessCode: AccessCode,
      },
      body: {
        UserName: email,
        Code: code,
        NewPassword: newPassword,
      },
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      });
  };

  @action private loginCs = (): Promise<any> => {

    this.activeTarget = {
      id: 'NabooGql',
      name: 'NabooGql',
      url: 'http://naboolinux01.development.scsl.network:3000/graphql',
    };

    console.log(`Current active target: ${this.activeTarget.url}`);

    return new Promise((resolve, reject) => {
      this.user = {
        id: 'id',
        username: 'fake',
        firstName: 'fake',
        lastName: 'fake',
      };
      resolve('done');
    });
  };

  @action public getPasswordRules = (): Promise<IPasswordRules> => {
    const { AccessCode } = this.accessDto;

    return Req.get({
      url: '/api/Organization/GetPasswordComplexity',
      query: {
        accessCode: AccessCode,
      },
    })
      .then((res: IQuantumObjRes<IPasswordRules>) => {
        this.passwordRules = res.Object;
        return this.passwordRules;
      });
  };

  @action public resetPasswordRules = (): void => {
    this.ruleResults.forEach(r => r.Passed = false);
    this.isValidPassword = true;
  };

  @action public getValidatePassword = (password: string, email: string, username: string, noEmailAccess?: boolean, passwordRules?: number): Promise<boolean> => {
    const { AccessCode } = this.accessDto;

    const query = (noEmailAccess) ?
      { password, accessCode: AccessCode, username, passwordRules } :
      { email, password, accessCode: AccessCode, username, passwordRules };

    if (password !== '') {
      return Req.get({
        url: '/api/Account/ValidatePassword',
        query: query,
        sendEmptyQueries: true,
      })
        .then((res: IValidatePassword) => {
          const { Object } = res;

          // Remove the unsupported characters rule for Engage App
          const filteredRules = Object.RuleResults;
          const failedRules = filteredRules.filter(r => !r.Passed);

          this.isValidPassword = failedRules.length === 0;
          this.ruleResults = filteredRules;

          return this.isValidPassword;
        });
    }
    else {
      this.ruleResults.forEach(r => r.Passed = false);
      this.isValidPassword = false;
      return Promise.resolve(this.isValidPassword);
    }
  };

  @action public login = (): Promise<any> => {
    // login
    if (process.env.CS) {
      return this.loginCs();
    }
    else {
      return this.loginQuantum();
    }
  };

  @action public getTargets = (): Promise<ITarget> => {
    return Req.get({
      prefix: 'consult',
      url: '/devinfo',
    })
      .then(({ devServices, activeTarget, branch, connectCreds }) => {
        this.targets = devServices;
        this.activeTarget = activeTarget;
        this.branch = branch;
        if ((process.env.DEBUG_MOBILE || process.env.NODE_ENV === 'development') && !process.env.CS) {
          this.connectCreds = connectCreds;
          this.autoFillConnectCreds();
        }
        return activeTarget;
      });
  };

  @action public setTargetByName(name: string, account: string): Promise<any> {
    let username;
    let password;

    if (account) {
      [username, password] = account.split('/');
    }

    if (name) {

      const target = this.targets.find((elem) => elem.name === name);

      this.activeTarget = target;

      const targetWithSelectedAccount = (username && password) ? { ...target, activeAccount: [username, password] } : target;

      return Req.put({
        prefix: 'consult',
        url: '/proxies',
        body: targetWithSelectedAccount,
      });
    }
    else {
      return null;
    }

  }

  @action public setTarget(): void {
    Req.put({
      prefix: 'consult',
      url: '/proxies',
      body: this.targets[0],
      contentType: ContentType.JSON,
    });
  }

  @action public setCredentials = ({ username, password }: ICreds): void => {
    if (username !== undefined) { this.username = username; }
    if (password !== undefined) { this.password = password; }
  };

  @action public setLoginMessage = (message): void => {
    this.loginMessage = message;
  };

  @action public resetRequiredPasswordChange = (): void => {
    this.passwordChangeRequired = false;
    this.forcePasswordChange = false;
  };

  /**
   * If a chain of access code validations are being fired, it will cancel the requests on mount
   */
  @action public abortCodeValidation = (): void => {
    if (this.validateCodeAbortController != null) {
      this.validateCodeAbortController.abort();
    }
  };

  @action public setModuleVersionOverride = (config: Map<string, string>) => {
    this.moduleVersionOverride = config;
  };

  @action public getMemberOrgsPDE = (): Promise<IOrg> => {
    const { ExternalGuid } = this.accessDto;

    return ConsultReq.get({
      url: '/organizations/memberships',
      responseType: ResponseType.JSON,
      query: {
        rolesOnly: true,
        filterBy: 'DataViewEnabled',
      },
      overwriteHeaders: true,
      headers: {
        'username': this.username,
        'password': this.password,
        'Content-Type': ContentType.JSON,
        'Accept': ContentType.JSON,
      },
    })
      .then((res: IOrg[]) => {
        const pdeOrg = res.find(o => o.id === ExternalGuid);

        runInAction(() => {
          this.loginMessage = undefined;
          this.pdeOrg = pdeOrg;
        });

        if (!this.pdeOrg) {
          this.setLoginMessage(t('Sorry, DataView is not enabled, or you do not have access to this organization'));
          return Promise.reject();
        }
        else {
          return this.pdeOrg;
        }
      })
      .catch((err) => {
        const { status } = err;
        const { setLoginMessage } = this;

        switch (+status) {
          case 401:
            setLoginMessage(t('Invalid username or password.'));
            break;
          case 403:
            setLoginMessage(t(`Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it`));
            break;
          default:
            setLoginMessage(t('__appName__ is not currently available.', { appName: 'LumiraDx DataView' }));
        }
        return Promise.reject();
      });
  };
}

const store = new LoggedInUser();
export { LoggedInUser, store as LoggedInUserStore };

// Make this available as a global for cypress
(window as any).__LoggedInUserStore = store;
Req.configure({
  errorStyle: process.env.CS ? ErrorStyles.REGULAR : ErrorStyles.QUANTUM,
  headers: {
    'Content-Type': ContentType.JSON,
    'Accept': ContentType.JSON,
  },
});
