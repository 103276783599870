import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { TextInput, Spinner } from '@Lineup/index';
import { IComponent, IQAccessDto, InjectedComponent } from '../../global/interfaces';
import { inject, observer } from 'mobx-react';
import { SpinnerNames } from '@Lineup/enums/index';
const styles = require('./styles/AccessCode.styl');
const appStyles = require('../App/styles/App.styl');

interface IProps extends IComponent {
  validateAccessCode: (code: string) => Promise<any>;
  showAccessCode: () => void;
  existingCode: string;
  manualAccessCodeNav: boolean;
  handleLangClick: () => void;
  langRef: React.RefObject<HTMLButtonElement>;
  locale: string;
  showUILoader: () => void;
}

@inject('loggedInUserStore', 'mobileBridgeStore') @observer
export default class AccessCode extends InjectedComponent<IProps, any> {
  public state = {
    accessCode: '',
    loading: false,
  };

  public componentDidMount(): void {
    const { abortCodeValidation, setLoginMessage } = this.props.loggedInUserStore;
    abortCodeValidation();
    setLoginMessage('');
  }

  public render(): JSX.Element {
    const { accessCode, loading } = this.state;
    const { loginMessage, initialFromRoute } = this.props.loggedInUserStore;
    const { existingCode, manualAccessCodeNav, handleLangClick, langRef, locale, mobileBridgeStore: { nativeAppIsCompatible } } = this.props;

    // Counteract a user bookmarking the access code screen
    // Should the user attempt go directly to the access code screen when they already have a code (and did not manually nav here)...
    // Redirect them back to login
    if (initialFromRoute === '/login/access-code' && !manualAccessCodeNav && existingCode) {
      return <Redirect to="/login"/>;
    }

    return (
      <div className={styles.AccessCode}>
        {(loading && !loginMessage) ? (

          <Spinner
            name={SpinnerNames.BallScaleRipple}
            color="white"
          />

        ) : (
          <>
            <TextInput
              placeholder={t('Access Code')}
              value={accessCode}
              onChange={this.handleAccessCodeChange}
              jsonPath="access-code-input"
              onEnterKey={this.validateAccessCode}
              wrapperClass={styles.TextInput}
              showClear
              focusOnMount={nativeAppIsCompatible}
              autoCapitalize="off"
              autoComplete="off"
              onBlur={this.handleBlur}
            />
            <button disabled={!accessCode} className={styles.Validate} onClick={this.validateAccessCode} data-test="access-code-validate">{t('Validate Access Code')}</button>
            {existingCode != null ? <button className={styles.Return} onClick={this.returnToLogin} data-test="return-to-login">{t('Return to Login')}</button> : null}
            <button
              id="language-selector"
              data-test="language-selector"
              className={`${appStyles.DropDownBtn} ${styles.Return}`}
              onClick={handleLangClick}
              ref={langRef}
            >
              {locale}
            </button>
          </>
        )
        }
      </div>
    );
  }

  public handleBlur = (): void => {
    // Combat an iOS issue where the keyboard from the previous screen has shifted the web view up, and it does not come back down
    // This results in a white box at the bottom of the screen.
    if (process.env.MOBILE_APP) {
      window.scrollTo(0, 0);
    }
  };

  public handleAccessCodeChange = (value): void => {
    this.setState({
      accessCode: value,
    });

    if (process.env.MOBILE_APP && value === '_ui_') {
      this.props.showUILoader();
    }
  };

  public validateAccessCode = (): Promise<any> => {
    const { accessCode } = this.state;
    const {
      loggedInUserStore,
      existingCode,
      validateAccessCode,
      mobileBridgeStore: {
        toggleUseAsHub,
        mobileAppStartUp,
        debugMobile,
        log,
        ldxConnectData: { useAsHub },
      },
    } = this.props;

    // If the user navigates to this screen by pressing enter on a focused 'Change Access Code' button
    // This method also fires due to the onEnterKey handler on the TextInput
    // Therefore prevent the access code validation when there is not yet any input
    if (accessCode === '') { return; }

    this.setState({
      loading: true,
    });

    loggedInUserStore.setLoginMessage(null);

    return validateAccessCode(accessCode)
      .then((accessDto: IQAccessDto) => {
        const { AccessCode } = accessDto;

        if (process.env.MOBILE_APP) {
          if (existingCode !== AccessCode && useAsHub) {
            if (debugMobile) {
              log(`Access code changed from ${existingCode} to ${AccessCode} AND us as hub was on, so turning it off.`)
            }
            toggleUseAsHub(false);
          }
        }
        return accessDto;
      })
      .then(process.env.MOBILE_APP ? mobileAppStartUp : (accessDto: IQAccessDto) => accessDto);
  };

  public returnToLogin = (): void => {
    const { loggedInUserStore: { accessDto }, existingCode } = this.props;

    if (accessDto === null && existingCode) {
      this.setState({ accessCode: existingCode }, this.validateAccessCode);
    }
    else {
      this.props.history.replace('/login');
    }

  };
}
