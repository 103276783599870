import * as React from 'react';

import { IValidationError, IBaseInputProps, IValidateOptions } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';
const styles = require('./styles/type_ahead.styl');
const inputStyles = require('./styles/input.styl');

import TypeAheadValue from './TypeAheadValue';
import TypeAheadOptionList from './TypeAheadOptionList';

export interface ITypeAheadProps extends IBaseInputProps {
  className?: string;
  minLength?: number;
  searchInterval?: number;
  maxContainerHeight?: number;
  focusOnMount?: boolean;
  validate?: (options: IValidateOptions) => IValidationError;
  handleValidation: (value: boolean) => any;
  onSelect: (option: any) => any;
  onClose?: () => any;
  onSearch: (term?: string) => any;
  onClearSearch: () => any;
  options: any[];
  zIndex?: number;
  disabled?: boolean;
  clearInput?: () => any;
  maxLength?: number;
  jsonPath?: string;
  tabIndex?: number;
  isInPopover?: boolean;
  placeholder?: string;
  showNoResults?: boolean;
  returnFullObjects?: boolean;
  labelField?: string;
  valueField?: string;
  selectedValue: any;
  maxChars?: number;
  isRequired?: boolean;
  clearTypeaheadInput?: boolean;
  formLabel?: string;
  ariaLabel?: string;
  noResultsText?: string;
}

export interface ITypeAheadState {
  loading?: boolean;
  value?: string | number;
  showErrors: boolean;
  focused: boolean;
  hasBlurred: boolean;
}

/**
 * @param options
 * Array of items that are printed in the results list
 *
 * @param value
 * Value of input field
 *
 * @param searchInterval
 * Number in milliseconds before firing onSearch, after user finishes typing
 *
 * @param onChange
 * Function that is fired when the value of the input changes
 *
 * @param onSearch
 * Function that is fired when the timer triggers after the set searchInterval
 *
 * @param onClearSearch
 * Function that is fired when the search results are cleared
 *
 * @param onSelect
 * Function that is fired when a value is selected from the search results
 *
 * @param handleValidation
 * Method that determines whether to validate the input field
 *
 * @param className
 * CSS class applied to form wrapper
 *
 * @param placeholder
 * Default placeholder value of text input
 *
 * @param maxContainerHeight
 * The maximum height of the typeahead results container. The resultConfig.height property should be evenly divisible into maxContainerHeight
 *
 * @param validation
 * a method that takes the value and returns an arry of validation objects
 * always return an empty array for a valid value
 * see the validation store for more documentation on validation objects
 *
 * @param zIndex
 * Default style is 10. Optionally pass higher number to cover typeaheads on the same page.
 *
 * @param disabled
 *
 * @param selectedValue
 * Value of the selected search results
 *
 * @param isRequired
 * Value is required - when isRequired it false the user does not need to have a value in the typeahead
 *
 * @param clearTypeaheadInput
 * Value if true will clear input box value on typeahead
 *
 * @param noResultsText
 * String to display when there are no results to a search
 */
export default class TypeAhead extends React.Component<ITypeAheadProps, ITypeAheadState> {
  private typeAheadInput: React.RefObject<HTMLInputElement>;
  public anchor: React.RefObject<HTMLDivElement> = React.createRef();
  public focusBtn: React.RefObject<HTMLButtonElement> = React.createRef();
  public errorAnchor: HTMLElement;

  constructor(props) {
    super(props);
    this.typeAheadInput = React.createRef();
  }

  public static defaultProps = {
    className: '',
    options: [],
    minLength: 1,
    searchInterval: 300,
    maxContainerHeight: 175,
    focusOnMount: false,
    disabled: false,
    labelField: 'label',
    valueField: 'value',
    returnFullObjects: true,
    isRequired: true,
    placeholder: null,
    formLabel: null,
    ariaLabel: null,
    noResultsText: 'No matching results',
    showNoResults: true,
  };

  public state = {
    loading: false,
    value: '',
    showErrors: false,
    focused: false,
    hasBlurred: false,
  };

  public searchTimer: any;

  public componentDidUpdate(prevProps): void {

    const { flashErrors, handleValidation, clearTypeaheadInput } = this.props;
    const { value } = this.state;

    // if value and flashErrors is true and does not equal previous flashErrors
    if (prevProps.flashErrors !== flashErrors && flashErrors && value.length > 0) {
      // handle validation of typeahead
      handleValidation(true);
    }

    // if clearTypeaheadInput is true and does not equal previous clearTypeaheadInput
    if (prevProps.clearTypeaheadInput !== clearTypeaheadInput && clearTypeaheadInput) {
      // handle validation of typeahead
      this.handleClearInput();
    }

  }

  public render(): JSX.Element {
    const {
      options,
      placeholder,
      onKeyDown,
      zIndex,
      disabled,
      jsonPath,
      tabIndex,
      validate,
      validation,
      returnFullObjects,
      labelField,
      valueField,
      selectedValue,
      flashErrors,
      formLabel,
      ariaLabel,
      noResultsText,
      showNoResults,
      minLength,
    } = this.props;

    const {
      loading = false,
      value,
      showErrors,
      focused,
      hasBlurred,
    } = this.state;

    const error = validate({ value, validation, anchor: this.errorAnchor });

    // check to see if the typeahead has errors
    const isValid = error.messages.length === 0 || value.length === 0 || !hasBlurred;

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    const errorButtonClass = `${inputStyles.FieldErrorsShow} ${inputStyles.TypeAhead}`;

    let className = `${styles.TypeAhead} ${inputStyles.FieldWrap}`;

    // this is showing the error with in the input box
    if (!isValid) {
      className += ` ${inputStyles.Invalid}`;
    }

    // show a selected value
    const selected = selectedValue !== null ?
      (
        <TypeAheadValue
          key={selectedValue[valueField] || selectedValue}
          value={selectedValue}
          labelField={labelField}
          disabled={disabled}
          onRemove={this.onRemove}
        />
      ) : null;

    // show a results list
    const resultsList = focused && !loading && (options.length > 0 || (showNoResults && value.length >= minLength)) ? (
      <TypeAheadOptionList
        jsonPath={jsonPath}
        options={options}
        onSelect={this.onSelect}
        labelField={labelField}
        valueField={valueField}
        returnFullObjects={returnFullObjects}
        anchor={this.typeAheadInput.current}
        noResultsText={noResultsText}
      />
    ) : null;

    // create aria label - ariaLabel then placeholder text then formLabel text
    const inputAriaLabel = ariaLabel !== null ? ariaLabel :
      placeholder !== null ? placeholder :
        formLabel !== null ? formLabel : null;

    return (
      <div className={className} onClick={this.handleClick} style={{ zIndex }}>
        {selectedValue === null ? <input
          ref={this.typeAheadInput}
          className={className}
          data-test={jsonPath}
          id={jsonPath}
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={this.handleChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          tabIndex={tabIndex}
          aria-label={inputAriaLabel}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          autoComplete="off"
        /> : null}
        {selected}
        {resultsList}
        {selectedValue === null ? <div
          className={errorButtonClass}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
        /> : null}
        {errorPvr}
      </div>
    );
  }

  public handleClick = (e): void => {
    e.stopPropagation();
  };

  public handleChange = (e): void => {
    const newValue = e.target.value;
    const { handleValidation, isRequired } = this.props;
    const { value } = this.state;

    // IE fires change events when the field is focused
    // this just ensures that state onlt changes when the data actually change
    if (newValue === value) { return; }

    this.setState({
      value: newValue,
    });

    // when the user types we must start searching
    this.executeSearch(newValue);

    // check for validation
    if (isRequired || newValue.length > 0) {
      handleValidation?.(true);
    } else {
      handleValidation?.(false);
    }

  };

  public executeSearch(term) {
    const { onSearch, onClearSearch, minLength, searchInterval } = this.props;
    const { length } = term;
    if (this.searchTimer != null) { clearInterval(this.searchTimer); }

    if (length === 0) {
      onClearSearch();
      this.setState({ value: '', loading: false });
      return;
    } else if (length < minLength) {
      // clear the search results
      onClearSearch();
      return;
    } else {
      if (!this.state.loading) { this.setState({ loading: true }); }
      return this.searchTimer = setTimeout(() => {
        return (typeof onSearch === 'function' ? onSearch(term).then(this.setState({ loading: false })) : undefined);
      }, searchInterval);
    }
  }

  public onSelect = (newValue): void => {
    const { onClearSearch, onSelect } = this.props;
    const { returnFullObjects, valueField } = this.props;

    // get the selected value
    newValue = returnFullObjects ? newValue : newValue[valueField];
    // set the selected value
    onSelect(newValue);

    // clear out input
    this.setState({
      value: '',
      hasBlurred: false,
    });

    // since the user has selected an item - clear the search results
    onClearSearch();
  };

  public handleClearInput = (): void => {

    // clear out input
    this.setState({
      value: '',
    });

  };

  public onRemove = (removedValue): void => {
    const { onSelect } = this.props;

    // set the selected value to null
    onSelect(null);
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  public handleFocus = (): void => {
    this.setState({
      focused: true,
    });
  };

  public handleBlur = (): void => {
    // Need to delay the blur so the option don't disappear before
    // before the user's click is registered
    setTimeout(() => {
      this.setState({
        focused: false,
        hasBlurred: true,
      });
    }, 150);
  };
}
