import * as React from 'react';
import Spinner from './Spinner';
import { IBaseInputProps, IValidateOptions, IValidationError, IInputKeyboardProps, IKeyboard } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';

const styles = require('./styles/input.styl');

export interface ITextareaProps extends IBaseInputProps {
  handleKeyUp?: (e) => void;
  clear?: () => void;
  clearBtnClass?: string;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
  rows?: number;
  cols?: number;
  keyboard?: IKeyboard;
}

export interface IState {
  showErrors: boolean;
}

export default class Textarea extends React.Component<ITextareaProps, IState> {
  public static defaultProps = {
    className: 'textarea',
    id: null,
    name: null,
    placeholder: '',
    wrapperClass: null,
    wrapperLabel: null,
    loading: false,
    showClear: false,
    tabIndex: null,
    maxLength: null,
    onKeyDown: null,
    onKeyPress: null,
    onFocus: null,
    onBlur: null,
    onKeyUp: null,
    onEnterKey: null,
    onChange: null,
    disabled: false,
    autoComplete: false,
    validation: false,
    isInPopover: false,
    delayedActionOnChange: null,
    spellCheck: true,
    style: null,
    focusOnMount: false,
    selectOnMount: false,
    rows: 4,
    cols: 50,
    forceShowAllErrors: false,
    keyboard: null,
  };

  public state = {
    showErrors: false,
  };

  private errorAnchor: HTMLElement;
  private input: React.RefObject<HTMLTextAreaElement>;

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  public componentDidMount(): void {
    const { focusOnMount, selectOnMount } = this.props;

    const curInput = this.input.current;
    if (focusOnMount) {
      curInput.focus();
      curInput.setSelectionRange(curInput.value.length, curInput.value.length);
    }
    if (selectOnMount) { curInput.select(); }

    curInput.addEventListener('touchmove', this.stopProp);

  }

  public componentWillUnmount() {
    this.input.current.removeEventListener('touchmove', this.stopProp);
  }

  public stopProp = (e: TouchEvent): void => {
    const { scrollHeight, clientHeight } = this.input.current;

    if (scrollHeight > clientHeight) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
  };

  public render() {
    let textareaClass;
    const { name, value, placeholder, tabIndex, className, maxLength, loading, showClear, onKeyDown,
      onKeyPress, onBlur, wrapperClass, wrapperLabel, id, disabled, validation, valueHasChanged,
      spellCheck, validate, style, forceShowAllErrors, rows, cols, flashErrors, jsonPath, keyboard, clearBtnClass } = this.props;
    let { autoComplete } = this.props;
    const { showErrors } = this.state;
    const error = validate({ value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;
    const val = value as string;

    let outerClass = `${styles.FieldWrap}`;
    if (wrapperClass != null) { outerClass += ` ${wrapperClass}`; }
    if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.Invalid} ${styles.Shrink}`; }

    if (className != null) { textareaClass = className; }
    if (loading) { textareaClass += ` ${styles.TextLoadingSpinner}`; }

    let clearClass = styles.SearchClear;
    if (clearBtnClass) {
      clearClass = clearBtnClass;
    }

    const errorButtonClass = styles.FieldErrorsShow;

    // Autocomplete
    autoComplete = (() => { switch (typeof autoComplete) {
      case 'string': return autoComplete;
      case 'boolean':
        if (autoComplete) { return 'on'; } else { return 'off'; }
    } })();

    let textArea = (
      <textarea
        key="textArea"
        ref={this.input}
        name={name || keyboard?.inputName}
        id={id}
        className={textareaClass}
        value={val}
        data-test={jsonPath}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        tabIndex={tabIndex}
        onChange={this.handleChange}
        onKeyUp={this.handleKeyUp}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        onFocus={this.handleFocus}
        autoComplete={autoComplete}
        spellCheck={spellCheck}
        rows={rows}
        cols={cols}
        onTouchStart={this.handleErrorTouch}
      />
    );

    // Add a wrapper label element if label prop is present
    if (wrapperLabel) {
      textArea = (
        <label key="textAreaLabel">
          {wrapperLabel}
          {textArea}
        </label>
      );
    }

    const loadingIndicator = loading ? <Spinner /> : null;

    const clearBtn = (val != null ? val.length : undefined) && showClear ? (
      <button
        className={clearClass}
        title="Clear Search"
        key="searchClearBtn"
        onClick={this.handleClear}
        tabIndex={-1}
        onMouseDown={this.preventDefault}
      />
    ) : void 0;

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    return (
      <div
        className={outerClass}
        style={style}
      >
        {textArea}
        {loadingIndicator}
        {clearBtn}
        <div
          className={errorButtonClass}
          ref={(el: HTMLElement) => this.errorAnchor = el}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
          onTouchStart={this.handleErrorTouch}
        />
        {errorPvr}
      </div>
    );
  }

  private handleClear = (): void => {
    const { clear, keyboard } = this.props;
    clear();

    if (keyboard) {
      const event = new CustomEvent('clear', {detail: {inputName: keyboard.inputName}});
      this.input?.current?.dispatchEvent(event);
    }
  };

  private handleChange = (e): void => {
    const { onChange } = this.props;
    const { value } = e.target;
    onChange(value);
  };

  private handleFocus = (e): void => {
    const { onFocus, keyboard } = this.props;

    const kbProps: IInputKeyboardProps = keyboard ? {
      ...keyboard,
      onChange: this.handleChange,
    } : null;

    onFocus?.(e, kbProps);

  };

  private handleKeyUp = (e): void => {
    const { onKeyUp } = this.props;
    const { value } = e.target;
    onKeyUp(value);
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    const { valueHasChanged, forceShowAllErrors } = this.props;
    if (!valueHasChanged && !forceShowAllErrors) { return; }

    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

  public getValue(): string {
    return this.input.current.value;
  }

  public preventDefault = (e): void => {
    e.preventDefault();
  };
}
