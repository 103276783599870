import * as React from 'react';
import { Redirect } from 'react-router';
import { Modal, GridFormInput, ScrollContainer } from '@Lineup/index';
import { inject, observer } from 'mobx-react';
import { InputTypes, isValidEmail, SaveStates, updateFormValue, ButtonType } from '../../../LDX-Lineup/enums/index';
import { IComponent, InjectedComponent, IUser } from '../../global/interfaces';
import FormErrorTracker from '../../helpers/ErrorTracker';
import { FACTORY_CODE } from '../../global/constants';
import Form from '../../../LDX-Lineup/Form';
import ResetPassword from './ResetPassword';

const gridStyles = require('@Lineup/styles/grid.styl');
const modalStyles = require('@Lineup/styles/modal.styl');
const formStyles = require('@Lineup/styles/form.styl');

interface IState {
  redirect?: string;
  editUser?: IUser;
  saveState?: SaveStates;
  saveMessage?: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
  unSavedChanges: boolean;
  passwordOpen: boolean;
}

interface IProps extends IComponent {
  toggleProfile?: (open: boolean) => void;
  asModal?: boolean;
  accessCode?: string;
}

@inject('loggedInUserStore', 'systemStore', 'applicationStore') @observer
export default class Profile extends InjectedComponent<IProps, IState> {
  private errorTracker: FormErrorTracker = new FormErrorTracker();

  constructor(props: IProps) {
    super(props);

    this.state = {
      forceShowAllErrors: false,
      flashErrorPvrs: 0,
      editUser: { ...{}, ...props.loggedInUserStore.user },
      saveState: null,
      saveMessage: null,
      unSavedChanges: false,
      passwordOpen: false,
    };
  }

  public componentDidMount(): void {
    const { getLanguages } = this.props.systemStore;
    getLanguages();
  }

  public render(): JSX.Element {

    const { accessCode, systemStore } = this.props;
    const { redirect, editUser, saveState, saveMessage, forceShowAllErrors, flashErrorPvrs, unSavedChanges, passwordOpen } = this.state;
    const { username, email, salutation, firstName, lastName, initials, languageId } = editUser;
    const { registerError } = this.errorTracker;
    const { languages, orgSettings } = systemStore;
    const { locale } = this.props.applicationStore;
    const allowAdminManagedPasswords = orgSettings.get('AllowAdminManagedPasswords');

    if (redirect) {
      return (<Redirect to={redirect} />);
    }

    const languageOptions = [];
    for (const [id, lang] of languages.entries()) {
      languageOptions.push({
        value: id,
        label: t(lang.Name),
      });
    }

    languageOptions.sort((a, b) => a.label > b.label ? 1 : -1);

    const usernameLabel = (
      <div className={`${gridStyles.FormGrid} ${gridStyles.ReadOnly}`}>
        <div className={gridStyles.Label}>
          <label className={gridStyles.FormLabel}>
            {t('Username')}
          </label>
        </div>
        <div className={gridStyles.ReadValue}>{username}</div>
      </div>
    );

    const firstNameContent = (
      <GridFormInput
        formLabel={t('First Name')}
        value={firstName}
        jsonPath="firstName"
        onChange={this.handleProfileUpdate}
        isFieldRequired={true}
        forceShowAllErrors={forceShowAllErrors}
        flashErrorPvrs={flashErrorPvrs}
        registerError={registerError}
        validation={(value: string) => {
          const messages = [];
          if (!value.length) { messages.push(t('Cannot be empty')); }
          return messages.length ? { messages } : null;
        }}
      />
    );

    const lastNameContent = (
      <GridFormInput
        formLabel={t('Last Name')}
        value={lastName}
        jsonPath="lastName"
        onChange={this.handleProfileUpdate}
        isFieldRequired={true}
        forceShowAllErrors={forceShowAllErrors}
        flashErrorPvrs={flashErrorPvrs}
        registerError={registerError}
        validation={(value: string) => {
          const messages = [];
          if (!value.length) { messages.push(t('Cannot be empty')); }
          return messages.length ? { messages } : null;
        }}
      />
    );

    const content = (
      <ScrollContainer className={this.props.asModal ? modalStyles.ModalContent : formStyles.FormContent}>

        {accessCode === FACTORY_CODE ? null : usernameLabel}
        <GridFormInput
          formLabel={t('Email')}
          value={accessCode === FACTORY_CODE ? username : email}
          jsonPath={accessCode === FACTORY_CODE ? 'username' : 'email'}
          onChange={this.handleProfileUpdate}
          isFieldRequired={!allowAdminManagedPasswords}
          registerError={registerError}
          forceShowAllErrors={forceShowAllErrors}
          flashErrorPvrs={flashErrorPvrs}
          validation={(function() {
            const messages = [];
            if (accessCode !== FACTORY_CODE) {
              if (!allowAdminManagedPasswords && !email.length) { messages.push(t('Cannot be empty')); }
              else if (email.length && !isValidEmail(email)) { messages.push(t('Invalid email format')); }
            }
            else if (accessCode === FACTORY_CODE) {
              if (!username.length) { messages.push(t('Cannot be empty')); }
              else if (username.length && !isValidEmail(username)) { messages.push(t('Invalid email format')); }
            }
            return messages.length ? { messages } : null;
          })()}
        />
        <GridFormInput
          formLabel={t('Title')}
          value={salutation}
          jsonPath="salutation"
          maxLength={5}
          onChange={this.handleProfileUpdate}
          forceShowAllErrors={forceShowAllErrors}
          flashErrorPvrs={flashErrorPvrs}
          validation={(value: string = '') => {
            const messages = [];
            if (value.length > 5) { messages.push(t('Maximum length is __count__ characters', { count: 5 })); }
            return messages.length ? { messages } : null;
          }}
        />
        {(locale === 'ja') ?
          <>
            {lastNameContent}
            {firstNameContent}
          </> :
          <>
            {firstNameContent}
            {lastNameContent}
          </>
        }
        {(locale !== 'ja') ?
          <GridFormInput
            formLabel={t('Initials')}
            value={initials}
            jsonPath="initials"
            onChange={this.handleProfileUpdate}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            maxLength={2}
            validation={(value: string = '') => {
              const messages = [];
              if (value.length > 2) { messages.push(t('Maximum length is __count__ characters', { count: 2 })); }
              return messages.length ? { messages } : null;
            }}
          /> : null
        }
        {accessCode === FACTORY_CODE ? null : (
          <GridFormInput
            formLabel={t('Language')}
            inputType={InputTypes.SELECT}
            value={languageId}
            jsonPath="languageId"
            onChange={this.handleProfileUpdate}
            options={languageOptions}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            selectText={t('Select from list...')}
            placeholder={t('Select language')}
          />
        )}

      </ScrollContainer>);

    return !this.props.asModal ? (
      <div className={formStyles.FormContainer}>
        <Form
          title={t('Reset password')}
          buttons={[
            {
              name: t('Reset'),
              handler: () => this.togglePassword(true),
              type: ButtonType.ACTION,
            },
          ]} />
        <Form
          title={t('Profile')}
          onSaveComplete={this.resetSavedState}
          saveState={saveState}
          saveMessage={saveMessage}
          onSaveFail={() => this.setState({ saveState: null, saveMessage: null })}
          unSavedChanges={unSavedChanges}
          buttons={[
            {
              name: t('Apply Changes'),
              handler: this.handleProfileSave,
              type: ButtonType.ACTION,
            },
          ]}>{content}
        </Form>
        {passwordOpen ? <ResetPassword togglePassword={this.togglePassword} /> : null}
      </div>
    ) : (
      <Modal
        close={this.close}
        title={t('Profile')}
        onSaveComplete={this.close}
        saveState={saveState}
        saveMessage={saveMessage}
        onSaveFail={() => this.setState({ saveState: null, saveMessage: null })}
        unSavedChanges={unSavedChanges}
        closeAfterSave={false}
        buttons={[
          {
            name: t('Apply Changes'),
            handler: this.handleProfileSave,
            type: ButtonType.ACTION,
          },
        ]}>{content}
      </Modal>
    );
  }

  public togglePassword = (open: boolean): void => {
    this.setState({passwordOpen: open});
  };

  public resetSavedState = (): void => {
    this.setState({
      saveState: null,
    });
  };

  public close = (): void => {
    if (this.props.toggleProfile) {
      this.props.toggleProfile(false);
    }
  };

  public handleProfileSave = () => {
    const { saveCurrentUser, updateCurrentUser } = this.props.loggedInUserStore;
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    this.setState({
      saveState: SaveStates.PENDING,
    });

    saveCurrentUser(this.state.editUser)
      .catch(err => {
        this.setState({
          saveMessage: err.ErrorMessage || t('Server Error. Unable to save your profile.'),
          saveState: SaveStates.FAILED,
        });
        return Promise.reject(err);
      })
      .then(data => {
        this.setState({
          saveState: SaveStates.COMPLETE,
        });
        updateCurrentUser(data);
      });
  };

  public handleProfileUpdate = (value: string, jsonPath: string) => {
    const { editUser } = this.state;
    const newData = updateFormValue(editUser, jsonPath, value) as IUser;

    this.setState({
      editUser: newData,
      unSavedChanges: true,
    });
  };
}
