import * as React from 'react';

export interface INoAccessProps {
  displayText?: string;
  styleOverrides?: object;
}

export default class NoAccess extends React.Component<INoAccessProps, any> {

  public render(): JSX.Element {
    const { styleOverrides } = this.props;
    let { displayText } = this.props;
    const { pathname } = window.location;

    const appName = (() => {
      if (pathname.search('/admin') > -1) {
        return 'System Manager';
      } else if (pathname.search('/portal') > -1) {
        return 'Engage';
      } else { return 'Consult'; }
    })();

    if (!displayText) { displayText = `Your user role does not contain the required permissions to use this feature of ${appName}.`; }

    let styles = {
      fontSize: '16px',
      backgroundPosition: 'center 150px',
      top: 0,
      paddingTop: 230,
    };

    if (styleOverrides != null) { 
      styles = {
        ...styles,
        ...styleOverrides,
      }; 
    }

    return (
      <div className="no-access" style={styles}>
        <div key="message" className="no-access-message">{displayText}</div>
      </div>
    );
  }
}
