import * as React from 'react';

export enum AlertTypes {
  DELETE,
  WARNING,
  AGREE,
  DOWNLOAD
}

export interface IAlertIconProps {
  type: AlertTypes;
}

export default class AlertIcon extends React.Component<IAlertIconProps, any> {
  public static defaultProps: IAlertIconProps = {
    type: AlertTypes.WARNING,
  };

  public render(): JSX.Element {
    const { type } = this.props;

    switch (type) {
      case AlertTypes.DELETE:
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(1 1)"
              strokeWidth="1.5"
              stroke="#EC1A37"
              fill="none"
            >
              <circle cx="11" cy="11" r="11" />
              <path d="M6.448 7.207l9.104 8.345" />
              <path d="M15.625 7.29l-9.25 8.18" />
            </g>
          </svg>
        );
      case AlertTypes.WARNING:
        return (
          <svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              transform="translate(-1 -1)"
              fill="none"
            >
              <path
                d="M11.116 4.532c1.593-2.503 4.176-2.502 5.768 0l8.232 12.936C26.71 19.97 25.6 22 22.634 22H5.366c-2.964 0-4.074-2.03-2.482-4.532l8.232-12.936z"
                stroke="#F7A138"
                strokeWidth="1.788"
                fill="#fff"
                strokeLinecap="round"
              />
              <path
                d="M14 7.615v6.77"
                stroke="#F7A138"
                strokeWidth="1.788"
                strokeLinecap="square"
              />
              <ellipse
                fill="#F7A138"
                cx="14"
                cy="17.769"
                rx="1.75"
                ry="1.692"
              />
            </g>
          </svg>
        );
      case AlertTypes.AGREE:
        return (
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="31px"
            height="27px" 
            viewBox="0 0 31 27"
          >
            <path 
              fill="none"
              stroke="#bebebe"
              strokeWidth="2.6662"
              strokeLinecap="round"
              strokeMiterlimit="10"
              d="M15.3 23.1h-13M15.3 16.5h-13M28.3 10h-26M28.3 3.5h-26"
            />
            <ellipse 
              cx="23.1"
              cy="19.2"
              rx="5.7"
              ry="5.7"
              fill="#7bc142" 
            />
            <path 
              d="M18.7 19.9l1-1 1.9 1.9 4.8-4.9 1.1 1.1-5.8 6-3-3.1z"
              fill="#fff9f6"
            />
          </svg>
        );
      case AlertTypes.DOWNLOAD:
        return (
          <svg 
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
          >
            <path 
              fill="#BEBEBE"
              d="M16.8 1c-4.3 0-8 3-8.8 7-3.8 0-7 3.2-7 7 0 1.3.4 2.5 1 3.5h9.5v-1.8c0-2.9 2.3-5.2 5.2-5.2s5.2 2.3 5.2 5.2v1.8h6.7c.2-.6.3-1.1.3-1.8 0-2.3-1.5-4.5-3.5-5.2V9.8c.1-4.9-3.8-8.8-8.6-8.8z" 
            />
            <path
              fill="#7BC142"
              d="M16.2 15c-.7.3-1.2 1-1.2 1.8V22H9.8l7 7 7-7h-5.2v-5.2c0-1-.8-1.8-1.7-1.8h-.7z"
            />
          </svg>
        );
    }
  }
}
