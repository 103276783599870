module.exports = {
  appName: 'LumiraDx App',
  INTERNAL_DIAGNOSTIC_SYSTEM: 'LumiraDxDiagnostics',
  SPINE_AUTHORITY: 'WELL_WELLOGIC',
  API_DATE_FORMAT: 'YYYY-MM-DDTHH:mm:ss.SSSZZ',
  menuBreakPoint: '1024px',
  tablet: '1023px',
  phone: '767px',
  phonePortraitWide: '425px',
  sessionTimeout: (1000 * 60) * 30,
  // Amira Colors
  amiraPurple: 'rgb(115,43,134)',
  // Colors
  lumiraBlue: 'rgb(0, 151, 213)',
  backgroundBlue: 'rgb(23, 151, 214)',
  blueBorder: 'rgb(55,161,219)',
  darkBlueBtn: 'rgb(7,74,121)',
  backgroundBlueGradient: 'linear-gradient(-125deg, #0F95CC 0%, #05CCEA 80%)',
  hoverblue: 'rgb(230,242,255)',
  WarningOrange: 'rgb(245,166,35)',
  ErrorRed: 'rgb(249,75,72)',
  easeOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  // Test Type Colors
  Coagulation_INR: 'rgb(208,72,147)',
  Infectious_Disease: 'rgb(36,183,195)',
  Cardiology: 'rgb(230,27,91)',
  Diabetes: 'rgb(239,125,23)',
  Chemistry: 'rgb(0,74,121)',
  Blood_Gas_Electrolytes: 'rgb(252,188,0)',
  Molecular_NAT: 'rgb(149,193,31)',
  Toxicology: 'rgb(11,187,239)',
  // Grays
  AlertRed: 'rgb(208,2,27)',
  BoxShadowGray: 'rgb(207,211,213)',
  Gray100: 'rgb(25, 25, 25)',
  Gray90: 'rgb(50, 50, 50)',
  Gray85: 'rgb(64, 64, 64)',
  Gray80: 'rgb(75, 75, 75)',
  Gray78: 'rgb(82, 82, 82)',
  Gray70: 'rgb(100, 100, 100)',
  Gray60: 'rgb(125, 125, 125)', // Form Labels'
  Gray55: 'rgb(140, 140, 140)',
  Gray50: 'rgb(150, 150, 150)',
  Gray45: 'rgb(165, 165, 165)',
  Gray40: 'rgb(175, 175, 175)',
  Gray35: 'rgb(190, 190, 190)',
  Gray30: 'rgb(200, 200, 200)', // Disabled Inputs'
  Gray29: 'rgb(204,204,204)', // No Results
  Gray28: 'rgb(209, 209, 209)',
  Gray20: 'rgb(225, 225, 225)',
  Gray16: 'rgb(230, 230, 230)',
  Gray12: 'rgb(235, 235, 235)',
  Gray9: 'rgb(238, 238, 238)',
  Gray8: 'rgb(240, 240, 240)',
  Gray6: 'rgb(243, 243, 243)',
  Gray4: 'rgb(245, 245, 245)',
  Gray2: 'rgb(248, 248, 248)',
  Gray1Cool: 'rgb(249, 249, 251)',
  Gray1: 'rgb(250, 250, 250)',
};
