module.exports = {
  "__appName__ is not currently available.": "__appName__ ei ole toistaiseksi saatavilla.",
  "Access Code": "Käyttöoikeuskoodi",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "${this.username}-tili on lukittu viiden kirjautumisyrityksen vuoksi. Ota yhteyttä järjestelmänvalvojaan tilin avaamiseksi",
  "Afghanistan": "Afganistan",
  "Albania": "Albania",
  "Algeria": "Algeria",
  "American Samoa": "Amerikan Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua ja Barbuda",
  "App": "Sovellus",
  "Application Error": "Sovellusvirhe",
  "Application error.": "Sovellusvirhe.",
  "Application is not currently available.": "Sovellus ei ole toistaiseksi käytettävissä.",
  "Apply Changes": "Tallenna muutokset",
  "Apr": "Huh",
  "April": "Huhtikuu",
  "Argentina": "Argentiina",
  "Armenia": "Armenia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Vähintään __value__ merkkiä",
  "At least one lowercase letter": "Ainakin yksi pieni kirjain",
  "At least one number": "Ainakin yksi numero",
  "At least one of these special characters: __value__": "Ainakin yksi näistä erikoismerkeistä: __value__",
  "At least one uppercase letter": "Ainakin yksi iso kirjain",
  "Aug": "Elo",
  "August": "Elokuu",
  "Australia": "Australia",
  "Austria": "Itävalta",
  "Azerbaijan": "Azerbaidžan",
  "Bahamas": "Bahama",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Valko-Venäjä",
  "Belgium": "Belgia",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth pois päältä",
  "Bolivia": "Bolivia",
  "Bosnia and Herzegovina": "Bosnia ja Hertsegovina",
  "Botswana": "Botswana",
  "Brazil": "Brasilia",
  "Bronchial Lavage": "BAL-näyte",
  "Brunei Darussalam": "Brunei",
  "Bulgaria": "Bulgaria",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Kambodža",
  "Cameroon": "Kamerun",
  "Canada": "Kanada",
  "Cancel": "Peruuta",
  "Cannot be empty": "Ei voi olla tyhjä",
  "Cape Verde": "Kap Verde",
  "Capillary Blood": "Kapillaariveri",
  "Cayman Islands": "Caymansaaret",
  "Central African Republic": "Keski-Afrikan tasavalta",
  "Cerebrospinal Fluid": "Selkäydinneste",
  "Cervical Swab": "Kohdunkaulanäyte",
  "Chad": "Tšad",
  "Change Access Code": "Muuta käyttöoikeuskoodia",
  "Check again": "Tarkista uudelleen",
  "Chile": "Chile",
  "China": "Kiina",
  "Clear Search": "Tyhjennä haku",
  "Close": "Sulje",
  "Cocos(Keeling) Islands and Chr": "Kookos (Keeling) -saaret ja Joulusaaret",
  "Colombia": "Kolumbia",
  "Comoros": "Komorit",
  "Confirm": "Vahvista",
  "Confirm New Password": "Vahvista uusi salasana",
  "Confirm new password": "Vahvista uusi salasana",
  "Confirm New Password is required.": "Uuden salasanan vahvistus vaaditaan.",
  "Confirm Password is required.": "Salasanan vahvistus vaaditaan.",
  "Confirm Password must match New Password": "Vahvista salasana -kohdan tulee olla sama kuin Uusi salasana",
  "Congo(Brazzaville)": "Kongon tasavalta",
  "Congo(Kinshasa)": "Kongon demokr. tasavalta",
  "Cook Islands": "Cookinsaaret",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ – LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ – LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Norsunluurannikko",
  "Croatia(Hrvatska)": "Kroatia",
  "Cuba": "Kuuba",
  "Culture Broth": "Elatusaine",
  "Current Password": "Nykyinen salasana",
  "Current Password is required.": "Nykyinen salasana vaaditaan.",
  "Current password required": "Nykyinen salasana vaaditaan",
  "Cyprus": "Kypros",
  "Czech Republic": "Tšekki",
  "Danish": "tanska",
  "Dec": "Jou",
  "December": "Joulukuu",
  "Denmark": "Tanska",
  "Disabled": "Pois käytöstä",
  "Discard Changes": "Hylkää muutokset",
  "Dismiss": "Hylkää",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Ei vastaa aiempia salasanoja",
  "Does not use unsupported characters": "Ei sisällä merkkejä, joita ei tueta",
  "Domain": "Verkkotunnus",
  "Domain is required.": "Verkkotunnus vaaditaan.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominikaaninen tasavalta",
  "Door Open": "Luukku auki",
  "Dutch": "hollanti",
  "Ecuador": "Ecuador",
  "Egypt": "Egypti",
  "El Salvador": "El Salvador",
  "Email": "Sähköposti",
  "Email is required.": "Sähköposti vaaditaan.",
  "English (UK)": "englanti (UK)",
  "English (US)": "englanti (US)",
  "Enter": "Valmis",
  "Equatorial Guinea": "Päiväntasaajan Guinea",
  "Eritrea": "Eritrea",
  "Error": "Virhe",
  "Estonia": "Viro",
  "Ethiopia": "Etiopia",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager ei tuettu Connect-mobiilisovelluksessa.",
  "Failed to reset password. A server error was encountered": "Salasanan nollaus epäonnistui. Havaittiin palvelinvirhe",
  "Falkland Islands(Malvinas)": "Falklandsaaret",
  "Faroe Islands": "Färsaaret",
  "Fatal Application Error": "Peruuttamaton sovellusvirhe",
  "Feb": "Hel",
  "February": "Helmikuu",
  "Fecal Specimen": "Ulostenäyte",
  "Fiji": "Fidži",
  "File type must be __fileType__": "Tiedostotyypin on oltava __fileType__",
  "Finland": "Suomi",
  "Finnish": "suomi",
  "First Name": "Etunimi",
  "Forgot your password?": "Unohditko salasanasi?",
  "Fr": "Pe",
  "France": "Ranska",
  "French": "ranska",
  "French Guiana": "Ranskan Guayana",
  "French Polynesia": "Ranskan Polynesia",
  "Gabon": "Gabon",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "saksa",
  "Germany": "Saksa",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Mene kohtaan __storeName__",
  "Greater than __value__ in __measure__": "Suurempi kuin __value__ / __measure__",
  "Greece": "Kreikka",
  "Greenland": "Grönlanti",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hkr viitealueen ulkopuolella",
  "Hide": "Piilota",
  "Hide password": "Piilota salasana",
  "Holy See(Vatican City State)": "Vatikaanivaltio",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hongkong",
  "Hungary": "Unkari",
  "Iceland": "Islanti",
  "India": "Intia",
  "Indonesia": "Indonesia",
  "Initials": "Nimikirjaimet",
  "Install": "Asenna",
  "Install Now": "Asenna nyt",
  "Instrument Not Level": "Instrument-laite ei vaakatasossa",
  "Insufficient Sample": "Riittämätön näyte",
  "Internet connection is offline.": "Internetyhteys ei ole käytössä.",
  "Invalid access code": "Käyttöoikeuskoodi ei kelpaa",
  "Invalid email format": "Sähköpostimuoto ei kelpaa",
  "Invalid File": "Tiedosto ei kelpaa",
  "Invalid Lot Cal File": "Erän kalibr.tiedosto ei kelpaa",
  "Invalid username or password.": "Käyttäjätunnus tai salasana ei kelpaa.",
  "Iran, Islamic Republic of": "Iran",
  "Iraq": "Irak",
  "Ireland": "Irlanti",
  "Israel": "Israel",
  "Italian": "italia",
  "Italy": "Italia",
  "Jamaica": "Jamaika",
  "Jan": "Tam",
  "January": "Tammikuu",
  "Japan": "Japani",
  "Japanese": "japani",
  "Jordan": "Jordania",
  "Jul": "Hei",
  "July": "Heinäkuu",
  "Jun": "Kes",
  "June": "Kesäkuu",
  "Kazakhstan": "Kazakstan",
  "KC Swab": "KC-näyte",
  "Kenya": "Kenia",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Pohjois-Korea",
  "Korea, Republic of(South)": "Etelä-Korea",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgisia",
  "Language": "Kieli",
  "Laos(Lao PDR)": "Laos",
  "Last Name": "Sukunimi",
  "Latvia": "Latvia",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Vähemmän kuin __value__ / __measure__",
  "Liberia": "Liberia",
  "Libya": "Libya",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Nestemäinen QC-testi",
  "Lithuania": "Liettua",
  "Loading more records...": "Ladataan lisää potilastietoja…",
  "Login": "Kirjaudu",
  "Logout": "Kirjaudu ulos",
  "Luxembourg": "Luxemburg",
  "Macao(SAR China)": "Macao",
  "Macedonia, Republic of": "Makedonian tasavalta",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Malesia",
  "Maldives": "Malediivit",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Maa",
  "March": "Maaliskuu",
  "Marshall Islands": "Marshallinsaaret",
  "Martinique": "Martinique",
  "Mauritania": "Mauritania",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Maksimipituus on __count__ merkkiä",
  "May": "Toukokuu",
  "Mexico": "Meksiko",
  "Micronesia, Federated States o": "Mikronesian liittovaltio",
  "Mo": "Ma",
  "Mobile Application Version": "Mobiilisovelluksen versio",
  "Moldova": "Moldova",
  "Monaco": "Monaco",
  "Mongolia": "Mongolia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marokko ja Länsi-Sahara",
  "Mouth Swab": "Suunäyte",
  "Mozambique": "Mosambik",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Nenäerite",
  "Nasal Swab": "Nasaalinäyte",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Alankomaat",
  "Netherlands Antilles": "Alankomaiden Antillit",
  "Never Connected": "Ei koskaan yhdistetty",
  "New Caledonia": "Uusi-Kaledonia",
  "New Password": "Uusi salasana",
  "New Password is invalid.": "Uusi salasana ei kelpaa.",
  "New Password is required.": "Uusi salasana vaaditaan.",
  "New password must match": "Uuden salasanan tulee olla sama",
  "New Version Required": "Uusi versio vaaditaan",
  "New Zealand": "Uusi-Seelanti",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Tässä ympäristössä ei ole sovellusmoduuleja. Ilmoita tästä ongelmasta järjestelmänvalvojalle.",
  "No easily guessable words": "Ei helposti arvattavia sanoja",
  "No more than __value__ characters": "Enintään __value__ merkkiä",
  "No region set": "Ei alueasetusta",
  "No repetitive characters (e.g. aaa)": "Ei toistuvia merkkejä (esim. aaa)",
  "No sequential characters (e.g. 123)": "Ei peräkkäisiä merkkejä (esim. 123)",
  "No Test Strip Detected": "Testiliuskaa ei havaita",
  "none": "Ei",
  "Norfolk Island": "Norfolkinsaari",
  "Northern Mariana Islands": "Pohjois-Mariaanit",
  "Norway(inc Svalbard and Jan May": "Norja (ml. Svalbard ja Jan Mayen)",
  "Norwegian": "norja",
  "Nov": "Mar",
  "November": "Marraskuu",
  "NP Aspirate": "nenänieluaspiraatti",
  "NP Swab": "Nenänielunäyte",
  "Oct": "Lok",
  "October": "Lokakuu",
  "Offline 1 day": "Ei verkossa 1 päivään",
  "Offline 1 hour": "Ei verkossa 1 tuntiin",
  "Offline 1 minute": "Ei verkossa 1 minuuttiin",
  "Offline 1 month": "Ei verkossa 1 kuukauteen",
  "Offline 1 year": "Ei verkossa 1 vuoteen",
  "Offline __days__ days": "Ei verkossa __days__ päivää",
  "Offline __hours__ hours": "Ei verkossa __hours__ tuntia",
  "Offline __minutes__ minutes": "Ei verkossa __minutes__ minuuttia",
  "Offline __months__ months": "Ei verkossa __months__ kuukautta",
  "Offline __years__ years": "Ei verkossa __years__ vuotta",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palestiina",
  "Panama": "Panama",
  "Papua New Guinea": "Papua-Uusi-Guinea",
  "Paraguay": "Paraguay",
  "Password": "Salasana",
  "Password requirements:": "Salasanaa koskevat vaatimukset:",
  "Peru": "Peru",
  "Philippines": "Filippiinit",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Puola",
  "Portugal": "Portugali",
  "Portuguese (Brazil)": "portugali (Brasilia)",
  "Portuguese (Portugal)": "portugali (Portugali)",
  "Profile": "Profiili",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Syötä seuraavat tiedot asettaaksesi salasanasi.  Vahvistuskoodi on lähetetty sähköpostiisi, jos voimassa.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Syötä sähköpostiosoite pyytääksesi salasanan nollausta. Väliaikainen vahvistuskoodi lähetetään sähköpostiisi.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "QC-liuos havaittu",
  "Rectal Swab": "Peräsuolinäyte",
  "Required Update": "Päivitys vaaditaan",
  "Reset": "Palauta",
  "Reset Password": "Nollaa salasana",
  "Reset password": "Nollaa salasana",
  "Resolution requirements": "Resoluutiovaatimukset",
  "Return to Login": "Palaa sisäänkirjautumiseen",
  "Romania": "Romania",
  "Russian Federation": "Venäjä",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Réunion ja Mayotte",
  "Sa": "La",
  "Saint Helena and Tristan da Cu": "Saint Helena ja Tristan da Cu",
  "Saint Kitts and Nevis": "Saint Kitts ja Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint Pierre ja Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent ja Grenadiinit",
  "Saliva": "Sylki",
  "Samoa": "Samoa",
  "Sample Detected": "Näyte havaittu",
  "Sample Error": "Näytevirhe",
  "Sample Type Error": "Näytetyypin virhe",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi-Arabia",
  "Save": "Tallenna",
  "Select a date": "Valitse päiväys",
  "Select from list...": "Valitse luettelosta…",
  "Select language": "Valitse kieli",
  "Selected files must match requirements": "Valittujen tiedostojen tulee vastata vaatimuksia",
  "Senegal": "Senegal",
  "Sep": "Syy",
  "September": "Syyskuu",
  "Serbia": "Serbia",
  "Serum": "Seerumi",
  "Server error, please try again later.": "Palvelinvirhe, yritä myöhemmin uudelleen.",
  "Server Error. Unable to save your profile.": "Palvelinvirhe. Profiiliasi ei voi tallentaa.",
  "Set Password": "Aseta salasana",
  "Seychelles": "Seychellit",
  "Show Password": "Näytä salasana",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapore",
  "Size must be less than __maxSize__": "Koon on oltava alle __maxSize__",
  "Skin Swab": "Näyte iholta",
  "Slovakia": "Slovakia",
  "Slovenia": "Slovenia",
  "Solomon Islands": "Salomoninsaaret",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Valitettavasti DataView ei ole käytössä tai sinulla ei ole pääsyä tähän organisaatioon",
  "South Africa": "Etelä-Afrikka",
  "Space": "Välilyönti",
  "Spain": "Espanja",
  "Spanish": "espanja",
  "Spanish (US)": "espanja (USA)",
  "Sputum": "Yskös",
  "Sri Lanka": "Sri Lanka",
  "Su": "Su",
  "Sudan": "Sudan",
  "Suriname": "Suriname",
  "Swaziland": "Swazimaa",
  "Sweden": "Ruotsi",
  "Swedish": "ruotsi",
  "Switzerland": "Sveitsi",
  "Syrian Arab Republic(Syria)": "Syyrian arabitasavalta",
  "São Tomé and Príncipe": "São Tomé ja Príncipe",
  "Taiwan, Republic of China": "Taiwan",
  "Tajikistan": "Tadžikistan",
  "Tanzania, United Republic of": "Tansania",
  "Temperature Error": "Lämpötilavirhe",
  "Test Cancelled": "Testi peruutettu",
  "Test Lot Not Permitted": "Testierä ei sallittu",
  "Test Operation Error": "Testin toimintavirhe",
  "Test Strip Lot Expired": "Testiliuskan erä vanhentunut",
  "Test Strip Not Supported": "Testiliuskaa ei tueta",
  "Test Strip Reading Error": "Testiliuskan lukuvirhe",
  "Test Timeout": "Testin aikakatkaisu",
  "Test Type Not Permitted": "Testityyppi ei sallittu",
  "Th": "To",
  "Thailand": "Thaimaa",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Uuden salasanan vahvistuskoodi lähetetään sähköpostiin, jos sähköpostiosoite on validi käyttäjätunnuksen sähköpostiosoite.",
  "The reset validation code was populated from the link and should not be changed.": "Nollauksen vahvistuskoodi saatiin linkistä eikä sitä saa vaihtaa.",
  "There are unsaved changes. How do you want to proceed?": "On olemassa tallentamattomia muutoksia. Kuinka haluat jatkaa?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Uusi versio on saatavilla (__versionNumber__), haluatko asentaa sen nyt?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Tämä laite ei toimi enää Connect Hub -tilassa. Jos haluat jatkaa tämän laitteen käyttöä Connect Hubina, ota Bluetooth uudelleen käyttöön laiteasetuksista ja ota uudelleen käyttöön Käytä tätä mobiililaitetta Connect Hubina Hubs-valikoissa.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Tämä LumiraDx Connect -versio ei enää ole yhteensopiva organisaatiosi kanssa. Mene kohtaan __storeName__ ja päivitä uusimpaan versioon.",
  "Throat Swab": "Nielunäyte",
  "Timor - Leste": "Itä-Timor",
  "Title": "Titteli",
  "TN Swab": "Nenänielunäytteenottotikku",
  "to": "päättyen",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Päivitä __APP_NAME__-sovellus uusimpaan versioon (__versionNumber__) käytön jatkamiseksi.",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad ja Tobago",
  "Tu": "Ti",
  "Tunisia": "Tunisia",
  "Turkey": "Turkki",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- ja Caicossaaret",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraina",
  "Unable to reset password, the provided current password is not valid.": "Salasanaa ei voi nollata, syötetty salasana ei ole voimassa.",
  "Unable to reset your password.": "Salasanan nollaaminen epäonnistui.",
  "Unable to validate access code.": "Käyttöoikeuskoodin validointi epäonnistui.",
  "United Arab Emirates": "Yhdistyneet Arabiemiirikunnat",
  "United Kingdom": "Iso-Britannia",
  "United States of America": "Yhdysvallat",
  "Unrecognized credentials. Please try again.": "Tunnistamattomat tunnistetiedot. Yritä uudelleen.",
  "Unsaved Changes": "Tallentamattomat tulokset",
  "Update Available": "Päivitys saatavilla",
  "Update Password": "Päivitä salasana",
  "Urethral Swab": "Näyte virtsaputkesta",
  "Urine": "Virtsa",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Käyttäjätili on lukittu. Yritä uudelleen tunnin kuluttua.",
  "User Interface Version": "Käyttöliittymäversio",
  "User logged out due to inactivity.": "Käyttäjä kirjattu ulos toimimattomuuden vuoksi.",
  "Username": "Käyttäjätunnus",
  "Uzbekistan": "Uzbekistan",
  "Vaginal Swab": "Näyte emättimestä",
  "Validate Access Code": "Vahvista käyttöoikeuskoodi",
  "Validation Code": "Vahvistuskoodi",
  "Validation Code is required.": "Vahvistuskoodi vaaditaan.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela",
  "Venous Blood": "Laskimoveri",
  "Version": "Versio",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Neitsytsaaret, Iso-Britannia",
  "Virgin Islands, US": "Neitsytsaaret, Yhdysvallat ",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulvovaginaalinen näyte",
  "Wallis and Futuna Islands": "Wallis ja Futuna",
  "We": "Ke",
  "We are working to get you back up and running as quickly as possible.": "Yritämme ratkaista tämän asian mahdollisimman nopeasti saadaksemme toiminnan taas käyntiin.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Valitettavasti LumiraDx Connect on tällä hetkellä pois käytöstä ylläpitotöiden takia.",
  "Yemen": "Jemen",
  "You have been signed out due to a password reset attempt.": "Sinut on kirjattu ulos salasanan nollausyrityksen vuoksi.",
  "You have been signed out due to the creation of another session with the same credentials.": "Sinut on kirjattu ulos, koska toinen istunto on luotu samoilla tunnistetiedoilla.",
  "You have been signed out due to your account being disabled by an admin.": "Sinut on kirjattu ulos, koska ylläpitäjä on poistanut tilisi käytöstä.",
  "You have been signed out due to your password being changed by an admin.": "Sinut on kirjattu ulos, koska ylläpitäjä on vaihtanut salasanasi.",
  "You have been signed out due to your user role being changed by an admin.": "Sinut on kirjattu ulos, koska ylläpitäjä on vaihtanut käyttäjärooliasi.",
  "You have been signed out.": "Sinut on kirjattu ulos.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Sinun on asennettava __APP_NAME__-sovelluksen toinen versio (__versionNumber__), joka on yhteensopiva organisaatiosi kanssa.",
  "Your password has expired. Please set a new password.": "Salasanasi on vanhentunut. Aseta uusi salasana.",
  "Your session has expired. Please login again.": "Istuntosi on vanhentunut. Kirjaudu sisään uudelleen.",
  "Your session has expired. User is not authorized.": "Istuntosi on vanhentunut. Käyttäjä ei ole valtuutettu.",
  "Zambia": "Sambia",
  "Zimbabwe": "Zimbabwe"
};
