import * as React from 'react';
import { findDOMNode } from 'react-dom';
import { PvrDirections } from './interfaces';
import Overlay from './Overlay';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/pvr.styl');
    break;
  default:
    styles = require('./styles/pvr.styl');
    break;
}

const NIB_OFFSET = 16;
const EDGE_BUFFER = 4;

export interface IPvrProps {
  element?: any;
  direction?: PvrDirections;
  height?: number | string;
  autoHeightClass?: string;
  autoHeightReCalc?: boolean;
  width?: number;
  styleMixin?: any;
  anchor?: HTMLElement;
  vAdjust?: number;
  hAdjust?: number;
  vSlide?: number;
  hSlide?: number;
  nibColor?: string;
  showNib?: boolean;
  borderColor?: string;
  updateOnKeys?: number[];
  closeOnAnchorLeave?: boolean;
  animateIn?: boolean;
  close?: () => any;
  backdrop?: boolean;
  backdropClose?: boolean;
  root?: Element;
  nonBlocking?: boolean;
  stores?: any;
  scale?: number;
  chooseOptimalDirection?: boolean;
}

export interface IPvrState {
  autoNibColor?: string;
  autoHeight?: number;
  scale?: number;
  animateHeight: boolean;
}

/**
 * @param element - a react element (not factory) to render inside the pvr
 * @param direction
 * below, above, right, left
 * determines on which side of the anchor element the pvr will appear
 * - in 'auto' mode below, above, right, left is the order they are checked
 * - should the pvr not fit in the direction provided, the opposite direction will be used
 * - should the pvr not fit in the opposite direction, the other 2 will be checked
 * - should the pvr not fit in any direction, the direction with the most space will be used
 * @param height
 * height of the pvr
 * 'auto' and the pvr will attempt to resize it self to the height of element, also see autoHeightClass below
 * @param autoHeightClass
 * pass this when height: 'auto'
 * This is the class to use
 * @param autoHeightReCalc
 * May impact performance, but will recalculate the height of the autoHeightClass element on every render and update the pvr height if it changes
 * defaults to false
 * @param width
 * width of the pvr
 * @param styleMixin
 * object containing any style properties to mixin with and/or override the defaults
 * note that width height are passed separately so they can have defaults and auto settings
 * passing width/height in this object could cause issues
 * @param anchor
 * pass then e.currentTarget or e.target element through to enable auto-positioning
 * if this isn't passed, you'll have to manually configure positioning with styleMixin or CSS
 * @param vAdjust
 * for above, below positioning
 * negative value moves the pvr closer to the anchor element, positive value moves it further away
 * for left, right
 * negative value moves pvr higher on the screen, positive value moves it lower
 * @param vSlide
 * leave the nib in the same place, but offsets the pvr body by this number of pixels
 * @param hAdjust
 * for above, below positioning
 * negative value moves the pvr further left, positive value moves it further right
 * for left, right
 * negative value moves the pvr closer to the anchor element, positive value moves it further away
 * @param hSlide
 * leave the nib in the same place, but offsets the pvr body by this number of pixels
 * @param nibColor
 * the color of the nib
 * pass 'auto' to have the nib attempt to color itself
 * @param showNib
 * whether the nib should show
 * @param borderColor
 * the color of pvr border
 * @param updateOnKeys
 * any keystrokes that should cause a rerender of the pvr
 * @param closeOnAnchorLeave
 * whether or not the pvr should close if the anchor element moves offscreen during a resize or key stroke (of a key in updateOnKeys)
 * @param animateIn
 * whether or not the pvr should animate when it mounts
 * @param backdrop
 * whether or not to display a backdrop behind this overlay
 * @param backdropClose
 * whether to close the overlay - default is false
 * @param root
 * the base element for the overlays container. Defaults to an element with the ID of `overlays`.
 * @param nonBlocking
 * nonBlocking When true, the overlay container will have no width/height and pass mouse events through.
 * @param chooseOptimalDirection
 * optional parameter that will enable/disable optimal direction selection. On by default. When off, will not try to change the direction based on space available
 */
export default class Pvr extends React.Component<IPvrProps, IPvrState> {
  public state = {
    autoNibColor: null,
    autoHeight: null,
    animateHeight: false,
    scale: 1,
  };

  public static defaultProps: IPvrProps = {
    height: 100,
    width: 200,
    styleMixin: {},
    vAdjust: 0,
    vSlide: 0,
    hAdjust: 0,
    hSlide: 0,
    anchor: {
      getBoundingClientRect: function() {
        return {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        };
      },
    } as any,
    element: null,
    direction: PvrDirections.AUTO,
    nibColor: 'auto',
    showNib: true,
    borderColor: 'rgb(136,136,136)',
    updateOnKeys: [],
    closeOnAnchorLeave: true,
    animateIn: true,
    autoHeightReCalc: false,
    backdrop: false,
    backdropClose: true,
    root: null,
    nonBlocking: false,
    chooseOptimalDirection: true,
  };

  constructor(props) {
    super(props);
    this.directionCheck = (() => {
      switch (props.direction) {
        case 'left': return ['left', 'right', 'below', 'above'];
        case 'right': return ['right', 'left', 'below', 'above'];
        case 'below': return ['below', 'above', 'right', 'left'];
        case 'above': return ['above', 'below', 'right', 'left'];
        default: return ['below', 'above', 'right', 'left'];
      }
    })();

    window.addEventListener('scroll', this.handleResize);
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('keydown', this.handleKeyStroke);
  }

  private directionCheck: string[];
  private nib: HTMLElement;

  public componentWillUnmount(): void {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleResize);
    window.removeEventListener('keydown', this.handleKeyStroke);
  }

  public componentDidMount(): void {
    const { nibColor, height, showNib } = this.props;

    if (showNib && (nibColor === 'auto')) {
      this.colorNibAutomatically();
    }

    if (height === 'auto') {
      setTimeout(() => {
        this.setState({
          autoHeight: this.calcHeight(),
        });
      }, 0);
    }
  }

  public colorNibAutomatically(): void {
    const { anchor, chooseOptimalDirection, direction } = this.props;
    let x;
    let y;

    // Find the direction of the pvr and the top and left of the nib
    const { top, left } = this.nib.getBoundingClientRect();
    const dir = chooseOptimalDirection ? this.calculateOptimalDirection(anchor.getBoundingClientRect()) : direction;

    const buffer = 5;
    const nibSize = 14;

    // Find the x and y coordinates of the element adjacent to the nib's base
    switch (dir) {
      case PvrDirections.LEFT:
        x = left - buffer;
        y = top + (nibSize / 2);
        break;
      case PvrDirections.RIGHT:
        x = left + nibSize + buffer;
        y = top + (nibSize / 2);
        break;
      case PvrDirections.BELOW:
        x = left + (nibSize / 2);
        y = top + nibSize + buffer;
        break;
      case PvrDirections.ABOVE:
        x = left + (nibSize / 2);
        y = top - buffer;
        break;
    }

    const adjacentEl = document.elementFromPoint ? document.elementFromPoint(x, y) : null;

    if (adjacentEl == null) { return; }

    let autoNibColor = 'white';
    let counter = 0;
    let node = adjacentEl as any;

    while ((autoNibColor === 'white') && node instanceof Element && (counter < 10)) {
      // Get the computed bg color of the node
      const bgColor = __guard__(getComputedStyle(node, null), x1 => x1.getPropertyValue('background-color'));

      // Set color if there is one
      if (!['rgba(0, 0, 0, 0)', 'transparent'].includes(bgColor)) { autoNibColor = bgColor; }

      // Try the parent node next
      node = node.parentNode;
      counter++;
    }

    return this.setState({ autoNibColor });
  }

  public handleKeyStroke = (e): void => {
    const { keyCode } = e;
    const { updateOnKeys, closeOnAnchorLeave, close } = this.props;

    if (!Array.from(updateOnKeys).includes(keyCode)) { return; }

    if (this.anchorIsOffscreen() && closeOnAnchorLeave) {
      return close();
    } else { return this.forceUpdate(); }
  };

  public handleResize = (e): void => {
    const { closeOnAnchorLeave } = this.props;
    if (this.anchorIsOffscreen() && closeOnAnchorLeave) {
      return close();
    } else { return this.forceUpdate(); }
  };

  public anchorIsOffscreen(): boolean {
    const { top, left, right, bottom } = this.props.anchor.getBoundingClientRect();

    if ((top > window.innerHeight) || (left > window.innerWidth) || (bottom < 0) || (right < 0)) {
      return true;
    } else { return false; }
  }

  public handleClick = (e): void => {
    return e.stopPropagation();
  };

  public calculateOptimalDirection(elPos): PvrDirections {
    const { innerHeight, innerWidth } = window;
    const { width } = this.props;
    let { height } = this.props;
    const { autoHeight } = this.state;
    let { right, bottom } = elPos;
    const { left, top }  = elPos;

    height = height === 'auto' ? (autoHeight || 18) : height;

    // left and top are already the space to the left and the top of the anchor
    // transform right and bottom to the space to the right and bottom of the anchor
    right = innerWidth - right;
    bottom = innerHeight - bottom;

    // Check if the popover fits in the preferred direction
    const widthNeeded = width + NIB_OFFSET + EDGE_BUFFER;
    const heightNeeded = height as number + NIB_OFFSET + EDGE_BUFFER;

    for (const direction of this.directionCheck) {
      switch (direction) {
        case 'left':
          if (widthNeeded < left) { return PvrDirections.LEFT; }
          break;
        case 'right':
          if (widthNeeded < right) { return PvrDirections.RIGHT; }
          break;
        case 'below':
          if (heightNeeded < bottom) { return PvrDirections.BELOW; }
          break;
        case 'above':
          if (heightNeeded < top) { return PvrDirections.ABOVE; }
          break;
      }
    }

    // If the popover will not fit in any of the the preferred directions
    // return the direction with the most space
    const maxDirection = Math.max(left, top, bottom, right);

    switch (maxDirection) {
      case left: return PvrDirections.LEFT;
      case right: return PvrDirections.RIGHT;
      case top: return PvrDirections.ABOVE;
      case bottom: return PvrDirections.BELOW;
    }
  }

  public render(): JSX.Element {
    const { width, styleMixin, vAdjust, hAdjust, anchor, showNib, borderColor, vSlide, hSlide, animateIn, stores, children, close, backdrop, backdropClose, root, nonBlocking, chooseOptimalDirection } = this.props;
    let { nibColor, height, direction, element } = this.props;
    const { autoNibColor, autoHeight, animateHeight } = this.state;
    let left;
    let nibBorderStyle;
    let nibStyle;
    let top;

    if (nibColor === 'auto') {
      nibColor = (autoNibColor != null) ? autoNibColor : 'white';
    }

    let className = `${styles.Pvr} ${styles[direction] || styles.below}`;

    if (height !== 'auto' || animateHeight) { className += ` ${styles.HeightTransform}`; }
    if (animateIn) { className += ` ${styles.AnimateIn}`; }

    height = height === 'auto' ? (autoHeight || 18) : height;

    const scale = this.props.scale || this.state.scale;

    const elPos = anchor?.getBoundingClientRect() || { top: 0, left: 0, right: 0, width: 0, height: 0 };
    const anchorTop = elPos.top;
    const anchorLeft = elPos.left;
    const anchorRight = elPos.right;
    const anchorWidth = elPos.width;
    const anchorHeight = elPos.height;

    direction = chooseOptimalDirection ? this.calculateOptimalDirection(elPos) : direction;

    const nibClass = `${styles.nib} ${styles[direction]}`;
    const nibBorderClass = `${styles.nib} ${styles.border} ${styles[direction]}`;
    let nibPosition = NIB_OFFSET / 2;

    // When adjusting the nib center point up/down, don't exceed half the height minus border radius
    const maxNibVerticalAdjust = (height as number / 2) - (NIB_OFFSET / 2) - 5;
    // When adjusting the nib center point left/right, don't exceed half the width minus border radius
    const maxNibHorizontalAdjust = (width / 2) - (NIB_OFFSET / 2) - 5;

    element = element || children;
    element =
      (stores != null) && (element != null) ? React.cloneElement(element, { ...element.props, ...stores })
        : (element != null) ? element
          : <div key="inner" className={styles.inner} />;

    let topAdjust;
    let leftAdjust;
    switch (direction) {
      case 'left':
        top = (((anchorTop + (anchorHeight / 2)) - (height as number / 2)) + vAdjust) - vSlide;
        left = anchorLeft - NIB_OFFSET - width - hAdjust;

        // Make sure the pvr is not off the screen to the bottom
        topAdjust = (top + height + EDGE_BUFFER) - window.innerHeight;
        if (topAdjust > 0) {
          top = top - topAdjust - vSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(topAdjust, maxNibVerticalAdjust);
        }

        // Make sure the pvr is not off the screen to the top
        topAdjust = top - EDGE_BUFFER;
        if (topAdjust < 0) {
          top = top - topAdjust - vSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(topAdjust, maxNibVerticalAdjust);
        }

        nibPosition -= vSlide;
        nibStyle = {
          borderColor: `transparent transparent transparent ${nibColor}`,
          top: `calc(50% - ${nibPosition}px)`,
        };

        nibBorderStyle = {
          borderColor: `transparent transparent transparent ${borderColor}`,
          top: `calc(50% - ${nibPosition}px)`,
          right: 0 - NIB_OFFSET,
        };

        break;

      case 'right':
        top = (((anchorTop + (anchorHeight / 2)) - (height as number / 2)) + vAdjust) - vSlide;
        left = anchorRight + NIB_OFFSET + hAdjust;

        // Make sure the pvr is not off the screen to the bottom
        topAdjust = (top + height + EDGE_BUFFER) - window.innerHeight;
        if (topAdjust > 0) {
          top = top - topAdjust - vSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(topAdjust, maxNibVerticalAdjust);
        }

        // Make sure the pvr is not off the screen to the top
        topAdjust = top - EDGE_BUFFER;
        if (topAdjust < 0) {
          top = top - topAdjust - vSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(topAdjust, maxNibVerticalAdjust);
        }

        nibPosition -= vSlide;
        nibStyle = {
          borderColor: `transparent ${nibColor} transparent transparent`,
          top: `calc(50% - ${nibPosition}px)`,
        };

        nibBorderStyle = {
          borderColor: `transparent ${borderColor} transparent transparent`,
          top: `calc(50% - ${nibPosition}px)`,
          left: 0 - NIB_OFFSET,
        };

        break;

      case 'below':
        top = anchorTop + anchorHeight + NIB_OFFSET + vAdjust + (window.scrollY ? window.scrollY : 0);
        left = (((anchorLeft + (anchorWidth / 2)) - (width / 2)) + hAdjust) - hSlide;

        // Make sure the pvr is not off the screen to the right
        leftAdjust = (left + width + EDGE_BUFFER) - window.innerWidth;
        if (leftAdjust > 0) {
          left = left - leftAdjust - hSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(leftAdjust, maxNibHorizontalAdjust);
        }

        // Make sure the pvr is not off the screen to the left
        leftAdjust = left - EDGE_BUFFER;
        if (leftAdjust < 0) {
          left = left - leftAdjust - hSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(leftAdjust, maxNibHorizontalAdjust);
        }

        nibPosition -= hSlide;
        nibStyle = {
          borderColor: `transparent transparent ${nibColor} transparent`,
          left: `calc(50% - ${nibPosition}px)`,
        };

        nibBorderStyle = {
          borderColor: `transparent transparent ${borderColor} transparent`,
          left: `calc(50% - ${nibPosition}px)`,
          top: 0 - NIB_OFFSET,
        };

        break;

      case 'above':
        top = (anchorTop + (window.scrollY ? window.scrollY : 0)) - NIB_OFFSET - (height as number) - vAdjust;
        left = (((anchorLeft + (anchorWidth / 2)) - (width / 2)) + hAdjust) - hSlide;

        // Make sure the pvr is not off the screen to the right
        leftAdjust = (left + width + EDGE_BUFFER) - window.innerWidth;
        if (leftAdjust > 0) {
          left = left - leftAdjust - hSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(leftAdjust, maxNibHorizontalAdjust);
        }

        // Make sure the pvr is not off the screen to the left
        leftAdjust = left - EDGE_BUFFER;
        if (leftAdjust < 0) {
          left = left - leftAdjust - hSlide;
          nibPosition = (NIB_OFFSET / 2) - Math.min(leftAdjust, maxNibHorizontalAdjust);
        }

        nibPosition -= hSlide;
        nibStyle = {
          borderColor: `${nibColor} transparent transparent  transparent`,
          left: `calc(50% - ${nibPosition}px)`,
        };

        nibBorderStyle = {
          borderColor: ` ${borderColor} transparent transparent transparent`,
          left: `calc(50% - ${nibPosition}px)`,
          bottom: 0 - NIB_OFFSET,
        };

        break;
    }

    // Set the styles
    const style = {
      height,
      width,
      top,
      left,
      borderColor,
      transform: `scale(${scale})`,
      WebkitTransform: `scale(${scale})`,
      msTransform: `scale(${scale})`,
      ...styleMixin,
    };

    return (
      <Overlay
        close={close}
        backdrop={backdrop}
        backdropClose={backdropClose}
        root={root}
        nonBlocking={nonBlocking}
      >
        <div
          className={className}
          style={style}
          onMouseDown={this.handleClick}
        >
          {showNib ?
            <div
              className={nibBorderClass}
              style={nibBorderStyle}
            />
            : null}
          {showNib ?
            <div
              className={nibClass}
              style={nibStyle}
              ref={(nib) => this.nib = nib}
            />
            : null}
          {element}
        </div>
      </Overlay>
    );
  }

  public componentDidUpdate() {
    const { autoHeightReCalc } = this.props;

    if (autoHeightReCalc) {
      const { autoHeight } = this.state;
      const newHeight = this.calcHeight();

      if (newHeight !== autoHeight) {
        this.setState({
          autoHeight: newHeight,
          animateHeight: true,
        });
      }

    }
  }

  private calcHeight(): number {
    const { autoHeightClass, height } = this.props;

    const el = findDOMNode(this) as HTMLElement;
    const els = el.getElementsByClassName(autoHeightClass); // TODO: Refactor this to use an element instead of a CSS class

    // account for eg 1 px border on top and bottom so that white space is correct
    const renderedPopover = document.querySelector('div[class^="pvr__Pvr"]');

    let compStyles = null;
    if (renderedPopover){
      compStyles = window.getComputedStyle(renderedPopover);
    }

    let borderWidthValue = null;
    if (compStyles){
      borderWidthValue = Number(compStyles.getPropertyValue('border-width')[0]) || 0;
    }

    const borderAdjustment = (borderWidthValue * 2) || 0 ;

    return els[0] != null ? els[0].clientHeight + borderAdjustment : + height;

  }

}

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}
