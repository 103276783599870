import * as React from 'react';
import * as moment from 'moment';
import TextInput from './TextInput';
import { IBaseInputProps, IValidateOptions, IValidationError } from './interfaces';
import DayPicker, { DateUtils } from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';

const styles = require('./styles/input.styl');

export interface IDatepickerProps extends IBaseInputProps {
  onChange?: (value: any, jsonPath?: string) => void;
  onKeyUp?: (e) => void;
  clear?: () => void;
  validate?: (options: IValidateOptions) => IValidationError;
  returnDateFormat?: string;
  format?: string;
  dayPickerProps?: any;
  to?: any;
  from?: any;
  hasDefaultFromDate?: boolean;
  hasDefaultToDate?: boolean;
  startDate?: any;
  [x: string]: any;
}

export interface IDatepickerState {
  showErrors?: boolean;
  from: any;
  to: any;
  valueHasChanged?: boolean;
  inputFromValue?: string;
  inputToValue?: string;
  disabledDays?: any;
  startMonth?: Date;
}

export default class InlineDateRangePicker extends React.Component<IDatepickerProps, IDatepickerState> {
  public static defaultProps: IDatepickerProps = {
    validationFrom: false,
    validationTo: false,
    forceShowAllErrors: false,
    format: 'MM/DD/YYYY',
    className: '',
    returnDateFormat: null,
    dayPickerProps: {},
    from: null,
    to: null,
    locale: 'en-US',
    hasDefaultFromDate: false,
    hasDefaultToDate: false,
    disableFutureDates: false,
  };

  constructor(props: IDatepickerProps) {
    super(props);

    const { hasDefaultFromDate, from, hasDefaultToDate, to, format, returnDateFormat } = props;

    this.state = {
      valueHasChanged: false,
      showErrors: false,
      from: null,
      to: null,
      inputFromValue: hasDefaultFromDate ? moment(from, returnDateFormat).format(format) : '',
      inputToValue: hasDefaultToDate ? moment(to, returnDateFormat).format(format) : '',
    };

  } 
  public componentDidMount(): void {
    const { hasDefaultFromDate, from, hasDefaultToDate, to, disableFutureDates, startDate } = this.props;

    // used to tell which month should be the first calendar
    if (startDate) {
      const month = startDate.month();
      const year = startDate.year();
      const startMonth = new Date(year, month);
      this.setState({
        startMonth,
      });
    }

    if (disableFutureDates) {
      const disabledDays = {
        after: new Date(),
      };
      this.setState({
        disabledDays,
      });
    }

    if (hasDefaultFromDate && hasDefaultToDate) {
      const newFrom = new Date(from);
      const newTo = new Date(to);
      this.setState({
        from: newFrom,
        to: newTo,
      });
    }
  }

  public render(): JSX.Element {
    const { validationFrom, validationTo, validate, forceShowAllErrors, flashErrorPvrs, registerError, placeholder = t('Select a date'), locale, dayPickerProps } = this.props;
    const { from, to, inputFromValue, inputToValue, disabledDays, startMonth } = this.state;

    const modifiers = { start: from, end: to };

    const inputDateFrom = `${styles.FieldWrap} ${styles.Datepicker} ${styles.FromDate}`;
    const inputDateTo = `${styles.FieldWrap} ${styles.Datepicker} ${styles.ToDate}`;

    return (
      <div className={styles.InlineDatePicker}>
        <div className={styles.InlineDates}>
          <div className={inputDateFrom}>
            <TextInput
              className={styles.DateInput}
              placeholder={placeholder}
              value={inputFromValue}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              registerError={registerError}
              validate={validate}
              validation={validationFrom}
              jsonPath="fromDate"
              disabled={true}
            />

          </div>
          <div className={styles.ToLabel}>{t('to')}</div>
          <div className={inputDateTo}>
            <TextInput
              className={styles.DateInput}
              placeholder={placeholder}
              value={inputToValue}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              registerError={registerError}
              validate={validate}
              validation={validationTo}
              jsonPath="toDate"
              disabled={true}
            />
          </div>
        </div>
        <DayPicker
          className={styles.DayPicker}
          selectedDays={[from, { from, to }]}
          modifiers={modifiers}
          {...dayPickerProps}
          locale={locale}
          localeUtils={MomentLocaleUtils}
          onDayClick={this.handleDayClick}
          backdrop={false}
          numberOfMonths={2}
          disabledDays={disabledDays}
          month={startMonth}
        />
      </div>
    );
  }

  public handleDayClick = (day: any): void => {
    const { onChange, format, returnDateFormat, jsonPath } = this.props;
   
    // set the range values on the calendars
    const range = DateUtils.addDayToRange(day, this.state);
    this.setState(range);

    // handle on change
    const { from, to } = range;
    const dates = { from: from, to: to };
    onChange(dates, jsonPath);

    // set the values in the input boxes above the calendar
    this.setState({
      inputFromValue: moment(from, returnDateFormat).format(format),
      inputToValue: to ? moment(to, returnDateFormat).format(format) : '',
    });
  };

}
