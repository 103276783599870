import * as React from 'react';
import { IBaseInputProps, IValidationError, IValidateOptions, InputValue, ITabError, PvrDirections } from './interfaces';
import Keyboard from './Keyboard';
import { makeGuid } from './utils';
import { Moment } from 'moment';

export interface IChildInputProps {
  inputId: string;
  onChange: (e) => void;
  onKeyUp: (e) => void;
  onBlur: (e) => void;
  clear: () => void;
  validate: (options: IValidateOptions) => IValidationError;
  setTabErrorAnchors: (options: ITabError) => ITabError;
  valueHasChanged: boolean;
  flashErrors: boolean;
}

export interface IInputProps extends IBaseInputProps {
  children: (props: IChildInputProps) => JSX.Element;
}
export interface IInputState {
  valueHasChanged: boolean;
  flashErrors: boolean;
}

export default class Input extends React.Component<IInputProps, IInputState> {
  private inputId: string;
  private delayedActionTimer: any;
  private flashTimer;

  public static defaultProps: Partial<IBaseInputProps> = {
    flashDuration: 3000,
  };

  constructor(props) {
    super(props);

    // create a unique id for this input
    // used by the validation framework to group validation errors
    this.inputId = makeGuid();

    this.state = {
      valueHasChanged: false,
      flashErrors: false,
    };
  }

  public componentWillUnmount() {
    const { registerError } = this.props;

    typeof registerError === 'function' ? registerError({
      inputId: this.inputId,
      error: null,
      remove: true,
    }) : void 0;

  }

  public render(): JSX.Element {
    const { children } = this.props;
    const { valueHasChanged, flashErrors } = this.state;

    return children({
      inputId: this.inputId,
      onChange: this.handleChange,
      onKeyUp: this.onKeyUp,
      onBlur: this.handleBlur,
      clear: this.clear,
      validate: this.validate,
      setTabErrorAnchors: this.setTabErrorAnchors,
      valueHasChanged,
      flashErrors,
    });
  }

  public componentDidUpdate(prevProps): void {
    const { flashDuration } = this.props;

    if (prevProps.flashErrorPvrs !== this.props.flashErrorPvrs) {
      this.setState({ flashErrors: true });
      clearTimeout(this.flashTimer);
      this.flashTimer = setTimeout(function(context) {
        return () => {
          context.setState({ flashErrors: false });
        };
      }(this), flashDuration);
    }
  }

  private handleChange = (value: string | Moment): void => {
    const { onChange, jsonPath } = this.props;
    const { valueHasChanged } = this.state;

    if (!valueHasChanged) {
      this.setState({ valueHasChanged: true });
    }

    if (typeof onChange === 'function') {
      onChange(this.parseValue(value), jsonPath);
    }

    this.fireDelayedAction();
  };

  private parseValue(value: InputValue): any {
    const { textTransform, returnNull } = this.props;
    let parsedValue;

    if (typeof value === 'object') {
      return value;
    }

    parsedValue = typeof textTransform === 'function' ? textTransform(value) : value;
    parsedValue = returnNull && parsedValue.toString().trim() === '' ? null : parsedValue;
    return parsedValue;
  }

  public handleBlur = (e, keyboard?) => {
    const { onBlur } = this.props;
    // Fix an iOS issue where <html> scrollTop is modified improperly when keyboard is closed
    document.getElementsByTagName('html')[0].scrollTop = 0;
    if (typeof onBlur === 'function') {
      onBlur(e, keyboard);
    }
  };

  public validate = ({value, validation, anchor}: IValidateOptions): IValidationError => {
    const { registerError } = this.props;
    const direction = PvrDirections.BELOW;

    // If validation is a function, run it, otherwise assign it directly
    let error;
    if (typeof validation === 'function') {
      error = validation(value);
    } else {
      error = validation;
    }

    typeof registerError === 'function' && error !== false ? registerError({
      inputId: this.inputId,
      error,
      remove: !error,
    }) : void 0;

    // Set the message array to empty when no validation is provided
    return error === false ? { messages: [], direction, anchor} : { messages: [], ...error, anchor};

  };

  private onKeyUp = (e): void => {
    const { onEnterKey, onKeyUp } = this.props;

    // If there's an enter key handler, fire it
    if (e.keyCode === Keyboard.ENTER) {
      if (typeof onEnterKey === 'function') {
        onEnterKey(e);
      }
    }

    // Fire for all other keyup events
    (typeof onKeyUp === 'function' ? onKeyUp(e) : void 0);

  };

  private clear = (): void => {
    const { onChange, jsonPath } = this.props;
    onChange('', jsonPath);
  };

  private fireDelayedAction(): void {
    const { delayedActionOnChange, value } = this.props;

    if (delayedActionOnChange != null) {
      const { action, interval } = delayedActionOnChange;

      clearInterval(this.delayedActionTimer);

      this.delayedActionTimer = setTimeout(() => {
        action(this.parseValue(value));
      }, interval);
    }
  }

  private setTabErrorAnchors(options: ITabError): ITabError {
    return options;
  }

}
