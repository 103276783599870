/**
 * Tests whether an input string is ONLY alphabetic
 * @param value the input string
 */
export function isAlphabetsOnly(value: string): boolean {
  return /^[a-zA-Z]+$/.test(value);
}
/**
 * Tests whether an input string is ONLY alphanumeric
 * @param value the input string
 */
export function isAlphaNumericOnly(value: string): boolean {
  return /^[a-zA-Z\d]*$/.test(value);
}
/**
 * Tests whether an input string is ONLY alphanumeric with spaces
 * @param value the input string
 */
export function isAlphaNumericWithSpaces(value: string): boolean {
  return /^[A-Za-z\d\s]*$/.test(value);
}
/**
 * Tests whether an input string is a valid title that includes no odd characters
 * @param value the input string
 */
export function isValidTitle(value: string): boolean {
  return /^[a-zA-Z'"!@#$%&*()-_+:;.,?\/0-9\s]+$/.test(value);
}
/**
 * Tests whether an input string is a positive, whole number
 * @param num the input number or string
 */
export function isPositiveWholeNumber(num: string | number): boolean {
  return /^\d+$/.test(num as string);
}
/**
 * Tests whether an input string is a number, including decimals and negatives
 * @param num the input number or string
 */
export function isNumeric(num: string | number): boolean {
  return /^-?[0-9]\d*(\.\d+)?$/.test(num as string);
}
/**
 * Tests for a valid email, per modified RFC 2822 - http://www.regular-expressions.info/email.html
 * @param email the input string
 */
export function isValidEmail(email: string): boolean {
  return /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(email);
}
/**
 * Tests for a valid name, including alphabetic, apostrophes, whitespace and hyphens
 * @param name the input string
 */
export function isValidName(name: string): boolean {
  return /^[a-zA-Z' \-]+$/.test(name);
}
/**
 * Tests for a valid phone number (as much as possible). Strips parens, hyphens, pluses and spaces, then allows for a 7 - 12 digits
 * @param num the input string or number
 */
export function isValidPhone(num: number | string): boolean {
  num = String(num);
  // Allow an empty phone numnber field
  if (num.length === 0) { return true; }
  // Strip out parentheses and hyphens and spaces
  const  digits = num.replace(/[\-\(\)\s\+\e\E\x\X\\t\\T\.]/g, '');
  // Require a 10 - 15 digit number
  if (digits.length < 10 || digits.length > 25) { return false; }
  // Check for only numbers
  return isPositiveWholeNumber(digits);
}
/**
 * Tests for a valid NHS number
 * @param nhs the input string
 */
export function isValidNhs(nhs: string): boolean {
  const nhsNumber = nhs.replace(/\s/g, '');

  // if there are not 10 digits then not valid
  if (nhsNumber.length < 10) { return false; }

  let mod11 = 0;
  let factor = 10;
  // for the first 9 digits starting from the left
  for (const digit of Array.from(nhsNumber)) {
    // multi the digit by the factor then add to mod 11
    if (factor > 1) {
      mod11 += +digit * factor;
      // descrease factor by 1
      factor -= 1;
    }
  }

  // get the modulus and the check digit (right most digit)
  const modulus = 11 - (mod11 % 11);
  const checkDigit = parseInt(nhsNumber[9], 10);

  // rules:
  // modulus and check digit are equal then valid
  // modulus is 11 and and check digit is 0 then valid
  // modulus is 10 then not valid
  // everything else not valid
  if ((modulus === checkDigit) || ((modulus === 11) && (checkDigit === 0))) {
    return true;
  } else {
    return false;
  }
}
/**
 * Tests for a valid URL - http:// or https://
 * @param url the input string
 */
export function isValidUrl(url: string): boolean {
  return url.slice(0, 8) === 'https://' || url.slice(0, 7) === 'http://';
}

/**
 * Validates a url OR IP address.
 * Returns a validation error for an invalid URL or IP, and true for a passing values.
 * @param value - the url or ip string to validate
 * @param options.mustHaveHttp - If the URL or IP is must start with http or https, false by default
 * @param options.fieldName - The field name to use in error messages, defaults to 'Hostname'
 */
interface IOptions {
  mustHaveHttp?: boolean;
  fieldName?: string;
}

export const isValidIpOrUrl = (value: string, options?: IOptions): string | boolean => {
  const { mustHaveHttp, fieldName } = {
    ...{ mustHaveHttp: false, fieldName: 'Hostname' },
    ...options,
  };

  if (mustHaveHttp && (!isValidHttpIPv4(value) && !isValidHttpURL(value))) {
    if (!/^https?:\/\//.test(value)) {
      return t('__fieldName__ must start with http:// or https://', { fieldName });
    }
    else {
      return t('__fieldName__ is not a valid URL or IP address', { fieldName });
    }
  }
  else if (!mustHaveHttp && (!isValidIPv4(value) && !isValidURL(value))) {
    if (/^http/.test(value)) {
      return t('__fieldName__ should not start with http:// or https://', { fieldName });
    }
    else {
      return t('__fieldName__ is not a valid URL or IP address', { fieldName });
    }
  }

  return true;
};

/**
 * Validates input is a valid IPv4 address (w/out http(s):// prefix).
 * @param IP - IP string to validate
 */
const isValidIPv4 = (IP: string): boolean => {
  return /^(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|$)){4}$/.test(IP);
};

/**
 * Validates input is a valid IPv4 address (w http(s):// prefix).
 * @param IP - IP string to validate
 */
const isValidHttpIPv4 = (IP: string): boolean => {
  return /^https?:\/\/(([1-9]?\d|1\d\d|2[0-4]\d|25[0-5])(\.(?!$)|$)){4}$/.test(IP);
};

/**
 * Validates input is a valid url (w/out http(s):// prefix).
 * @param URL - URL string to validate
 */
const isValidURL = (URL: string): boolean => {
  return /^(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(URL);
};

/**
 * Validates input is a valid url (w/ http(s):// prefix).
 * @param URL - URL string to validate
 */
const isValidHttpURL = (URL: string): boolean => {
  return /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(URL);
};
