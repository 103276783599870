import * as React from 'react';
import * as moment from 'moment';
import Datepicker, { IDatepickerProps } from './DatepickerInput';
import SelectInput from './SelectInput';

const styles = require('./styles/grid.styl');

export interface IGridFormDatePickerProps extends Partial<IDatepickerProps> {
  labelColumnClass?: string;
  labelFlexBasis?: React.ReactText;
  inputColumnClass?: string;
  className?: string;
  isFieldRequired?: boolean;
  fullRow?: boolean;
  enableTime?: boolean;
  twentyFourHour?: boolean;
  minutes?: number[];
  meridian?: string[];
  isInPopover?: boolean;
  disabled?: boolean;
  timeColumns?: number;
  showCalendar?: boolean;
}

/**
 * @param formLabel
 * The value of the label that will display to the left of the input
 * @param className - default 'styles.FormGrid'
 * optional class to be added the main div
 * @param labelColumnClass
 * optional class that will be added to the label column
 * @param labelFlexBasis
 * optional style that will be added to the label column
 * @param inputColumnClass
 * optional class that will be added to the input column
 * @param isFieldRequired
 * optional boolean that will display the red asterisk if true
 * @param fullRow
 * optional boolean that will determine whether to display the input in a full row with or without a label
 * @param enableTime
 * includes the time picker
 * @param maxDate
 * when using the time picker it will disable times of day after this value
 * @param minDate
 * when using the time picker it will disable times of day before this value
 * @param children
 * optional array of children
 * @param showCalendar
 * when using the calendar will always be visible
 */
export default class GridFormDatePicker extends React.Component<IGridFormDatePickerProps, any> {
  public static defaultProps = {
    labelColumnClass: styles.Label,
    labelFlexBasis: '130px',
    inputColumnClass: styles.InputControl,
    className: styles.FormGrid,
    isFieldRequired: false,
    fullRow: true,
    enableTime: false,
    twentyFourHour: false,
    minutes: [0, 15, 30, 45],
    meridian: ['am', 'pm'],
    isInPopover: false,
    disabled: false,
    timeColumns: 2,
    showCalendar: false,
  };

  private input: Datepicker;
  private ampm: SelectInput;
  private hour: SelectInput;
  private minute: SelectInput;

  public componentDidMount(): void {
    return this.changeOnOutOfRange();
  }

  public componentDidUpdate(): void {
    return this.changeOnOutOfRange();
  }

  public changeOnOutOfRange(): void {
    const { value, maxDate, minDate } = this.props;

    if ((value != null) && ((maxDate != null) || (minDate != null))) {
      // If the selected date is out of range, the controls will be updated to a value different than the passed selected date
      // In this case onChange must be fired to ensure the parent form has the value displayed in the form
      const propsValue = this.getMomentObject(value);
      const controlValue = this.getMomentObject(this.getValue());

      if (!propsValue.isSame(controlValue, 'minute')) { return (this.handleChange)(); }
    }
  }

  public render(): JSX.Element {
    let content;
    let inputCell;
    let labelField;
    const { formLabel, labelColumnClass, labelFlexBasis, inputColumnClass, isFieldRequired, selected, fullRow, enableTime, twentyFourHour, tabId, children, isInPopover, tabIndex, disabled, jsonPath } = this.props;

    if (formLabel != null) {
      let labelClass = styles.FormLabel;
      if (isFieldRequired) {
        labelClass += ` ${styles.IsRequired}`;
      }

      labelField = (
        <div key="labelField" className={labelColumnClass} style={{ flexBasis: labelFlexBasis }}>
          <label className={labelClass}>{formLabel}</label>
        </div>
      );
    }

    // we do not want to pass the className down as it will mess up the style
    const ip: IDatepickerProps = {
      ...{}, ...this.props, ...{
        ref: (input) => this.input = input,
      },
    };
    const { className, ...inputProps } = ip;

    inputProps.onChange = this.handleChange;

    const input = <Datepicker {...inputProps} />;

    if (enableTime) {
      const classes = this.getCellClasses(inputColumnClass);
      const { hour, minute, ampm } = this.parseTime(selected);

      const hourOptions = this.getHourOptions({ minute, ampm });

      inputCell = [
        <div key="picker" className={classes[0]}>{input}</div>,
        (
          <div key="time1" className={classes[1]}>
            <SelectInput
              ref={(hour) => this.hour = hour}
              tabId={tabId}
              wrapperClass="hour"
              value={hour != null ? hour : ''}
              options={hourOptions}
              onChange={this.handleChange}
              disabled={disabled}
              isInPopover={isInPopover}
              tabIndex={tabIndex}
              jsonPath={jsonPath}
            />
            <span className="time-divide">:</span>
            <SelectInput
              ref={(minute) => this.minute = minute}
              wrapperClass="minutes"
              tabId={tabId}
              value={minute != null ? minute : ''}
              options={this.getMinuteOptions({ hour, ampm }, hourOptions)}
              onChange={this.handleChange}
              disabled={disabled}
              isInPopover={isInPopover}
              tabIndex={tabIndex}
              jsonPath={jsonPath}
            />
            {!twentyFourHour ? <SelectInput
              ref={(ampm) => this.ampm = ampm}
              wrapperClass="ampm"
              tabId={tabId}
              value={ampm != null ? ampm : ''}
              options={(this.getAmPmOptions)()}
              onChange={this.handleChange}
              disabled={disabled}
              isInPopover={isInPopover}
              tabIndex={tabIndex}
              jsonPath={jsonPath}
            /> : null
            }
          </div>
        ),
      ];
    } else {
      inputCell = <div key="input" className={inputColumnClass}>{input}</div>;
    }

    // This is a full row of a form with a label
    if (fullRow) {
      content = [
        (formLabel != null) ? labelField : null,
        inputCell,
      ].concat(children);

      return <div className={className}>{content}</div>;
    }
    // This is a single cell within a row
    else {
      return inputCell;
    }
  }

  public handleChange = (): void => {
    const { onChange, jsonPath } = this.props;
    const value = this.getValue();
    onChange(value, jsonPath);
  };

  public getValue(): string | moment.Moment | Date {
    let date;
    const { enableTime, returnDateFormat, twentyFourHour } = this.props;
    const timestamp = this.input.getValue();
    if (timestamp == null) { return null; }
    if (!enableTime) { return timestamp; }

    const minute = this.minute.getValue();
    const hour = this.hour.getValue();
    const ampm = (this.ampm != null ? this.ampm.getValue() : undefined) || '';

    // When returnDateFormat is passed the datepicker will return a string instead of a moment object
    // in this case momentize it before extracting the time
    if (returnDateFormat != null) {
      date = moment(timestamp, returnDateFormat).format('YYYYMMDD');
      const rv = moment(`${date}${hour}${minute}${ampm}`, twentyFourHour ? 'YYYYMMDDHHmm' : 'YYYYMMDDhmma');
      return rv.format(returnDateFormat);
    } else {
      date = (timestamp as moment.Moment).format('YYYYMMDD');
      return moment(`${date}${hour}${minute}${ampm}`, twentyFourHour ? 'YYYYMMDDHHmm' : 'YYYYMMDDhmma');
    }
  }

  private getMomentObject(date) {
    const { returnDateFormat } = this.props;

    // When returnDateFormat is passed the datepicker will return a string instead of a moment object
    // in this case momentize it before extracting the time
    if (returnDateFormat != null) {
      return moment(date, returnDateFormat);
    }
    else {
      return date;
    }
  }

  private parseTime(timestamp) {
    if (timestamp == null) {
      return {};
    }

    const { twentyFourHour } = this.props;

    timestamp = this.getMomentObject(timestamp);

    return {
      hour: timestamp.format(twentyFourHour ? 'HH' : 'h'),
      minute: timestamp.format('mm'),
      ampm: timestamp.format('a'),
    };
  }

  private getHourOptions(parsedTime) {
    const { twentyFourHour } = this.props;

    const hoursArray = twentyFourHour ? __range__(0, 23, true) : [12].concat([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);

    const hourOptions = (Array.from(hoursArray).map((hour) => ({
      label: twentyFourHour ? this.prependZero(hour) : `${hour}`,
      value: twentyFourHour ? this.prependZero(hour) : `${hour}`,
      disabled: false,
    })));

    this.removeOutOfRange('hours', parsedTime, hourOptions);

    return hourOptions;
  }

  private getMinuteOptions(parsedTime, hourOptions) {
    const { minutes } = this.props;

    const minuteOptions = (Array.from(minutes).map((minute) => ({
      label: this.prependZero(minute),
      value: this.prependZero(minute),
      disabled: false,
    })));

    this.removeOutOfRange('minutes', parsedTime, minuteOptions, hourOptions);

    return minuteOptions;
  }

  private getAmPmOptions() {
    const { meridian } = this.props;

    const ampmOptions = (Array.from(meridian).map((opt) => ({
      label: opt,
      value: opt,
    })));

    this.removeOutOfRangeAmPm(ampmOptions);

    return ampmOptions;
  }

  private removeOutOfRange(timeSection, parsedTime, optionsList, hourOptions = []) {
    const { selected, twentyFourHour, minDate, maxDate, minutes } = this.props;
    const dateFormat = twentyFourHour ? 'MMDDYYYY HHmm' : 'MMDDYYYY hmma';

    if (selected != null) {
      let checkTime;
      let o;
      let option;
      const currentValue = this.getMomentObject(selected);

      // If the selected hour is out of range, use the first hour option for checking the mintes
      const selectedHour = (hourOptions.find((opt) => opt.value === parsedTime.hour) == null) ? ((hourOptions[0] != null ? hourOptions[0].value : undefined) || '') : parsedTime.hour;

      // When checking for the hours to include in the list, use the highest/lowest minute option
      // This will prevent and hour option from appearing that has no corresponding minute options
      // It will also ensure the max hours is in the list if there is a corresponding minute that puts it in range
      const lastMinute = this.prependZero(minutes.pop());
      const firstMinute = this.prependZero(minutes[0]);

      // When the max date is selected, remove hours and minutes that fall after the max time
      if ((maxDate != null) && currentValue.isSame(maxDate, 'day')) {
        const maxDay = maxDate.format('MMDDYYYY');
        for (o = optionsList.length - 1; o >= 0; o--) {
          option = optionsList[o];
          checkTime = (() => {
            switch (timeSection) {
              case 'hours': return moment(`${maxDay} ${option.value}${firstMinute}${twentyFourHour ? '' : parsedTime.ampm}`, dateFormat);
              case 'minutes': return moment(`${maxDay} ${selectedHour}${option.value}${twentyFourHour ? '' : parsedTime.ampm}`, dateFormat);
            }
          })();
          if (checkTime.isAfter(maxDate, 'minute')) { optionsList.splice(o, 1); }
        }
      }
      // When the min date is selected, remove hours and minutes that fall before the min time
      if ((minDate != null) && currentValue.isSame(minDate, 'day')) {
        const minDay = minDate.format('MMDDYYYY');
        for (o = optionsList.length - 1; o >= 0; o--) {
          option = optionsList[o];
          checkTime = (() => {
            switch (timeSection) {
              case 'hours': return moment(`${minDay} ${option.value}${lastMinute}${twentyFourHour ? '' : parsedTime.ampm}`, dateFormat);
              case 'minutes': return moment(`${minDay} ${selectedHour}${option.value}${twentyFourHour ? '' : parsedTime.ampm}`, dateFormat);
            }
          })();
          if (checkTime.isBefore(minDate, 'minute')) { optionsList.splice(o, 1); }
        }
      }

      return optionsList;
    }
  }

  private removeOutOfRangeAmPm(optionsList) {
    const { selected, minDate, maxDate } = this.props;

    if (selected != null) {
      const currentValue = this.getMomentObject(selected);
      if (maxDate != null) {
        if (currentValue.isSame(maxDate, 'day')) {
          if (maxDate.format('a') === 'am') { optionsList.splice(1, 1); }
        }
      }
      if (minDate != null) {
        if (currentValue.isSame(minDate, 'day')) {
          if (minDate.format('a') === 'pm') { return optionsList.splice(0, 1); }
        }
      }
    }
  }

  private getCellClasses(inputColumnClass) {
    const { timeColumns } = this.props;
    const classSplit = inputColumnClass.split('-');
    const dateCol = classSplit[1];
    const colCount = classSplit[2];

    return [
      `col-${dateCol - timeColumns}-${colCount}`,
      `col-${timeColumns}-${colCount}`,
    ];
  }

  private prependZero(value): string {
    if (+value < 10) {
      return `0${value}`;
    }
    else {
      return `${value}`;
    }
  }
}

function __range__(left, right, inclusive) {
  const range = [];
  const ascending = left < right;
  const end = !inclusive ? right : ascending ? right + 1 : right - 1;
  for (let i = left; ascending ? i < end : i > end; ascending ? i++ : i--) {
    range.push(i);
  }
  return range;
}
