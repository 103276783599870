import * as React from 'react';
import * as moment from 'moment';
import TextInput from './TextInput';
import Pvr from './Pvr';
import { IDatepickerProps } from './DatepickerInput';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';

const styles = require('./styles/input.styl');

export interface IDatepickerState {
  showErrors?: boolean;
  value?: string | moment.Moment | Date;
  valueHasChanged?: boolean;
  calendarVisible?: boolean;
  inputValue?: string;
}

export default class Datepicker extends React.Component<IDatepickerProps, IDatepickerState> {
  public static defaultProps: IDatepickerProps = {
    validation: false,
    forceShowAllErrors: false,
    hasDefaultDate: true,
    allowBlankDate: false,
    format: 'MM/DD/YYYY',
    className: '',
    returnDateFormat: null,
    dayPickerProps: {},
    value: new Date(),
    locale: 'en-US',
  };

  private errorAnchor: HTMLElement;

  private inputEl: HTMLInputElement;

  constructor(props: IDatepickerProps) {
    super(props);
    const { hasDefaultDate, value, format, returnDateFormat } = props;
    this.state = {
      valueHasChanged: false,
      showErrors: false,
      calendarVisible: false,
      value: hasDefaultDate ? moment(value, returnDateFormat) : null,
      inputValue: hasDefaultDate ? moment(value, returnDateFormat).format(format) : '',
    };
  }

  public render(): JSX.Element {
    const { validation, validate, className, forceShowAllErrors, flashErrorPvrs, registerError, placeholder = t('Select a date'), pvrProps, minDate, maxDate, locale, dayPickerProps, hasDefaultDate, allowBlankDate, jsonPath, showCalendar } = this.props;
    const { valueHasChanged, value, inputValue, calendarVisible } = this.state;

    const error = validate({ value: value, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;

    let outerClass = `${styles.FieldWrap} ${styles.Datepicker}`;
    if (className != null) { outerClass += ` ${className}`; }

    outerClass +=  (!isValid && (valueHasChanged || forceShowAllErrors)) ? ` ${styles.Invalid}` : ` ${styles.Valid}`;
    // Abstract disabled days API
    const dateRangeRules = {} as any;
    if (minDate != null || maxDate != null) {
      dateRangeRules.before = minDate != null ? minDate.toDate() : null;
      dateRangeRules.after = maxDate != null ? maxDate.toDate() : null;
      dayPickerProps.disabledDays = [dateRangeRules];
    }

    const val = value && typeof (value as moment.Moment).toDate === 'function' ? (value as moment.Moment).toDate() : null;

    // if we have a default date then use it else use today's date for the initial month
    const initMonth = hasDefaultDate && allowBlankDate === false ? val : new Date();

    return (
      <>
        <div className={outerClass}>
          <TextInput
            placeholder={placeholder}
            onFocus={(e) => this.handleToggleCalendar(true, e)}
            value={inputValue}
            onChange={this.handleDateInputChange}
            onBlur={this.handleDateInputSet}
            onEnterKey={this.handleDateInputSet}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            registerError={registerError}
            validate={validate}
            validation={validation}
            jsonPath={jsonPath}
            focusOnMount={showCalendar}
          />
        </div>

        {calendarVisible ?
          <Pvr
            {...pvrProps}
            nibColor="white"
            height={pvrProps?.height ? pvrProps.height : 252}
            width={pvrProps?.width ? pvrProps.width : 226}
            anchor={this.inputEl}
            close={() => this.handleToggleCalendar(false)}
          >
            <DayPicker
              initialMonth={initMonth}
              selectedDays={val}
              {...dayPickerProps}
              locale={locale}
              localeUtils={MomentLocaleUtils}
              onDayClick={this.handleDateChange}
              onDayTouchEnd={this.handleDateChange}
              close={() => this.handleToggleCalendar(false)}
              backdrop={false}
            />
          </Pvr>
          : null}
      </>
    );
  }

  public handleToggleCalendar = (value: boolean, e?: React.FocusEvent<HTMLInputElement>) => {
    if (e && this.inputEl == null) {
      this.inputEl = e.currentTarget;
    }

    this.setState({
      calendarVisible: value,
    });
  };

  public handleDateInputChange = (value: string, jsonPath: string): void => {
    this.setState({
      inputValue: value,
    });
  };

  public handleDateInputSet = (): void => {
    const { validation, validate, onChange, jsonPath, format, allowBlankDate } = this.props;
    const { inputValue } = this.state;
    // if there is a value or the date picker does not allow for a blank date then set to moment date else set to empty string
    const momentDateVal = (inputValue || !allowBlankDate) ? moment(inputValue, format) : '';
    // if not empty string and valid date set else leave as empty string
    const dateVal = (momentDateVal !== '' && momentDateVal.isValid()) ? momentDateVal.format(format) : '';

    const state: IDatepickerState = {
      inputValue: dateVal,
      valueHasChanged: false,
    };

    validate({ validation, value: inputValue });

    // Only update the state when valid or date is empty
    if (momentDateVal === '' || momentDateVal.isValid()) {
      state.value = momentDateVal;
      state.valueHasChanged = true;
    }

    this.setState(state, () => {
      if (typeof onChange === 'function') {
        onChange(inputValue, jsonPath);
      }
    });
  };

  public handleDateChange = (date: Date, modifiers: any, e): void => {
    e.preventDefault();
    const { validation, jsonPath, validate, format, onChange } = this.props;
    const momentDateVal = moment(date);
    const dateVal = momentDateVal.format(format);

    // Do nothing if it is disabled
    if (modifiers.disabled) {
      return;
    }

    validate({ validation, value: dateVal});

    this.setState({
      value: momentDateVal,
      inputValue: dateVal,
      calendarVisible: false,
      valueHasChanged: true,
    }, () => {
      return (typeof onChange === 'function' ? onChange(dateVal, jsonPath) : undefined);
    });
  };

  public getDateValue() {
    const { returnDateFormat, hasDefaultDate, allowBlankDate} = this.props;
    const { value } = this.state;

    // if hasDefaultDate is false and nothing is selected then return an empty string
    if ((returnDateFormat != null) && (value != null) && (value !== '')) {
      return (value as moment.Moment).format(returnDateFormat);
    } else if (value != null && value !== '') {
      return value;
    } else if (hasDefaultDate && allowBlankDate === false) {
      return moment();
    } else {
      return null;
    }
  }

  public getValue(): string | moment.Moment | Date {
    return this.getDateValue();
  }
}
