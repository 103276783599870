import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { MOBILE_APPS } from '../app/global/constants';

export interface IOverlayProps {
  close?: () => any;
  backdrop?: boolean;
  backdropClose?: boolean;
  root?: Element;
  nonBlocking: boolean;
  zIndex?: number;
  fadeBackground?: boolean;
  startBackgroundFade?: boolean;
}

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/overlay.styl');
    break;
  default:
    styles = require('./styles/overlay.styl');
    break;
}

/**
 * @param close Function called on overlay close events, such as clicking the backdrop.
 * @param backdrop Whether or not to display a backdrop behind this overlay.
 * @param backdropClose Whether to close the overlay - default is true
 * @param root The base element for the overlays container. Defaults to an element with the ID of `overlays`.
 * @param nonBlocking When true, the overlay container will have no width/height and pass mouse events through.
 * @param zIndex Optional zIndex property to manually set the zIndex of an overlay
 */
export default class Overlay extends React.Component<IOverlayProps, any> {
  private overlayEl: HTMLDivElement;
  private overlaysContainer: Element;

  public static defaultProps: Partial<IOverlayProps> = {
    backdrop: true,
    backdropClose: true,
    nonBlocking: false,
  };

  public constructor(props) {
    super(props);
    const { nonBlocking, backdrop, zIndex } = this.props;

    this.overlayEl = document.createElement('div');
    this.overlayEl.className = `${styles.Overlay} ${backdrop ? '' : styles.NoBackdrop} ${nonBlocking ? styles.NonBlocking : ''}`;

    // Figure out how to z-index the overlay
    const ovrl = document.getElementById('overlays');
    this.overlayEl.style.zIndex = zIndex ? `${zIndex}` : ((ovrl != null && ovrl.children.length > 0) ? ovrl.children.length + 1 + '' : '1');

    // Prevent bounce scrolling on mobile app
    if (process.env.MOBILE_APP) {
      this.overlayEl.style.position = 'fixed';
      this.overlayEl.style.height = '100vh';
      this.overlayEl.style.top = '0';
      this.overlayEl.style.right = '0';
      this.overlayEl.style.bottom = '0';
      this.overlayEl.style.left = '0';
    }
  }

  public componentDidMount(): void {
    const { root = document.getElementById('overlays'), nonBlocking } = this.props;

    // Check for a root location for the overlays to live
    if (root == null) {
      this.overlaysContainer = document.createElement('div');
      this.overlaysContainer.id = 'overlays';
      document.body.appendChild(this.overlaysContainer);
    }
    else {
      this.overlaysContainer = root;
    }

    if (nonBlocking) { this.overlaysContainer.className = styles.NonBlocking; }

    // add this Overlay to the overlays container
    this.overlaysContainer.appendChild(this.overlayEl);

    // Focus the overlay
    this.overlayEl.focus();
  }

  public componentWillUnmount(): void {
    // Remove the destination element inside the overlays container
    this.overlaysContainer?.removeChild(this.overlayEl);

    // If there are no more open overlays, then remove the overlays container
    if (this.overlaysContainer.children.length === 0) {
      this.overlaysContainer.parentNode?.removeChild(this.overlaysContainer);
    }
  }

  public render(): React.ReactPortal {
    const { children, startBackgroundFade , fadeBackground } = this.props;
    return ReactDOM.createPortal((
      <div className={`${styles.Backdrop} ${fadeBackground ? styles.FadeInBackground : null} ${startBackgroundFade ? styles.FadeOutBackground : null}`}
        onMouseDown={this.handleBackdropClick}>
        {children}
      </div>
    ), this.overlayEl);
  }

  public handleBackdropClick = (e: React.MouseEvent<HTMLDivElement>): void => {
    const { close, backdropClose } = this.props;
    e.stopPropagation();

    if (backdropClose) {
      close?.();
    }
  };
}
