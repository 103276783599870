import * as React from 'react';
import * as Spin from 'spin';

interface IProps {
  className?: string;
}

export default class Loader extends React.Component<IProps, any> {
  private el: HTMLDivElement;

  public componentDidMount() {
    const spinner = new Spin({
      lines: 12, // The number of lines to draw
      length: 10, // The length of each line
      width: 4, // The line thickness
      radius: 15, // The radius of the inner circle
      corners: 2, // Corner roundness(0..1)
      rotate: 0, // The rotation offset
      direction: 1, // 1: clockwise, - 1: counterclockwise
      color: '#737373', // rgb or rrggbb or array of colors
      speed: 1, // Rounds per second
      trail: 60, // Afterglow percentage
      shadow: false, // Whether to render a shadow
      hwaccel: false, // Whether to use hardware acceleration
      zIndex: 15, // The z - index(defaults to 2000000000)
    }).spin();

    this.el.appendChild(spinner.el);

  }

  public render(): JSX.Element {
    const { className } = this.props;

    return (
      <div
        className={className}
        ref={(el) => this.el = el}
      />
    );
  }

}
