import * as React from 'react';

const styles = require('./styles/progress_bar.styl');

export interface IProgressBarProps {
  progress?: string | number;
  showLabel?: boolean;
  labelPosition?: 'center' | 'top';
  indeterminate?: boolean;
  className?: string;
  wrapperClassName?: string;
}

export interface IProgressBarState {
  indPos?: number;
  progWidth?: number;
}

export default class ProgressBar extends React.Component<IProgressBarProps, IProgressBarState> {
  public static defaultProps: IProgressBarProps = {
    progress: 0, // Progress percentage. Ranges from 0-100. Can also be a text label, e.g. 'Loading...'
    showLabel: true,
    labelPosition: 'center',
    indeterminate: false, // Ignore the progress value for width calculation. Will show an indeterminate progress bar.
    className: '',
    wrapperClassName: '',
  };

  public state = {
    indPos: 0,
    progWidth: 0,
  };

  private progressBar: HTMLElement;

  private ind: number;

  public componentWillUnmount(): void {
    clearInterval(this.ind);
    return window.removeEventListener('resize', this.resizeWindow);
  }

  public componentDidMount(): void {
    if (this.props.indeterminate) {
      return this.setState(
        { progWidth: this.progressBar.clientWidth }
        , () => {
          return this.startProgress();
        });
    }
  }

  public render(): JSX.Element {
    let bars;
    const { className, showLabel, labelPosition, indeterminate, wrapperClassName } = this.props;
    let { progress } = this.props;
    const { indPos, progWidth } = this.state;
    const progressClass = `${styles.ProgressBar} ${className}`;

    // If the progress value is a number, put a percentage on it
    if (typeof progress === 'number') {
      progress = `${progress}%`;
    }

    let style: React.CSSProperties = {
      width: progress,
    };

    // Indeterminate progress bar
    if (indeterminate) {
      bars = [];
      const tickWidth = 25;
      const numTicks = Math.floor(progWidth / tickWidth);
      style = {
        width: (numTicks + 2) * tickWidth, // Add an extra 2 for the buffer when moving the bar
        transform: `translateX(${indPos}px) translateZ(0px)`,
        msTransform: `translate(${indPos}px)`,
        WebkitTransform: `translateX(${indPos}px)`,
      };

      // Add ticks according to the width of the element
      for (let i = 0, end = numTicks, asc = 0 <= end; asc ? i <= end : i >= end; asc ? i++ : i--) {
        bars.push(
          <div
            key={i}
            className={styles.IndBar}
            style={{
              width: 13,
              marginRight: 13,
            }}
          />
        );
      }
    }

    return (
      <div className={`${styles.ProgressBarContainer} ${wrapperClassName}`}>
        {showLabel && labelPosition === 'top' ?
          <div className={`${styles.ProgressLabel} ${styles[labelPosition]}`}>{progress}</div>
          : null}
        <div className={progressClass} ref={(progressBar) => this.progressBar = progressBar}>
          <div className={`${styles.Progress} ${indeterminate ? styles.Ind : ''}`} style={style}>{bars}</div>
          {showLabel && labelPosition === 'center' ?
            <div className={styles.ProgressLabel}>{progress}</div>
            : null}
        </div>
      </div>
    );
  }

  public startProgress(): void {
    clearInterval(this.ind);
    this.ind = window.setInterval(() => {
      const { indPos } = this.state;
      const pos = indPos <= -26 ? 0 : indPos - 1;

      return this.setState({
        indPos: pos,
      });
    }, 60);

    return window.addEventListener('resize', this.resizeWindow);
  }

  public resizeWindow = (): void => {
    this.setState({
      progWidth: this.progressBar.clientWidth,
    }, this.startProgress);
  };
}
