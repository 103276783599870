module.exports = {
  "__appName__ is not currently available.": "__appName__ steht momentan nicht zur Verfügung.",
  "Access Code": "Zugriffscode",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Konto ${this.username} wurde aufgrund von 5 Anmeldeversuchen gesperrt. Zum Entsperren Administrator benachrichtigen.",
  "Afghanistan": "Afghanistan",
  "Albania": "Albanien",
  "Algeria": "Algerien",
  "American Samoa": "Amerikanisch-Samoa",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua und Barbuda",
  "App": "App",
  "Application Error": "Anwendungsfehler",
  "Application error.": "Anwendungsfehler.",
  "Application is not currently available.": "Die Anwendung steht momentan nicht zur Verfügung.",
  "Apply Changes": "Änderungen übernehmen",
  "Apr": "Apr",
  "April": "April",
  "Argentina": "Argentinien",
  "Armenia": "Armenien",
  "Aruba": "Aruba",
  "At least __value__ characters": "Mindestens __value__ Zeichen",
  "At least one lowercase letter": "Mindestens einen Kleinbuchstaben",
  "At least one number": "Mindestens eine Zahl",
  "At least one of these special characters: __value__": "Mindestens eines dieser Sonderzeichen: __value__",
  "At least one uppercase letter": "Mindestens einen Großbuchstaben",
  "Aug": "Aug",
  "August": "August",
  "Australia": "Australien",
  "Austria": "Österreich",
  "Azerbaijan": "Aserbaidschan",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesch",
  "Barbados": "Barbados",
  "Belarus": "Weißrussland",
  "Belgium": "Belgien",
  "Belize": "Belize",
  "Benin": "Benin",
  "Bermuda": "Bermuda",
  "Bhutan": "Bhutan",
  "Bluetooth is Turned Off": "Bluetooth ist ausgeschaltet",
  "Bolivia": "Bolivien",
  "Bosnia and Herzegovina": "Bosnien und Herzegowina",
  "Botswana": "Botswana",
  "Brazil": "Brasilien",
  "Bronchial Lavage": "Bronchiallavage",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgarien",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Kambodscha",
  "Cameroon": "Kamerun",
  "Canada": "Kanada",
  "Cancel": "Abbr.",
  "Cannot be empty": "Darf nicht leer sein",
  "Cape Verde": "Kap Verde",
  "Capillary Blood": "Kapillarblut",
  "Cayman Islands": "Kaimaninseln",
  "Central African Republic": "Zentralafrikanische Republik",
  "Cerebrospinal Fluid": "Cerebrospinal-Flüss.",
  "Cervical Swab": "Zervikalabstrich",
  "Chad": "Tschad",
  "Change Access Code": "Zugriffscode ändern",
  "Check again": "Erneut prüfen",
  "Chile": "Chile",
  "China": "China",
  "Clear Search": "Suche löschen",
  "Close": "Schließen",
  "Cocos(Keeling) Islands and Chr": "Kokosinseln (Keelinginseln) und Weihnachtsinsel",
  "Colombia": "Kolumbien",
  "Comoros": "Komoren",
  "Confirm": "Bestätigen",
  "Confirm New Password": "Neues Passwort bestätigen",
  "Confirm new password": "Neues Passwort bestätigen",
  "Confirm New Password is required.": "Neues Passwort bestätigen ist erforderlich.",
  "Confirm Password is required.": "Passwort bestätigen ist erforderlich.",
  "Confirm Password must match New Password": "Bestätigungspasswort muss mit neuem Passwort übereinstimmen",
  "Congo(Brazzaville)": "Kongo (Brazzaville)",
  "Congo(Kinshasa)": "Kongo (Kinshasa)",
  "Cook Islands": "Cookinseln",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ – LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Côte D'Ivoire (Elfenbeinküste)",
  "Croatia(Hrvatska)": "Kroatien (Hrvatska)",
  "Cuba": "Kuba",
  "Culture Broth": "Kultur",
  "Current Password": "Aktuelles Passwort",
  "Current Password is required.": "Aktuelles Passwort ist erforderlich.",
  "Current password required": "Aktuelles Passwort erforderlich",
  "Cyprus": "Zypern",
  "Czech Republic": "Tschechische Republik",
  "Danish": "Dänisch",
  "Dec": "Dez",
  "December": "Dezember",
  "Denmark": "Dänemark",
  "Disabled": "Deaktiviert",
  "Discard Changes": "Änderungen verwerfen",
  "Dismiss": "Abweisen",
  "Djibouti": "Dschibuti",
  "Does not match your previous passwords": "Stimmt nicht mit Ihren letzten Passwörtern überein",
  "Does not use unsupported characters": "Verwendet keine nicht unterstützten Zeichen",
  "Domain": "Domain",
  "Domain is required.": "Domain ist erforderlich.",
  "Dominica": "Dominica",
  "Dominican Republic": "Dominikanische Republik",
  "Door Open": "Tür offen",
  "Dutch": "Niederländisch",
  "Ecuador": "Ecuador",
  "Egypt": "Ägypten",
  "El Salvador": "El Salvador",
  "Email": "E-Mail",
  "Email is required.": "E-Mail ist erforderlich.",
  "English (UK)": "Englisch (GB)",
  "English (US)": "Englisch (USA)",
  "Enter": "Eingabe",
  "Equatorial Guinea": "Äquatorialguinea",
  "Eritrea": "Eritrea",
  "Error": "Fehler",
  "Estonia": "Estland",
  "Ethiopia": "Äthiopien",
  "Factory Manager is not supported in the Connect mobile application.": "Factory Manager wird in der mobilen Connect-App nicht unterstützt.",
  "Failed to reset password. A server error was encountered": "Zurücksetzen des Passworts fehlgeschlagen. Es wurde ein Serverfehler erkannt",
  "Falkland Islands(Malvinas)": "Falklandinseln (Malvinas)",
  "Faroe Islands": "Färöer",
  "Fatal Application Error": "Schwerwiegender Anwendungsfehler",
  "Feb": "Feb",
  "February": "Februar",
  "Fecal Specimen": "Stuhlprobe",
  "Fiji": "Fidschi",
  "File type must be __fileType__": "Dateityp muss __fileType__ sein",
  "Finland": "Finnland",
  "Finnish": "Finnisch",
  "First Name": "Vorname",
  "Forgot your password?": "Passwort vergessen?",
  "Fr": "Fr",
  "France": "Frankreich",
  "French": "Französisch",
  "French Guiana": "Französisch-Guayana",
  "French Polynesia": "Französisch-Polynesien",
  "Gabon": "Gabun",
  "Gambia": "Gambia",
  "Georgia": "Georgia",
  "German": "Deutsch",
  "Germany": "Deutschland",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Zum __storeName__ gehen",
  "Greater than __value__ in __measure__": "Größer als __value__ von __measure__",
  "Greece": "Griechenland",
  "Greenland": "Grönland",
  "Grenada": "Grenada",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinea",
  "Guinea - Bissau": "Guinea-Bissau",
  "Guyana": "Guyana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Hct außerhalb Bereich",
  "Hide": "Verbergen",
  "Hide password": "Passwort verbergen",
  "Holy See(Vatican City State)": "Vatikan",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hongkong, SAR",
  "Hungary": "Ungarn",
  "Iceland": "Island",
  "India": "Indien",
  "Indonesia": "Indonesien",
  "Initials": "Initialen",
  "Install": "Installieren",
  "Install Now": "Jetzt installieren",
  "Instrument Not Level": "Instrument nicht waagerecht",
  "Insufficient Sample": "Nicht genügend Probe",
  "Internet connection is offline.": "Internetverbindung ist offline.",
  "Invalid access code": "Ungültiger Zugriffscode",
  "Invalid email format": "Ungültiges E-Mail-Format",
  "Invalid File": "Ungültige Datei",
  "Invalid Lot Cal File": "Chargen-Kal.-Datei ungültig",
  "Invalid username or password.": "Ungültiger Benutzername oder ungültiges Passwort.",
  "Iran, Islamic Republic of": "Iran, Islamische Republik",
  "Iraq": "Irak",
  "Ireland": "Irland",
  "Israel": "Israel",
  "Italian": "Italienisch",
  "Italy": "Italien",
  "Jamaica": "Jamaika",
  "Jan": "Jan",
  "January": "Januar",
  "Japan": "Japan",
  "Japanese": "Japanisch",
  "Jordan": "Jordanien",
  "Jul": "Jul",
  "July": "Juli",
  "Jun": "Jun",
  "June": "Juni",
  "Kazakhstan": "Kasachstan",
  "KC Swab": "KC-Abstrich",
  "Kenya": "Kenia",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Korea, Demokratische Volksrep.",
  "Korea, Republic of(South)": "Korea, Republik (Südkorea)",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Kirgisistan",
  "Language": "Sprache",
  "Laos(Lao PDR)": "Laos (Demokratische Volksrepublik Laos)",
  "Last Name": "Nachname",
  "Latvia": "Lettland",
  "Lebanon": "Libanon",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Kleiner als __value__ von __measure__",
  "Liberia": "Liberia",
  "Libya": "Libyen",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Flüssig-QK",
  "Lithuania": "Litauen",
  "Loading more records...": "Weitere Datensätze werden geladen…",
  "Login": "Anmelden",
  "Logout": "Abmelden",
  "Luxembourg": "Luxemburg",
  "Macao(SAR China)": "Macao (SAR China)",
  "Macedonia, Republic of": "Mazedonien, Republik",
  "Madagascar": "Madagaskar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysia",
  "Maldives": "Malediven",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mär",
  "March": "März",
  "Marshall Islands": "Marshallinseln",
  "Martinique": "Martinique",
  "Mauritania": "Mauretanien",
  "Mauritius": "Mauritius",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "Maximale Länge sind __count__ Zeichen",
  "May": "Mai",
  "Mexico": "Mexiko",
  "Micronesia, Federated States o": "Mikronesien, Föderierte Staaten von",
  "Mo": "Mo",
  "Mobile Application Version": "Mobile App-Version",
  "Moldova": "Moldova",
  "Monaco": "Monaco",
  "Mongolia": "Mongolei",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marokko und Westsahara",
  "Mouth Swab": "Mundabstrich",
  "Mozambique": "Mosambik",
  "Myanmar": "Myanmar",
  "Namibia": "Namibia",
  "Nasal Discharge": "Nasalflüssigkeit",
  "Nasal Swab": "Nasalabstrich",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Niederlande",
  "Netherlands Antilles": "Niederländische Antillen",
  "Never Connected": "Nie verbunden",
  "New Caledonia": "Neukaledonien",
  "New Password": "Neues Passwort",
  "New Password is invalid.": "Neues Passwort ist ungültig.",
  "New Password is required.": "Neues Passwort ist erforderlich.",
  "New password must match": "Neues Passwort muss übereinstimmen",
  "New Version Required": "Neue Version erforderlich",
  "New Zealand": "Neuseeland",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "In dieser Umgebung sind keine Anwendungsmodule vorhanden. Bitte melden Sie dieses Problem einem Administrator.",
  "No easily guessable words": "Keine leicht zu erratenden Wörter",
  "No more than __value__ characters": "Nicht mehr als __value__ Zeichen",
  "No region set": "Keine Reg. eingest.",
  "No repetitive characters (e.g. aaa)": "Keine sich wiederholenden Zeichen (z. B. aaa)",
  "No sequential characters (e.g. 123)": "Keine fortlaufenden Zeichen (z. B. 123)",
  "No Test Strip Detected": "Kein Teststreifen erkannt",
  "none": "keine",
  "Norfolk Island": "Norfolkinsel",
  "Northern Mariana Islands": "Nördliche Marianen",
  "Norway(inc Svalbard and Jan May": "Norwegen (einschl. Spitzbergen und Jan Mayen)",
  "Norwegian": "Norwegisch",
  "Nov": "Nov",
  "November": "November",
  "NP Aspirate": "NP-Flüssigkeit",
  "NP Swab": "NP-Abstrich",
  "Oct": "Okt",
  "October": "Oktober",
  "Offline 1 day": "Offline 1 Tag",
  "Offline 1 hour": "Offline 1 Stunde",
  "Offline 1 minute": "Offline 1 Minute",
  "Offline 1 month": "Offline 1 Monat",
  "Offline 1 year": "Offline 1 Jahr",
  "Offline __days__ days": "Offline __days__ Tage",
  "Offline __hours__ hours": "Offline __hours__ Stunden",
  "Offline __minutes__ minutes": "Offline __minutes__ Minuten",
  "Offline __months__ months": "Offline __months__ Monate",
  "Offline __years__ years": "Offline __years__ Jahre",
  "OK": "OK",
  "Okay": "Okay",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Palästinensisches Gebiet, Besetztes",
  "Panama": "Panama",
  "Papua New Guinea": "Papua-Neuguinea",
  "Paraguay": "Paraguay",
  "Password": "Passwort",
  "Password requirements:": "Anforderungen an das Passwort:",
  "Peru": "Peru",
  "Philippines": "Philippinen",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polen",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugiesisch (Brasilien)",
  "Portuguese (Portugal)": "Portugiesisch (Portugal)",
  "Profile": "Profil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Zum Festlegen Ihres Passworts geben Sie bitte die folgenden Informationen ein.  Der Validierungscode wurde an Ihre gültige E-Mail-Adresse gesendet.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Geben Sie Ihre E-Mail-Adresse an, um eine Passwort-Rücksetzung anzufordern. Ein temporärer Validierungscode wird an Ihre E-Mail-Adresse gesendet.",
  "Puerto Rico": "Puerto Rico",
  "Qatar": "Katar",
  "QC Solution Detected": "QK-Lösung erkannt",
  "Rectal Swab": "Rektaler Abstrich",
  "Required Update": "Erforderliches Update",
  "Reset": "Zurücks.",
  "Reset Password": "Passwort zurücksetzen",
  "Reset password": "Passwort zurücksetzen",
  "Resolution requirements": "Anforderungen zur Lösung",
  "Return to Login": "Zurück zur Anmeldung",
  "Romania": "Rumänien",
  "Russian Federation": "Russische Föderation",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Réunion und Mayotte",
  "Sa": "Sa",
  "Saint Helena and Tristan da Cu": "Saint Helena und Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint Kitts und Nevis",
  "Saint Lucia": "Saint Lucia",
  "Saint Pierre and Miquelon": "Saint-Pierre und Miquelon",
  "Saint Vincent and the Grenadine": "Saint Vincent und die Grenadinen",
  "Saliva": "Speichel",
  "Samoa": "Samoa",
  "Sample Detected": "Probe erkannt",
  "Sample Error": "Probenfehler",
  "Sample Type Error": "Probentyp-Fehler",
  "San Marino": "San Marino",
  "Saudi Arabia": "Saudi-Arabien",
  "Save": "Speichern",
  "Select a date": "Ein Datum auswählen",
  "Select from list...": "Auswählen aus Liste...",
  "Select language": "Sprache auswählen",
  "Selected files must match requirements": "Ausgewählte Dateien müssen die Anforderungen erfüllen",
  "Senegal": "Senegal",
  "Sep": "Sep",
  "September": "September",
  "Serbia": "Serbien",
  "Serum": "Serum",
  "Server error, please try again later.": "Serverfehler. Bitte versuchen Sie es später erneut.",
  "Server Error. Unable to save your profile.": "Serverfehler. Ihr Profil konnte nicht gespeichert werden.",
  "Set Password": "Passwort festlegen",
  "Seychelles": "Seychellen",
  "Show Password": "Passwort anzeigen",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapur",
  "Size must be less than __maxSize__": "Größe muss unter __maxSize__ liegen",
  "Skin Swab": "Hautabstrich",
  "Slovakia": "Slowakei",
  "Slovenia": "Slowenien",
  "Solomon Islands": "Salomonen",
  "Somalia": "Somalia",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Leider ist DataView nicht aktiviert oder Sie haben keinen Zugriff auf diese Organisation.",
  "South Africa": "Südafrika",
  "Space": "Leerzeichen",
  "Spain": "Spanien",
  "Spanish": "Spanisch",
  "Spanish (US)": "Spanisch (USA)",
  "Sputum": "Sputum",
  "Sri Lanka": "Sri Lanka",
  "Su": "So",
  "Sudan": "Sudan",
  "Suriname": "Suriname",
  "Swaziland": "Swasiland",
  "Sweden": "Schweden",
  "Swedish": "Schwedisch",
  "Switzerland": "Schweiz",
  "Syrian Arab Republic(Syria)": "Syrische Arabische Republik (Syrien)",
  "São Tomé and Príncipe": "São Tomé und Príncipe",
  "Taiwan, Republic of China": "Taiwan, Republik China",
  "Tajikistan": "Tadschikistan",
  "Tanzania, United Republic of": "Tansania, Vereinigte Republik",
  "Temperature Error": "Temperaturfehler",
  "Test Cancelled": "Test abgebrochen",
  "Test Lot Not Permitted": "Test-Charge nicht gestattet",
  "Test Operation Error": "Testbetriebsfehler",
  "Test Strip Lot Expired": "Teststreifen-Charge abgelaufen",
  "Test Strip Not Supported": "Teststreifen nicht unterstützt",
  "Test Strip Reading Error": "Teststreifen-Lesefehler",
  "Test Timeout": "Test-Timeout",
  "Test Type Not Permitted": "Testtyp nicht gestattet",
  "Th": "Do",
  "Thailand": "Thailand",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Der neue Passwort-Validierungscode wird an die E-Mail-Adresse gesendet, wenn es sich dabei um eine gültige E-Mail-Adresse des Benutzers handelt.",
  "The reset validation code was populated from the link and should not be changed.": "Der Validierungscode zum Zurücksetzen wurde automatisch aus dem Link eingefügt und sollte nicht geändert werden.",
  "There are unsaved changes. How do you want to proceed?": "Es sind nicht gespeicherte Änderungen vorhanden. Wie möchten Sie fortfahren?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Es ist eine neue Version (__versionNumber__) verfügbar. Möchten Sie diese jetzt installieren?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Dieses Gerät wird nicht mehr als Connect Hub betrieben. Um dieses Gerät weiterhin als Connect Hub zu verwenden, schalten Sie in Ihren Geräteeinstellungen Bluetooth erneut ein und wählen Sie im Bildschirm „Hub“ die Option „Dieses Mobilgerät als Connect Hub verwenden“.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Diese Version von LumiraDx Connect ist nicht mehr kompatibel mit Ihrer Organisation. Bitte gehen Sie zum __storeName__ und führen Sie ein Update auf die neueste Version durch.",
  "Throat Swab": "Rachenabstrich",
  "Timor - Leste": "Osttimor",
  "Title": "Titel",
  "TN Swab": "RN-Abstrich",
  "to": "bis",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Um __APP_NAME__ weiter nutzen zu können, führen Sie bitte ein Upgrade auf die neueste Version (__versionNumber__) durch.",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinidad und Tobago",
  "Tu": "Di",
  "Tunisia": "Tunesien",
  "Turkey": "Türkei",
  "Turkmenistan": "Turkmenistan",
  "Turks and Caicos Islands": "Turks- und Caicosinseln",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ukraine",
  "Unable to reset password, the provided current password is not valid.": "Kann Passwort nicht zurücksetzen; das eingegebene aktuelle Passwort ist ungültig.",
  "Unable to reset your password.": "Ihr Passwort kann nicht zurückgesetzt werden.",
  "Unable to validate access code.": "Zugriffscode kann nicht validiert werden.",
  "United Arab Emirates": "Vereinigte Arabische Emirate",
  "United Kingdom": "Vereinigtes Königreich",
  "United States of America": "Vereinigte Staaten von Amerika",
  "Unrecognized credentials. Please try again.": "Anmeldedaten nicht erkannt. Bitte erneut versuchen.",
  "Unsaved Changes": "Nicht gespeicherte Änderungen",
  "Update Available": "Update verfügbar",
  "Update Password": "Passwort aktualisieren",
  "Urethral Swab": "Urethraler Abstrich",
  "Urine": "Urin",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Benutzerkonto ist gesperrt. Bitte versuchen Sie es erneut in einer Stunde.",
  "User Interface Version": "Benutzeroberflächenversion",
  "User logged out due to inactivity.": "Benutzer mangels Aktivität abgemeldet.",
  "Username": "Benutzername",
  "Uzbekistan": "Usbekistan",
  "Vaginal Swab": "Vaginalabstrich",
  "Validate Access Code": "Zugriffscode validieren",
  "Validation Code": "Validierungscode",
  "Validation Code is required.": "Validierungscode ist erforderlich.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (Bolivarische Republik)",
  "Venous Blood": "Venöses Blut",
  "Version": "Version",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Jungferninseln, Britische",
  "Virgin Islands, US": "Jungferninseln, US",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Vulva-Vaginalabstr.",
  "Wallis and Futuna Islands": "Wallis und Futuna",
  "We": "Mi",
  "We are working to get you back up and running as quickly as possible.": "Wir arbeiten daran, die Funktion für Sie so schnell wie möglich wiederherzustellen.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Leider ist LumiraDx Connect im Moment wegen Wartungsarbeiten nicht erreichbar.",
  "Yemen": "Jemen",
  "You have been signed out due to a password reset attempt.": "Sie wurden abgemeldet, weil versucht wurde, Ihr Passwort zurückzusetzen.",
  "You have been signed out due to the creation of another session with the same credentials.": "Sie wurden abgemeldet, weil eine andere Sitzung mit den gleichen Anmeldedaten eröffnet wurde.",
  "You have been signed out due to your account being disabled by an admin.": "Sie wurden abgemeldet, weil Ihr Konto vom Administrator deaktiviert wird.",
  "You have been signed out due to your password being changed by an admin.": "Sie wurden abgemeldet, weil Ihr Passwort vom Administrator geändert wird.",
  "You have been signed out due to your user role being changed by an admin.": "Sie wurden abgemeldet, weil Ihre Benutzerrolle vom Administrator geändert wird.",
  "You have been signed out.": "Sie wurden abgemeldet.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Sie müssen eine andere Version (__versionNumber__) von __APP_NAME__ installieren, um mit Ihrer Organisation kompatibel zu sein.",
  "Your password has expired. Please set a new password.": "Ihr Passwort ist abgelaufen. Bitte legen Sie ein neues Passwort fest.",
  "Your session has expired. Please login again.": "Ihre Sitzung wurde beendet. Bitte melden Sie sich erneut an.",
  "Your session has expired. User is not authorized.": "Ihre Sitzung wurde beendet. Benutzer ist nicht autorisiert.",
  "Zambia": "Sambia",
  "Zimbabwe": "Simbabwe"
};
