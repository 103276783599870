import * as React from 'react';
import Spinner, { SpinnerNames } from './Spinner';
const styles = require('./styles/custom_select.styl');

interface IProps {
  getNextOptionsPage: () => void;
  optionsFullyLoaded: boolean;
}

export default class SelectCustomLoadingRow extends React.Component<IProps, any> {
  public loaderElement: React.RefObject<HTMLLIElement> = React.createRef();

  componentDidMount(): void {
    const { getNextOptionsPage } = this.props;

    if (window.IntersectionObserver != null) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio <= 0 || this.props.optionsFullyLoaded) return;
        getNextOptionsPage();
      }, {
        root: document.getElementById('OptionsList'),
        threshold: .5,
      });
      intersectionObserver.observe(this.loaderElement.current as any);
    }
  }

  public render(): JSX.Element {
    return (
      <li key="loading-row" id="LoadingRow" ref={this.loaderElement}>
        <Spinner
          key="spinner"
          wrapperClass={styles.LoadingSpinner}
          name={SpinnerNames.BallScaleRipple}
          color="gray"
        />
      </li>
    );
  }


}
