import { SR } from '@Lineup/index';
import * as LocalStorage from './LocalStorage';

/**
 * Request singleton for Consult calls (configured by PDE module)
 */
const ConsultReq = new SR({});

/**
 * Request singleton for Router calls (configured by PDE module)
 */
const RouterReq = new SR({});

/**
 * Request singleton for Reporting calls (configured by Connect module)
 */
const ReportingReq = new SR({});

export { LocalStorage, ConsultReq, RouterReq, ReportingReq };
