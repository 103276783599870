import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { AlertModal } from '@Lineup/index';

import { IComponent, InjectedComponent } from '../../global/interfaces';
import { BridgeCommands } from '../../global/enums';
import { AlertTypes } from '@Lineup/enums/index';

import * as compareVersions from 'semver/functions/compare';
import * as satisfies from 'semver/functions/satisfies';
import * as coerce from 'semver/functions/coerce';
import { APP_NAME, MOBILE_APPS } from '../../global/constants';

@inject('mobileBridgeStore', 'loggedInUserStore') @observer
class MobileUiUpdate extends InjectedComponent<IComponent, any> {

  public render(): JSX.Element {
    const { uiVersionUpdate, uiUpdateAlertOpen, appData, uiUpdateForce } = this.props.mobileBridgeStore;
    const { accessDto } = this.props.loggedInUserStore;

    if (accessDto == null && !uiUpdateForce) {
      return null;
    }

    let uiSupportedVersionIsCompatible = true;

    if (accessDto && accessDto.Version && accessDto.UiApiUrl && appData) {
      const { UIVersion } = appData;
      const { Version } = accessDto;

      // coerce will set the version as an object with major, minor, version (major, minor, patch), etc
      const coerceSupportedUIVersion = coerce(Version);
      const coerceAppUIVersion = coerce(UIVersion);
      // get the version this will be in the format major.minor.patch
      const supportedUIVersion = coerceSupportedUIVersion.version;
      const appUIVersion = coerceAppUIVersion.version;
      // see if the 2 versions are equal to each other
      const uiVersionsCompatible = compareVersions(UIVersion, supportedUIVersion) === 0;

      // if the app ui version is not compatible with the org ui version
      if (!uiVersionsCompatible) {
        // check the app's UIVersion against the major and minor supported version to see if they user needs to be forced to update the UI
        // if UIVersion is any where in the major.minor range it will return true
        uiSupportedVersionIsCompatible = satisfies(appUIVersion, `~${coerceSupportedUIVersion.major}.${coerceSupportedUIVersion.minor}`);
      }
    }

    // only show these pop overs with native app is compatible
    return uiUpdateAlertOpen ? (
      !uiSupportedVersionIsCompatible ? (
        <AlertModal
          alertIcon={AlertTypes.DOWNLOAD}
          close={this.handleInstallClick}
          alertTitle={t('New Version Required')}
          message={process.env.MOBILE_APP === MOBILE_APPS.CONNECT ? t('You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.', { APP_NAME, versionNumber: uiVersionUpdate.UIVersion }) : t('To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).', { APP_NAME, versionNumber: uiVersionUpdate.UIVersion })}
          cancelText={t('Install Now')}
        />
      ) : (
        <AlertModal
          alertIcon={AlertTypes.DOWNLOAD}
          close={this.handleAlertClose}
          alertTitle={t('Update Available')}
          message={t('There is a new version available (__versionNumber__), would you like to install it now?', { versionNumber: uiVersionUpdate.UIVersion })}
          cancelText={t('Cancel')}
          okText={t('Install')}
          cb={this.handleInstallClick}
        />
      )
    ) : null;
  }

  public handleAlertClose = (): void => {
    const { openUpdateAlert } = this.props.mobileBridgeStore;
    openUpdateAlert(false);
  };

  public handleInstallClick = (): void => {
    const { tellMobileApp, openUpdateAlert, setConnectData } = this.props.mobileBridgeStore;
    openUpdateAlert(false);
    // Set a callback so the UI can try to return to where it was after the update
    setConnectData({
      mobileUpdateCallback: location.hash.substr(1),
    });
    tellMobileApp(BridgeCommands.RELOAD_UI);
  };

}

export default MobileUiUpdate;
