import * as React from 'react';
const styles = require('./styles/scroll_container.styl');

/**
 * Used to stopPropagation on touch moves events which can be leveraged to improve iOSs crapping scroll behavior
 * @param className - css class to be aplied
 * @param list - when passed the scrolling element will be a <ul> instead of a <div>
 * @param style - inline styles to be applied
 * @param initialScrollPosition - intial scroll position of the element
 *
 */
interface IProps {
  className: string;
  wrapperClassName?: string;
  list?: boolean;
  style?: React.CSSProperties;
  initialScrollPosition?: number;
  [key: string]: any;
  updateScrollStatus?: (value: boolean) => void;
  onScroll?: (e: React.UIEvent<HTMLElement>) => any;
  handleLoadMore?: () => any;
  loading?: boolean;
  pageChunkSize?: number;
  fullyLoaded?: boolean;
  scrollToBottom?: boolean;
  onScrollToBottom?: () => void;
}

export default class ScrollContainer extends React.Component<IProps, any> {
  public stickyHeaderPortal;
  public listEl: HTMLUListElement | HTMLDivElement;
  public loaderElement: React.RefObject<HTMLLIElement> = React.createRef();
  private loadMoreThreshold = 0.25; // Trigger when >= 25% of the element is visible
  private loadMoreTimer;

  public static defaultProps: Partial<IProps> = {
    wrapperClassName: '',
    pageChunkSize: 10,
    scrollToBottom: false,
  };

  public componentDidMount(): void {
    const { initialScrollPosition, updateScrollStatus, handleLoadMore } = this.props;

    if (initialScrollPosition) {
      this.listEl.scrollTop = initialScrollPosition;
    }

    this.listEl.addEventListener('touchmove', this.stopProp);

    if (updateScrollStatus !== undefined) {
      this.checkScroll();

      window.addEventListener('resize', this.checkScroll);
    }

    // Handle the infinite scrolling, if supported
    if (typeof handleLoadMore === 'function' && window.IntersectionObserver != null) {
      const options = {
        root: this.listEl,
        threshold: this.loadMoreThreshold,
      };

      const observer = new IntersectionObserver(this.handleLoadMore, options);
      observer.observe(this.loaderElement.current);
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any): void {
    if (prevProps.scrollToBottom !== this.props.scrollToBottom && this.props.scrollToBottom) {
      this.listEl.scrollTop = this.listEl.scrollHeight;
      this.props.onScrollToBottom?.();
    }
  }

  public componentWillUnmount(): void {
    this.listEl.removeEventListener('touchmove', this.stopProp);
    if (this.props.updateScrollStatus !== undefined) {
      window.removeEventListener('resize', this.checkScroll);
    }
  }

  public stopProp = (e: TouchEvent): void => {
    if (!this.listEl) { return; }

    const { scrollHeight, clientHeight } = this.listEl;

    if (scrollHeight > clientHeight) {
      e.stopPropagation();
      e.stopImmediatePropagation();
    }

  };

  public render(): JSX.Element {
    const { list, children, className, wrapperClassName, onScroll, handleLoadMore, fullyLoaded } = this.props;

    return list ? (
      <div className={`${styles.ScrollContainer} ${wrapperClassName}`}>
        <div id="-ldx-sticky-header-portal" className={styles.StickyHeaders} />
        <ul className={className} onScroll={onScroll} ref={(el) => this.listEl = el}>
          {children}
          {typeof handleLoadMore === 'function' && <li className={styles.LoadingMore} ref={this.loaderElement}>{fullyLoaded ? null : <div className={styles.LoadingMoreMessage}>{t('Loading more records...')}</div>}</li>}
        </ul>
      </div>
    ) : (
      <div className={className} onScroll={onScroll} ref={(el) => this.listEl = el}>{children}</div>
    );
  }

  public checkScroll = (): void => {
    if (this.listEl) {
      this.props.updateScrollStatus(this.listEl.scrollHeight > this.listEl.clientHeight);
    }
  };

  public handleLoadMore = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
    const { handleLoadMore } = this.props;
    const ratio = entries[0].intersectionRatio;

    if (this.loadMoreTimer == null && ratio >= this.loadMoreThreshold) {
      handleLoadMore();
      this.loadMoreTimer = setTimeout(() => {
        this.loadMoreTimer = clearTimeout(this.loadMoreTimer);
      }, 250);
    }
  };
}
