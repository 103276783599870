import * as React from 'react';
import { IBaseInputProps } from './interfaces';
import CheckboxInput from './CheckboxInput';
import IOSToggleSwitch from './IosToggleSwitch';
import {makeGuid} from './utils';
import { MOBILE_APPS } from '../app/global/constants';

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/grid.styl');
    break;
  default:
    styles = require('./styles/grid.styl');
    break;
}

export interface IGridFormCheckBoxProps extends IBaseInputProps {
  // Provided by Parent Input Component
  formLabel?: string;
  checkboxLabel?: string;
  labelColumnClass?: string;
  labelFlexBasis?: string;
  checkboxColumnClass?: string;
  className?: string;
  isFieldRequired?: boolean;
  useIOSToggle?: boolean;
  children: object;
  title?: string;
  labelEl?: JSX.Element;
}

interface IState {
  id: string;
}

export default class GridFormCheckBox extends React.Component<IGridFormCheckBoxProps, IState> {

  private checkbox: any;

  public static defaultProps = {
    labelColumnClass: styles.Label,
    labelFlexBasis: '130px',
    checkboxColumnClass: styles.InputControl,
    className: styles.FormGrid,
    formLabel: null,
    useIOSToggle: false,
    children: [],
    labelEl: null,
  };

  public state = {
    id: makeGuid(),
  };

  public render(): JSX.Element {
    let labelField;
    const { formLabel, checkboxLabel, className, labelColumnClass, labelFlexBasis, checkboxColumnClass, isFieldRequired, useIOSToggle, value, labelEl, jsonPath } = this.props;
    const { id } = this.state;

    // we do not want to pass the props.className down as it will mess up the styles
    const checkboxProps = {
      ...this.props,
      ref: (checkbox) => this.checkbox = checkbox,
      id: id,
      checked: value,
      className: null,
    } as any;

    // if checkboxLabel then label will be on the right side of the checkbox and label
    if (checkboxProps.checkboxLabel) {
      checkboxProps.wrapperLabel = checkboxLabel;
      checkboxProps.checkboxLabelRight = true;
    }

    // if formLabel then the label will be on the left and will have label & checkbox as a normal form row
    // label in left column and checkbox in right column
    if (formLabel || labelEl) {
      let labelClass = styles.FormLabel;
      if (isFieldRequired) { labelClass += ` ${styles.IsRequired}`; }

      labelField = (
        <div key="label" className={labelColumnClass} style={{ flexBasis: labelFlexBasis }}>
          <label className={labelClass} htmlFor={jsonPath}>{labelEl ? labelEl : formLabel}</label>
        </div>
      );
    }

    const control = (() => {
      if (useIOSToggle) {
        return <IOSToggleSwitch {...checkboxProps} />;
      } else {
        return <CheckboxInput {...checkboxProps} />;
      }
    })();

    let checkBoxClass = checkboxColumnClass;
    // if there is no label make sure the checkbox has no left padding
    if (formLabel === null) { checkBoxClass += ` ${styles.NoLabel}`; }

    const content = [
      (formLabel != null) ? labelField : null,
      (id) ? <div key="select-field" className={checkBoxClass}>{control}</div> : null,
    ];

    return <div className={className}>{content}</div>;
  }

  public getValue = (): any => {
    return this.checkbox.getValue();
  };
}
