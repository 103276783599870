export const languages: ILangConfig = new Map([
  ['en-US', {
    name: 'English (US)',
    nativeLabel: 'English',
    serverAbbr: 'en-US',
    altAbbr: 'us-en', // Note: this is for the training center link, the training center does not support all the same languages, so this is our 'best choice' of the languages they support
    colonFormat: ':', // Allows spacing/formatting to be customized for colons each language
    strings: require('../strings/en-US.js'),
    // This is used by the instrument to determine the default lang for the region during a factory reset
    // This entry is empty as the default is en-US when no other match is found
    defaultForRegions: [],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['factory', '3.2'],
      ['pde', '4.0'],
      ['ehr-connect', '4.0'],
      ['dataview', '4.0'],
      ['engage', '4.1'],
      ['instrument', '1.0'],
      ['amira', '5.5'],
      ['ulc-patient-test', '5.5'],
    ]),
  }],
  ['en-GB', {
    name: 'English (UK)',
    nativeLabel: 'English',
    serverAbbr: 'en-US',
    altAbbr: 'us-en',
    colonFormat: ':',
    strings: require('../strings/en-GB.js'),
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['factory', '3.2'],
      ['pde', '4.0'],
      ['ehr-connect', '4.0'],
      ['dataview', '4.0'],
      ['engage', '4.1'],
    ]),
  }],
  ['it-IT', {
    name: 'Italian',
    nativeLabel: 'Italiano',
    serverAbbr: 'it-IT',
    altAbbr: 'it-it',
    colonFormat: ':',
    strings: require('../strings/it-IT.js'),
    defaultForRegions: [39,136,137],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['engage', '4.1'],
      ['dataview', '5.0'],
      ['amira', '5.5'],
      ['ulc-patient-test', '5.5'],
      ['instrument', '1.0'],
    ]),
  }],
  ['de-DE', {
    name: 'German',
    nativeLabel: 'Deutsch',
    serverAbbr: 'de-DE',
    altAbbr: 'de-de',
    colonFormat: ':',
    strings: require('../strings/de-DE.js'),
    defaultForRegions: [41,43,49,147],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  // ['es-US', {
  //   name: 'Spanish (US)',
  //   nativeLabel: 'Español (US)',
  //   serverAbbr: 'es-US',
  //   altAbbr: 'us-es', // Note: this is for the training center link, the training center does not support all the same languages, so this is our 'best choice' of the languages they support
  //   colonFormat: ':',
  //   strings: require('../strings/es-US.js'),
  //   modules: new Map([
  //     ['amira', '5.5'],
  //     ['ulc-patient-test', '5.5'],
  //   ]),
  // }],
  ['es-ES', {
    name: 'Spanish',
    nativeLabel: 'Español',
    serverAbbr: 'es-ES',
    altAbbr: 'es-es',
    colonFormat: ':',
    strings: require('../strings/es-ES.js'),
    defaultForRegions: [18,34,51,52,53,54,56,57,58,150,151,152,153,154,155,159,161,163,166,240],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['fr-FR', {
    name: 'French',
    nativeLabel: 'Français',
    serverAbbr: 'fr-FR',
    altAbbr: 'ch-fr',
    colonFormat: ' :',
    strings: require('../strings/fr-FR.js'),
    defaultForRegions: [32,33,105,120,135,156,157,158,162,164,179,184,186,221,222,223,224,225,226,227,228,229,235,236,242,243],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['sv-SE', {
    name: 'Swedish',
    nativeLabel: 'Svenska',
    serverAbbr: 'sv-SE',
    altAbbr: 'se-se',
    colonFormat: ':',
    strings: require('../strings/sv-SE.js'),
    defaultForRegions: [46],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['fi-FI', {
    name: 'Finnish',
    nativeLabel: 'Suomi',
    serverAbbr: 'fi-FI',
    altAbbr: 'fi-fi',
    colonFormat: ':',
    strings: require('../strings/fi-FI.js'),
    defaultForRegions: [126],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['nb-NO', {
    name: 'Norwegian',
    nativeLabel: 'Norsk',
    serverAbbr: 'nb-NO',
    altAbbr: 'no-no',
    colonFormat: ':',
    strings: require('../strings/nb-NO.js'),
    defaultForRegions: [47],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['pt-PT', {
    name: 'Portuguese (Portugal)',
    nativeLabel: 'Português (Portugal)',
    serverAbbr: 'pt-PT',
    altAbbr: 'us-en',
    colonFormat: ':',
    strings: require('../strings/pt-PT.js'),
    defaultForRegions: [102,119,169,238,239,244,245],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['pt-BR', {
    name: 'Portuguese (Brazil)',
    nativeLabel: 'Português (Brasil)',
    serverAbbr: 'pt-BR',
    altAbbr: 'us-en',
    colonFormat: ':',
    strings: require('../strings/pt-BR.js'),
    defaultForRegions: [55],
    modules: new Map([
      ['home', '4.1'],
      ['connect', '4.1'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['nl', {
    name: 'Dutch',
    nativeLabel: 'Nederlands',
    serverAbbr: 'nl-NL',
    altAbbr: 'us-en',
    colonFormat: ':',
    strings: require('../strings/nl.js'),
    defaultForRegions: [27,31,115,165,167],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['da-DK', {
    name: 'Danish',
    nativeLabel: 'Dansk',
    serverAbbr: 'da-DK',
    altAbbr: 'dk-da',
    colonFormat: ':',
    strings: require('../strings/da-DK.js'),
    defaultForRegions: [45,116],
    modules: new Map([
      ['home', '3.2'],
      ['connect', '3.2'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
  ['ja', {
    name: 'Japanese',
    nativeLabel: '日本語',
    serverAbbr: 'jp-JP',
    altAbbr: 'us-en',
    colonFormat: '：',
    strings: require('../strings/ja.js'),
    defaultForRegions: [81],
    modules: new Map([
      ['home', '4.0'],
      ['connect', '4.0'],
      ['dataview', '5.0'],
      ['instrument', '1.0'],
    ]),
  }],
]);

export const getLanguageForRegion = (region: number): string => {
  for (const [locale, { defaultForRegions } ] of languages.entries()) {
    if (defaultForRegions?.includes(region)) {
      return locale;
    }
  }
  return 'en-US';
};

export type ILangConfig = Map<string, ILang>;

interface ILang {
  name: string;
  nativeLabel: string;
  altAbbr: string;
  serverAbbr: string;
  strings: any;
  modules: Map<string, string>;
  dateFNS?: any;
  colonFormat?: string;
  defaultForRegions?: number[];
}
