import * as React from 'react';
import ProgressBar from './ProgressBar';
import { IBaseInputProps, IFileInputValue } from './interfaces';
import { IMAGE_TYPES } from './FileTypes';
import { bytesToSize, synthesizeMouseEvent } from './utils';
import AlertModal from './AlertModal';

const styles = require('./styles/file_input.styl');

interface IResolutionDimensions {
  h: number | string;
  w: number | string;
}

interface IResolution {
  max: IResolutionDimensions;
  min: IResolutionDimensions;
}

export interface IFileInputProps extends IBaseInputProps {
  multiple?: boolean;
  chooseFileText?: string;
  removeFileText?: string;
  name?: string;
  maxSize?: number;
  resolution?: IResolution;
  disabled?: boolean;
  displayFilename?: boolean;
  uploadProgress?: number | string;
  displayProgress?: boolean;
  fileTypes?: object | string;
  onFileRemove?: (value: IFileInputValue, callback: () => any) => void;
  overlayClass: string;
  overlayToolsClass: string;
  isUploadingClass: string;
  fileNameClass: string;
  addFileClass: string;
  removeFileClass: string;
  isEmptyClass: string;
}

/**
 * @param onChange
 * Event handler fired after on or more files are selected. Fires in an Async manner for images to check dimensions
 * @param onFileRemove
 * Event handler fired when a file is removed from the input
 * @param multiple
 * Multiple file input
 * @param chooseFileText
 * Placeholder text for the 'Choose' button
 * @param removeFileText
 * Placeholder text for the 'Remove' button, null to hide the button
 * @param name
 * Name attribute for the input element
 * @param maxSize
 * The maximum size in bytes for a file
 * @param resolution
 * Only applicable to image files. Enforces minimum and maximum length and width for images.
 * resolution:
 *  max:
 *    h: null
 *    w: null
 *  min:
 *    h: null
 *    w: null
 * @param disabled
 * Disables the input element
 * @param displayFilename
 * Toggle the display of the filename
 * @param uploadProgress
 * Tracks the upload progress of the file with the associated `name` prop
 * @param wrapperClass
 * CSS class that wraps the component
 * @param className
 * CSS class on the input element itself
 * @param overlayClass
 * CSS class on the file input overlay
 * @param overlayToolsClass
 * CSS class on the file input overlay tools
 * @param isUploadingClass
 * CSS class for the overlay modifier when uploading
 * @param fileNameClass
 * CSS class on the file name display
 * @param addFileClass
 * CSS class for that add file button
 * @param removeFileClass
 * CSS class on the remove file button
 * @param isEmptyClass
 * CSS class applied when no file is Selected
 */
export default class FileInput extends React.Component<IFileInputProps, any> {
  public static defaultProps: IFileInputProps = {
    wrapperClass: styles.FileInputWrapper,
    className: styles.FileInput,
    multiple: false,
    chooseFileText: 'Choose file...',
    removeFileText: 'Remove file',
    maxSize: 1048576, // Default max size of 1MB
    disabled: false,
    displayFilename: true,
    displayProgress: true,
    name: '',
    overlayClass: 'file-overlay',
    overlayToolsClass: 'overlay-tools',
    isUploadingClass: 'is-uploading',
    fileNameClass: styles.FileName,
    addFileClass: styles.AddFile,
    removeFileClass: 'remove-file',
    isEmptyClass: 'is-empty',
  };

  public state = {
    fileInputKey: 0,
    valid: true,
    inputHasFile: false,
    filename: '',
    alertMsg: null,
  };

  private fileInput: HTMLInputElement;

  private stopFilesLoop: boolean;

  public render(): JSX.Element {
    const { multiple, name, className, chooseFileText, removeFileText, disabled, displayFilename, displayProgress, overlayClass, overlayToolsClass, isUploadingClass, fileNameClass, addFileClass, removeFileClass, isEmptyClass } = this.props;
    let { uploadProgress, wrapperClass } = this.props;
    const { inputHasFile, filename, alertMsg } = this.state;

    // If an object is passed, use that name property value. Otherwise, use the string directly.
    if (typeof uploadProgress === 'object') {
      uploadProgress = uploadProgress[name];
    }

    if ((uploadProgress != null) && (uploadProgress !== '')) {
      wrapperClass += ' is-uploading';
    }

    if (!inputHasFile) {
      wrapperClass += ` ${isEmptyClass}`;
    }

    const fileOverlay = (
      <div className={`${overlayClass} ${(uploadProgress != null) && (uploadProgress !== '') ? isUploadingClass : ''}`}>
        <div className={overlayToolsClass}>
          {displayFilename ?
            <div className={fileNameClass} title={filename}>{filename}</div>
            : null}
          {!inputHasFile || multiple ?
            <button className={addFileClass} onClick={this.handleFileClick}>{chooseFileText}</button>
            : null}
          {inputHasFile && removeFileText ?
            <button className={removeFileClass} onClick={this.handleFileRemove}>{removeFileText}</button>
            : null}
        </div>
        {(uploadProgress != null) && (uploadProgress !== '') && displayProgress ?
          <ProgressBar progress={uploadProgress} />
          : null}
      </div>
    );

    return (
      <div className={wrapperClass}>
        <input
          className={className}
          type="file"
          ref={(fileInput) => this.fileInput = fileInput}
          name={name}
          multiple={multiple}
          onChange={this.handleChange}
          style={{
            display: inputHasFile ? 'none' : 'block',
          }}
          disabled={disabled}
        />
        {!disabled ? fileOverlay : null}
        {alertMsg ? (
          <AlertModal
            close={() => this.setState({ alertMsg: null })}
            alertTitle={t('Invalid File')}
            message={alertMsg}
            okText={t('Okay')}
          />
        ) : null}
      </div>
    );
  }

  public clear(): void {
    this.setState({
      fileInputKey: this.state.fileInputKey + 1,
      inputHasFile: false,
      filename: '',
    });
  }

  public validate(file, img?) {
    const { fileTypes, maxSize, resolution } = this.props;
    const status =
      { errors: [] };
    const URL = window.URL || (window as any).webkitURL;
    const extension = file.name.split('.').pop().toLowerCase();

    // Check file size
    if ((maxSize != null) && (file.size > maxSize)) { status.errors.push(t('Size must be less than __maxSize__', { maxSize: bytesToSize(maxSize) })); }

    // Check file extensions
    if (fileTypes != null) {
      // Use an array of qualified image types
      let types;
      if (Array.isArray(fileTypes)) {
        types = fileTypes.join(', ');

        // If set to imagesOnly, only image formats will be accepted
      } else if ((typeof fileTypes === 'string') && (fileTypes === 'imagesOnly')) {
        types = IMAGE_TYPES.join(', ');
      }

      // Check the file type to see if it's allowed
      if (types.length && (types.search(extension) === -1)) { status.errors.push(t('File type must be __fileType__', { fileType: types.toUpperCase() })); }
    }

    // For images, enforce resolution restrictions
    if ((resolution != null) && (img != null) && (URL != null)) {
      const { min, max } = resolution;
      const resErrors = [];

      if (max != null) {
        if (max.h && (img.height > max.h)) { resErrors.push(t('Less than __value__ in __measure__', { value: `${max.h}px`, measure: 'height' })); }
        if (max.w && (img.width > max.w)) { resErrors.push(t('Less than __value__ in __measure__', { value: `${max.w}px`, measure: 'width' })); }
      }
      if (min != null) {
        if (min.h && (img.height < min.h)) { resErrors.push(t('Greater than __value__ in __measure__', { value: `${min.h}px`, measure: 'height' })); }
        if (min.w && (img.width < min.w)) { resErrors.push(t('Greater than __value__ in __measure__', { value: `${min.w}px`, measure: 'width' })); }
      }

      if (resErrors.length) {
        status.errors.push(t('Resolution requirements'));
        status.errors.push(resErrors);
      }
    }

    return status;
  }

  public showErrorMessage(errors): void {
    this.stopFilesLoop = true;
    const messages = [];

    // Parse the validation errors
    for (let index = 0; index < errors.length; index++) {
      const error = errors[index];
      if ((typeof error === 'object') && error.length) {
        for (const sErr of Array.from(error)) {
          messages.push(<li key={index} className="sub-item">{sErr}</li>);
        }
      } else {
        messages.push(<li key={index} className="item">{error}</li>);
      }
    }

    const msg = messages.length ? <ul className="error-message-list">{messages}</ul> : t('Selected files must match requirements');

    this.setState({
      alertMsg: msg,
    });

    this.clear();
  }

  public handleFileRemove = (e: React.MouseEvent<HTMLButtonElement>): void => {
    // Report back the name of the element
    return (typeof this.props.onFileRemove === 'function' ? this.props.onFileRemove(this.getValue(), this.clear) : undefined);
  };

  public handleFileClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    return synthesizeMouseEvent(this.fileInput, 'click');
  };

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { uploadProgress, onChange, resolution } = this.props;
    const { files } = this.fileInput;
    this.stopFilesLoop = false;

    // Check for the URL class so we can use createObjectURL to assign data to images created dynamically
    // This is used to enforce resolution rules, and is not supported by IE9
    const URL = window.URL || (window as any).webkitURL;

    // If an upload is in progress, do nothing
    if (uploadProgress != null) {
      return e.preventDefault();
    }

    // Set the filename to the first file
    this.setState({
      filename: `${files[0].name} (${bytesToSize(files[0].size, 2)})`,
    });

    for (let index = 0; index < files.length; index++) {
      const file = files[index];
      const extension = file.name.split('.').pop().toLowerCase();
      const isImage = Array.from(IMAGE_TYPES).includes(extension);
      const isLastFile = index === (files.length - 1);

      // If an invalid file is detected, stop scanning
      if (this.stopFilesLoop) {
        this.stopFilesLoop = false;
        this.setState({
          filename: '',
        });
        return;
      }

      // If an image and resolution constraints defined, wait for img onload
      if ((resolution != null) && isImage && (URL != null)) {
        const img = new Image();
        img.onload = () => {
          const v = this.validate(file, img);
          if (v.errors.length) {
            return this.showErrorMessage(v.errors);
          } else if (isLastFile && v) {
            if (typeof onChange === 'function') {
              onChange(this.getValue());
            }
            return this.setState({
              inputHasFile: true,
            });
          }
        };
        img.src = URL.createObjectURL(file);

        // For all other files, run validation normally
      } else {
        const v = this.validate(file);
        if (v.errors.length) {
          this.showErrorMessage(v.errors);
        } else if (isLastFile && v) {
          if (typeof onChange === 'function') {
            onChange(this.getValue());
          }
          this.setState({
            inputHasFile: true,
          });
        }
      }
    }
  };

  public getValue(): IFileInputValue {
    const value: IFileInputValue = {
      files: this.fileInput.files,
      name: this.fileInput.name,
      maxSize: this.props.maxSize,
      ref: this,
    };

    return value;
  }
}
