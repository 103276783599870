import * as React from 'react';
import ConfirmSave, { SaveStates } from './ConfirmSave';
import Spinner, { ISpinnerProps } from './Spinner';
import Dialogue, { IDialogueProps } from './Dialogue';
import Keyboard from './Keyboard';

require('./styles/overlay.styl');
const styles = require('./styles/form.styl');

interface IState {
  showDialogue?: IDialogueProps;
}

export enum ButtonType {
  ACTION = 'ButtonAction',
  RESET = 'ButtonReset'
}

export interface IButton {
  name: string;
  handler: () => any;
  disabled?: boolean;
  type?: ButtonType;
}

export interface IModalProps {
  title: string;
  buttons?: IButton[];
  backdrop?: boolean;
  backdropClose?: boolean;
  onSaveComplete?: () => any;
  onSaveFail?: () => any;
  showClose?: boolean;
  closeAfterSave?: boolean;
  unSavedMessage?: string;
  unSavedDialogueHeight?: number;
  unSavedChanges?: boolean;
  style?: React.CSSProperties;
  loading?: boolean;
  stopPropagation?: boolean;
  spinnerProps?: ISpinnerProps;
  displayProgressBar?: boolean;
  uploadProgress?: string | number;
  saveState?: SaveStates;
  saveMessage?: string;
  shiftSaveMessage?: number;
  toggleNavFreeze?: () => any;
  className?: string;
  noHeaderBorder?: boolean;
}

/**
 * @param title
 * title for the modal header
 * @param buttons
 * Array of button objects with name, handler to be called on click, and disabled boolean, eg...
 * ```
 *  [
 *   {
 *     name: 'Save'
 *     handler: @save
 *     disabled: no
 *  }
 * ]
 * ```
 * @param children
 * React element (or array of elements) to inserted as the modal body
 * @param onSaveComplete
 * Function that is called right after the saveState is set to complete and the success indicator finishes animating
 * @param loading
 * Defaults to false, whether or not to show the spinner instead of the children
 * @param stopPropagation
 * Defaults to true, whether or not the modal stop click from bubbling above it
 * @param displayProgressBar
 * Defaults to false, whether or not the confirm/save show the progress bar instead of the spinner
 * @param uploadProgress
 * Progress of a file being uploaded
 * @param className
 * Add a CSS class to the base modal element
 * @param noHeaderBorder
 * boolean, true removes the gray border at the bottom of the header
 */
export default class Form extends React.Component<IModalProps, IState> {
  public static defaultProps: Partial<IModalProps> = {
    style: {},
    buttons: [],
    unSavedDialogueHeight: 140,
    saveState: null,
    saveMessage: null,
    unSavedChanges: false,
    loading: false,
    stopPropagation: true,
    displayProgressBar: false,
    uploadProgress: '',
    className: '',
    noHeaderBorder: false,
    shiftSaveMessage: 0,
    backdrop: true,
    backdropClose: true,
  };

  public state = {
    showDialogue: null,
  };

  public render(): JSX.Element {
    const { title, buttons, children, saveState, saveMessage, onSaveFail, loading, spinnerProps, displayProgressBar, uploadProgress, className, noHeaderBorder, shiftSaveMessage } = this.props;
    const { showDialogue } = this.state;
    const headerChildren = [];
    const actionButtons = [];

    // Modal Title
    if (title != null) {
      headerChildren.push(
        <span key="title" className={styles.Title}>{title}</span>
      );
    }

    // Other buttons
    for (let i = buttons.length - 1; i >= 0; i--) {
      const b = buttons[i];
      actionButtons.push(
        <button
          key={b.name}
          className={`${styles.ActionButton} ${styles[b.type]}`}
          onClick={b.handler}
          disabled={b.disabled}
        >{b.name}</button>
      );
    }

    const actionButtonItems = actionButtons.length ? <div key="actions" className={styles.ActionButtons}>{actionButtons}</div> : null;
    const headerClass = noHeaderBorder ? `${styles.Header} ${styles.NoBorder}` : styles.Header;

    return (
      <div className={`${styles.Form} ${className}`} title={title}>
        {headerChildren.length ?
          <header
            className={headerClass}
          >{headerChildren}</header>
          : null}
        {showDialogue != null ? <Dialogue {...showDialogue} /> : null}
        {saveState != null ?
          <ConfirmSave
            done={this.saveComplete}
            fail={onSaveFail}
            saveMessage={saveMessage}
            saveState={saveState}
            displayProgressBar={displayProgressBar}
            uploadProgress={uploadProgress}
            shiftSaveMessage={shiftSaveMessage}
          />
          : null}
        {loading ? <Spinner {...spinnerProps} /> : [
          children,
          actionButtonItems,
        ]}
      </div>
    );
  }

  public saveComplete = (): void => {
    const { onSaveComplete } = this.props;

    if (typeof onSaveComplete === 'function') {
      onSaveComplete();
    }
  };

  public handleKeyPress = (e): void => {
    const { keyCode, metaKey } = e;

    if ((keyCode === Keyboard.KEY_S) && metaKey) {
      e.preventDefault();
      const { buttons } = this.props;
      if ((buttons[0] != null ? buttons[0].name : undefined) === t('Save')) {
        buttons[0].handler();
      }
    }
  };

  public showDialogue(options): void {
    if (typeof this.props.toggleNavFreeze === 'function') {
      this.props.toggleNavFreeze();
    }

    this.setState({
      showDialogue: {...options, ...{
        key: 'dialogue',
        cancelCallback: this.closeDialogue,
      },
      }});
  }

  public closeDialogue = (): void => {
    if (typeof this.props.toggleNavFreeze === 'function') {
      this.props.toggleNavFreeze();
    }
    this.setState({
      showDialogue: null,
    });
  };
}
