import * as React from 'react';
import Input from './Input';
import SelectCustom, { ISelectCustomProps } from './SelectCustom';

/**
 * @param options
 * Full list of options to display on component
 * @param value
 * The value that corresponds to the option object with the matching value
 * @param selectText
 * Text displayed as the default value
 * @param onChange
 * Function fired when a change is made to the selection
 * @param tabIndex
 * Tab order index
 * @param disabled
 * Disabled state of the component
 * @param isFilter
 * Show/hide the filter typeahead input. Default is no
 * @param returnFullObjects
 * Determine whether `getValue` returns the full option object, or just the default value string
 * @param placeholder
 * Placeholder text for the filter input
 * @param valueField
 * The name of the key used to reference the value on the option object
 * @param labelField
 * The name of the key used to reference the label on the option object
 * @param width
 * The width of the menu popover
 * @param height
 * The height of the menu popover
 * @param optionHeight
 * The fixed height of each menu option
 * @param openOptions
 * Open select options handler
 */
export default class SelectCustomInput extends React.Component<ISelectCustomProps, any> {
  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <SelectCustom {...this.props} {...props} />
          );
        }}
      </Input>
    );
  }
}
