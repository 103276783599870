module.exports = {
  "__appName__ is not currently available.": "__appName__ não está disponível no momento.",
  "Access Code": "Código de acesso",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "A conta ${this.username} está bloqueada devido a 5 tentativas de login. Entre em contato com seu administrador para desbloquear.",
  "Afghanistan": "Afeganistão",
  "Albania": "Albânia",
  "Algeria": "Argélia",
  "American Samoa": "Samoa Americana",
  "Andorra": "Andorra",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antígua e Barbuda",
  "App": "Aplicativo",
  "Application Error": "Erro de aplicativo",
  "Application error.": "Erro de aplicativo.",
  "Application is not currently available.": "O aplicativo não está disponível no momento.",
  "Apply Changes": "Aplicar alterações",
  "Apr": "Abr",
  "April": "Abril",
  "Argentina": "Argentina",
  "Armenia": "Armênia",
  "Aruba": "Aruba",
  "At least __value__ characters": "Pelo menos __value__ caracteres",
  "At least one lowercase letter": "Pelo menos uma letra minúscula",
  "At least one number": "Pelo menos um número",
  "At least one of these special characters: __value__": "Pelo menos um destes caracteres especiais: __value__",
  "At least one uppercase letter": "Pelo menos uma letra maiúscula",
  "Aug": "Ago",
  "August": "Agosto",
  "Australia": "Austrália",
  "Austria": "Áustria",
  "Azerbaijan": "Azerbaijão",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrein",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbados",
  "Belarus": "Belarus",
  "Belgium": "Bélgica",
  "Belize": "Belize",
  "Benin": "Benim",
  "Bermuda": "Bermudas",
  "Bhutan": "Butão",
  "Bluetooth is Turned Off": "Bluetooth está desativado",
  "Bolivia": "Bolívia",
  "Bosnia and Herzegovina": "Bósnia e Herzegovina",
  "Botswana": "Botsuana",
  "Brazil": "Brasil",
  "Bronchial Lavage": "Lavagem brônquica",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgária",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Camboja",
  "Cameroon": "Camarões",
  "Canada": "Canadá",
  "Cancel": "Cancelar",
  "Cannot be empty": "Não pode estar vazio",
  "Cape Verde": "Cabo Verde",
  "Capillary Blood": "Sangue capilar",
  "Cayman Islands": "Ilhas Cayman",
  "Central African Republic": "República Centro-Africana",
  "Cerebrospinal Fluid": "Líq. cefalorraquidiano",
  "Cervical Swab": "Swab cervical",
  "Chad": "Chade",
  "Change Access Code": "Alterar código de acesso",
  "Check again": "Verificar novamente",
  "Chile": "Chile",
  "China": "China",
  "Clear Search": "Limpar pesquisa",
  "Close": "Fechar",
  "Cocos(Keeling) Islands and Chr": "Ilha Cocos (Keeling) e Christmas",
  "Colombia": "Colômbia",
  "Comoros": "Comores",
  "Confirm": "Confirmar",
  "Confirm New Password": "Confirmar nova senha",
  "Confirm new password": "Confirmar nova senha",
  "Confirm New Password is required.": "Confirmar nova senha é obrigatório.",
  "Confirm Password is required.": "Confirmar a senha é obrigatório.",
  "Confirm Password must match New Password": "A senha de confirmação deve coincidir com a nova senha",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Ilhas Cook",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Costa do Marfim",
  "Croatia(Hrvatska)": "Croácia (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Meio de cultura líquido",
  "Current Password": "Senha atual",
  "Current Password is required.": "A senha atual é obrigatória.",
  "Current password required": "A senha atual é obrigatória",
  "Cyprus": "Chipre",
  "Czech Republic": "República Checa",
  "Danish": "Dinamarquês",
  "Dec": "Dez",
  "December": "Dezembro",
  "Denmark": "Dinamarca",
  "Disabled": "Desabilitado",
  "Discard Changes": "Descartar alterações",
  "Dismiss": "Ignorar",
  "Djibouti": "Djibuti",
  "Does not match your previous passwords": "Não coincide com suas senhas anteriores",
  "Does not use unsupported characters": "Não utiliza caracteres não permitidos",
  "Domain": "Domínio",
  "Domain is required.": "O domínio é obrigatório.",
  "Dominica": "Dominica",
  "Dominican Republic": "República Dominicana",
  "Door Open": "Porta aberta",
  "Dutch": "Holandês",
  "Ecuador": "Equador",
  "Egypt": "Egito",
  "El Salvador": "El Salvador",
  "Email": "E-mail",
  "Email is required.": "O e-mail é obrigatório.",
  "English (UK)": "Inglês (RU)",
  "English (US)": "Inglês (EUA)",
  "Enter": "Enter",
  "Equatorial Guinea": "Guiné Equatorial",
  "Eritrea": "Eritreia",
  "Error": "Erro",
  "Estonia": "Estônia",
  "Ethiopia": "Etiópia",
  "Factory Manager is not supported in the Connect mobile application.": "O aplicativo móvel Connect não comporta o Factory Manager.",
  "Failed to reset password. A server error was encountered": "Falha ao redefinir senha. Foi encontrado um erro de servidor",
  "Falkland Islands(Malvinas)": "Ilhas Malvinas",
  "Faroe Islands": "Ilhas Faroe",
  "Fatal Application Error": "Erro fatal de aplicativo",
  "Feb": "Fev",
  "February": "Fevereiro",
  "Fecal Specimen": "Amostra fecal",
  "Fiji": "Fiji",
  "File type must be __fileType__": "O tipo de arquivo deve ser __fileType__",
  "Finland": "Finlândia",
  "Finnish": "Finlandês",
  "First Name": "Nome",
  "Forgot your password?": "Esqueceu sua senha?",
  "Fr": "Sex",
  "France": "França",
  "French": "Francês",
  "French Guiana": "Guiana Francesa",
  "French Polynesia": "Polinésia Francesa",
  "Gabon": "Gabão",
  "Gambia": "Gâmbia",
  "Georgia": "Geórgia",
  "German": "Alemão",
  "Germany": "Alemanha",
  "Ghana": "Gana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Ir para __storeName__",
  "Greater than __value__ in __measure__": "Maior que __value__ em __measure__",
  "Greece": "Grécia",
  "Greenland": "Groenlândia",
  "Grenada": "Granada",
  "Guadeloupe": "Guadalupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guiné",
  "Guinea - Bissau": "Guiné - Bissau",
  "Guyana": "Guiana",
  "Haiti": "Haiti",
  "Hct Out of Range": "Ht fora do intervalo",
  "Hide": "Ocultar",
  "Hide password": "Esconder senha",
  "Holy See(Vatican City State)": "Santa Sé (Estado da Cidade do Vaticano)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong (RAE da China)",
  "Hungary": "Hungria",
  "Iceland": "Islândia",
  "India": "Índia",
  "Indonesia": "Indonésia",
  "Initials": "Iniciais",
  "Install": "Instalar",
  "Install Now": "Instalar agora",
  "Instrument Not Level": "Instrument não nivelado",
  "Insufficient Sample": "Amostra insuficiente",
  "Internet connection is offline.": "A conexão com a internet está offline.",
  "Invalid access code": "Código de acesso inválido",
  "Invalid email format": "Formato de e-mail inválido",
  "Invalid File": "Arquivo inválido",
  "Invalid Lot Cal File": "Arq. de lote de cal. inválido",
  "Invalid username or password.": "Nome de usuário ou senha inválido.",
  "Iran, Islamic Republic of": "República Islâmica do Irã",
  "Iraq": "Iraque",
  "Ireland": "Irlanda",
  "Israel": "Israel",
  "Italian": "Italiano",
  "Italy": "Itália",
  "Jamaica": "Jamaica",
  "Jan": "Jan",
  "January": "Janeiro",
  "Japan": "Japão",
  "Japanese": "Japonês",
  "Jordan": "Jordânia",
  "Jul": "Jul",
  "July": "Julho",
  "Jun": "Jun",
  "June": "Junho",
  "Kazakhstan": "Cazaquistão",
  "KC Swab": "Swab de KC",
  "Kenya": "Quênia",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "República Popular Democrática da Coreia",
  "Korea, Republic of(South)": "República da Coreia do Sul",
  "Kuwait": "Kuwait",
  "Kyrgyzstan": "Quirguistão",
  "Language": "Idioma",
  "Laos(Lao PDR)": "RDP do Laos",
  "Last Name": "Sobrenome",
  "Latvia": "Letônia",
  "Lebanon": "Líbano",
  "Lesotho": "Lesoto",
  "Less than __value__ in __measure__": "Menos que __value__ em __measure__",
  "Liberia": "Libéria",
  "Libya": "Líbia",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Controle de Qualidade líquido",
  "Lithuania": "Lituânia",
  "Loading more records...": "Carregando mais registros...",
  "Login": "Login",
  "Logout": "Encerrar sessão",
  "Luxembourg": "Luxemburgo",
  "Macao(SAR China)": "Macau (RAE da China)",
  "Macedonia, Republic of": "República da Macedônia",
  "Madagascar": "Madagascar",
  "Malawi": "Malawi",
  "Malaysia": "Malásia",
  "Maldives": "Maldivas",
  "Mali": "Mali",
  "Malta": "Malta",
  "Mar": "Mar",
  "March": "Março",
  "Marshall Islands": "Ilhas Marshall",
  "Martinique": "Martinica",
  "Mauritania": "Mauritânia",
  "Mauritius": "Maurício",
  "MAX": "MÁX.",
  "Maximum length is __count__ characters": "O comprimento máximo é de __count__ caracteres",
  "May": "Maio",
  "Mexico": "México",
  "Micronesia, Federated States o": "Estados Federados da Micronésia",
  "Mo": "Seg",
  "Mobile Application Version": "Versão do aplicativo móvel",
  "Moldova": "Moldávia",
  "Monaco": "Mônaco",
  "Mongolia": "Mongólia",
  "Montenegro": "Montenegro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Marrocos e Saara Ocidental",
  "Mouth Swab": "Swab bucal",
  "Mozambique": "Moçambique",
  "Myanmar": "Mianmar",
  "Namibia": "Namíbia",
  "Nasal Discharge": "Secreção nasal",
  "Nasal Swab": "Swab nasal",
  "Nauru": "Nauru",
  "Nepal": "Nepal",
  "Netherlands": "Países Baixos",
  "Netherlands Antilles": "Antilhas Holandesas",
  "Never Connected": "Nunca conectado",
  "New Caledonia": "Nova Caledônia",
  "New Password": "Nova senha",
  "New Password is invalid.": "A nova senha é inválida.",
  "New Password is required.": "A nova senha é obrigatória.",
  "New password must match": "A nova senha deve corresponder a",
  "New Version Required": "A nova versão é obrigatória",
  "New Zealand": "Nova Zelândia",
  "Nicaragua": "Nicarágua",
  "Niger": "Níger",
  "Nigeria": "Nigéria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Não existem módulos de aplicativo neste ambiente. Informe esse problema a um administrador.",
  "No easily guessable words": "Não utilize palavras fáceis de adivinhar",
  "No more than __value__ characters": "Não mais do que __value__ caracteres",
  "No region set": "Região não definida",
  "No repetitive characters (e.g. aaa)": "Não utilize caracteres repetidos (por exemplo, aaa)",
  "No sequential characters (e.g. 123)": "Não utilize caracteres sequenciais (por exemplo, 123)",
  "No Test Strip Detected": "Nenhuma Tira de Teste detectada",
  "none": "nenhum(a)",
  "Norfolk Island": "Ilha Norfolk",
  "Northern Mariana Islands": "Ilhas Marianas do Norte",
  "Norway(inc Svalbard and Jan May": "Noruega (inc Svalbard e Jan Mayen)",
  "Norwegian": "Norueguês",
  "Nov": "Nov",
  "November": "Novembro",
  "NP Aspirate": "Aspirado de nasofaringe",
  "NP Swab": "Swab de nasofaringe",
  "Oct": "Out",
  "October": "Outubro",
  "Offline 1 day": "Offline 1 dia",
  "Offline 1 hour": "Offline 1 hora",
  "Offline 1 minute": "Offline 1 minuto",
  "Offline 1 month": "Offline 1 mês",
  "Offline 1 year": "Offline 1 ano",
  "Offline __days__ days": "Offline __days__ dias",
  "Offline __hours__ hours": "Offline __hours__ horas",
  "Offline __minutes__ minutes": "Offline __minutes__ minutos",
  "Offline __months__ months": "Offline __months__ meses",
  "Offline __years__ years": "Offline __years__ anos",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Omã",
  "Pakistan": "Paquistão",
  "Palau": "Palau",
  "Palestinian Territory, Occupie": "Território Palestino, Ocupado",
  "Panama": "Panamá",
  "Papua New Guinea": "Papua Nova Guiné",
  "Paraguay": "Paraguai",
  "Password": "Senha",
  "Password requirements:": "Requisitos de senha:",
  "Peru": "Peru",
  "Philippines": "Filipinas",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Polônia",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Português (Brasil)",
  "Portuguese (Portugal)": "Português (Portugal)",
  "Profile": "Perfil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Forneça as seguintes informações para definir sua senha.  O código de validação foi enviado para o seu e-mail, se válido.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Forneça seu e-mail para solicitar uma redefinição de senha. Um código de validação temporário será enviado para o seu e-mail.",
  "Puerto Rico": "Porto Rico",
  "Qatar": "Catar",
  "QC Solution Detected": "Solução de QC detectada",
  "Rectal Swab": "Swab retal",
  "Required Update": "A atualização é obrigatória",
  "Reset": "Restaurar",
  "Reset Password": "Redefinir senha",
  "Reset password": "Redefinir senha",
  "Resolution requirements": "Requisitos de resolução",
  "Return to Login": "Retornar ao login",
  "Romania": "Romênia",
  "Russian Federation": "Federação Russa",
  "Rwanda": "Ruanda",
  "Réunion and Mayotte": "Reunião e Mayotte",
  "Sa": "Sáb",
  "Saint Helena and Tristan da Cu": "Santa Helena e Tristão da Cunha",
  "Saint Kitts and Nevis": "São Cristóvão e Nevis",
  "Saint Lucia": "Santa Lúcia",
  "Saint Pierre and Miquelon": "São Pedro e Miquelon",
  "Saint Vincent and the Grenadine": "São Vicente e Granadinas",
  "Saliva": "Saliva",
  "Samoa": "Samoa",
  "Sample Detected": "Amostra detectada",
  "Sample Error": "Erro de amostra",
  "Sample Type Error": "Erro de tipo de amostra",
  "San Marino": "São Marinho",
  "Saudi Arabia": "Arábia Saudita",
  "Save": "Salvar",
  "Select a date": "Selecione uma data",
  "Select from list...": "Selecione na lista…",
  "Select language": "Selecionar idioma",
  "Selected files must match requirements": "Os arquivos selecionados devem atender aos requisitos",
  "Senegal": "Senegal",
  "Sep": "Set",
  "September": "Setembro",
  "Serbia": "Sérvia",
  "Serum": "Soro",
  "Server error, please try again later.": "Erro de servidor, tente novamente mais tarde.",
  "Server Error. Unable to save your profile.": "Erro de servidor. Não foi possível salvar seu perfil.",
  "Set Password": "Definir senha",
  "Seychelles": "Seicheles",
  "Show Password": "Mostrar senha",
  "Sierra Leone": "Serra Leoa",
  "Singapore": "Cingapura",
  "Size must be less than __maxSize__": "O tamanho deve ser menor que __maxSize__",
  "Skin Swab": "Swab cutâneo",
  "Slovakia": "Eslováquia",
  "Slovenia": "Eslovênia",
  "Solomon Islands": "Ilhas Salomão",
  "Somalia": "Somália",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Desculpe, DataView não está habilitado ou você não tem acesso a esta organização",
  "South Africa": "África do Sul",
  "Space": "Espaço",
  "Spain": "Espanha",
  "Spanish": "Espanhol",
  "Spanish (US)": "Espanhol (EUA)",
  "Sputum": "Expectoração",
  "Sri Lanka": "Sri Lanka",
  "Su": "Dom",
  "Sudan": "Sudão",
  "Suriname": "Suriname",
  "Swaziland": "Suazilândia",
  "Sweden": "Suécia",
  "Swedish": "Sueco",
  "Switzerland": "Suíça",
  "Syrian Arab Republic(Syria)": "República Árabe da Síria (Síria)",
  "São Tomé and Príncipe": "São Tomé e Príncipe",
  "Taiwan, Republic of China": "Taiwan, República da China",
  "Tajikistan": "Tajiquistão",
  "Tanzania, United Republic of": "República Unida da Tanzânia",
  "Temperature Error": "Erro de temperatura",
  "Test Cancelled": "Teste cancelado",
  "Test Lot Not Permitted": "Lote de testes não permitido",
  "Test Operation Error": "Erro na execução do teste",
  "Test Strip Lot Expired": "Lote da Tira de Teste vencido",
  "Test Strip Not Supported": "Tira de Teste não compatível",
  "Test Strip Reading Error": "Erro na leitura da Tira de Teste",
  "Test Timeout": "Tempo de teste excedido",
  "Test Type Not Permitted": "Tipo de teste não permitido",
  "Th": "Qui",
  "Thailand": "Tailândia",
  "The new password validation code will be sent to the email, if email is a valid user email.": "O código de validação da nova senha será enviado para o e-mail, se for um e-mail de usuário válido.",
  "The reset validation code was populated from the link and should not be changed.": "O código de validação de redefinição foi preenchido a partir do link e não deve ser alterado.",
  "There are unsaved changes. How do you want to proceed?": "Há alterações não salvas. Como deseja prosseguir?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Existe uma nova versão disponível (__versionNumber__), gostaria de instalá-la agora?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Esse dispositivo não está mais atuando como um Connect Hub. Para continuar a usar esse dispositivo como um Connect Hub, habilite o Bluetooth novamente nas configurações do seu dispositivo e ative outra vez a opção Use este dispositivo móvel como um Connect Hub na tela Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "A versão do LumiraDx Connect não é mais compatível com a sua organização. Vá para __storeName__ e atualize para a última versão.",
  "Throat Swab": "Swab da garganta",
  "Timor - Leste": "Timor - Leste",
  "Title": "Título",
  "TN Swab": "Swab F/N",
  "to": "para",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Para continuar usando __APP_NAME__, atualize para a versão mais recente (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Toquelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trindade e Tobago",
  "Tu": "Ter",
  "Tunisia": "Tunísia",
  "Turkey": "Turquia",
  "Turkmenistan": "Turcomenistão",
  "Turks and Caicos Islands": "Ilhas Turcas e Caicos",
  "Tuvalu": "Tuvalu",
  "Uganda": "Uganda",
  "Ukraine": "Ucrânia",
  "Unable to reset password, the provided current password is not valid.": "Não foi possível redefinir a senha; a senha atual fornecida não é válida.",
  "Unable to reset your password.": "Não foi possível redefinir sua senha.",
  "Unable to validate access code.": "Não foi possível validar o código de acesso.",
  "United Arab Emirates": "Emirados Árabes Unidos",
  "United Kingdom": "Reino Unido",
  "United States of America": "Estados Unidos da América",
  "Unrecognized credentials. Please try again.": "Credenciais não reconhecidas. Por favor, tente novamente.",
  "Unsaved Changes": "Alterações não salvas",
  "Update Available": "Atualização disponível",
  "Update Password": "Atualizar senha",
  "Urethral Swab": "Swab uretral",
  "Urine": "Urina",
  "Uruguay": "Uruguai",
  "User account is locked. Please try again in an hour.": "A conta do usuário está bloqueada. Tente novamente em uma hora.",
  "User Interface Version": "Versão da interface do usuário",
  "User logged out due to inactivity.": "Usuário desconectado por inatividade.",
  "Username": "Nome de usuário",
  "Uzbekistan": "Uzbequistão",
  "Vaginal Swab": "Swab vaginal",
  "Validate Access Code": "Validar código de acesso",
  "Validation Code": "Código de validação",
  "Validation Code is required.": "O código de validação é obrigatório.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "República Bolivariana da Venezuela",
  "Venous Blood": "Sangue venoso",
  "Version": "Versão",
  "Viet Nam": "Vietnã",
  "Virgin Islands, British": "Ilhas Virgens Britânicas",
  "Virgin Islands, US": "Ilhas Virgens dos EUA",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Swab vulvovaginal",
  "Wallis and Futuna Islands": "Ilhas Wallis e Futuna",
  "We": "Qua",
  "We are working to get you back up and running as quickly as possible.": "Estamos trabalhando para que tudo volte a funcionar o mais rápido possível.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Lamentamos, mas atualmente o LumiraDx Connect está em manutenção.",
  "Yemen": "Iémen",
  "You have been signed out due to a password reset attempt.": "Você foi desconectado devido a uma tentativa de redefinição de senha.",
  "You have been signed out due to the creation of another session with the same credentials.": "Você foi desconectado devido à criação de outra sessão com as mesmas credenciais.",
  "You have been signed out due to your account being disabled by an admin.": "Você foi desconectado porque sua conta foi desabilitada por um admin.",
  "You have been signed out due to your password being changed by an admin.": "Você foi desconectado porque sua senha foi alterada por um admin.",
  "You have been signed out due to your user role being changed by an admin.": "Você foi desconectado porque sua função de usuário foi alterada por um admin.",
  "You have been signed out.": "Você foi desconectado.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Você precisa instalar uma versão diferente (__versionNumber__) de __APP_NAME__ para que seja compatível com sua organização.",
  "Your password has expired. Please set a new password.": "Sua senha expirou. Defina uma nova senha.",
  "Your session has expired. Please login again.": "Sua sessão expirou. Faça login novamente.",
  "Your session has expired. User is not authorized.": "Sua sessão expirou. O usuário não está autorizado.",
  "Zambia": "Zâmbia",
  "Zimbabwe": "Zimbábue"
};
