import * as React from 'react';
import ConfirmSave, { SaveStates } from './ConfirmSave';
import Spinner, { ISpinnerProps } from './Spinner';
import Dialogue, { IDialogueProps } from './Dialogue';
import Keyboard from './Keyboard';
import Overlay from './Overlay';
import { MOBILE_APPS } from '../app/global/constants';

require('./styles/overlay.styl');

let styles;
switch (process.env.MOBILE_APP) {
  case MOBILE_APPS.INSTRUMENT:
    styles = require('./uirs/styles/modal.styl');
    break;
  default:
    styles = require('./styles/modal.styl');
    break;
}

interface IState {
  dragX?: number;
  dragY?: number;
  dragging?: boolean;
  showDialogue?: IDialogueProps;
  modalHeight?: number;
}

export enum ButtonType {
  ACTION = 'ButtonAction',
  RESET = 'ButtonReset'
}

export enum ModalAnimations {
  BOUNCE = 'ModalEnterBounce',
  FADE_IN = 'ModalEnterFadeIn'
}

export interface IButton {
  name: string;
  handler: () => any;
  disabled?: boolean;
  type?: ButtonType;
}

export interface IModalProps {
  title: string;
  buttons?: IButton[];
  dataTest?: string;
  close: () => any;
  backdrop?: boolean;
  backdropClose?: boolean;
  onSaveComplete?: () => any;
  onSaveFail?: () => any;
  showClose?: boolean;
  closeAfterSave?: boolean;
  unSavedMessage?: string;
  unSavedDialogueHeight?: number;
  unSavedChanges?: boolean;
  style?: React.CSSProperties;
  animateIn?: ModalAnimations;
  loading?: boolean;
  draggable?: boolean;
  stopPropagation?: boolean;
  spinnerProps?: ISpinnerProps;
  displayProgressBar?: boolean;
  uploadProgress?: string | number;
  saveState?: SaveStates;
  saveMessage?: string;
  shiftSaveMessage?: number;
  toggleNavFreeze?: () => any;
  className?: string;
  noHeaderBorder?: boolean;
  fullScreen?: boolean;
  nonBlocking?: boolean;
  zIndex?: number;
  centerVertically?: boolean;
}

/**
 * @param title
 * title for the modal header
 * @param buttons
 * Array of button objects with name, handler to be called on click, and disabled boolean, eg...
 * ```
 *  [
 *   {
 *     name: 'Save'
 *     handler: @save
 *     disabled: no
 *  }
 * ]
 * ```
 * @param children
 * React element (or array of elements) to inserted as the modal body
 * @param close
 * Function that closes the modal, passed automatically by the overlay framework
 * @param showClose
 * Defaults to true, set it to false to not show the close button
 * @param closeAfterSave
 * Defaults to true, set it to false to prevent the modal from calling close after a save is complete
 * Note: in this case you MUST pass an onSaveComplete handler that sets the saveState to null after a save
 * @param onSaveComplete
 * Function that is called right after the saveState is set to complete and the success indicator finishes animating
 * @param animateIn
 * Enum ModalAnimations - How the modal animates in, set to null to prevent animation, defaults to bounce effect
 * @param loading
 * Defaults to false, whether or not to show the spinner instead of the children
 * @param draggable
 * Defaults to true, whether or not the modal can be dragged from it's header
 * @param stopPropagation
 * Defaults to true, whether or not the modal stop click from bubbling above it
 * @param displayProgressBar
 * Defaults to false, whether or not the confirm/save show the progress bar instead of the spinner
 * @param uploadProgress
 * Progress of a file being uploaded
 * @param className
 * Add a CSS class to the base modal element
 * @param noHeaderBorder
 * boolean, true removes the gray border at the bottom of the header
 * @param nonBlocking
 * When true, the overlay container will have no width/height and pass mouse events through.
 * @param zIndex
 * Optional zIndex property to manually set the zIndex of an overlay
 * @param centerVertically
 * When true, the modal will be centered vertically with in the overlay container.
 */
export default class Modal extends React.Component<IModalProps, IState> {
  public static defaultProps: Partial<IModalProps> = {
    style: {},
    buttons: [],
    dataTest: null,
    showClose: true,
    closeAfterSave: true,
    unSavedDialogueHeight: 140,
    saveState: null,
    saveMessage: null,
    unSavedChanges: false,
    animateIn: ModalAnimations.BOUNCE,
    loading: false,
    draggable: true,
    stopPropagation: true,
    displayProgressBar: false,
    uploadProgress: '',
    className: '',
    noHeaderBorder: false,
    shiftSaveMessage: 0,
    backdrop: true,
    backdropClose: true,
    fullScreen: false,
    nonBlocking: false,
    zIndex: null,
    centerVertically: false,
  };

  public state = {
    dragX: 0,
    dragY: 0,
    dragging: false,
    showDialogue: null,
    modalHeight: 0,
  };

  private header: HTMLElement;
  private startDragX: number = 0;
  private startDragY: number = 0;
  private startX: number = 0;
  private startY: number = 0;
  private minDragY: number = 0;
  private maxDragY: number = 0;
  private minDragX: number = 0;
  private maxDragX: number = 0;
  private modalEl = React.createRef<HTMLDivElement>();

  public componentDidMount(): void {
    document.addEventListener('keydown', this.handleKeyPress);
    setTimeout(() => {
      this.calculateMaxDrags();
    }, 0);
    window.addEventListener('resize', this.calculateMaxDrags);
    this.modalEl.current.focus();

    if (this.props.centerVertically) {
      setTimeout(() => {
        this.setState({
          modalHeight: this.modalEl?.current?.clientHeight,
        });
      }, 0);
    }
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeyPress);
    window.removeEventListener('resize', this.calculateMaxDrags);
  }

  public render(): JSX.Element {
    const { dataTest, title, buttons, showClose, children, saveState, saveMessage, onSaveFail, animateIn, loading, spinnerProps, draggable, stopPropagation, displayProgressBar, uploadProgress, className, noHeaderBorder, shiftSaveMessage, unSavedChanges, close, backdrop, backdropClose, fullScreen, zIndex, nonBlocking, centerVertically } = this.props;
    const { dragX, dragY, showDialogue, dragging, modalHeight } = this.state;
    let { style } = this.props;
    const headerChildren = [];
    const actionButtons = [];

    style = {...style, ...{
      transform: `translate(${dragX}px, ${dragY}px) translateZ(0px)`,
      WebkitTransform: `translate(${dragX}px, ${dragY}px) translateZ(0px)`,
      msTransform: `translate(${dragX}px, ${dragY}px)`,
    }};

    // centers the modal vertically in the overlay
    if (centerVertically) {
      const offSetTopHeight = modalHeight / 2;

      style = {
        ...style, ...{
          top: `calc(50% - ${offSetTopHeight}px)`,
        },
      };
    }

    // Modal Title
    if (title != null) {
      headerChildren.push(
        <span key="title" className={styles.Title}>{title}</span>
      );
    }

    // Close Button
    if (showClose) {
      headerChildren.push(
        <button
          title={t('Close')}
          key="close"
          className={styles.CloseButton}
          onClick={this.closeWithCheck}
        />
      );
    }

    // Other buttons
    for (let i = buttons.length - 1; i >= 0; i--) {
      const b = buttons[i];
      actionButtons.push(
        <button
          key={b.name}
          className={`${styles.ActionButton} ${styles[b.type]}`}
          onClick={b.handler}
          disabled={b.disabled}
        >{b.name}</button>
      );
    }

    const actionButtonItems = actionButtons.length ? <div key="actions" className={styles.ActionButtons}>{actionButtons}</div> : null;
    let headerClass = noHeaderBorder ? `${styles.Header} ${styles.NoBorder}` : styles.Header;
    headerClass += dragging ? ` ${styles.Grabbing}` : '';

    return (
      <Overlay zIndex={zIndex} close={unSavedChanges ? null : close} backdrop={backdrop} backdropClose={backdropClose} nonBlocking={nonBlocking}>
        <div
          data-test={dataTest}
          ref={this.modalEl}
          className={`${styles.Modal} ${className} ${animateIn ? styles[animateIn] : ''} ${fullScreen ? styles.FullScreen : ''}`}
          style={style}
          onMouseDown={stopPropagation ? this.stopMouseDownProp : null}
          title={title}
        >
          {headerChildren.length ?
            <header
              className={headerClass}
              onMouseDown={draggable ? this.handleMouseDown : null}
              ref={(header) => this.header = header}
            >{headerChildren}</header>
            : null}
          {showDialogue != null ? <Dialogue {...showDialogue} /> : null}
          {saveState != null ?
            <ConfirmSave
              done={this.saveComplete}
              fail={onSaveFail}
              saveMessage={saveMessage}
              saveState={saveState}
              displayProgressBar={displayProgressBar}
              uploadProgress={uploadProgress}
              shiftSaveMessage={shiftSaveMessage}
            />
            : null}
          {loading ? <Spinner {...spinnerProps} /> : [
            children,
            actionButtonItems,
          ]}
        </div>
      </Overlay>
    );
  }

  public stopMouseDownProp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    e.stopPropagation();
  };

  public closeWithCheck = (): void => {
    const { unSavedMessage, unSavedDialogueHeight, unSavedChanges } = this.props;

    if (unSavedChanges) {
      this.showDialogue({
        message: unSavedMessage || t('There are unsaved changes. How do you want to proceed?'),
        confirmText: t('Discard Changes'),
        cancelText: t('Cancel'),
        height: unSavedDialogueHeight,
        width: 340,
        top: 30,
        dialogueTitle: t('Unsaved Changes'),
        confirmCallback: this.props.close,
      });
    } else {
      this.props.close();
    }
  };

  public saveComplete = (): void => {
    const { onSaveComplete, closeAfterSave } = this.props;

    if (typeof onSaveComplete === 'function') {
      onSaveComplete();
    }

    if (closeAfterSave) {
      this.props.close();
    }
  };

  public handleKeyPress = (e): void => {
    const { keyCode, metaKey } = e;

    if (keyCode === Keyboard.ESCAPE) {
      this.closeWithCheck();
    }
    if ((keyCode === Keyboard.KEY_S) && metaKey) {
      e.preventDefault();
      const { buttons } = this.props;
      if ((buttons[0] != null ? buttons[0].name : undefined) === t('Save')) {
        buttons[0].handler();
      }
    }
  };

  public showDialogue(options): void {
    if (typeof this.props.toggleNavFreeze === 'function') {
      this.props.toggleNavFreeze();
    }

    this.setState({
      showDialogue: {...options, ...{
        key: 'dialogue',
        cancelCallback: this.closeDialogue,
      }},
    });
  }

  public closeDialogue = (): void => {
    if (typeof this.props.toggleNavFreeze === 'function') {
      this.props.toggleNavFreeze();
    }
    this.setState({
      showDialogue: null,
    });
  };

  public handleMouseDown = (e): void => {
    e.preventDefault();

    const { dragX, dragY } = this.state;

    this.startDragX = dragX;
    this.startDragY = dragY;
    this.startX = e.clientX;
    this.startY = e.clientY;

    this.setState({
      dragging: true,
    });

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  public handleMouseMove = (e): void => {
    e.preventDefault();

    let dragX = this.startDragX + (e.clientX - this.startX);
    let dragY = this.startDragY + (e.clientY - this.startY);

    dragY = dragY < this.minDragY ? this.minDragY : dragY;
    dragY = dragY > this.maxDragY ? this.maxDragY : dragY;

    dragX = dragX < this.minDragX ? this.minDragX : dragX;
    dragX = dragX > this.maxDragX ? this.maxDragX : dragX;

    this.setState({ dragX, dragY });
  };

  public handleMouseUp = (e): void => {
    this.setState({
      dragging: false,
    });

    document.removeEventListener('mousemove', this.handleMouseMove);
    document.removeEventListener('mouseup', this.handleMouseUp);
  };

  public calculateMaxDrags(): void {
    if (this.header == null) {
      return;
    }

    const { offsetTop, offsetLeft } = this.header.parentNode as any;
    const { offsetHeight, offsetWidth } = this.header;
    const { innerHeight, innerWidth } = window;

    this.maxDragX = innerWidth - (offsetLeft + offsetWidth);
    this.maxDragY = innerHeight - (offsetTop + offsetHeight);

    this.minDragX = 0 - offsetLeft;
    this.minDragY = 0 - offsetTop;
  }
}
