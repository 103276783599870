import '@babel/polyfill';
import 'isomorphic-fetch';
import 'abortcontroller-polyfill';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as ReactRouterDom from 'react-router-dom';
const { HashRouter, BrowserRouter } = ReactRouterDom;
import App from './components/App/App';
import './global/helpers';
import amd from './helpers/amd';
import * as domReady from 'detect-dom-ready';
import ViewportUnits from './helpers/viewport';
import { MOBILE_APPS } from './global/constants';
const { registerDependencies } = amd;

/**
 * Defines Mobile Bridge V1 for the Connect App
 */
if (process.env.MOBILE_APP) {
  require('./helpers/Mt')();
}

/**
 * Intrument
 */
if (process.env.MOBILE_APP === MOBILE_APPS.INSTRUMENT) {
  registerDependencies(new Map([
    ['crypto-browserify', require('crypto-browserify')],
    ['base64-js', require('base64-js')],
    ['ieee754', require('ieee754')],
    ['isarray', require('isarray')],
  ]));
}

/**
 * Mocks the connect app bridge when MOCK_CONNECT_APP env variable is on
 * Note: This will overwrite the bridge objects, so MOCK_CONNECT_APP will break the actual bridge
 */
if (process.env.MOCK_CONNECT_APP && process.env.MOBILE_APP !== MOBILE_APPS.INSTRUMENT) {
  require('./test/MobileAppMock');
}

// Register all dependencies required by loaded AMD modules
registerDependencies(new Map([
  ['react-window', require('react-window')],
  ['react-virtualized', require('react-virtualized')],
  ['qrcode', require('qrcode')],
  ['react-router-dom', require('react-router-dom')],
  ['react', require('react')],
  ['react-dom', require('react-dom')],
  ['mobx', require('mobx')],
  ['mobx-react', require('mobx-react')],
  ['bowser', require('bowser')],
  ['react-router-drilldown', require('react-router-drilldown')],
  ['moment', require('moment')],
  ['moment-timezone', require('moment-timezone')],
  ['hammerjs', require('hammerjs')],
  ['apollo-client', require('apollo-client')],
  ['apollo-cache-inmemory', require('apollo-cache-inmemory')],
  ['apollo-link-http', require('apollo-link-http')],
  ['graphql-tag', require('graphql-tag')],
  ['query-string', require('query-string')],
  ['@apollo/react-hooks', require('@apollo/react-hooks')],
  ['semver/functions/coerce', require('semver/functions/coerce')],
  ['semver/functions/gt', require('semver/functions/gt')],
  ['semver/functions/patch', require('semver/functions/patch')],
  ['semver/functions/rcompare', require('semver/functions/rcompare')],
  ['date-fns', require('date-fns')],
  ['date-fns-tz', require('date-fns-tz')],
  ['pretty-json-stringify', require('pretty-json-stringify')],
  // engage
  ['react-transition-group', require('react-transition-group')],
  ['react-html-parser', require('react-html-parser')],
  ['react-iframe', require('react-iframe')],
  ['url-parse', require('url-parse')],
  ['react-player', require('react-player')],
  // Lineup dependencies
  ['ainojs-animation', require('ainojs-animation')],
  ['ainojs-easing', require('ainojs-easing')],
  // Import the Moment locales
  ['moment/locale/it', require('moment/locale/it')],
  ['moment/locale/de', require('moment/locale/de')],
  ['moment/locale/es', require('moment/locale/es')],
  ['moment/locale/sv', require('moment/locale/sv')],
  ['moment/locale/fr', require('moment/locale/fr')],
  ['moment/locale/it', require('moment/locale/it')],
  ['moment/locale/fi', require('moment/locale/fi')],
  ['moment/locale/nb', require('moment/locale/nb')],
  ['moment/locale/da', require('moment/locale/da')],
  ['moment/locale/nl', require('moment/locale/nl')],
  ['moment/locale/pt', require('moment/locale/pt')],
  ['moment/locale/ja', require('moment/locale/ja')],
  ['moment/locale/pt-br', require('moment/locale/pt-br')],
  // Path shortcuts
  ['@UI-Core/components/App/ModuleContainer', require('./components/App/ModuleContainer')],
  ['@UI-Core/components/App/Profile', require('./components/App/Profile')],
  ['@UI-Core/components/App/ResetPassword', require('./components/App/ResetPassword')],
  ['@UI-Core/components/App/styles/App.styl', require('./components/App/styles/App.styl')],
  ['@UI-Core/components/Login/styles/Login.styl', require('./components/Login/styles/Login.styl')],
  ['@UI-Core/helpers/ErrorTracker', require('./helpers/ErrorTracker')],
  ['@UI-Core/helpers/LocalStorage', require('./helpers/LocalStorage')],
  ['@UI-Core/global/helpers', require('./global/helpers')],
  ['@UI-Core/helpers/history', require('./helpers/history')],
  ['@UI-Core/helpers', require('./helpers')],
  ['@UI-Core/global/interfaces', require('./global/interfaces')],
  ['@UI-Core/global/enums', require('./global/enums')],
  ['@UI-Core/global/variables', require('./global/variables')],
  ['@UI-Core/global/constants', require('./global/constants')],
  ['@UI-Core/global/languages', require('./global/languages')],
  ['@UI-Core/stores', require('./stores')],
  ['@UI-Core/stores/LoggedInUser', require('./stores/LoggedInUser')],
  ['@UI-Core/stores/Application', require('./stores/Application')],
  ['@UI-Core/stores/MobileBridge', require('./stores/MobileBridge')],
  ['@UI-Core/components/Login/PasswordRules', require('./components/Login/PasswordRules')],
  ['@Lineup/index', require('../LDX-Lineup/index')],
  ['@Lineup/Modal', require('../LDX-Lineup/Modal')],
  ['@Lineup/Drawer', require('../LDX-Lineup/Drawer')],
  ['@Lineup/interfaces', require('../LDX-Lineup/interfaces')],
  ['@Lineup/enums/index', require('../LDX-Lineup/enums/index')],
  ['@Lineup/utils', require('../LDX-Lineup/utils')],
  ['@Lineup/styles/grid.styl', require('../LDX-Lineup/styles/grid.styl')],
  ['@Lineup/styles/modal.styl', require('../LDX-Lineup/styles/modal.styl')],
  // Automation sandbox
  ['json-beautify', require('json-beautify')],
]));

domReady(() => {
  // Set some local vvw and vvh values for use with mobile devices. Helps to gather the proper, usable vh and vw (mainly for when a soft keyboard is open)
  new ViewportUnits();

  ReactDOM.render(
    process.env.MOBILE_APP !== MOBILE_APPS.INSTRUMENT ? (
      <HashRouter>
        <App />
      </HashRouter>
    ) : (
      <BrowserRouter>
        <App />
      </BrowserRouter>
    ), document.getElementById('app'));
});
