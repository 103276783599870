import NumberRange, { INumberRangeProps } from '@Lineup/NumberRange';
import * as React from 'react';

const styles = require('./styles/number_range_input.styl');
const gridStyles = require('./styles/grid.styl');

export interface INumberRangeInputProps extends INumberRangeProps{
  minEl?: React.ReactNode;
  maxEl?: React.ReactNode;
  labelColumnClass?: string;
  formLabel?: string;
  labelFlexBasis?: string;
  jsonPath?: string;
}

export default class NumberRangeInput extends React.Component<INumberRangeInputProps, any> {
  public static defaultProps = {
    name: 'range',
    className: styles.RangeInput,
    disabled: false,
    labelFlexBasis: '130px',
  };

  public render(): JSX.Element {
    const { minEl, maxEl, formLabel, labelColumnClass, labelFlexBasis, jsonPath } = this.props;
    let labelField;
    if (formLabel) {
      labelField = (
        <div key="label" className={labelColumnClass} style={{ flexBasis: labelFlexBasis }}>
          <label className={gridStyles.FormLabel}>{formLabel}</label>
        </div>
      );
    }

    return (
      <div className={`${styles.Wrapper} ${gridStyles.FormGrid}`} data-test={jsonPath}>
        {(formLabel != null) ? labelField : null}
        {minEl}
        <NumberRange {...this.props} className={styles.RangeInput} />
        {maxEl}
      </div>
    );
  }

}
