import * as React from 'react';
import { IBaseInputProps } from './interfaces';
import Pvr from './Pvr';

import TypeAheadOptionItem from './TypeAheadOptionItem';
import Keyboard from './Keyboard';
import ScrollContainer from './ScrollContainer';

const styles = require('./styles/type_ahead.styl');

export interface ITypeAheadOptionListProps extends IBaseInputProps {
  options: any[];
  value?: any;
  onSelect: (option: any) => any;
  valueField?: string;
  labelField?: string;
  returnFullObjects?: boolean;
  pvrProps?: any;
  anchor: HTMLDivElement;
  noResultsText?: string;
}

export default class TypeAheadOptionList extends React.Component<ITypeAheadOptionListProps, any> {
  public static defaultProps = {
    returnFullObjects: false,
  };

  public state = {
    focused: 0,
  };

  private keyNav: boolean;
  private sc: ScrollContainer;

  public componentDidMount(): void {
    this.keyNav = false;
    document.addEventListener('keydown', this.handleKeydown);
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  public render(): JSX.Element {
    const {focused} = this.state;
    const { anchor, pvrProps, options, valueField, labelField, onSelect, noResultsText } = this.props;
    const clientWidth = anchor ? anchor.clientWidth : 0;

    const optionList = options.map((optionItem, i) => {
      const itemKey = optionItem[valueField] || optionItem;
      const isFocused = i === focused;
      return (
        <TypeAheadOptionItem
          key={itemKey}
          option={optionItem}
          isFocused={isFocused}
          onSelect={onSelect}
          labelField={labelField}
          focusOption={this.focusOption}
          index={i}
        />
      );
    });

    if (optionList.length === 0) {
      optionList.push(<li key="no-results" className={styles.NoResults}>
        {noResultsText}
      </li>);
    }

    const cWidth = clientWidth;
    const width = (cWidth < 470) ? cWidth : 470;

    return (
      <Pvr
        {...pvrProps}
        direction="below"
        width={width}
        animateIn={false}
        height="auto"
        autoHeightClass={styles.TypeAheadOptionList}
        autoHeightReCalc
        nibColor="white"
        anchor={anchor}
        styleMixin={{
          transition: 'top 300ms ease-out, left 300ms ease-out',
        }}
        nonBlocking={true}
      >
        <ScrollContainer
          className={styles.TypeAheadOptionList}
          wrapperClassName={styles.TypeAheadOptionList}
          onMouseMove={this.handleMouseMove}
          onKeyDown={this.handleKeydown}
          ref={(el) => this.sc = el}
          list
        >
          {optionList}
        </ScrollContainer>
      </Pvr>

    );
  }

  public handleKeydown = (e): void => {
    const {options} = this.props;
    const {keyCode} = e;
    if ([Keyboard.TAB, Keyboard.SPACE].includes(keyCode)) { (e.preventDefault)(); }

    if ([Keyboard.UP_ARROW, Keyboard.DOWN_ARROW].includes(keyCode)) {
      (e.preventDefault)();
      let {focused} = this.state;

      if (keyCode === Keyboard.DOWN_ARROW) { focused++; }
      if (keyCode === Keyboard.UP_ARROW) { focused--; }

      const lastIndex = options.length - 1;

      focused = focused < 0 ? 0 : focused;
      focused = focused > lastIndex ? lastIndex : focused;

      this.keyNav = true;

      this.setState({focused}, this.adjustScroll);
    }

    if (keyCode === Keyboard.ENTER) { this.selectFocused(); }

  };

  private selectFocused() {
    const {focused} = this.state;
    const {options, onSelect} = this.props;
    const option = options[focused];

    return onSelect(option);

  }

  public focusOption = (focused): void => {
    // Only focus on hover when the user is moving the mouse
    if (this.keyNav === false) { return this.setState({focused}); }
  };

  public handleMouseMove = (): void => {
    this.keyNav = false;
  };

  private adjustScroll = (): void => {
    const {scrollTop, clientHeight, children} = this.sc.listEl;

    const {focused} = this.state;
    const focusedChild = children[focused] as HTMLLIElement;

    const liHeight = focusedChild.clientHeight;
    const focusedTop = focusedChild.offsetTop;
    const focusedBottom = focusedTop + liHeight;

    // At least a portion of the focused li is overflowing the bottom
    if (focusedBottom > (clientHeight + scrollTop)) {
      this.sc.listEl.scrollTop = focusedBottom - clientHeight;
    // At least a portion of the focused li is overflowing the top
    } else if (focusedTop < scrollTop) {
      this.sc.listEl.scrollTop = focusedTop;
    }
  };
}
