import * as React from 'react';
import Input from './Input';
import Radio, { IRadioProps } from './Radio';

export default class RadioInput extends React.Component<IRadioProps, any> {
  public radio: React.RefObject<Radio> = React.createRef();

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Radio {...props} {...this.props} ref={this.radio}/>
          );
        }}
      </Input>
    );
  }
}
