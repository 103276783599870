import * as React from 'react';
import Input from './Input';
import Select, { ISelectProps } from './Select';

/**
 * 
 * @param value The value of the input
 * 
 * @param name The name of the input
 * 
 * @param options An array of objects w/ a value and label property or an array of objects w/ any values
 * 
 * @param selectText text for the default option
 * 
 * @param labelField The attribute name from the collection of objects to be used as the select label
 * 
 * @param valueField The attribute value from the collection of objects to be used as the select value
 * 
 * @param returnFullObjects Get value will return a full object instead of just the valueField
 * 
 * @param onChange method that will change the value of the input prop. Passed a single param that is the new value of the input
 * 
 * @param className optional class to be added the select element itself
 * 
 * @param id optional id to be added the input element itself
 * 
 * @param wrapperClass class to be added to the wrapper div
 * 
 * @param wrapperLabel text for wrapping label element
 * 
 * @param loading indicator to determine that the input is loading a value from the server
 * 
 * @param tabIndex tab index for the input
 * 
 * @param onEnterKey handler for enter key up event
 * 
 * @param disabled disabled state of the input
 * 
 * @param validation
 *   a method that takes the value and returns an arry of validation objects
 *   always return an empty array for a valid value
 *   see the validation store for more documentation on validation objects
 * 
 * @param isInPopover
 *   set this to yes if the form is inside a popover or modal, forces the
 *   validation to display above the popover/modal layer
 *   Inconsequential if validation is not being used
 * 
 * @param delayedActionOnChange Takes action and interval parameters, will fire the action after the set interval everytime the data changes
 * 
 * @param openOnMount opens the drop down when it mounts
 * 
 * @param tabId If this input is within a tab, this id will be used for validation errors in the store
 * 
 * @param returnNull When the default or 'placeholder' value is selected, instead of returning empty string, return null
 */
export default class SelectInput extends React.Component<ISelectProps, any> {
  private input: Select;

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Select {...this.props} {...props} ref={(input) => this.input = input} getValue={this.getValue} />
          );
        }}
      </Input>
    );
  }

  public getValue(): any {
    return this.input.getValue();
  }
}
