import { IKeyboardLayouts } from './SoftKeyboard';

// TODO: replace layout with array pos
export const KEYBOARD_KEYS = [
  '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
  '-', '/', '(', ')', '<', '>', '+', '=', '%', '#',
  '@', '.', ',', '?', '!', '\'',
  'à', 'á', 'â', 'ä', 'æ', 'å', 'ç', 'è', 'é', 'ë',
  'ê', 'î', 'ï', 'ì', 'í', 'ñ', 'ô', 'ö', 'ò', 'ó',
  'œ', 'ø', 'ß', 'ù', 'ú', 'ü',
  'À', 'Á', 'Â', 'Ä', 'Æ', 'Å', 'Ç', 'È', 'É', 'Ë',
  'Ê', 'Î', 'Ï', 'Ì', 'Í', 'Ñ', 'Ô', 'Ö', 'Ò', 'Ó',
  'Œ', 'Ø', 'ẞ', 'Ù', 'Ú', 'Ü',
  'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p',
  'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l',
  'z', 'x', 'c', 'v', 'b', 'n', 'm',
  'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
  'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L',
  'Z', 'X', 'C', 'V', 'B', 'N', 'M',
  ' ',
];

export const DEFAULT_QWERTY_KEYBOARD_LAYERS = {
  // tslint:disable-next-line:object-literal-key-quotes
  '123': [
    '1 2 3 4 5 6 7 8 9 0',
    '- / ( ) < > + = % #',
    '{shift} {hidden} @ . , ? ! \' {hidden} {bksp}',
    '{abc} {àêö} {space} {enter}',
  ],
  // tslint:disable-next-line:object-literal-key-quotes
  'diacriticLowercase': [
    'à á â ä æ å ç è é ë',
    'ê î ï ì í ñ ô ö ò ó',
    '{shift} {hidden} œ ø ß ù ú ü {hidden} {bksp}',
    '{abc} {123s} {space} {enter}',
  ],
  // tslint:disable-next-line:object-literal-key-quotes
  'diacriticUppercase': [
    'À Á Â Ä Æ Å Ç È É Ë',
    'Ê Î Ï Ì Í Ñ Ô Ö Ò Ó',
    '{shift} {hidden} Œ Ø ẞ Ù Ú Ü {hidden} {bksp}',
    '{abc} {123s} {space} {enter}',
  ]};

export const DEFAULT_ABC_KEYBOARD_LAYERS = {
  // tslint:disable-next-line:object-literal-key-quotes
  '123': [
    '1 2 3 4 5',
    '6 7 8 9 0',
    ', ? ! \' /',
    '+ - = % #',
    '( ) ~ < >',
    '@ {àêö} {abc} {bksp}',
    '{shift} {space} {enter}',
  ],
  // tslint:disable-next-line:object-literal-key-quotes
  'diacriticLowercase': [
    'à á â ä æ',
    'å ç è é ë',
    'ê î ï ì í',
    'ñ ô ö ò ó',
    'œ ø ß ù ú',
    'ü {abc} {123s} {bksp}',
    '{shift} {space} {enter}',
  ],
  // tslint:disable-next-line:object-literal-key-quotes
  'diacriticUppercase': [
    'À Á Â Ä Æ',
    'Å Ç È É Ë',
    'Ê Î Ï Ì Í',
    'Ñ Ô Ö Ò Ó',
    'Œ Ø ẞ Ù Ú',
    'Ü {abc} {123s} {bksp}',
    '{shift} {space} {enter}',
  ]};

export const SOFT_KEYBOARD_LAYOUTS: IKeyboardLayouts = {
  QWERTY: {
    default: {
      layout: {
        // tslint:disable-next-line:object-literal-key-quotes
        'abcLowercase': [
          'q w e r t y u i o p',
          'a s d f g h j k l',
          '{shift} z x c v b n m {bksp}',
          '{123} {space} {enter}',
        ],
        // tslint:disable-next-line:object-literal-key-quotes
        'abcUppercase': [
          'Q W E R T Y U I O P',
          'A S D F G H J K L',
          '{shift} Z X C V B N M {bksp}',
          '{123} {space} {enter}',
        ],
        ...DEFAULT_QWERTY_KEYBOARD_LAYERS,
      },
      height: 370,
    },
  },
  QWERTZ: {
    default: {
      layout: {
        // tslint:disable-next-line:object-literal-key-quotes
        'abcLowercase': [
          'q w e r t z u i o p',
          'a s d f g h j k l',
          '{shift} y x c v b n m {bksp}',
          '{123} {space} {enter}',
        ],
        // tslint:disable-next-line:object-literal-key-quotes
        'abcUppercase': [
          'Q W E R T Z U I O P',
          'A S D F G H J K L',
          '{shift} Y X C V B N M {bksp}',
          '{123} {space} {enter}',
        ],
        ...DEFAULT_QWERTY_KEYBOARD_LAYERS,
      },
      height: 370,
    },
  },
  ABCDEF: {
    default: {
      layout: {
        // tslint:disable-next-line:object-literal-key-quotes
        'abcLowercase': [
          'a b c d e',
          'f g h i j',
          'k l m n o',
          'p q r s t',
          'u v w x y',
          'z . {123} {bksp}',
          '{shift} {space} {enter}',
        ],
        // tslint:disable-next-line:object-literal-key-quotes
        'abcUppercase': [
          'A B C D E',
          'F G H I J',
          'K L M N O',
          'P Q R S T',
          'U V W X Y',
          'Z . {123} {bksp}',
          '{shift} {space} {enter}',
        ],
        ...DEFAULT_ABC_KEYBOARD_LAYERS,
      },
      height: 534,
    },
  },
  123: {
    default: {
      layout: {
        // tslint:disable-next-line:object-literal-key-quotes
        'default': [
          '1 2 3 4 5 6 7 8 9 0',
          '{bksp} {enter}',
        ],
      },
      height: 200,
    },
  },
};
