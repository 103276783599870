import * as React from 'react';

const styles = require('./styles/toggle_switch.styl');

interface IProps {
  value: boolean;
  jsonPath?: string;
  handleChange: (value, jsonPath?) => void;
  className?: string;
  subClass?: string;
  disabled?: boolean;
  name?: string;
  id?: string;
  label?: string;
}

export default class ToggleSwitch extends React.Component<IProps, any> {

  public static defaultProps = {
    disabled: false,
  };

  public render(): JSX.Element {
    const { className, subClass, value, disabled, id, label } = this.props;

    let mainClass = className || styles.ToggleSwitch;
    mainClass += subClass ? ` ${subClass}` : '';

    const labelEl = label ? <label htmlFor={id ? id : null}>{label}</label> : null;

    return (
      <div className={styles.Wrapper}>
        {labelEl}
        <input
          id={id}
          type="checkbox"
          className={mainClass}
          checked={value}
          onChange={this.handleChange}
          disabled={disabled}
        />
      </div>
    );
  }

  public handleChange = () => {
    const { value, jsonPath, handleChange} = this.props;
    handleChange(!value, jsonPath);
  };

}
