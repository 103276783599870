import * as UAParser from 'ua-parser-js';
import { observable, action, runInAction } from 'mobx';
import * as ServerRequest from '@Lineup/ServerRequest';
const { Req } = ServerRequest;
import { IQuantumObjRes, IQOrganization, IQRegion, IQRegionRes, IQLanguage, IQLanguageRes, IQTimeZone, IQTimeZoneRes, IServerBuildInfo, IQualityControlPolicy, IQualityControls } from '../global/interfaces';
import { InstDataSources } from '../global/enums';
import { LoggedInUserStore } from './LoggedInUser';
import { MobileBridgeStore } from './MobileBridge';
import { FACTORY_CODE } from '../global/constants';
import * as compareVersions from 'semver/functions/compare';
import * as coerce from 'semver/functions/coerce';

interface IGetCodeOptions {
  alwaysReGet?: boolean;
}

class System {
  @observable public orgRegion: IQRegion;
  @observable public orgSettings: Map<string, any> = new Map();
  @observable public organization: IQOrganization;
  @observable public regions: Map<number, IQRegion> = new Map();
  @observable public languages: Map<number, IQLanguage> = new Map();
  @observable public timeZones: Map<number, IQTimeZone> = new Map();
  @observable public os: string = '';
  @observable public device: string = '';
  @observable public serverBuildInfo: IServerBuildInfo;
  @observable public qcPolicies: IQualityControlPolicy[] = [];
  @observable public qcOrgPolicies: IQualityControlPolicy[] = [];
  @observable public qcOrgPoliciesByVersion: Map<string, IQualityControlPolicy> = new Map();
  @observable public qcWkGpPolicies: IQualityControlPolicy[] = [];
  @observable public qcWkGpPoliciesByVersion: Map<string, IQualityControlPolicy> = new Map();

  public abort: AbortController = new AbortController();

  constructor() {
    // Check the operating system
    const ua = UAParser();
    this.os = ua.os.name;
    this.device = ua.device.type;
  }

  /**
   * This can is called after login to perform any data fetches required
   */
  @action public appSetup = (accessCode: string): Promise<any> => {
    const promises = [];

    if (process.env.CS) {
      promises.push(this.doSomeSetup());
    }
    else {
      promises.push(LoggedInUserStore.getCurrentUser());
      if (accessCode !== FACTORY_CODE) {
        promises.push(this.getOrganization());
        // promises.push(this.getQualityControlForOrg());
        promises.push(this.getRegions());
        promises.push(LoggedInUserStore.getPasswordRules());
        promises.push(this.getOrgSettings());
      }
    }

    return Promise.all(promises)
      .then(this.setOrgRegion);
  };

  @action public doSomeSetup = (): Promise<any> => {
    return Promise.resolve();
  };

  /**
   * Sets the region of the organization by looking up Region_Id in the regions map
   */
  @action public setOrgRegion = (): void => {
    const { Region_Id } = this.organization;
    this.orgRegion = this.regions.get(Region_Id);
  };

  @action public getOrganization = (): Promise<any> => {
    return Req.get({
      url: '/api/organization/getfirst',
    })
    .then((res: IQuantumObjRes) => {
      runInAction(() => {
        this.organization = res.Object as IQOrganization;

        // if mobile app & middleware is turned on
        if (process.env.MOBILE_APP && this.organization.DataTypes?.TestResults === InstDataSources.MIDDLEWARE) {
          // turn off act as hub
          MobileBridgeStore.toggleUseAsHub(false);
        }
      });
    });
  };

  @action public getOrgSettings = (): Promise<Map<string, boolean>> => {
    return Req.get({
      url: '/api/Organization/GetAllSettings',
    })
      .then((res: any) => {
        // Transform boolean strings into proper booleans, leave other values alone
        this.orgSettings = new Map(res.Object.OrganizationSettings.map((s) => [s.SettingName, ['True', 'False'].includes(s.SettingValue) ? s.SettingValue === 'True' : s.SettingValue]));

        return this.orgSettings;
      });
  };

  @action public getRegions = (options?: IGetCodeOptions): Promise<any> => {
    const { alwaysReGet = false } = options || {};

    if (this.regions.size && !alwaysReGet) {
      return Promise.resolve(this.regions);
    }

    return Req.get({
      url: '/api/organization/getregions',
    })
      .then(({ Object: { Regions } }: IQuantumObjRes<IQRegionRes>) => {
        runInAction(() => {
          this.regions = new Map(Regions.map<Readonly<[number, IQRegion]>>(r => [r.Id, {...r, Name: t(r.Name) }]));
        });
      });
  };

  @action public getLanguages = (options?: IGetCodeOptions): Promise<any> => {
    const { alwaysReGet = false } = options || {};

    if (this.languages.size && !alwaysReGet) {
      return Promise.resolve(this.languages);
    }

    return Req.get({
      url: '/api/organization/getlanguages',
    })
      .then(({ Object: { Languages } }: IQuantumObjRes<IQLanguageRes>) => {
        runInAction(() => {
          this.languages = new Map(Languages.map<Readonly<[number, IQLanguage]>>(l => [l.Id, l]));
        });
      });
  };

  @action public getTimeZones = (options?: IGetCodeOptions): Promise<any> => {
    const { alwaysReGet = false } = options || {};

    if (this.timeZones.size && !alwaysReGet) {
      return Promise.resolve(this.timeZones);
    }

    return Req.get({
      url: '/api/organization/gettimezones',
    })
      .then(({ Object: { TimeZones } }: IQuantumObjRes<IQTimeZoneRes>) => {
        runInAction(() => {
          this.timeZones = new Map(TimeZones.map<Readonly<[number, IQTimeZone]>>(tz => [tz.Id, { ...tz, Name: t(tz.Name) }]));
        });
      });
  };

  @action public saveOrganization = (org: IQOrganization): Promise<IQOrganization> => {
    return Req.put({
      url: '/api/organization/update',
      body: org,
    })
    .catch(({ message = {} }) => {
      return Promise.reject(message);
    })
    .then((res: IQuantumObjRes): IQOrganization | Promise<any> => {

      runInAction(() => {
        this.organization = res.Object;
      });

      return this.organization;
    });
  };

  @action public updateOrganization = (org): IQOrganization => {
    return this.organization = org;
  };

  @action public getServerBuild = (): Promise<any> => {
    return Req.get({
      url: '/api/Service/GetVersionDetails',
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes) => {

        runInAction(() => {
          this.serverBuildInfo = res.Object;
        });

        return this.serverBuildInfo;
      });
  };

  /**
   * Fetches all the quality control policies
   */
  @action public getQualityControlPolicies = (): Promise<any> => {
    return Req.get({
      url: '/api/QualityControl/GetAll',
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes<IQualityControls>) => {
        runInAction(() => {
          this.qcPolicies = res.Object.QualityControls;
        });
      });
  };

  /**
   * Fetches all the quality control policies for the org
   */
  @action public getQualityControlForOrg = (supportedVersions): Promise<any> => {
    return Req.get({
      url: '/api/QualityControl/GetForOrganization',
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes<IQualityControls>) => {
        runInAction(() => {

          const qcOrgPoliciesByVersion = new Map();
          const qcOrgPolicies = [];

          // sort Object by InstrumentVersion in descending order - highest version first
          res.Object.QualityControls.sort((a, b) => compareVersions(coerce(a.InstrumentVersion), coerce(b.InstrumentVersion)) >= 0 ? -1 : 1);

          res.Object.QualityControls.forEach(obj => {
            qcOrgPoliciesByVersion.set(obj.InstrumentVersion, obj);
            qcOrgPolicies.push(obj);
          });

          this.qcOrgPoliciesByVersion = qcOrgPoliciesByVersion;
          this.qcOrgPolicies = qcOrgPolicies;
        });

        return this.qcOrgPoliciesByVersion;
      });
  };

  /**
   * Fetches policies by WorkGroupId
   * @param workgroupId The workgroup ID used to filter the policies
   */
  @action public getQualityControlPolicyByWorkgroupId = (workgroupId: number, supportedVersions) => {
    return Req.get({
      url: `/api/QualityControl/GetByWorkGroupId/${workgroupId}`,
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes<IQualityControls>) => {
        runInAction(() => {
          const qcWkGpPoliciesByVersion = new Map();
          const qcWkGpPolicies = [];

          // sort Object by InstrumentVersion in descending order - highest version first
          res.Object.QualityControls.sort((a, b) => compareVersions(coerce(a.InstrumentVersion), coerce(b.InstrumentVersion)) >= 0 ? -1 : 1);

          res.Object.QualityControls.forEach(obj => {
            qcWkGpPoliciesByVersion.set(obj.InstrumentVersion, obj);
            qcWkGpPolicies.push(obj);
          });

          this.qcWkGpPoliciesByVersion = qcWkGpPoliciesByVersion;
          this.qcWkGpPolicies = qcWkGpPolicies;

        });

        return this.qcWkGpPoliciesByVersion;
      });
  };

  /**
   * Update a policy
   * @param policy The policy body to update
   */
  @action public updateQualityControlPolicy = (policy: IQualityControlPolicy): Promise<IQualityControlPolicy> => {

    return Req.put({
      url: '/api/QualityControl/Update',
      body: policy,
    })
    .catch(({ message = {} }) => {
      return Promise.reject(message);
    })
    .then((res: IQuantumObjRes<IQualityControlPolicy>) => {
      return res.Object;
    });
  };

  /**
   * Create a policy
   * @param policy The policy body
   */
  @action public createQualityControlPolicy = (policy: IQualityControlPolicy) => {
    return Req.post({
      url: '/api/QualityControl/Create',
      body: policy,
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then((res: IQuantumObjRes<IQualityControlPolicy>) => {
        runInAction(() => {
          return res.Object;
        });
      });
  };

  /**
   * Delete a policy
   * @param id The ID of the policy
   */
  @action public deleteQualityControlPolicy = (policy: IQualityControlPolicy) => {
    return Req.delete({
      url: `/api/QualityControl/Delete/${policy.Id}`,
    })
      .catch(({ message = {} }) => {
        return Promise.reject(message);
      })
      .then(() => {
        return Promise.resolve();
      });
  };

  /**
   * Save a fall back policy
   * @param newPolicy The new policy body
   * @param existingPolicy The existing policy body
   */
  @action public saveQualityControlPolicy = (newPolicy: IQualityControlPolicy, existingPolicy: IQualityControlPolicy, recordType: number): Promise<any> => {

    // delete policy - this ensures we only delete an existing qc for the record type - not a default QC whether default org or default workgroup (org qc)
    if (newPolicy === null && existingPolicy && recordType === existingPolicy?.RecordType) {
      this.deleteQualityControlPolicy(existingPolicy);
    }
    // create policy
    else if (newPolicy && (existingPolicy === null || existingPolicy?.RecordType === 0)) {
      this.createQualityControlPolicy(newPolicy);
    }
    // update policy
    else if (newPolicy && existingPolicy) {
      this.updateQualityControlPolicy(newPolicy);
    }
    return Promise.resolve();

  };

  @action public clearLocaleProperties = (): void => {
    this.regions = new Map();
    this.timeZones = new Map();
    this.languages = new Map();
  };

}

const store = new System();
export { System, store as SystemStore };

// Make this available as a global for cypress
(window as any).__SystemStore = store;
