import * as React from 'react';
import { Pvr } from '@Lineup/index';
import { IRuleResult } from '../../global/interfaces';
import { getPasswordRuleSentences } from '../../global/helpers';
import { PvrDirections } from '@Lineup/enums/index';
import { MOBILE_APPS } from '../../global/constants';

const styles = require('./styles/PasswordRules.styl');

interface IProps {
  ruleResults: IRuleResult[];
  close: (value: boolean) => void;
  anchor: HTMLElement;
  direction: PvrDirections;
  height: number;
  showPreviousRule: boolean;
  showCloseButton?: boolean;
  chooseOptimalDirection?: boolean;
}

export default class PasswordRules extends React.Component<IProps, any> {

  public static defaultProps: Partial<IProps> = {
    direction: PvrDirections.AUTO,
    height: 230,
    showPreviousRule: true,
    showCloseButton: false,
  };

  public render(): JSX.Element {
    const { ruleResults, anchor, direction, showPreviousRule, height, showCloseButton, chooseOptimalDirection } = this.props;
    const isAmira = process.env.MOBILE_APP === MOBILE_APPS.AMIRA;

    const rules = ruleResults
      .filter((r) => showPreviousRule ? true : r.RuleName !== 'PasswordReuse')
      .map((result) => {
        const { RuleName, Passed, ComparisonValue } = result;
        const rule = getPasswordRuleSentences(RuleName, ComparisonValue);

        return Passed ? (
          <div key={RuleName} className={`${styles.result} ${isAmira ? styles.Amira : ''}`}>
            <span key="image" className={styles.success} />
            <div key="rule" >{rule}</div>
          </div>
        ) : (
          <div key={RuleName} className={`${styles.result} ${isAmira ? styles.Amira : ''}`}>
            <span key="image" className={styles.error} />
            <div key="rule" >{rule}</div>
          </div>
        );
      });

    return (
      <Pvr
        height={height}
        hAdjust={-2}
        width={360}
        anchor={anchor}
        nibColor="white"
        close={this.closePvr}
        direction={direction}
        nonBlocking
        chooseOptimalDirection={chooseOptimalDirection}
      >
        <div className={styles.PasswordRules}>
          {showCloseButton && <button className={styles.CloseBtn} onClick={this.closePvr} />}
          <h4>{t('Password requirements:')}</h4>
          {rules}
        </div>
      </Pvr>
    );
  }

  public closePvr = (): void => {
    this.props.close(false);
  };
}
