import { InvalidTokens } from './enums';
const { EXPIRED, NOT_SET, DISABLED, ROLE_CHANGE, PASSWORD_CHANGE, NEW_SESSION, RESET_PASSWORD  } = InvalidTokens;

// This indicates the minimum version of the native app that is compatible with this version of the UI
// If the native app is lower than this version, the user will be directed to the app/play store to upgrade
// Be careful updating this too early, as it could cause the app to be unusable should the user not be able to get this version
export const MIN_SUPPORTED_APP_VERSION = '8.2.0';

export const FACTORY_CODE = 'ldxfactory';
export const TOOLS_CODE = 'ldxtools';
export const API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const API_DATE_TIMEZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const MOBILE_APPS = {
  ENGAGE: 'engage',
  CONNECT: 'connect',
  AMIRA: 'amira',
  TOOLS: 'tools',
  INSTRUMENT: 'instrument',
  ON_PREM: 'on-prem',
};

export const APP_NAME = function() {
  switch (process.env.MOBILE_APP) {
    case MOBILE_APPS.CONNECT:
      return 'LumiraDx Connect';
    case MOBILE_APPS.AMIRA:
      return 'LumiraDx Amira';
    case MOBILE_APPS.ENGAGE:
      return 'LumiraDx Engage';
    default:
      return '';
  }
}();

export const enum PasswordEnvRules {
  CONNECT = 0,
  ENGAGE = 1
}

export const PROD_HOF_URL = 'https://connect.lumiradx.com/hof';

export const ONLINE_THRESHOLDS = {
  LOW: 2,
  HIGH: 2,
};

/**
 * Returns a map of the invalid token reason enum to translated ui strings
 */
export const InvalidTokenMsgs = (): Map<InvalidTokens, string> => {
  return new Map([
    [EXPIRED, t('Your session has expired. Please login again.')],
    [NOT_SET, t('You have been signed out.')],
    [DISABLED, t('You have been signed out due to your account being disabled by an admin.')],
    [ROLE_CHANGE, t('You have been signed out due to your user role being changed by an admin.')],
    [PASSWORD_CHANGE, t('You have been signed out due to your password being changed by an admin.')],
    [NEW_SESSION, t('You have been signed out due to the creation of another session with the same credentials.')],
    [RESET_PASSWORD, t('You have been signed out due to a password reset attempt.')],
  ]);
};

export const DOCUMENT_TITLE: Map<string, () => string> = new Map([
  ['/home/connect/users', () => t('Users')],
  ['/home/connect/instruments', () => t('LumiraDx Instruments')],
  ['/home/connect/workgroups', () => t('WorkGroups')],
  ['/home/connect/hubs', () => t('LumiraDx Connect Hubs')],
  ['/home/connect/reporting', () => t('Test Results')],
  ['/home/connect/settings', () => t('Settings')],
  ['/home/connect/qualitycontrol', () => t('Quality Control')],
  ['/home/connect/onpremises', () => t('On Premises Export')],
  ['/home/connect', () => t('LumiraDx Connect')]
]);

/**
 * Timeout value when connecting to a hub to configure; 2 minutes in milliseconds
 */
export const HUB_CONNECTION_TIMEOUT = 120000;

export const ERROR_CODES: Map<string, () => any> = new Map([
  ['0', () => {
    return {
      msg: '',
      uiCode: '',
    };
  },
  ],
  ['Unknown', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  },
  ],
  ['3501', () => {
    return {
      msg: t('Door Open'),
      uiCode: '002',
    };
  },
  ],
  ['3506', () => {
    return {
      msg: t('No Test Strip Detected'),
      uiCode: '003',
    };
  }],
  ['3503', () => {
    return {
      msg: t('Sample Detected'),
      uiCode: '004',
    };
  }],
  ['1615', () => {
    return {
      msg: t('Temperature Error'),
      uiCode: '005',
    };
  }],
  ['3510', () => {
    return {
      msg: t('Instrument Not Level'),
      uiCode: '006',
    };
  }],
  ['3512', () => {
    return {
      msg: t('Instrument Not Level'),
      uiCode: '006',
    };
  }],
  ['3600', () => {
    return {
      msg: t('Test Timeout'),
      uiCode: '016',
    };
  }],
  ['3601', () => {
    return {
      msg: t('Test Timeout'),
      uiCode: '016',
    };
  }],
  ['3602', () => {
    return {
      msg: t('Test Timeout'),
      uiCode: '016',
    };
  }],
  ['3603', () => {
    return {
      msg: t('Test Timeout'),
      uiCode: '016',
    };
  }],
  ['3604', () => {
    return {
      msg: t('Test Timeout'),
      uiCode: '016',
    };
  }],
  ['3503_QC', () => {
    return {
      msg: t('QC Solution Detected'),
      uiCode: '017',
    };
  }],
  ['2106', () => {
    return {
      msg: t('Sample Type Error'),
      uiCode: '018',
    };
  }],
  ['2105', () => {
    return {
      msg: t('Hct Out of Range'),
      uiCode: '019',
    };
  }],
  ['2230', () => {
    return {
      msg: t('Test Lot Not Permitted'),
      uiCode: '022',
    };
  }],
  ['3514', () => {
    return {
      msg: t('Test Lot Not Permitted'),
      uiCode: '022',
    };
  }],
  ['3515', () => {
    return {
      msg: t('Test Type Not Permitted'),
      uiCode: '023',
    };
  }],
  ['2201', () => {
    return {
      msg: t('Test Strip Lot Expired'),
      uiCode: '026',
    };
  }],
  ['3507', () => {
    return {
      msg: t('Temperature Error'),
      uiCode: '033',
    };
  }],
  ['2218', () => {
    return {
      msg: t('Test Strip Not Supported'),
      uiCode: '036',
    };
  }],
  ['2219', () => {
    return {
      msg: t('Test Strip Not Supported'),
      uiCode: '037',
    };
  }],
  ['3605', () => {
    return {
      msg: t('Sample Error'),
      uiCode: '038',
    };
  }],
  ['3504', () => {
    return {
      msg: t('Insufficient Sample'),
      uiCode: '042',
    };
  }],
  ['3302', () => {
    return {
      msg: t('Test Strip Reading Error'),
      uiCode: '105',
    };
  }],
  ['3502', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3505', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3508', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3509', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3606', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3607', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3608', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '108',
    };
  }],
  ['3609', () => {
    return {
      msg: t('Test Cancelled'),
      uiCode: '',
    };
  }],
  ['2217', () => {
    return {
      msg: t('Invalid Lot Cal File'),
      uiCode: '109',
    };
  }],
  ['2220', () => {
    return {
      msg: t('Invalid Lot Cal File'),
      uiCode: '109',
    };
  }],
  ['2229', () => {
    return {
      msg: t('Invalid Lot Cal File'),
      uiCode: '109',
    };
  }],
  ['2231', () => {
    return {
      msg: t('Invalid Lot Cal File'),
      uiCode: '109',
    };
  }],
  ['1805', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '116',
    };
  }],
  ['4001', () => {
    return {
      msg: t('Test Operation Error'),
      uiCode: '117',
    };
  }],
  ['2111', () => {
    return {
      msg: t('Sample Error'),
      uiCode: '051',
    };
  }],
]);
