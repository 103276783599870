import { observable, action, computed, when } from 'mobx';
import { getItem, setItem } from '../helpers/LocalStorage';
import * as ServerRequest from '@Lineup/ServerRequest';
const { Req } = ServerRequest;
import { IModuleManifest } from '../global/interfaces';
import * as moment from 'moment';
import { MobileBridgeStore } from './MobileBridge';
import { SystemStore } from './System';
import { MOBILE_APPS } from '../global/constants';
import { LoggedInUserStore } from './LoggedInUser';
import { chooseModuleVersion } from '../global/helpers';
import * as compareVersions from 'semver/functions/compare';
import * as coerce from 'semver/functions/coerce';
import { languages } from '../global/languages';

interface ILang {
  name: string;
  altAbbr: string;
  serverAbbr: string;
  strings: any;
  modules: Map<string, string>;
}

export type ILangConfig = Map<string, ILang>;

export class Application {
  @observable public locale: string = 'en-US';
  @observable public status;
  @observable public scrollBarWidth: number;
  @observable public availableModules: any[] = [];
  @observable public modulesById: Map<string, IModuleManifest> = new Map();
  @observable public childrenById: Map<string, IModuleManifest[]> = new Map();
  @observable public languages: ILangConfig = languages;

  constructor() {
    // Wait for the app data to be set
    if (process.env.MOBILE_APP && process.env.MOBILE_APP !== MOBILE_APPS.INSTRUMENT && process.env.MOBILE_APP !== MOBILE_APPS.ON_PREM) {
      when(
        () => MobileBridgeStore.appData !== undefined,
        () => {
          this.setLocale(this.initializeLocale());
        }
      );
    }
    else {
      this.setLocale(this.initializeLocale());
    }

  }

  public initializeLocale = (): string => {
    // Look for language first in local storage, then in Connect app data, then the browser language setting
    // Note: question marks in the following line are a workaround for jest thinking getItem and MobileBridgeStore are undefined for inexplicable reasons
    const savedLocale = getItem?.('ldxLocale', false) || MobileBridgeStore?.ldxConnectData.locale || navigator.language || 'en-US';
    const lang = savedLocale.substr(0, 2).toUpperCase();

    if (process.env.MOBILE_APP) {
      const { log } = MobileBridgeStore;
      log(`init locale: ${savedLocale}`);
    }

    for (const k of this.languages.keys()) {
      // Return a potential language + locale match first
      if (savedLocale === k) {
        return k;
      }
      // Otherwise accept just a language match
      else if (lang === k) {
        return k;
      }
    }

    return 'en-US';
  };

  @action public checkLocale = (locale: string, module: string = null): boolean => {
    const language = languages.get(locale);
    return (language && (!module || language.modules.has(module)));
  };

  @action public setLocale = (locale: string, persist: boolean = true): void => {
    // Remove Connect only check once Engage uses bridge V2
    if (process.env.MOBILE_APP === MOBILE_APPS.CONNECT || process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      const { setConnectData } = MobileBridgeStore;
      setConnectData({ locale });
    } else if (persist) {
      setItem?.('ldxLocale', locale, false);
    }

    // Clear these code sets, as there names are translated after the GETs
    // Clearing them will trigger new GETs (when necessary), and the names will be retranslated
    SystemStore.clearLocaleProperties();

    document.getElementsByTagName('html')[0].lang = locale;
    this.locale = locale;

    // Fetch strings for each module
    this.processModules(this.availableModules).then(() => moment.locale(locale));
  };

  @computed get strings() {
    return { ...this.languages.get('en-US').strings, ...this.languages.get(this.locale).strings };
  }

  @action public calculateScrollBarWidth = (): void => {
    const outer = document.createElement('div');
    outer.style.overflow = 'scroll';
    outer.style.height = '500px';
    outer.style.width = '500px';
    outer.style.position = 'absolute';
    outer.style.top = '100px';
    outer.style.left = '100px';

    const inner1 = document.createElement('div');
    inner1.style.position = 'absolute';
    inner1.style.height = '100%';
    inner1.style.width = '100%';

    const inner2 = document.createElement('div');
    inner2.style.height = '600px';
    inner2.style.width = '600px';

    outer.appendChild(inner1);
    outer.appendChild(inner2);

    document.body.appendChild(outer);
    const scrollBarWidth = outer.offsetHeight - inner1.offsetHeight;
    document.body.removeChild(outer);

    this.scrollBarWidth = scrollBarWidth;
  };

  @action public getAvailableModules = (): Promise<any> => {
    if (process.env.MOBILE_APP && !process.env.MOCK_CONNECT_APP) {
      return Promise.resolve(Array.from((window as any).__UI_MODULES__, mod => {
        return mod as IModuleManifest;
      }))
        .then((res: IModuleManifest[]) => {
          this.availableModules = res;
          this.processModules(res);
        });
    }
    return Req.get({
      prefix: '',
      url: process.env.CS ? '/ui-modules/' : '/ui-modules/v2',
      cacheBust: false,
    })
      .catch((e: string) => {
        return Promise.reject(e);
      })
      .then((res: IModuleManifest[]) => {
        this.availableModules = res;
        this.processModules(res);
      });
  };

  @action public getModuleStrings = (module: IModuleManifest, lang: string): Promise<any> => {
    const { accessDto } = LoggedInUserStore;
    const { availableVersions = [] } = module;
    const versions = Array.from(availableVersions, (v) => v[0]);
    const version = chooseModuleVersion(accessDto?.Version, versions, module.version);

    return Req.get({
      prefix: '',
      url: `/ui-modules/${module.id}/${version}/strings/${lang}`,
    })
      .catch((e: string) => {
        return Promise.reject(e);
      })
      .then((res: any = {}) => {
        return res;
      });
  };

  @action public processModules = (modules: IModuleManifest[]): Promise<any> => {
    const modulePromises = [];
    modules.forEach((module) => {
      const { id, strings, children } = module;

      this.modulesById.set(id, module);

      // If children are encounterd, put them in a map and recursively process them
      if (children?.length > 0) {
        this.childrenById.set(id, children);
        this.processModules(children);
      }

      // If using the v2 modules API, we will need to fetch the strings separately
      if (!strings && this.locale !== 'en-US') {
        modulePromises.push(new Promise((resolve, reject) => {
          when(
            () => LoggedInUserStore.accessDto != null || process.env.MOBILE_APP === MOBILE_APPS.INSTRUMENT || process.env.MOBILE_APP === MOBILE_APPS.ON_PREM,
            () => {
              const { Version = module.version } = LoggedInUserStore.accessDto || {};
              const { modules } = this.languages.get(this.locale);

              // Get the language for this module if the language is supoprted by this module and version
              // Note that factory has no version, so skip the version check when it is not present.
              if (modules.has(module.id) && (!Version || compareVersions(coerce(Version), coerce(modules.get(module.id))) >= 0)) {
                this.getModuleStrings(module, this.locale)
                  .catch((e: string) => {
                    return resolve(null);
                  })
                  .then((s) => {
                    this.mergeModuleStrings(module, s);
                    return resolve(null);
                  });
              }
              else {
                resolve(null);
              }
            }
          );
        }));
      }
      // Using the v1 modules API
      else {
        this.mergeModuleStrings(module, strings);
        modulePromises.push(Promise.resolve());
      }
    });
    return Promise.all(modulePromises);
  };

  @action public mergeModuleStrings = (module: IModuleManifest, moduleStrings: any = {}): void => {
    // Merge the module's strings with the base strings
    for (const locale in moduleStrings) {
      if (Object.prototype.hasOwnProperty.call(moduleStrings, locale)) {
        const localeConfig = this.languages.get(locale);
        // Extend the locale strings onto the base strings.
        // Warning: Make sure modules have consistent translations for same keys, as existing keys are overwritten
        const extendedStrings = { ...localeConfig.strings, ...moduleStrings[locale] };
        this.languages.set(locale, { ...localeConfig, strings: { ...extendedStrings } });
      }
    }
  };
}

export const ApplicationStore = new Application();
// Make this available as a global for cypress
(window as any).__ApplicationStore = ApplicationStore;
