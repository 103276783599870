import * as React from 'react';

const styles = require('./styles/grid.styl');

export interface IGridFormSectionTextProps {
  text: string;
  textClass?: string;
  className?: string;
}

export default class GridFormSectionText extends React.Component<IGridFormSectionTextProps, any> {
 
  public static defaultProps = {
    text: '',
    textClass: styles.Title,
    className: styles.FormGrid,    
  };

  public render(): JSX.Element {
    const { text, className, textClass } = this.props;
 
    return (
      <div className={className}>
        <div key="title" className={textClass}>{text}</div>
      </div>
    ); 
  }
}
