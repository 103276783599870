import * as React from 'react';
import { IBaseInputProps } from './interfaces';

const styles = require('./styles/multi_select.styl');

export interface IMultiSelectValueProps extends IBaseInputProps {
  value: any;
  disabled: boolean;
  labelField?: string;
  onRemove: (option: any) => any;
}

export default class MultiSelectValue extends React.Component<IMultiSelectValueProps, any> {

  public render() {
    const {labelField, value, disabled} = this.props;

    const label = value[labelField] || value;

    return (
      <div className={styles.MultiselectValue}>
        <span key="label" data-test={label}>{label}</span>
        <button
          data-test={`${label}-remove`}
          key="remove"
          onClick={this.handleRemove}
          tabIndex={-1}
          disabled={disabled}
        />
      </div>
    );
  }
  
  public handleRemove = (e): void => {
    e.stopPropagation();
    const {onRemove, value} = this.props;
    onRemove(value);
  };
}
