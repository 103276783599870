import * as React from 'react';
import Input from './Input';
import Search, { ISearchProps } from './Search';

/**
 * @param wrapperClass
 * className to apply to the div wrapper
 * @param placeholder
 * placeholder text for searh field
 * @param height
 * height of the inout
 * @param width
 * width of the input
 * @param changeDelay
 * ms delay before calling change handler
 * @param handleChange
 * method to call when the search term changes
 * @param focusOnMount
 * whether or not the search input should focus immediately after mounting
 * @param tabIndex
 * tab order of the text input
 * @param value
 * the value of the search field when first rendered
 */
export default class SearchInput extends React.Component<ISearchProps, any> {
  private input: Search;

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Search {...this.props} {...props} ref={(input) => this.input = input} getValue={this.getValue} />
          );
        }}
      </Input>
    );
  }

  public getValue(): string {
    return this.input.getValue();
  }

  public focus() {
    this.input.focus();
  }
}
