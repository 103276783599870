import * as React from 'react';

import { IValidationError, IBaseInputProps, IValidateOptions } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';

import MultiSelectValue from './MultiSelectValue';
import MultiSelectOptionListWgScroll from './MultiSelectOptionListWgScroll';

import Keyboard from './Keyboard';
const { DOWN_ARROW, SPACE } = Keyboard;

const styles = require('./styles/multi_select.styl');
const inputStyles = require('./styles/input.styl');

/*
Multi Select Props

@props.options - REQUIRED - Array
  An array of options for the user to click - can be a flat array (where each entry is both the value and label)
  or an array of objects, where the value is the `valueField` and the label is the `labelField` (see below)

@props.value - OPTIONAL - Array
  A flat array of value that the control initializes to. If the options array is objects, each entry should be the
  `valueField` value. Will default to an empty array

@props.labelField - OPTIONAL - String
  The attribute name from each option object that is the user facing label

@props.valueField - OPTIONAL - String
  The attribute name from each option object that is the 'value`

@props.returnFullObjects - OPTIONAL - Boolean - default: false
  Whether or not the value returned from the input are a collection of objects or a flat array of value

@props.onChange - OPTIONAL - function
  Function/method to fire when the data changes

@props.placeholder - OPTIONAL
  placeholder text for an empty control

@props.tabIndex - OPTIONAL
  tab order of the edi button

@props.onRemove - OPTIONAL - function
  function call when an item is removed, will pass the removed item
*/

export interface IMultiSelectProps extends IBaseInputProps {
  options: any[];
  returnFullObjects?: boolean;
  labelField?: string;
  valueField?: string;
  closeOnSelect?: boolean;
  validate?: (options: IValidateOptions) => IValidationError;
}

interface IState {
  value: any[];
  focused: boolean;
  optionsOpen: boolean;
  showErrors: boolean;
}

export default class MultiSelectScroll extends React.Component<IMultiSelectProps, IState> {
  public anchor: React.RefObject<HTMLDivElement> = React.createRef();
  public focusBtn: React.RefObject<HTMLButtonElement> = React.createRef();
  public errorAnchor: React.RefObject<HTMLDivElement> = React.createRef();

  public static defaultProps = {
    returnFullObjects: true,
    disabled: false,
    labelField: 'label',
    valueField: 'value',
    closeOnSelect: false,
  };

  constructor(props) {
    super(props);
    const {value} = this.props;

    this.state = {
      value: value.slice(0),
      focused: false,
      showErrors: false,
      optionsOpen: false,
    };
  }

  public componentDidMount() {
    this.focusBtn.current.addEventListener('focus', this.handleFocus);
    this.focusBtn.current.addEventListener('blur', this.handleBlur);
  }

  public UNSAFE_componentWillReceiveProps(nextProps) {
    const {value, validation, valueField, returnFullObjects, validate} = nextProps;

    // This will update the state should the value change externally after the component is mounted
    // This is an ugly side effect of transferring props to state, but also needing to react to prop changes
    // Try a quick compare of length
    if (this.state.value.length !== value.length) {
      this.setState({ value });
      return validate({ value, validation, anchor: this.errorAnchor.current });
    // Otherwise resort to
    } else {
      let i;
      for (i = 0; i < this.state.value.length; i++) {
        const value = this.state.value[i];
        if (returnFullObjects) {
          if (value[valueField] !== (value[i] != null ? value[i][valueField] : undefined)) { break; }
        } else {
          if (value !== value[i]) { break; }
        }
      }

      // Don't update state if no differences were found
      if (i === this.state.value.length) { return; }

      this.setState({ value });
      return validate({ value, validation, anchor: this.errorAnchor.current });
    }
  }

  public componentWillUnmount(): void {
    this.focusBtn.current.removeEventListener('focus', this.handleFocus);
    this.focusBtn.current.removeEventListener('blur', this.handleBlur);
  }

  public render(): JSX.Element {
    const { focused, value, showErrors, optionsOpen } = this.state;
    const { options, tabIndex, disabled, valueField, labelField, placeholder, validate,
      forceShowAllErrors, validation, valueHasChanged, flashErrors, returnFullObjects, closeOnSelect, jsonPath } = this.props;
    const error = validate({ value, validation, anchor: this.errorAnchor.current });
    const isValid = error.messages.length === 0;

    let className = `${styles.MultiSelect} ${inputStyles.FieldWrap}`;
    if (!isValid && (valueHasChanged || forceShowAllErrors)) { className += ` ${styles.Invalid}`; }
    if (disabled) { className += ` ${styles.Disabled}`; }
    if (focused) { className += ` ${styles.Focused}`; }

    const selected = value.map((value) => {
      return (
        <MultiSelectValue
          key={value[valueField] || value}
          value={value}
          labelField={labelField}
          disabled={disabled}
          onRemove={this.onRemove}
        />
      );
    });

    let placeholderEl;
    if (selected.length === 0) {
      placeholderEl = <div className="placeholder">{placeholder}</div>;
    }

    let optionsPvr;
    if (optionsOpen) {
      optionsPvr = (
        <MultiSelectOptionListWgScroll
          jsonPath={jsonPath}
          options={options}
          value={value}
          onRemove={this.onRemove}
          onAdd={this.onAdd}
          labelField={labelField}
          valueField={valueField}
          returnFullObjects={returnFullObjects}
          closeOnSelect={closeOnSelect}
          close={this.closeOptions}
          anchor={this.anchor.current}
        />
      );
    }

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    return (
      <div className={className} onClick={!disabled ? this.handleClick : null} ref={this.anchor}>
        <button
          className={styles.FocusBtn}
          ref={this.focusBtn}
          onKeyDown={this.handleKeydown}
          tabIndex={tabIndex}
          onFocus={this.handleFocus}
          disabled={disabled}
        />
        {selected || placeholderEl}
        <div
          className={styles.FieldErrorsShow}
          ref={this.errorAnchor}
          onMouseOver={this.handleErrorMouseOver}
          onMouseOut={this.handleErrorMouseOut}
          onTouchStart={this.handleErrorTouch}
        />
        {errorPvr}
        {optionsPvr}
      </div>
    );
  }

  public handleClick = (): void => {
    const { focused } = this.state;

    if (!focused) { this.focusBtn.current.focus(); }
  };

  public handleKeydown = ({keyCode}) => {
    if (![SPACE, DOWN_ARROW].includes(keyCode)) { return; }

    return (this.handleClick)();
  };

  public handleChange = (): void => {
    const {jsonPath, onChange, validation, closeOnSelect, validate} = this.props;
    const { value } = this.state;

    if (typeof onChange === 'function') {
      onChange(value, jsonPath);
    }

    validate({ value, validation, anchor: this.errorAnchor.current });

    if (closeOnSelect) {
      this.closeOptions();
    }
  };

  public onAdd = (newValue): void => {
    const { value } = this.state;
    const { returnFullObjects, valueField } = this.props;
    newValue = returnFullObjects ? newValue : newValue[valueField];

    value.push(newValue);

    this.setState({ value }, this.handleChange);
  };

  public onRemove = (removedValue): void => {
    const { value } = this.state;
    const { valueField, returnFullObjects } = this.props;

    for (let i = 0; i < value.length; i++) {
      const item = value[i];
      if (returnFullObjects ? item[valueField] === removedValue[valueField] : item === removedValue[valueField]) {
        value.splice(i, 1);
        break;
      }
    }

    this.setState({ value }, this.handleChange);
  };

  public handleFocus = (): void => {
    this.setState({
      focused: true,
      optionsOpen: true,
    });
  };

  public closeOptions = (): void => {
    this.setState({
      optionsOpen: false,
    });
  };

  public handleBlur = (): void => {
    this.setState({focused: false});
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    const { valueHasChanged, forceShowAllErrors } = this.props;
    if (!valueHasChanged && !forceShowAllErrors) { return; }

    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

}
