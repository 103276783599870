import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IComponent } from '../../global/interfaces';
import { AlertModal, TextInput, Spinner } from '@Lineup/index';
import { isValidEmail, SaveStates, SpinnerNames } from '@Lineup/enums/index';
import AccountConfirmPassword from './AccountConfirmPassword';
import FormErrorTracker from '../../../../../UI-Core/src/app/helpers/ErrorTracker';
import * as queryString from 'query-string';

const styles = require('./styles/AccountResetPassword.styl');

interface IState {
  saveState?: SaveStates;
  saveMessage?: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
  email: string;
  errorMessage: string;
  openAlertModal: boolean;
  passwordCodeSent: boolean;
  queryCode: string;
  queryCodeDetected: boolean;
}

@inject('loggedInUserStore') @observer
export default class AccountResetPassword extends React.Component<IComponent, IState> {
  private errorTracker: FormErrorTracker = new FormErrorTracker();

  public state: IState;

  constructor(props) {
    super(props);
    const { email, code } = queryString.parse(this.props.location.search);

    this.state = {
      forceShowAllErrors: false,
      flashErrorPvrs: 0,
      email: email ? email as string : '',
      saveState: null,
      saveMessage: null,
      errorMessage: null,
      openAlertModal: false,
      passwordCodeSent: email as string ? true : false,
      queryCode: code ? code as string : null,
      queryCodeDetected: code ? true : false,
    };

  }

  public render(): JSX.Element {
    const { email, openAlertModal, errorMessage, passwordCodeSent, forceShowAllErrors, flashErrorPvrs, saveState, queryCode, queryCodeDetected } = this.state;
    const { registerError } = this.errorTracker;

    let content;

    if (passwordCodeSent === false) {
      content = (
        <>
          <h3>
            {t('Provide your email to request a password reset. A temporary validation code will be sent to your email.')}
          </h3>
          <form>
            <TextInput
              key="email"
              id="email"
              type="email"
              data-test="email"
              placeholder={t('Email')}
              jsonPath="email"
              value={email}
              wrapperClass={styles.TextInput}
              focusOnMount={true}
              onChange={this.handleEmailChange}
              onEnterKey={this.handleResetPassword}
              registerError={registerError}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              autoCapitalize="off"
              autoComplete="email"
              validation={(value: string) => {
                const messages = [];
                if (!value.length) { messages.push(t('Email is required.')); }
                else if (!isValidEmail(value)) { messages.push(t('Invalid email format')); }
                return messages.length ? { messages } : null;
              }}
            />
            {(saveState === SaveStates.COMPLETE || saveState === SaveStates.PENDING) ?
              <Spinner
                key="spinner"
                wrapperClass={styles.Spinner}
                name={SpinnerNames.BallScaleRipple}
                color="white"
              /> : <button
                id="reset-password-button"
                data-test="reset-password-button"
                className={styles.Btn}
                onClick={this.handleResetPassword}
              >
                {t('Reset Password')}
              </button>
            }
          </form>
        </>
      );
    } else {
      content = <AccountConfirmPassword email={email} setErrorMsg={this.setErrorMsg} handleReturn={this.handleReturn} queryCode={queryCode} queryCodeDetected={queryCodeDetected} />;
    }
    
    return (
      <div className={`${styles.Body}`}>
        {content}
        <div key="return">
          <button
            id="return-login-button"
            data-test="return-login-button"
            className={styles.Btn}
            onClick={this.handleReturn}
          >
            {t('Return to Login')}
          </button>
        </div>
        {(openAlertModal) ?
          <AlertModal
            close={() => this.handleAlertModal(false)}
            alertTitle={t('Reset Password')}
            message={t('The new password validation code will be sent to the email, if email is a valid user email.')}
            okText={t('Confirm')}
            cb={this.confirmResetPassword}
            cancelText={t('Cancel')}
          />
          : null
        }
        {(errorMessage) ?
          <AlertModal
            close={() => this.setErrorMsg(null)}
            alertTitle={t('Reset Password')}
            message={errorMessage}
            okText={t('OK')}
          />
          : null
        }
      </div>
    );
  }

  public handleEmailChange = (value: string, jsonPath: string) => {
    this.setState({
      email: value,
    });
  };

  public handleResetPassword = (e: React.MouseEvent) => {
    e.preventDefault();
    
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    this.handleAlertModal(true);
  };

  public confirmResetPassword = () => {
    const { resetPassword } = this.props.loggedInUserStore;
    const { email } = this.state;

    this.setState({
      saveState: SaveStates.PENDING,
    });

    resetPassword(email)
      .catch(err => {
        this.setErrorMsg(err);
        return Promise.reject(err);
      })
      .then(() => {
        // redirect them to next from
        this.setState({
          passwordCodeSent: true,
          saveState: null,
        });
      });
  };

  public handleReturn = () => {
    // go to the login page
    const { history } = this.props;
    history.replace('/login');
  };

  public handleAlertModal = (value: boolean) => {
    this.setState({
      openAlertModal: value,
    });
  };

  public setErrorMsg = (value: string) => {
    this.setState({
      errorMessage: value,
    });
  };

}
