import * as React from 'react';

import { SelectPvr } from '@Lineup/index';
import { ITargetSelection } from '../../global/interfaces';

interface IProps {
  targets?: ITargetSelection[];
  onClose: () => void;
  onSelected: (item: any) => void;
  anchor: HTMLElement;
}

export default class DevTargetChoicePvr extends React.Component<IProps, any> {

  public render(): JSX.Element {

    const { targets, anchor, onClose } = this.props;

    const pvrProps = {
      direction: 'below',
      width: 270,
      anchor: anchor,
      hAdjust: -8,
      nibColor: 'white',
    };

    return (
      <SelectPvr
        options={targets}
        onChange={this.handleSelection}
        pvrProps={pvrProps}
        styleMixin={{overflow: 'auto'}}
        close={onClose}
      />
    );
  }

  public handleSelection = (item) => {
    this.props.onSelected(item);
  };
}
