import * as React from 'react';
import { IBaseInputProps } from './interfaces';
import Pvr from './Pvr';
import Overlay from './Overlay';
import MultiSelectOptionItem from './MultiSelectOptionItem';
import Keyboard from './Keyboard';
import ScrollContainer from './ScrollContainer';
import { inject, observer } from 'mobx-react';

const styles = require('./styles/multi_select.styl');
const PAGE_SIZE = 50;
export interface IMultiSelectOptionListWgScrollProps extends IBaseInputProps {
  options: any[];
  value: any[];
  onRemove: (option: any) => any;
  onAdd: (option: any) => any;
  close: () => any;
  closeOnSelect: boolean;
  valueField?: string;
  labelField?: string;
  returnFullObjects?: boolean;
  pvrProps?: any;
  anchor: HTMLDivElement;
  workgroupStore?: any;
}

@inject('workgroupStore') @observer
export default class MultiSelectOptionList extends React.Component<IMultiSelectOptionListWgScrollProps, any> {
  public static defaultProps = {
    returnFullObjects: false,
  };

  public state = {
    focused: 0,
  };

  private keyNav: boolean;
  private sc: ScrollContainer;

  public componentDidMount(): void {
    this.keyNav = false;
    document.addEventListener('keydown', this.handleKeydown);
    this.refreshList();
  }

  public componentWillUnmount(): void {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  public render(): JSX.Element {
    const { focused } = this.state;
    const { anchor, pvrProps, options, close, valueField, labelField, onAdd, onRemove, jsonPath} = this.props;
    const { filters } = this.props.workgroupStore;
    const {clientWidth} = anchor;
    const optionList = options.map((optionItem, i) => {
      const itemKey = optionItem[valueField] || optionItem;
      const isFocused = i === focused;
      return (
        <MultiSelectOptionItem
          key={itemKey}
          option={optionItem}
          isSelected={this.optionIsSelected(optionItem)}
          isFocused={isFocused}
          onAdd={onAdd}
          onRemove={onRemove}
          labelField={labelField}
          focusOption={this.focusOption}
          index={i}
        />
      );
    });

    const cWidth = clientWidth - (clientWidth * .1);
    const width = (cWidth < 470) ? cWidth : 470;

    // This closingDiv gives Cypress an element to click on and close that's inside the Overlay but that (normally) cannot be occluded by its children
    // Useful because cy.get(whatever).click() defaults to clicking on the center of the overlay, and that is rarely what we want if we
    // want to emulate the 'click outside of' event (there's usually some element that will catch the click)

    const closingDiv = (
      <div
        onClick={close}
        style={{
          position: 'absolute',
          top: '0px',
          left: '0px',
          height: '1px',
          width: '1px',
          background: 'transparent',
          border: 'none'}
        }
        data-test={jsonPath} />
    );

    return (
      <Overlay backdrop={false} close={close} >
        {closingDiv}
        <Pvr
          {...pvrProps}
          direction="below"
          width={width}
          animateIn={false}
          close={close}
          height="auto"
          autoHeightClass={styles.MultiSelectOptionList}
          nibColor="white"
          anchor={anchor}
          styleMixin={{
            transition: 'top 300ms ease-out, left 300ms ease-out',
          }}
        >
          <ScrollContainer
            className={styles.MultiSelectOptionList}
            onMouseMove={this.handleMouseMove}
            onKeyDown={this.handleKeydown}
            loading={filters.loadingMore}
            handleLoadMore={this.handleLoadMore}
            fullyLoaded={filters.PageNumber === -1}
            ref={(el) => this.sc = el}
            list
          >
            {optionList}
          </ScrollContainer>
        </Pvr>
      </Overlay>
    );
  }

  public handleKeydown = (e): void => {
    const {close, options} = this.props;
    const {keyCode} = e;
    if ([Keyboard.TAB, Keyboard.SPACE].includes(keyCode)) { (e.preventDefault)(); }
    if ([Keyboard.ESCAPE, Keyboard.SPACE].includes(keyCode)) { return close(); }

    if ([Keyboard.UP_ARROW, Keyboard.DOWN_ARROW].includes(keyCode)) {
      (e.preventDefault)();
      let {focused} = this.state;

      if (keyCode === Keyboard.DOWN_ARROW) { focused++; }
      if (keyCode === Keyboard.UP_ARROW) { focused--; }

      const lastIndex = options.length - 1;

      focused = focused < 0 ? 0 : focused;
      focused = focused > lastIndex ? lastIndex : focused;

      this.keyNav = true;

      this.setState({focused}, this.adjustScroll);
    }

    if (keyCode === Keyboard.ENTER) { this.selectFocused(); }
  };

  private selectFocused() {
    const {focused} = this.state;
    const {options, onAdd, onRemove} = this.props;
    const option = options[focused];

    if (this.optionIsSelected(option)) { return onRemove(option); } else { return onAdd(option); }
  }

  public focusOption = (focused): void => {
    // Only focus on hover when the user is moving the mouse
    if (this.keyNav === false) { return this.setState({focused}); }
  };

  private optionIsSelected(option) {
    const { value, valueField, returnFullObjects } = this.props;
    const val = option[valueField];
    const flatValue = (returnFullObjects) ? value.map(v => v[valueField]) : value;

    for (const entry of flatValue) {
      if (val === entry) { return true; }
    }
    return false;
  }

  public handleMouseMove = (): void => {
    this.keyNav = false;
  };

  public handleLoadMore = (): any => {
    const { getWorkgroups, workgroups, totalCount, updateloadingMore, setFilters } = this.props.workgroupStore;
    let { filters } = this.props.workgroupStore;
    const pageNumber  = filters.PageNumber;
    const nextPage = pageNumber + 1;

    if (pageNumber === -1) {
      updateloadingMore(false);
      return;
    }

    updateloadingMore(true);

    setFilters({PageNumber: nextPage});
    // redeclare for new value
    filters = this.props.workgroupStore.filters;

    getWorkgroups(filters)
      .then((resultWorkgroups) => {
        if (resultWorkgroups.length < PAGE_SIZE || workgroups.length + resultWorkgroups.length === totalCount){
          updateloadingMore(false);
          setFilters({PageNumber: -1});
          return;
        }

        updateloadingMore(false);
        setFilters({PageNumber: nextPage});

        return workgroups;
      });

  };

  private adjustScroll = (): void => {
    const {scrollTop, clientHeight, children} = this.sc.listEl;

    const {focused} = this.state;
    const focusedChild = children[focused] as HTMLLIElement;

    const liHeight = focusedChild.clientHeight;
    const focusedTop = focusedChild.offsetTop;
    const focusedBottom = focusedTop + liHeight;

    // At least a portion of the focused li is overflowing the bottom
    if (focusedBottom > (clientHeight + scrollTop)) {
      this.sc.listEl.scrollTop = focusedBottom - clientHeight;
    // At least a portion of the focused li is overflowing the top
    } else if (focusedTop < scrollTop) {
      this.sc.listEl.scrollTop = focusedTop;
    }
  };

  public refreshList = (): Promise<any> => {
    const { getWorkgroups, updateLoading, setFilters, updateloadingMore, clearFilters } = this.props.workgroupStore;

    updateLoading(true);
    updateloadingMore(false);

    clearFilters();

    return getWorkgroups()
      .then((workgroups) => {
        const pageNumber = (workgroups?.length < PAGE_SIZE) ? -1 : 1;
        updateLoading(false);
        setFilters({PageNumber: pageNumber});
        return workgroups;
      });

  };
}
