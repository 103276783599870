import * as React from 'react';
import { LoggedInUser, Application, System, MobileBridge } from '../stores';
import { RouteComponentProps } from 'react-router';
import { IContext } from '../AppContext';
import { AssayFunctions, AcceptStatuses, SensorStatusKeys, PatientGenders } from './enums';
import { Moment } from 'moment';

export interface ILocaleOption {
  value: string;
  label: string;
}

export interface IStores {
  loggedInUserStore?: LoggedInUser;
  applicationStore?: Application;
  systemStore?: System;
  mobileBridgeStore?: MobileBridge;
}

/**
 * Extend this class for your components that need to be testable with Jest.
 * This will provide additional static properties `wrappedComponent` and `wrappedInstance` to allow you to test the component in isolation
 *
 * Example usage in Jest: `const wrapper = mount(<MyComponent.wrappedComponent {...props} />);`
 */
export class InjectedComponent<P, S> extends React.Component<P, S> {
  public static wrappedComponent: any;
  public static wrappedInstance: any;
}

export interface IComponent extends IStores, IContext, Partial<RouteComponentProps<any>> {
  setLoading?: (loading: boolean) => Promise<any>;
  loading?: boolean;
}

export interface ITargetSelection {
  id: string;
  label: string;
  value: string;
  isSelected: boolean;
  children: any[];
}

export interface IRoleSelection {
  label: string;
  value: number;
}

export interface IModuleManifest {
  id: string;
  name: string;
  version: string;
  strings?: any;
  children?: IModuleManifest[];
  availableVersions?: Array<[string, IModuleManifest]>;
}

/**
 * Generic User Object
 * Quantum and Care Solutions users should both be mapped into this interface
 * Additional properties unique to each can be added, just make them optional
 */
export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  middleName?: string;
  initials?: string;
  username?: string;
  workGroupApplicationUsers?: IWorkGroupApplicationUser[];
  accessLevelInstAdmin?: boolean;
  accessLevelWgAdmin?: boolean;
  accessLevelMeterAdmin?: boolean;
  accessLevelMeterOperator?: boolean;
  languageId?: number;
  email?: string;
  salutation?: string;
  status?: boolean;
  code?: string;
  newPassword?: string;
  currentPassword?: string;
  connectManagerRoleWgs?: any;
  instrumentRoleWgs?: any;
  EnforceCompliance?: boolean;
  ComplianceExpirationDate?: Moment;
  ServerTimeUTC?: Moment;
  Role?: string;
  NoEmailAccess?: boolean;
  Password?: string;
  ConfirmPassword?: string;
  LastPasswordChangeDateUtc?: string;
  PasswordExpirationDateUtc?: string | null;
  LastLoginUtc?: string;
}

export interface IResultNotification {
  Id: number;
  PatientId: string;
  ObservationId: string;
  DateCreated?: number;
}

export interface IQProfile {
  UniqueId?: string;
  Id?: number;
  UserId?: string;
  GenderId?: number;
  FirstName?: string;
  FamilyName?: string;
  DateOfBirth?: string;
  DateCreated?: string;
  DateUpdated?: string;
  Primary?: boolean;
  ConfirmedLegalGuardian?: boolean;
  InitialAgreementsComplete?: boolean;
  ZipCode?: string;
  CoachmarksViewed_Home?: boolean;
  CoachmarksViewed_Self?: boolean;
  VideosViewed_Self?: boolean;
}

export interface IQProfileAgreement {
  Id?: number;
  ProfileId?: number;
  AgreementId?: number;
  AcceptedDate?: string;
  AcceptedByLegalGuardian?: boolean;
}

export interface IQProfileAgreementsResponse {
  ProfileAgreements: IQProfileAgreement[];
}

export interface IQAgreement {
  Id?: number;
  AgreementId?: number;
  AgreementTypeId?: number;
  RegionId?: number;
  Title?: string;
  Description?: string;
  Url?: string;
  Version?: string;
  Active?: boolean;
  DateCreated?: string;
  DateUpdated?: string;
}

export interface IUserWorkgroup {
  User_Id: string;
  WorkGroup_Id: number;
  WorkGroupAdministrator: boolean;
  InstrumentOperator: boolean;
  AccessLevelInstAdmin: boolean;
  AccessLevelWgAdmin: boolean;
  AccessLevelMeterAdmin: boolean;
  AccessLevelMeterOperator: boolean;
}

export interface IWorkGroupApplicationUser {
  InstrumentOperator: boolean;
  User_Id: string;
  WorkGroupAdministrator: boolean;
  WorkGroup_Id: number;
}

export interface IWorkgroupTestType {
  TestType_Id: number;
  WorkGroup_Id: number;
}

export interface IQualityControl {
  InstrumentQcIntervalInDays: number;
  NValue: number;
  OrganizationId: number;
  PolicyBasis: number;
  PolicyManagement: number;
  RequireAfterN: number;
  RequireForEachNewLot: boolean;
}

export interface IWorkgroup {
  Description: string;
  Id: number;
  Name: string;
  OrganizationId: number;
  WorkGroupApplicationUsers: IWorkGroupApplicationUser[];
  WorkGroupTestTypes: IWorkgroupTestType[];
  QualityControl?: IQualityControl;
  TimeZoneId?: number;
  OverrideOrganizationTimeZone: boolean;
}

export interface IAccess {
  accessLevelInstAdmin: boolean;
  accessLevelWgAdmin: boolean;
  accessLevelMeterAdmin: boolean;
  accessLevelMeterOperator: boolean;
}

/**
 * Quantum User Object
 */
export interface IQUser {
  Id: string;
  UserName: string;
  Salutation: string;
  FirstName: string;
  FamilyName: string;
  Initials: string;
  Status: boolean;
  AccessLevelInstAdmin: boolean;
  AccessLevelWgAdmin: boolean;
  AccessLevelMeterAdmin: boolean;
  AccessLevelMeterOperator: boolean;
  Email: string;
  Language_Id: number;
  WorkGroupApplicationUsers: IWorkGroupApplicationUser[];
  Code: string;
  NewPassword: string;
  CurrentPassword: string;
  EnforceCompliance?: boolean;
  ComplianceExpirationDate?: Moment;
  ServerTimeUTC?: string;
  Role?: string;
  NoEmailAccess?: boolean;
  Password?: string;
  ConfirmPassword?: string;
  LastPasswordChangeDateUtc?: string;
  PasswordExpirationDateUtc?: string | null;
  LastLoginUtc?: string;
}

/**
 * Quantum Generic Response
 */
export interface IQuantumObjRes<T = any> {
  Object: T;
  Errored: boolean;
  ErrorMessage: string;
  AlternativeHofUrl: string;
  Message?: string;
  ValidationErrors?: string[];
  ServerTimeUtc?: string;
  FilterCount?: number;
  TotalCount?: number;
}

/**
 * Quantum Access Token
 */
export interface IQToken {
  access_token: string;
  expires_in: number;
  token_type: string;
  password_change_required: boolean;
  force_password_change?: boolean;
}

/**
 * Quantum Organization
 */
export interface IQOrganization {
  QualityControl?: any;
  Id: number;
  OrganizationName: string;
  InstanceName: string;
  Region_Id: number;
  Language_Id: number;
  ContactPerson: string;
  ContactEmail: string;
  ContactPhone: string;
  ReplicationStatus: number;
  ReplicationStatusText: string;
  LastReplicationTime: string;
  LogoutSetting: number;
  LogoutInactiveTime: number;
  NumberOfPatientId: number;
  PatientId1Title: string;
  PatientId2Title: string;
  PredefinedComments: string[];
  TimeZoneId: number;
  DataTypes?: IDataTypes;
  DebugFlag?: boolean;
  AllowAdminManagedPasswords?: boolean;
  AmiraLegalAge?: string;
  AmiraTesting: boolean;
  AmiraApplicationOrganization: boolean;
  EnableAdditionalInformation?: boolean;
}

/**
 * Quantum Organization DataTypes
 * 0: off/disabled, 1: Cloud, 2: Middleware
 */

export interface IDataTypes {
  TestResults?: number;
  PatientLookup?: number;
  Operators?: number;
  InstrumentSettings?: number;
  Status?: number;
}

/**
 * Reset Password
 */
export interface IPasswordRules {
  DefaultAccountLockoutTimeSpan: number;
  MaxFailedAccessAttemptsBeforeLockout: number;
  PasswordDoNotAllowReuseOldPasswordCount: number;
  PasswordExpirationEnabled: boolean;
  PasswordExpirationTimeSpan: number;
  PasswordRequireDigit: boolean;
  PasswordRequireLowerCase: boolean;
  PasswordRequireNonLetterOrDigit: boolean;
  PasswordRequireUpperCase: boolean;
  PasswordRequiredMinLength: number;
  PasswordRequiredMaxLength: number;
  PasswordResetCodeValidationTimeoutInHours: number;
  UserLockoutEnabledByDefault: boolean;
  UserSessionTimeSpan: number;
  AmiraUserSessionTimeSpan: number;
}

export interface IValidatePassword {
  Object: {
    IsValidPassword: boolean;
    RuleResults: IRuleResult[];
  };
}

export interface IRuleResult {
  ComparisonValue: string;
  Passed: boolean;
  RuleName: string;
}
/**
 * Quantum Region
 */
export interface IQRegion {
  Id: number;
  Name: string;
  Locale?: string;
  RegionCode?: number;
  Locale2?: string;
  CountryCode?: string;
}

export interface IQRegionRes {
  Regions: IQRegion[];
}

/**
 * Quantum Language
 */
export interface IQLanguage {
  Id: number;
  Name: string;
  Locale?: string;
}

export interface IQGenderRes {
  Genders: IQGender[];
}

export interface IQGender {
  Id: number;
  Name: string;
}

export interface IQAccessDto {
  AccessCode: string;
  ExternalGuid: string;
  QuantumSyncUrl: string;
  UiApiUrl: string;
  EnvShortCode: string;
  Version: string;
  OrganizationStatus: boolean;
  Region_Id?: number;
  Region_Name?: string;
  AmiraLegalAge?: string;
  AmiraModules?: number;
  OnPremise?: boolean;
  HofUrl?: string;
}

export interface IQAccessRegionDto {
  AccessCode: string;
  ExternalGuid: string;
  QuantumSyncUrl: string;
  UiApiUrl: string;
  EnvShortCode: string;
  Version: string;
  OrganizationStatus: boolean;
  Region_Name: string;
  Region_Id: number;
}

export interface IQLanguageRes {
  Languages: IQLanguage[];
}

/**
 * Quantum TimeZone
 */
export interface IQTimeZone {
  Id: number;
  Name: string;
}

export interface IQTimeZoneRes {
  TimeZones: IQTimeZone[];
}

export interface IServerBuildInfo {
  WebServiceVersion: string;
  AssemblyVersion: {
    DomainVersion: string;
    QuantumAdapterVersion: string;
    QuantumVersion: string;
    DatabaseVersion: string;
  };
}

/**
 * Module Interfaces
 */
export interface IModule {
  id: string;
  displayName: string;
  version: string;
  strings: any;
  children: string[];
}

/* ------------------------
 * Mobile App Bridge Interfaces
 ------------------------ */

export interface IBridgeAppData {
  /**
   * @param UserIdAvailable Whether or not the user Id is available.
   */
  UserIdAvailable?: boolean;
  /**
   * @param BluetoothEnabled Whether or not bluetooth is enabled on the device.
   */
  BluetoothEnabled?: boolean;
  /**
   * @param BLEAllowed Whether or not bluetooth has permissions on the device
   */
  BLEAllowed?: boolean;
  /**
   * @param ServerOk Whether or not the last attempt to access the server succeeded.
   */
  ServerOk?: boolean;
  /**
   * @param SerialNumber The devive serial number. A 'null' value means no app link serial number available.
   */
  SerialNumber?: string;
  /**
   * @param AppVersion The mobile application version.
   */
  AppVersion?: string;
  /**
   * @param UIVersion The UI version.
   */
  UIVersion?: string;
  /**
   * @param Platform A the mobile platform eg. 'iPhone 6'.
   */
  Platform?: string;
  /**
   * @param OSVersion The version of the mobile OS. eg. 'iOS 8.1.2'.
   */
  OSVersion?: string;
  /**
   * @param Carrier The mobile carrier. eg. 'Verizon'.
   */
  Carrier?: string;
  /**
   * @param IsAdmin Whether or not the app is running in admin mode.
   */
  IsAdmin?: boolean;
  /**
   * @param CallbackAvailable Wether or not a callback URL is available.
   */
  CallbackAvailable?: boolean;
  /**
   * @param UiString A string whose contents are controlled by the UI and saved by the App (written by UI calling set_ui_string(“string”)).
   */
  UiString?: string;
  /**
   * @param SAU App username.
   */
  SAU?: string;
  /**
   * @param SAP App token password.
   */
  SAP?: string;
  /**
   * @param CameraPermissions a boolean to let UI know the status the application has for Camera Permissions
   */
  CameraPermissions?: boolean;
}

export interface IConnectData {
  accessCode?: string;
  regionName?: string;
  AccessCode?: string;
  useAsHub: boolean;
  locale: string;
  clearedMiddlewareOpWarning: boolean;
  QuantumSyncUrl?: string;
  ExternalGuid?: string;
  amiraEnv?: any;
  registrationState?: any;
  username?: string;
  token?: string;
  mobileUpdateCallback?: string;
  cameraPermissionsAccepted: boolean;
}

export interface IBridgeDeviceData {
  /**
   * @param SerialNumber The serial number of the device (setting the SerialNumber to null designates that no devices exist).
   */
  SerialNumber: string;
  /**
   * @param DeviceName The nickname for the device in the UI. String up to 16 characters or null if device naming is not supported.
   */
  DeviceName?: string;
  /**
   * @param FirmwareVersion The firmware version number. eg. '1.2.3', null for unknown.
   */
  FirmwareVersion?: string;
  /**
   * @param FirmwareUpdateAvailable The version string IF a firmware version is available for this device.
   */
  FirmwareUpdateAvailable?: string;
  /**
   * BluetoothEnabled Whether or not bluetooth is enabled.
   */
  BluetoothEnabled?: boolean;
  /**
   * @param CloudConnect Whether or not the device is connected to the cloud.
   */
  CloudConnect?: boolean;
  /**
   * @param CloudSync An integer with a value of elapsed time since the last cloud sync in.
   */
  CloudSync?: number;
  /**
   * @param DeviceConnect Wether or not the device is connected.
   */
  DeviceConnect?: boolean;
  /**
   * @param DeviceSync An integer with a value of elapsed time since the last device sync.
   */
  DeviceSync?: number;
  /**
   * @param SensorStatus The current device status.
   */
  SensorStatus?: string;
  /**
   * @param SensorStatusEnum
   */
  SensorStatusEnum?: SensorStatusKeys;
  /**
   * @param SensorStatusVal
   */
  SensorStatusVal?: string;
  /**
   * @param HardwareVersion
   */
  HardwareVersion?: string;
  /**
   * @param OperatingMode
   */
  OperatingMode?: number;
  /**
   * @param MaxTestCount
   */
  MaxTestCount?: number;
  /**
   * @param LotCode
   */
  LotCode?: number;
  /**
   * @param LotCodeExpirationDate
   */
  LotCodeExpirationDate?: number;
  /**
   * @param BatteryStatus
   */
  BatteryStatus?: number;
  /**
   * @param SelfTestStatus
   */
  SelfTestStatus?: number;
  /**
   * @param SelfTestStatusError
   */
  SelfTestStatusError?: number;
  /**
   * @param CurrentTestSequenceNumber
   */
  CurrentTestSequenceNumber?: number;
  /**
   * @param ServerMaxTestSequence
   */
  ServerMaxTestSequence?: number;
  /**
   * @param ServerTotalNumOfReadings
   */
  ServerTotalNumOfReadings?: number;
}

export interface IBridgeServerInfo {
  /**
   * @param GUID The GUID currently being used to retrieve the device list.
   */
  GUID: string;
  /**
   * @param ServerURL The URL of the server.
   */
  ServerURL: string;
  /**
   * @param ContentURL The URL to the UIVersion.txt and UIContents.zip
   */
  ContentURL: string;
}

export interface IBridgeUIVersionUpdate {
  /**
   * @param GUID The current device GUID.
   */
  GUID?: string;
  /**
   * @param UIVersion The version of the new UI available
   */
  UIVersion?: string;
}

export interface IOrgGroupSimple {
  id: string;
  name: string;
  orgDataShared: boolean;
  orgCount: number;
}

export interface IUrlAction {
  uiaction: string;
}

export interface ISetPasswordData {
  code: string;
  orgCode: string;
  email: string;
  action: string;
  expiryDate: string;
}

export interface IOrg {
  groups?: IOrgGroupSimple[];
  region?: IRegion;
  shareWithGroup?: boolean;
  id: string;
  name: string;
  abbr: string;
}

interface IRegion {
  id: string;
  name: string;
  code: string;
  gpSearchType: string;
}

export enum UserFeatures {
  DATA_VIEW = 'DataView',
  SUPER_ADMIN = 'SuperAdmin'
}

export enum UserActions {
  ALL = 'All',
  CREATE = 'Create',
  DELETE = 'Delete',
  EXPORT = 'Export',
  LOGIN = 'Login',
  UPDATE = 'Update',
  VIEW = 'View',
  VIEW_GROUP = 'ViewGroup'
}

export type UserPermissions = Map<UserFeatures, Set<UserActions>>;

export interface ILoggedInDetails {
  org?: IOrg;
  group?: IOrgGroupSimple;
}

export interface IPerm {
  feature: UserFeatures;
  action: UserActions;
  id: string;
}

export interface IPDEUser {
  nm_first: string;
  nm_last: string;
  nm_middle: string;
  person_id: string;
  principal_id: string;
  authToken: string;
  force_password_reset: boolean;
  permissions: IPerm[];
  allOrgsAccess: boolean;
}

export interface IQualityControls {
  QualityControls: IQualityControlPolicy[];
}

export interface IQualityControlPolicy {
  Id?: number;
  OrganizationId?: number;
  WorkGroupId?: number;
  PolicyBasis: number;
  RequireForEachNewLot: boolean;
  RequireAfterN: number;
  NValue: number;
  PolicyManagement: number;
  InstrumentQcIntervalInDays: number;
  InstrumentVersion: string;
  RecordType: number;
  PolicyInterval?: boolean;
}

interface IResultSummary {
  DataBlockVersion: number;
  InstrumentSerial?: number;
  TestSequenceNumber?: number;
  TestDeviceLotNumber?: number;
  TestDeviceSequenceNumber?: number;
  TestPurpose: number;
  ControlLiquidLotNumber?: number;
  ControlLevelNumber?: number;
  OperatorGuid?: string;
  RequesterGuid?: string;
  PatientSearchId?: number;
  TestTypeCode?: number;
  TestDeviceExpiry?: string;
  TimeOfTest?: string;
  SampleType?: number;
  InstrumentCalibrationResult?: number;
  InstrumentCalibrationGoodBad?: number;
  NumMeasurements?: number;
  QcOverallPassed?: number;
  QcOverrideText?: string;
  ErrorCode: number;
  UsersAcceptReject: number;
  NumStandardComments?: number;
  ControlLevelName?: string;
  OperatorName?: string;
  ReferenceTestOrderId?: string;
  RequesterName?: string;
  TestTypeName: string;
  StandardComments?: string[];
  FreeFormCommentText?: string;
  SummaryUniqueIdentifier?: number;
}

export interface IAnalyteResult {
  AnalyteId: number;
  ResultText?: string;
  ResultStandardUnits?: string;
  TestSequenceNumber?: number;
  TotalNumMeasurements?: number;
  MeasurementNumber?: number;
  DataPayloadVersion: number;
  InstrumentSerial?: number;
  TestDeviceLotNumber?: number;
  TestDeviceSequenceNumber?: number;
  ReadingNumber?: number;
  AssayNumber?: number;
  AssayFunction?: number;
  DisplayPosition?: number;
  Block?: number;
  ErrorCode: number;
  RelevantOnBoardControlsValid?: number;
  QualitativeResultBinary?: number;
  QuantitativeResultStandardUnits?: number;
  ResultLimitedByCalRange?: number;
  IndicatedQuantitativeResultIndicatedUnits?: number;
  ConversionFactor?: number;
  AnalyticalRangeUpperLimit?: number;
  AnalyticRangeLowerLimit?: number;
  NormalRangeUpperLimit?: number;
  NormalRangeLowerLimit?: number;
  RangeResult?: number;
  RawSignalValue?: number;
  IntegrationTimeUsed?: number;
  PassFailIfQc?: number;
  AssayName: string;
  QualitativeResultText?: string;
  StandardUnitsText?: string;
  IndicatedUnitsText?: string;
  IndicatedValueText?: string;
  QuantitativeStandardValueText: string;
  MeasurementUniqueIdentifier?: number;
}

export interface ITestResult {
  Id: number;
  TestOrderId?: number;
  TimeOfTest?: string;
  TestSequenceNumber?: number;
  TotalNumMeasurements?: number;
  PatientGuid?: string;
  OperatorGuid?: string;
  RequesterGuid?: string;
  Summary: IResultSummary;
  AnalyteResults: IAnalyteResult[];
}

export interface IConnectReport {
  Id: number;
  ControlLevelName: string;
  ControlLevelNumber: number;
  ControlLiquidLotNumber: number;
  DataPayloadVersion: number;
  DateUpdated: string;
  FreeFormComment: string;
  InstrumentSerial: string;
  InstrumentWirelessModuleId: string;
  OperatorName: string;
  OrgAccessCode: string;
  POCReadings_Measurement_ID: number;
  QcOverallPassedFailed: number;
  QcPolicyOverrideCommentText: string;
  SampleType: number;
  SummaryErrorCode: number;
  TestDeviceExpiryUtc: string;
  TestDeviceLotNumber: number;
  TestDeviceSequenceNumber: number;
  TestPurpose: number;
  TestTypeName: string;
  TimeOfTestLocal: string;
  TimeOfTestUTC: string;
  UserAcceptReject: AcceptStatuses;
  WorkGroupId: number;
  Measurements: IMeasurement[];
}

export interface IMeasurement {
  Id: number;
  POCReadings_Summary_ID: number;
  AssayName: string;
  AssayFunction: AssayFunctions;
  QualitativeResultText: string;
  QuantitativeStandardValueText: string;
  IndicatedValueText: string;
  RawSignalValueFloat: number;
  StandardUnitsText: string;
  IndicatedUnitsText: string;
  RelevantOnBoardControls: string;
  MeasurementErrorCode: number;
  MeasurementPassFailIfQC: number;
  NormalRangeLowerLimit: number;
  NormalRangeUpperLimit: number;
  DisplayPosition: number;
  DateCreated: string;
  DataPayloadVersion?: number;
  NormalRangeLowerLimitOrQcLowerLimitRangeText?: string;
  NormalRangeUpperLimitOrQcUpperLimitRangeText?: string;
}

export interface IConnectResult {
  Id: number;
  ControlLevelName: string;
  ControlLevelNumber: number;
  ControlLiquidLotNumber: number;
  FullControlLiquidLotNumber?: number;
  DataPayloadVersion: number;
  DateUpdated: string;
  FreeFormComment: string;
  InstrumentSerial: string;
  InstrumentWirelessModuleId: string;
  OperatorName: string;
  OrgAccessCode: string;
  POCReadings_Measurement_ID: number;
  QcOverallPassedFailed: number;
  QcPolicyOverrideCommentText: string;
  SampleType: number;
  SummaryErrorCode: number;
  TestDeviceExpiryUtc: string;
  TestDeviceLotNumber: number;
  TestDeviceSequenceNumber: number;
  TestPurpose: number;
  TestTypeName: string;
  TimeOfTestLocal: string;
  TimeOfTestUTC: string;
  UserAcceptReject: AcceptStatuses;
  WorkGroupId: number;
  WorkGroupName?: string;
  Measurements: IMeasurement[];
  POCReadings_Summary_ID?: number;
  AssayName?: string;
  AssayFunction?: AssayFunctions;
  QualitativeResultText?: string;
  QuantitativeStandardValueText?: string;
  IndicatedValueText?: string;
  RawSignalValueFloat?: number;
  StandardUnitsText?: string;
  IndicatedUnitsText?: string;
  RelevantOnBoardControls?: string;
  MeasurementErrorCode?: number;
  MeasurementPassFailIfQC?: number;
  NormalRangeLowerLimit?: number;
  NormalRangeUpperLimit?: number;
  DisplayPosition?: number;
  DateCreated?: string;
  PatientRefNo1?: string;
  UsersAcceptReject?: number;
  NormalRangeLowerLimitOrQcLowerLimitRangeText?: string;
  NormalRangeUpperLimitOrQcUpperLimitRangeText?: string;
  Patient?: IPatientDetails;
}

export interface IPatientDetails {
  PatientRefNo1?: string;
  PatientRefNo2?: string;
  FirstName?: string;
  FamilyName?: string;
  Dob?: string;
  Gender?: PatientGenders;
}

export interface IPDEOrganization {
  abbr?: string;
  auto_enroll_flag?: boolean;
  ccd_share_analytics_flag?: boolean;
  ccd_share_ihe_flag?: boolean;
  ccd_share_portal_flag?: boolean;
  community_abbr?: string;
  community_id?: string;
  groups?: any[];
  hasPortalAccess?: boolean;
  id?: string;
  logoResourceName?: string;
  name?: string;
  notes?: string;
  oid?: string;
  org_type?: string;
  region?: any;
  sensitivity_disabled?: boolean;
  website?: string;
}

export interface IPDEOrganizationSettings {
  encrypted: boolean;
  settingName: string;
  settingValue: string;
}
