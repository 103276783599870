import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ModuleContainer from './ModuleContainer';
import { IComponent, InjectedComponent } from '../../global/interfaces';
import { AlertModal } from '@Lineup/index';

@inject('applicationStore', 'loggedInUserStore', 'mobileBridgeStore') @observer
export default class Home extends InjectedComponent<IComponent, any> {
  private userNavvedToDataview: boolean = window.location.pathname?.toLowerCase().includes('dataview');

  public render(): JSX.Element {
    const { modulesById } = this.props.applicationStore;
    const { user, passwordChangeRequired, forcePasswordChange, logout, accessDto, pdeOrg, username, password } = this.props.loggedInUserStore;

    if ((user == null && pdeOrg == null) || passwordChangeRequired || forcePasswordChange) {
      return <Redirect to="/login" />;
    }

    // Set the parent module. Most of the time, this will be the Home module.
    const key = !process.env.CS ? 'home' : 'engage';
    // If the user is going to Dataview, make sure to load the parent module (PDE)
    const parentModule = this.userNavvedToDataview ? modulesById.get('pde') : modulesById.get(key);

    // TODO: May want to add some conditions here for the ULC Home module, once we are ready to implement that
    
    if (!parentModule) {
      return (
        <AlertModal
          message={t('No application modules exist in this environment. Please report this issue to an administrator.')}
          close={() => {
            logout();
            this.props.history.replace('/login');
          }}
          alertTitle={t('Application Error')}
          cancelText={t('Logout')}
        />
      );
    } 

    return (
      <Switch>
        <Route path="/main" render={() => <Redirect to={parentModule.id}/>} />
        <Route key={parentModule.id} path={`/${parentModule.id}`} render={(props) =>
          <ModuleContainer 
            module={parentModule}
            rootPath={`/${parentModule.id}`}
            version={accessDto?.Version}
            organization={pdeOrg}
            username={username}
            password={password}
            {...props} 
          />
        } />
      </Switch>
    );
  }
}
