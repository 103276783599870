import * as React from 'react';
import { IItem } from './interfaces';

const styles = require('./styles/pvr.styl');

export interface IPvrInfoItemProps {
  item: IItem;
}

export default class PvrInfoItem extends React.Component<IPvrInfoItemProps, any> {
  public render(): JSX.Element {
    const { item: { label, info } } = this.props;
    
    return (
      <div className={styles.ContentRow} >
        <dt>{label}</dt>
        <dd title={info}>{info}</dd>
      </div>
    );
  }
}
