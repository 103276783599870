import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IComponent } from '../../global/interfaces';
import ModuleContainer from './ModuleContainer';

@inject('applicationStore') @observer
export default class InstrumentWrapper extends React.Component<IComponent, any> {

  public componentDidMount() {
    const { applicationStore: { getAvailableModules } } = this.props;

    getAvailableModules()
      .catch((e) => {
        return Promise.reject();
      });
  }

  public render(): JSX.Element {
    const { modulesById } = this.props.applicationStore;
    const mod = modulesById.get('instrument');

    return mod ? (
      <ModuleContainer
        module={mod}
        rootPath="/instrument"
        version={process.env.UI_VERSION.split('-')[0]}
        {...this.props}
      />
    ) : null;
  }
}
