export default class ViewportUnits {
  private vv;
  private styleEl: HTMLStyleElement = document.createElement('style');

  constructor() {
    this.vv = (window as any).visualViewport;
    if (typeof this.vv === 'object') {
      this.styleEl.id = 'viewportUnits';
      this.styleEl.type = 'text/css';
      document.head.prepend(this.styleEl);
      this.vv.addEventListener('resize', () => this.getViewportUnits());
      this.getViewportUnits();
    }
  }

  public getViewportUnits() {
    const { height, width } = this.vv;
    this.styleEl.innerHTML = `
    :root {
      --100vvw: ${width}px;
      --100vvh: ${height}px;
    }
  `;
  }
}
