import * as React from 'react';
import * as moment from 'moment';
import { isNumeric } from './validation';

export function formatAge(datestring: string, includeMonth?: boolean, datestringDeceased?: string): string {
  let days;
  let months;
  let years;
  const now  = moment().utc();
  const birthDate = moment(datestring).utc();

  let age = '';

  // Determine age in the following formats: days, months, years
  if (datestringDeceased != null) {
    const deceasedDate = moment(datestringDeceased).utc();

    days = deceasedDate.diff(birthDate, 'days');
    months = deceasedDate.diff(birthDate, 'months');
    years = deceasedDate.diff(birthDate, 'years');
  } else {
    days = now.diff(birthDate, 'days');
    months = now.diff(birthDate, 'months');
    years = now.diff(birthDate, 'years');
  }

  if (months < 1) {
    age = `${days} DO`;
  } else if (months < 48) {
    age = `${months} MO`;
  } else {
    age = `${years} YO`;

    // Include month in age
    if (includeMonth) {
      months = months - (years * 12);
      // don't include if 0 months
      if (months) { age = `${years} yrs ${months} mnth`; }
    }
  }

  return age;
}

export function formatAgeWithEls(datestring: string, datestringDeceased?: string): JSX.Element[] {
  let days;
  let months;
  let title;
  let years;
  const age: JSX.Element[] = [];
  const now  = moment().utc();
  const birthDate = moment(datestring).utc();

  if (datestringDeceased != null) {
    days = now.diff(birthDate, 'days');
    months = now.diff(birthDate, 'months');
    years = now.diff(birthDate, 'years');
  } else {
    days = now.diff(birthDate, 'days');
    months = now.diff(birthDate, 'months');
    years = now.diff(birthDate, 'years');
  }

  if (months < 1) {
    title = `${days} DO`;
    age.push(<span key="label" className="pat-value" title={title}>`${days}`</span>);
    age.push(<span key="value" className="pat-label" title={title}>DO</span>);
  } else if (months < 48) {
    title = `${months} MO`;
    age.push(<span key="label" className="pat-value" title={title}>`${months}`</span>);
    age.push(<span key="value" className="pat-label" title={title}>MO</span>);
  } else {
    title = `${years} YO`;
    age.push(<span key="label" className="pat-value" title={title}>`${years}`</span>);
    age.push(<span key="value" className="pat-label" title={title}>YO</span>);
  }

  return age;
}

export function formatNHS(nhs): string {
  // Strip non digit characters out of the nhs
  if (nhs == null) { nhs = ''; }
  nhs = nhs.replace(/[^0-9]/g, '');

  const nhs1 = nhs.substr(0, 3);
  const nhs2 = nhs.substr(3, 3);
  const nhs3 = nhs.substr(6, 4);

  let rv = `${nhs1}`;
  if (nhs2) { rv += ` ${nhs2}`; }
  if (nhs3) { rv += ` ${nhs3}`; }
  return rv;
}

// Returns 10 digit number in (xxx) xxx-xxxx format
// or 7 digit number in xxx-xxxx format
export function formatPhoneNumber(phone): string {
  if (phone != null) {
    // Make sure the number is a String
    phone = `${phone}`;
    let phoneClone = phone;
    phoneClone = phoneClone.replace(/[^0-9]/g, '');
    if (phoneClone.length === 10) {
      phoneClone = phoneClone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      return phoneClone;
    }
    if (phoneClone.length === 7) {
      phoneClone = phoneClone.replace(/(\d{3})(\d{4})/, '$1-$2');
      return phoneClone;
    } else {
      return phone;
    }
  } else {
    return '';
  }
}

export function formatSource(options): string {
  const {orgName, facilityName, systemName} = options;

  let sourceName = '';

  // Org
  if (orgName != null) {
    sourceName += orgName;
  }
  // Facility
  if ((facilityName != null) && ![orgName, ''].includes(facilityName)) {
    if (sourceName !== '') { sourceName += ' - '; }
    sourceName += facilityName;
  }

  // System
  if (((facilityName != null) || (orgName != null)) && (systemName != null) && (systemName !== '')) {
    if (sourceName !== '') { sourceName += ' - '; }
    sourceName += systemName;
  }

  return sourceName;
}

export function formatTimezoneForAPI(relevantDate): string {
  const minutes = Math.abs(moment(relevantDate).utcOffset());
  const negative = (minutes < 0) ? '-' : '+';

  let hours: number | string = Math.floor(minutes / 60);
  if (hours < 10) {
    hours = `0${hours}`;
  }

  let minutestring: number | string = minutes % 60;
  if (minutestring < 10) {
    minutestring = `0${minutestring}`;
  }

  return `${negative}${hours}:${minutestring}`;
}

export function escapeRegExp(value: string): string {
  return value.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
}

// Adding comment to jenkins/github integration.
// Converts raw bytes to KB, MB, etc
export function bytesToSize(bytes: number, precision: number = 2): string {
  if (bytes == null) { return ''; }
  const kilobyte = 1024;
  const megabyte = kilobyte * 1024;
  const gigabyte = megabyte * 1024;
  const terabyte = gigabyte * 1024;

  if ((bytes >= 0) && (bytes < kilobyte)) { return bytes + ' B';
  } else if ((bytes >= kilobyte) && (bytes < megabyte)) { return (bytes / kilobyte).toFixed(precision) + ' KB';
  } else if ((bytes >= megabyte) && (bytes < gigabyte)) { return (bytes / megabyte).toFixed(precision) + ' MB';
  } else if ((bytes >= gigabyte) && (bytes < terabyte)) { return (bytes / gigabyte).toFixed(precision) + ' GB';
  } else if (bytes >= terabyte) { return (bytes / terabyte).toFixed(precision) + ' TB';
  } else { return bytes + ' B'; }
}

// Reduces name to targetLength by replacing a middle portion of the name with an ellipses
export function formatFileName(name: string, targetLength: number): string {
  // Get the starting file name length
  const fileLength = name.length;
  // Do nothing to files with less than targetLength characters
  if (fileLength <= targetLength) { return name; }
  // Calculate the number of characters that need to be removed to get the filename down to targetLength
  // Add 3 to file length as the file length will technically get larger with the ellipses thus we need to increase the file name length
  // NOTE : it was adding 3 to the targetLength but doing that caused for overlapping characters in the file name
  const removeCount = (fileLength + 3) - targetLength;
  // Figure out how far from the end we should keeps characters
  // In most cases this will be 6 characters unless the diff between the target length and the removeCount is 6 or else
  // This will insure the beginning of the file has more than 1 or 2 characters before the ellipses (unless the target length is extremely small like 3)
  const charactersFromEnd = (targetLength - removeCount) <= 6 ? 4 : 6;
  // Calculate the place the remove should end
  const endRemove = fileLength - charactersFromEnd;
  // Calculate the postion to start the remove
  const startRemove = endRemove - removeCount;
  // NOTE: Negative start values cannot be used in substr becuase IE8 does not support them!
  // Get the start of the file
  const fileStart = name.substr(0, startRemove);
  // Get the end of the file
  const fileEnd = name.substr(endRemove);

  return fileStart + '…' + fileEnd;
}

// Gets file extensions from a file name, returns empty string when there is no extensions
export function parseFileExtension(filename: string): string {
  const filenameSplit = filename.split('.');
  return filenameSplit.length > 1 ? filenameSplit[filenameSplit.length - 1] : '';
}

// Makes a temporary (somewhat unique) id for use in the GUI before saving a new object to the server
export function makeGuid(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = (Math.random() * 16) | 0; // tslint:disable-line
    const v = c === 'x' ? r : ((r & 0x3) | 0x8); // tslint:disable-line
    return v.toString(16);
  });
}

// Checks if two object have the same id or guid
export function idsMatch(a, b): boolean {
  if (a == null || b == null) {
    return false;
  }

  if ((a.id != null) && (b.id != null)) {
    return a.id === b.id;
  } else if ((a.guid != null) && (b.guid != null)) {
    return a.guid === b.guid;
  } else {
    return false;
  }
}

// Create a fake mouse event
export function synthesizeMouseEvent(target: HTMLElement, type, options = {}): void {
  const event = target.ownerDocument.createEvent('MouseEvents');
  const opts = {
    type,
    canBubble: false, // Defaults to false: may need to set to true if we run into a situation where React has issues with events not bubbling to the top
    cancelable: true,
    view: target.ownerDocument.defaultView,
    detail: 1,
    screenX: 0, // The coordinates within the entire page
    screenY: 0,
    clientX: 0, // The coordinates within the viewport
    clientY: 0,
    ctrlKey: false,
    altKey: false,
    shiftKey: false,
    metaKey: false,
    button: 0, // 0 = left, 1 = middle, 2 = right
    relatedTarget: null,
    ...options,
  };

  // Pass in the options
  event.initMouseEvent(
    opts.type,
    opts.canBubble,
    opts.cancelable,
    opts.view,
    opts.detail,
    opts.screenX,
    opts.screenY,
    opts.clientX,
    opts.clientY,
    opts.ctrlKey,
    opts.altKey,
    opts.shiftKey,
    opts.metaKey,
    opts.button,
    opts.relatedTarget
  );

  // Fire the event
  target.dispatchEvent(event);
}

// Limits a value to only two decimal places
export function toFixed(value: number | string, precision = 2): number {
  if (!isNumeric(value)) {
    return value as number;
  }

  const multiplier = Math.pow(10, precision);

  return Math.round(value as number * multiplier) / multiplier;
}

// Can measure widths and heights of DOM elements
export function measureDOMProp<T extends HTMLElement, K extends keyof T>(el: T, DOMProp: K): T[K] {
  const measurer = document.getElementById('measurer');
  measurer.appendChild(el);
  const prop = el[DOMProp];
  measurer.removeChild(el);
  return prop;
}

// Make sure URL begins with 'https'
export function checkHTTPS(url): boolean {
  const checkString = url.slice(0, 5);
  return checkString === 'https';
}

export function alphaNumericKeyCode(code: number): boolean {
  if (!((code > 47) && (code < 58)) && // numeric (0-9)
  !((code > 64) && (code < 91)) && // upper alpha (A-Z)
  !((code > 96) && (code < 123))) { // lower alpha (a-z)
    return false;
  }
  return true;
}
/**
 * Upstream method used for updating a single value, returning an object created based on a key definition
 * @param newData
 * Default data object. This is the base object used to iterate upon and build the sub-properties according to the key definition
 * @param jsonPath
 * The key definition is provided in order to create the object structure. There are multiple ways to structure a key:
 * propertyName
 * ```javascript
 *  {
 *    propertyName: 'value'
 *  }
 * ```
 * propertyName.[0]
 * ```javascript
 *   {
 *     propertyName: [
 *      'value',
 *      ...
 *      ...
 *    ]
 *  }
 * ```
 * propertyName.nestedProperty
 * ```javascript
 *  {
 *    propertyName: {
 *      nestedProperty: 'value'
 *    }
 *  }
 * ```
 * propertyName.nestedProperty.doubleNestedProperty
 * ```javascript
 *  {
 *    propertyName: {
 *      nestedProperty: {
 *        doubleNestedProperty: 'value'
 *      }
 *    }
 *  }
 * ```
 * Filtering collections and injecting state properties
 * You can also inject properties from the component's local state.
 * This can be useful when you want to filter a collection by a state variable:
 * Local component state:
 * ```javascript
 *  this.state = {
 *    currentValueFilter: 888
 *  }
 * ```
 * arrayOfValues.[{value: $currentValueFilter}]
 * ```javascript
 *  arrayOfValues = [
 *    {
 *      value: 11
 *    },
 *    {
 *      value: 888
 *    },
 *    {
 *      value: 512
 *    }
 *  ]
 * ```
 * To use local state variable injection, make sure to call `updateFormValue` with the proper context applied, so state can be found: `updateFormValue.call(this, data, jsonPath, value)`
 * Collections can also be filtered by literal values, e.g. `55` or `'stringValue'`.
 * @param value
 * The value to be assigned to the JSON object once the destination is reached
 */
export function updateFormValue(existingData: object = {}, jsonPath: string, value: any): object {
  const newData = { ...existingData };
  // ************************************
  // Define the iterate function that will run for each key part
  // ************************************
  const iterate = (data, keyParts) => {
    // Nested properties
    let arrayKey;
    let collectionKey;
    let nextIsArray;
    let cur = keyParts[0];
    let next = data;
    const matchArray = cur.match(/\[(\d+)\]/);
    const matchCollection = cur.match(/\[\{(.+)\}\]/);
    if (keyParts[1] != null) { nextIsArray = keyParts[1].match(/\[(.+)\]/); }
    if (matchArray != null) { arrayKey = +matchArray[1]; }
    if (matchCollection != null) { collectionKey = matchCollection[1].split(':'); }

    // Check the object to establish the default type
    // Arrays
    if (arrayKey != null) {
      if (data == null) { data = []; }
      next = data[arrayKey];
      cur = arrayKey;

    // Collections
    } else if (collectionKey) {
      if (data == null) { data = []; }
      let collectionRecord = null;
      const k = collectionKey[0];
      let v = collectionKey[1];

      // Check if the value contains a state variable
      const valRef = v.match(/\$(\w+)/);
      if (valRef != null) {
        let val = this.state;

        // Make sure state is defined
        if (val == null) { throw Error('Unable to pull state properties when local state is undefined. Call updateFormValue from your current context using updateFormValue.call(this...)'); }

        // Double-nested refs
        const subKeys = v.replace(/^\s?\$/, '').split('.');
        while (subKeys.length) {
          val = val[subKeys.shift()];
        }
        v = val;
      }

      for (const obj of data) {
        // Cast the prop value to a string, in case of a number/boolean type
        if (obj != null) {
          if ((obj[k] != null) && (String(obj[k]) === String(v))) {
            collectionRecord = (next = obj);
            break;
          }
        }
      }

      if (collectionRecord == null) {
        // If no record is found, push a new record in with the matching property
        const d = {};
        d[k] = v;
        data.push(d);
        next = data[data.length - 1];
      }

    // Object
    } else if ((data[cur] == null)) {
      next = (data[cur] = nextIsArray ? [] : {});

    // If it already exists
    } else {
      next = data[cur];
    }

    // Base case. Return the value of the field once the destination prop has been reached
    if (!(keyParts.length > 1)) {
      data[cur] = value;
      return data;
    }

    // Remove the first item to continue recursion
    keyParts.shift();

    return iterate(next, keyParts);
  };

  // Check for nested properties
  if (jsonPath.match(/\.(?![^[\]]*])/)) {
    const keyParts = jsonPath.split(/\.(?![^[\]]*])/g);

    // Iterate over each key
    iterate(newData, keyParts);
  } else {
    newData[jsonPath] = value;
  }

  // return the new object
  return newData;
}

export function isValidEmail(addr) {
  // modified RFC 2822 - http://www.regular-expressions.info/email.html
  return /[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/.test(addr);
}

export const containsUpperCaseChar = (string: string) => string && (/[A-Z]/.test(string) || /[ÀÁÂÄÆÅÇÈÉËÊÎÏÌÍÑÔÖÒÓŒØẞÙÚÜ]/.test(string));
export const containsLowerCaseChar = (string: string) => string && (/[a-z]/.test(string) || /[àáâäæåçèéëêîïìíñôöòóœøßùúü]/.test(string));
export const containsNumber = (string: string) => string &&  /[1-9]/.test(string);

export function stripTags(text) {
  const el = document.createElement('div');
  el.innerHTML = text;
  return el.innerText;
}

export function stripScriptTags(text) {
  const el = document.createElement('div');
  el.innerHTML = text;
  const scripts = el.getElementsByTagName('script');

  for (let i = scripts.length - 1; i >= 0; i--) { const script = scripts[i]; script.parentElement.removeChild(script); }

  return el.innerHTML;
}

export function secondsToMMSS(seconds: number): string {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;

  return [mins, secs]
      .map(v => v < 10 ? '0' + v : v)
      .join(':');
}

export function boundedNumber(numberToBound: number, lowerBound: number, upperBound: number): number {
  return Math.min(Math.max(lowerBound, numberToBound), upperBound);
}

export function resetInputScroll(scrollSelector: string): void {
  scrollToInput({scrollSelector: scrollSelector, absHeight: 'auto', absBottom: '0', scrlHeight: '100%', scrlTop: 0});
}

export function centerInputScroll(scrollSelector: string, inputName: string, absBottom: number, scrlHeight): void {
  setTimeout(() => {
    scrollToInput({
      scrollSelector: scrollSelector,
      inputName: inputName,
      absHeight: 'auto',
      absBottom: `${absBottom}px`,
      scrlHeight: `${scrlHeight}px`,
    }
    );
  }, 1);
}

interface IInputScroll {
  scrollSelector: string;
  inputName?: string;
  absHeight: string;
  absBottom: string;
  scrlHeight?: string;
  scrlTop?: number;
}

function scrollToInput(inputScrollOptions: IInputScroll): void {
  const { scrollSelector, inputName, absHeight, absBottom, scrlHeight, scrlTop } = inputScrollOptions;

  if (scrollSelector) {
    const scrollEl = document.querySelector(scrollSelector) as HTMLDivElement;

    if (scrollEl) {
      const { position } = window.getComputedStyle(scrollEl);

      if (position === 'absolute') {
        scrollEl.style.height = absHeight;
        scrollEl.style.bottom = absBottom;
      }
      else {
        scrollEl.style.height = scrlHeight;
      }

      scrollEl.scrollTo({
        top: (inputName && !scrlTop) ? findScroll(scrollSelector, inputName) : scrlTop,
        behavior: 'smooth',
      });
    }
    else {
      if (process.env.NODE_ENV !== 'test') {
        console.warn(`No element found with selector ${scrollSelector}`);
      }
    }
  }
}

function findScroll(scrollSelector: string, inputName: string): number {
  const currentInput = document.querySelector(`input[name="${inputName}"]`);
  const scrollEl = document.querySelector(scrollSelector) as HTMLDivElement;

  // Get the DOM attributes needed for the calculations
  const { offsetHeight, scrollHeight, scrollTop } = scrollEl;

  const currentInputRect = currentInput?.getBoundingClientRect();
  const scrollElRect = scrollEl?.getBoundingClientRect();

  const maxScroll = scrollHeight - offsetHeight;
  const scroll = (currentInputRect.top + scrollTop) - scrollElRect.top - (scrollElRect.height / 2) + (currentInputRect.height / 2);

  return scroll < maxScroll ? scroll : maxScroll;
}
