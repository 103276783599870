import * as React from 'react';
import Modal, { IButton } from '../Modal';
import { SaveStates } from '../ConfirmSave';
import * as Spin from 'spin';
import { ModalAnimations } from '@Lineup/enums/index';

const styles = require('./styles/simple_modal.styl');
const modalStyles = require('./styles/modal.styl');

export enum SimpleModalTypes {
  CONFIRMATION,
  SELECTION,
  ALERT,
  ERROR,
  DETECTED,
  LOADING,
}

export interface ISimpleModalProps {
  close?: () => void;
  open?: () => void;
  title?: string | JSX.Element;
  message?: string | JSX.Element;
  type: SimpleModalTypes;
  saveState?: SaveStates;
  saveMessage?: string;
  onSaveFail?: () => void;
  buttons?: IButton[];
  timeout?: number;
  top?: string;
  zIndex?: number;
  dataTest?: string;
}

export default class SimpleModal extends React.Component<ISimpleModalProps, any > {
  private detectedEl: HTMLDivElement;
  public static defaultProps = {
    saveState: null,
    saveMessage: null,
    top: null,
    dataTest: null,
  };

  public componentDidMount() {
    const { open, close, timeout, type} = this.props;

    if (timeout && timeout > 0 && close) {
      setTimeout(() => close(), timeout);
    }

    else if (SimpleModalTypes.LOADING === type) {
      const spinner = new Spin({
        lines: 12, // The number of lines to draw
        length: 10, // The length of each line
        width: 4, // The line thickness
        radius: 15, // The radius of the inner circle
        corners: 2, // Corner roundness(0..1)
        rotate: 0, // The rotation offset
        direction: 1, // 1: clockwise, - 1: counterclockwise
        color: '#737373', // rgb or rrggbb or array of colors
        speed: 1, // Rounds per second
        trail: 60, // Afterglow percentage
        shadow: false, // Whether to render a shadow
        hwaccel: false, // Whether to use hardware acceleration
        zIndex: 15, // The z - index(defaults to 2000000000)
      }).spin();

      this.detectedEl?.appendChild(spinner.el);
    }

    open?.();
  }

  public render(): JSX.Element {
    const { message, title, saveState, saveMessage, onSaveFail, buttons, type, top, zIndex, dataTest } = this.props;

    const messageContent = [SimpleModalTypes.DETECTED, SimpleModalTypes.LOADING].includes(type)  ?
      (
        <div
          className={`${styles.DetectedMessage} ${type === SimpleModalTypes.LOADING ? styles.Loader : ''} ${!message ? styles.NoMessage : ''}`}
          ref={(el) => this.detectedEl = el}
        >
          {message && <div className={styles.DetectedText}>{message}</div>}
        </div>
      ) : message;

    let modalClass = top == null ? modalStyles.AlertModalCentered : modalStyles.AlertModal;
    if ([SimpleModalTypes.DETECTED, SimpleModalTypes.LOADING].includes(type)) { modalClass = modalStyles.DetectedModal; }

    let messageClass = styles.ModalMessage;
    if (typeof messageContent !== 'string') { messageClass += ` ${styles.NoMargin}`; }

    return (
      <Modal
        dataTest={dataTest}
        close={this.close}
        showClose={false}
        className={`${modalClass}`}
        draggable={false}
        saveState={saveState}
        saveMessage={saveMessage}
        onSaveFail={onSaveFail}
        backdropClose={false}
        animateIn={ModalAnimations.FADE_IN}
        style={{top: top}}
        zIndex={zIndex}
      >
        {title ?
          <div className={`${styles.Header} ${type === SimpleModalTypes.ALERT || type === SimpleModalTypes.ERROR ? styles.Highlight : null}`}>
            {type === SimpleModalTypes.ERROR && <div className={`${styles.Icon} ${styles.Error}`} />}
            <div className={styles.Title}>
              {title}
            </div>
          </div> : null
        }
        <div className={messageClass}>
          {messageContent}
        </div>
        <div className={styles.Buttons}>
          {buttons?.map((button, index) => (
            <button
              key={`button-${index}`}
              className={`${styles.Button} ${buttons.length > 2 ? styles.Stack : ''}`}
              disabled={!button.handler}
              onClick={button.handler}
              data-test={`button-${index}`}
            >
              {button.name}
            </button>
          ))}
        </div>
      </Modal>);
  }

  private close = (): void => {
    this.props.close();
  };
}
