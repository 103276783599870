import * as React from 'react';
import Input from './Input';
import TypeAhead, { ITypeAheadProps } from './TypeAhead';

export default class TypeAheadInput extends React.Component<ITypeAheadProps, any> {
  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <TypeAhead {...this.props} {...props} />
          );
        }}
      </Input>
    );
  }
}
