import * as React from 'react';
import { IBaseInputProps, IValidateOptions, IValidationError } from './interfaces';
import ValidationErrorPvr from './ValidationErrorPvr';

const styles = require('./styles/input.styl');

export interface ICheckboxProps extends IBaseInputProps {
  name: string;
  jsonPath: string;
  className?: string;
  wrapperLabel?: string;
  checkboxLabelRight?: boolean;
  disabled?: boolean;
  tabIndex?: number;
  title?: string;
  id?: string;
  checked?: boolean;
  onChange: (value: boolean, jsonPath?: string) => void;
  validate?: (options: IValidateOptions) => IValidationError;
  valueHasChanged?: boolean;
}

export default class Checkbox extends React.Component<ICheckboxProps, any> {

  public static defaultProps = {
    wrapperClass: '',
    validation: false,
    forceShowAllErrors: false,
    checkboxLabelRight: false,
  };

  public state = {
    showErrors: false,
  };

  private errorAnchor: HTMLElement;
  private input: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.input = React.createRef();
  }

  public componentDidMount(): void {
    const { focusOnMount } = this.props;

    if (focusOnMount) { this.input.current.focus(); }
  }

  public render(): JSX.Element {
    const { className, wrapperClass, wrapperLabel, checkboxLabelRight, validation, checked, disabled, id, name, tabIndex,
      title, validate, forceShowAllErrors, valueHasChanged, flashErrors, jsonPath} = this.props;
    const { showErrors } = this.state;
    const error = validate({ value: checked, validation, anchor: this.errorAnchor });
    const isValid = error.messages.length === 0;
    const inputEl = (
      <input
        type="checkbox"
        key="input"
        ref={this.input}
        id={id}
        name={name}
        data-test={jsonPath}
        title={title}
        checked={checked}
        disabled={disabled}
        className={className}
        tabIndex={tabIndex}
        onChange={this.handleChange}
        onTouchStart={this.handleErrorTouch}
      />
    );

    const errorPvr = !isValid && (showErrors || flashErrors) ? <ValidationErrorPvr error={error} /> : void 0;

    const errEl = (
      <div
        className={styles.FieldErrorsShow}
        key="checkboxInputErrorsShow"
        ref={(el: HTMLElement) => this.errorAnchor = el}
        onMouseOver={this.handleErrorMouseOver}
        onMouseOut={this.handleErrorMouseOut}
        onTouchStart={this.handleErrorTouch}
      />
    );

    let outerClass = styles.FieldWrap;
    let labelClass = styles.CheckboxInputLabel;
    let label = null;

    if (!isValid && (valueHasChanged || forceShowAllErrors)) { outerClass += ` ${styles.Invalid}`; }

    if (disabled) { labelClass += ` ${styles.Disabled}`; }

    if (wrapperLabel != null) {
      if (checkboxLabelRight) {
        outerClass += ` ${styles.CheckBoxRightWrap}`;
        labelClass += ` ${styles.CheckBoxRightLabel}`;
      }

      label = <label className={labelClass} htmlFor={id} title={title}>{wrapperLabel}</label>;
    }

    return (
      <div className={`${outerClass} ${wrapperClass}`}>
        {inputEl}
        {label}
        {errEl}
        {errorPvr}
      </div>
    );
  }

  public handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange, jsonPath } = this.props;
    if (typeof onChange === 'function') {
      onChange(e.target.checked, jsonPath);
    }
  };

  private handleErrorMouseOver = (e): void => {
    this.setState({ showErrors: true });
  };

  private handleErrorMouseOut = (e): void => {
    this.setState({ showErrors: false });
  };

  private handleErrorTouch = (e): void => {
    e.stopPropagation();
    this.setState({ showErrors: true }, () => {
      setTimeout(() => this.setState({ showErrors: false }), 3000);
    });
  };

}
