import * as React from 'react';
import AlertIcon, { AlertTypes } from './AlertIcon';

const styles = require('./styles/dialogue.styl');

export interface IDialogueBoxProps {
  top?: number;
  width?: number;
  height?: number;
  message?: string;
  confirmText?: string;
  cancelText?: string;
  dialogueTitle?: string;
  alertIcon?: AlertTypes;
  transitionOut?: (e) => void;
}

export default class DialogueBox extends React.Component<IDialogueBoxProps, any> {
  public static defaultProps: IDialogueBoxProps = {
    width: 200,
    alertIcon: AlertTypes.WARNING,
  };

  public render(): JSX.Element {
    const { top, width, height, message, confirmText, cancelText, dialogueTitle, alertIcon } = this.props;
    const title = [];
    let messageStyle = styles.Message;

    if (dialogueTitle != null) {
      messageStyle += ` ${styles.Indent}`;
      const icons = <AlertIcon type={alertIcon} />;

      title.push(
        <div key="titleWrap" className={styles.TitleWrap}>
          <div key="icon" className={styles.Icon}>{icons}</div>
          <div key="title" className={styles.Title}>{dialogueTitle}</div>
        </div>
      );
    }

    const checkStyles = {
      transform: `translateY(${top}px) translateZ(0px)`,
      msTransform: `translateY(${top}px)`,
      WebkitTransform: `translateY(${top}px) translateZ(0px)`,
      height: height,
      width: width,
      marginLeft: -(width / 2),
    };

    return (
      <div className={styles.DialogueBox} style={checkStyles}>
        {title}
        <div key="message" className={messageStyle}>
          {message}
        </div>
        <button key="okay-btn" className={styles.OkayBtn} onClick={this.handleButtonClick} value="okay">
          {confirmText}
        </button>
        <button key="cancel-btn" className={styles.CancelBtn} onClick={this.handleButtonClick} value="cancel">
          {cancelText}
        </button>
      </div>
    );
  }

  public handleButtonClick = (e): void => {
    this.props.transitionOut(e.target.value);
  };
}
