import * as React from 'react';
import Pvr, { IPvrProps } from './Pvr';
import PvrInfoItem from './PvrInfoItem';
import { IItem } from './interfaces';

const styles = require('./styles/pvr.styl');

/*
Info Popover Props

@props.items
  array of objects containing at minimum a label and value attribute
  optionally a subLabel property can be passed

@props.styleMixin
  object containing any style properties to mixin with and/or overrride the defaults
  note that width height are passed separately so they can have defaults and auto settings
  passing widt/height in this object could cause issues

@props.headerTitle
  optional title String for popover header

@props.headerClass
  optional class for popover header

@props.className
  optional class for whole popover. default is 'PlainPvr'. 'PlainPvr' class should be
  included if a custom class is passed, i.e., className: 'PlainPvr custom-class'

@props.pvrProps
  properties germane to PVR wrapper: width, height, anchor, hAdjust, vAdjust, direction

*/
export interface IInfoPvrProps {
  items: IItem[];
  styleMixin?: React.CSSProperties;
  headerTitle?: string;
  headerClass?: string;
  className?: string;
  pvrProps?: IPvrProps;
  close?: () => void;
  noItemsTxt?: string;
}

const ITEM_HEIGHT = 44;

export class InfoPvr extends React.Component<IInfoPvrProps, any> {
  private hasHeader: boolean;
  
  public static defaultProps = {
    items: [],
    headerTitle: null,
    headerClass: '',
    pvrProps: {},
    className: styles.InfoPvr,
    styleMixin: {
      height: 'auto',
      width: 0,
    },
  };
  
  public render(): any {
    const { items, styleMixin, className, headerTitle, headerClass, close, pvrProps, noItemsTxt} = this.props;
    
    let style: React.CSSProperties = {};

    const localHeight = {height: pvrProps.height};
    const localPvrProps = { ...localHeight, ...this.props.pvrProps, close, scale: 1 };

    let pvrClassName = '';

    if (className != null) { pvrClassName = className; }

    this.hasHeader = (headerTitle != null);

    if (pvrProps.height == null) {
      localPvrProps.height = (items.length * ITEM_HEIGHT) + (items.length - 1);
    }
    if (this.hasHeader) {
      (pvrProps.height as number) += 34;    
    }

    style = { ...style, ...styleMixin };

    style.height = pvrProps.height;

    if (pvrProps.width) {
      style.width = pvrProps.width;
    }

    const itemRows = items.map((item) => <PvrInfoItem key={item.label} item={item} />);

    if (itemRows.length === 0) {
      itemRows.push(<div key="no-items" className={styles.NoItems}>{noItemsTxt}</div>);
    }
    
    const headerElement = this.hasHeader ? <div key="header" className={`${styles.Header} ${styles.PlainContentItem}  ${headerClass}`}>{headerTitle}</div> : null;

    return (
      <Pvr {...localPvrProps}>
        <div className={pvrClassName} style={style}>
          {headerElement}
          <div className={styles.InnerRows}>{itemRows}</div>
        </div>
      </Pvr>
    );
  }
}

export default InfoPvr;
