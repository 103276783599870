import * as React from 'react';
import * as Animation from 'ainojs-animation';
import * as easing from 'ainojs-easing';

const styles = require('./styles/chevron.styl');

/* &
  @props.onClick 
  handle click events

  @props.invertOnClick
  determines whether or not the element is inverted on click event

  @props.defaultOrientation
  Accepts values `up`, `left`, `down`, or `right` to set the default direction

  @props.isInverted
  determines whether or not the chevron is in an inverted state

  @props.animationDuration - OPTIONAL [Number]
  duration in milliseconds determining how long the animation takes to complete

  @props.color - OPTIONAL [String]
  hex/rgba color to fill the chevron
&*/

export enum Directions {
  DOWN = 'down',
  UP = 'up',
  LEFT = 'left',
  RIGHT = 'right',
}

export interface IChevronProps {
  onClick?: () => void;
  invertOnClick?: boolean;
  defaultOrientation?: Directions;
  isInverted?: boolean;
  animationDuration?: number;
  color?: string;
}

class Chevron extends React.Component<IChevronProps, any> {

  public isInverted: boolean;
  private animation;

  constructor(props) {
    super(props);
    this.isInverted = this.props.isInverted;

    this.state = this.isInverted ? {...Chevron.inverted} : {...Chevron.default};
  }

  public static defaultProps = {
    invertOnClick: false,
    defaultOrientation: Directions.DOWN,
    isInverted: false,
    animationDuration: 200,
    color: '#37BAC4',
  };

  public static default = {
    d1: 4,
    d2: -4,
    d3: 6,
    d4: -4,
    d5: 0,
    d6: 4,
  };

  public static inverted = {
    d1: 0,
    d2: 4,
    d3: 2,
    d4: 4,
    d5: 4,
    d6: -4,
  };

  public componentDidMount(): void {
    this.animation = new Animation({
      duration: this.props.animationDuration,
      easing: easing('linear'),
    }).on('frame', this.onFrame);
  }

  public componentWillReceiveProps(nextProps): void {
    if (nextProps.isInverted !== this.isInverted) {
      if (this.props.invertOnClick) { this.animate(); }
    }
  }

  public componentWillUnmount(): void {
    if (this.animation != null ? this.animation.isAnimating() : undefined) { this.animation.end(); }
    this.animation.destroy();
  }

  public render(): JSX.Element {
    const {defaultOrientation, color} = this.props;

    const {d1, d2, d3, d4, d5, d6} = this.state;
    const dAttr = `M4 ${d1}l5 ${d2}v2L4 ${d3}l-5 ${d4}V${d5}l5 ${d6}z`;
    
    let className = styles.Chevron;
    className += ` ${styles[defaultOrientation]}`;
        
    return (
      <div  
        className={className}
        onClick={this.handleClick}
      >
        <svg
          className={styles.ChevronSvg}
          width="10"
          height="6"
          viewBox="-1 0 10 6"
        >
          <path fill={color} d={dAttr}/>
        </svg>
      </div>
    );
  }

  private onFrame = (e): void => {
    this.setState(e.values);
  };

  private handleClick = (e): void => { 
    e.stopPropagation();
    
    if (typeof this.props.onClick === 'function') {
      this.props.onClick();
    }

    if (this.props.invertOnClick) { this.animate(); }
  };

  private animate(): void {
    this.isInverted = !this.isInverted;

    // Note: need to clone here so animate class does not alter initial values
    const stateStart = {...this.state};
    const stateEnd = this.isInverted ? {...Chevron.inverted} : {...Chevron.default};
    
    this.animation.init(stateStart);
    this.animation.animateTo(stateEnd);
  }
}

export default Chevron;
