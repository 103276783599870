module.exports = {
  "__appName__ is not currently available.": "__appName__ n'est pas disponible actuellement.",
  "Access Code": "Code d’accès",
  "Account ${this.username} is locked due to 5 login attempts. Please contact your administrator to unlock it": "Le compte ${this.username} est verrouillé suite à 5 tentatives de connexion. Veuillez contacter un administrateur pour le déverrouiller",
  "Afghanistan": "Afghanistan",
  "Albania": "Albanie",
  "Algeria": "Algérie",
  "American Samoa": "Samoa Américaines",
  "Andorra": "Andorre",
  "Angola": "Angola",
  "Anguilla": "Anguilla",
  "Antigua and Barbuda": "Antigua-et-Barbuda",
  "App": "App",
  "Application Error": "Erreur de l’application",
  "Application error.": "Erreur de l’application.",
  "Application is not currently available.": "L’application n’est pas disponible actuellement.",
  "Apply Changes": "Appliquer les modifications",
  "Apr": "Avr",
  "April": "Avril",
  "Argentina": "Argentine",
  "Armenia": "Arménie",
  "Aruba": "Aruba",
  "At least __value__ characters": "Au moins __value__ caractères",
  "At least one lowercase letter": "Au moins une lettre minuscule",
  "At least one number": "Au moins un chiffre",
  "At least one of these special characters: __value__": "Au moins un de ces caractères spéciaux : __value__",
  "At least one uppercase letter": "Au moins une lettre majuscule",
  "Aug": "Août",
  "August": "Août",
  "Australia": "Australie",
  "Austria": "Autriche",
  "Azerbaijan": "Azerbaïdjan",
  "Bahamas": "Bahamas",
  "Bahrain": "Bahrain",
  "Bangladesh": "Bangladesh",
  "Barbados": "Barbade",
  "Belarus": "Biélorussie",
  "Belgium": "Belgique",
  "Belize": "Belize",
  "Benin": "Bénin",
  "Bermuda": "Bermudes",
  "Bhutan": "Bhoutan",
  "Bluetooth is Turned Off": "Bluetooth éteint",
  "Bolivia": "Bolivie",
  "Bosnia and Herzegovina": "Bosnie-Herzégovine",
  "Botswana": "Botswana",
  "Brazil": "Brésil",
  "Bronchial Lavage": "Lavage broncho-alvéolaire (LBA)",
  "Brunei Darussalam": "Brunei Darussalam",
  "Bulgaria": "Bulgarie",
  "Burkina Faso": "Burkina Faso",
  "Burundi": "Burundi",
  "Cambodia": "Cambodge",
  "Cameroon": "Cameroun",
  "Canada": "Canada",
  "Cancel": "Annuler",
  "Cannot be empty": "Ne peut pas être vide",
  "Cape Verde": "Cap-Vert",
  "Capillary Blood": "Sang capillaire",
  "Cayman Islands": "Îles Caïmans",
  "Central African Republic": "République Centrafricaine",
  "Cerebrospinal Fluid": "Liquide cérébrospinal (LCS)",
  "Cervical Swab": "Prélèvement cervical",
  "Chad": "Tchad",
  "Change Access Code": "Modifier le code d’accès",
  "Check again": "Vérifier à nouveau",
  "Chile": "Chili",
  "China": "Chine",
  "Clear Search": "Effacer la recherche",
  "Close": "Fermer",
  "Cocos(Keeling) Islands and Chr": "Îles Cocos (Keeling) et île de noël",
  "Colombia": "Colombie",
  "Comoros": "Comores",
  "Confirm": "Confirmer",
  "Confirm New Password": "Confirmer le nouveau mot de passe",
  "Confirm new password": "Confirmer le nouveau mot de passe",
  "Confirm New Password is required.": "Confirmation du nouveau mot de passe requise.",
  "Confirm Password is required.": "Confirmation du mot de passe requise.",
  "Confirm Password must match New Password": "Le mot de passe de confirmation doit correspondre au nouveau mot de passe",
  "Congo(Brazzaville)": "Congo (Brazzaville)",
  "Congo(Kinshasa)": "Congo (Kinshasa)",
  "Cook Islands": "Îles Cook",
  "Copyright © __year__ - LumiraDx": "Copyright © __year__ - LumiraDx",
  "Copyright © __year__ - LumiraDx __UI_VERSION__": "Copyright © __year__ - LumiraDx __UI_VERSION__",
  "Costa Rica": "Costa Rica",
  "Cote D'Ivoire (Ivory Coast)": "Cote D'Ivoire (Ivory Coast)",
  "Croatia(Hrvatska)": "Croatie (Hrvatska)",
  "Cuba": "Cuba",
  "Culture Broth": "Bouillon de culture",
  "Current Password": "Mot de passe actuel",
  "Current Password is required.": "Le mot de passe actuel est requis.",
  "Current password required": "Mot de passe actuel requis",
  "Cyprus": "Chypre",
  "Czech Republic": "République Tchèque",
  "Danish": "Danois",
  "Dec": "Déc",
  "December": "Décembre",
  "Denmark": "Danemark",
  "Disabled": "Désactivé",
  "Discard Changes": "Annuler les changements",
  "Dismiss": "Ignorer",
  "Djibouti": "Djibouti",
  "Does not match your previous passwords": "Ne correspond pas à vos mots de passe précédents",
  "Does not use unsupported characters": "N'utilise pas de caractères non pris en charge",
  "Domain": "Domaine",
  "Domain is required.": "Domaine requis.",
  "Dominica": "Dominique",
  "Dominican Republic": "République Dominicaine",
  "Door Open": "Porte ouverte",
  "Dutch": "Néerlandais",
  "Ecuador": "Équateur",
  "Egypt": "Égypte",
  "El Salvador": "Le Salvador",
  "Email": "Adresse électronique",
  "Email is required.": "Adresse électronique requise.",
  "English (UK)": "Anglais (Royaume-Uni)",
  "English (US)": "Anglais (États-Unis)",
  "Enter": "Entrée",
  "Equatorial Guinea": "Guinée Équatoriale",
  "Eritrea": "Érythrée",
  "Error": "Erreur",
  "Estonia": "Estonie",
  "Ethiopia": "Éthiopie",
  "Factory Manager is not supported in the Connect mobile application.": "Le Factory Manager n’est pas compatible avec l’application mobile Connect.",
  "Failed to reset password. A server error was encountered": "Échec de la réinitialisation du mot de passe. Une erreur de serveur s’est produite",
  "Falkland Islands(Malvinas)": "Îles Falkland (Malouines)",
  "Faroe Islands": "Îles Féroé",
  "Fatal Application Error": "Erreur fatale de l’application",
  "Feb": "Févr",
  "February": "Février",
  "Fecal Specimen": "Échantillon fécal",
  "Fiji": "Fidji",
  "File type must be __fileType__": "Le type de fichier doit être __fileType__",
  "Finland": "Finlande",
  "Finnish": "Finnois",
  "First Name": "Prénom",
  "Forgot your password?": "Mot de passe oublié ?",
  "Fr": "Ve",
  "France": "France",
  "French": "Français",
  "French Guiana": "Guyane Française",
  "French Polynesia": "Polynésie Française",
  "Gabon": "Gabon",
  "Gambia": "Gambie",
  "Georgia": "Géorgie",
  "German": "Allemand",
  "Germany": "Allemagne",
  "Ghana": "Ghana",
  "Gibraltar": "Gibraltar",
  "Go To __storeName__": "Accéder à __storeName__",
  "Greater than __value__ in __measure__": "Supérieur à __value__ en __measure__",
  "Greece": "Grèce",
  "Greenland": "Groenland",
  "Grenada": "Grenade",
  "Guadeloupe": "Guadeloupe",
  "Guam": "Guam",
  "Guatemala": "Guatemala",
  "Guinea": "Guinée",
  "Guinea - Bissau": "Guinée-Bissau",
  "Guyana": "Guyane",
  "Haiti": "Haïti",
  "Hct Out of Range": "Hct hors intervalle",
  "Hide": "Masquer",
  "Hide password": "Masquer le mot de passe",
  "Holy See(Vatican City State)": "Saint-Siège (État de la Cité du Vatican)",
  "Honduras": "Honduras",
  "Hong Kong, SAR": "Hong Kong, RAS",
  "Hungary": "Hongrie",
  "Iceland": "Islande",
  "India": "Inde",
  "Indonesia": "Indonésie",
  "Initials": "Initiales",
  "Install": "Installer",
  "Install Now": "Installer maintenant",
  "Instrument Not Level": "L’Instrument n’est pas à niveau",
  "Insufficient Sample": "Échantillon insuffisant",
  "Internet connection is offline.": "La connexion internet est désactivée.",
  "Invalid access code": "Code d’accès invalide",
  "Invalid email format": "Format d’adresse électronique invalide",
  "Invalid File": "Fichier invalide",
  "Invalid Lot Cal File": "Fichier de calibration du lot invalide",
  "Invalid username or password.": "Nom d’utilisateur ou mot de passe invalide.",
  "Iran, Islamic Republic of": "Iran (République islamique)",
  "Iraq": "Irak",
  "Ireland": "Irlande",
  "Israel": "Israël",
  "Italian": "Italien",
  "Italy": "Italie",
  "Jamaica": "Jamaïque",
  "Jan": "Janv",
  "January": "Janvier",
  "Japan": "Japon",
  "Japanese": "Japonais",
  "Jordan": "Jordanie",
  "Jul": "Juil",
  "July": "Juillet",
  "Jun": "Juin",
  "June": "Juin",
  "Kazakhstan": "Kazakhstan",
  "KC Swab": "Écouvillon KC",
  "Kenya": "Kenya",
  "Kiribati": "Kiribati",
  "Korea, Democratic People's Rep": "Corée, République populaire démocratique",
  "Korea, Republic of(South)": "Corée, République de (Sud)",
  "Kuwait": "Koweït",
  "Kyrgyzstan": "Kirghizistan",
  "Language": "Langue",
  "Laos(Lao PDR)": "Laos (RDP lao)",
  "Last Name": "Nom de famille",
  "Latvia": "Lettonie",
  "Lebanon": "Liban",
  "Lesotho": "Lesotho",
  "Less than __value__ in __measure__": "Inférieur à __value__ en __measure__",
  "Liberia": "Liberia",
  "Libya": "Libye",
  "Liechtenstein": "Liechtenstein",
  "Liquid Quality Control": "Contrôle Qualité liquide",
  "Lithuania": "Lituanie",
  "Loading more records...": "Chargement d'autres dossiers...",
  "Login": "Se connecter",
  "Logout": "Se déconnecter",
  "Luxembourg": "Luxembourg",
  "Macao(SAR China)": "Macao (RAS Chine)",
  "Macedonia, Republic of": "Macédoine, République de",
  "Madagascar": "Madagascar",
  "Malawi": "Malawi",
  "Malaysia": "Malaysie",
  "Maldives": "Maldives",
  "Mali": "Mali",
  "Malta": "Malte",
  "Mar": "Mars",
  "March": "Mars",
  "Marshall Islands": "Îles Marshall",
  "Martinique": "Martinique",
  "Mauritania": "Mauritanie",
  "Mauritius": "Île Maurice",
  "MAX": "MAX",
  "Maximum length is __count__ characters": "La longueur maximale est de __count__ caractères",
  "May": "Mai",
  "Mexico": "Mexique",
  "Micronesia, Federated States o": "Micronésie, États fédérés de",
  "Mo": "Lu",
  "Mobile Application Version": "Version de l’application mobile",
  "Moldova": "Moldavie",
  "Monaco": "Monaco",
  "Mongolia": "Mongolie",
  "Montenegro": "Monténégro",
  "Montserrat": "Montserrat",
  "Morocco and Western Sahara": "Maroc et Sahara Occidental",
  "Mouth Swab": "Prélèvement buccal",
  "Mozambique": "Mozambique",
  "Myanmar": "Birmanie",
  "Namibia": "Namibie",
  "Nasal Discharge": "Sécrétions nasales",
  "Nasal Swab": "Prélèvement nasal",
  "Nauru": "Nauru",
  "Nepal": "Népal",
  "Netherlands": "Pays-Bas",
  "Netherlands Antilles": "Antilles Néerlandaises",
  "Never Connected": "Jamais connecté",
  "New Caledonia": "Nouvelle-Calédonie",
  "New Password": "Nouveau mot de passe",
  "New Password is invalid.": "Nouveau mot de passe invalide.",
  "New Password is required.": "Nouveau mot de passe requis.",
  "New password must match": "Le nouveau mot de passe doit correspondre",
  "New Version Required": "Nouvelle version requise",
  "New Zealand": "Nouvelle-Zélande",
  "Nicaragua": "Nicaragua",
  "Niger": "Niger",
  "Nigeria": "Nigeria",
  "Niue": "Niue",
  "No application modules exist in this environment. Please report this issue to an administrator.": "Il n’existe aucun module d’application dans cet environnement. Veuillez signaler ce problème à un administrateur.",
  "No easily guessable words": "Pas de mots facilement devinables",
  "No more than __value__ characters": "Pas plus de __value__ caractères",
  "No region set": "Région non définie",
  "No repetitive characters (e.g. aaa)": "Pas de caractères répétitifs (p. ex. aaa)",
  "No sequential characters (e.g. 123)": "Pas de caractères séquentiels (p. ex. 123)",
  "No Test Strip Detected": "Aucune carte microfluidique détectée",
  "none": "aucune",
  "Norfolk Island": "Île Norfolk",
  "Northern Mariana Islands": "Îles Mariannes du Nord",
  "Norway(inc Svalbard and Jan May": "Norvège (inc Svalbard et Jan Mayen)",
  "Norwegian": "Norvégien",
  "Nov": "Nov",
  "November": "Novembre",
  "NP Aspirate": "Aspirat. naso-phar.",
  "NP Swab": "Écouvillon NP",
  "Oct": "Oct",
  "October": "Octobre",
  "Offline 1 day": "Hors ligne 1 jour",
  "Offline 1 hour": "Hors ligne 1 heure",
  "Offline 1 minute": "Hors ligne 1 minute",
  "Offline 1 month": "Hors ligne 1 mois",
  "Offline 1 year": "Hors ligne 1 an",
  "Offline __days__ days": "Hors ligne __days__ jours",
  "Offline __hours__ hours": "Hors ligne __hours__ heures",
  "Offline __minutes__ minutes": "Hors ligne __minutes__ minutes",
  "Offline __months__ months": "Hors ligne __months__ mois",
  "Offline __years__ years": "Hors ligne __years__ ans",
  "OK": "OK",
  "Okay": "OK",
  "Oman": "Oman",
  "Pakistan": "Pakistan",
  "Palau": "Palaos",
  "Palestinian Territory, Occupie": "Territoire palestinien occupé",
  "Panama": "Panama",
  "Papua New Guinea": "Papouasie-Nouvelle-Guinée",
  "Paraguay": "Paraguay",
  "Password": "Mot de passe",
  "Password requirements:": "Exigences relatives au mot de passe :",
  "Peru": "Pérou",
  "Philippines": "Philippines",
  "Pitcairn": "Pitcairn",
  "Plasma": "Plasma",
  "Poland": "Pologne",
  "Portugal": "Portugal",
  "Portuguese (Brazil)": "Portugais (Brésil)",
  "Portuguese (Portugal)": "Portugais (Portugal)",
  "Profile": "Profil",
  "Provide the following information to set your password.  The validation code was sent to your email, if valid.": "Saisissez les informations suivantes pour définir votre mot de passe.  Le code de validation a été envoyé à votre adresse électronique, si elle est valide.",
  "Provide your email to request a password reset. A temporary validation code will be sent to your email.": "Saisissez votre adresse électronique pour demander la réinitialisation du mot de passe. Un code de validation temporaire vous sera envoyé par courrier électronique.",
  "Puerto Rico": "Porto Rico",
  "Qatar": "Qatar",
  "QC Solution Detected": "Solution CQ détectée",
  "Rectal Swab": "Prélèvement rectal",
  "Required Update": "Mise à jour requise",
  "Reset": "Réinitialiser",
  "Reset Password": "Réinit. mot de passe",
  "Reset password": "Réinit. mot de passe",
  "Resolution requirements": "Exigences en matière de résolution",
  "Return to Login": "Revenir à la connexion",
  "Romania": "Roumanie",
  "Russian Federation": "Fédération Russe",
  "Rwanda": "Rwanda",
  "Réunion and Mayotte": "Réunion et Mayotte",
  "Sa": "Sa",
  "Saint Helena and Tristan da Cu": "Sainte-Hélène et Tristan da Cunha",
  "Saint Kitts and Nevis": "Saint-Christophe-et-Niévès",
  "Saint Lucia": "Sainte-Lucie",
  "Saint Pierre and Miquelon": "Saint-Pierre-et-Miquelon",
  "Saint Vincent and the Grenadine": "Saint-Vincent-et-les Grenadines",
  "Saliva": "Salive",
  "Samoa": "Samoa",
  "Sample Detected": "Échantillon détecté",
  "Sample Error": "Erreur d’échantillon",
  "Sample Type Error": "Erreur de type d’échantillon",
  "San Marino": "Saint-Marin",
  "Saudi Arabia": "Arabie Saoudite",
  "Save": "Enregistrer",
  "Select a date": "Sélectionner une date",
  "Select from list...": "Choisir dans la liste…",
  "Select language": "Choisir la langue",
  "Selected files must match requirements": "Les fichiers sélectionnés doivent correspondre aux exigences",
  "Senegal": "Sénégal",
  "Sep": "Sept",
  "September": "Septembre",
  "Serbia": "Serbie",
  "Serum": "Sérum",
  "Server error, please try again later.": "Erreur de serveur, veuillez réessayer plus tard.",
  "Server Error. Unable to save your profile.": "Erreur du serveur. Impossible d’enregistrer votre profil.",
  "Set Password": "Définir le mot de passe",
  "Seychelles": "Seychelles",
  "Show Password": "Afficher le mot de passe",
  "Sierra Leone": "Sierra Leone",
  "Singapore": "Singapour",
  "Size must be less than __maxSize__": "La taille doit être inférieure à __maxSize__",
  "Skin Swab": "Prélèvement cutané",
  "Slovakia": "Slovaquie",
  "Slovenia": "Slovénie",
  "Solomon Islands": "Îles Salomon",
  "Somalia": "Somalie",
  "Sorry, DataView is not enabled, or you do not have access to this organization": "Désolé, DataView n’est pas activé, ou vous n’avez pas accès à cette organisation",
  "South Africa": "Afrique du Sud",
  "Space": "Espace",
  "Spain": "Espagne",
  "Spanish": "Espagnol",
  "Spanish (US)": "Espagnol (États-Unis)",
  "Sputum": "Crachat",
  "Sri Lanka": "Sri Lanka",
  "Su": "Di",
  "Sudan": "Soudan",
  "Suriname": "Suriname",
  "Swaziland": "Swaziland",
  "Sweden": "Suède",
  "Swedish": "Suédois",
  "Switzerland": "Suisse",
  "Syrian Arab Republic(Syria)": "République arabe syrienne (Syrie)",
  "São Tomé and Príncipe": "São Tomé et Príncipe",
  "Taiwan, Republic of China": "Taïwan, République de Chine",
  "Tajikistan": "Tadjikistan",
  "Tanzania, United Republic of": "Tanzanie, République-Unie de",
  "Temperature Error": "Erreur de température",
  "Test Cancelled": "Test annulé",
  "Test Lot Not Permitted": "Lot de test non autorisé",
  "Test Operation Error": "Erreur de fonctionnement du test",
  "Test Strip Lot Expired": "Lot de carte microfluidique expiré",
  "Test Strip Not Supported": "Carte microfluidique non compatible",
  "Test Strip Reading Error": "Erreur de lecture",
  "Test Timeout": "Délai de test dépassé",
  "Test Type Not Permitted": "Type de test non autorisé",
  "Th": "Je",
  "Thailand": "Thaïlande",
  "The new password validation code will be sent to the email, if email is a valid user email.": "Le code de validation du nouveau mot de passe sera envoyé par courrier électronique, si l’adresse électronique de l'utilisateur est valide.",
  "The reset validation code was populated from the link and should not be changed.": "Le code de validation de la réinitialisation a été rempli à partir du lien et ne doit pas être modifié.",
  "There are unsaved changes. How do you want to proceed?": "Il y a des modifications non enregistrées. Comment voulez-vous procéder ?",
  "There is a new version available (__versionNumber__), would you like to install it now?": "Une nouvelle version est disponible (__versionNumber__), souhaitez-vous l’installer maintenant ?",
  "This device is no longer acting as a Connect Hub. To continue using this device as a Connect Hub, please re-enable bluetooth in your device settings and turn back on Use this Mobile Device as a Connect Hub from the Hubs screen.": "Cet appareil ne fonctionne plus comme un Connect Hub. Pour continuer à utiliser cet appareil en tant que Connect Hub, veuillez réactiver le Bluetooth dans les paramètres de votre appareil et réactiver 'Utiliser cet appareil mobile comme un Connect Hub' sur l’écran Hubs.",
  "This version of LumiraDx Connect is no longer compatible with your organization. Please go to __storeName__ and update to the latest version.": "Cette version de LumiraDx Connect n’est plus compatible avec votre organisation. Veuillez accéder à __storeName__ et passer à la version la plus récente.",
  "Throat Swab": "Prélèvement de gorge",
  "Timor - Leste": "Timor - Oriental",
  "Title": "Civilité",
  "TN Swab": "Écouvillon PN",
  "to": "au",
  "To continue using __APP_NAME__, please upgrade to the latest version (__versionNumber__).": "Pour continuer à utiliser __APP_NAME__, veuillez passer à la version la plus récente (__versionNumber__).",
  "Togo": "Togo",
  "Tokelau": "Tokelau",
  "Tonga": "Tonga",
  "Trinidad and Tobago": "Trinité-et-Tobago",
  "Tu": "Ma",
  "Tunisia": "Tunisie",
  "Turkey": "Turquie",
  "Turkmenistan": "Turkménistan",
  "Turks and Caicos Islands": "îles Turques-et-Caïques",
  "Tuvalu": "Tuvalu",
  "Uganda": "Ouganda",
  "Ukraine": "Ukraine",
  "Unable to reset password, the provided current password is not valid.": "Impossible de réinitialiser le mot de passe, le mot de passe actuel saisi n'est pas valide.",
  "Unable to reset your password.": "Impossible de réinitialiser votre mot de passe.",
  "Unable to validate access code.": "Impossible de valider le code d’accès.",
  "United Arab Emirates": "Émirats Arabes Unis",
  "United Kingdom": "Royaume-Uni",
  "United States of America": "États-Unis",
  "Unrecognized credentials. Please try again.": "Informations d’identification non reconnues. Veuillez réessayer.",
  "Unsaved Changes": "Modifications non enregistrées",
  "Update Available": "Mise à jour disponible",
  "Update Password": "Mettre à jour le mot de passe",
  "Urethral Swab": "Prélèvement urétral",
  "Urine": "Urine",
  "Uruguay": "Uruguay",
  "User account is locked. Please try again in an hour.": "Compte utilisateur verrouillé. Veuillez réessayer dans une heure.",
  "User Interface Version": "Version de l’interface utilisateur",
  "User logged out due to inactivity.": "L’utilisateur a été déconnecté suite à une période d’inactivité.",
  "Username": "Nom d’utilisateur",
  "Uzbekistan": "Ouzbékistan",
  "Vaginal Swab": "Prélèvement vaginal",
  "Validate Access Code": "Valider le code d’accès",
  "Validation Code": "Code de validation",
  "Validation Code is required.": "Code de validation requis.",
  "Vanuatu": "Vanuatu",
  "Venezuela(Bolivarian Republic o": "Venezuela (République bolivarienne du)",
  "Venous Blood": "Sang veineux",
  "Version": "Version",
  "Viet Nam": "Vietnam",
  "Virgin Islands, British": "Îles Vierges britanniques",
  "Virgin Islands, US": "Îles Vierges, États-Unis",
  "VTM": "VTM",
  "Vulvo Vaginal Swab": "Prélèvement vulvo-vaginal",
  "Wallis and Futuna Islands": "Iles Wallis et Futuna",
  "We": "Me",
  "We are working to get you back up and running as quickly as possible.": "Nous mettons tout en œuvre pour que vous soyez à nouveau opérationnel le plus rapidement possible.",
  "We're sorry, LumiraDx Connect is currently down for maintenance.": "Nous sommes désolés, LumiraDx Connect est actuellement indisponible pour cause de maintenance.",
  "Yemen": "Yémen",
  "You have been signed out due to a password reset attempt.": "Vous avez été déconnecté suite à une tentative de réinitialisation de mot de passe.",
  "You have been signed out due to the creation of another session with the same credentials.": "Vous avez été déconnecté en raison de la création d'une autre session avec les mêmes informations d'identification.",
  "You have been signed out due to your account being disabled by an admin.": "Vous avez été déconnecté parce que votre compte a été désactivé par un administrateur.",
  "You have been signed out due to your password being changed by an admin.": "Vous avez été déconnecté parce que votre mot de passe a été modifié par un administrateur.",
  "You have been signed out due to your user role being changed by an admin.": "Vous avez été déconnecté parce que votre rôle d'utilisateur a été modifié par un administrateur.",
  "You have been signed out.": "Vous avez été déconnecté.",
  "You need to install a different version (__versionNumber__) of __APP_NAME__ to be compatible with your organization.": "Vous devez installer une version différente (__versionNumber__) de __APP_NAME__ pour assurer la compatibilité avec votre organisation.",
  "Your password has expired. Please set a new password.": "Votre mot de passe a expiré. Veuillez définir un nouveau mot de passe.",
  "Your session has expired. Please login again.": "Votre session a expiré. Veuillez vous connecter à nouveau.",
  "Your session has expired. User is not authorized.": "Votre session a expiré. Utilisateur non autorisé.",
  "Zambia": "Zambie",
  "Zimbabwe": "Zimbabwe"
};
