import * as React from 'react';
import * as moment from 'moment';
import { IComponent } from '../../global/interfaces';
import { inject, observer } from 'mobx-react';
import { chooseModuleVersion } from '../../global/helpers';
import * as coerce from 'semver/functions/coerce';

const styles = require('./styles/BuildInfo.styl');

@inject('loggedInUserStore', 'applicationStore') @observer
export default class BuildInfo extends React.Component<IComponent, any> {

  public render(): JSX.Element {

    const { availableModules, modulesById } = this.props.applicationStore;

    const home = availableModules.find((m) => m.id === 'home');

    const moduleBI = [];

    // UI CORE
    const nbi = '(no build info)';
    const { bt = nbi, brch = 'true', jv = nbi, nmbr = nbi } = (window as any)['ui-core-BI'] || {} as any;

    moduleBI.push(<div key={'core'} className={styles.ModuleHeader}>ui core</div>);
    moduleBI.push((
      <div key={'core-headers'} className={styles.BuildRow}>
        <div className={styles.HeaderItem}>Version</div>
        <div className={styles.HeaderItem}>Build Number</div>
        <div className={styles.HeaderItem}>Build Time</div>
      </div>
    ));
    moduleBI.push((
      <div key={'core-data'} className={styles.BuildRow}>
        <div className={styles.RowItem}>{`${jv} ${brch === 'true' ? '' : ` (${brch})`}`}</div>
        <div className={styles.RowItem}>{nmbr}</div>
        <div className={styles.RowItem}>{bt !== nbi ? moment(+bt).format('ddd MMM Do [at] h:mmA [(your local time)]') : '(no build info)'}</div>
      </div>
    ));

    if (home) {
      const { children, availableVersions } = home;
      // put available version numbers in an array
      const availVersions = Array.from(availableVersions, (v) => v[0]);
      // get accessDto version with Major, Minor and Patch number
      const { accessDto } = this.props.loggedInUserStore;
      const coerceAccessDtoVersion = (accessDto && accessDto.Version) ? coerce(accessDto.Version) : null;

      // MODULES
      for (const { id, availableVersions } of children) {
        moduleBI.push(<div key={`${id}-header`} className={styles.ModuleHeader}>{id}</div>);
        moduleBI.push((
          <div key={`${id}-BuildRow`} className={styles.BuildRow}>
            <div className={styles.HeaderItem}>Version</div>
            <div className={styles.HeaderItem}>Build Number</div>
            <div className={styles.HeaderItem}>Build Time</div>
          </div>
        ));
        // if factory just show the latest version
        if (id === 'factory') {
          const factoryVersion = availableVersions[0];
          const { id, build, buildTime, jenkinsVersion, version, branch } = factoryVersion[1];
          moduleBI.push((
            <div key={`${id}-${version}-data`} className={styles.BuildRow}>
              <div className={styles.RowItem}>{`${process.env.NODE_ENV === 'production' ? jenkinsVersion : version} ${branch === true ? '' : ` (${branch})`}`}</div>
              <div className={styles.RowItem}>{build}</div>
              <div className={styles.RowItem}>{moment(new Date(buildTime)).format('ddd MMM Do [at] h:mmA [(your local time)]')}</div>
            </div>
          ));
        }
        // else loop over availableVersions - highlight the version that is being used
        else {
          const defaultVersion = coerceAccessDtoVersion ? chooseModuleVersion(coerceAccessDtoVersion, availVersions, modulesById.get(id).version) : null;

          for (const v of availableVersions) {
            const { id, build, buildTime, jenkinsVersion, version, branch } = v[1];

            // highlight the build the org is using
            let rowClass = `${styles.BuildRow}`;
            if (defaultVersion === version) {
              rowClass += ` ${styles.Highlight}`;
            }

            moduleBI.push((
              <div key={`${id}-${version}-data`} className={rowClass}>
                <div className={styles.RowItem}>{`${process.env.NODE_ENV === 'production' ? jenkinsVersion : version} ${branch === true ? '' : ` (${branch})`}`}</div>
                <div className={styles.RowItem}>{build}</div>
                <div className={styles.RowItem}>{moment(new Date(buildTime)).format('ddd MMM Do [at] h:mmA [(your local time)]')}</div>
              </div>
            ));
          }
        }
      }

      // HOME
      const { build, buildTime, branch, jenkinsVersion, version } = home;
      moduleBI.push(<div key={'home'} className={styles.ModuleHeader}>home</div>);
      moduleBI.push((
        <div key={'home-headers'} className={styles.BuildRow}>
          <div className={styles.HeaderItem}>Version</div>
          <div className={styles.HeaderItem}>Build Number</div>
          <div className={styles.HeaderItem}>Build Time</div>
        </div>
      ));
      moduleBI.push((
        <div key={'home-data'} className={styles.BuildRow}>
          <div className={styles.RowItem}>{`${process.env.NODE_ENV === 'production' ? jenkinsVersion : version} ${branch === true ? '' : ` (${branch})`}`}</div>
          <div className={styles.RowItem}>{build}</div>
          <div className={styles.RowItem}>{moment(new Date(buildTime)).format('ddd MMM Do [at] h:mmA [(your local time)]')}</div>
        </div>
      ));

    }

    return (
      <div className={`${styles.BuildContainer} -ldx-lineup-content`}>
        {moduleBI}
      </div>
    );
  }

}
