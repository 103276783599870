import { OutgoingHttpHeaders } from 'http';

export enum Auth {
  NONE,
  TOKEN,
  TOKEN_BEARER,
  BASIC,
  BASIC_HEADERS,
}

export enum Http {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  OPTIONS = 'OPTIONS'
}

export enum ContentType {
  TEXT = 'text/plain',
  JSON = 'application/json',
  PDF = 'application/pdf',
  FORM_DATA = 'multipart/form-data',
  URL_ENCODED = 'application/x-www-form-urlencoded',
  OCTET_STREAM = 'application/octet-stream',
  AUTO = 'auto'
}

export enum ResponseType {
  ARRAY_BUFFER = 'arrayBuffer',
  BLOB = 'blob',
  FORM_DATA = 'formData',
  JSON = 'json',
  TEXT = 'text'
}

export enum RequestMode {
  CORS = 'cors',
  NO_CORS = 'no-cors',
  SAME_ORIGIN = 'same-origin',
  NAVIGATE = 'navigate'
}

export enum ErrorStyles {
  REGULAR,
  QUANTUM
}

export interface IServerRequest {
  /**
   * @param url the URI address to be targeted.
   */
  url?: string;
  /**
   * @param prefix universal prefix for all requests.
   */
  prefix?: string;
  /**
   * @param headers HTTP headers.
   */
  headers?: OutgoingHttpHeaders;
  /**
   * @param overwriteHeaders Passed `headers` object is treated as a one-time value, ignoring defaults set with `configure()`. Custom headers set by properties (`ContentType`, `X-Target`, `Authorization`) will still be appended to the headers object.
   */
  overwriteHeaders?: boolean;
  /**
   * @param body HTTP body content. `ContentType.JSON` and encoded with `JSON.stringify` by default.
   * `ContentType.FORM_DATA` content is converted to `FormData` instance.
   */
  body?: string | object | FormData | Map<string | number, string | number | File | FileList>;
  /**
   * @param query Query parameters applied to URI. Will be automatically encoded with `encodeURIComponent`.
   */
  query?: any;
  /**
   * @param abort Instance of `AbortController`. Used to programmatically cancel fetches from another context.
   */
  abort?: AbortController;
  /**
   * @param auth Authentication strategy. Supports `Auth` enum types.
   */
  auth?: Auth;
  /**
   * @param method HTTP verbs. Supports `Http` enum types.
   */
  method?: Http;
  /**
   * @param contentType Defines the content type of the request body. Supports `ContentType` enum types.
   */
  contentType?: ContentType;
  /**
   * @param responseType Defines how the response body should be parsed. Supports `ResponseType` enum types.
   */
  responseType?: ResponseType;
  /**
   * @param downloadFile File name and extension for downloaded files from binary responses. Uses a `ResponseType = BLOB`.
   */
  downloadFile?: string | boolean;
  /**
   * @param createFileName Optionally Used in conjunction with downloadFile, gets passed the Content-Disposition header and should return the desired file name
   */
  createFileName?: (string) => string;
  /**
   * @param username the username used for Basic Authentication
   */
  username?: string;
  /**
   * @param password the password used for Basic Authentication
   */
  password?: string;
  /**
   * @param token server Authentication Token used to authenticate most requests
   */
  token?: string;
  /**
   * @param target Target server ID for which APIs to route. Sets the `X-Target` header to be used for call proxying
   */
  target?: string;
  /**
   * @param worker Providing a Web Worker constructor will execute fetches in a separate thread from the main thread. Each fetch will create a new instance of this class as a context for execution.
   */
  worker?: any;
  /**
   * @param mode mode of the request that determines how cross-origin requests are handled
   */
  mode?: RequestMode;
  /**
   * @param handleErrorResponse will be passed the response code and any json error payload, and can handle any necessary side effects. Intended to be used as a global config for handling certain error the same way for all app (eg logout on 401)
   */
  handleErrorResponse?: (status: number, err?: any) => void;
  /**
   * @param errorStyle
   */
  errorStyle?: ErrorStyles;
  /**
   * @param cacheBust Defaults to true. Will append additional query param with timestamp for cache busting.
   */
  cacheBust?: boolean;
  /**
   * @param sendEmptyQueries Defaults to false. When true, will send query param even if they are an empty string
   */
  sendEmptyQueries?: boolean;
}

export class ServerRequest {
  public configuration: IServerRequest;

  public constructor(config: IServerRequest = {}) {
    this.configuration = config;
  }

  /**
   * One-time configuration for the ServerRequest class to setup default properties for each call. Supports the same options as the verb methods.
   * @param configuration `IServerRequest` properties supported.
   */
  public configure(configuration: IServerRequest): IServerRequest {
    return this.configuration = {
      ...this.configuration,
      ...configuration,
    };
  }

  /**
   * Used to check if the configuration option is set or not
   * @param prop - the property name to check
   */
  public configOptionIsSet = (prop: string): boolean => {
    return this.configuration[prop] != null;
  };

  /**
   * HTTP GET verb method.
   * @param options `IServerRequest` properties supported.
   */
  public get<T>(options: IServerRequest): Promise<T> {
    options.method = Http.GET;
    return this.buildRequest<T>(options);
  }

  /**
   * HTTP POST verb method.
   * @param options `IServerRequest` properties supported.
   */
  public post<T>(options: IServerRequest): Promise<T> {
    options.method = Http.POST;
    return this.buildRequest<T>(options);
  }

  /**
   * HTTP PUT verb method.
   * @param options `IServerRequest` properties supported.
   */
  public put<T>(options: IServerRequest): Promise<T> {
    options.method = Http.PUT;
    return this.buildRequest<T>(options);
  }

  /**
   * HTTP DELETE verb method.
   * @param options `IServerRequest` properties supported.
   */
  public delete<T>(options: IServerRequest): Promise<T> {
    options.method = Http.DELETE;
    return this.buildRequest<T>(options);
  }

  /**
   * HTTP PATCH verb method.
   * @param options `IServerRequest` properties supported.
   */
  public patch<T>(options: IServerRequest): Promise<T> {
    options.method = Http.PATCH;
    return this.buildRequest<T>(options);
  }

  /**
   * HTTP OPTIONS verb method.
   * @param options `IServerRequest` properties supported.
   */
  public options<T>(options: IServerRequest): Promise<T> {
    options.method = Http.OPTIONS;
    return this.buildRequest<T>(options);
  }

  private buildRequest<T>(options: IServerRequest): Promise<T> {
    const opts: IServerRequest = { ...this.configuration, ...options };
    const {
      prefix = '',
      url,
      body,
      headers,
      auth = Auth.TOKEN,
      method,
      abort,
      query,
      contentType = ContentType.JSON,
      responseType = ResponseType.JSON,
      downloadFile,
      createFileName,
      username,
      password,
      token,
      target,
      worker,
      mode,
      overwriteHeaders,
      handleErrorResponse,
      errorStyle = ErrorStyles.REGULAR,
      cacheBust = true,
    } = opts;
    const cfg: any = {
      errorStyle,
      responseType,
      headers: overwriteHeaders ? headers : { ...this.configuration.headers, ...headers },
      handleErrorResponse: handleErrorResponse,
    };

    // Add custom headers
    if (options.contentType !== ContentType.AUTO) {
      cfg.headers['Content-Type'] = contentType;
    }
    // Auto content type will let fetch decide
    else {
      delete cfg.headers['Content-Type'];
    }

    if (target != null) {
      cfg.headers['X-Target'] = target;
    }

    // Process the body before sending the request
    if (body != null) {
      switch (contentType) {
        case ContentType.JSON:
          // Add a stringified JSON body
          try {
            cfg.body = JSON.stringify(body, (name, val) => {
              if (typeof val === 'string') {
                return val.replace('’', '\'');
              }
              return val;
            });
          }
          catch (e) {
            cfg.body = '';
          }
          break;

        case ContentType.OCTET_STREAM:
        case ContentType.AUTO:
        case ContentType.TEXT:
          cfg.body = body;
          break;
        case ContentType.URL_ENCODED:
          cfg.body = body;
          break;
        case ContentType.FORM_DATA:
          cfg.body = this.processFormData(body);
          break;
      }
    }

    // Set the method
    cfg.method = method;

    // Set the mode
    cfg.mode = mode;

    // Set the url
    cfg.url = url;

    // Add cache busting parameter
    if (cacheBust) {
      cfg.query = {
        ...query,
        _: `${new Date().getTime()}`,
      };
    }

    // Add any query parameters
    if (cfg.query != null) {
      cfg.url += this.parseQueryParameters(cfg.query, opts);
    }

    const targetUrl = `${prefix}${cfg.url}`;

    // Authorization strategy
    switch (auth) {
      case Auth.BASIC:
        if (username == null || password == null) {
          throw new Error('Unable to use Basic Authentication because username and/or password have not been configured using ServerRequest.configure()');
        }
        cfg.headers.Authorization = `Basic ${window.btoa(`${username}:${password}`)}`;
        break;
      case Auth.BASIC_HEADERS:
        if (username == null || password == null) {
          throw new Error('Unable to use Basic Authentication Headers because username and/or password have not been configured using ServerRequest.configure()');
        }
        cfg.headers.username = username;
        cfg.headers.password = password;
        break;
      case Auth.TOKEN:
        if (token != null) {
          cfg.headers.authToken = token;
        }
        break;
      case Auth.TOKEN_BEARER:
        if (token != null) {
          cfg.headers.Authorization = `Bearer ${token}`;
        }
        break;
    }

    // Set the filename for a binary download
    cfg.downloadFile = downloadFile;
    cfg.createFileName = createFileName;

    // Configure the abort controller signal
    if (abort != null) {
      cfg.signal = abort.signal;
    }

    // A Web Worker will be created and the fetch executed in a new thread, instead of the main thread
    // Binary downloads will always be performed in the main thread
    if (worker != null && (cfg != null && !downloadFile)) {
      const wrk = new worker();
      const wcfg = { ...cfg, ...{ worker: null } };

      return new Promise((resolve, reject) => {
        wrk.addEventListener('message', (e) => {
          switch (e.data.event) {
            case 'error':
              reject(e.data.content);
              break;
            case 'response':
              resolve(e.data.content);
              break;
          }
        });

        wrk.postMessage({
          targetUrl: targetUrl,
          targetConfig: cfg,
          configuration: wcfg,
        });
      });
    }

    return this.performFetch<T>(targetUrl, cfg);
  }

  /**
   * All-purpose fetch method
   * @param targetUrl Target URL string with added prefix
   * @param options `IServerRequest` properties supported.
   */
  public performFetch<T>(targetUrl: string, options: IServerRequest): Promise<T> {
    const { responseType, downloadFile, createFileName, handleErrorResponse, errorStyle } = options;

    // If downloadFile is passed, force a blob response type
    options.responseType = downloadFile ? ResponseType.BLOB : responseType;

    return fetch(targetUrl, options as any)
      .catch(err => {
        throw err;
      })
      .then((res: Response) => {
        // For No Content, don't parse anything
        if (res.status === 204) {
          return res as any;
        }
        // Handle error responses by parsing the JSON
        else if (!res.ok) {
          const resClone = res.clone();
          return res.json()
            .catch((err) => {
              if (typeof handleErrorResponse === 'function') {
                handleErrorResponse(res.status, resClone);
              }
              return Promise.reject({
                message: err,
                status: +res.status,
              });
            })
            .then(err => {
              if (typeof handleErrorResponse === 'function') {
                handleErrorResponse(res.status, err);
              }
              return Promise.reject({
                message: err,
                status: +res.status,
              });
            });
        }
        // Use the responseType to figure out which response parser to use
        return res[options.responseType]()
          .catch(err => {
            throw err;
          })
          .then((data) => {
            // Download binary data
            if (downloadFile != null) {
              this.downloadBinaryAsFile(data, downloadFile as string, createFileName?.(res.headers.get('Content-Disposition')));
            }
            // This is a special case for the Connect/Factory APIs
            // Errors return as 200s w/ an Errored boolean
            // Treat a response like this as if the call failed, and send back the ErrorMessage
            if (errorStyle === ErrorStyles.QUANTUM && options.responseType === ResponseType.JSON && data.Errored === true) {
              return Promise.reject({
                message: data,
                status: +res.status,
              });
            }
            else {
              return data;
            }
          });
      });
  }

  private processFormData(body: any): FormData {
    // FormData object can be passed
    if (body instanceof FormData) {
      return body;
    }
    // Create a Map from a plain object
    else if (body != null && typeof body === 'object' && !Array.isArray(body) && !(body instanceof Map)) {
      body = new Map(Object.entries(body));
    }
    // Throw an error
    else if (!(body instanceof Map)) {
      throw new TypeError('Invalid data type. When submitting FormData, the body parameter must be Object, FormData, or Map');
    }

    const formData = new FormData();
    body.forEach((value, key) => {
      let name = key;

      // Change the name for selected files
      if (value instanceof File || value instanceof FileList) {
        name = 'files[]';
      }
      // If multiple files are passed, attach them all
      if (value instanceof FileList) {
        Array.from(value).forEach(sval => {
          formData.append(name, sval);
        });
      }
      // Otherwise, attach the single file / value
      else {
        formData.append(name, value);
      }
    });
    return formData;
  }

  private parseQueryParameters(query: any, opts?: IServerRequest): string {
    const { sendEmptyQueries } = opts;
    let output = '';
    for (const k in query) {
      if (Object.prototype.hasOwnProperty.call(query, k) && query[k] != null && (sendEmptyQueries || query[k] !== '')) {
        // For the first query param, add a question mark
        if (output === '') {
          output += '?';
        }
        // For 2-n, add an ampersand
        else {
          output += '&';
        }

        // Add the key=value
        output += `${k}=${encodeURIComponent(query[k])}`;
      }
    }

    return output;
  }

  private downloadBinaryAsFile(res: Response, filename: string, generatedFileName?: string): void {
    const fname = (generatedFileName || filename).substring(0, 255);
    const link = URL.createObjectURL(res as any);

    // create a link to click it programmatically
    const a = document.createElement('a');

    // Modern browsers
    // IE10+ support. Download links aren't supported before EDGE 13
    if ((navigator as any).msSaveOrOpenBlob != null) {
      (navigator as any).msSaveOrOpenBlob(res, fname);
    }
    else if (typeof a !== undefined) {
      a.setAttribute('href', link);
      a.setAttribute('download', fname);
      document.body.appendChild(a);
      a.click();

      // Remove the link after click
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(link);
      }, 10);
    }
    // Safari support. Use window.location.href for fallback. Custom file names isn't supported in Safari
    else {
      const fr = new FileReader();
      fr.onloadend = (e) => {
        let { result } = e.target as any;
        result = result.replace(/^data:[^;]*;/, 'data:attachment/file;');
        window.location.href = result;
      };

      // Read the binary data and convert it to a data URL
      fr.readAsDataURL(res as any);
    }
  }
}

export const Req = new ServerRequest();
