import * as React from 'react';
import Input from './Input';
import MultiSelectScroll, { IMultiSelectProps } from './MultiSelectScroll';

export default class MultiSelectInputScroll extends React.Component<IMultiSelectProps, any> {
  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <MultiSelectScroll {...this.props} {...props}  />
          );
        }}
      </Input>
    );
  }
}
