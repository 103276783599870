import * as React from 'react';
import Overlay from './Overlay';

const styles = require('./styles/drawer.styl');

export interface IDrawerContext {
  changeDrawerTitle?: (title: string) => void;
  drawerTitle?: string;
  short?: boolean;
  noBackdrop?: boolean;
  setShort?: (short: boolean) => void;
  closeDrawer?: () => void;
}

export const DrawerContext = React.createContext<IDrawerContext>({
  changeDrawerTitle: undefined,
  drawerTitle: '',
  short: false,
  noBackdrop: false,
  setShort: undefined,
  closeDrawer: undefined,
});

interface IProps {
  title?: string;
  close: () => void;
  noPadding?: boolean;
  titleClass?: string;
  short?: boolean;
  shorter?: boolean;
  noBackdrop?: boolean;
  scaleOthers?: boolean;
  hideClose?: boolean;
  fireCloseImmediately?: boolean;
  onClose: () => void;
  modifierClass?: string;
}

interface IState {
  startClose: boolean;
  title: string;
}

export default class Drawer extends React.Component<IProps, IState> {
  private el: React.RefObject<HTMLDivElement>;
  private contentEl: React.RefObject<HTMLDivElement>;
  private isPhone: boolean;

  public static defaultProps: Partial<IProps> = {
    titleClass: null,
    scaleOthers: true,
    short: false,
    shorter: false,
    noBackdrop: false,
  };

  constructor(props) {
    super(props);
    this.el = React.createRef();
    this.contentEl = React.createRef();
    this.isPhone = window.matchMedia?.('(max-width: 767px)').matches;

    this.state = {
      startClose: false,
      title: props.title,
    };
  }

  public componentDidMount() {
    if (this.isPhone && this.props.scaleOthers) {
      this.scaleOthers(0.91);
    }
  }

  public componentWillUnmount() {
    const { close } = this.props;
    const { startClose } = this.state;
    this.el.current?.removeEventListener('animationend', close, false);

    // In case close was initiated externally
    if (!startClose) {
      this.scaleOthers(1);
    }
  }

  public componentDidUpdate(prevProps) {
    const { short, scaleOthers  } = this.props;

    if (prevProps.short && !short && scaleOthers && this.isPhone) {
      this.scaleOthers(0.91);
    }
  }

  public render(): JSX.Element {
    const { close, children, noPadding, titleClass, hideClose, short, shorter, noBackdrop, onClose, modifierClass} = this.props;
    const { startClose, title } = this.state;

    let className = `${styles.Drawer}${modifierClass ? ` ${modifierClass}` : ''} ${styles.DrawerEnter}`;
    if (startClose) { className += ` ${styles.DrawerExit}`; }
    if (noPadding) { className += ` ${styles.NoPadding}`; }
    if (short) { className += ` ${styles.Short}`; }
    if (shorter) { className += ` ${styles.Shorter}`; }

    return (
      <DrawerContext.Provider value={{
        changeDrawerTitle: this.changeDrawerTitle,
        drawerTitle: title,
        short,
        noBackdrop,
        closeDrawer: this.handleClose,
      }}>
        <Overlay close={close} backdrop={noBackdrop ? !noBackdrop : (!this.isPhone) || short || shorter} backdropClose={false}>
          <div ref={this.el} className={className}>
            {!hideClose && <button className={styles.CloseBtn} onClick={onClose ? onClose : this.handleClose} />}
            {title && <h3 className={titleClass}>{title}</h3>}
            <div ref={this.contentEl} className={styles.DrawerContent}>{children}</div>
          </div>
        </Overlay>
      </DrawerContext.Provider>
    );
  }

  public handleClose = (): void => {
    const { fireCloseImmediately, close } = this.props;

    if (!fireCloseImmediately) {
      this.el.current?.addEventListener('animationend', close, false);
    }
    else {
      close();
    }

    this.setState({ startClose: true });
    if (this.isPhone && this.props.scaleOthers) {
      this.scaleOthers(1);
    }
  };

  public changeDrawerTitle = (title: string): void => {
    this.setState({
      title,
    });
  };

  private scaleOthers = (scale): void => {
    const main = document.getElementById('app');
    const otherOverlays = Array.from(document.getElementById('overlays').querySelectorAll('div[class*="Overlay"]'))
      .filter((el) => el !== this.el.current.parentElement.parentElement);

    if (!otherOverlays.length) {
      main.style.transform = `scale(${scale}) translateZ(0)`;
      main.style.borderTopRightRadius = scale === 1 ? '0px' : '8px';
      main.style.borderTopLeftRadius = scale === 1 ? '0px' : '8px';
    }
    else {
      otherOverlays
        .forEach((el: HTMLElement) => {
          el.style.transform = `scale(${scale}) translateZ(0)`;
          el.style.borderTopRightRadius = scale === 1 ? '0px' : '8px';
          el.style.borderTopLeftRadius = scale === 1 ? '0px' : '8px';
        });

      if (scale === 1) {
        main.style.transform = `scale(${scale}) translateZ(0)`;
        main.style.borderTopRightRadius = scale === 1 ? '0px' : '8px';
        main.style.borderTopLeftRadius = scale === 1 ? '0px' : '8px';
      }
    }
  };
}
