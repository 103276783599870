import { Moment } from 'moment';
import FileInput from './FileInput';

export type InputValue = string | boolean | number | Moment | IFileInputValue | Date | any[];

export enum PvrDirections {
  BELOW = 'below',
  ABOVE = 'above',
  LEFT = 'left',
  RIGHT = 'right',
  AUTO = 'auto'
}

export interface IItem {
  label: string;
  info: string;
}

export interface IKeyboard {
  enterText?: string;
  inputName: string;
  maxLength?: number;
  inputPattern?: RegExp;
  modifyInput?: IKeyboardModifyInput;
  onChange?: (e: any) => void;
  disableKeys?: string[];
  isPassword?: boolean;
  specialActionDefaultOn?: boolean;
}

export interface IKeyboardModifyInput {
  modify: (e: string, t: string, c: number) => {newValue: string, newCurPos: number};
  addedChars: string[];
}

export interface IInputKeyboardProps extends IKeyboard {
  onChange: (value: InputValue, jsonPath?: string) => void;
}

export interface IBaseInputProps {
  /**
   * @param value Value of the input for controlled fields
   */
  value?: any;
  /**
   * @param onChange Method called on change
   */
  onChange?: (value: InputValue, jsonPath?: string) => void;
  /**
   * @param validation Either a method that returns an IValidationError object or an IValidationError or null if there are no errors
   */
  validation?: ((value: InputValue) => IValidationError | boolean) | IValidationError | boolean;
  /**
   * @param registerError Method called when validation fails, used by parent app to track erors in a form
   */
  registerError?: (options: IRegisterError) => void;
  /**
   * @param textTransform Receives the value of the user's input onChnage and returns a transformed value
   */
  textTransform?: (value: InputValue) => string;
  /**
   * @param onInput Duh.
   */
  onInput?: () => void;
  /**
   * @param onKeyDown Duh.
   */
  onKeyDown?: (e) => void;
  /**
   * @param onKeyUp Duh.
   */
  onKeyUp?: (e) => void;
  /**
   * @param onKeyPress Duh.
   */
  onKeyPress?: () => void;
  /**
   * @param onBlur Duh.
   */
  onBlur?: (e, kbProps?: IInputKeyboardProps) => void;
  /**
   * @param onFocus Duh.
   */
  onFocus?: (e, kbProps?: IInputKeyboardProps) => void;
  /**
   * @param onEnterKey Duh.
   */
  onEnterKey?: (e) => void;
  /**
   * @param delayedActionOnChange {action: method, interval: ms} - action called onChange after ms delay
   */
  delayedActionOnChange?: IDelayedAction;
  /**
   * @param style Inline style object aplied to the wrapper div
   */
  style?: any;
  /**
   * @param type Input type, defaults to text
   */
  type?: string;
  /**
   * @param name Input name attribute
   */
  name?: string;
  /**
   * @param jsonPath String passed as the 2nd argument to onChange, usually the json attribute name the field represents. Also applied to actual input ast data-test attribute.
   */
  jsonPath?: string;
  /**
   * @param className Class applied to the actual input
   */
  className?: string;
  /**
   * @param wrapperLabel If passed, a <label> will wrap the input with this string as the label's text
   */
  wrapperLabel?: string;
  /**
   * @param wrapperClass Class to be added to the wrapper div after the -ldx classes
   */
  wrapperClass?: string;
  /**
   * @param placeholder Input element's placeholder attribute
   */
  placeholder?: string;
  /**
   * @param id Input element's id attribute
   */
  id?: string;
  /**
   * @param autoComplete Input element's autoComplete attribute
   */
  autoComplete?: string;
  /**
   * @param showClear Show x button that calls props.clear when clicked
   */
  showClear?: boolean;
  /**
   * @param clearBtnClass Class that is applied to the x button
   */
  clearBtnClass?: string;
  /**
   * @param disabled Input element's disabled attribute
   */

  disabled?: boolean;
  /**
   * @param focusOnMount Will focus the input right after mount
   */
  focusOnMount?: boolean;
  /**
   * @param focusOnMountDelay Will delay the initial focus on mount in milliseconds
   */
  focusOnMountDelay?: number;
  /**
  * @param keepFocus Will keep the selected component on mount focused
  */
  keepFocus?: boolean;
  /**
   * @param selectOnMount Will select the input's text right after mount
   */
  selectOnMount?: boolean;
  /**
   * @param loading Will show a spinner when true
   */
  loading?: boolean;
  /**
   * @param loading Will pass null to onChange when the input is empty. Note: value will always default to empty string inside of render.
   */
  returnNull?: boolean;
  /**
   * @param isInPopover Deprecated in lineup, used to control the z-index of the validation error pvrs
   */
  isInPopover?: boolean;
  /**
   * @param spellCheck Input element's spellCheck attribute
   */
  spellCheck?: boolean;
  /**
   * @param forceShowAllErrors Will cause the red error icon indicating a validation issue to appear even before a change has ocurred
   */
  forceShowAllErrors?: boolean;
  /**
   * @param displayErrorPvr Will give us the ability to hide the error popover but still display the red error icon
   */
  displayErrorPvr?: boolean;
  /**
   * @param tabIndex Input element's tabIndex attribute
   */
  tabIndex?: number;
  /**
   * @param tabId Used in a tab form to associate validation errors with a specific tab
   */
  tabId?: number;
  /**
   * @param maxLength Input element's maxLength attribute
   */
  maxLength?: number;
  /**
   * @param step Affects number fields only. Input element's step attribute
   */
  step?: number;
  /**
   * @param min Affects number fields only. Input element's min attribute
   */
  min?: number;
  /**
   * @param max Affects number fields only. Input element's max attribute
   */
  max?: number | string;
  /**
   * @param flashErrorPvrs Incrementing this number causes the validation error pvr (if the input is invalid) to flash for flashDuration ms
   */
  flashErrorPvrs?: number;
  /**
   * @param flashDuration The amount of time the error pvrs show when flashErrorPvrs is icremented
   */
  flashDuration?: number;
  /**
   * @param flashErrors ldx-lineup internal use only. Passed by Input.tsx to child to show error pvrs for a period of time
   */
  flashErrors?: boolean;
  /**
   * @param getValue ldx-lineup internal use only. Passed by Input.tsx to child.
   */
  getValue?: () => any;
  /**
   * @param getValue ldx-lineup internal use only. Passed by Input.tsx to child.
   */
  validate?: (options: IValidateOptions) => IValidationError;
  /**
   * @param valueHasChanged ldx-lineup internal use only. Passed by Input.tsx to child.
   */
  valueHasChanged?: boolean;
  /**
   * @param getValue ldx-lineup internal use only. Passed by Input.tsx to child.
   */
  setTabErrorAnchors?: (options: ITabError) => ITabError;
  isFieldRequired?: boolean;
  /**
   * @param mobileValidation use mobile friendly validation style
   */
  mobileValidation?: boolean;
}

export interface ITabError {
  add?: boolean;
  anchors: any;
}

export interface IFileInputValue {
  files: FileList;
  name: string;
  maxSize: number;
  ref: FileInput;
}

export interface IRegisterError {
  inputId: string;
  error: IValidationError;
  remove?: boolean;
}

export interface IValidateOptions {
  value?: InputValue;
  validation: ((value: InputValue) => IValidationError | boolean) | IValidationError | boolean;
  anchor?: HTMLElement;
}

export interface IValidationError {
  messages: string[];
  direction?: PvrDirections;
  anchor?: HTMLElement;
}

export interface IDelayedAction {
  action: (value: string) => void;
  interval: number;
}

export interface IKeyboardKey {
  type: string;
  enabled: boolean;
}
