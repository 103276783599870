import * as React from 'react';
import { ApplicationStore, MobileBridgeStore } from '../stores';
import { IUser, IQUser, IConnectReport, ITestResult, IAnalyteResult } from './interfaces';
import { BridgeCommands, TestPurposes } from './enums';
import { API_DATE_FORMAT, ONLINE_THRESHOLDS, ERROR_CODES } from './constants';
import * as moment from 'moment';
import * as satisfies from 'semver/functions/satisfies';
import * as rsort from 'semver/functions/rsort';
import * as valid from 'semver/functions/valid';
import { languages } from './languages';
import { isHan } from '@scriptin/is-han';

/**
 * Returns a React Element created with directly injected HTML from the translation file
 * @param value string value
 * @param subs substitutions object
 * @param elementType HTML element string
 * @param className CSS class name - must be a global class
 */
export function tJSX(value: string, subs?: any, elementType: keyof JSX.IntrinsicElements = 'span', className: string = ''): JSX.Element {
  return React.createElement(elementType, {
    className,
    dangerouslySetInnerHTML: { __html: global.t(value, subs) },
  });
}

/**
 * Returns a string with unsubstituted keys for the selected locale
 * @param value translated string value
 */
export function getEnglishTranslation(value: string, caseSensitive: boolean = true): string {
  const { strings } = ApplicationStore || { strings: {} };
  if (strings == null || value == null) {
    return null;
  }

  const search = caseSensitive ?
    key => strings[key] === value :
    key => strings[key].toLowerCase() === value.toLowerCase();

  return Object.keys(strings).find(search);
}

/**
 * Returns a string with substituted keys for the selected locale
 * @param value string value
 * @param subs substitutions object
 */
export function t(value: string, subs?: any): string {
  const { strings } = ApplicationStore || { strings: {} };

  // Trim out spaces in the value
  value = value.trim ? value.trim() : `${value}`.trim();

  // Don't attempt the substitution when there aren't any strings loaded
  if (strings == null) {
    return value;
  }

  // Match the input to the translations
  let val: string = strings[value] == null ? value : strings[value];

  if (subs != null) {
    for (const s in subs) {
      if (Object.prototype.hasOwnProperty.call(subs, s)) {
        val = val.replace(`__${s}__`, subs[s]);
      }
    }
  }

  return val;
}

/**
 * Returns the appropriate colon for the language
 */
export function colon(): string {
  const { locale } = ApplicationStore;

  return languages.get(locale).colonFormat;

}

export const quantumUserMappings: Array<[keyof IUser, keyof IQUser]> = [
  ['id', 'Id'],
  ['username', 'UserName'],
  ['firstName', 'FirstName'],
  ['lastName', 'FamilyName'],
  ['initials', 'Initials'],
  ['accessLevelInstAdmin', 'AccessLevelInstAdmin'],
  ['accessLevelMeterAdmin', 'AccessLevelMeterAdmin'],
  ['accessLevelMeterOperator', 'AccessLevelMeterOperator'],
  ['accessLevelWgAdmin', 'AccessLevelWgAdmin'],
  ['workGroupApplicationUsers', 'WorkGroupApplicationUsers'],
  ['languageId', 'Language_Id'],
  ['email', 'Email'],
  ['salutation', 'Salutation'],
  ['status', 'Status'],
  ['code', 'Code'],
  ['newPassword', 'NewPassword'],
  ['currentPassword', 'CurrentPassword'],
  ['EnforceCompliance', 'EnforceCompliance'],
  ['ComplianceExpirationDate', 'ComplianceExpirationDate'],
  ['Role', 'Role'],
  ['NoEmailAccess', 'NoEmailAccess'],
  ['Password', 'Password'],
  ['ConfirmPassword', 'ConfirmPassword'],
  ['LastPasswordChangeDateUtc', 'LastPasswordChangeDateUtc'],
  ['PasswordExpirationDateUtc', 'PasswordExpirationDateUtc'],
  ['LastLoginUtc', 'LastLoginUtc'],
];

/**
 * Converts to or from a Quantum objects's mapped properties
 * @param dto `Phoenix | Quantum` DTO to convert between the types
 */
export function mappedQuantumObject<P, Q>(dto, mappings: Array<[keyof P, keyof Q]>): any {
  // If the object is a Quantum object, use the plain mappings
  const to = (dto as any).Id == null ? 1 : 0;

  // Build the converted object with the new mapping
  const output = {} as any;
  mappings.forEach(m => {
    const value = dto[to === 0 ? m[1] : m[0]];
    output[m[to]] = value;
  });

  return output;
}

export const getPasswordRuleSentences = (rule: string, value?: any) => {
  const passwordRuleSentences: Map<string, string> = new Map([
    ['MinLength', t('At least __value__ characters', { value: value })],
    ['MaxLength', t('No more than __value__ characters', { value: value })],
    ['ContainDigits', t('At least one number')],
    ['ContainLowerCase', t('At least one lowercase letter')],
    ['ContainNonLetterDigit', t('At least one of these special characters: __value__', { value: value })],
    ['ContainUpperCase', t('At least one uppercase letter')],
    ['DoesNotContainRepetition', t('No repetitive characters (e.g. aaa)')],
    ['DoesNotContainSequence', t('No sequential characters (e.g. 123)')],
    ['NotBlacklisted', t('No easily guessable words')],
    ['SupportedCharacters', t('Does not use unsupported characters')],
    ['PasswordReuse', t('Does not match your previous passwords')],
    ['PasswordsMatch', t('Confirm new password')],
  ]);

  return passwordRuleSentences.get(rule);
};

export const openExternalLink = (e: React.MouseEvent<HTMLAnchorElement>): void => {
  e.preventDefault();
  const { href } = e.currentTarget;

  // Call the bridge API method on mobile devices
  if (process.env.MOBILE_APP) {
    return MobileBridgeStore.tellMobileApp(BridgeCommands.OPEN_EXTERNAL_URL, href);
  }

  // Otherwise open a new tab
  window.open(href);
};

export const getQueryParams = (url: string): any => {
  const o = {};
  const u = url.split('?');

  // return if no params
  if (u[1] == null) {
    return o;
  }

  const p = u[1].split('&');
  p.forEach(param => {
    const [key, value] = param.split('=');
    o[key] = decodeURIComponent(value);
  });

  return o;
};

interface IIsOnline {
  AsOfServerTimeUtc: string;
  LastSeenDateUtc: string;
  KillSwitch?: boolean;
}

/**
 * Detirmines if an instrument or hub is online or offline
 * @param options - AsOfServerTimeUtc, LastSeenDateUtc, KillSwitch
 */
export const isOnline = ({ AsOfServerTimeUtc, LastSeenDateUtc, KillSwitch = false }: IIsOnline): boolean => {
  // Disabled instruments are not online
  if (KillSwitch) { return false; }

  const asOfServerTime = AsOfServerTimeUtc ? moment(AsOfServerTimeUtc, API_DATE_FORMAT) : moment().add(20, 'years');
  const lastSeen = moment(LastSeenDateUtc, API_DATE_FORMAT);

  // Never connected hubs send down really old dates, so consider them offline
  if (lastSeen.isBefore(moment('2014', 'YYYY'))) { return false; }

  // Instruments seen in the last 2 minutes are considered online
  // Anything else is offline
  return asOfServerTime.diff(lastSeen, 'minutes') < ONLINE_THRESHOLDS.HIGH ? true : false;
};

/**
 * Returns the appropriate offline status message
 * @param lastSeen - moment - ized LastSeenDateUtc
 * @param asOfServerTime - moment - ized AsOfServerTimeUtc
 * @param KillSwitch - defaults to false, an instrument's KillSwitch status
 */

export const buildStatusMessage = (lastSeen: moment.Moment, asOfServerTime: moment.Moment, KillSwitch: boolean = false): string => {
  const years = asOfServerTime.diff(lastSeen, 'years');

  if (KillSwitch === true) { return t('Disabled'); }

  if (lastSeen.isBefore(moment('2014', 'YYYY'))) { return t('Never Connected'); }

  if (years === 1) { return t('Offline 1 year'); }
  if (years > 1) { return t('Offline __years__ years', { years }); }

  const months = asOfServerTime.diff(lastSeen, 'months');
  if (months === 1) { return t('Offline 1 month'); }
  if (months > 1) { return t('Offline __months__ months', { months }); }

  const days = asOfServerTime.diff(lastSeen, 'days');
  if (days === 1) { return t('Offline 1 day'); }
  if (days > 1) { return t('Offline __days__ days', { days }); }

  const hours = asOfServerTime.diff(lastSeen, 'hours');
  if (hours === 1) { return t('Offline 1 hour'); }
  if (hours > 1) { return t('Offline __hours__ hours', { hours }); }

  const minutes = asOfServerTime.diff(lastSeen, 'minutes');
  if (minutes === 1) { return t('Offline 1 minute'); }
  if (minutes > 1) { return t('Offline __minutes__ minutes', { minutes }); }

  return 'Unknown';

};

(global as any).t = t;
(global as any).getEnglishTranslation = getEnglishTranslation;
(global as any).tJSX = tJSX;

export const chooseModuleVersion = (preferredVersion: string, versions: string[] = [], defaultVersion: string): string => {
  // Set the default version value from the server. If not present, use the default from the primary manifest.
  if (!preferredVersion || !versions?.length) {
    return defaultVersion;
  }

  // If there's no direct version match, go through each of the versions and find the one that is the best match
  for (const version of rsort(versions.filter(v => valid(v)))) {
    try {
      // If there isn't a match, check for a valid substitute
      if (satisfies(version, `~${preferredVersion}`)) {
        return version;
      }
    }
    // If the date is not valid, skip it
    catch (err) {
      continue;
    }
  }

  // If there is no compatible version found, just return the default
  return defaultVersion;
};

export const getCorrectAnalyte = (analyteResults: IAnalyteResult[] = [], measurementUniqueIdentifier?: number): IAnalyteResult => {
  const analyte = measurementUniqueIdentifier ? analyteResults.find(a => a.MeasurementUniqueIdentifier === measurementUniqueIdentifier) : analyteResults.find(a => a.DisplayPosition > 0);

  return analyte || {
    AnalyteId: 0,
    QuantitativeStandardValueText: '',
    IndicatedValueText: '',
    DataPayloadVersion: 2,
    StandardUnitsText: null,
    IndicatedUnitsText: null,
    ErrorCode: 0,
    AssayName: '',
  };
};

/**
 * Parses out the correct unit text from the result provided
 * @param result Either a connect report or analyte result
 * @param measurementUniqueIdentifier An identifier used to choose analyte results
 */
export const getResultUnits = (result: ITestResult | IConnectReport, measurementUniqueIdentifier?: number): string => {
  let ErrorCode: number;
  let analyte;

  // Analyte result
  if (Object.prototype.hasOwnProperty.call(result, 'Summary')) {
    ErrorCode = (result as ITestResult).Summary.ErrorCode;
    analyte = getCorrectAnalyte((result as ITestResult).AnalyteResults, measurementUniqueIdentifier);
  }
  // Connect report
  else {
    ErrorCode = (result as IConnectReport).SummaryErrorCode;
    analyte = result;
  }

  const { StandardUnitsText, IndicatedUnitsText, DataPayloadVersion } = analyte;

  // No errors, return the units
  if (ErrorCode === 0) {
    if (+DataPayloadVersion < 5) {
      return StandardUnitsText ? StandardUnitsText : null;
    }
    // In DataPayloadVersion 5, units to be displayed, regardless of type, moved to the Indicated Units Text field
    else {
      return IndicatedUnitsText ? IndicatedUnitsText : null;
    }
  }
  else {
    return null;
  }
};

/**
 * Returns the correct result for tha passed test and analyteId based on error codes, data payload version and QC status
 * @param result Either a connect report or an analyte result object
 * @param measurementUniqueIdentifier
 */
export const getResultText = (result: ITestResult | IConnectReport, measurementUniqueIdentifier?: number): string | number => {
  const errorText = t('Error');
  let ErrorCode: number;
  let TestPurpose: TestPurposes = TestPurposes.PATIENT;
  let analyte;

  // Test results
  if (Object.prototype.hasOwnProperty.call(result, 'Summary')) {
    analyte = getCorrectAnalyte((result as ITestResult).AnalyteResults, measurementUniqueIdentifier);
    ErrorCode = (result as ITestResult).Summary.ErrorCode;
    TestPurpose = (result as ITestResult).Summary.TestPurpose;
  }
  // Connect reports
  else {
    ErrorCode = (result as IConnectReport).SummaryErrorCode;
    TestPurpose = (result as IConnectReport).TestPurpose;
    analyte = result;
  }

  const { QuantitativeStandardValueText, IndicatedValueText, DataPayloadVersion, QualitativeResultText } = analyte;

  // No errors, return the message
  if (ErrorCode === 0) {
    if (+DataPayloadVersion < 5) {
      if (QualitativeResultText != null && QualitativeResultText.trim() !== '') {
        return QualitativeResultText;
      }
      else {
        return isNaN(+QuantitativeStandardValueText) ? QuantitativeStandardValueText : (+QuantitativeStandardValueText).toFixed(1);
      }
    }
    // In DataPayloadVersion 5, all result values to be displayed, regardless of type, moved to the Indicated Value Text field
    else {
      return IndicatedValueText;
    }
  }

  // Special case for 3503, which has two modes depending if QC or Patient test.
  if (ErrorCode === 3503 && TestPurpose !== TestPurposes.PATIENT) {
    const errCode = ERROR_CODES.get('3503_QC')();
    return `${errCode.msg} (${errorText} ${errCode.uiCode} - ${ErrorCode})`;
  }

  // Return the error code's message
  if (ERROR_CODES.has(`${ErrorCode}`)) {
    const errCode = ERROR_CODES.get(`${ErrorCode}`)();
    return `${errCode.msg} (${errorText} ${errCode.uiCode} - ${ErrorCode})`;
  }

  // No error matches, return unknown error code
  const errCode = ERROR_CODES.get('Unknown')();
  return `${errCode.msg} (${errorText} ${errCode.uiCode} - ${ErrorCode})`;
};

export const setElScale = (scale, el = document.getElementById('app')): void => {
  el.style.transform = `scale(${scale}) translateZ(0)`;
  el.style.borderTopRightRadius = scale === 1 ? '0px' : '8px';
  el.style.borderTopLeftRadius = scale === 1 ? '0px' : '8px';
};

/**
 * Utility to check a string for japanese
 * @param str - the string to check for japanese characters
 * @returns true if any japanese was detected (hanzi, kanji, hanja)
 */
export const containsJapanese = (str: string): boolean => {
  return Array.from(str).filter(isHan).length > 0;
};
