import * as React from 'react';
import Chevron from './Chevron';

export interface IAccordionProps {
  id?: string;
  header: string | JSX.Element;
  height?: number | string;
  useChevron?: boolean;
  className?: string;
  isOpenOnMount?: boolean;
}

export interface IAccordionState {
  isOpen: boolean;
}

const accordionStyles = require('./styles/accordion.styl');

/**
 * Accordion component with clickable headers and collapsible content
 * @param id An identifier to be set on the component
 * @param height Set a fixed height for CSS animations of content container. Default is 'auto'.
 * @param useChevron Renders a `Chevron` component inside the header
 * @param className CSS wrapper class to go around the entire element
 * @param isOpenOnMount Set whether the body is expanded on mount
 */
export default class Accordion extends React.Component<IAccordionProps, IAccordionState> {
  public static defaultProps: Partial<IAccordionProps> = {
    id: '',
    height: 'auto',
    useChevron: true,
    className: '',
    isOpenOnMount: false,
  };

  constructor(props: IAccordionProps) {
    super(props);
    this.state = {
      isOpen: props.isOpenOnMount,
    };
  }

  public render(): JSX.Element {
    const { id, header, height, children, useChevron, className } = this.props;
    const { isOpen } = this.state;
    const styles = {
      height: isOpen ? height : 0,
    };
    return (
      <div className={`${accordionStyles.Accordion} ${accordionStyles[className]}`} data-is-open={isOpen}>
        <header id={id} className={accordionStyles.Header} onClick={this.handleHeaderClick}>
          {header}
          {useChevron ? <Chevron isInverted={isOpen ? true : false} invertOnClick={true} onClick={this.handleHeaderClick} /> : null}
        </header>
        <div className={accordionStyles.Body} style={styles}>{children}</div>
      </div>
    );
  }

  public handleHeaderClick = (): void => {
    // Toggle the accordion
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };
}
