import * as React from 'react';

const styles = require('./styles/ios_toggle_switch.styl');

export interface IIOSToggleSwitchProps {
  checked: boolean;
  tabIndex?: number;
  disabled?: boolean;
  jsonPath?: string;
  title?: string;
  onChange: (val: boolean, jsonPath: string) => void;
}

class IOSToggleSwitch extends React.Component<IIOSToggleSwitchProps, any> {

  public static defaultProps = {
    tabIndex: -1,
    disabled: false,
  };

  public render(): JSX.Element {
    const { tabIndex, disabled, checked, jsonPath, title } = this.props;

    let className = styles.IosToggleSwitch;

    if (checked) { className += ` ${styles.IsChecked}`; }

    return (
      <button
        key="ios-toggle-switch"
        className={className}
        onClick={this.handleChange}
        tabIndex={tabIndex}
        disabled={disabled}
        data-test={jsonPath}
        id={jsonPath}
        title={title}
      >
        <label key="switch-label" className={styles.IosToggleSwitchLabel} htmlFor="myios-toggle-switch">
          <span key="switch-inner" className={styles.IosToggleSwitchInner}/>
          <span key="switch-switch" className={styles.IosToggleSwitchSwitch}/>
        </label>
      </button>
    );
  }

  public handleChange = (e: React.MouseEvent): void => {
    e.preventDefault();
    const {onChange, jsonPath, checked} = this.props;
    // switch the toggle
    onChange(!checked, jsonPath);
  };

}

export default IOSToggleSwitch;
