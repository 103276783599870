import * as React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { BridgeCommands } from '../../global/enums';
import amd from '../../helpers/amd';
import * as moment from 'moment';
const { importModule } = amd;

const styles = require('./styles/App.styl');
require('../../../../../node_modules/react-day-picker/lib/style.css');

// Models
import { LoggedInUserStore, ApplicationStore, SystemStore, MobileBridgeStore } from '../../stores';
import Home from './Home';
import { Login } from '..';
import MobileUiUpdate from './MobileUiUpdate';
import BluetoothWarn from './BluetoothWarn';
import ForceAppUpdate from './ForceAppUpdate';
import MobileUILoader from './MobileUILoader';
import { MOBILE_APPS, DOCUMENT_TITLE } from '../../global/constants';
import ToolsWrapper from './ToolsWrapper';
import AmiraWrapper from './AmiraWrapper';
import InstrumentWrapper from './InstrumentWrapper';
import OnPremWrapper from './OnPremWrapper';
import DebugLog from '../DebugLog/DebugLog';

// Construct the app state object
const appState = {
  loggedInUserStore: LoggedInUserStore,
  applicationStore: ApplicationStore,
  systemStore: SystemStore,
  mobileBridgeStore: MobileBridgeStore,
};

interface IState {
  appDidCatch: boolean;
  mountKey: number;
  showDebugLog: boolean;
}

class App extends React.Component<any, IState> {
  private tapCount: number = 0;
  private tapTimer;
  private userNavvedToTools: boolean = window.location.pathname?.toLowerCase().includes('tools');

  public state: IState = {
    appDidCatch: false,
    mountKey: 0,
    showDebugLog: false,
  };

  public componentDidMount(): void {

    const { history } = this.props;


    history.listen((location) => {
      if (location.pathname.startsWith('/home/connect')) {
        document.title = DOCUMENT_TITLE.has(location.pathname) ?
          DOCUMENT_TITLE.get(location.pathname)() :
          'LumiraDx Connect';

        DOCUMENT_TITLE.has(location.pathname) ? null : console.warn(`Update document title for ${location.pathname}`);
      }
    });

    if (this.userNavvedToTools) {
      history.replace('/tools');
    }

    if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      history.replace('/amira');

      history.listen(() => {
        // Combat an iOS issue where the keyboard from the previous screen has shifted the web view up, and it does not come back down
        // This results in a white box at the bottom of the screen.
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      });

    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.INSTRUMENT) {
      const { location: { pathname } } = history;

      history.replace(pathname === '/' ? '/instrument' : pathname);
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.ON_PREM) {
      const { location: { pathname } } = history;

      history.replace(pathname === '/' ? '/lmdx-local' : pathname);
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.CONNECT) {
      history.listen(() => {
        // Combat an iOS issue where the keyboard from the previous screen has shifted the web view up, and it does not come back down
        // This results in a white box at the bottom of the screen.
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 0);
      });
    }

    if (process.env.NODE_ENV === 'development' && process.env.HOT) {
      // Once the app mounts, listen for script changes from the WebSocket server
      const ws = new WebSocket('ws://localhost:8002');
      // On change, fire importModule to replace the existing module
      ws.onerror = (e) => {
        console.log(e);
      };

      ws.onmessage = (e) => {
        const { data: messageData } = e;
        const { data, type } = JSON.parse(messageData);
        const { path, event } = data;
        const moduleName = path.match(/LDX-Modules\/(\w+)/)[1];
        const { message } = data;
        switch (type) {
          case 'change':

            switch (event) {
              case 'source-edit':
                console.group();
                console.log(`%c [${moment().format('hh:mm:ss')}] 📝 SOURCE FILE CHANGED: ${path}`, 'background: #ebebeb; color: #444; padding: 5px; font-size: 12px;');
                console.log(`%c [${moment().format('hh:mm:ss')}] 🕓 COMPILING MODULE: %c${moduleName}`, 'background: #ebebeb; color: #444; padding: 5px; font-size: 12px;', 'background: #ebebeb; color: #444; padding: 5px; font-size: 12px; font-weight: bold');
                break;
              case 'compile-done':
                importModule(path, true)
                  .then(() => {
                    this.setState({
                      mountKey: this.state.mountKey + 1,
                    });
                    console.log(`%c [${moment().format('hh:mm:ss')}] 🔥 MODULE HOT LOADED: ${path}`, 'background: #d97f27; color: #fff; padding: 5px; font-size: 12px;');
                    console.groupEnd();
                  });
                break;
            }
            break;
          case 'message':
            if (Array.isArray(message)) {
              console.log.apply(this, message);
            }
            else {
              console.log(message);
            }
            break;
          case 'error':
            console.error(data);
            break;
        }
      };
    }
  }

  public componentDidCatch(error, info) {
    const { history } = this.props;

    MobileBridgeStore.tellMobileApp(BridgeCommands.J_LOG, `ReactApp: Component Errored: ${error}`);
    // Send a separate J_LOG for each line of the component stack message, to capture each line. Helps a lot with debugging....
    info.componentStack.split('\n').filter(ln => ln.trim() !== '').forEach(ln => MobileBridgeStore.tellMobileApp(BridgeCommands.J_LOG, `ReactApp: ${ln}`));

    LoggedInUserStore.setLoginMessage(t('Fatal Application Error'));
    if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      history.replace('/amira/login/auth');
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.ON_PREM) {
      history.replace('/lmdx-local/error');
    }
    else {
      history.replace('/login');
    }

  }

  public static getDerivedStateFromError() {
    return { appDidCatch: true };
  }

  public render(): JSX.Element {
    const { mountKey, showDebugLog } = this.state;

    const className = process.env.MOBILE_APP === MOBILE_APPS.AMIRA ? styles.MobileApp : styles.App;

    let routes;
    if (process.env.MOBILE_APP === MOBILE_APPS.AMIRA) {
      routes = <Route path={'/amira*'} render={(props) => <AmiraWrapper {...props} />} />;
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.INSTRUMENT) {
      routes = <Route path={'/instrument*'} render={(props) => <InstrumentWrapper {...props} />} />;
    }
    else if (process.env.MOBILE_APP === MOBILE_APPS.ON_PREM) {
      routes = <Route path={'/lmdx-local*'} render={(props) => <OnPremWrapper {...props} />} />;
    }
    else if (this.userNavvedToTools) {
      routes = <Route path={'/tools*'} render={(props) => <ToolsWrapper {...props} />} />;
    }
    else {
      routes = (
        <Switch>
          <Route exact path={'/(login|build|factory-switcher)?/(access-code|account-reset-password|expired-password-reset)?'} render={(props) => <Login {...props} history={this.props.history} />} />
          <Route path={'/:moduleId'} render={(props) => <Home {...props} />} />
        </Switch>
      );
    }

    return (
      <>
        {process.env.MOBILE_APP !== MOBILE_APPS.AMIRA ? <div className={styles.SafeAreaTop}/> : null}
        <div className={className} onClick={process.env.MOBILE_APP === MOBILE_APPS.CONNECT ? this.handleAppTap : null}>
          <Provider {...appState} key={mountKey}>
            <>
              {process.env.MOBILE_APP === MOBILE_APPS.CONNECT || process.env.MOBILE_APP === MOBILE_APPS.AMIRA
                ? (
                  <>
                    <MobileUiUpdate />
                    <ForceAppUpdate />
                    {process.env.MOBILE_APP === MOBILE_APPS.CONNECT ? <BluetoothWarn /> : null}
                  </>
                )
                : null}
              {process.env.MOBILE_APP && showDebugLog ? <DebugLog close={() => this.setState({ showDebugLog: false })}/> : null}
              {process.env.MOBILE_APP ? <Route path="/load-ui-build" render={(props) => <MobileUILoader {...props} />} /> : null}
              {routes}
            </>
          </Provider>
        </div>
      </>
    );
  }

  public handleAppTap = (e: React.MouseEvent): any => {
    if (!MobileBridgeStore.debugMobile) { return void 0; }

    clearTimeout(this.tapTimer);

    this.tapCount = this.tapCount + 1;

    if (this.tapCount === 5) {
      this.setState({ showDebugLog: true });
      return this.tapCount = 0;
    }

    this.tapTimer = setTimeout(() => this.tapCount = 0, 500);

  };

}

export default withRouter(App);
