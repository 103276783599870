import * as React from 'react';
import Input from './Input';
import Textarea, { ITextareaProps } from './Textarea';

/**
 * @param value
 * The value of the textarea
 * w/ out this props, the textarea will not work
 *
 * @param name
 * The name of the input
 *
 * @param onChange
 * method that will change the value of the textarea prop
 * gets passed a single param that is the new value of the textarea
 * w/ out this method, the textarea will not update
 *
 * @param className
 * optional class to be added the textarea element itself
 *
 * @param inputTextClass
 * optional class to be added the input element itself when using the gridInputForm
 *
 * @param id
 * optional id to be added the textarea element itself
 *
 * @param placeholder
 * optional placeholder text for the textarea
 *
 * @param wrapperClass
 * class to be added to the wrapper div
 *
 * @param wrapperLabel
 * text for wrapping label element
 *
 * @param loading
 * indicator to determine that the textarea is loading a value from the server
 *
 * @param showClear
 * indicator to determine whether or not to show the clear "X" button
 *
 * @param tabIndex
 * tab index for the textarea
 *
 * @param maxLength
 * max characters that can be entered in the textarea
 *
 * @param onKeyDown
 * optional handlers for various events
 *
 * @param disabled
 * disabled state of the textarea
 *
 * @param validation
 * a method that takes the value and returns an arry of validation objects
 * always return an empty array for a valid value
 * see the validation store for more documentation on validation objects
 *
 * @param isInPopover
 * set this to yes if the form is inside a popover or modal, forces the
 * validation to display above the popover/modal layer
 * Inconsequential if validation is not being used
 *
 * @param delayedActionOnChang
 * Takes action and interval parameters, will fire the action after the set interval everytime the data changes
 *
 * @param textTransform
 * runs the value through a transform method that will change the textarea before returning via getValue()
 *
 * @param focusOnMount
 * Focuses the textarea once mounted
 *
 * @param selectOnMoun
 * Selects all the input's text once mounted (TextInput and Textarea only)
 *
 * @param rows
 * Height of textarea
 *
 * @param cols
 * Width of textarea
 */
export default class TextareaInput extends React.Component<ITextareaProps, any> {
  private input: Textarea;

  public render(): JSX.Element {
    return (
      <Input {...this.props}>
        {(props) => {
          return (
            <Textarea {...this.props} {...props} ref={(input) => this.input = input} getValue={this.getValue} />
          );
        }}
      </Input>
    );
  }

  public getValue(): string {
    return this.input.getValue();
  }
}
