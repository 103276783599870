import * as React from 'react';
import { IComponent } from '../../global/interfaces';
import { BridgeCommands } from '../../global/enums';
import { inject, observer } from 'mobx-react';
import { Modal, GridFormInput } from '@Lineup/index';
import FormErrorTracker from '../../helpers/ErrorTracker';
import { InputTypes, ButtonType } from '@Lineup/enums';

// const styles = require('./styles/BuildInfo.styl');

interface IState {
  domain: string;
  app: string;
  version: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
}

@inject('loggedInUserStore', 'mobileBridgeStore') @observer
export default class MobileUILoader extends React.Component<IComponent, IState> {
  private errorTracker: FormErrorTracker = new FormErrorTracker();

  public state: IState = {
    domain: '',
    app: 'mobile-amira',
    version: '5.0.0',
    forceShowAllErrors: false,
    flashErrorPvrs: 0,
  };

  public render(): JSX.Element {
    const { domain, app, version, forceShowAllErrors, flashErrorPvrs } = this.state;
    const { registerError } = this.errorTracker;
    const flexBasis = '160px';

    return (
      <Modal
        title="Load UI Build"
        close={this.close}
        buttons={[
          {
            name: t('Apply Changes'),
            handler: this.applyChanges,
            type: ButtonType.ACTION,
          },
        ]}
      >
        <div className={'-ldx-lineup-content'}>
          <GridFormInput
            formLabel={t('Domain')}
            list="ui-domain"
            value={domain}
            labelFlexBasis={flexBasis}
            jsonPath="domain"
            onChange={this.handleChange}
            isFieldRequired={true}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            registerError={registerError}
            validation={(value: string) => {
              const messages = [];
              if (!value.length) { messages.push(t('Domain is required.')); }
              return messages.length ? { messages } : null;
            }}
          />
          <datalist id="ui-domain">
            <option value="https://dev7-connect.lumiradx.com"/>
            <option value="https://qa7-connect.lumiradx.com"/>
            <option value="https://dev6-connect.lumiradx.com"/>
            <option value="https://qa6-connect.lumiradx.com"/>
            <option value="https://integration-connect.lumiradx.com"/>
            <option value="http://darcy.local:8003" />
          </datalist>
          <GridFormInput
            formLabel={t('App')}
            inputType={InputTypes.SELECT}
            value={app}
            labelFlexBasis={flexBasis}
            jsonPath="app"
            onChange={this.handleChange}
            isFieldRequired={true}
            options={[
              {
                label: 'Amira',
                value: 'mobile-amira',
              },
              {
                label: 'Connect',
                value: 'mobile-connect',
              },
            ]}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            registerError={registerError}
          />
          <GridFormInput
            formLabel={t('Version')}
            inputType={InputTypes.SELECT}
            value={version}
            labelFlexBasis={flexBasis}
            jsonPath="version"
            onChange={this.handleChange}
            isFieldRequired={true}
            options={[
              {
                label: '5.0',
                value: '5.0.0',
              },
            ]}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            registerError={registerError}
          />
        </div>
      </Modal>
    );
  }

  public handleChange = (value: string, jsonPath: string) => {
    this.setState({
      [jsonPath]: value,
    } as any);
  };

  public applyChanges = () => {
    const { tellMobileApp, setForceUIUpdate } = this.props.mobileBridgeStore;
    const { domain, app, version } = this.state;
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    const newUiApiUrl = `${domain}/ui-modules/${app}/${version}`;

    setForceUIUpdate(true);
    tellMobileApp(BridgeCommands.SET_UI_CONTENT_URL, newUiApiUrl);
    this.close();

  };

  public close = () => {
    const { history } = this.props;
    history.replace('/login');
  };

}
