import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { LoggedInUser } from '../../stores';
import { TextInput, ConfirmSave } from '@Lineup/index';
import { SaveStates } from '@Lineup/enums/index';
import FormErrorTracker from '../../../../../UI-Core/src/app/helpers/ErrorTracker';
import PasswordRules from './PasswordRules';

const styles = require('./styles/AccountResetPassword.styl');

interface IProps {
  email: string;
  queryCode: string;
  loggedInUserStore?: LoggedInUser;
  setErrorMsg: (value: string) => void;
  handleReturn: () => void;
  queryCodeDetected: boolean;
}

interface IState {
  saveState?: SaveStates;
  saveMessage?: string;
  forceShowAllErrors: boolean;
  flashErrorPvrs: number;
  newPassword: string;
  newPasswordConfirm: string;
  rulesPvrOpen: boolean;
  code: string;
  errorMessage: string;
  openAlertModal: boolean;
}

@inject('loggedInUserStore') @observer
export default class AccountResetPassword extends React.Component<IProps, IState> {
  private menuAnchor: HTMLElement;
  private errorTracker: FormErrorTracker = new FormErrorTracker();

  public state: IState;

  constructor(props) {
    super(props);
    const { queryCode } = this.props;

    this.state = {
      forceShowAllErrors: false,
      flashErrorPvrs: 0,
      newPassword: '',
      newPasswordConfirm: '',
      rulesPvrOpen: false,
      code: queryCode ? queryCode : '',
      saveState: null,
      saveMessage: null,
      errorMessage: null,
      openAlertModal: false,
    };

  }

  public componentWillUnmount() {
    const { resetPasswordRules } = this.props.loggedInUserStore;
    resetPasswordRules();
  }

  public render(): JSX.Element {
    const { email, queryCodeDetected } = this.props;
    const { loggedInUserStore, handleReturn } = this.props;
    const { isValidPassword, ruleResults } = loggedInUserStore;
    const { newPassword, newPasswordConfirm, rulesPvrOpen, code, forceShowAllErrors, flashErrorPvrs, saveState } = this.state;
    const { registerError } = this.errorTracker;

    return (
      <>
        <h3>
          {t('Provide the following information to set your password.  The validation code was sent to your email, if valid.')}
        </h3>
        {(saveState === SaveStates.COMPLETE || saveState === SaveStates.PENDING) ? (
          <ConfirmSave 
            dismissBtnText={t('Dismiss')}
            saveState={saveState} 
            done={handleReturn}
            fail={() => this.setState({saveState: null})}
          />) : null
        }
        <form>
          <TextInput
            id="email"
            data-test="email"
            key="email"
            placeholder={t('Email')}
            jsonPath="email"
            value={email}
            wrapperClass={styles.TextInput}
            disabled={true}
            autoComplete="email"
            autoCapitalize="off"
          />
          <TextInput
            id="code"
            data-test="code"
            key="code"
            placeholder={t('Validation Code')}
            jsonPath="code"
            value={code}
            wrapperClass={styles.TextInput}
            focusOnMount={!queryCodeDetected}
            onChange={(value: string) => this.handleChange(value, 'code')}
            registerError={registerError}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            autoComplete="off"
            autoCapitalize="off"
            disabled={queryCodeDetected}
            title={queryCodeDetected ? t('The reset validation code was populated from the link and should not be changed.') : null}
            validation={(value: string) => {
              const messages = [];
              if (!value.length) { messages.push(t('Validation Code is required.')); }
              return messages.length ? { messages } : null;
            }}
          />
        
          {(!isValidPassword && rulesPvrOpen) ?
            <PasswordRules ruleResults={ruleResults} close={this.handleRulesPvr} anchor={this.menuAnchor}/> : null
          }
          <div key="newPassword" ref={(el: HTMLElement) => this.menuAnchor = el}>
            <TextInput
              id="newPassword"
              data-test="newPassword"
              type="password"
              placeholder={t('New Password')}
              jsonPath="newPassword"
              value={newPassword}
              wrapperClass={styles.TextInput}
              focusOnMount={queryCodeDetected}
              onChange={(value: string) => this.handleChange(value, 'newPassword')}
              onBlur={() => this.handleRulesPvr(false)}
              onFocus={this.handleFocus}
              registerError={registerError}
              forceShowAllErrors={forceShowAllErrors}
              flashErrorPvrs={flashErrorPvrs}
              autoComplete="new-password"
              autoCapitalize="off"
              validation={(function() {
                const messages = [];
                if (!newPassword.length) { messages.push(t('New Password is required.')); }
                if (!isValidPassword) { messages.push(t('New Password is invalid.')); }
                return messages.length ? { messages } : null;
              })()}
            
            />
          </div>
        
          <TextInput
            id="newPasswordConfirm"
            data-test="newPasswordConfirm"
            key="newPasswordConfirm"
            type="password"
            placeholder={t('Confirm New Password')}
            jsonPath="newPasswordConfirm"
            value={newPasswordConfirm}
            wrapperClass={styles.TextInput}
            onChange={(value: string) => this.handleChange(value, 'newPasswordConfirm')}
            registerError={registerError}
            forceShowAllErrors={forceShowAllErrors}
            flashErrorPvrs={flashErrorPvrs}
            autoComplete="new-password"
            autoCapitalize="off"
            validation={(function() {
              const messages = [];
              if (!newPasswordConfirm.length) { messages.push(t('Confirm Password is required.')); }
              if (newPasswordConfirm !== newPassword) { messages.push(t('Confirm Password must match New Password')); }
              return messages.length ? { messages } : null;
            })()}
          />
        
          <div key="set">
            <button
              id="set-password-button"
              data-test="setPasswordButton"
              className={styles.Btn}
              onClick={this.handlePasswordUpdate}
            >
              {t('Set Password')}
            </button>
          </div>
        </form>
      </>
    );
  }

  public handleChange = (value: string, property: string): void => {
    // if new password and password has length check to see if it is valid
    if (property === 'newPassword') {
      this.handlePasswordValidation(value);
    }
    // set state of field
    const state = this.state;
    state[property] = value;
    this.setState(state);
  };

  public handlePasswordValidation = (password: string): void => {
    const { loggedInUserStore, email } = this.props;
    const { getValidatePassword } = loggedInUserStore;
    // check to see if the password is valid
    getValidatePassword(password, email, 'null').then((isValid) => {
      this.handleRulesPvr(!isValid);
    });
  };

  public handlePasswordUpdate = (e: React.MouseEvent): void => {
    e.preventDefault();

    const { email, setErrorMsg, loggedInUserStore} = this.props;
    const { setPasswordWithCode } = loggedInUserStore;
    const { code, newPassword } = this.state;
    const { formHasErrors } = this.errorTracker;
    const { flashErrorPvrs } = this.state;

    if (formHasErrors()) {
      return this.setState({
        forceShowAllErrors: true,
        flashErrorPvrs: flashErrorPvrs + 1,
      });
    }

    this.setState({
      saveState: SaveStates.PENDING,
    });

    setPasswordWithCode(email, code, newPassword)
      .catch(({ message = {} }) => {
        this.setState({
          saveState: SaveStates.FAILED,
        });
        const { ErrorMessage, ValidationErrors = [] } = message;
        const msg = ValidationErrors[0] || ErrorMessage || t('Unable to reset your password.');
        setErrorMsg(msg);
        return Promise.reject(message);
      })
      .then(() => {
        // if password is updated
        this.setState({
          saveState: SaveStates.COMPLETE,
        });
      });
  };

  public handleRulesPvr = (pvrOpen: boolean): void => {
    this.setState({
      rulesPvrOpen: pvrOpen,
    });
  };

  public handleFocus = (): void => {
    const { isValidPassword } = this.props.loggedInUserStore;    

    if (!isValidPassword) { this.handleRulesPvr(true); }
  };

}
