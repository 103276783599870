import { IValidationError } from '@Lineup/interfaces';

interface IErrorNotification {
  inputId: string;
  remove: boolean;
  error: any;
}

export default class FormErrorTracker {
  private errors: Map<string, IValidationError> = new Map();

  public registerError = (errNotification: IErrorNotification): void => {
    const { inputId, remove, error } = errNotification;
    remove ? this.errors.delete(inputId) : this.errors.set(inputId, error);
  };

  public formHasErrors = (): boolean => this.errors.size > 0;
}
