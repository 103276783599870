import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { IComponent } from '../../global/interfaces';
import ModuleContainer from './ModuleContainer';
import { TOOLS_CODE } from '../../global/constants';

@inject('applicationStore', 'loggedInUserStore') @observer
export default class Module extends React.Component<IComponent, any> {

  public componentDidMount() {
    const { applicationStore: { getAvailableModules } } = this.props;

    getAvailableModules()
      .catch((e) => {
        console.log('catch');
        return Promise.reject();
      });

    const { validateAccessCode } = this.props.loggedInUserStore;
    
    // validate access code to get version and envShortCode
    validateAccessCode(TOOLS_CODE);
   
  }

  public render(): JSX.Element {
    const { accessDto } = this.props.loggedInUserStore;
    const { modulesById } = this.props.applicationStore;
    const mod = modulesById.get('tools');

    return mod && accessDto ? (
      <ModuleContainer
        module={mod}
        rootPath="/tools"
        version={accessDto.Version}
        envShortCode={accessDto.EnvShortCode.toLowerCase()}
        {...this.props}
      />
    ) : null;

  }
}
