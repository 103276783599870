/**
 * @enum InvalidTokens Possible reasons a token was invaldiated by the server
 */
export enum InvalidTokens {
  /**
   * Generic expired session
   */
  EXPIRED = 'Invalid token',
  /**
   * Used when the user is first created or in other cases where there is no reason for the Token change
   */
  NOT_SET = 'Invalid token - reason code: NotSet',
  /**
   * The user was disabled by an administrator user
   */
  DISABLED = 'Invalid token - reason code: Disabled',
  /**
   * Roles of the user changed.
   */
  ROLE_CHANGE = 'Invalid token - reason code: RoleChange',
  /**
   * Password was changed by administrator.
   */
  PASSWORD_CHANGE = 'Invalid token - reason code: PasswordChange',
  /**
   * The user logged in on a new session.
   */
  NEW_SESSION = 'Invalid token - reason code: NewSession',
  /**
   * The user started or complete the forgot password process.
   */
  RESET_PASSWORD = 'Invalid token - reason code: ResetPassword'
}

/* ------------------------
 * Mobile App Bridge Enums
 ------------------------ */
export enum OSVersions {
  IOS1 = 0,
  ANDROID = 1,
  IOS2 = 2
}

export enum UIActions {
  CONFIRM = 'confirm',
  RESET = 'reset',
  EXPIRED = 'expired',
  NOTIFICATION = 'notification',
  LOGIN = 'login',
  RESETPASSWORD = 'resetpassword'
}

export enum MobileBridgeVersions {
  BRIDGE_V1 = 1,
  BRIDGE_V2 = 2
}

export enum SensorStatuses {
  _EMPTY = '',
  _BLANK = ' ',
  _DASH = '-',
  _OKAY = 'Okay __SensorStatusVal__',
  _IDLE = 'Idle __SensorStatusVal__',
  _CONN = 'Conn __SensorStatusVal__',
  _BLE_OFF = 'BLE is off',
  _CONN_NB = 'Conn: no bcn: __SensorStatusVal__',
  _CONN_NP = 'Conn: bcn: no props yet',
  _SEND_SET = 'Sending Settings',
  _OFFLOAD = 'Offloading: __SensorStatusVal__',
  _SET_METER = 'Setting Meter Settings',
  _SET_NET = 'Setting Network Status',
  _REQ_CAL = 'Requesting Cal File',
  _SEARCH = 'Searching for Patient',
  _TESTING = 'Testing',
  _SET_OS = 'Setting Oper/Stat: __SensorStatusVal__',
  _SET_LOT = 'Setting Cal Files: __SensorStatusVal__'
}

export enum SensorStatusKeys {
  OKAY = '_OKAY',
  OFFLOAD = '_OFFLOAD'
}

export enum DeviceStatuses {
  /**
   * Note: the NOT_STARTED is a UI status only, do not send to mobile app
   */
  NOT_STARTED = 'notstarted',
  SCANNING = 'scanning',
  SCAN_STARTED = 'scanStarted',
  STOP_SCANNING = 'stopscanning',
  CONNECTING = 'connecting',
  TIMEOUT = 'timeout',
  SUCCESS = 'success',
  SENDING_DATA = 'sendingData',
  RETRYING = 'retrying',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected'
}

export enum BridgeCommands {
  /**
   * @enum Call when the UI API is ready for use by the app
   */
  API_READY = 'api_ready',
  /**
   * @enum Call when user confirms they want to load a newer version of the UI
   */
  RELOAD_UI = 'reload_ui',
  /**
   * @enum Allows the UI to specify the UIContent url used by Quantum
   */
  SET_UI_CONTENT_URL = 'set_uicontent_url',
  /**
   * @enum Allows the UI to specify the Quantum server URL
   * @param url - string - new url
   */
  SET_SERVER_URL = 'set_server_url',
  /**
   * @enum Allows the UI to specify the GUID used by Quantum to get the DeviceList
   * @param guid - string - new guid
   */
  SET_GUID = 'set_guid',
  /**
   * @enum Tells the app to function as a hub. The app uses the specified server URL and GUID to get a device list and start connecting to devices.
   * @param act_as_hub - boolean - whether or not to act as a hub
   */
  ACT_AS_HUB = 'act_as_hub',
  /**
   * @enum Forces the app to re-send the app_information.
   */
  GET_APP_INFORMATION = 'get_app_information',
  /**
   * @enum Allows the UI to save any string to the app.  The current Ui stores this as a json string.
   * @param ui_string - string - anything the UI wants to persist on the device for future use
   */
  SET_UI_STRING = 'set_ui_string',
  /**
   * @enum Starts scanning for the specified hub.
   * @param serialNumber - string - serial number of the hub to scan for
   */
  START_SCAN = 'start_scan',
  /**
   * @enum Stops scanning for the specified hub.
   * @param serialNumber - string - serial number of the hub to stop scanning for
   */
  STOP_SCAN = 'stop_scan',
  /**
   * @enum Configure a hub
   * @param hubConfig - interface - hub configuration
   */
  CONFIG_HUB = 'config_hub',
  /**
   * @enum This function allows the UI to provide a mechanism for the user to send a log.
   * @param msg
   */
  SEND_LOG = 'send_log',
  /**
   * @enum This function is called by the UI to output info for debug.  It passes a string of debug information intended to be routed to a debug console or log.
   * @param msg - string - a message to be logged
   */
  J_LOG = 'j_log',
  /**
   * @enum This opens an external link in a native overlay above the app's content.
   * @param href - A URI that is accessed
   */
  OPEN_EXTERNAL_URL = 'open_external_url',
  /**
   * @enum Adds a device to the local connect list and registers it to Quantum.
   * @param wireless_id - the instrument's wireless ID. Must be unique.
   */
  ADD_DEVICE = 'add_device',
  /**
   * @enum Removes a device from the device list.
   * @param wireless_id - the instrument's wireless ID.
   */
  REMOVE_DEVICE = 'remove_device',
  /**
   * @enum Starts scanning for the specified ULC device.
   * @param wireless_id - string - serial number of the device to scan for
   */
  CONNECT_TO_ULC = 'connect_to_ulc',
  /**
   * @enum Stops scanning for the specified ULC device.
   * @param wireless_id - string - serial number of the device for which to stop scanning
   */
  DISCONNECT_FROM_ULC = 'disconnect_from_ulc',
  /**
   * @enum Passes an object to the app. The app sends the data to the instrument. Object limited to a total of 296 bytes.
   */
  SEND_READING_LABEL_TO_DEVICE = 'send_reading_label_to_device',
  /**
   * @enum Sends a command to the device
   * @param wireless_id - string - serial number of the device
   * @param command - enum - member of `UlcCommands` enum
   */
  SEND_ULC_CMD = 'send_ulc_cmd',
  /**
   * @enum Sends a lot calibration code
   * @param data - object - Data containing device serialNumber and lotCal code
   */
  SEND_LOT_CAL = 'send_lot_cal',
  /**
   * @enum Scans for a QR code to connect to a device
   * @param command - enum - member of `UlcQrCommands` enum
   */
  QRCODE_SCANNER = 'qrcode_scanner',
  /**
   * @enum Directs the App whether or not to keep the screen active
   */
  KEEP_SCREEN_ON = 'keep_screen_on',
  /**
   * @enum Displays an App notification
   */
  DISPLAY_NOTIFICATION = 'display_notification',
  /**
   * @enum Displays the settings for the Engage app
   */
  DISPLAY_APP_SETTINGS = 'display_app_settings',
  /**
   * @enum Send the hof url and the org guid to the native app
   * { "ExternalHof": { "URL": "http://192.168.1.123", Guid": "3245jh354hjh345" } }
   */
  HOF_URL = 'hof_url',
}

/**
 * The possible methods of the `SEND_ULC_CMD` bridge command
 */
export enum UlcCommands {
  ENABLE_INSERT_STRIP = 1,
  ENABLE_REMOVE_STRIP
}

// Note: all test names (keys) are lowercased with punctuation & whitespace removed to maximise chance of a hit
export enum TestCategories {
  'inr' = 'Coagulation_INR',
  'crp' = 'Chemistry',
  'ddimer' = 'Cardiology',
  'hba1c' = 'Diabetes'
}

export enum InstDataSources {
  OFF = 0,
  LUMIRADX = 1,
  MIDDLEWARE = 2
}

export enum AmiraModules {
  PROFESSIONAL_TEST = 1,
  SELF_TEST = 2,
  BOTH = 3
}

export enum DataViewModes {
  PDE,
  CONNECT
}

export enum TestPurposes {
  PATIENT,
  QUALITY_CONTROL,
  POOLED
}

export enum AssayFunctions {
  QUALITATIVE = 1,
  QUANTITATIVE,
  CONTROL
}

export enum AcceptStatuses {
  REJECTED,
  ACCEPTED,
  REJECTED_AUTOSAVE,
  ACCEPTED_AUTOSAVE
}

export enum Ms {
  THIRTY_SECONDS = 30000,
  ONE_MINUTE = 60000,
  TWO_MINUTES = 120000,
  FOUR_MINUTES = 240000,
  FIVE_MINUTES = 300000,
  TEN_MINUTES = 600000,
  FIFTEEN_MINUTES = 900000,
  TWENTY_MINUTES = 1200000,
  THIRTY_MINUTES = 1800000,
  SIXTY_MINUTES = 3600000
}

export const enum PatientGenders {
  NA = 0,
  MALE,
  FEMALE,
  OTHER,
  UNKNOWN,
}

export enum DemographicsDisplayLevel {
  NONE,
  ID_ONLY,
  ALL,
}

export enum DemographicsDisplayStrings {
  NONE = 'None',
  ID_ONLY = 'IdOnly',
  ALL = 'All',
}
